import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import {
  Alert,
  Button,
  Pane,
  Heading,
  CalendarIcon,
  EditIcon,
  Spinner,
  Link,
  RadioGroup,
  majorScale,
  toaster,
  defaultTheme as theme
} from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import { Link as BrowserLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { formatInTimeZone } from "date-fns-tz";

import SessionCard from "../../components/session/SessionCard";
import BackButton from "../../components/BackButton";
import TextArea from "../../components/form/TextArea";
import ChooseTime from "../../routes/profile/scheduler/ChooseTime";

import SessionQueries from "../../queries/session";

const options = [
  {
    label: "Something came up - I can no longer make this time",
    value: "NOT_AVAILABLE"
  },
  {
    label: "I chose the wrong time",
    value: "WRONG_TIME"
  },
  {
    label: "Something else",
    value: "OTHER"
  }
];

const RescheduleForm = ({ session, history, myProfile, mentorId }) => {
  const [timezone, setTimeZone] = useState(null);
  const [rescheduleSession, { loading }] = useMutation(SessionQueries.mutations.RescheduleSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "upcoming" }
      },
      { query: SessionQueries.queries.MyFeedback },
      { query: SessionQueries.queries.MyReceivedRequests },
      { query: SessionQueries.queries.MySessionRequests },
      { query: SessionQueries.queries.MissingReviews },
      { query: SessionQueries.queries.MySessionOffers }
    ],
    onCompleted: (rescheduleSession) => {
      toaster.success(`Rescheduled session.`);
      history.push(`/session/${rescheduleSession?.rescheduleSession?.id}/prepare`);
    }
  });

  if (myProfile?.id && _.isNull(timezone)) {
    setTimeZone(myProfile?.timezone ? myProfile.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  return (
    <Formik
      initialValues={{
        reason: "NOT_AVAILABLE",
        details: "",
        timeSlot: null
      }}
      onSubmit={({ reason, details, timeSlot }, { setSubmitting }) => {
        rescheduleSession({
          variables: {
            id: session?.id,
            reason,
            details,
            startTime: timeSlot?.start,
            timeZone: timezone
          }
        });

        setSubmitting(false);
      }}
      render={({ errors, values, setFieldValue, isSubmitting, values: { timeSlot } }) => (
        <Form>
          <Heading size={600} marginBottom={majorScale(2)}>
            {`Choose a new time`}
          </Heading>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            backgroundColor="white"
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            marginBottom={majorScale(4)}
            paddingX={majorScale(4)}
            paddingY={majorScale(4)}
          >
            {_.isNull(timeSlot) ? (
              <ChooseTime
                title=""
                myProfile={myProfile}
                mentorId={mentorId}
                onSelect={(slot) => {
                  setFieldValue("timeSlot", slot, false);
                }}
                hideSelect
                onChooseTz={(tz) => setTimeZone(tz)}
              />
            ) : (
              <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Heading>New timeslot</Heading>
                <Button type="button" iconBefore={EditIcon} appearance="minimal" onClick={() => setFieldValue("timeSlot", null, false)}>
                  {formatInTimeZone(timeSlot?.start, timezone, "EEE MMM dd hh:mm (z)")}
                </Button>
              </Pane>
            )}
          </Pane>
          <Heading size={600} marginBottom={majorScale(2)}>
            {`Let them know why you're rescheduling`}
          </Heading>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            backgroundColor="white"
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            marginBottom={majorScale(4)}
            paddingX={majorScale(4)}
            paddingY={majorScale(4)}
          >
            <Heading size={400}>Choose a reason</Heading>
            <RadioGroup
              defaultValue={values?.reason}
              options={options}
              size={majorScale(2)}
              onChange={(e) => setFieldValue("reason", e.target.value, false)}
              marginBottom={majorScale(1)}
            />
            <Heading size={400} marginBottom={majorScale(1)}>
              Add an optional message
            </Heading>
            <Field type="details" name="details" placeholder="" component={TextArea} />
            <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(1)}>
              <Button appearance="primary" type="submit" disabled={_.isNull(timeSlot)} isLoading={loading || isSubmitting}>
                Reschedule session
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const RescheduleSessionForm = ({
  history,
  match: {
    params: { id, state }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.RescheduleSessionQuery, {
    variables: { id }
  });

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row justify="flex-start" style={{ width: "100%" }}>
          <Col xs={12} sm={2} md={2} lg={2}>
            <BackButton />
          </Col>
          <Col xs={12} sm={8} md={8} lg={8}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={screenClass.includes("xs") ? null : majorScale(4)}
              width="100%"
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={majorScale(2)}>
                <CalendarIcon marginRight={majorScale(1)} size={majorScale(2)} />
                {"Reschedule session"}
              </Heading>
            </Pane>
            {sessionAndReview?.sessionById?.rescheduleCount > 0 && (
              <Pane display="flex" justifyContent="center" marginBottom={majorScale(2)}>
                <Alert intent="warning" title="">
                  This is the second time you are rescheduling this session. If you need to reschedule again, you will need to book a new
                  session.{" "}
                  <BrowserLink is={Link} to="/booking-policy">
                    View our booking policy.
                  </BrowserLink>
                </Alert>
              </Pane>
            )}
            {loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Spinner />
              </Pane>
            )}
            {error && !loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Alert title="There was an error loading this page. Please try again in a few momments." />
              </Pane>
            )}
            {sessionAndReview?.sessionById?.id && (
              <>
                <Pane width="100%">
                  <SessionCard myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />
                </Pane>
                <RescheduleForm
                  session={sessionAndReview?.sessionById}
                  myProfile={sessionAndReview?.myProfile}
                  mentorId={sessionAndReview?.sessionById?.coach?.id}
                  history={history}
                />
              </>
            )}
          </Col>
        </Row>
      )}
    />
  );
};

export default RescheduleSessionForm;
