import _ from "lodash";
import React from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { Link as BrowserLink } from "react-router-dom";
import { Pane, Tab, TabNavigation, majorScale, minorScale } from "evergreen-ui";

import theme from "../theme";

const stylePicker = (index, selectedIndex) => {
  const options = {
    unselected: {
      color: theme.color.text,
      padding: majorScale(1),
      fontFamily: "'Karla', sans-serif",
      fontstyle: "normal",
      fontWeight: 400,
      fontSize: majorScale(2),
      letterSpacing: "-0.01em",
      marginTop: majorScale(1),
      marginBottom: majorScale(1),
      textAlign: "center",
      "&:hover": {
        all: "initial"
      }
    },

    selectedStyle: {
      all: "initial",
      color: theme.color.text,
      fontFamily: "'Karla', sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: majorScale(2),
      letterSpacing: "-0.01em",
      textAlign: "center",
      padding: minorScale(1),
      marginTop: majorScale(2),
      marginBottom: majorScale(2),
      backgroundColor: "none"
    }
  };

  return _.isEqual(index, selectedIndex) ? options.selectedStyle : options.unselected;
};

const SubNav = ({ tabs, subState }) => {
  let initialIndex = _.findIndex(tabs, { title: subState });
  let selectedIndex = initialIndex;

  return (
    <Row align="center">
      <Col xs={12} style={{ padding: 0 }}>
        <TabNavigation textAlign="center" backgroundColor={theme.color.lightestGrey}>
          {tabs.map((tab, index) => (
            <Tab
              style={{ ...stylePicker(index, selectedIndex) }}
              key={tab.title}
              is={BrowserLink}
              to={tab.link}
              d={tab.title}
              onSelect={() => (selectedIndex = initialIndex)}
              isSelected={index === selectedIndex}
            >
              {tab.title}
            </Tab>
          ))}
        </TabNavigation>
      </Col>
    </Row>
  );
};

export default withRouter(SubNav);
