import _ from "lodash";
import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Pane, SelectMenu, Text, IconButton, TrashIcon, majorScale, toaster } from "evergreen-ui";

import ProfileList from "../../queries/profile/ProfileList";

const HOURS_SUCCESS = "Updated your opening hours.";
const AT_LEAST_ONE_WARNING = "Choose at least one day.";

const availableHours = _.range(0, 24).map((t) => {
  if (t < 10) return `0${t}:00`;
  else return `${t}:00`;
});

const availableDays = [
  { value: "U", text: "S" },
  { value: "M", text: "M" },
  { value: "T", text: "T" },
  { value: "W", text: "W" },
  { value: "R", text: "T" },
  { value: "F", text: "F" },
  { value: "S", text: "S" }
];

const atLeastOneDay = (days) => {
  // Check that at least one day is selected per opening hours
  if (!days.length) {
    toaster.warning(AT_LEAST_ONE_WARNING, { duration: 2 });
    return false;
  } else return true;
};

const OpeningHours = ({ openingHours, onRemove, onUpdate }) => {
  const [days, setDays] = useState(openingHours.days);
  const [start, setStart] = useState(openingHours.start);
  const [end, setEnd] = useState(openingHours.end);

  if (_.isEmpty(openingHours)) return null;

  return (
    <React.Fragment>
      <Pane display="flex" flexDirection="row" justifyContent="start" paddingBottom={majorScale(2)}>
        {/* Available Days */}
        <Pane display="flex" justifyContent="flex-end">
          {availableDays.map((day) => (
            <Button
              key={`OpeningHours-${day.value}`}
              marginRight={majorScale(1)}
              paddingLeft={0}
              paddingRight={0}
              intent={"none"}
              appearance={days.includes(day.value) ? "primary" : "minimal"}
              onClick={() => {
                const updated = days.includes(day.value) ? _.without(days, day.value) : [...days, day.value];

                if (atLeastOneDay(updated)) {
                  setDays(updated);
                  onUpdate({ days: updated, start, end });
                }
              }}
            >
              {day.text}
            </Button>
          ))}
        </Pane>

        {/* Available Hours */}
        <Pane display="flex" justifyContent="flex-end" alignItems="center">
          <SelectMenu
            title="Start"
            options={availableHours.map((label) => ({ label, value: label }))}
            selected={start}
            onSelect={({ value }) => {
              setStart(value);
              onUpdate({ days, start: value, end });
            }}
          >
            <Button>{start || `Start`}</Button>
          </SelectMenu>
          <Text marginRight={majorScale(1)} marginLeft={majorScale(1)}>
            to
          </Text>
          <SelectMenu
            title="End"
            options={availableHours.map((label) => ({ label, value: label }))}
            selected={end}
            onSelect={({ value }) => {
              setEnd(value);
              onUpdate({ days, start, end: value });
            }}
          >
            <Button>{end || `End`}</Button>
          </SelectMenu>
        </Pane>

        {onRemove && _.isFunction(onRemove) && (
          <Pane>
            <IconButton icon={TrashIcon} intent="danger" appearance="minimal" onClick={() => onRemove()} />
          </Pane>
        )}
      </Pane>
    </React.Fragment>
  );
};

export default OpeningHours;
