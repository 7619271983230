import React, { useState } from "react";
import { EditIcon, Button, Pane, Popover, majorScale, defaultTheme as theme } from "evergreen-ui";
import { Container } from "react-grid-system";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../queries/profile/ProfileList";
import ProfileQuery from "../../../components/profile/ProfileQuery";

import Picker from "./Picker";

// Choose yellow as default background - is a backstop in case of data issues
const defaultBg = "https://ucarecdn.com/13e84758-d0a2-441a-a6b0-dbed979b90e7/";

const ProfileBackground = ({ profile, isSmallScreen, isCurrentUser, onChange, isEditing, isMobile }) => {
  const [localCover, setLocalCover] = useState();
  const [disableClose, setDisableClose] = useState(false);
  const [backgroundHover] = useState(isEditing);
  const background = localCover || profile?.cover || defaultBg;
  const showPopover = isCurrentUser && (disableClose || backgroundHover);

  const [updateCover] = useMutation(ProfileList.UpdateCover, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileQuery }]
  });

  const handleUpdateBackground = (image) => {
    updateCover({ variables: { image } });
    setLocalCover(image);
    window.analytics.track("Update Cover Photo");
  };

  return (
    <Pane
      height={isSmallScreen ? "100px" : "200px"}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundColor="#F4F6FA"
      backgroundImage={background && `url(${background})`}
      border={`1px solid ${theme.colors.gray500}`}
      borderTopLeftRadius={majorScale(2)}
      borderTopRightRadius={majorScale(2)}
      borderBottomRightRadius={isMobile ? undefined : majorScale(2)}
      width="100%"
    >
      <Container style={{ height: "100%" }}>
        <Pane position="relative" height="100%">
          {showPopover && (
            <Popover
              shouldCloseOnExternalClick={!disableClose}
              content={<Picker onChange={handleUpdateBackground} setDisableClose={setDisableClose} />}
            >
              <Pane
                background="white"
                position="absolute"
                paddingY={2}
                paddingX={majorScale(1)}
                bottom={majorScale(1)}
                borderRadius="4px"
                right={0}
                cursor="pointer"
              >
                <Button iconBefore={EditIcon} appearance="minimal">
                  Edit background
                </Button>
              </Pane>
            </Popover>
          )}
        </Pane>
      </Container>
    </Pane>
  );
};

export default ProfileBackground;
