import React from "react";
import { Pill, majorScale } from "evergreen-ui";
import { MOTIVATION_TAGS, MOTIVATION_COLORS } from "./constants";

const MotivationPill = ({ motivation }) => {
  return (
    <Pill paddingLeft="8px" paddingRight="8px" marginRight={majorScale(1)} color={MOTIVATION_COLORS[motivation]}>
      {MOTIVATION_TAGS[motivation]}
    </Pill>
  );
};

export default MotivationPill;
