import React from "react";
import { Button, EditIcon } from "evergreen-ui";

const EditItem = ({ comment, post, myProfile, onEdit }) => {
  return (
    <Button
      key={`EditItem-${comment?.id ? comment.id : post?.id}`}
      size="small"
      fontWeight={600}
      display="flex"
      alignItems="center"
      justifyContent="center"
      appearance="minimal"
      fontSize={14}
      borderRadius={999}
      height={28}
      style={{ borderRadius: 99 }}
      onClick={onEdit}
    >
      <EditIcon color="#8F95B2" marginRight={5} />
      Edit
    </Button>
  );
};

export default EditItem;
