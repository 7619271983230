import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { isString, isNumber } from "lodash";
import { withRouter } from "react-router-dom";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Pane, Paragraph, Heading, Spinner, majorScale, TabNavigation, Tab, minorScale, defaultTheme as theme } from "evergreen-ui";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";

import { SortSelect } from "./sections/FiltersRow";
import MentorFilters from "./sections/MentorFilters";
import CandidateQueries from "../../queries/candidate";
import NewMentorCard from "../../components/mentor/NewMentorCard";
import emptyPhoto from "../../images/empty-chat.svg";
import { Helmet } from "react-helmet";
import ProfileList from "../../queries/profile/ProfileList";
import CoachList from "../coach/CoachList";
import ResumeNotif from "../../components/candidate/ResumeNotif";

const TITLE = "Find a mentor";

const getArray = (item = []) => (item && isString(item) ? [item] : item);

export const FILTER_TYPE = {
  SEARCH: "name",
  TOPICS: "topics",
  ROLES: "roles",
  LEVELS: "levels",
  TIME: "availableSoon",
  INDUSTRY: "industry",
  COMPANY_SIZE: "companySize",
  COMPANY_STAGE: "companyStage",
  SKILLS: "skills",
  LOCATION: "location",
  SAVED: "saved",
  STATUS: "status",
  SORT: "sort"
};

export const INITIAL_FILTERS = {
  name: "",
  topics: [],
  roles: [],
  levels: [],
  availableSoon: false,
  industry: [],
  companySize: [],
  companyStage: [],
  skills: [],
  lat: undefined,
  lon: undefined,
  saved: false,
  status: "Active",
  sort: "DEFAULT"
};

const getFiltersFromURL = (location) => {
  const params = queryString.parse(location.search, { arrayFormat: "comma" });

  return {
    name: params?.name || "",
    topics: getArray(params?.topics),
    roles: getArray(params?.roles),
    levels: getArray(params?.levels),
    availableSoon: params?.availableSoon?.includes("true") ? true : false,
    industry: getArray(params?.industry),
    companySize: getArray(params?.companySize),
    companyStage: getArray(params?.companyStage),
    skills: getArray(params?.skills),
    lat: isNumber(params?.lat) ? parseFloat(params?.lat) : undefined,
    lon: isNumber(params?.lon) ? parseFloat(params?.lon) : undefined,
    status: params?.status || "Active",
    sort: params?.sort || INITIAL_FILTERS.sort,
    saved: params?.saved?.includes("true") ? true : false
  };
};

const updateFiltersInURL = (history, updatedFilters) => {
  const stringified = queryString.stringify(updatedFilters, {
    arrayFormat: "comma"
  });
  history.push({ search: stringified });
};

const MentorsMeta = () => {
  const metaDescription = `Connect with a mentor through Merit. Merit connects tech talent to senior leaders to grow their career, craft, and community.`;
  const title = "Find a mentor | Merit";
  const picture = "https://ucarecdn.com/f637b3cc-42b2-40d7-a508-ea3b125c6b79/MeritScreen.png";
  const twitterURL = `@meritmentor`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window.location.href} />
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta name="title" property="og:title" content={title} />
      <meta name="description" property="og:description" content={metaDescription} />
      <meta name="image" property="og:image" content={picture} />
      <meta name="url" property="og:url" content={window.location.href} />
      <meta name="twitter:card" property="twitter:card" content="summary" />
      <meta name="twitter:title" property="twitter:title" content={title} />
      <meta name="twitter:description" property="twitter:description" content={metaDescription} />
      <meta name="twitter:image" property="twitter:image" content={picture} />
      <meta name="twitter:site" property="twitter:site" content={twitterURL} />
    </Helmet>
  );
};

const EmptySearchView = ({ isSaved }) => {
  const title = isSaved ? "No mentors saved" : "No mentors found";
  const details = isSaved ? "When you save a mentor profile you can view it here" : "Try changing or widening your search criteria";

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
    </Pane>
  );
};

const MentorsTable = ({ pageInfo, mentors, fetchMore, myProfile, onUpdateCard, isMobile, refetchQueries }) => {
  const _mentors = mentors.filter((m) => m.user);

  return (
    <Pane marginTop={majorScale(2)}>
      {_mentors && _mentors.length > 0 && (
        <InfiniteScroll
          hasMore={pageInfo?.hasNextPage}
          dataLength={_mentors.length}
          loader={
            <Row>
              <Col xs={12}>
                <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <Spinner />
                </Pane>
              </Col>
            </Row>
          }
          next={fetchMore}
          style={{ overflow: "hidden" }}
        >
          <Row>
            {_mentors.map((mentor) => (
              <Col xs={12} key={mentor?.id}>
                <NewMentorCard
                  mentor={mentor}
                  key={mentor.id}
                  nextAvailable={mentor.nextAvailable}
                  myProfile={myProfile}
                  onUpdate={onUpdateCard}
                  isMobile={isMobile}
                  refetchQueries={refetchQueries}
                />
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      )}
    </Pane>
  );
};

const LoadingView = () => (
  <Pane>
    <Row style={{ height: 360 }}>
      <Col xs={12} style={{ height: "100%" }}>
        <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center" height="100%">
          <Spinner />
        </Pane>
      </Col>
    </Row>
  </Pane>
);

const MentorIndexPage = ({ location, history }) => {
  const filtersFromURL = getFiltersFromURL(location);
  const [selectedFilters, setSelectedFilters] = useState(filtersFromURL);
  const { data: topicData } = useQuery(ProfileList.TopicOptions);
  const { data: profileFilterData } = useQuery(ProfileList.LeaderFilterOptions);

  const { data: filterData } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const {
    data: mentorResult,
    loading,
    error,
    fetchMore,
    refetch
  } = useQuery(CoachList.LeaderQuery, {
    variables: { cursor: "1", ...selectedFilters }
  });

  const refetchQueries = [
    {
      query: CoachList.LeaderQuery,
      variables: {
        cursor: "1",
        ...selectedFilters
      }
    }
  ];

  const fetchMoreCallback = (prev, { fetchMoreResult }) => {
    return fetchMoreResult
      ? {
          ...prev,
          leaders: {
            __typename: fetchMoreResult.leaders.__typename,
            pageInfo: fetchMoreResult.leaders.pageInfo,
            edges: [...prev.leaders.edges, ...fetchMoreResult.leaders.edges]
          }
        }
      : prev;
  };

  const handleFetchMore = () =>
    fetchMore({
      query: CoachList.LeaderQuery,
      variables: {
        ...selectedFilters,
        cursor: mentorResult?.leaders?.pageInfo?.nextPage
      },
      updateQuery: fetchMoreCallback
    });

  const handleFilterUpdate = (type, value) => {
    const updatedFilters = { ...selectedFilters, [type]: value };
    setSelectedFilters(updatedFilters);
    updateFiltersInURL(history, updatedFilters);
    refetch(updatedFilters);
  };

  const onLocationUpdate = (formattedLocation, inputRef) => {
    const updatedFilters = {
      ...selectedFilters,
      lat: formattedLocation?.lat,
      lon: formattedLocation?.long
    };

    setSelectedFilters(updatedFilters);
    updateFiltersInURL(history, updatedFilters);
  };

  const handleFilterReset = () => {
    setSelectedFilters(INITIAL_FILTERS);
    updateFiltersInURL(history, INITIAL_FILTERS);
  };

  const isViewingActive = selectedFilters.status === "active";

  const { companySize, industry } = _.reduce(
    filterData?.availableTeamFeatureCategories,
    (acc, category) => {
      acc[category.value] = _.chain(filterData?.availableTeamFeatures)
        .filter({ category: category.value })
        .map((feature) => ({ label: feature.attribute, value: feature.attribute }))
        .value();

      return acc;
    },
    {}
  );

  const skills = _.map(filterData?.availableSkills, ({ attribute }) => ({ label: attribute, value: attribute }));

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <>
            <MentorsMeta />
            <ResumeNotif />
            <Pane width="100%">
              <Row>
                <Col xs={12} sm={3} lg={3}>
                  <MentorFilters
                    selectedFilters={selectedFilters}
                    filterData={{ ...filterData, industry, companySize, availableTopics: topicData?.availableTopics, skills }}
                    onFilterUpdate={handleFilterUpdate}
                    onFiltersReset={handleFilterReset}
                    onLocationUpdate={onLocationUpdate}
                  />
                </Col>
                <Col xs={12} sm={9} lg={9}>
                  <Pane
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    width="100%"
                    marginTop={majorScale(2)}
                    marginBottom={majorScale(1)}
                  >
                    <Heading is="h1" size={800} marginBottom={majorScale(1)}>
                      {mentorResult?.leaders?.pageInfo.edgeCount > 0
                        ? `Search results (${mentorResult?.leaders?.pageInfo.edgeCount})`
                        : TITLE}
                    </Heading>
                    <Pane display="flex" justifyContent="space-between">
                      <TabNavigation>
                        <Tab
                          id="active"
                          onClick={() => handleFilterUpdate("status", "Active")}
                          appearance="minimal"
                          isSelected={selectedFilters.status === "Active"}
                          marginRight={majorScale(1)}
                          paddingY={minorScale(5)}
                          fontWeight={500}
                          size={400}
                          color={selectedFilters.status === "Active" ? "#3366FF" : "#696F8C"}
                          borderBottom={selectedFilters.status === "Active" ? "2px solid #3366FF" : "2px solid white"}
                        >
                          Active
                        </Tab>
                        <Tab
                          id="inactive"
                          onClick={() => handleFilterUpdate("status", "Inactive")}
                          appearance="minimal"
                          isSelected={selectedFilters.status === "Inactive"}
                          marginRight={majorScale(1)}
                          paddingY={minorScale(5)}
                          fontWeight={500}
                          size={400}
                          color={selectedFilters.status === "Inactive" ? "#3366FF" : "#696F8C"}
                          borderBottom={selectedFilters.status === "Inactive" ? "2px solid #3366FF" : "2px solid white"}
                        >
                          Inactive
                        </Tab>
                        <Tab
                          id="saved"
                          onClick={() => handleFilterUpdate("status", "Saved")}
                          appearance="minimal"
                          isSelected={selectedFilters.status === "Saved"}
                          marginRight={majorScale(1)}
                          paddingY={minorScale(5)}
                          fontWeight={500}
                          size={400}
                          color={selectedFilters.status === "Saved" ? "#3366FF" : "#696F8C"}
                          borderBottom={selectedFilters.status === "Saved" ? "2px solid #3366FF" : "2px solid white"}
                        >
                          Saved
                        </Tab>
                      </TabNavigation>
                      <SortSelect
                        selected={selectedFilters?.sort}
                        type={FILTER_TYPE.SORT}
                        availableSorts={profileFilterData?.availableSorts}
                        onFilterUpdate={handleFilterUpdate}
                      />
                    </Pane>
                  </Pane>

                  {loading && !mentorResult?.leaders?.pageInfo && <LoadingView />}
                  {mentorResult?.leaders?.pageInfo && (
                    <>
                      {loading ? (
                        <LoadingView />
                      ) : (
                        <>
                          {mentorResult?.leaders?.pageInfo?.edgeCount === 0 ? (
                            <EmptySearchView isSaved={filtersFromURL?.status === "Saved"} />
                          ) : (
                            <MentorsTable
                              pageInfo={mentorResult?.leaders?.pageInfo}
                              mentors={mentorResult?.leaders?.edges}
                              fetchMore={handleFetchMore}
                              myProfile={null}
                              refetchQueries={refetchQueries}
                              onUpdateCard={handleFilterUpdate}
                              isMobile={isMobile}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Pane>
          </>
        );
      }}
    />
  );
};

export default withRouter(MentorIndexPage);
