import gql from "graphql-tag";

import QueryUtils from "../../queries/utils";
import ProfileList from "../../queries/profile/ProfileList";

const CoachList = {};

CoachList.fragments = {
  topic: `
      id,
      name,
      description
  `,
  mentor: `
    id,
    schedule,
    topics {
      id,
      name,
      description
    },
    isAvailable,
    availableSlots,
    nextAvailable {
      start
    },
    reason,
    playlists {
      id,
      name,
      description,
    },
    mySave {
      id,
      status
    }
  `,
  coach: `
      id,
      schedule,
      topics {
        name,
        description
      },
      isAvailable,
      availableSlots,
      user {
        ${ProfileList.fragments.profile}
      }
  `,
  reminder: `
    id,
    status,
  `,
  recommendation: `
    id,
    status,
    reason,
    sentDate,
    createdAt,
    updatedAt,
  `
};

CoachList.CoachQuery = gql`
query CoachQuery($topicName: [String], $cursor: String) {
  topics {
    ${CoachList.fragments.topic}
  },
  coaches(topic: $topicName, cursor: $cursor) {
    edges {
      ${CoachList.fragments.coach}
    },
    ${QueryUtils.PageInfo}
  }
}
`;

CoachList.LeaderQuery = gql`
  query LeaderQuery($query: LeaderSearchQuery, $cursor: String) {
    leaders(query: $query, cursor: $cursor) {
      edges {
        ${CoachList.fragments.coach}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

const LeaderSearchQuery = {
  vars: `
    $name: String,
    $levels: [String],
    $roles: [String],
    $topics: [String],
    $availableSoon: Boolean,
    $industry: [String],
    $companySize: [String],
    $lat: Float,
    $lon: Float,
    $skills: [String],
    $saved: Boolean,
    $status: String,
    $sort: String,
  `,
  args: `
    name: $name,
    levels: $levels,
    roles: $roles,
    topics: $topics,
    availableSoon: $availableSoon,
    industry: $industry,
    companySize: $companySize,
    lat: $lat,
    lon: $lon,
    skills: $skills,
    saved: $saved,
    status: $status,
    sort: $sort
  `
};

CoachList.LeaderSearchQueryOptions = LeaderSearchQuery;

CoachList.LeaderQuery = gql`
  query LeaderQuery(${LeaderSearchQuery.vars}, $cursor: String) {
    leaders(${LeaderSearchQuery.args}, cursor: $cursor) {
      __typename,
      edges {
        ${CoachList.fragments.mentor},
        totalSessions,
        totalReviews,
        user {
          ${ProfileList.fragments.profile},
          candidate {
            id,
            specialities {
              skill,
              jobRole,
              proficiency
            }
          }
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

CoachList.MoreLeadersQuery = gql`
  query LeaderQuery(${LeaderSearchQuery.vars}, $cursor: String) {
    leaders(${LeaderSearchQuery.args}, cursor: $cursor) {
      __typename,
      edges {
        ${CoachList.fragments.mentor}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

CoachList.MoreCoachesQuery = gql`
query CoachQuery($topicName: [String], $cursor: String) {
  coaches(topic: $topicName, cursor: $cursor) {
    __typename,
    edges {
      ${CoachList.fragments.coach}
    },
    ${QueryUtils.PageInfo}
  }
}
`;

CoachList.CoachPreviewQuery = gql`
  query CoachPreviewQuery {
    coachPreview {
      ${CoachList.fragments.coach}
    }
  }
`;

CoachList.RecommendationQuery = gql`
  query RecommendationQuery {
    myRecommendations {
      ${CoachList.fragments.recommendation}
      coach {
        ${CoachList.fragments.coach}
      }
    }
  }
`;

CoachList.DismissRecommendationMutation = gql`
  mutation DimissRecommendationMutation($recommendationID: String!) {
    dismissRecommendation(recommendationID: $recommendationID) {
      ${CoachList.fragments.recommendation}
      coach {
        ${CoachList.fragments.coach}
      }
    }
  }
`;

CoachList.RemindMeMutation = gql`
  mutation RemindMeMutation($coachId: String!) {
    remindMe(coachId: $coachId) {
      ${CoachList.fragments.reminder}
    }
  }
`;

CoachList.CoachReminderQuery = gql`
  query CoachReminderQuery($coachId: String!) {
    coachReminder(coachId: $coachId) {
      ${CoachList.fragments.reminder}
    }
  }
`;

CoachList.SaveMentor = gql`
  mutation SaveMentor($mentorId: String) {
    createSave(mentorId: $mentorId) {
      id
      status
    }
  }
`;

CoachList.UnsaveMentor = gql`
  mutation UnsaveMentor($mentorId: String) {
    deleteSave(mentorId: $mentorId) {
      id
      status
    }
  }
`;

export default CoachList;
