import React, { useState } from "react";

import { Button, Heading, Pane, Link, Strong, Tab, Tablist, Text, Textarea, toaster, majorScale } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const LINKEDIN = `LinkedIn`;
const TWITTER = `Twitter`;
const WEBSITE = `Website`;

const CopyButton = ({ copy, label }) => {
  const handler = (e) => {
    navigator.clipboard.writeText(copy);
    toaster.success(`Copied to clipboard!`, { duration: 2 });
  };

  return (
    <Button marginLeft={majorScale(1)} onClick={handler}>
      {label}
    </Button>
  );
};

const renderIconTitle = (icon, title) => {
  return (
    <Pane display="flex">
      <Text marginRight={majorScale(1)}>
        <FontAwesomeIcon icon={icon} />
      </Text>
      <Text>{title}</Text>
    </Pane>
  );
};

const renderInstallMeritSocial = (instructions, profileLink, setLinkCopied) => {
  return (
    <Pane isplay="flex" flexDirection="row" justifyContent="start" alignItems="start" paddingLeft={majorScale(1)}>
      {instructions ? <Pane paddingBottom={majorScale(2)}>{instructions}</Pane> : null}
      <Pane display="flex" flexDirection="row">
        <Textarea value={profileLink} disabled style={{ resize: "none" }} height={140} />
        <Pane minWidth={90}>
          <CopyButton copy={profileLink} label={`🔗 Copy`} />
        </Pane>
      </Pane>
    </Pane>
  );
};

const ShareProfile = ({ profileLink, myProfile }) => {
  const [tabs] = useState([LINKEDIN, TWITTER, WEBSITE]);
  const [selected, setSelected] = useState(0);

  const WEBSITEBLURB = `<p>I’m also a mentor on Merit where I coach diverse and under-represented talent in tech to help grow their careers. Book time with me <a href=${profileLink}> here</a>.</p>`;

  const linkedin = myProfile.linkedin;
  const linkedinUrl = linkedin + `/edit/add-link/`;

  const linkedInInstructions = (
    <Text>
      Add as a <Strong>Link</Strong> on your{" "}
      <Link target="_blank" href={linkedinUrl}>
        LinkedIn profile.
      </Link>
    </Text>
  );

  const twitterInstructions = (
    <Text>
      Add as your <Strong>Website</Strong> on your{" "}
      <Link target="_blank" href="https://twitter.com/settings/profile">
        Twitter profile
      </Link>
    </Text>
  );

  const websiteInstructions = <Text>Add your profile to your website.</Text>;

  const LINKEDINPANE = renderInstallMeritSocial(linkedInInstructions, profileLink);
  const TWITTERPANE = renderInstallMeritSocial(twitterInstructions, profileLink);
  const WEBSITEPANE = renderInstallMeritSocial(websiteInstructions, WEBSITEBLURB);

  const mappings = [
    { title: renderIconTitle(faLinkedinIn, LINKEDIN), pane: LINKEDINPANE },
    { title: renderIconTitle(faTwitter, TWITTER), pane: TWITTERPANE },
    { title: renderIconTitle(faExternalLinkAlt, WEBSITE), pane: WEBSITEPANE }
  ];

  return (
    <Pane marginTop={majorScale(3)}>
      <Tablist>
        {tabs.map((tab, index) => (
          <Tab key={tab} id={tab} onSelect={() => setSelected(index)} isSelected={index === selected} aria-controls={`panel-${tab}`}>
            {mappings[index].title}
          </Tab>
        ))}
      </Tablist>
      <Pane marginTop={majorScale(2)}>
        {tabs.map((tab, index) => (
          <Pane
            key={tab}
            id={`panel-${tab}`}
            role="tabpanel"
            aria-labelledby={tab}
            aria-hidden={index !== selected}
            display={index === selected ? "block" : "none"}
          >
            {mappings[index].pane}
          </Pane>
        ))}
      </Pane>
    </Pane>
  );
};

export default ShareProfile;
