import _ from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Card,
  EditIcon,
  Heading,
  IconButton,
  Link,
  Menu,
  MoreIcon,
  Pane,
  Paragraph,
  Pill,
  Popover,
  Position,
  Text,
  TrashIcon,
  Small,
  Strong,
  UnorderedList,
  ListItem,
  majorScale,
  toaster,
  OrderedList
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import ProfilePreview from "../feed/ProfilePreview";
import ReplyDelete from "./ReplyDelete";

const replyActions = (replyProps) => {
  const { showDelete, setShowDelete, reply, onDelete, commentId, postId, setShowEdit } = replyProps;

  const menu = (
    <Menu>
      <Menu.Group>
        <Menu.Item icon={EditIcon} onClick={() => setShowEdit(true)}>
          Edit
        </Menu.Item>
        <Menu.Item item={TrashIcon} intent="danger" onClick={() => setShowDelete(true)}>
          {showDelete ? <ReplyDelete reply={reply} onDelete={onDelete} commentId={commentId} postId={postId} /> : null}
          Delete
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );

  return (
    <Popover postition={Position.BOTTOM_LEFT} content={menu}>
      <IconButton icon={MoreIcon} appearance="minimal" />
    </Popover>
  );
};

const isReplyOwner = ({ myProfile, reply, postId }) => {
  return myProfile?.id === reply?.owner?.id;
};

const Reply = (replyProps) => {
  const { reply } = replyProps;
  const [titleHover, setTitleHover] = useState(false);

  if (!reply || !reply.owner) return null;

  return (
    <Pane
      marginLeft={majorScale(1)}
      display="flex"
      flexDirection="column"
      width="100%"
      background="blueTint"
      padding={majorScale(1)}
      borderRadius={5}
    >
      <Pane display="flex" flexDirection="row" width="100%" alignItems="center" justifyContent="space-between">
        <Pane>
          <Link
            is={BrowserLink}
            to={`/p/${reply.owner?.slug}`}
            onMouseEnter={() => setTitleHover(true)}
            onMouseLeave={() => setTitleHover(false)}
            textDecoration={titleHover ? "underline" : "none"}
            target="_blank"
            onClick={() => window.analytics.track("Reply -> View Profile")}
          >
            <Strong>{reply.owner?.fullName}</Strong>
          </Link>
          {reply.owner?.coach && (
            <Pill marginLeft={majorScale(1)} color="blue">
              Mentor
            </Pill>
          )}
        </Pane>
        <Pane>{isReplyOwner(replyProps) ? replyActions(replyProps) : null}</Pane>
      </Pane>
      <ReactMarkdown
        components={{
          p: Paragraph,
          ul: UnorderedList,
          li: ListItem,
          ol: OrderedList
        }}
      >
        {reply.details}
      </ReactMarkdown>
    </Pane>
  );
};

export default Reply;
