import _ from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import { Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { useQuery } from "@apollo/client";
import { Alert, Dialog, Paragraph, Pane, majorScale, defaultTheme as theme } from "evergreen-ui";

import ProfileList from "../../queries/profile/ProfileList";
import Navigation from "../navigation/Navigation";
import NewNavigation from "../navigation/NewNavigation";
import ActivateNotif from "../ActivateNotif";
import Footer from "../navigation/Footer";
import Loader from "../Loader";
import SubNav from "../navigation/SubNav";
import ShareProfile from "../ShareProfile";
import ScrollToTop from "../ScrollToTop";

const styles = {
  root: {
    width: "100%",
    height: "100%",
    // backgroundColor: 'white',
    alignItems: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.gray100
  }
};

const publicLinks = [
  { link: "/", title: "Find a mentor" },
  { link: "/join-mentors", title: "Be a mentor" },
  { link: "/hire", title: "Hire talent" },
  { link: "/about", title: "Our story" },
  { link: "/login", title: "Login" }
];

const LoadingPage = ({ logged, items, state, className, location }) => (
  <Container fluid className={className}>
    <Navigation location={location} items={items} logged={false} headerState={state} />
    <Row>
      <Loader />
    </Row>
  </Container>
);

const ShowActivatedDialog = ({ myProfile, isShown, setShown }) => {
  return (
    <Dialog
      isShown={isShown}
      title="🎉 Congrats! Your mentor profile is live!"
      hasCancel={false}
      confirmLabel="Completed"
      onCancel={() => setShown(false)}
      onConfirm={() => setShown(false)}
    >
      <Pane display="flex" flexDirection="column">
        <Paragraph marginBottom={majorScale(3)}>
          Thanks for activating your profile! Members can now book time with you on your calendar. You can always update your profile,
          topics, and calendar later.
        </Paragraph>
        <Paragraph>
          If you want to receive mentorship sessions sooner, add Merit to your social profiles so that others know how to connect with you!
        </Paragraph>
      </Pane>
      <ShareProfile myProfile={myProfile} profileLink={`${window.location.href}/p/${myProfile?.slug}`} />
    </Dialog>
  );
};

const ErrorPage = ({ logged, items, state, className, errorMessage, location }) => (
  <Container fluid className={className}>
    <Navigation location={location} items={items} logged={false} headerState={state} />
    <Row>
      <Col lg={6} md={8} sm={10} offset={{ lg: 3, md: 2 }}>
        <Pane display="flex" justifyContent="center" padding={majorScale(12)}>
          <Alert intent="danger" title={errorMessage} />
        </Pane>
      </Col>
    </Row>
  </Container>
);

const Layout = ({ children, state, privateRoute, location, options, subState, reviewRoute, hideNav, noGutter, hideFooter }) => {
  const [showActivate, setShowActivate] = useState(false);
  const { data, loading, error } = useQuery(ProfileList.LayoutQuery);

  const token = Cookies.get("token");
  let logged = false;
  let headerLinks = publicLinks;

  let content = null;

  const width = {
    xs: 375,
    sm: 540,
    md: 740,
    lg: 960,
    xl: 1140,
    xxl: 1140
  };

  if (loading) {
    content = (
      <LoadingPage location={location} logged={token ? true : false} items={publicLinks} state={state} style={{ ...styles.root }} />
    );
  }

  if (error) {
    content = (
      <ErrorPage
        logged={token ? true : false}
        items={publicLinks}
        state={state}
        location={location}
        errorMessage={error && error.message ? error.message : "There was an error loading this page. Please refresh in a few moments"}
        style={{ ...styles.root }}
      />
    );
  }

  if (data) {
    const { myProfile, myCoachingProfile, myCandidateProfile, missingRecommendations } = data;
    let isActiveCandidate;

    if (token) {
      logged = true;

      // Segment Tracking
      if (window && window.analytics && myProfile) {
        window.analytics.identify(myProfile.id, {
          name: myProfile.fullName
        });
      }

      const profileUrl = myProfile && myProfile.slug ? `/p/${myProfile.slug}` : "/profile/view";
      const leaderSetup = myCoachingProfile && myCoachingProfile.id ? { link: "/mentor/setup", title: "Setup" } : null;
      const missingActivate = myCoachingProfile && _.isEqual(myCoachingProfile?.status, "Accepted") ? true : null;
      const questions = myCoachingProfile && _.isEqual(myCoachingProfile?.status, "Active") ? { link: "/feed", title: "Questions" } : null;
      isActiveCandidate = myCandidateProfile && ["active", "passive"].includes(myCandidateProfile?.status) ? true : false;

      const bestPractices = myCoachingProfile
        ? { link: "/guide/mentor", title: "Best Practices" }
        : { link: "/guide/member", title: "Best Practices" };

      const privateLinks = [
        { link: "/sessions", title: "Sessions" },
        { link: "/messages", title: "Messages" },
        { link: "/topics/all", title: "Topics" },
        { link: "/leaders/all", title: "Leaders" },
        questions,
        { link: "/jobs/all", title: "Jobs" },
        { link: profileUrl, title: "My Profile" },
        bestPractices,
        { link: "/share/nav", title: "Share Merit" },
        //{ link: '/nominate', title: 'Nominate a mentor' },
        { link: "/settings", title: "Settings" },
        leaderSetup,
        { link: "/logout", title: "Logout" }
      ];

      headerLinks = _.reject(privateLinks, _.isNull);
    }

    if (!token && privateRoute) {
      return <Redirect to={`/login?origin=${location.pathname}`} />;
    }

    if (missingRecommendations?.length > 0 && !reviewRoute) {
      if (_.isEqual(missingRecommendations?.length, 1)) {
        return <Redirect to={`/recommend/${missingRecommendations?.[0]?.id}?missingRecommendation=true`} />;
      } else {
        return <Redirect to={`/connections?missingRecommendation=true`} />;
      }
    }

    if (myProfile?.applicationStatus.includes("Waitlist")) {
      if (
        !_.isEqual(location.pathname, "/") &&
        !_.isEqual(location.pathname, "/join-mentors") &&
        !_.isEqual(location.pathname, "/waitlist")
      ) {
        return <Redirect to={`/waitlist`} />;
      }
    }

    if (token && _.isEqual(location.pathname, "/")) {
      if (myProfile?.mode?.includes("Recruit")) {
        return <Redirect to={`/candidates/all`} />;
      } else
        return myCoachingProfile &&
          (_.isEqual(myCoachingProfile?.status, "Active") ||
            _.isEqual(myCoachingProfile?.status, "Pending") ||
            _.isEqual(myCoachingProfile?.status, "Inactive")) ? (
          <Redirect to={`/dashboard`} />
        ) : (
          <Redirect to={`/discover`} />
        );
    }

    let navigation = logged ? (
      <NewNavigation
        location={location}
        logged={logged}
        myProfile={myProfile}
        isActiveCandidate={isActiveCandidate}
        isCoach={
          myCoachingProfile &&
          (_.isEqual(myCoachingProfile?.status, "Active") ||
            _.isEqual(myCoachingProfile?.status, "Pending") ||
            _.isEqual(myCoachingProfile?.status, "Inactive"))
        }
        isRecruiter={myProfile?.mode?.includes("Recruit")}
      />
    ) : (
      <Navigation location={location} items={headerLinks} logged={logged} headerState={state} />
    );

    content = (
      <Container
        fluid
        style={{
          ...styles.root,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <ActivateNotif
          myCoachingProfile={myCoachingProfile}
          myProfile={myProfile}
          location={location}
          onActivate={() => setShowActivate(true)}
        />

        {showActivate && <ShowActivatedDialog myProfile={myProfile} isShown={showActivate} setShown={setShowActivate} />}

        {!hideNav && navigation}
        {options && options.subheader ? <SubNav tabs={options.subStates} subState={subState} /> : null}
        <ScreenClassRender
          render={(screenClass) => (
            <Row
              justify={screenClass.includes("xs") ? "center" : "flex-start"}
              justifyContent="center"
              style={{
                maxWidth: 1140,
                width: width[screenClass],
                minHeight: "calc(100vh - 120px)",
                paddingBottom: majorScale(4),
                // flexDirection: "column", // or column - that's the question
                // flexDirection: screenClass?.includes("xs") ? "column" : "row",
                flexDirection: "row",
                backgroundColor: theme.colors.gray100,
                marginTop: screenClass.includes("xs") && !logged ? majorScale(6) : null
              }}
            >
              <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
              </Helmet>
              {children}
            </Row>
          )}
        />
        {!hideFooter && <Footer />}
      </Container>
    );
  }

  return <ScrollToTop>{content}</ScrollToTop>;
};

export default withRouter(Layout);
