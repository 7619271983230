import React from "react";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

import PostDetail from "./PostDetail";
import FeedPage from "./FeedPage";
import PostUpdate from "./PostUpdate";

export default withRoutes(Layout, [
  {
    path: "/post/edit/:id",
    component: PostUpdate,
    state: "Questions"
  },
  {
    path: "/post/:id",
    component: PostDetail,
    state: "Questions"
  },
  {
    path: "/feed",
    component: FeedPage,
    state: "Questions"
  }
]);
