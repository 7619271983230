import _ from "lodash";
import React, { useState } from "react";
import queryString from "query-string";
import { Col, Row, Container } from "react-grid-system";
import { Query, useQuery } from "@apollo/client";
import { Alert, Heading, Pane, majorScale, minorScale } from "evergreen-ui";

import PlaylistQueries from "../../queries/playlist";
import CustomizeForm from "../playlist/CustomizeForm";
import TopicIndexContainer from "../../components/topic/TopicIndexContainer";
import TopicFilters from "../../components/topic/TopicFilters";
import TopicHeader from "../../components/topic/TopicHeader";
import DiscussionAbout from "../../components/discussion/DiscussionAbout";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../components/Loader";
import Meta from "./TopicMeta";

const getArray = (item) => (item && _.isString(item) ? [item] : item);

const initialQuery = ({ search }) => {
  const { name, levels, roles, motivations, cursor, hello, question, sort } = queryString.parse(search, { arrayFormat: "comma" });

  return {
    cursor: cursor ? cursor : "1",
    name: name ? name : "",
    levels: levels ? getArray(levels) : [],
    roles: roles ? getArray(roles) : [],
    motivations: motivations ? getArray(motivations) : [],
    sort: sort ? sort : "DEFAULT"
  };
};

const TopicIndexPage = ({ location, history }) => {
  const [query, setQuery] = useState(initialQuery(location));

  const { loading, error, data, fetchMore, refetch } = useQuery(PlaylistQueries.TopicSearchQuery, { variables: query });

  let topicSearch, topicFilters, updateSearch, next;

  if (data?.topicSearch) {
    topicSearch = data.topicSearch;
    topicFilters = data.topicFilters;

    updateSearch = (updated) => {
      setQuery(updated);
      refetch({ variables: { ...updated } });

      history.push({
        location: location.pathname,
        search: queryString.stringify(updated, {
          arrayFormat: "comma"
        })
      });
    };

    next = () =>
      fetchMore({
        query: PlaylistQueries.TopicSearchQuery,
        variables: {
          ...query,
          cursor: topicSearch?.pageInfo?.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            topicSearch: {
              __typename: fetchMoreResult.topicSearch.__typename,
              pageInfo: fetchMoreResult.topicSearch.pageInfo,
              edges: [...prev.topicSearch.edges, ...fetchMoreResult.topicSearch.edges]
            }
          });
        }
      });
  }

  return (
    <>
      <Breadcrumb history={history} />
      {topicSearch?.edges && <Meta topics={topicSearch?.edges} topicCount={topicSearch.pageInfo?.edgeCount} />}
      <Container>
        <Row>
          <Col xs={12} sm={8} md={8} lg={8}>
            <Row>
              <Col>
                <Pane>
                  <Heading is="h1" size={600} marginBottom={minorScale(3)}>
                    Choose a topic to talk about
                  </Heading>
                </Pane>
              </Col>
            </Row>
            {topicSearch?.edges && <TopicFilters updateSearch={updateSearch} query={query} topicFilters={topicFilters} />}
            {!loading && topicSearch?.edges && (
              <>
                <TopicIndexContainer loading={loading} topics={topicSearch} next={next} />
              </>
            )}
            {loading && <Loader />}
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <DiscussionAbout />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TopicIndexPage;
