import _ from "lodash";
import Moment from "moment";
import { eachMonthOfInterval, eachYearOfInterval, endOfYear, format, startOfYear } from "date-fns";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  Button,
  Pane,
  majorScale,
  Heading,
  Paragraph,
  Switch,
  TrashIcon,
  SelectMenu,
  CaretDownIcon,
  CrossIcon,
  IconButton
} from "evergreen-ui";
import AsyncSelect from "react-select/async";
import { isValid, fromUnixTime } from "date-fns";

import TextInput from "../../components/form/TextInput";
import ProfileQuery from "../../components/profile/ProfileQuery";
import ProfileList from "../../queries/profile/ProfileList";
import { clearbitOrgOptions, createOption } from "../../components/profile/AutoFill";
import CandidateQueries from "../../queries/candidate";
import TextArea from "../form/TextArea";

const RoleSchema = Yup.object().shape({
  title: Yup.string().min(1, "Please include your title").max(140, "Please shorten the title you put in")
});

const safeDate = (date) => (isValid(date) ? date : fromUnixTime(Number(date) / 1000));

const getMonthOptions = () => {
  const months = eachMonthOfInterval({
    start: startOfYear(new Date()),
    end: endOfYear(new Date())
  }).map((m) => format(m, "MMM"));

  return months.map((label) => ({ label, value: label }));
};

const getYearOptions = () => {
  const years = eachYearOfInterval({
    start: new Date(2000, 0, 1),
    end: new Date(new Date().getFullYear(), 0, 1)
  }).map((y) => format(y, "yyyy"));

  return _.reverse(years.map((label) => ({ label, value: label })));
};

const getMonth = (date) => {
  if (_.isString(date) && date?.length > 0) {
    date = new Date(date);
  }
  if (!date || _.isNaN(Number(date))) return null;
  date = fromUnixTime(Number(date) / 1000);

  return format(date, "MMM");
};

const getYear = (date) => {
  if (_.isString(date) && date?.length > 0) {
    date = new Date(date);
  }
  if (!date || _.isNaN(Number(date))) return null;
  date = fromUnixTime(Number(date) / 1000);

  return format(date, "yyyy");
};

const ExperienceDatesField = ({ initialDate, onChange, isDisabled }) => {
  initialDate = safeDate(initialDate);

  const [month, setMonth] = useState(getMonth(initialDate));
  const [year, setYear] = useState(getYear(initialDate));

  const monthOptions = getMonthOptions();
  const yearOptions = getYearOptions();

  const updateDate = ({ month, year }) => {
    if (_.isNull(month) && _.isNull(year)) {
      onChange(null);
    } else {
      if (year && month && _.isFunction(onChange)) {
        onChange(new Date(`${year}-${month}-01`));
      }
    }
  };

  return (
    <Pane>
      {(month || year) && (
        <IconButton
          marginRight={majorScale(1)}
          appearance="minimal"
          icon={CrossIcon}
          onClick={() => {
            setMonth(null);
            setYear(null);
            updateDate({ month: null, year: null });
          }}
        />
      )}
      <SelectMenu
        title="Seleect month"
        options={monthOptions}
        hasFilter={false}
        selected={month}
        onSelect={(item) => {
          setMonth(item?.value);
          updateDate({ month: item?.value, year });
        }}
      >
        <Button disabled={isDisabled} type="button" iconAfter={CaretDownIcon} width={majorScale(16)} marginRight={majorScale(1)}>
          {month || "Month"}
        </Button>
      </SelectMenu>
      <SelectMenu
        title="Select year"
        options={yearOptions}
        hasFilter={false}
        selected={year}
        onSelect={(item) => {
          setYear(item?.value);
          updateDate({ year: item?.value, month });
        }}
      >
        <Button disabled={isDisabled} type="button" iconAfter={CaretDownIcon} width={majorScale(16)}>
          {year || "Year"}
        </Button>
      </SelectMenu>
    </Pane>
  );
};

const WorkExperienceForm = (formProps) => {
  const { role, myProfile, onDelete, onMove, items, position, hideDelete, industryOptions, companySizeOptions } = formProps;

  const query = role?.id ? ProfileList.UpdateRole : ProfileList.CreateRole;

  const [updateRole, { loading, error }] = useMutation(query, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery },
      { query: ProfileQuery, variables: { slug: myProfile?.slug } }
    ]
  });

  const selectedTeam = role && role.team && createOption(role.team.name, role.team.logo, role.team.website);

  return (
    <Formik
      validationSchema={RoleSchema}
      initialValues={{
        roleID: role?.id ? role.id : "",
        teamName: role?.team?.name ? role.team.name : "",
        teamID: role?.team?.id ? role.team.id : "",
        title: role?.title ? role.title : "",
        description: role?.description ? role.description : "",
        teamLogo: role?.team?.logo ? role.team.logo : "",
        teamDomain: role?.team?.website ? role.team.website : "",
        currentRole: role?.id?.length > 0 ? role.currentRole : false,

        companyIndustry: role?.companyIndustry ? role.companyIndustry : "",
        companySize: role?.companySize ? role.companySize : "",

        startDate: role?.startDate ? role.startDate : null,
        endDate: role?.endDate ? role.endDate : null
      }}
      onSubmit={(variables, { setSubmitting, setValues }) => {
        updateRole({ variables });
        setSubmitting(false);
        setValues({ ...variables });
        window.analytics.track("Update Work Experience");
      }}
      render={({ setFieldValue, submitForm, values }) => (
        <Form>
          <Pane display="flex" flexDirection="column" width="100%">
            <Pane
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={majorScale(2)}
            >
              <Paragraph size={200}>Company</Paragraph>
              <AsyncSelect
                cacheOptions
                defaultValue={selectedTeam}
                onChange={(selected) => {
                  const { domain, logo, name } = selected.value;

                  setFieldValue("teamName", name, false);
                  setFieldValue("teamLogo", logo, false);
                  setFieldValue("teamDomain", domain, false);
                  setFieldValue("teamID", null, false);

                  submitForm();
                }}
                loadOptions={clearbitOrgOptions}
                defaultOptions
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    minWidth: 320
                  })
                }}
              />
            </Pane>
            <Pane
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={majorScale(2)}
            >
              <Paragraph size={200}>Title</Paragraph>
              <Field
                type="text"
                name="title"
                placeholder="Title"
                component={TextInput}
                height={28}
                width={320}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("title", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>

            <Pane
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={majorScale(2)}
            >
              <Paragraph size={200}>Size</Paragraph>
              <SelectMenu
                title="Choose company size"
                options={companySizeOptions}
                selected={values?.companySize}
                onSelect={(item) => {
                  setFieldValue("companySize", item?.value, false);
                  submitForm();
                }}
              >
                <Button minWidth={majorScale(16)} iconAfter={CaretDownIcon} type="button">
                  {values?.companySize || "Size"}
                </Button>
              </SelectMenu>
            </Pane>

            <Pane
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={majorScale(2)}
            >
              <Paragraph size={200}>Industry</Paragraph>
              <SelectMenu
                title="Choose industry"
                options={industryOptions}
                selected={values?.companyIndustry}
                onSelect={(item) => {
                  setFieldValue("companyIndustry", item?.value, false);
                  submitForm();
                }}
              >
                <Button minWidth={majorScale(16)} iconAfter={CaretDownIcon} type="button">
                  {values?.companyIndustry || "Industry"}
                </Button>
              </SelectMenu>
            </Pane>

            <Pane
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              marginBottom={majorScale(2)}
              height={28}
              justifyContent="space-between"
            >
              <Paragraph size={200}>Start date</Paragraph>
              <ExperienceDatesField
                initialDate={values.startDate}
                onChange={(date) => {
                  setFieldValue("startDate", safeDate(date), false);
                  submitForm();
                }}
              />
            </Pane>

            <Pane display="flex" flexDirection="row" width="100%" alignItems="center" marginBottom={majorScale(2)} height={28}>
              <Switch
                onChange={(e) => {
                  setFieldValue("currentRole", e.target.checked, false);

                  if (e.target.checked) {
                    setFieldValue("endDate", null, false);
                  }

                  submitForm();
                }}
                checked={values?.currentRole}
                marginRight={majorScale(1)}
              />
              <Paragraph size={200}>I am currently working in this role</Paragraph>
            </Pane>

            {!values?.currentRole && (
              <Pane
                display="flex"
                flexDirection="row"
                width="100%"
                alignItems="center"
                marginBottom={majorScale(2)}
                height={28}
                justifyContent="space-between"
              >
                <Paragraph size={200}>End date</Paragraph>
                <ExperienceDatesField
                  isDisabled={values?.isCurrentRole}
                  initialDate={values.endDate}
                  onChange={(date) => {
                    setFieldValue("endDate", safeDate(date), false);
                    submitForm();
                  }}
                />
              </Pane>
            )}

            <Pane
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
              marginBottom={majorScale(2)}
              justifyContent="flex-start"
            >
              <Pane width="100%">
                <Paragraph size={200} marginBottom={majorScale(2)}>
                  What did you accomplish in this role?
                </Paragraph>
              </Pane>
              <Field
                type="text"
                name="description"
                width={496}
                placeholder={
                  "Led major team milestone that ensured the fidelity of all active wheels and reduced incidents caused by manual access to artifact store to zero by automating the upload of wheels to production Artifactory, impacting 600+ eng and non-technical customers...."
                }
                component={TextArea}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("description", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>

            <Pane width="100%" display="flex" justifyContent="space-between">
              {items?.length > 1 && (
                <Pane>
                  {!loading && position !== 0 && (
                    <Button iconBefore={ArrowUpIcon} appearance="minimal" onClick={() => onMove("up")}>
                      Move up
                    </Button>
                  )}
                  {!loading && position !== items.length - 1 && (
                    <Button iconBefore={ArrowDownIcon} appearance="minimal" onClick={() => onMove("down")}>
                      Move down
                    </Button>
                  )}
                </Pane>
              )}
              {!loading && !hideDelete && (
                <Button isLoading={loading} iconBefore={TrashIcon} appearance="minimal" intent="danger" onClick={() => onDelete()}>
                  Delete
                </Button>
              )}
              {loading && (
                <Button isLoading={loading} appearance="minimal">
                  Updating
                </Button>
              )}
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default WorkExperienceForm;
