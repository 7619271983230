import React, { useState } from "react";
import { Pane, Heading, Text, Card, Tablist, Tab, majorScale, minorScale } from "evergreen-ui";

import Uploader from "../../../components/Uploader";

const PICKER = {
  GALLERY: "Gallery",
  UPLOAD: "Upload"
};

const COLORS = {
  PINK: "https://ucarecdn.com/e9992030-ba54-418b-a6d8-beed92e2904e/",
  YELLOW: "https://ucarecdn.com/13e84758-d0a2-441a-a6b0-dbed979b90e7/",
  RED: "https://ucarecdn.com/c207c2fd-6f33-4fe5-acc5-bb902e64f95c/",
  BLUE: "https://ucarecdn.com/a4bebc3f-dad8-4b50-ae03-cea5c01b5481/"
};

const PREVIEWS = [COLORS.PINK, COLORS.YELLOW, COLORS.RED, COLORS.BLUE];

const Patch = ({ onClick, preview }) => {
  return (
    <Pane
      cursor="pointer"
      borderRadius="2px"
      width="50px"
      height="50px"
      marginRight="4px"
      marginBottom="4px"
      backgroundSize="cover"
      background={`url(${preview})`}
      onClick={() => onClick(preview)}
    />
  );
};

const GalleryPicker = ({ onChange }) => {
  return (
    <Pane paddingY={majorScale(1)} paddingX={majorScale(2)}>
      <Heading is="h5" fontSize="12px" fontWeight="bold" textTransform="uppercase" marginBottom={majorScale(1)} color="#696F8B">
        Colors
      </Heading>
      <Pane display="flex" flexWrap="wrap" marginBottom={majorScale(2)}>
        {PREVIEWS.map((image) => (
          <Patch key={image} onClick={onChange} preview={image} />
        ))}
      </Pane>
    </Pane>
  );
};

const UploadPicker = ({ onChange }) => {
  return (
    <Pane paddingY={majorScale(4)} paddingX={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
      <Uploader onUploadComplete={(file) => onChange(file.cdnUrl)} />
      <Text fontSize="12px" marginTop={minorScale(3)}>
        Images wider than 1500 pixels work best.
      </Text>
    </Pane>
  );
};

const Picker = ({ onChange, setDisableClose }) => {
  const [tabSelected, setTabSelected] = useState(PICKER.GALLERY);
  const tabs = [PICKER.GALLERY, PICKER.UPLOAD];

  const handleTabSelected = (tab) => {
    setDisableClose(tab === PICKER.UPLOAD);
    setTabSelected(tab);
  };

  const handleChange = (file) => {
    setDisableClose(false);
    onChange(file);
  };

  return (
    <Card background="white" width={320}>
      <Tablist borderBottom="1px solid #EDEFF4" paddingX={majorScale(1)}>
        {tabs.map((tab) => (
          <Tab
            key={tab}
            isSelected={tabSelected === tab}
            onSelect={() => handleTabSelected(tab)}
            appearance="minimal"
            marginX={majorScale(1)}
            paddingY={majorScale(1)}
            fontSize="12px"
            color={tabSelected === tab ? "#3C6FFC" : "#696F8B"}
            borderBottom={tabSelected === tab ? "1px solid #3C6FFC" : "1px solid white"}
          >
            {tab}
          </Tab>
        ))}
      </Tablist>
      {tabSelected === PICKER.GALLERY && <GalleryPicker onChange={handleChange} />}
      {tabSelected === PICKER.UPLOAD && <UploadPicker onChange={handleChange} />}
    </Card>
  );
};

export default Picker;
