import _ from "lodash";
import React from "react";
import { Avatar, Button, Heading, Pane, StarIcon, Text, majorScale, defaultTheme as theme } from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { fromUnixTime } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";

const formatSessionDate = (date, timeZone) => {
  // Convert the date to UTC and then apply a proper timezone
  timeZone = timeZone?.length ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eee LLL do");
  const time = formatInTimeZone(zonedDate, timeZone, "hh:mm b");

  return `${day} at ${time}`;
};

const SessionPreview = ({ session, myProfile }) => {
  const {
    coach: { user: mentor },
    user
  } = session;
  const other = _.isEqual(myProfile?.id, user?.id) ? mentor : user;

  const title = other?.role?.team?.name;
  let role = other?.role?.title;
  role = title ? `${role} at ${title}` : role;

  return (
    <Pane
      display="flex"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      paddingX={majorScale(4)}
      paddingY={majorScale(4)}
      justifyContent="space-between"
      alignItems="center"
      marginBottom={majorScale(2)}
      backgroundColor="white"
    >
      <Pane display="flex">
        <Avatar src={other?.picture} name={other?.fullName} size={majorScale(7)} marginRight={majorScale(2)} />
        <Pane display="flex" flexDirection="column" justifyContent="center">
          <Heading>{other?.fullName}</Heading>
          <Text>{role}</Text>
          <Heading size={400} display="flex" flexDirection="row">
            {formatSessionDate(session?.date, myProfile?.timezone)}
          </Heading>
        </Pane>
      </Pane>
      <Button appearance="primary" iconBefore={StarIcon} is={BrowserLink} to={`/session/${session?.id}/feedback`}>
        Review Session
      </Button>
    </Pane>
  );
};

export default SessionPreview;
