import React from "react";
import { Col, ScreenClassRender } from "react-grid-system";
import { Pane, Paragraph, Heading, majorScale, defaultTheme as theme } from "evergreen-ui";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

import GuideSection from "./sections/GuideSection";
import BlogSection from "./sections/BlogSection";
import MembersSection from "./sections/MembersSection";
import ProfileList from "../../queries/profile/ProfileList";

const Header = ({ myProfile }) => (
  <Col
    xs={12}
    style={{
      paddingTop: majorScale(4),
      marginBottom: majorScale(4)
    }}
  >
    <Pane display="flex" flexDirection="column" width="100%">
      <Heading size={900} marginRight={majorScale(1)}>
        {myProfile?.name?.first ? `👋 Welcome back, ${myProfile?.name?.first}!` : `👋 Welcome back!`}
      </Heading>
      <Paragraph size={500}>Here are some ways to help others in tech today</Paragraph>
    </Pane>
  </Col>
);

const DashboardMeta = () => {
  const title = "Dashboard | Merit";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window.location.href} />
      <title>{title}</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="title" property="og:title" content={title} />
    </Helmet>
  );
};

const DashboardPage = () => {
  const { data: profileResult } = useQuery(ProfileList.MyCoachingProfile);

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <div style={{ flexDirection: "row", width: "100%" }}>
            <DashboardMeta />
            <Header myProfile={profileResult?.myProfile} />
            <MembersSection isMobile={isMobile} />
            <GuideSection />
            <BlogSection />
          </div>
        );
      }}
    />
  );
};

export default DashboardPage;
