import React from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Pane, majorScale, minorScale, defaultTheme } from "evergreen-ui";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import injectSheet from "react-jss/lib/injectSheet";

import theme from "../../components/theme";
import Testimonial from "../../components/profile/Testimonial";

import { benefitsRecruiters, SALES_URL } from "../../content/coaches";
import { getRecruited, recruitingTestimonial } from "../../content/learners";

// Splash section
// const headline = "Access to diverse & engaged candidates"
const headline = "Vetted & diverse tech talent";
const cta = "Contact us";

const HOW_IT_WORKS_OVERVIEW = "Let's change that";
const HOW_IT_WORKS_SUBTEXT = "Merit makes generating interviews plug and play";

const BENEFITS_OVERVIEW = "Merit is for high-performing teams";

// Community overview
const COMMUNITY_OVERVIEW = "Access to diverse & engaged candidates";
const COMMUNITY_TEAMS = "Our community works at these companies";
const COMMUNITY_DETAILS = [
  {
    title: "Diverse",
    stat: "70%",
    subtitle: `people of color`
  },
  {
    title: ``,
    stat: "62%",
    subtitle: `women & non-binary`
  },
  {
    title: "Technical",
    stat: "10K+",
    subtitle: "US & Canada based product managers, designers & software engineers"
  },
  {
    title: "Active",
    stat: "75%+",
    subtitle: "passively or actively looking for roles"
  }
];

const PRICING_OVERVIEW = "Pricing";
const PRICING_DETAILS = "Partner with us to connect with great talent, without placement or agency fees.";

const PricingOptions = [
  {
    title: "Try it out",
    price: "$5,000",
    description: "first 25 candidates interviewed"
  },
  {
    title: "Unlimited",
    price: "$5,000",
    description: "per seat per year"
  }
];

const WHY_MENTORSHIP_OVERVIEW = "Hiring is broken";
const WHY_MENTORSHIP_SUBTEXT = "Recruiting teams miss out on great talent";

const WHY_MENTORSHIP_DETAILS = [
  {
    title: "Recruiting teams rely on networking or inefficient tools",
    subtitle: "Spending too much time and money on inconsistent results.",
    image: "https://ucarecdn.com/630fd279-4da4-4743-9a8b-cfbc3218ed7c/HiringMatrix.png"
  },
  {
    title: "But candidates struggle to build a high-quality network",
    subtitle: "Teams miss out on great candidates because they never get a chance to connect.",
    image: "https://ucarecdn.com/c77ff203-7277-42ce-9d08-0e59b36c84e1/Netwokringishard2.png"
  },
  {
    title: "This contributes to difficulty diversifying hiring pipelines",
    subtitle: "While still spending too much time and money to get lackluster results.",
    image: "https://ucarecdn.com/3bd5c34b-64c5-4c7e-b805-f24b4ab81edb/DEIstats3.png"
  }
];

const BENEFITS_DETAILS = [
  {
    title: "Save 30 hours a month",
    subtitle: "When surveying time required to source, qualify, message, and book interviews with candidates.",
    image: "https://ucarecdn.com/2e614939-24e4-4dd8-bf58-98e188c85bd7/-/resize/x300/-/format/auto/-/quality/smart_retina/"
  },
  {
    title: "Cut traditional hiring costs by over 75%",
    subtitle: "When compared to 20% contingency fees for a technical hire",
    image: "https://ucarecdn.com/3bb1cdba-c8f9-4c9d-9376-b91bd51df076/-/resize/x300/-/format/auto/-/quality/smart_retina/"
  }
];

const LOGOS = [
  `https://ucarecdn.com/2c2c0456-d6a7-47a6-98dd-dd63efe3f01e`,
  `https://ucarecdn.com/689cba92-1194-44d1-9290-a31139512a96`,
  `https://ucarecdn.com/60ee6e60-55b5-4085-a28a-908aa2d1035e`,
  `https://ucarecdn.com/702ad8d5-3dba-4df0-a53e-ee8c92050b82`,
  `https://ucarecdn.com/5a92ee12-1f63-4dd1-8d54-c4eba218f900`,
  `https://ucarecdn.com/f9d32724-c9f4-4a16-9543-f04c9322b89d`,
  `https://ucarecdn.com/1281b1f4-cf55-4377-b46b-cefa35fdb539`,
  `https://ucarecdn.com/59b52b11-f626-4f6c-935b-17661bb9b084`,
  `https://ucarecdn.com/02a73b3f-c0bf-49bc-ac25-811c7ed4ba42`
];

const styles = {
  p: {
    ...theme.p,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  b: {
    ...theme.b,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  h1: {
    ...theme.h1,
    margin: 0,
    textAlign: "center"
  },
  h1Mobile: {
    ...theme.h1Mobile,
    textAlign: "center",
    margin: 0,
    paddingTop: majorScale(4)
  },
  h2: {
    ...theme.h2,
    textAlign: "left",
    marginBottom: majorScale(1),
    marginTop: majorScale(4),
    padding: 0
  },
  h2Mobile: {
    ...theme.h2Mobile,
    textAlign: "left",
    margin: 0,
    padding: 0
  },
  h3: {
    ...theme.h3,
    marginBottom: majorScale(1),
    padding: 0
  },
  h4: {
    ...theme.h4,
    marginBottom: majorScale(1),
    padding: 0
  },
  h6: {
    ...theme.h6,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  subtitle: {
    ...theme.b,
    textAlign: "center",
    paddingTop: majorScale(4),
    paddingBottom: majorScale(4)
  },
  subtitleMobile: {
    ...theme.p,
    textAlign: "center",
    paddingTop: majorScale(4),
    paddingBottom: majorScale(4)
  },
  testimonials: {
    paddingTop: theme.spacing.grid * 4,
    paddingBottom: theme.spacing.grid * 3
  },
  tweets: {
    background: theme.color.darkBackground,
    paddingRight: theme.spacing.grid,
    paddingLeft: theme.spacing.grid,
    paddingBottom: theme.spacing.grid * 4
  },
  paragraph: {
    ...theme.p
  },
  benefitDescription: {
    ...theme.p,
    margin: 0,
    padding: 0,
    textAlign: "left",
    paddingTop: majorScale(2)
  },
  fullWidth: {
    marginRight: -15,
    marginLeft: -15
  },
  image: {
    width: "100%",
    display: "block",
    marginLeft: "auto",
    maxWidth: 408
  }
};

const RequestDemo = () => (
  <Pane display="flex" alignItems="center" flexDirection="column" justifyContent="center" padding={majorScale(2)} margin={majorScale(2)}>
    <Button is="a" href={SALES_URL} appearance="primary" height={40} minWidth={125} borderRadius={0} textAlign="center">
      {cta}
    </Button>
  </Pane>
);

const SplashSection = ({ classes }) => (
  <Container>
    <Row align="start" justify="center">
      <Col md={10} xs={12}>
        <Pane
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding={majorScale(2)}
          margin={majorScale(2)}
        >
          <Hidden xs md>
            <h1 className={classes.h1}>{headline}</h1>
          </Hidden>
          <Visible xs md>
            <h1 className={classes.h1Mobile}>{headline}</h1>
          </Visible>
        </Pane>
        <RequestDemo />
      </Col>
    </Row>
  </Container>
);

const BenefitsSection = ({ classes }) => (
  <Container>
    <Row align="start" justify="center">
      <Col md={10} xs={12}>
        <Pane
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding={majorScale(2)}
          marginBottom={majorScale(6)}
        >
          <Hidden xs md>
            <h2 className={classes.h2}>{BENEFITS_OVERVIEW}</h2>
          </Hidden>
          <Visible xs md>
            <h2 className={classes.h2Mobile}>{BENEFITS_OVERVIEW}</h2>
          </Visible>
        </Pane>
      </Col>
    </Row>
    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-end" paddingRight={majorScale(3)}>
            <img src={BENEFITS_DETAILS[0].image} alt={BENEFITS_DETAILS[0].image} className={classes.image} />
          </Pane>
        </Col>
        <Col md={6}>
          <Pane justifyContent="flex-start" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{BENEFITS_DETAILS[0].title}</div>
            <div className={classes.benefitDescription}>{BENEFITS_DETAILS[0].subtitle}</div>
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{BENEFITS_DETAILS[0].title}</div>
            <div className={classes.benefitDescription}>{BENEFITS_DETAILS[0].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
            <img src={BENEFITS_DETAILS[0].image} className={classes.image} alt={BENEFITS_DETAILS[0].title} />
          </Pane>
        </Col>
      </Visible>
    </Row>
    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6} style={{ display: "flex" }}>
          <Pane justifyContent="flex-end" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{BENEFITS_DETAILS[1].title}</div>
            <div className={classes.benefitDescription}>{BENEFITS_DETAILS[1].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-start" marginLeft={majorScale(2)}>
            <img src={BENEFITS_DETAILS[1].image} alt={BENEFITS_DETAILS[1].image} className={classes.image} />
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{BENEFITS_DETAILS[1].title}</div>
            <div className={classes.benefitDescription}>{BENEFITS_DETAILS[1].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
            <img src={BENEFITS_DETAILS[1].image} className={classes.image} alt={BENEFITS_DETAILS[1].title} />
          </Pane>
        </Col>
      </Visible>
    </Row>
    <Row align="start" justify="center">
      <Col xs={12}>
        <Pane
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          padding={majorScale(2)}
          marginTop={majorScale(5)}
          marginBottom={majorScale(2)}
        >
          <Hidden xs md>
            <h2 className={classes.h2} style={{ marginTop: 0 }}>
              {COMMUNITY_OVERVIEW}
            </h2>
          </Hidden>
          <Visible xs md>
            <h2 className={classes.h2Mobile} style={{ textAlign: "center" }}>
              {COMMUNITY_OVERVIEW}
            </h2>
          </Visible>
        </Pane>
        <Visible xs md>
          <Pane width="100%" display="grid" gridTemplateColumns="1" gridTemplateRows="4" marginBottom={majorScale(2)}>
            {COMMUNITY_DETAILS.map(({ title, subtitle, stat }) => (
              <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" key={title}>
                <h3 className={classes.h3}>{title}</h3>
                <h2 className={classes.h2} style={{ margin: 0 }}>
                  {stat}
                </h2>
                <p className={classes.benefitDescription} style={{ paddingTop: 0, marginTop: 0, textAlign: "center" }}>
                  {subtitle}
                </p>
              </Pane>
            ))}
          </Pane>
        </Visible>
        <Hidden xs md>
          <Pane
            width="100%"
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            gridColumnGap={majorScale(1)}
            gridRowGap={majorScale(1)}
            marginBottom={majorScale(2)}
          >
            {COMMUNITY_DETAILS.map(({ title, subtitle, stat }) => (
              <Pane key={title}>
                {title?.length > 0 ? <h3 className={classes.h3}>{title}</h3> : <h3 className={classes.h3}>&nbsp;</h3>}
                <h2 className={classes.h2} style={{ marginTop: 0, marginBottom: majorScale(1) }}>
                  {stat}
                </h2>
                <p className={classes.benefitDescription} style={{ paddingTop: 0, marginTop: majorScale(1) }}>
                  {subtitle}
                </p>
              </Pane>
            ))}
          </Pane>
        </Hidden>
      </Col>
      <Col xs={12}>
        <Pane
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          padding={majorScale(2)}
          marginTop={majorScale(5)}
          marginBottom={majorScale(2)}
        >
          <Hidden xs md>
            <h2 className={classes.h2} style={{ marginTop: 0 }}>
              {COMMUNITY_TEAMS}
            </h2>
          </Hidden>
          <Visible xs md>
            <h2 className={classes.h2Mobile} style={{ textAlign: "center" }}>
              {COMMUNITY_TEAMS}
            </h2>
          </Visible>
        </Pane>
        <Visible xs md>
          <Pane width="100%" display="grid" placeItems="center" gridTemplateColumns="repeat(3, 1fr)" gridTemplateRows="repeat(3, 1fr)">
            {LOGOS.map((logo, index) => (
              <img src={`${logo}/-/resize/96x/logo${index + 1}.png`} key={`logos-${index}`} alt={`logos-${index}`} style={{ width: 48 }} />
            ))}
          </Pane>
        </Visible>
        <Hidden xs md>
          <Pane width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(5)}>
            {LOGOS.map((logo, index) => (
              <img src={`${logo}/-/resize/x48/logo${index + 1}.png`} key={`logos-${index}`} alt={`logos-${index}`} style={{ height: 24 }} />
            ))}
          </Pane>
        </Hidden>
      </Col>
    </Row>
  </Container>
);

const WhyMentorshipSection = ({ classes }) => (
  <Container>
    <Row align="start" justify="center">
      <Col md={10} xs={12}>
        <Pane
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding={majorScale(2)}
          margin={majorScale(2)}
        >
          <Hidden xs md>
            <h2 className={classes.h2}>{WHY_MENTORSHIP_OVERVIEW}</h2>
            <h3 className={classes.h3}>{WHY_MENTORSHIP_SUBTEXT}</h3>
          </Hidden>
          <Visible xs md>
            <h2 className={classes.h2Mobile}>{WHY_MENTORSHIP_OVERVIEW}</h2>
            <h3 className={classes.h3}>{WHY_MENTORSHIP_SUBTEXT}</h3>
          </Visible>
        </Pane>
      </Col>
    </Row>
    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-end" paddingRight={majorScale(3)}>
            <img src={WHY_MENTORSHIP_DETAILS[0].image} alt={WHY_MENTORSHIP_DETAILS[0].image} className={classes.image} />
          </Pane>
        </Col>
        <Col md={6}>
          <Pane justifyContent="flex-start" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{WHY_MENTORSHIP_DETAILS[0].title}</div>
            <div className={classes.benefitDescription}>{WHY_MENTORSHIP_DETAILS[0].subtitle}</div>
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{WHY_MENTORSHIP_DETAILS[0].title}</div>
            <div className={classes.benefitDescription}>{WHY_MENTORSHIP_DETAILS[0].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
            <img src={WHY_MENTORSHIP_DETAILS[0].image} className={classes.image} alt={WHY_MENTORSHIP_DETAILS[0].title} />
          </Pane>
        </Col>
      </Visible>
    </Row>
    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6} style={{ display: "flex" }}>
          <Pane justifyContent="flex-end" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{WHY_MENTORSHIP_DETAILS[1].title}</div>
            <div className={classes.benefitDescription}>{WHY_MENTORSHIP_DETAILS[1].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-start">
            <img src={WHY_MENTORSHIP_DETAILS[1].image} alt={WHY_MENTORSHIP_DETAILS[1].image} className={classes.image} />
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{WHY_MENTORSHIP_DETAILS[1].title}</div>
            <div className={classes.benefitDescription}>{WHY_MENTORSHIP_DETAILS[1].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
            <img src={WHY_MENTORSHIP_DETAILS[1].image} className={classes.image} alt={WHY_MENTORSHIP_DETAILS[1].title} />
          </Pane>
        </Col>
      </Visible>
    </Row>
    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-end" paddingRight={majorScale(3)}>
            <img src={WHY_MENTORSHIP_DETAILS[2].image} alt={WHY_MENTORSHIP_DETAILS[2].image} className={classes.image} />
          </Pane>
        </Col>
        <Col md={6}>
          <Pane justifyContent="flex-start" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{WHY_MENTORSHIP_DETAILS[2].title}</div>
            <div className={classes.benefitDescription}>{WHY_MENTORSHIP_DETAILS[2].subtitle}</div>
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{WHY_MENTORSHIP_DETAILS[2].title}</div>
            <div className={classes.benefitDescription}>{WHY_MENTORSHIP_DETAILS[2].subtitle}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
            <img src={WHY_MENTORSHIP_DETAILS[2].image} className={classes.image} alt={WHY_MENTORSHIP_DETAILS[2].title} />
          </Pane>
        </Col>
      </Visible>
    </Row>
  </Container>
);

const HowItWorksSection = ({ classes }) => (
  <Container>
    <Row align="start" justify="center">
      <Col md={10} xs={12}>
        <Pane
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          padding={majorScale(2)}
          marginBottom={majorScale(6)}
        >
          <Hidden xs md>
            <h2 className={classes.h2}>{HOW_IT_WORKS_OVERVIEW}</h2>
            <h3 className={classes.h3}>{HOW_IT_WORKS_SUBTEXT}</h3>
          </Hidden>
          <Visible xs md>
            <h2 className={classes.h2Mobile}>{HOW_IT_WORKS_OVERVIEW}</h2>
          </Visible>
        </Pane>
      </Col>
    </Row>
    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-end" paddingRight={majorScale(3)}>
            <img src={benefitsRecruiters[0].image} alt="We make it easy to make an impact" className={classes.image} />
          </Pane>
        </Col>
        <Col md={6}>
          <Pane justifyContent="flex-start" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{benefitsRecruiters[0].title}</div>
            <div className={classes.benefitDescription}>{benefitsRecruiters[0].description}</div>
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{benefitsRecruiters[0].title}</div>
            <div className={classes.benefitDescription}>{benefitsRecruiters[0].description}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
            <img src={benefitsRecruiters[0].image} className={classes.image} alt={benefitsRecruiters[0].title} />
          </Pane>
        </Col>
      </Visible>
    </Row>

    <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
      <Col md={6}>
        <Pane justifyContent="flex-end" padding={majorScale(2)} margin={majorScale(2)}>
          <Hidden xs md>
            <div className={classes.h2}>{benefitsRecruiters[1].title}</div>
          </Hidden>
          <Visible xs md>
            <div className={classes.h2Mobile}>{benefitsRecruiters[1].title}</div>
          </Visible>
          <div className={classes.benefitDescription}>{benefitsRecruiters[1].description}</div>
        </Pane>
      </Col>
      <Col md={6}>
        <Pane display="flex" alignItems="center" justifyContent="flex-start" marginLeft={majorScale(2)}>
          <img src={benefitsRecruiters[1].image} alt={benefitsRecruiters[1].title} className={classes.image} />
        </Pane>
      </Col>
    </Row>

    <Row align="center" justify="center" style={{ paddingBottom: majorScale(2) }}>
      <Hidden xs md>
        <Col md={6}>
          <Pane display="flex" alignItems="center" justifyContent="flex-end" paddingRight={majorScale(3)}>
            <img src={benefitsRecruiters[2].image} alt={benefitsRecruiters[2].title} className={classes.image} />
          </Pane>
        </Col>
        <Col md={6}>
          <Pane justifyContent="flex-start" padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2}>{benefitsRecruiters[2].title}</div>
            <div className={classes.benefitDescription}>{benefitsRecruiters[2].description}</div>
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col md={6}>
          <Pane padding={majorScale(2)} margin={majorScale(2)}>
            <div className={classes.h2Mobile}>{benefitsRecruiters[2].title}</div>
            <div className={classes.benefitDescription}>{benefitsRecruiters[2].description}</div>
          </Pane>
        </Col>
        <Col md={6}>
          <Pane
            backgroundColor="white"
            border={`1px solid ${defaultTheme.colors.gray500}`}
            borderRadius={majorScale(2)}
            padding={majorScale(2)}
            margin={majorScale(2)}
          >
            <img src={benefitsRecruiters[2].image} alt={benefitsRecruiters[2].title} className={classes.image} />
          </Pane>
        </Col>
      </Visible>
    </Row>
  </Container>
);

const PricingSection = ({ classes }) => (
  <Container>
    <Row align="start" justify="center">
      <Col md={10} xs={12}>
        <Pane display="flex" alignItems="center" flexDirection="column" padding={majorScale(2)} margin={majorScale(2)} textAlign="center">
          <Hidden xs md>
            <h2 className={classes.h2} style={{ textAlign: "center" }}>
              {PRICING_OVERVIEW}
            </h2>
            <div className={classes.subtitle}>{PRICING_DETAILS}</div>
          </Hidden>
          <Visible xs md>
            <h2 className={classes.h2Mobile} style={{ textAlign: "center" }}>
              {PRICING_OVERVIEW}
            </h2>
            <div className={classes.subtitleMobile} style={{ padding: 0, marginTop: majorScale(1) }}>
              {PRICING_DETAILS}
            </div>
          </Visible>
        </Pane>
      </Col>
    </Row>
    {/* <Row
      align='center'
      justify='center'
      style={{ paddingBottom: majorScale(6) }}>
      <Hidden xs md>
        <Col md={6} style={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <Pane>
            <div className={classes.h3}>
              {PricingOptions[0].title}
            </div>
            <div className={classes.h2}>
              {PricingOptions[0].price}
            </div>
            <div className={classes.paragraph}>
              {PricingOptions[0].description}
            </div>
          </Pane>
        </Col>
        <Col md={6} style={{
          display: "flex",
          justifyContent: "flex-start"
        }}>
          <Pane>
            <div className={classes.h3}>
              {PricingOptions[1].title}
            </div>
            <div className={classes.h2}>
              {PricingOptions[1].price}
            </div>
            <div className={classes.paragraph}>
              {PricingOptions[1].description}
            </div>
          </Pane>
        </Col>
      </Hidden>
      <Visible xs md>
        <Col>
          <Pane
            display="flex"
            flexDirection="column"
            textAlign="center"
            marginBottom={majorScale(4)}>
            <div className={classes.h3} style={{ marginBottom: majorScale(1) }}>
              {PricingOptions[0].title}
            </div>
            <div className={classes.h2} style={{ marginTop: 0, textAlign: "center" }}>
              {PricingOptions[0].price}
            </div>
            <div className={classes.paragraph}>
              {PricingOptions[0].description}
            </div>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            textAlign="center">
            <div className={classes.h3} style={{ marginBottom: majorScale(1) }}>
              {PricingOptions[1].title}
            </div>
            <div className={classes.h2} style={{ marginTop: 0, textAlign: "center" }}>
              {PricingOptions[1].price}
            </div>
            <div className={classes.paragraph}>
              {PricingOptions[1].description}
            </div>
          </Pane>
        </Col>
      </Visible>
    </Row> */}

    <Row>
      <Col md={12}>
        <RequestDemo />
      </Col>
    </Row>
  </Container>
);

const ScoutPage = ({ classes }) => {
  return (
    <div>
      <link href={theme.fonts} rel="stylesheet" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Diverse tech talent at all levels | Merit</title>
      </Helmet>
      <div className={classes.fullWidth}>
        <SplashSection classes={classes} />
        <WhyMentorshipSection classes={classes} />
        <HowItWorksSection classes={classes} />
        <BenefitsSection classes={classes} />
        <PricingSection classes={classes} />
      </div>
    </div>
  );
};

export default injectSheet(styles)(ScoutPage);
