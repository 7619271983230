import _ from "lodash";
import React, { useState } from "react";
import queryString from "query-string";
import { Col, Row, Container } from "react-grid-system";
import { Query, useQuery } from "@apollo/client";
import { Alert, Heading, Menu, Pane, majorScale } from "evergreen-ui";

import FeedQueries from "../../queries/feed";

import Loader from "../../components/Loader";
import PostIndex from "../../components/post/PostIndex";
import PostCreate from "../../components/post/PostCreate";
import BackButton from "../../components/button/BackButton";

const ERROR_MSG = "Could not load this page. Please try again in a few moments.";

const leftPanel = ({ history }) => {
  return history?.length > 2 ? (
    <Pane display="flex" alignItems="start" flexDirection="column" padding={majorScale(2)}>
      <BackButton
        marginBottom={majorScale(2)}
        onClick={() => {
          history.goBack();
        }}
      />
    </Pane>
  ) : null;
};

const postTopics = ({ postTopics }, { query, updateFeed }) => {
  return postTopics && postTopics.length ? (
    <Menu>
      <Menu.OptionsGroup
        title="Browse by Topic"
        selected={query.topic}
        options={postTopics.map(({ topic, id }) => ({
          label: `${topic.name}`,
          value: id
        }))}
        onChange={updateFeed}
      />
    </Menu>
  ) : null;
};

const initialQuery = (props) => {
  const {
    location: { search }
  } = props;
  const { cursor, topic } = queryString.parse(search, { arrayFormat: "comma" });

  return {
    cursor: cursor ? cursor : "1",
    topic: topic ? topic : undefined
  };
};

const renderFeedUrl = ({ cursor, topic }) => {
  let search = `cursor=${cursor}`;

  search = topic && topic.length ? `${search}&topic=${encodeURIComponent(topic)}` : search;

  return search;
};

const FeedPage = (props) => {
  const [query, setQuery] = useState(initialQuery(props));
  const { data, loading, error, fetchMore, refetch } = useQuery(FeedQueries.queries.FeedQuery, {
    variables: query
  });

  const { data: postsByTopic } = useQuery(FeedQueries.queries.PostTopics);

  let posts, myProfile, next, updateFeed;

  if (data) {
    posts = data.posts;
    myProfile = data.myProfile;

    updateFeed = (topic) => {
      const updated = { ...query, topic };

      props.history.push({
        location: props.location.pathname,
        search: renderFeedUrl(updated)
      });

      refetch({ variables: updated });
      setQuery(updated);
    };

    next = () =>
      fetchMore({
        query: FeedQueries.queries.FeedQuery,
        variables: {
          ...query,
          cursor: posts.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            posts: {
              __typename: fetchMoreResult.posts.__typename,
              pageInfo: fetchMoreResult.posts.pageInfo,
              edges: [...prev.posts.edges, ...fetchMoreResult.posts.edges]
            }
          });
        }
      });
  }

  return (
    <Container>
      <Row align="start" justify="start">
        <Col xs={12} sm={3} md={3} lg={3}>
          {postsByTopic && postTopics(postsByTopic, { query, updateFeed })}
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Pane>
            <PostCreate myProfile={myProfile} />
            {posts && posts.edges ? <PostIndex myProfile={myProfile} posts={posts} next={next} /> : <Loader />}
          </Pane>
        </Col>
      </Row>
    </Container>
  );
};

export default FeedPage;
