import _ from "lodash";
import React from "react";
import { Badge, Button, Dialog, FilePicker, Heading, Pane, majorScale, toaster } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";

import TextArea from "../form/TextArea";
import FeedQueries from "../../queries/feed";

const CommentSchema = Yup.object().shape({
  details: Yup.string()
    .min(1, "Please provide some details")
    .max(10000000, "Please shorten your comment")
    .required("Please provide some details"),
  photo: Yup.string(),
  video: Yup.string()
});

const SUCCESS_TOAST = "Successfully commented.";
const COMMENT_PLACEHOLDER = "Reply to the question...";

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

const CommentForm = ({ comment, postId, onUpdate, onClose }) => {
  const [isShown, setIsShown] = React.useState(false);

  const mutation = comment && comment.id ? FeedQueries.mutations.UpdateComment : FeedQueries.mutations.CreateComment;
  const [createComment, { loading, error }] = useMutation(mutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FeedQueries.queries.FeedQuery }, { query: FeedQueries.queries.Comments, variables: { postId } }],
    onCompleted: () => {
      toaster.success(SUCCESS_TOAST, { duration: 2 });
      onUpdate();
    }
  });

  return (
    <Formik
      validationSchema={CommentSchema}
      initialValues={{
        details: comment && comment.details ? comment.details : "",
        photo: comment && comment.photo ? comment.photo : "",
        video: comment && comment.video ? comment.video : "",
        id: comment && comment.id ? comment.id : undefined
      }}
      onSubmit={(variables, { setSubmitting }) => {
        createComment({ variables: { postId, ...variables } });
        setSubmitting(false);
      }}
      render={({ values, errors, status, touched, isSubmitting, setFieldValue, submitForm }) => (
        <Form
          padding={majorScale(2)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {/* Details */}
          <Pane width="100%">
            <Field type="text" name="details" placeholder={COMMENT_PLACEHOLDER} component={TextArea} />
          </Pane>

          <Pane display="flex" justifyContent="space-between" marginTop={majorScale(2)} marginBottom={majorScale(2)}>
            <Button appearance="minimal" type="button" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button appearance="primary" disabled={isSubmitting} type="submit">
              Post
            </Button>
          </Pane>
        </Form>
      )}
    />
  );
};

export default CommentForm;
