import React from "react";
import {
  Button,
  EditIcon,
  Heading,
  Pane,
  Paragraph,
  RemoveIcon,
  WarningSignIcon,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Form, Field } from "formik";

import { showSuccess } from "../../components/session/utils";
import { Col, ScreenClassRender } from "react-grid-system";
import TextArea from "../../components/form/TextArea";
import _ from "lodash";
import SessionQueries from "../../queries/session";
import SessionCard from "../../components/session/SessionCard";

const PLACEHOLDER =
  "When I opened the Zoom link I was stuck in the waiting room and we ended up starting 5 minutes late. Otherwise, the session was great!";

const SessionReportForm = ({ history, session, myProfile, existingReport }) => {
  const [reportSession, { loading: saving }] = useMutation(SessionQueries.mutations.ReportSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.SessionAndReviewById,
        variables: {
          id: session?.id
        }
      }
    ],
    onCompleted: () => {
      showSuccess("Session report submitted.");
      history.goBack();
    }
  });

  const CTA = existingReport?.id ? "Update" : "Submit";

  return (
    <Formik
      initialValues={{
        response: existingReport ? existingReport?.response : "",
        status: existingReport ? existingReport?.status : "Inactive",
        sessionId: session?.id
      }}
      onSubmit={({ response, status, sessionId }) => {
        reportSession({
          variables: {
            response,
            status: !_.isEqual(status, "Rejected") ? "Completed" : status,
            sessionId: session?.id
          }
        });
      }}
      render={({ setFieldValue }) => (
        <Form>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Pane marginBottom={majorScale(2)}>
                  <Heading size={500}>{"Let the Merit team know if something went wrong."}</Heading>
                  <Paragraph size={400}>{"This private feedback only goes to the Merit admin team."}</Paragraph>
                </Pane>
                <Pane>
                  <Field
                    name={"response"}
                    type="textarea"
                    defaultValue={existingReport?.response}
                    component={TextArea}
                    placeholder={PLACEHOLDER}
                  />
                </Pane>
                <Pane display="flex" flexDirection="row" justifyContent="flex-end" paddingTop={majorScale(2)}>
                  <Button isLoading={saving} iconBefore={EditIcon} type="submit" appearance="primary">
                    {`${CTA} report`}
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const SessionReportFormContainer = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.SessionAndReviewById, {
    variables: {
      id
    }
  });

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
            <Pane
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={majorScale(4)}
              width="100%"
              marginBottom={isMobile ? minorScale(1) : majorScale(3)}
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={majorScale(1)}>
                <WarningSignIcon size={majorScale(2)} marginRight={majorScale(1)} />
                Report an issue
              </Heading>
              <Heading display="flex" alignItems="center" size={600}>
                Let the Merit team know if something went awry
              </Heading>
            </Pane>

            {sessionAndReview?.sessionById?.id && (
              <Pane width="100%" justifyContent="center" display="flex" flexDirection="column">
                <SessionCard myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} hideActions />
              </Pane>
            )}

            {sessionAndReview?.sessionById?.id && (
              <SessionReportForm
                history={history}
                session={sessionAndReview?.sessionById}
                myProfile={sessionAndReview?.sessionById?.myProfile}
                existingReport={sessionAndReview?.reportBySessionId}
              />
            )}
          </Col>
        );
      }}
    />
  );
};

export default SessionReportFormContainer;
