import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Alert, AlignJustifyIcon, Button, EditIcon, Pane, Text, majorScale } from "evergreen-ui";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

import KitQueries from "../../queries/kit";

import CustomizeForm from "../playlist/CustomizeForm";
import HeroSection from "./sections/HeroSection";
import BlogSection from "./sections/BlogSection";
import MentorsSection from "./sections/MentorsSection";
import AnswersSection from "./sections/AnswersSection";
import EditCandidateDocs from "../profile/edit/candidate/EditCandidateDocs";
import CandidateQueries from "../../queries/candidate";
import ResumeNotif from "../../components/candidate/ResumeNotif";
import CoachList from "../coach/CoachList";

const DiscoverMeta = () => {
  const title = "Discover | Merit";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window.location.href} />
      <title>{title}</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="title" property="og:title" content={title} />

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Helmet>
  );
};

const width = {
  xs: 375,
  sm: 740,
  md: 960,
  xl: 1140,
  xxl: 1140
};

const getArray = (item = []) => (item && _.isString(item) ? [item] : item);

export const INITIAL_FILTERS = {
  topics: [],
  roles: [],
  levels: [],
  sort: "DEFAULT"
};

const getFiltersFromURL = (location) => {
  const params = queryString.parse(location.search, { arrayFormat: "comma" });
  return {
    topics: getArray(params?.topics),
    roles: getArray(params?.roles),
    levels: getArray(params?.levels),
    sort: params?.sort || INITIAL_FILTERS.sort
  };
};

const DiscoverPageContainer = ({ history, match, location }) => {
  const { hello } = queryString.parse(location?.search, { arrayFormat: "comma" });
  const filtersFromURL = getFiltersFromURL(location);
  const [selectedFilters, setSelectedFilters] = useState(filtersFromURL);

  const [setup, showSetup] = useState(hello);
  const [role, setRole] = useState(null);
  const { data: kitResult } = useQuery(KitQueries.queries.Discover, {
    variables: { slug: match?.params?.slug }
  });

  const { data: mentors } = useQuery(CoachList.LeaderQuery, {
    variables: { cursor: "1", ...selectedFilters }
  });

  const onChangeRole = (updated) => {
    const updatedFilters = { ...selectedFilters, roles: updated ? [updated] : [] };

    setSelectedFilters(updatedFilters);
    setRole(updated);

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(updatedFilters, { arrayFormat: "comma" })
    });
  };

  useEffect(() => {
    window.analytics.track("View Discover Page");
    if (_.isNull(role) && kitResult) {
      onChangeRole(kitResult?.kit?.roles[0] || kitResult?.myProfile?.jobRoles[0]);
    }
  }, [kitResult]); // eslint-disable-line

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <div style={{ paddingBottom: majorScale(8) }}>
          {setup && (
            <CustomizeForm
              onCancel={() => {
                showSetup(false);
              }}
              onCompleted={(result) => {
                if (result?.length > 0) {
                  onChangeRole(_.head(result));
                }
                showSetup(false);
              }}
            />
          )}
          <DiscoverMeta />
          <ResumeNotif />
          <HeroSection kit={kitResult?.kit} role={role} myProfile={kitResult?.myProfile} onChangeRole={onChangeRole} />
          <MentorsSection myProfile={kitResult?.myProfile} filters={selectedFilters} mentors={mentors} />
          <AnswersSection />
          <BlogSection />
        </div>
      )}
    />
  );
};

export default DiscoverPageContainer;
