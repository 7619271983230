import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import { Button, Heading, Pane, Paragraph, ThumbsUpIcon, ThumbsDownIcon, majorScale, toaster, defaultTheme as theme } from "evergreen-ui";

import TextArea from "../form/TextArea";

import SessionQueries from "../../queries/session";
import ProfileList from "../../queries/profile/ProfileList";

const SUBMITTED_SUCCESS = "Feedback submitted.";

const renderHeader = (privacy) => {
  if (_.isEqual(privacy, "null")) return "Feedback";
  else if (_.isEqual(privacy, "Public")) return "Write a public testimonial";
  else return `${privacy} Feedback`;
};

const Questions = ({ responses }) => {
  const infoText = {
    Private: "This is just for your own reflection. The other person will not see it.",
    Admin: "This is just for the Merit staff - it helps us improve the platform for future sessions."
  };

  return _.chain(responses)
    .groupBy("privacy")
    .map((questions, privacy) => (
      <Pane
        display="flex"
        flexDirection="column"
        width="100%"
        padding={majorScale(4)}
        borderRadius={majorScale(2)}
        border={`1px solid ${theme.colors.gray500}`}
        backgroundColor="white"
        minHeight="100px"
        marginTop={majorScale(4)}
      >
        <Heading size={500} marginBottom={majorScale(1)}>
          {renderHeader(privacy)}
        </Heading>
        {infoText[privacy] && (
          <Heading size={400} marginBottom={majorScale(1)}>
            {infoText[privacy]}
          </Heading>
        )}
        {questions.map((question) => (
          <Pane marginBottom={majorScale(2)} marginTop={majorScale(1)}>
            <Paragraph size={500} marginBottom={majorScale(1)}>
              {question.question}
            </Paragraph>
            <Field
              name={question.name}
              type="textarea"
              defaultValue={question.answer}
              component={TextArea}
              placeholder={question.placeholder}
            />
          </Pane>
        ))}
      </Pane>
    ))
    .value();
};

const getAnswer = (responses, name) => {
  let answer = _.chain(responses)
    .filter((response) => {
      return _.isEqual(response.name, name);
    })
    .head()
    .value();

  answer = answer ? answer.answer : null;
  return answer;
};

const FeedbackForm = ({ myProfile, session, responses, onCompleted, rating, history }) => {
  const [reviewSession] = useMutation(SessionQueries.mutations.ReviewSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.SessionAndReview,
        variables: {
          sessionID: session.id
        }
      },
      {
        query: SessionQueries.queries.SessionAndReviewById,
        variables: {
          id: session.id
        }
      },
      { query: ProfileList.LayoutQuery }
    ],
    onCompleted: () => {
      toaster.success(SUBMITTED_SUCCESS, { duration: 2 });

      // Track that the review was completed
      window.analytics.track("Session Review Completed", {
        id: session.id,
        coach: session.coach.id,
        coachName: session.coach.user.fullName
      });

      if (_.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });

  const allResponses = responses?.responses;
  return (
    <Formik
      initialValues={{
        rating,
        message: getAnswer(allResponses, "message") ? getAnswer(allResponses, "message") : "",
        lesson: getAnswer(allResponses, "lesson") ? getAnswer(allResponses, "lesson") : "",
        improvement: getAnswer(allResponses, "improvement") ? getAnswer(allResponses, "improvement") : "",
        admin: getAnswer(allResponses, "admin") ? getAnswer(allResponses, "admin") : "",
        success: getAnswer(allResponses, "success") ? getAnswer(allResponses, "success") : "",
        testimonial: getAnswer(allResponses, "testimonial") ? getAnswer(allResponses, "testimonial") : "",
        expertise: getAnswer(allResponses, "expertise") ? getAnswer(allResponses, "expertise") : "",
        questions: getAnswer(allResponses, "questions") ? getAnswer(allResponses, "questions") : "",
        stories: getAnswer(allResponses, "stories") ? getAnswer(allResponses, "stories") : "",
        actions: getAnswer(allResponses, "actions") ? getAnswer(allResponses, "actions") : "",
        responses
      }}
      onSubmit={(reviewProps) => {
        const { rating, message, lesson, improvement, admin, responses, success, testimonial, expertise, questions, stories, actions } =
          reviewProps;

        reviewSession({
          variables: {
            sessionID: session.id,
            rating,
            message,
            lesson,
            improvement,
            admin,
            responses,
            success,
            testimonial,
            expertise,
            questions,
            stories,
            actions
          }
        });
      }}
      render={({ values: { rating, responses }, errors, status, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Heading size={500} marginBottom={majorScale(2)}>
                  {`How would you rate the session?`}
                </Heading>
                <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                  <Button
                    color={_.isEqual(rating, 1) ? "white" : "#FFB020"}
                    borderColor={_.isEqual(rating, 1) ? "white" : "#FFB020"}
                    backgroundColor={_.isEqual(rating, 1) ? "#FFB020" : "white"}
                    iconBefore={ThumbsDownIcon}
                    type="button"
                    onClick={() => setFieldValue("rating", 1)}
                  >
                    Not so great
                  </Button>
                  <Button
                    intent="success"
                    appearance={_.isEqual(rating, 5) ? "primary" : "default"}
                    iconBefore={ThumbsUpIcon}
                    type="button"
                    onClick={() => setFieldValue("rating", 5)}
                  >
                    It was great!
                  </Button>
                </Pane>
              </Pane>
              <Questions responses={responses?.responses} />
            </Pane>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              paddingTop={majorScale(2)}
              paddingBottom={majorScale(6)}
            >
              <Button type="submit" appearance="minimal" intent="none">
                Skip Feedback
              </Button>
              <Button type="submit" appearance="primary" intent="success">
                Save Feedback
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default FeedbackForm;
