import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Pane, Spinner } from "evergreen-ui";

import DiscussionItem from "./DiscussionItem";

const loader = (
  <Pane display="flex" flexDirection="row" width="100%" justifyContent="center">
    <Spinner />
  </Pane>
);

const ReplyIndex = (indexProps) => {
  const { next, replies, myProfile, ...more } = indexProps;

  return replies?.edges?.length ? (
    <InfiniteScroll
      style={{ display: "flex", flexWrap: "wrap" }}
      hasMore={replies.pageInfo.hasNextPage}
      dataLength={replies.edges.length}
      loader={loader}
      next={() => next()}
    >
      {replies.edges.map((reply) => (
        <DiscussionItem key={`ReplyIndex-DiscussionItem-${reply?.id}`} reply={reply} myProfile={myProfile} long hideReplies {...more} />
      ))}
    </InfiniteScroll>
  ) : null;
};

export default ReplyIndex;
