import gql from "graphql-tag";
import ProfileList from "../../queries/profile/ProfileList";
import CoachList from "../coach/CoachList";

export default gql`
  query ReviewQuery($reviewID: String!) {
    myProfile {
      id,
      isAdmin
    },
    reviewById(reviewID: $reviewID) {
      id,
      rating,
      session {
        id,
        topic,
        date,
        description,
        cleanDescription,
        type,
        user {
          ${ProfileList.fragments.profile}
        },
        coach {
          ${CoachList.fragments.coach}
        }
      },
      user {
        ${ProfileList.fragments.profile}
      },
      responses {
        id,
        question,
        answer,
        privacy
      }
    }
  }
`;
