import React from "react";

import Button from "../../components/button/Button";
import Hero from "../../components/layout/Hero";
import Section from "../../components/layout/Section";
import SectionHeader from "../../components/layout/Section";

import sections from "../../content/organizations";

export default () => (
  <div>
    <Hero
      headline={"Build inclusive teams"}
      subtitle={"A tool for high-performing organizations"}
      heroShot={"https://cdn.glitch.com/d0b37ad1-2a33-4aeb-81f8-580d20e9c13c%2Femployee-dashboard.png?1541193961689"}
    >
      <Button isExternal title={"Join Waitlist"} link={"https://knowyourvisa.typeform.com/to/OnHr6H"} />
    </Hero>
    <SectionHeader>Coming Soon</SectionHeader>
    {sections.map((section) => (
      <Section detail={section.markdown} headline={section.headline} sectionShot={section.sectionShot} />
    ))}
  </div>
);
