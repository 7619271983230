import React from "react";
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import Layout from "../../components/layout/Layout";
import withRoutes from "../withRoutes";

// import GroupIndexPage from "./GroupIndexPage"
// import GroupById from "./GroupById"
// import GroupPage from "./GroupPage"

export default withRoutes(Layout, [
  // {
  //   path: "/communities/all",
  //   component: GroupIndexPage,
  // },
  // {
  //   path: "/group/:id",
  //   component: GroupById,
  // },
  // {
  //   path: "/c/:slug",
  //   component: GroupPage
  // },
]);
