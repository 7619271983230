import _ from "lodash";
import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Pane, Spinner, majorScale } from "evergreen-ui";

export default () => (
  <Col lg={6} md={8} sm={10} offset={{ lg: 3, md: 2 }}>
    <Pane display="flex" justifyContent="center" minHeight="50vh" padding={majorScale(12)}>
      <Spinner />
    </Pane>
  </Col>
);
