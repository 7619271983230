import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Link as BrowserLink, withRouter } from "react-router-dom";
import { Button, Pane, Paragraph, Heading, Spinner, SearchIcon, Tab, TabNavigation, majorScale, defaultTheme as theme } from "evergreen-ui";
import InfiniteScroll from "react-infinite-scroll-component";

import CandidateQueries from "../../queries/candidate";
import CandidateCard from "../../components/candidate/CandidateCard";
import CandidateRequestCard from "../../components/candidate/CandidateRequestCard";
import emptyPhoto from "../../images/empty-chat.svg";

const TABS = [
  {
    title: "Requests",
    status: "active"
  },
  {
    title: "Connected",
    status: "accepted"
  },
  {
    title: "Archived",
    status: "archived"
  }
];

const getFiltersFromURL = ({ params }) => {
  return {
    status: params?.status || "active"
  };
};

const EmptyRequestView = ({ isRecruiter }) => {
  const title = isRecruiter ? "No messages sent... yet!" : "No messages received... yet!";
  const details = isRecruiter
    ? "Find talented folks for your team by exploring candidates below"
    : "You'll see messages from recruiters here";
  const cta = "Source talent";
  let link = "/candidates/all";

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
      {isRecruiter && (
        <Button appearance="primary" iconBefore={SearchIcon} is={BrowserLink} to={link} marginBottom={majorScale(1)}>
          {cta}
        </Button>
      )}
    </Pane>
  );
};

const CandidateRequestTable = ({ pageInfo, requests, fetchMore, onUpdate, myRecruiterProfile }) => {
  const _requests = requests.filter((request) => request?.candidate?.user && request?.recruiter?.user);

  return (
    <Pane marginTop={majorScale(2)}>
      {_requests?.length > 0 && (
        <InfiniteScroll
          dataLength={_requests?.length}
          hasMore={pageInfo?.hasNextPage}
          loader={
            <Row>
              <Col xs={12}>
                <Pane display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <Spinner />
                </Pane>
              </Col>
            </Row>
          }
          next={fetchMore}
          style={{ overflow: "hidden" }}
        >
          <Row>
            {_requests?.map((request) => (
              <Col xs={12} key={request?.id}>
                {myRecruiterProfile?.id ? (
                  <CandidateCard existingRequest={request} candidate={request?.candidate} onUpdate={onUpdate} />
                ) : (
                  <CandidateRequestCard
                    myCandidateProfile={request?.candidate}
                    candidateRequest={request}
                    recruiter={request?.recruiter}
                    onUpdate={onUpdate}
                  />
                )}
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      )}
    </Pane>
  );
};

const CandidateActivityPage = ({ match, history }) => {
  const filtersFromURL = getFiltersFromURL(match);
  const [selectedStatus, setSelectedStatus] = useState(filtersFromURL?.status);

  const { data: recruiterResult } = useQuery(CandidateQueries.queries.MyRecruiterProfile);
  const {
    data: requestResult,
    loading: isLoading,
    fetchMore,
    refetch
  } = useQuery(CandidateQueries.queries.MyCandidateRequests, {
    variables: {
      status: selectedStatus,
      recruiterId: recruiterResult?.myRecruiterProfile?.id,
      cursor: "1"
    }
  });

  const fetchMoreCallback = (prev, { fetchMoreResult }) => {
    return fetchMoreResult
      ? {
          ...prev,
          myCandidateRequests: {
            __typename: fetchMoreResult.myCandidateRequests.__typename,
            pageInfo: fetchMoreResult.myCandidateRequests.pageInfo,
            edges: [...prev.myCandidateRequests.edges, ...fetchMoreResult.myCandidateRequests.edges]
          }
        }
      : prev;
  };

  const handleUpdate = ({ status }) => {
    refetch({ status });
  };

  const handleFetchMore = () =>
    fetchMore({
      variables: {
        cursor: requestResult?.myCandidateRequests?.pageInfo?.nextPage,
        status: selectedStatus
      },
      updateQuery: fetchMoreCallback
    });

  const handleFilterUpdate = (key, status) => {
    setSelectedStatus(status);
    refetch({ status });
    history.push(`/activity/${status}`);
  };

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <Pane width="100%">
            <Row>
              <Col xs={8} offset={{ xs: 2 }}>
                <Pane
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginTop={majorScale(4)}
                  marginBottom={majorScale(1)}
                >
                  <Heading is="h1" size={800} marginBottom={majorScale(1)}>
                    My Activity
                  </Heading>
                  <TabNavigation>
                    {TABS.map(({ title, status }) => {
                      const isSelected = selectedStatus === status;

                      return (
                        <Tab
                          id={status}
                          onClick={() => handleFilterUpdate("status", status)}
                          isSelected={isSelected}
                          appearance="minimal"
                          marginRight={majorScale(1)}
                          paddingY={majorScale(2)}
                          fontWeight={500}
                          size={400}
                          color={isSelected ? "#3366FF" : "#696F8C"}
                          borderBottom={isSelected ? "2px solid #3366FF" : "2px solid white"}
                        >
                          {title}
                        </Tab>
                      );
                    })}
                  </TabNavigation>
                </Pane>
                {!requestResult?.myCandidateRequests?.pageInfo?.edgeCount > 0 ? (
                  <>
                    {isLoading ? (
                      <Pane>
                        <Row style={{ height: 360 }}>
                          <Col xs={12} style={{ height: "100%" }}>
                            <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center" height="100%">
                              <Spinner />
                            </Pane>
                          </Col>
                        </Row>
                      </Pane>
                    ) : (
                      <Pane>
                        <Row style={{ height: 360 }}>
                          <Col xs={12} style={{ height: "100%" }}>
                            <EmptyRequestView isRecruiter={recruiterResult?.myRecruiterProfile} />
                          </Col>
                        </Row>
                      </Pane>
                    )}
                  </>
                ) : (
                  <CandidateRequestTable
                    pageInfo={requestResult?.myCandidateRequests?.pageInfo}
                    requests={requestResult?.myCandidateRequests?.edges}
                    myRecruiterProfile={recruiterResult?.myRecruiterProfile}
                    fetchMore={handleFetchMore}
                    onUpdate={handleUpdate}
                  />
                )}
              </Col>
            </Row>
          </Pane>
        );
      }}
    />
  );
};

export default withRouter(CandidateActivityPage);
