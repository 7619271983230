import _ from "lodash";
import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  Button,
  CaretDownIcon,
  Checkbox,
  CrossIcon,
  Dialog,
  Heading,
  Pane,
  Paragraph,
  RadioGroup,
  SelectMenu,
  majorScale,
  toaster,
  minorScale
} from "evergreen-ui";
import * as Yup from "yup";
import Moment from "moment";

import CandidateQueries from "../../../../queries/candidate";
import LocationAutocomplete from "../../../../components/LocationAutocomplete";

const showSuccess = (copy) =>
  _.throttle(() => {
    toaster.success(copy, { duration: 2, id: copy });
  }, 5000);

const initEmploymentOptions = ({ myCandidateProfile, availableWorkStyles, setEmploymentOptions }) => {
  let initial = {};

  if (myCandidateProfile?.employmentType?.length) {
    initial = _.zipObject(_.map(availableWorkStyles, "value"), _.fill(Array(availableWorkStyles.length), false));
    _.forEach(myCandidateProfile.workStyle, (type) => {
      initial[type] = true;
    });
  } else {
    initial = _.zipObject(_.map(availableWorkStyles, "value"), _.fill(Array(availableWorkStyles.length), false));
  }

  setEmploymentOptions(initial);
};

const RoleTypeForm = ({ myCandidateProfile, myProfile }) => {
  const { data: candidateProfileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const [selectedEmploymentOptions, setEmploymentOptions] = useState(null);
  const [updateWorkPreferences] = useMutation(CandidateQueries.mutations.UpdateWorkPreferences, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CandidateQueries.queries.MyCandidateProfile
      }
    ],
    onCompleted: (result) => {
      showSuccess("Work preferences updated.")();
    }
  });

  const chooseEmploymentType = (selectedOptions, setFieldValue) => {
    const selected = _.pickBy(selectedOptions, (value) => value);
    const selectedKeys = _.keys(selected);

    setFieldValue("workStyle", selectedKeys, false);
  };

  const onMove = (direction, locations, location, setFieldValue) => {
    const current = locations.indexOf(location);
    const updated = _.isEqual(direction, "up") ? current - 1 : current + 1;
    const change = locations.splice(current, 1)[0];

    locations.splice(updated, 0, change);
    setFieldValue("locationPreference", locations, false);
  };

  if (_.isNull(selectedEmploymentOptions)) {
    initEmploymentOptions({
      myCandidateProfile,
      setEmploymentOptions,
      availableWorkStyles: candidateProfileOptions.availableWorkStyles
    });
  }

  const timezoneOptions = Moment.tz.names().map((value) => ({ value, label: value }));

  return (
    <Formik
      initialValues={{
        workStyle: myCandidateProfile?.workStyle || ["hybrid"],
        workStylePreference: myCandidateProfile?.workStylePreference || "hybrid",
        timezonePreference: myCandidateProfile?.timezonePreference || myProfile?.timezone || "",
        locationPreference: myCandidateProfile?.locationPreference || [_.omit(myProfile?.place, "__typename")] || []
      }}
      validationSchema={Yup.object().shape({
        workStyle: Yup.array().of(Yup.string()),
        workStylePreference: Yup.string(),
        timezonePreference: Yup.string(),
        locationPreference: Yup.array().of(Yup.string())
      })}
      onSubmit={(variables, { setSubmitting }) => {
        updateWorkPreferences({
          variables: {
            workStyle: variables?.workStyle,
            workStylePreference: variables?.workStylePreference,
            timezonePreference: variables?.timezonePreference,
            locationPreference: variables?.locationPreference.map((location) => _.omit(location, "__typename"))
          }
        });
      }}
      render={({ values, setFieldValue, submitForm }) => (
        <Form>
          <Pane>
            <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
              <Heading>What type of employment are you seeking?</Heading>
              <Paragraph>You can select multiple.</Paragraph>
              <Pane>
                {candidateProfileOptions.availableWorkStyles.map(({ value, label }) => (
                  <Checkbox
                    key={`EmploymentType-${value}`}
                    label={label}
                    value={value}
                    checked={selectedEmploymentOptions[value]}
                    onChange={(e) => {
                      if (selectedEmploymentOptions[value]) {
                        selectedEmploymentOptions[value] = false;
                        setEmploymentOptions({ ...selectedEmploymentOptions, [value]: false });
                      } else {
                        selectedEmploymentOptions[value] = true;
                        setEmploymentOptions({ ...selectedEmploymentOptions, [value]: true });
                      }

                      chooseEmploymentType(selectedEmploymentOptions, setFieldValue);
                      submitForm();
                    }}
                  />
                ))}
              </Pane>
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
              <Heading>What is your employment preference?</Heading>
              <RadioGroup
                value={values?.employmentPreference}
                options={candidateProfileOptions.availableWorkStyles}
                onChange={(e) => {
                  setFieldValue("employmentPreference", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>

            {values?.workStyle?.includes("remote") && (
              <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={majorScale(1)}>
                <Heading marginBottom={majorScale(1)}>What is your preferred work timezone?</Heading>
                <SelectMenu
                  title=""
                  options={timezoneOptions}
                  selected={values?.timezonePreference}
                  onSelect={(item) => {
                    setFieldValue("timezonePreference", item.value, false);
                    submitForm();
                  }}
                >
                  <Button type="button" iconAfter={CaretDownIcon}>
                    {values?.timezonePreference || "Select your timezone"}
                  </Button>
                </SelectMenu>
              </Pane>
            )}

            {values?.workStyle?.includes("hybrid") > 0 && (
              <Pane width="100%" display="flex" flexDirection="column" marginBottom={minorScale(1)}>
                <Pane marginBottom={majorScale(2)}>
                  <Heading marginBottom={minorScale(1)}>Where do you want to work?</Heading>
                  <Heading size={400}>In priority order</Heading>
                </Pane>
                {values?.locationPreference.map((location, index) => (
                  <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Pane display="flex" flexDirection="row">
                      <CrossIcon
                        marginRight={minorScale(1)}
                        onClick={() => {
                          const newLocations = _.reject(values?.locationPreference, { placeId: location?.placeId });
                          setFieldValue("locationPreference", newLocations, false);
                          submitForm();
                        }}
                      />
                      <Heading size={400}>{`${index + 1}. ${location?.formatted}`}</Heading>
                    </Pane>
                    <Pane>
                      {values?.locationPreference?.length > 1 && (
                        <Pane>
                          {index !== 0 && (
                            <Button appearance="minimal" onClick={() => onMove("up", values?.locationPreference, location, setFieldValue)}>
                              <ArrowUpIcon />
                            </Button>
                          )}
                          {index !== values?.locationPreference.length - 1 && (
                            <Button
                              appearance="minimal"
                              onClick={() => onMove("down", values?.locationPreference, location, setFieldValue)}
                            >
                              <ArrowDownIcon />
                            </Button>
                          )}
                        </Pane>
                      )}
                    </Pane>
                  </Pane>
                ))}
                <Pane paddingTop={majorScale(2)}>
                  <LocationAutocomplete
                    disableUserUpdate
                    onSelected={(loc, inputRef) => {
                      if (!_.find(values?.locationPreference, loc)) {
                        setFieldValue("locationPreference", [...values?.locationPreference, loc], false);
                        submitForm();

                        inputRef.value = "";
                      }
                    }}
                  />
                </Pane>
              </Pane>
            )}
          </Pane>
        </Form>
      )}
    />
  );
};

const EditRolePreferences = ({ showRole, setShowRole, myCandidateProfile, myProfile }) => {
  return (
    <Dialog
      isShown={showRole}
      shouldAutoFocus={false}
      title="What type of role are you looking for?"
      onCloseComplete={() => setShowRole(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
      preventBodyScrolling={true}
      containerProps={{ overflowY: "scroll", minHeight: 500 }}
    >
      <RoleTypeForm myCandidateProfile={myCandidateProfile} myProfile={myProfile} />
    </Dialog>
  );
};

export default EditRolePreferences;
