import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  EditIcon,
  Heading,
  Pane,
  Text,
  TextInput as TextInputControl,
  majorScale,
  toaster
} from "evergreen-ui";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../components/form/TextInput";
import LocationAutocomplete from "../../../components/LocationAutocomplete";
import ProfileList from "../../../queries/profile/ProfileList";

// https://stackoverflow.com/questions/13927099/validate-url-with-or-without-protocol
const URL_REGEX = /(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+?\.(?:[a-zA-Z])|\d+\.\d+\.\d+\.\d+)/;
const PRONOUN_PLACEHOLDER = `Please identify your pronouns`;

const ProfileOverviewSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, "Please include your first name")
    .max(50, "Please shorten the name you put in")
    .required("Please provide your first name"),
  lastName: Yup.string()
    .min(1, "Please include your last name")
    .max(50, "Please shorten the name you put in")
    .required("Please provide your last name"),
  linkedin: Yup.string().matches(URL_REGEX, "Please provide a valid LinkedIn URL"),
  twitter: Yup.string().matches(URL_REGEX, "Please provide a valid Twitter URL"),
  website: Yup.string().matches(URL_REGEX, "Please provide a valid website URL"),
  mode: Yup.string()
});

const MENTOR_APP_COPY = "Interested in becoming a Mentor? Click here to get started";
const MENTOR_APP_BUTTON = "Apply to Mentor";
const SUCCESS_TOAST = "Updated profile.";

const showSuccess = _.debounce(() => {
  toaster.success(SUCCESS_TOAST);
}, 1000);

const modes = [
  { value: "Learn", label: "Learn from others" },
  { value: "Coach", label: "Mentor others in tech" },
  { value: "Both", label: "Both learn from and mentor people in tech" }
];

const PronounsForm = (formProps) => {
  const { selectedPronouns, availablePronouns } = formProps;
  const [updatePronouns] = useMutation(ProfileList.UpdatePronouns, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }],
    onCompleted: () => showSuccess()
  });

  const onChange = (changedItem) =>
    updatePronouns({
      variables: {
        pronouns: _.isNil(changedItem) ? null : changedItem
      }
    });

  return (
    <Pane marginBottom={majorScale(4)}>
      <Autocomplete selectedItem={selectedPronouns} allowOtherValues={true} items={availablePronouns} onChange={onChange}>
        {({ getInputProps, getRef, inputValue, openMenu }) => (
          <TextInputControl
            placeholder={PRONOUN_PLACEHOLDER}
            value={inputValue}
            ref={getRef}
            width={"100%"}
            {...getInputProps({
              onFocus: () => {
                openMenu();
              }
            })}
          />
        )}
      </Autocomplete>
    </Pane>
  );
};

const ProfileOverviewForm = ({ myProfile }) => {
  const [showApp, setShowApp] = useState(false);
  const [updateProfile, { error: submissionError }] = useMutation(ProfileList.UpdateProfile, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyCoachingProfile
      }
    ],
    onCompleted: () => showSuccess()
  });

  const currentMode = _.find(modes, { value: myProfile.mode });

  return (
    <Formik
      validationSchema={ProfileOverviewSchema}
      initialValues={{
        firstName: myProfile.name && myProfile.name.first ? myProfile.name.first : "",
        lastName: myProfile.name && myProfile.name.last ? myProfile.name.last : "",
        twitter: myProfile.twitter ? myProfile.twitter : "",
        linkedin: myProfile.linkedin ? myProfile.linkedin : "",
        website: myProfile.website ? myProfile.website : "",
        mode: myProfile.mode
      }}
      onSubmit={(variables, { setSubmitting, setValues }) => {
        if (variables.mode && variables.mode.value) {
          variables = _.extend(variables, {
            mode: _.map(variables.mode, "value")
          });
        }

        updateProfile({ variables });
        setSubmitting(false);

        window.analytics.track("Update Profile Overview");
      }}
      render={({ setFieldValue, handleChange, submitForm, errors }) => (
        <Form>
          <Pane display="flex" flexDirection="column" width="100%">
            <Pane>
              <Heading size={400} marginBottom={majorScale(1)}>
                My name is
              </Heading>
            </Pane>
            <Pane display="flex" justifyContent="space-between" marginBottom={majorScale(3)}>
              <Field
                type="text"
                name="firstName"
                placeholder="First Name"
                component={TextInput}
                height={majorScale(4)}
                containerStyles={{ width: "calc(50% - 8px)" }}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("firstName", e.target.value, false);
                  submitForm();
                }}
              />
              <Field
                type="text"
                name="lastName"
                placeholder="Last Name"
                component={TextInput}
                height={majorScale(4)}
                containerStyles={{ width: "calc(50% - 8px)" }}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("lastName", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>

            <Pane display="flex" flexDirection="column">
              <Heading size={400} marginBottom={majorScale(1)}>
                My pronouns are
              </Heading>
              <PronounsForm availablePronouns={myProfile?.availablePronouns} selectedPronouns={myProfile?.pronouns} />
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane>
                <Heading size={400} marginBottom={majorScale(1)}>
                  I'm using Merit to
                </Heading>
              </Pane>
              <Select
                options={modes}
                value={currentMode}
                onChange={(selected) => {
                  setFieldValue("mode", selected.value, false);
                  if (!_.isEqual(selected.value, currentMode)) {
                    if (_.isEqual(selected.value, "Coach") || _.isEqual(selected.value, "Both")) {
                      setShowApp(true);
                    }
                  }
                  submitForm();
                }}
              />
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={majorScale(1)}>
                I'm based in
              </Heading>
            </Pane>
            <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
              <LocationAutocomplete myProfile={myProfile} />
            </Pane>

            <Pane display="flex" flexDirection="column">
              <Heading size={400} marginBottom={majorScale(2)}>
                You can find me online at
              </Heading>
              <Text marginBottom={majorScale(2)}>
                <FontAwesomeIcon icon={faLinkedinIn} /> LinkedIn
              </Text>
              <Field
                type="text"
                name="linkedin"
                placeholder="Linkedin"
                component={TextInput}
                height={majorScale(6)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("linkedin", e.target.value, false);
                  submitForm();
                }}
              />
              <Text marginBottom={majorScale(2)}>
                <FontAwesomeIcon icon={faExternalLinkAlt} /> My personal website
              </Text>
              <Field
                type="text"
                name="website"
                placeholder="Website"
                component={TextInput}
                height={majorScale(6)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("website", e.target.value, false);
                  submitForm();
                }}
              />
              <Text marginBottom={majorScale(2)}>
                <FontAwesomeIcon icon={faTwitter} /> Twitter
              </Text>
              <Field
                type="text"
                name="twitter"
                placeholder="Twitter"
                component={TextInput}
                height={majorScale(6)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("twitter", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>

            {showApp ? (
              <Pane width="100%" display="flex" padding={majorScale(2)}>
                <Pane flex={1}>
                  <Text>{MENTOR_APP_COPY}</Text>
                </Pane>
                <Pane>
                  <Button is={Link} to="/apply/0" target="_blank" appearance="primary">
                    {MENTOR_APP_BUTTON}
                  </Button>
                </Pane>
              </Pane>
            ) : null}
            {/* Submission Errors */}
            {submissionError && submissionError.graphQLErrors
              ? submissionError.graphQLErrors.map(({ message }) => (
                  <Pane paddingLeft={majorScale(2)} paddingRight={majorScale(2)}>
                    <Alert marginTop={majorScale(1)} marginBottom={majorScale(1)} title={message} intent="danger"></Alert>
                  </Pane>
                ))
              : null}
          </Pane>
        </Form>
      )}
    />
  );
};

const ProfileOverviewFormContainer = (formProps) => {
  const [showEditor, setShowEditor] = useState(formProps?.showEditor);
  const { myProfile } = formProps;

  return (
    <>
      <Dialog
        isShown={showEditor}
        title="Edit Profile Overview"
        onCloseComplete={() => setShowEditor(false)}
        confirmLabel="Finish"
        hasCancel={false}
        hasClose={false}
        preventBodyScrolling={true}
      >
        <ProfileOverviewForm myProfile={myProfile} />
      </Dialog>
      <Button marginLeft={majorScale(1)} iconBefore={EditIcon} appearance="minimal" onClick={() => setShowEditor(true)}>
        Edit
      </Button>
    </>
  );
};

export default ProfileOverviewFormContainer;
