import _ from "lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Select from "react-select";
import gql from "graphql-tag";
import { Dialog, Heading, Pane, Text, majorScale, minorScale, toaster } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import ProfileList from "../../../queries/profile/ProfileList";
import TextArea from "../../../components/form/TextArea";

const MENTOR_MOTIVATIONS = "What are your motivations for mentoring others?";
const MENTOR_INFO = "This helps break the ice for potential mentees by letting them know what you get out of helping others!";
const MOTIVATIONS_TOAST = "Mentoring motivations updated.";
const MOTIVATIONS_PLACEHOLDER = "Please select your motivations for mentoring.";

const MENTOR_REASON = "Any additional context for why you mentor?";
const REASON_TOAST = "Updated mentorship reason.";
const REASON_PLACEHOLDER =
  "I’ve been lucky enough to have a lot of great mentors in the past. I would love to pass it forward by helping others in industry where I think I can help most. Please reach out to me about....";

const showSuccess = (copy) =>
  _.debounce(() => {
    toaster.success(copy, { duration: 2 });
  }, 2000);

const createSelectLabels = (labels) => labels?.map((value) => ({ value, label: value }));

const MentorMotivationsForm = ({ myCoachingProfile }) => {
  const [updateMotivations] = useMutation(ProfileList.UpdateMotivations, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      window.analytics.track("Update Mentor Motivations");
      showSuccess(MOTIVATIONS_TOAST)();
    }
  });

  const { data: motivationsResult } = useQuery(gql`
    query motivations {
      availableMotivations
    }
  `);

  if (motivationsResult) {
    return (
      <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
        <Pane marginBottom={majorScale(1)}>
          <Heading size={400} marginBottom={minorScale(2)}>
            {MENTOR_MOTIVATIONS}
          </Heading>
          <Text marginBottom={minorScale(2)}>{MENTOR_INFO}</Text>
        </Pane>
        <Select
          isMulti
          options={createSelectLabels(motivationsResult?.availableMotivations)}
          defaultValue={createSelectLabels(myCoachingProfile.motivations)}
          placeholder={MOTIVATIONS_PLACEHOLDER}
          onChange={(selected) => {
            const motivations = _.map(selected, ({ value }) => value);

            updateMotivations({ variables: { motivations } });
          }}
        />
      </Pane>
    );
  } else return null;
};

const MentorReasonForm = ({ myCoachingProfile, myProfile }) => {
  const [updateCoachingReason] = useMutation(ProfileList.UpdateCoachingReason, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      window.analytics.track("Update Mentorship Reason");
      showSuccess(REASON_TOAST)();
    }
  });

  const ReasonSchema = Yup.object().shape({ reason: Yup.string() });

  return (
    <Formik
      validationSchema={ReasonSchema}
      initialValues={{
        reason: myCoachingProfile.reason ? myCoachingProfile.reason : ""
      }}
      onSubmit={(submitted, { setSubmitting }) => {
        updateCoachingReason({ variables: { ...submitted } });
        setSubmitting(false);
      }}
      render={({ values: { reason }, setFieldValue, handleChange, submitForm }) => (
        <Form>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
            <Pane marginBottom={majorScale(1)}>
              <Heading size={400} marginBottom={minorScale(2)}>
                {MENTOR_REASON}
              </Heading>
            </Pane>
            <Field
              type="text"
              name="reason"
              placeholder={REASON_PLACEHOLDER}
              component={TextArea}
              onBlur={(e) => {
                e.persist();
                setFieldValue("reason", e.target.value, false);
                submitForm();
              }}
            />
          </Pane>
        </Form>
      )}
    />
  );
};

const EditMotivations = ({ myProfile, myCoachingProfile, showEditor, setShowEditor }) => {
  return (
    <Dialog
      isShown={showEditor}
      title="Edit Mentor Motivations"
      onCloseComplete={() => setShowEditor(false)}
      confirmLabel="Finish"
      hasCancel={false}
      hasClose={false}
    >
      <Pane minHeight="332px">
        <MentorMotivationsForm myCoachingProfile={myCoachingProfile} />
        <MentorReasonForm myProfile={myProfile} myCoachingProfile={myCoachingProfile} />
      </Pane>
    </Dialog>
  );
};

export default EditMotivations;
