import _ from "lodash";
import React from "react";
import { Col, ScreenClassRender } from "react-grid-system";
import { Link as BrowserLink } from "react-router-dom";
import {
  AnnotationIcon,
  Pane,
  Heading,
  Paragraph,
  PulseIcon,
  SearchIcon,
  majorScale,
  minorScale,
  Button,
  CalendarIcon,
  Spinner,
  defaultTheme as theme
} from "evergreen-ui";

import ConnectionQueries from "../../queries/connection";
import { useQuery } from "@apollo/client";
import ConnectionCard from "../../components/connection/ConnectionCard";
import SessionCard from "../../components/session/SessionCard";
import emptyPhoto from "../../images/empty-chat.svg";

const isCoach = ({ status }) => {
  return _.isEqual(status, "Active") || _.isEqual(status, "Inactive") || _.isEqual(status, "Pending");
};

const SectionDivider = ({ title }) => (
  <Pane width="100%" justifyContent="center" alignItems="center" display="flex" flexDirection="column" marginBottom={majorScale(2)}>
    <Heading size={500}>{title}</Heading>
  </Pane>
);

const EmptySessionView = ({ myCoachingProfile }) => {
  let title = "No sessions booked... yet!";
  let details = "Get career help today by booking a session with an available mentor";
  let cta = "Explore Mentors";
  let link = "/discover";

  if (myCoachingProfile?.id && isCoach(myCoachingProfile)) {
    title = "No sessions booked... yet!";
    details = "Mentor others in tech today by helping mentees with their career questions.";
    cta = "Explore Members";
    link = "/members";
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
      <Button appearance="primary" iconBefore={SearchIcon} is={BrowserLink} to={link} marginBottom={majorScale(1)}>
        {cta}
      </Button>
    </Pane>
  );
};

const ConnectionDetailPage = ({
  history,
  match: {
    params: { id, state }
  }
}) => {
  const { data: connectionResult } = useQuery(ConnectionQueries.queries.ConnectionById, {
    variables: {
      id
    }
  });

  const { data: upcomingSessionsResult, loading: upcomingLoading } = useQuery(ConnectionQueries.queries.SessionsByConnectionId, {
    variables: {
      id,
      direction: "upcoming"
    }
  });

  const { data: previouSessionsResult, loading: previousLoading } = useQuery(ConnectionQueries.queries.SessionsByConnectionId, {
    variables: {
      id,
      direction: "previous"
    }
  });

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
            <Pane
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={majorScale(4)}
              width="100%"
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={isMobile ? minorScale(1) : majorScale(3)}>
                <PulseIcon size={majorScale(2)} marginRight={majorScale(1)} />
                Connection activity
              </Heading>
              <Pane>
                {connectionResult?.connectionById?.id && connectionResult?.connectionById?.sessionsCompleted > 0 && (
                  <Button
                    is={BrowserLink}
                    to={`/recommend/${connectionResult?.connectionById?.id}`}
                    iconBefore={AnnotationIcon}
                    marginLeft={majorScale(1)}
                    appearance={connectionResult?.connectionById?.recommendation?.id ? "default" : "primary"}
                  >
                    {connectionResult?.connectionById?.recommendation?.id ? "Edit recommendation" : "Recommend"}
                  </Button>
                )}
                {!connectionResult?.connectionById?.amItheMentor && (
                  <Button
                    is={BrowserLink}
                    to={`/p/${connectionResult?.connectionById?.viewing?.slug}`}
                    iconBefore={CalendarIcon}
                    marginLeft={majorScale(1)}
                    appearance={connectionResult?.connectionById?.recommendation?.id ? "primary" : "default"}
                  >
                    Book session
                  </Button>
                )}
              </Pane>
            </Pane>
            {connectionResult?.connectionById && (
              <Pane width="100%" justifyContent="center" display="flex" flexDirection="column">
                <ConnectionCard connection={connectionResult?.connectionById} hideActions />
              </Pane>
            )}
            {upcomingSessionsResult?.sessionsByConnectionId?.edges?.length > 0 && (
              <>
                <SectionDivider title="Upcoming sessions" />
                <Pane width="100%" justifyContent="center" display="flex" flexDirection="column">
                  {upcomingSessionsResult?.sessionsByConnectionId?.edges?.map((session) => (
                    <SessionCard hideProfile session={session} key={session?.id} />
                  ))}
                </Pane>
              </>
            )}
            {previouSessionsResult?.sessionsByConnectionId?.edges?.length > 0 && (
              <>
                <SectionDivider title="Previous sessions" />
                <Pane width="100%" justifyContent="center" display="flex" flexDirection="column">
                  {previouSessionsResult?.sessionsByConnectionId?.edges?.map((session) => (
                    <SessionCard hideProfile session={session} key={session?.id} />
                  ))}
                </Pane>
              </>
            )}
            {!upcomingSessionsResult?.sessionsByConnectionId?.edges?.length &&
              !previouSessionsResult?.sessionsByConnectionId?.edges?.length && (
                <>
                  {upcomingLoading || previousLoading ? (
                    <Pane display="flex" flexDirection="column" width="100%" alignItems="center" heigth={majorScale(10)}>
                      <Spinner />
                    </Pane>
                  ) : (
                    <EmptySessionView myCoachingProfile={connectionResult?.myCoachingProfile} />
                  )}
                </>
              )}
          </Col>
        );
      }}
    />
  );
};

export default ConnectionDetailPage;
