import gql from "graphql-tag";

import ProfileList from "../profile/ProfileList";
import FeedQueries from "../feed";
import TeamQueries from "../team";
import QueryUtils from "../utils";
import PlaylistQueries from "../playlist";
import CoachList from "../../routes/coach/CoachList";

const KitQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

KitQueries.queries.DiscoverPage = gql`
  query DiscoverPage(
    $cursor: String, $sort: String, $motivations: [String], $roles: [String!],
    $topic: String, $text: String, $industries: [String!], $levels: [String!],
    $name: String, $topics: [String!], $availableSoon: Boolean, $pageSize: Int,
  ) {
    myProfile {
      ${ProfileList.fragments.profileLite}
    },
    posts(cursor: $cursor, topic: $topic, sort: $sort, text: $text) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.post},
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction}
          }
        },
      }
    },

    topicSearch(${PlaylistQueries.TopicSearchQueryOptions.args}, cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        id,
        name,
        description,
        level,
        jobRole,
        motivations,
        slug,
      }
    },

    jobs(roles: $roles, levels: $levels, industries: $industries) {
      edges {
        ${TeamQueries.fragments.job},
        team {
          ${TeamQueries.fragments.team}
        }
      },
      ${QueryUtils.PageInfo}
    },

    leaders(${CoachList.LeaderSearchQueryOptions.args}, cursor: $cursor) {
      edges {
        ${CoachList.fragments.mentor}
      },
      ${QueryUtils.PageInfo}
    },

    industries {
      id,
      name,
      keyword
    },

    topicFilters {
      availableJobRoles,
      availableLevels,
      availableMotivations,
      availableSorts
    },
  }
`;

KitQueries.queries.Discover = gql`
  query Kit($slug: String) {
    motivations {
      id,
      key,
      name,
      topicIds
    },
    myProfile {
      ${ProfileList.fragments.profileLite}
    },
    kit(slug: $slug) {
      id,
      title,
      subtitle,
      logo,
      slug,
      headline,
      description,
      roles,
      motivations
    }
  }
`;

export default KitQueries;
