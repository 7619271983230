import React from "react";

import { Row, Col } from "react-grid-system";
import { Pane, Heading, majorScale } from "evergreen-ui";

export default ({ title, children }) => (
  <Row>
    <Col lg={6} md={8} sm={10} offset={{ lg: 3, md: 2 }}>
      <Pane display="flex" borderBottom={"1px solid"} padding={majorScale(2)}>
        <Pane flex={1} alignItems="center" display="flex">
          <Heading size={900}>{title}</Heading>
        </Pane>
        <Pane>{children}</Pane>
      </Pane>
    </Col>
  </Row>
);
