import React from "react";
import { TextInputField, Badge, Pane, majorScale } from "evergreen-ui";

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

export default ({ field, form: { touched, errors }, height, containerStyles, ...props }) => (
  <Pane alignItems="center" height={height ? height : majorScale(7)} {...containerStyles}>
    <TextInputField marginBottom="0" width="100%" {...field} {...props} />
    {touched[field.name] && errors[field.name] && errorText(errors[field.name])}
  </Pane>
);
