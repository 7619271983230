import _ from "lodash";
import React, { useState } from "react";
import { Link as BrowserLink } from "react-router-dom";
import { Link } from "evergreen-ui";

const sameWebsite = (href) => {
  if (_.isEqual("/", href[0])) return true;
  else return href?.includes("localhost") || href?.includes("get-merit");
};

const HoverLink = ({ children, to, noUnderline, samePage }) => {
  const [hover, setHover] = useState(false);

  return sameWebsite(to) ? (
    <Link
      is={BrowserLink}
      to={to}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      textDecoration={hover && !noUnderline ? "underline" : "none"}
      target={samePage ? "" : "_blank"}
    >
      {children}
    </Link>
  ) : (
    <Link
      is={"a"}
      href={to}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      textDecoration={hover && !noUnderline ? "underline" : "none"}
      target={samePage ? "" : "_blank"}
    >
      {children}
    </Link>
  );
};

export default HoverLink;
