const color = {
  text: "#292929",
  whiteText: "#FFFFFF",
  brand: "#FF3D1D",
  background: "#E5E5E5",
  altText: "#717171",
  darkBackground: "#F7F7F7",
  blueBackground: "#2754C9",
  highlight: "#F4C742",
  yellow: `#FCEEC6`,
  love: "#E14A4A",
  pink: "#FEB7DB",
  darkGrey: "#717171",
  lightGrey: "#D6D6D6",
  lightestGrey: "#F7F7F7"
};

const spacing = {
  margin: 10,
  padding: 10,
  grid: 20
};

const fonts = `https://fonts.googleapis.com/css?family=DM+Sans:400,500|Karla:400,700&display=swap`;

const title = {
  color: color.text,
  font: {
    family: "'DM Sans', sans-serif",
    weight: "normal",
    style: "normal"
  }
};

const body = {
  color: color.text,
  font: {
    family: "'Karla', sans-serif",
    weight: 400,
    style: "normal"
  }
};

const logo = (size) => ({
  color: color.whiteText,
  font: {
    family: "'Cabrito Sans', sans-serif",
    weight: 400,
    size
  }
});

const header = (size, lineHeight, fontWeight) => ({
  color: title.color,
  font: {
    ...title.font,
    size,
    weight: fontWeight ? fontWeight : 400
  },
  lineHeight
});

const content = (size, lineHeight, letterSpacing, fontWeight) => ({
  color: body.color,
  font: {
    ...body.font,
    weight: fontWeight ? fontWeight : 400,
    size
  },
  lineHeight,
  letterSpacing
});

export default {
  // Colors
  color,
  spacing,

  // Fonts
  h1: header("90px", "78px", 500),
  h1Mobile: header("48px", "54px", 500),
  h2: header("48px", "54px", 500),
  h2Mobile: header("36px", "42px", 500),
  h3: header("24px", "32px", 500),
  h4: header("24px", "32px", 400),
  h5: header("13px", "16px", 500),

  p: content("20px", "28px", "-0.01em"),
  s: content("16px", "22px"),
  b: content("25px", "33px", "-0.01em"),
  button: content("20px", "32px", "", 700),

  // Functions
  content,
  header,
  logo,
  fonts,

  // Forms
  textField: {
    width: "100%",
    ...content(16),
    paddingLeft: spacing.padding,
    paddingBottom: spacing.padding,
    paddingTop: spacing.padding,
    marginTop: spacing.margin / 2 + 3,
    marginBottom: spacing.margin / 2 + 3,
    boxSizing: "border-box",
    border: `1px solid ${color.text}`,
    "&:hover": {
      // border: `1px solid ${color.text}`,
      transition: "color .5s cubic-bezier(0.215, 0.61, 0.355, 1)"
    },
    "&:focus": {
      outline: "none !important",
      border: `1px solid ${color.text}`
    }
  },
  error: {
    ...content(16),
    color: color.brand,
    padding: {
      top: spacing.padding,
      bottom: spacing.padding
    },
    margin: spacing.margin
  }
};
