import React from "react";
import { Pane, majorScale, Text } from "evergreen-ui";

import emptyPhoto from "../../../images/empty-chat.svg";

const EmptyView = ({ profile }) => {
  return (
    <Pane width="100%" display="flex" flexDirection="column" alignItems="center" paddingY={majorScale(7)} marginY={majorScale(7)}>
      <img src={emptyPhoto} alt="empty profile" style={{ opacity: 0.1, height: "180px" }} />
      <Text textAlign="center" size={600} marginY={majorScale(3)}>
        {profile?.name?.first} is just getting started - no activity here yet!
      </Text>
    </Pane>
  );
};

export default EmptyView;
