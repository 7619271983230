import _ from "lodash";
import React, { useState } from "react";
import { Query, Mutation, useMutation, useQuery } from "@apollo/client";
import { Link as BrowserLink } from "react-router-dom";
import { Row, Col, Container, Visible, Hidden } from "react-grid-system";
import { Button, Heading, Overlay, Pane, Paragraph, Text, Tooltip, InfoSignIcon, majorScale } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import AuthQueries from "../../queries/auth";
import ProfileList from "../../queries/profile/ProfileList";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";

const CustomizeSchema = Yup.object().shape({
  jobRoles: Yup.array().of(Yup.string()),
  title: Yup.string(),
  details: Yup.string()
});

const HEADER_TEXT = "👋 Welcome to Merit!";
const INTRO_TEXT =
  "Mentors choose topics to talk about. If you let us know more about your goals we can suggest more relevant topics and mentors for you!";
const SUBMIT_CTA = "Explore Merit";
const CANCEL_CTA = "Skip";

const CURRENT_TITLE = "What is your current job role?";
const TITLE_INFO = "We can suggest mentors, topics, and discussions based on your role.";
const CURRENT_QUESTION = "Do you have a specific career question you want advice on?";
const QUESTION_INFO = "We will share this question with relevant mentors to who can help you answer your question.";
const QUESTION_DETAILS = "Anything else we should know? (Optional)";

const SAMPLE_QUESTION = "What does it mean to be a senior product manager?";
const SAMPLE_CONTEXT = "I'm at a series B startup and my product team grew from three to five this year....";

const JOB_ROLES = ["Software Engineering", "Product Management", "Product Design", "Other"];

const renderForm = ({ roles, writePost, onCancel, hideQuestion }) => (
  <Formik
    validationSchema={CustomizeSchema}
    initialValues={{
      jobRoles: [],
      title: "",
      details: ""
    }}
    onSubmit={(variables, { setSubmitting }) => {
      writePost({ variables });
      setSubmitting(false);
    }}
    render={({ errors, status, touched, isSubmitting, values }) => (
      <Form>
        <Pane padding={majorScale(2)}>
          <Pane>
            <Heading size={700} paddingBottom={majorScale(2)}>
              {HEADER_TEXT}
            </Heading>
            <Paragraph paddingBottom={majorScale(2)}>{INTRO_TEXT}</Paragraph>
          </Pane>

          <Pane>
            <Heading paddingBottom={majorScale(2)}>{CURRENT_TITLE}</Heading>
            <Paragraph paddingBottom={majorScale(2)}>{TITLE_INFO}</Paragraph>
            <Select
              isMulti
              options={_.map(roles, (value) => ({ value, label: value }))}
              marginBottom={majorScale(2)}
              onChange={(selected) => {
                values.jobRoles = _.map(selected, (role) => role.value);
              }}
            />
          </Pane>

          <Pane>
            <Heading paddingBottom={majorScale(2)} paddingTop={majorScale(2)}>
              {CURRENT_QUESTION}
            </Heading>
            <Paragraph paddingBottom={majorScale(2)}>{QUESTION_INFO}</Paragraph>
            <Field type="text" name="title" placeholder={SAMPLE_QUESTION} component={TextInput} />
          </Pane>

          {values && values.title && !_.isEmpty(values.title) && (
            <Pane>
              <Heading paddingBottom={majorScale(2)}>{QUESTION_DETAILS}</Heading>
              <Field type="text" name="details" placeholder={SAMPLE_CONTEXT} component={TextArea} />
            </Pane>
          )}

          <Pane display="flex" justifyContent="space-between" paddingTop={majorScale(2)}>
            <Button appearance="minimal" type="button" is={BrowserLink} to={`/discover`} onClick={onCancel}>
              {CANCEL_CTA}
            </Button>

            <Button appearance="primary" type="submit" disabled={isSubmitting}>
              {SUBMIT_CTA}
            </Button>
          </Pane>
        </Pane>
      </Form>
    )}
  />
);

const CustomizeForm = (props) => {
  const [roles, setRoles] = useState(JOB_ROLES);
  const [writePost, { loading: submittingPost, error: submitError }] = useMutation(ProfileList.InitialPost, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }],
    onCompleted: (result) => {
      if (window && window.analytics) {
        window.analytics.track("Customize Profile");
      }

      props.onCompleted(result?.initialPost?.jobRoles);
    }
  });

  return (
    <Overlay isShown={true} shouldCloseOnEscape={true} shouldCloseOnClick={false} containerProps={{ overflowY: "scroll" }}>
      <Container>
        <Row justify="center">
          <Col xs={12} sm={10} md={8} lg={6} xl={6}>
            <Pane padding={majorScale(2)} marginY="12vmin" backgroundColor="white" elevation={4} borderRadius={8}>
              {renderForm({ roles, setRoles, writePost, ...props })}
            </Pane>
          </Col>
        </Row>
      </Container>
    </Overlay>
  );
};

export default CustomizeForm;
