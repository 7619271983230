import _ from "lodash";
import React from "react";
import { useQuery } from "@apollo/client";
import { Row, Col, ScreenClassRender, Hidden } from "react-grid-system";
import { Alert, Heading, Pane, majorScale, defaultTheme as theme } from "evergreen-ui";

import ProfileList from "../../queries/profile/ProfileList";
import Loader from "../../components/Loader";
import DemographicsForm from "./sections/DemographicsForm";
import AccountForm from "./sections/AccountForm";
import BackButton from "../../components/BackButton";

const ERROR_MSG = "We weren’t able to load this page. Please refresh in a few moments.";

const SettingsPage = (props) => {
  const { loading, error, data } = useQuery(ProfileList.MyCoachingProfile);
  let myProfile, myCoachingProfile;

  if (loading) return <Loader />;
  if (error) return <Alert intent="danger" title={ERROR_MSG} />;

  if (data) {
    myProfile = data.myProfile;
    myCoachingProfile = data.myCoachingProfile;
  }

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          justify="flex-start"
          style={{
            width: width[screenClass],
            backgroundColor: theme.colors.gray100,
            minHeight: "calc(100vh - 120px)",
            paddingBottom: majorScale(4)
          }}
        >
          <Hidden xs>
            <Col sm={2} md={2} lg={2} xs={12}>
              <BackButton />
            </Col>
          </Hidden>
          <Col lg={8} md={8} sm={8} xs={12}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginTop={screenClass?.includes("xs") ? majorScale(2) : majorScale(4)}
              marginBottom={screenClass?.includes("xs") ? undefined : majorScale(1)}
            >
              <Heading size={700} marginRight={majorScale(1)}>
                ⚙️ My Settings
              </Heading>
            </Pane>
            <Pane minHeight="80vh">
              {!_.isNil(myProfile) ? (
                <>
                  <DemographicsForm myProfile={myProfile} myCoachingProfile={myCoachingProfile} {...props} />
                  <AccountForm myProfile={myProfile} myCoachingProfile={myCoachingProfile} {...props} />
                </>
              ) : (
                <Loader />
              )}
            </Pane>
          </Col>
        </Row>
      )}
    />
  );
};

export default SettingsPage;
