import gql from "graphql-tag";

export default gql`
  query MyProfileQuery {
    myProfile {
      id
      fullName
      linkedin
      twitter
      website
      bio
      picture
      schedule
      checkin
      isOnboarded
      isAdmin
      name {
        first
        last
      }
      degrees {
        id
        field
        major
        start
        end
        school {
          id
          logo
          name
          website
        }
      }
      plan
      roles {
        id
        title
        startDate
        endDate
        team {
          id
          name
          logo
          website
        }
      }
    }
  }
`;
