import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Badge, Button, Pane, majorScale, toaster } from "evergreen-ui";
import * as Yup from "yup";
import { withFormik, Field, Form } from "formik";

import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import FeedQueries from "../../queries/feed";

const UpdateForm = (formProps) => (formikProps) => {
  const { onClose } = formProps;
  const { values, errors, status, touched, isSubmitting, setFieldValue, submitForm } = formikProps;

  return (
    <Pane
      marginLeft={majorScale(1)}
      display="flex"
      flexDirection="column"
      width="100%"
      background="blueTint"
      padding={majorScale(1)}
      borderRadius={5}
    >
      <Form
        padding={majorScale(2)}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {/* Details */}
        <Pane
          marginLeft={majorScale(1)}
          display="flex"
          flexDirection="column"
          width="100%"
          background="blueTint"
          padding={majorScale(1)}
          borderRadius={5}
        >
          <Field type="text" name="details" placeholder={COMMENT_PLACEHOLDER} component={TextArea} />
        </Pane>

        <Pane display="flex" justifyContent="space-between" marginTop={majorScale(2)} marginBottom={majorScale(2)}>
          <Button appearance="minimal" type="button" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button appearance="primary" isLoading={isSubmitting} type="submit">
            Update
          </Button>
        </Pane>
      </Form>
    </Pane>
  );
};

const CommentSchema = Yup.object().shape({
  details: Yup.string()
    .min(1, "Please provide some details")
    .max(10000000, "Please shorten your comment")
    .required("Please provide some details"),
  photo: Yup.string(),
  video: Yup.string()
});

const SUCCESS_TOAST = "Successfully updated.";
const COMMENT_PLACEHOLDER = "Reply to this comment...";

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

const UpdateCommentForm = (props) => {
  const { comment, myProfile, postId, onUpdate, onClose } = props;

  const [updateComment, { loading, error }] = useMutation(FeedQueries.mutations.UpdateComment, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.FeedQuery },
      { query: FeedQueries.queries.Comments, variables: { postId } },
      { query: FeedQueries.queries.Replies, variables: { commentId: comment.id } }
    ],
    onCompleted: () => {
      toaster.success(SUCCESS_TOAST, { duration: 2 });
      onUpdate();
    }
  });

  const Form = withFormik({
    validationSchema: CommentSchema,
    mapPropsToValues: () => ({
      details: comment && comment.details ? comment.details : "",
      photo: comment && comment.photo ? comment.photo : "",
      video: comment && comment.video ? comment.video : "",
      id: comment && comment.id ? comment.id : undefined
    }),
    handleSubmit: (variables, { setSubmitting }) => {
      updateComment({ variables: { postId, ...variables } });
      setSubmitting(false);
    }
  })(UpdateForm({ onUpdate, onClose }));

  return <Form />;
};

export default UpdateCommentForm;
