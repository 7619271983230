import React from "react";

const AddToSlack = () => {
  const CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID || "376719047236.490216356420";

  return (
    <a href={`https://slack.com/oauth/authorize?client_id=${CLIENT_ID}&scope=bot,chat:write:bot`}>
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
};

export default AddToSlack;
