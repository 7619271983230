// import _ from 'lodash'
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";

const setAuthorizationLink = setContext(() => {
  const token = Cookies.get("token");

  if (token) {
    const authorizationHeader = token ? `Bearer ${Cookies.get("token")}` : null;
    return {
      headers: {
        authorization: authorizationHeader
      }
    };
  }

  return {};
});

const handleErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`, `${JSON.stringify(locations, null, 2)}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Determine the backend URL for the frontend. This app can be in three
// states at a given time -- Production, A QA Box, or Local
// QA Box's run with NODE_ENV set to production but with a pre-determined API url
const backupUrl = process.env.NODE_ENV !== "production" ? "http://localhost:3001/graphql" : "https://merit-app-dev.herokuapp.com/graphql";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : backupUrl;

const baseURL = new HttpLink({ uri: BACKEND_URL });

const client = new ApolloClient({
  link: ApolloLink.from([setAuthorizationLink, handleErrors, baseURL]),
  cache: new InMemoryCache()
});

export const clearClient = () => {
  client.resetStore();
};

export default client;
