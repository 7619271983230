import React from "react";
import { Dialog, Pane, Button, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import FeedQueries from "../../queries/feed";

const PostDelete = ({ postId }) => {
  const [isShown, setIsShown] = React.useState(false);
  const [deletePost] = useMutation(FeedQueries.mutations.DeletePost, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FeedQueries.queries.FeedQuery }],
    onCompleted: () => {
      toaster.success("Post deleted.", { duration: 2 });
    }
  });

  return (
    <Dialog
      isShown={isShown}
      title="Are you sure you want to delete this post?"
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      hasFooter={false}
      preventBodyScrolling
    >
      <Pane display="flex" justifyContent="space-between" width="100%">
        <Button appearance="minimal" onClick={() => setIsShown(false)}>
          Cancel
        </Button>
        <Button appearance="primary" intent="danger" onClick={() => deletePost({ variables: { postId } })}>
          Delete
        </Button>
      </Pane>
    </Dialog>
  );
};

export default PostDelete;
