import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const removeEmoji = (str) =>
  str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "");

const Meta = ({ topics, topicCount }) => {
  const firstThreePrompts = _.chain(topics)
    .take(3)
    .map((t) => t.name)
    .map((t) => removeEmoji(t))
    .map(_.lowerCase)
    .thru((ts) => `${ts[0]}, ${ts[1]}, and ${topicCount - 2} more topics`)
    .value();

  const title = `Merit | Talk to tech leaders`;
  const description = `Get help with ${firstThreePrompts}. Tech leaders are available to talk right now.`;
  const twitterURL = `@meritmentor`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:description" property="og:description" content={description} />
      <meta name="og:url" property="og:url" content={window.location.href} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitterURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default Meta;
