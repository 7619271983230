import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

// import CoachView from "./CoachView.js"
// import CoachListPage from "./CoachListPage.js"
// import CoachListDetail from "./CoachListDetail.js"

export default withRoutes(Layout, [
  // {
  //   exact: true,
  //   path: "/coaches/all",
  //   component: CoachListDetail,
  //   state: 'Coaches',
  //   privateRoute: true,
  // },
  // {
  //   path: "/coach/:id",
  //   component: CoachView,
  //   state: 'Coaches',
  //   privateRoute: true,
  // },
]);
