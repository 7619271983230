export const aboutDiscussions = `
Discussions is where tech workers share tips, tricks, and stories about important career questions.

Start a discussion to get help from thousands of tech professionals.
`;

export const questionHeader = `How to write a great question`;

export const questions = `

1. Browse existing discussions before asking a question
    - If you like the question, even if it's unanswered, ♥  the question
    - New discussions and answers are added all of the time. Before posting a discussion we recommend browsing within an existing topic to see if your question has been asked already.
    - At best, you'll find an existing discussion and answers that can help you immediately! And if the question hasn't been posted already, you can ask the community and hopefully get more helpful adjacent advice along the way
2. Keep questions focused
    - It's impossible to answer all of life's questions in a single text box, so make it easy to get started by asking one question at a time. If you have multiple things you can figure out you can always post multiple questions in each relevant topic!
3. Be specific and actionable
    - Providing a specific question makes it easier for mentors and peers to give you helpful tips, stories, and context
    - For example, if you're looking for a job, writing "Just promoted" doesn't tell others much about what you're looking to accomplish. A better question would be, "I just got promoted to Senior Product Manager for the first time, and will now be leading projects for the first time. How should I spend my first 90 days in the job?"
4. Provide context
    - Similar to the above, giving more context to your question makes it easier for others to provide helpful advice
    - Examples of useful context include
        - Your current work situation (small company, big company, first job, in school, etc.)
        - What you've tried before - both successes and failures
        - What you think is the biggest blocker or pain point
5. Tag a topic
    - Questions are highlighted to mentors primarily through the topics questions are tagged under, so you increases the chances your question is answered by tagging it with a relevant topic
`;

export const answerHeader = `How to write a great answer`;

export const answers = `

1. Pass it forward

    Saying thanks is great, but even better is passing the favor forward. Make it easier and faster for others to get help by:

    - Liking good discussion questions and answers
    - Contributing your own answers where you believe you can help
    - Sharing discussion with others who could find it helpful
2. Answer the question being asked

    Read the discussion carefully enough to understand what's being asked. Responded with, "I don't know" or "don't do X" is not as helpful as, "I've seen XYZ work in the past". The goal is to provide an answer that can help unblock someone else's career questions.

3. If you don't know the answer, contribute your research and stories

    Careers are messy, so oftentimes career questions won't have a "perfect" answer. Even then, contributing your research, stories, and experiences can still illuminate a way forward for someone figuring it out for the first time.

    Many times hearing many of these stories can be motivating and helpful, even if only one of them isn't a perfect answer!

4. Provide links with additional context and information

    Linking out to pages with relevant information can be a really helpful way of unblocking someone, especially when they're looking to learn about a subject for the first time.

5. Answer well-asked questions

    Answering good questions increases the chances that other people reading the discussion will learn from the conversation. Avoid questions that are

    - Vague or missing context
    - Are too broad, or require too much context-setting to be answered
    - Are irrelevant or off topic
`;
