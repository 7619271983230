import _ from "lodash";
import React from "react";
import { Alert } from "evergreen-ui";
import { fromUnixTime } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";

export const formatDate = (date, timeZone) => {
  const utcDate = zonedTimeToUtc(date);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return formatInTimeZone(zonedDate, timeZone, "eeee, LLLL d");
};

const ProfilePaused = ({ isPaused, myProfile }) => {
  let endDate = isPaused?.end;

  if (!_.isNaN(Number(endDate))) {
    endDate = fromUnixTime(Number(endDate) / 1000);
  }

  return (
    <Alert intent="warning" title="Your account has been paused." style={{ width: "100%" }}>
      {`You have misssed or canceled too many sessions. You can resume booking on ${formatDate(endDate)}.`}
    </Alert>
  );
};

export default ProfilePaused;
