import React, { useState } from "react";
import { Button, Dialog, ChatIcon, Pane, Text, majorScale } from "evergreen-ui";

import EmailSignup from "../../routes/landing/EmailSignup";
import { learnersCTA } from "../../content/learners";

const SIGNUP_HEADER = "New to Merit?";
const SIGNUP_TEXT = "Sign up to talk to senior leaders in product, design & engineering.";

const ReplyItem = ({ comment, post, myProfile, onReply }) => {
  const [showSignup, setShowSignup] = useState(false);
  const clickHandler = myProfile?.id ? onReply : () => setShowSignup(true);

  return (
    <>
      <Dialog
        title={SIGNUP_HEADER}
        isShown={showSignup}
        onCloseComplete={() => setShowSignup(false)}
        preventBodyScrolling
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
        hasFooter={false}
      >
        {showSignup && (
          <>
            <Pane display="flex" flexDirection="row" width="100%" marginBottom={majorScale(3)}>
              <Text>{SIGNUP_TEXT}</Text>
            </Pane>
            <EmailSignup mode="Learn" cta={learnersCTA} showGoogle={true} />
          </>
        )}
      </Dialog>
      <Button
        key={`ReplyItem-${comment?.id ? comment.id : post?.id}`}
        size="small"
        fontWeight={600}
        display="flex"
        alignItems="center"
        justifyContent="center"
        appearance="minimal"
        fontSize={14}
        borderRadius={999}
        height={28}
        style={{ borderRadius: 99 }}
        onClick={clickHandler}
      >
        <ChatIcon color="#8F95B2" marginRight={5} />
        Reply
      </Button>
    </>
  );
};

export default ReplyItem;
