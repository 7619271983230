import _ from "lodash";
import React from "react";
import { Pane, Heading, Text, Paragraph, Select as EvergreenSelect, majorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";

const SESSIONS_PER_MONTH = `Update the maximum number of sessions you want per month.`;

const EditMaxSessions = ({ myCoachingProfile }) => {
  const currentMaxSessions = myCoachingProfile.maxSessions;
  const [updateMaxSessions] = useMutation(ProfileList.UpdateMaxSessions, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }],
    onCompleted: () => {
      window.analytics.track("Update Max Sessions");
    }
  });

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
      <Pane>
        <Heading marginBottom={majorScale(1)}>📊 &nbsp; How many sessions do you want?</Heading>
        <Pane display="flex">
          <Pane flex={1}>
            <Paragraph size={500}>{SESSIONS_PER_MONTH}</Paragraph>
          </Pane>
          <Pane>
            <EvergreenSelect
              onChange={(e) => {
                const maxSessions = e.target.value;
                updateMaxSessions({ variables: { maxSessions } });
                toaster.success("Max sessions updated.");
              }}
            >
              {_.map(_.range(1, 11), (option) => (
                <option selected={_.isEqual(option, currentMaxSessions)}>{option}</option>
              ))}
            </EvergreenSelect>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default EditMaxSessions;
