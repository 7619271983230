import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { fromUnixTime, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { Button, TimeIcon, CaretDownIcon, CaretUpIcon, GlobeIcon, Heading, Pane, SelectMenu, Spinner, majorScale } from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";
import moment from "moment-timezone";

import SessionQueries from "../../../queries/session";

const timezones = moment.tz.names();

const formatTime = (start, timeZone) => {
  let formatted = formatInTimeZone(start, timeZone, "hh:mm b");
  formatted = formatted.replace("noon", "PM");

  return formatted;
};

const convertDate = (time) => {
  if (!_.isNaN(Number(time))) {
    return fromUnixTime(Number(time) / 1000);
  } else return time;
};

const groupByDay = (timeslots) => {
  return _.chain(timeslots)
    .map(({ start, end }) => ({
      start: convertDate(start),
      end: convertDate(end)
    }))
    .groupBy(({ start }) => format(start, "yyyy-MM-dd"))
    .value();
};

const formatDayButton = (sample) => {
  return { theDay: format(sample, "EEE"), date: format(sample, "MMM dd") };
};

const ChooseTime = (chooseTimeContext) => {
  const { isMobile, title, onSelect, mentorId, meetingLength, myProfile, hideSelect, onChooseTz } = chooseTimeContext;

  const [day, setDay] = useState(null);
  const [time, setTime] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const [showMoreDays, setShowMoreDays] = useState(false);
  const [showMoreTimes, setShowMoreTimes] = useState(false);

  const { data, loading: slotsLoading } = useQuery(SessionQueries.queries.UpcomingSlots, {
    variables: {
      mentorId
    }
  });

  let slots, dates;
  const cutoff = 4;
  const numItems = showMoreDays ? data?.upcomingSlots.length : cutoff;

  if (data?.upcomingSlots?.length > 0) {
    slots = groupByDay(data?.upcomingSlots);
    dates = _.chain(slots).keys().sort().value();

    if (_.isNull(day)) {
      setDay(_.keys(slots)[0]);
      setTimeZone(myProfile?.timezone ? myProfile?.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }

  return (
    <>
      {!slots ? (
        <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Heading marginBottom={majorScale(1)}>Loading availability</Heading>
          <Spinner />
        </Pane>
      ) : (
        <>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={_.keys(slots)?.length > cutoff ? majorScale(1) : null}
          >
            <Heading marginBottom={_.keys(slots)?.length > cutoff ? null : majorScale(1)}>{title}</Heading>
            {_.keys(slots)?.length > cutoff && (
              <Button
                type="button"
                appearance="minimal"
                iconBefore={showMoreDays ? CaretUpIcon : CaretDownIcon}
                onClick={() => setShowMoreDays(!showMoreDays)}
              >
                {showMoreDays ? "Fewer days" : "More days"}
              </Button>
            )}
          </Pane>
          <Pane
            width="100%"
            display="grid"
            gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)"}
            gridColumnGap={majorScale(1)}
            gridRowGap={majorScale(1)}
            marginBottom={majorScale(2)}
          >
            {dates.slice(0, numItems)?.map((slot, index) => {
              const sample = slots[slot][0]["start"];
              const { theDay, date } = formatDayButton(sample);

              return (
                <Button
                  type="button"
                  size="large"
                  isActive={day === slot}
                  onClick={() => {
                    setDay(slot);
                    setTime(null);
                  }}
                >
                  {`${theDay} ${date}`}
                </Button>
              );
            })}
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={slots[day]?.length > cutoff ? majorScale(1) : null}
          >
            {parseInt(meetingLength) && parseInt(meetingLength) > 0 ? (
              <Heading marginBottom={slots[day]?.length > cutoff ? null : majorScale(1)}>
                {`Available times (${meetingLength} mins)`}
              </Heading>
            ) : (
              <Heading marginBottom={slots[day]?.length > cutoff ? null : majorScale(1)}>Available times</Heading>
            )}
            {slots[day]?.length > cutoff && (
              <Button
                appearance="minimal"
                iconBefore={showMoreTimes ? CaretUpIcon : CaretDownIcon}
                onClick={() => setShowMoreTimes(!showMoreTimes)}
              >
                {showMoreTimes ? "Fewer times" : "More times"}
              </Button>
            )}
          </Pane>
          <Pane
            width="100%"
            display="grid"
            gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)"}
            gridColumnGap={majorScale(1)}
            gridRowGap={majorScale(1)}
            marginBottom={majorScale(2)}
          >
            {_.map(_.take(slots[day], showMoreTimes ? slots[day]?.length : 4), (slot, index) => (
              <Button
                type="button"
                size="large"
                isActive={time && format(time?.start, "hh:mm") === format(slot?.start, "hh:mm")}
                onClick={() => {
                  setTime(slot);

                  if (hideSelect) {
                    onSelect(slot);
                  }
                }}
              >
                {formatTime(slot?.start, timeZone, "hh:mm b")}
              </Button>
            ))}
          </Pane>
          <Pane display="flex" flexDirection="row" justifyContent="space-between">
            <SelectMenu
              title={timeZone}
              selected={timeZone}
              options={_.map(timezones, (tz) => ({
                value: tz,
                label: tz
              }))}
              onSelect={(item) => {
                setTimeZone(item?.value);

                if (onChooseTz && _.isFunction(onChooseTz)) {
                  onChooseTz(item?.value);
                }
              }}
            >
              <Button type="button" iconBefore={GlobeIcon} appearance="minimal">
                {timeZone}
              </Button>
            </SelectMenu>
            {!hideSelect && (
              <Button type="button" iconBefore={TimeIcon} appearance="primary" disabled={_.isNull(time)} onClick={() => onSelect(time)}>
                Select time
              </Button>
            )}
          </Pane>
        </>
      )}
    </>
  );
};

const ChooseTimeContainer = (chooseTimeContext) => {
  return <ScreenClassRender render={(screenClass) => <ChooseTime isMobile={screenClass?.includes("xs")} {...chooseTimeContext} />} />;
};

export default ChooseTimeContainer;
