import gql from "graphql-tag";

import QueryUtils from "../utils";
import ProfileList from "../profile/ProfileList";
import CoachList from "../../routes/coach/CoachList";

const PlaylistQueries = {
  fragments: {
    playlist: `
      id,
      name,
      description,
      slug,
    `
  }
};

PlaylistQueries.PlaylistDetail = gql`
  query PlaylistDetail($playlistID: String, $slug: String) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    playlist(id: $playlistID, slug: $slug) {
      id,
      name,
      coaches {
        ${ProfileList.fragments.coach},
        user {
          ${ProfileList.fragments.profile}
        }
      }
    }
  }
`;

PlaylistQueries.playlists = gql`
  query PlaylistQuery($topicName: [String!]!) {
    playlists(topic: $topicName) {
      id
      name
      description
    }
    topics {
      id
      name
      description
    }
  }
`;

PlaylistQueries.GetPlaylist = gql`
  query PlaylistQuery($playlistId: String!) {
    playlist(id: $playlistId) {
      id
      name
      description
      motivations
      topics {
        id
        name
      }
    }
  }
`;

PlaylistQueries.PlaylistsQuery = gql`
  query PlaylistsQuery($topicName: [String!], $cursor: String) {
    topics {
      id,
      name,
      description
    }
    playlists(topic: $topicName, cursor: $cursor) {
      __typename,
      edges {
        ${PlaylistQueries.fragments.playlist}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

PlaylistQueries.MorePlaylistsQuery = gql`
  query PlaylistsQuery($topicName: [String!], $cursor: String) {
    playlists(topic: $topicName, cursor: $cursor) {
      __typename,
      edges {
        ${PlaylistQueries.fragments.playlist},
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

PlaylistQueries.ChoosePlaylistsQuery = gql`
  query PlaylistsQuery($topicName: [String!], $cursor: String) {
    myCoachingProfile {
      ${ProfileList.fragments.coach}
    },
    myProfile {
      ${ProfileList.fragments.profile},
    },
    topics {
      id,
      name,
      description
    }
    playlists(topic: $topicName, cursor: $cursor) {
      edges {
        ${PlaylistQueries.fragments.playlist},
        motivations,
        coaches {
          id,
          user {
            id
          }
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

PlaylistQueries.MoreChoosePlaylists = gql`
  query PlaylistsQuery($topicName: [String!], $cursor: String) {
    playlists(topic: $topicName, cursor: $cursor) {
      __typename,
      edges {
        ${PlaylistQueries.fragments.playlist},
        coaches {
          id,
          user {
            id,
          }
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

PlaylistQueries.ChooseRemovePlaylistMutation = gql`
  mutation ChooseRemovePlaylistMutation($playlistId: String!) {
    addRemoveCoach(playlistId: $playlistId) {
      id
      name
    }
  }
`;

const TopicSearchQuery = {
  vars: `
    $name: String,
    $levels: [String],
    $roles: [String],
    $motivations: [String],
    $sort: String,
    $pageSize: Int,
  `,
  args: `
    name: $name,
    levels: $levels,
    roles: $roles,
    motivations: $motivations,
    sort: $sort,
    pageSize: $pageSize
  `
};

PlaylistQueries.TopicSearchQueryOptions = TopicSearchQuery;

PlaylistQueries.TopicSearchQuery = gql`
  query TopicSearchQuery(${TopicSearchQuery.vars}, $cursor: String) {
    topicSearch(${TopicSearchQuery.args}, cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        id,
        name,
        description,
        level,
        jobRole,
        motivations,
        slug,
      }
    }
    topicFilters {
      availableJobRoles,
      availableLevels,
      availableMotivations,
      availableSorts
    }
  }
`;

PlaylistQueries.TopicFormQuery = gql`
  query TopicSearchQuery(${TopicSearchQuery.vars}, $cursor: String) {
    topicSearch(${TopicSearchQuery.args}, cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        id,
        name,
        description,
        level,
        jobRole,
        motivations,
        coaches {
          id,
          user {
            id
          }
        }
      }
    }
    topicFilters {
      availableJobRoles,
      availableLevels,
      availableMotivations,
      availableSorts
    }
  }
`;

PlaylistQueries.MoreTopicsSearchQuery = gql`
  query TopicSearchQuery(${TopicSearchQuery.vars}, $cursor: String) {
    topicSearch(${TopicSearchQuery.args}, cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        id,
        name,
        level,
        description,
        jobRole,
        motivations,
        availableSorts
      }
    }
  }
`;

PlaylistQueries.TopicsByIds = gql`
  query TopicsById($ids: [String]) {
    topicsByIds(ids: $ids) {
      ${PlaylistQueries.fragments.playlist}
    }
  }

`;

export default PlaylistQueries;
