import _ from "lodash";
import React from "react";
import queryString from "query-string";
import { fromUnixTime, format } from "date-fns";
import { Container, Row, Col } from "react-grid-system";
import { Link as BrowserLink } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { Alert, Button, Card, Pane, Paragraph, Link, Heading, Text, majorScale } from "evergreen-ui";

import PageTitle from "../../components/navigation/PageTitle";
import Loader from "../../components/Loader";

const PAGE_LOAD_ERROR = "We were not able to load this page. Please try refreshing.";

const ZOOM_SCHEDULER_QUERY = gql`
  query CurrentScheduler {
    schedulerZoomAuthLink
    scheduler {
      id
      email
      createdAt
      updatedAt
      zoomId
      meetingUrl
    }
  }
`;

const formatDate = (date) => {
  return format(fromUnixTime(date / 1000), "MMM do yyyy HH:MM");
};

const renderExistingScheduler = (schedulerContext) => {
  const { id, email, createdAt, updatedAt, meetingUrl } = schedulerContext;

  return (
    <Pane minHeight="50vh" marginTop={majorScale(2)}>
      <Pane elevation={1} padding={majorScale(2)}>
        <Pane padding={majorScale(1)}>
          <Heading>{`Current scheduler: ${email}`}</Heading>
          <Text>{`Created at ${formatDate(createdAt)}`}</Text>
          <Text>{`Last updated at ${formatDate(updatedAt)}`}</Text>
        </Pane>
      </Pane>
    </Pane>
  );
};

const createScheduler = (link) => (
  <Pane minHeight="50vh" marginTop={majorScale(2)}>
    <Pane elevation={1} padding={majorScale(2)}>
      <Pane padding={majorScale(1)}>
        <Text>Press below to create a scheduler.</Text>
        <Pane display="flex" justifyContent="flex-end">
          <Button marginTop={majorScale(2)} is="a" href={link} appearance="primary">
            Create Scheduler
          </Button>
        </Pane>
      </Pane>
    </Pane>
  </Pane>
);

const ZoomScheduler = (props) => {
  const { data, loading, error } = useQuery(ZOOM_SCHEDULER_QUERY);

  let content;
  let title = "Create a scheduler";

  if (loading) {
    content = <Loader />;
  }
  if (error) {
    content = <Alert intent="danger" title={PAGE_LOAD_ERROR} />;
  }
  if (data) {
    const { schedulerZoomAuthLink, scheduler } = data;
    title = "Current Scheduler";
    content = scheduler && !_.isNil(scheduler) ? renderExistingScheduler(scheduler) : createScheduler(schedulerZoomAuthLink);
  }

  return (
    <Container>
      <PageTitle title={title} />
      <Row>
        <Col lg={6} md={8} sm={10} offset={{ lg: 3, md: 2 }}>
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default ZoomScheduler;
