import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

import MentorsPage from "./MentorsPage";
import NewMentorsPage from "./NewMentorsPage";

export default withRoutes(Layout, [
  {
    exact: true,
    path: "/mentors",
    component: NewMentorsPage,
    state: "Discover"
  }
  // {
  //   exact: true,
  //   path: "/new-mentors",
  //   component: NewMentorsPage,
  //   state: 'Discover'
  // },
]);
