import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import {
  Badge,
  Button,
  Card,
  DocumentOpenIcon,
  EditIcon,
  Heading,
  InlineAlert,
  Pane,
  Paragraph,
  UploadIcon,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";

import CandidateQueries from "../../../queries/candidate";

import EditCandidateWorkExperience from "../edit/candidate/EditCandidateWorkExperience";
import EditCandidateDocs from "../edit/candidate/EditCandidateDocs";

const ExpertiseBar = ({ yearsOfExperience, expertise }) => {
  if (!expertise?.proficiency || !expertise?.skill) return null;
  const level = yearsOfExperience?.indexOf(expertise?.proficiency) + 1;
  const width = (level / yearsOfExperience?.length) * 100;

  return (
    <Pane
      style={{ width: `${width}%` }}
      backgroundColor={theme?.colors?.green300}
      minHeight={majorScale(2)}
      display="flex"
      alignItems="center"
      paddingY={minorScale(1)}
      paddingX={majorScale(1)}
      borderBottomLeftRadius={majorScale(2)}
      borderTopLeftRadius={majorScale(2)}
    >
      <Heading size={100}>{expertise?.proficiency}</Heading>
    </Pane>
  );
};

const findMaxYearsOfExperience = ({ yearsOfExperience, specialities, jobRole }) => {
  const hasCoreJobRole = _.find(specialities, ({ jobRole }) =>
    ["Software Engineering", "Product Management", "Product Design", "Design"].includes(jobRole)
  );
  if (!hasCoreJobRole) return "0-1 years";

  return specialities?.length > 0
    ? specialities.reduce((max, speciality) => {
        const level = yearsOfExperience?.indexOf(speciality?.proficiency);
        return level > yearsOfExperience?.indexOf(max) ? speciality?.proficiency : max;
      }, "0-1 years")
    : null;
};

const CandidateSkills = ({ profile, isEditing, location, isRecruiter }) => {
  const { section } = qs.parse(location?.search, { arrayFormat: "comma" });
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile, {
    variables: {
      profileId: profile?.id
    }
  });
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);

  const [showWorkExperience, setShowWorkExperience] = useState(section === "skills");

  const [showDocs, setShowDocs] = useState(false);

  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const yearsOfExperience = profileOptions?.availableYearsOfExperience;
  const maxExperience = findMaxYearsOfExperience({
    yearsOfExperience,
    jobRole: myCandidateProfile?.jobRole,
    specialities: myCandidateProfile?.specialities
  });

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass.includes("xs");

        return !myCandidateProfile?.id || _.isEqual(myCandidateProfile?.status, "inactive") ? null : (
          <Card
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            backgroundColor="white"
            padding={majorScale(2)}
            marginBottom={majorScale(3)}
            marginTop={majorScale(3)}
            minHeight="100px"
            id="skills"
          >
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
              <Pane display="flex" flexDirection="row" alignItems="center">
                <Heading fontWeight={500}>
                  <span style={{ marginRight: "6px" }}>⭐️</span>
                  Skills and Experience
                </Heading>
                <EditCandidateWorkExperience
                  showWorkExperience={showWorkExperience}
                  setShowWorkExperience={setShowWorkExperience}
                  myCandidateProfile={myCandidateProfile}
                  myProfile={profile}
                />
              </Pane>
              {!isRecruiter && <InlineAlert>Only visible our hiring partners</InlineAlert>}
            </Pane>

            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
              <Heading size={400}>Resume and Portfolio</Heading>
              <EditCandidateDocs
                showDocs={showDocs}
                setShowDocs={setShowDocs}
                myCandidateProfile={myCandidateProfile}
                myProfile={profile}
              />
              <Pane>
                {myCandidateProfile?.resume?.url?.length > 0 && (
                  <Button
                    is="a"
                    href={myCandidateProfile?.resume?.url}
                    target="_blank"
                    type="button"
                    iconBefore={DocumentOpenIcon}
                    overflowX="clip"
                  >
                    View resume
                  </Button>
                )}
                {myCandidateProfile?.portfolio?.length > 0 && (
                  <Button
                    is="a"
                    href={myCandidateProfile?.portfolio}
                    target="_blank"
                    type="button"
                    iconBefore={DocumentOpenIcon}
                    overflowX="clip"
                    marginLeft={majorScale(2)}
                  >
                    View portfolio
                  </Button>
                )}
              </Pane>
              {isEditing && (
                <Button appearance="minimal" onClick={() => setShowDocs(true)}>
                  <EditIcon />
                </Button>
              )}
            </Pane>

            {myCandidateProfile?.jobRole?.length > 0 ? (
              <Pane>
                <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={majorScale(1)}>
                  <Heading size={400} marginRight={majorScale(1)}>
                    Expertise
                  </Heading>
                  {isEditing && (
                    <Button appearance="minimal" onClick={() => setShowWorkExperience(true)}>
                      <EditIcon />
                    </Button>
                  )}
                </Pane>

                <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={majorScale(1)}>
                  <Paragraph size={300} marginBottom={majorScale(1)} minWidth={majorScale(20)} marginRight={majorScale(2)}>
                    {myCandidateProfile?.jobRole}
                  </Paragraph>
                  <ExpertiseBar
                    yearsOfExperience={yearsOfExperience}
                    expertise={{
                      skill: myCandidateProfile?.jobRole,
                      proficiency: maxExperience
                    }}
                  />
                </Pane>
              </Pane>
            ) : (
              <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={majorScale(1)}>
                <Heading size={400} marginRight={majorScale(1)}>
                  Expertise
                </Heading>
                {isEditing && (
                  <Button appearance="minimal" onClick={() => setShowWorkExperience(true)}>
                    <EditIcon />
                  </Button>
                )}
              </Pane>
            )}

            {myCandidateProfile?.specialities?.length > 0 && (
              <Pane>
                <Heading size={400} marginBottom={majorScale(1)}>
                  In order of proficiency
                </Heading>
                {myCandidateProfile?.specialities.map((speciality, index) => (
                  <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={majorScale(1)}>
                    <Paragraph size={300} marginBottom={0} minWidth={majorScale(20)} marginRight={majorScale(2)}>
                      {speciality?.skill}
                    </Paragraph>
                    <ExpertiseBar yearsOfExperience={yearsOfExperience} expertise={speciality} />
                  </Pane>
                ))}
              </Pane>
            )}

            {myCandidateProfile?.frameworks?.length > 0 && (
              <Pane>
                <Heading size={400} marginBottom={majorScale(1)}>
                  Frameworks
                </Heading>
                <Pane display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                  {myCandidateProfile?.frameworks.map((framework, index) => (
                    <Badge marginTop={majorScale(1)} marginRight={majorScale(1)} color="blue">
                      {framework}
                    </Badge>
                  ))}
                </Pane>
              </Pane>
            )}
          </Card>
        );
      }}
    />
  );
};

export default withRouter(CandidateSkills);
