import React, { useState } from "react";
import { Button, Pane, majorScale } from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";

import CommentForm from "./CommentForm";

const WRITE_COMMENT_TITLE = "Comment";

const CreateComment = ({ postId }) => {
  const [isShown, setShown] = useState(false);

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={["xs"].includes(screenClass) ? null : majorScale(6)}
          paddingTop={majorScale(2)}
          justifyContent="flex-end"
          width="100%"
        >
          {isShown ? (
            <CommentForm postId={postId} onUpdate={() => setShown(false)} onClose={() => setShown(false)} />
          ) : (
            <Button
              onClick={() => {
                window.analytics.track("Feed -> Answer the question");
                setShown(true);
              }}
            >
              {WRITE_COMMENT_TITLE}
            </Button>
          )}
        </Pane>
      )}
    />
  );
};

export default CreateComment;
