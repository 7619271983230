import React from "react";
import ReactMarkdown from "react-markdown";
import { Row, Col, Container } from "react-grid-system";
import injectSheet from "react-jss";
import { Helmet } from "react-helmet";

import theme from "../../components/theme";

import content from "../../content/conduct";
import overview from "../../content/conduct-overview";

const styles = {
  h1: {
    ...theme.h1,
    textAlign: "center",
    padding: 0,
    margin: 0,
    paddingTop: theme.spacing.grid * 2.5
  },
  contact: {
    textAlign: "left",
    ...theme.p
  }
};

class AboutPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <link href={theme.fonts} rel="stylesheet" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Merit | Conduct</title>
        </Helmet>
        <Container>
          <Row align="center" justify="center">
            <Col md={10}>
              <div className={classes.h1}>Code of Conduct</div>
            </Col>
          </Row>
          <Row align="center" justify="center">
            <Col md={8}>
              <ReactMarkdown className={classes.contact}>{overview}</ReactMarkdown>
            </Col>
          </Row>
          <Row align="center" justify="center">
            <Col md={8}>
              <ReactMarkdown className={classes.contact}>{content}</ReactMarkdown>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default injectSheet(styles)(AboutPage);
