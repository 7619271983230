import _ from "lodash";
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import { Helmet } from "react-helmet";
import { Alert, Button, Heading, Pane, CalendarIcon, PersonIcon, StarIcon, Paragraph, Text, majorScale, toaster } from "evergreen-ui";
import { Row, Col, Container } from "react-grid-system";
import { Link as BrowserLink } from "react-router-dom";

import SessionQueries from "../../queries/session";
import LoadingView from "../profile/components/LoadingView";
import SessionCard from "../../components/session/SessionCard";

const ERROR_MSG = "There was an error loading this page. Please refresh in a few moments.";

const otherUser = ({ user, coach, myProfile }) => {
  return _.isEqual(myProfile?.id, coach?.user?.id) ? user : coach.user;
};

const Meta = () => {
  const title = "Review Submitted | Merit";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:url" property="og:url" content={window.location.href} />
      <meta name="twitter:title" property="twitter:title" content={title} />
    </Helmet>
  );
};

const MissingReview = ({ session, myProfile }) => {
  const other = otherUser({ ...session, myProfile });

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
      <Button appearance="secondary" iconBefore={PersonIcon} is={BrowserLink} to={`/p/${other?.slug}`} marginBottom={majorScale(1)}>
        View Feedback
      </Button>
      <Button appearance="primary" iconBefore={StarIcon} is={BrowserLink} to={`/sessions/previous`} marginBottom={majorScale(1)}>
        Submit More Feedback
      </Button>
    </Pane>
  );
};

const BookAgain = ({ session, myProfile }) => {
  const other = otherUser({ ...session, myProfile });

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
      <Button appearance="secondary" iconBefore={PersonIcon} is={BrowserLink} to={`/p/${other?.slug}`} marginBottom={majorScale(1)}>
        View Feedback
      </Button>
      <Button appearance="primary" iconBefore={CalendarIcon} is={BrowserLink} to={`/calendar/${other?.id}`} marginBottom={majorScale(1)}>
        Book Another Session
      </Button>
    </Pane>
  );
};

const MentorMissingFeedback = () => {
  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
      <Button appearance="secondary" iconBefore={PersonIcon} is={BrowserLink} to="/sessions/upcoming" marginBottom={majorScale(1)}>
        View Upcoming Activity
      </Button>
      <Button appearance="primary" iconBefore={StarIcon} is={BrowserLink} to={`/sessions/previous`} marginBottom={majorScale(1)}>
        Submit More Feedback
      </Button>
    </Pane>
  );
};

const MentorMoreActivity = () => {
  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
      <Button appearance="secondary" iconBefore={PersonIcon} is={BrowserLink} to="/sessions/upcoming" marginBottom={majorScale(1)}>
        View Upcoming Activity
      </Button>
      <Button
        appearance="primary"
        iconBefore={StarIcon}
        is={BrowserLink}
        to={`/discussions?cursor=1&sort=RECENT`}
        marginBottom={majorScale(1)}
      >
        Answer Recent Questions
      </Button>
    </Pane>
  );
};

const FeedbackSubmittedContainer = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.SessionAndReview, {
    variables: {
      sessionID: id
    }
  });

  const { data: myFeedbackRequests } = useQuery(SessionQueries.queries.MissingReviews);

  const title = "Feedback Submitted!";
  let wasMemberReview;

  if (sessionAndReview?.sessionById) {
    const { sessionById, myProfile } = sessionAndReview;
    const other = otherUser({ ...sessionById, myProfile });

    wasMemberReview = _.isEqual(other?.id, sessionById?.user?.id);
  }

  return (
    <>
      <Meta />
      <div style={{ width: "100%" }}>
        <Col md={8} offset={{ md: 2 }}>
          {loading && <LoadingView />}
          {error && <Alert intent="danger" title={ERROR_MSG} />}
          {sessionAndReview && myFeedbackRequests && (
            <Pane display="flex" flexDirection="column" paddingX={majorScale(2)} marginTop={majorScale(2)}>
              <Pane display="flex">
                <Heading size={500} marginBottom={majorScale(3)}>
                  <StarIcon color="#8F95B2" marginRight={majorScale(1)} size={majorScale(2)} />
                  {title}
                </Heading>
              </Pane>
              <SessionCard myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />
              {wasMemberReview ? (
                <>{myFeedbackRequests?.length > 0 ? <MentorMissingFeedback /> : <MentorMoreActivity />}</>
              ) : (
                <>
                  {myFeedbackRequests?.length > 0 ? (
                    <MissingReview myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />
                  ) : (
                    <BookAgain myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />
                  )}
                </>
              )}
            </Pane>
          )}
        </Col>
      </div>
    </>
  );
};

export default FeedbackSubmittedContainer;
