import React from "react";
import Moment from "moment";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { Button, Pane, majorScale, ArrowUpIcon, ArrowDownIcon, toaster } from "evergreen-ui";
import AsyncSelect from "react-select/async";

import TextInput from "../../components/form/TextInput";
import MyProfileQuery from "../../components/profile/MyProfileQuery";
import ProfileList from "../../queries/profile/ProfileList";
import { clearbitOrgOptions, createOption } from "../../components/profile/AutoFill";

const DegreeSchema = Yup.object().shape({
  field: Yup.string().min(1, "Please include your field").max(140, "Please shorten the field of study you put in"),
  major: Yup.string().min(1, "Please include your major").max(140, "Please shorten the major you put in")
});

const formatMonth = (date) => {
  if (!date) return "";

  date = new Moment(new Date(Number(date)));
  const formatted = date.format("YYYY-MM");

  return formatted;
};

const DegreeForm = ({ degree, onMove, onDelete, items, position }) => {
  const [mutation] = useMutation(degree && degree.id ? ProfileList.UpdateDegree : ProfileList.CreateDegree, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MyProfileQuery
      }
    ],
    onCompleted: () => toaster.success("Updated degree.", { duration: 2, id: "updated-degree" })
  });

  const selectedSchool = degree?.school && createOption(degree.school.name, degree.school.logo, degree.school.website);

  return (
    <Formik
      validationSchema={DegreeSchema}
      initialValues={{
        degreeID: degree && degree.id ? degree.id : "",
        fieldName: degree && degree.field ? degree.field : "",
        majorName: degree && degree.major ? degree.major : "",
        schoolName: degree && degree.school && degree.school.name ? degree.school.name : "",
        schoolID: degree && degree.school && degree.school.id ? degree.school.id : "",
        schoolDomain: degree && degree.school && degree.school.website ? degree.school.website : "",
        schoolLogo: degree && degree.school && degree.school.logo ? degree.school.logo : "",
        start: degree && degree.start ? formatMonth(degree.start) : "",
        end: degree && degree.end ? formatMonth(degree.end) : ""
      }}
      onSubmit={(args, { setSubmitting, setValues }) => {
        mutation({ variables: { ...args } });
        setSubmitting(false);
        setValues({ ...args });
        window.analytics.track("Update Education History");
      }}
      render={({ values, errors, status, touched, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Pane marginBottom={majorScale(2)} display="flex" width="100%" flexDirection="column">
            <Pane paddingBottom={majorScale(2)}>
              <AsyncSelect
                cacheOptions
                defaultValue={selectedSchool}
                onChange={(selected) => {
                  const { domain, logo, name } = selected.value;

                  setFieldValue("schoolName", name, false);
                  setFieldValue("schoolLogo", logo, false);
                  setFieldValue("schoolDomain", domain, false);
                  setFieldValue("schoolID", null, false);

                  submitForm();
                }}
                loadOptions={clearbitOrgOptions}
                defaultOptions
              />
            </Pane>
            <Field
              type="text"
              name="fieldName"
              placeholder="Field of Study e.g. (B.S., B.A., A.B.)"
              component={TextInput}
              marginBottom={0}
              onBlur={(e) => {
                e.persist();
                setFieldValue("fieldName", e.target.value, false);
                submitForm();
              }}
            />
            <Field
              type="text"
              name="majorName"
              placeholder="Economics"
              component={TextInput}
              onBlur={(e) => {
                e.persist();
                setFieldValue("majorName", e.target.value, false);
                submitForm();
              }}
            />
            <Pane width="100%" display="flex" justifyContent="space-between">
              {items?.length > 1 && (
                <Pane>
                  {position !== 0 && (
                    <Button appearance="minimal" onClick={() => onMove("up")}>
                      <ArrowUpIcon />
                    </Button>
                  )}
                  {position !== items.length - 1 && (
                    <Button appearance="minimal" onClick={() => onMove("down")}>
                      <ArrowDownIcon />
                    </Button>
                  )}
                </Pane>
              )}
              <Button type="button" appearance="minimal" intent="danger" onClick={() => onDelete()}>
                Delete Degree
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default DegreeForm;
