import _ from "lodash";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFigma } from "@fortawesome/free-brands-svg-icons";
import { Badge, Button, Dialog, Heading, Pane, Paragraph, PlusIcon, toaster, majorScale, minorScale } from "evergreen-ui";
import { Formik, Form, Field } from "formik";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";

import CandidateQueries from "../../../../queries/candidate";
import FileUploader from "../../../../components/FileUploader";
import TextInput from "../../../../components/form/TextInput";

// https://stackoverflow.com/questions/13927099/validate-url-with-or-without-protocol
const URL_REGEX = /(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+?\.(?:[a-zA-Z])|\d+\.\d+\.\d+\.\d+)/;

const CandidateDocForm = ({ myCandidateProfile, type }) => {
  const [uploadResume] = useMutation(CandidateQueries.mutations.UploadDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CandidateQueries.queries.MyCandidateProfile
      }
    ],
    onCompleted: () => {
      toaster.success(`Successfully uploaded ${type}`, { duration: 2, id: type });
    }
  });

  return myCandidateProfile?.readingResume ? (
    <Pane marginBottom={majorScale(2)}>
      <Heading>{`Your ${type}`}</Heading>
      <Paragraph marginBottom={majorScale(1)}>{`Reading your ${type} for you...`}</Paragraph>
      <Button isLoading={true} type="button" appearance="minimal" iconBefore={PlusIcon}>
        {`${myCandidateProfile?.[type]?.filename || type}...`}
      </Button>
    </Pane>
  ) : (
    <Formik
      initialValues={{
        url: (myCandidateProfile && myCandidateProfile[type]?.url) || "",
        filename: (myCandidateProfile && myCandidateProfile[type]?.filename) || ""
      }}
      onSubmit={({ url, filename }, { setSubmitting }) => {
        uploadResume({
          variables: {
            readResume: myCandidateProfile[type]?.url ? false : true,
            url,
            filename,
            type
          }
        });

        setSubmitting(false);
      }}
      render={({ values, setFieldValue, submitForm }) => {
        return (
          <Form>
            <Pane marginBottom={majorScale(2)}>
              <Heading>{`Your ${type}`}</Heading>
              <Paragraph marginBottom={majorScale(1)}>
                {values?.url?.length > 0 && values?.filename?.length > 0
                  ? `If you have it handy, please upload your ${type}.`
                  : `If you have it handy, please upload your ${type}. We’ll automatically fill in your profile using AI and notify you when it’s done! ✨`}
              </Paragraph>
              <FileUploader
                title={values?.filename || `Upload your ${type}`}
                onChange={({ url, filename }) => {
                  setFieldValue("url", url, false);
                  setFieldValue("filename", filename, false);

                  setTimeout(() => {
                    submitForm();
                  }, 100);
                }}
              />
            </Pane>
          </Form>
        );
      }}
    />
  );
};

const EditPortfolio = ({ myCandidateProfile, myProfile }) => {
  const [updatePortfolio] = useMutation(CandidateQueries.mutations.UpdatePortfolio, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CandidateQueries.queries.MyCandidateProfile
      }
    ],
    onCompleted: () => {
      toaster.success("Successfully updated your portfolio");
    }
  });

  return (
    <Formik
      initialValues={{
        portfolio: myCandidateProfile?.portfolio || ""
      }}
      validationSchema={Yup.object().shape({
        portfolio: Yup.string().matches(URL_REGEX, "Please provide a valid website")
      })}
      onSubmit={({ portfolio }, { setSubmitting }) => {
        updatePortfolio({
          variables: {
            portfolio
          }
        });

        setSubmitting(false);
      }}
      render={({ setFieldValue, errors, touched, submitForm }) => {
        return (
          <Form>
            <Pane marginBottom={majorScale(2)}>
              <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={majorScale(1)}>
                <Heading paddingBottom={minorScale(2)}>Your portfolio URL</Heading>
                <Button
                  is="a"
                  href="https://www.figma.com/"
                  target="_blank"
                  appearance="minimal"
                  iconBefore={<FontAwesomeIcon icon={faFigma} />}
                >
                  Find a Figma
                </Button>
              </Pane>
              <Field
                type="text"
                name="portfolio"
                placeholder="https://www.figma.com/proto/abc/my-portfolio"
                component={TextInput}
                height={majorScale(5)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("portfolio", e.target.value, false);
                  submitForm();
                }}
              />
              {touched["portfolio"] && errors["portfolio"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["portfolio"]}
                </Badge>
              )}
            </Pane>
          </Form>
        );
      }}
    />
  );
};

const EditCandidateDocs = ({ showDocs, setShowDocs, myCandidateProfile, myProfile }) => {
  return (
    <Dialog
      isShown={showDocs}
      title="Upload your resume and portfolio"
      onCloseComplete={() => setShowDocs(false)}
      confirmLabel="Finish uploading docs"
      hasCancel={false}
      hasClose={false}
    >
      <Pane>
        <CandidateDocForm myCandidateProfile={myCandidateProfile} myProfile={myProfile} type="resume" />
        <EditPortfolio myCandidateProfile={myCandidateProfile} myProfile={myProfile} />
      </Pane>
    </Dialog>
  );
};

export default EditCandidateDocs;
