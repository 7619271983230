import _ from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Card,
  EditIcon,
  Heading,
  IconButton,
  Link,
  Menu,
  MoreIcon,
  Pane,
  Pill,
  Popover,
  Position,
  Text,
  TrashIcon,
  majorScale,
  toaster,
  Small,
  Strong,
  Paragraph,
  UnorderedList,
  ListItem,
  OrderedList
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import ProfilePreview from "../feed/ProfilePreview";
import CommentDelete from "./CommentDelete";
import ReplyIndex from "./ReplyIndex";
import CreateReply from "./CreateReply";

const commentActions = (actionProps) => {
  const { myProfile, comment, postId, setShowEdit, showDelete, setShowDelete, onDelete } = actionProps;

  const menu = (
    <Menu>
      <Menu.Group>
        <Menu.Item icon={EditIcon} onClick={() => setShowEdit(true)}>
          Edit
        </Menu.Item>
        <Menu.Item icon={TrashIcon} intent="danger" onClick={() => setShowDelete(true)}>
          {showDelete ? <CommentDelete comment={comment} onDelete={onDelete} /> : null}
          Delete
        </Menu.Item>
      </Menu.Group>
    </Menu>
  );

  return (
    <Popover position={Position.BOTTOM_LEFT} content={menu}>
      <IconButton icon={MoreIcon} appearance="minimal" />
    </Popover>
  );
};

const isCommentOwner = ({ myProfile, comment, postId }) => {
  return myProfile?.id === comment?.owner?.id;
};

const Comment = (props) => {
  const { myProfile, comment, postId } = props;
  const [titleHover, setTitleHover] = useState(false);

  const { owner, details } = comment;
  const { role, fullName, slug } = owner;

  const teamTitle = role && role.team ? `${role.title}, ${role.team.name}` : null;

  return (
    <Pane
      marginLeft={majorScale(1)}
      display="flex"
      flexDirection="column"
      width="100%"
      background="blueTint"
      padding={majorScale(1)}
      borderRadius={5}
    >
      <Pane display="flex" flexDirection="row" width="100%" alignItems="center" justifyContent="space-between">
        <Pane>
          <Link
            is={BrowserLink}
            to={`/p/${slug}`}
            onMouseEnter={() => setTitleHover(true)}
            onMouseLeave={() => setTitleHover(false)}
            textDecoration={titleHover ? "underline" : "none"}
            target="_blank"
            onClick={() => window.analytics.track("Comment -> View Profile")}
          >
            <Strong>{fullName}</Strong>
          </Link>
          {owner && owner.coach && (
            <Pill marginLeft={majorScale(1)} color="blue">
              Mentor
            </Pill>
          )}
        </Pane>
        <Pane>{isCommentOwner(props) ? commentActions(props) : null}</Pane>
      </Pane>
      <Pane style={{ wordBreak: "break-word" }}>
        <ReactMarkdown
          components={{
            p: Paragraph,
            ul: UnorderedList,
            li: ListItem,
            ol: OrderedList
          }}
        >
          {details}
        </ReactMarkdown>
      </Pane>
    </Pane>
  );
};

export default Comment;
