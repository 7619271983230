import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

import DiscoverPage from "./DiscoverPage";
import DashboardPage from "./DashboardPage";
import RecommendationPage from "./RecommendationPage";
import RecommendationDetailPage from "./RecommendationDetailPage";

export default withRoutes(Layout, [
  {
    exact: true,
    path: "/dashboard",
    component: DashboardPage,
    state: "Discover",
    privateRoute: true
  },
  {
    exact: true,
    path: "/discover",
    component: DiscoverPage,
    state: "Discover",
    bgColor: true
  },
  {
    exact: true,
    path: "/discover/:slug",
    component: DiscoverPage,
    state: "Discover"
  },
  {
    path: "/members",
    component: RecommendationPage,
    state: "Recommendation"
  },
  {
    path: "/m/:id",
    component: RecommendationDetailPage,
    state: "Recommendation"
  }
]);
