import React from "react";
import { Button, Dialog, Heading, Pane, Paragraph, majorScale } from "evergreen-ui";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import ProfileList from "../../../queries/profile/ProfileList";
import TextInput from "../../../components/form/TextInput";

const PAGE_TITLE = "Add your name, photo, and LinkedIn";
const SUBMIT_CTA = "Update profile";
const DISMISS_CTA = "Close";

// https://stackoverflow.com/questions/13927099/validate-url-with-or-without-protocol
const URL_REGEX = /(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+?\.(?:[a-zA-Z])|\d+\.\d+\.\d+\.\d+)/;

const HumanSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Please include your full name")
    .max(100, "Please shorten the name you put in")
    .required("Please provide your name"),
  website: Yup.string().matches(URL_REGEX, "Please provide a valid website").required("Please provide a valid website")
});

const hasName = ({ name }) => {
  return name?.first?.length > 0 && name?.last?.length > 0;
};

const HumanityCheckForm = ({ myProfile, profile, onCompleted }) => {
  const INTRO_TEXT = `Providing a bit of basic information lets others know you're a real person when contacting them.`;

  const [beAHuman] = useMutation(ProfileList.ShowHumanity, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: ProfileList.LayoutQuery }],
    onCompleted: ({ beAHuman }) => {
      if (window && window.analytics) {
        window.analytics.track("Be a Human");
      }

      onCompleted();
    }
  });

  return (
    <Formik
      validationSchema={HumanSchema}
      initialValues={{
        website: "",
        name: hasName(myProfile) ? myProfile.fullName : ""
      }}
      onSubmit={(variables, { setSubmitting }) => {
        beAHuman({ variables });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, values }) => (
        <Form>
          <Pane>
            {/* Intro */}
            <Pane>
              <Paragraph paddingBottom={majorScale(2)}>{INTRO_TEXT}</Paragraph>
            </Pane>

            {/* Name */}
            <Heading paddingTop={majorScale(1)} paddingBottom={majorScale(1)}>
              What is your first and last name?
            </Heading>
            <Field type="text" name="name" placeholder="Your name" component={TextInput} />

            {/* Website */}
            <Heading paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
              What is your LinkedIn (or personal website)?
            </Heading>
            <Field type="text" name="website" placeholder="" component={TextInput} />

            {/* Action Items */}
            <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="start"
              width="100%"
              minWidth="100%"
              flexDirection="row"
              marginBottom={majorScale(1)}
            >
              <Button onClick={() => onCompleted()} type="button">
                {DISMISS_CTA}
              </Button>
              <Button disabled={isSubmitting} appearance="primary" type="submit">
                {SUBMIT_CTA}
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const ZoomSetup = ({ history, showEditor, setShowEditor, myProfile }) => {
  const onCompleted = () => {
    setShowEditor(false);
    history.push(history?.location?.pathname);
  };

  return (
    <Dialog
      isShown={showEditor}
      onCloseComplete={() => setShowEditor(false)}
      confirmLabel="Activate Profile"
      cancelLabel="Continue Editing"
      hasClose={false}
      preventBodyScrolling={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      header={
        <Pane display="flex" justifyContent="space-between" alignItems="start" width="100%" flexDirection="column">
          <Pane>
            <Heading is="h4" size={600}>
              {PAGE_TITLE}
            </Heading>
          </Pane>
        </Pane>
      }
      hasFooter={false}
    >
      <HumanityCheckForm myProfile={myProfile} onCompleted={onCompleted} />
    </Dialog>
  );
};

export default withRouter(ZoomSetup);
