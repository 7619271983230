import _ from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Button,
  BriefcaseIcon,
  ChatIcon,
  CrossIcon,
  Dialog,
  HandIcon,
  Heading,
  IconButton,
  Pane,
  Paragraph,
  SearchIcon,
  IdNumberIcon,
  Text,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { useQuery } from "@apollo/client";
import { Link as BrowserLink } from "react-router-dom";
import { formatDistanceToNow, isValid } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import HoverLink from "../../../components/HoverLink";
import ReplyForm from "../../../components/discussion/ReplyForm";
import OfferSessionForm from "../../../components/session/OfferSessionForm";
import SessionQueries from "../../../queries/session";

const formatDate = (createdAt) => {
  // https://date-fns.org/v2.27.0/docs/isValid
  createdAt = isValid(new Date(createdAt)) ? createdAt : Number(createdAt);

  // https://date-fns.org/v2.27.0/docs/formatDistanceToNow
  return `${formatDistanceToNow(new Date(createdAt))} ago`;
};

const roleTitle = (person) => {
  const { title, team } = person?.role;
  let str = title;

  str = team?.name?.length > 0 ? `${str} at ${team?.name}` : str;

  return str;
};

const OfferModal = ({ post, myProfile, refetch, membersQuery, myOfferedSession, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const existingOffer = myOfferedSession?.id;

  return (
    <>
      <Dialog
        isShown={showModal}
        shouldCloseOnOverlayClick={false}
        header={
          <Pane display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Pane display="flex" flexDirection="column">
              <Heading is="h4" size={600}>
                {`Offer a mentorship session to ${post?.owner?.fullName}`}
              </Heading>
            </Pane>
            <IconButton onClick={() => setShowModal(false)} icon={CrossIcon} appearance="minimal" />
          </Pane>
        }
        hasFooter={false}
        onCloseComplete={() => setShowModal(false)}
      >
        <Pane>
          <Paragraph size={500} marginBottom={majorScale(2)}>
            {`Let them know why you’re interested in chatting and we’ll send them your message and your calendar availability.`}
          </Paragraph>
        </Pane>
        <OfferSessionForm
          post={post}
          myProfile={myProfile}
          membersQuery={membersQuery}
          myOfferedSession={myOfferedSession}
          onCancel={() => setShowModal(false)}
          onUpdate={() => {
            refetch();
            setShowModal(false);

            if (onUpdate && _.isFunction(onUpdate)) {
              onUpdate();
            }
          }}
        />
      </Dialog>
      <Button appearance={existingOffer ? "default" : "primary"} iconBefore={HandIcon} onClick={() => setShowModal(true)}>
        {existingOffer ? "Edit Session Offer" : "Offer Session"}
      </Button>
    </>
  );
};

const ReplyModal = ({ post, myProfile, refetch }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Dialog
        isShown={showModal}
        shouldCloseOnOverlayClick={false}
        header={
          <Pane display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Pane display="flex" flexDirection="column">
              <Heading is="h4" size={600}>
                {`Reply to ${post?.owner?.fullName}`}
              </Heading>
            </Pane>
            <IconButton onClick={() => setShowModal(false)} icon={CrossIcon} appearance="minimal" />
          </Pane>
        }
        hasFooter={false}
        confirmLabel={"Reply"}
        onCloseComplete={() => setShowModal(false)}
      >
        <Paragraph size={500} marginBottom={majorScale(2)}>
          {`Reply to ${post?.owner?.fullName} with any advice, resources, or perspectives as a quick way to help. They’ll receive a notification with your reply.`}
        </Paragraph>
        <ReplyForm
          post={post}
          myProfile={myProfile}
          refetch={refetch}
          onClose={() => setShowModal(false)}
          onUpdate={() => {
            refetch();
            setShowModal(false);
          }}
        />
      </Dialog>
      <Button iconBefore={ChatIcon} marginRight={majorScale(2)} onClick={() => setShowModal(true)}>
        Reply
      </Button>
    </>
  );
};

const MemberCard = ({ post, myProfile, refetch, membersQuery, onUpdate, hideTop, isMobile }) => {
  const person = post?.owner;

  const { data: result } = useQuery(SessionQueries.queries.MyOfferedSession, {
    variables: {
      profileId: post?.owner?.id
    }
  });

  return (
    <>
      {person?.id && (
        <>
          <Pane
            backgroundColor={"white"}
            borderRadius={minorScale(3)}
            border={`1px solid ${theme.colors.gray400}`}
            width="100%"
            padding={majorScale(4)}
            marginBottom={isMobile ? null : majorScale(3)}
          >
            {!hideTop && (
              <Pane
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <HoverLink samePage to={`/p/${person?.slug}`}>
                  <Pane display="flex" justifyContent="space-between" alignItems="center">
                    <Avatar name={person?.fullName} size={majorScale(10)} src={person?.picture} marginRight={majorScale(2)} />
                    <Pane display="flex" flexDirection="column">
                      <Heading size={500} marginBottom={minorScale(1)}>
                        {person?.fullName}
                      </Heading>
                      {person?.location && (
                        <Text size={500} marginBottom={minorScale(1)}>
                          {person?.location}
                        </Text>
                      )}
                      {person?.jobRoles && (
                        <Text size={500} marginBottom={minorScale(1)}>
                          {person?.jobRoles[0]}
                        </Text>
                      )}
                    </Pane>
                  </Pane>
                </HoverLink>
                <Pane>
                  {person?.linkedin && (
                    <Button is="a" href={person?.linkedin} target="_blank" appearance="minimal">
                      <FontAwesomeIcon size="lg" icon={faLinkedinIn} />
                    </Button>
                  )}
                  {person?.twitter && (
                    <Button is="a" href={person?.twitter} target="_blank" appearance="minimal">
                      <FontAwesomeIcon size="lg" icon={faTwitter} />
                    </Button>
                  )}
                  {person?.website && (
                    <Button is="a" href={person?.website} target="_blank" appearance="minimal">
                      <FontAwesomeIcon size="lg" icon={faExternalLinkAlt} />
                    </Button>
                  )}
                </Pane>
              </Pane>
            )}
            <Pane
              display="flex"
              justifyContent="row"
              alignItems="center"
              marginTop={!hideTop && majorScale(3)}
              marginBottom={majorScale(3)}
            >
              {person?.role?.title && (
                <Text size={500} marginRight={majorScale(2)} display="flex" justifyContent="row" alignItems="center">
                  <BriefcaseIcon marginRight={majorScale(1)} />
                  {roleTitle(person)}
                </Text>
              )}
              {person?.level && (
                <Text size={500} marginRight={majorScale(2)} display="flex" justifyContent="row" alignItems="center">
                  <IdNumberIcon marginRight={majorScale(1)} />
                  {person?.level}
                </Text>
              )}
              {post?.topic?.name && (
                <Text size={500} marginRight={majorScale(2)} display="flex" justifyContent="row" alignItems="center">
                  <SearchIcon marginRight={majorScale(1)} />
                  {post?.topic?.name}
                </Text>
              )}
            </Pane>
            <Pane>
              <HoverLink samePage to={`/m/${post?.id}`}>
                <Heading size={500} marginBottom={majorScale(1)}>
                  {post?.title}
                </Heading>
              </HoverLink>
              <Paragraph size={500}>{post?.details}</Paragraph>
            </Pane>
            <Pane display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" marginTop={majorScale(3)}>
              {myProfile?.id && (
                <Pane>
                  <Button iconBefore={ChatIcon} marginRight={majorScale(2)} is={BrowserLink} to={`/d/${post?.id}`}>
                    Reply
                  </Button>
                  <OfferModal
                    post={post}
                    myProfile={myProfile}
                    refetch={refetch}
                    membersQuery={membersQuery}
                    myOfferedSession={result?.myOfferedSession}
                    onUpdate={onUpdate}
                  />
                </Pane>
              )}
            </Pane>
          </Pane>
        </>
      )}
    </>
  );
};

export default MemberCard;
