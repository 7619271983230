import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { Row, Col, Container, Visible, Hidden } from "react-grid-system";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { Pane, Overlay, majorScale } from "evergreen-ui";

import CoachList from "../coach/CoachList";
import ProfileList from "../../queries/profile/ProfileList";

import MentorCard from "../../components/mentor/MentorCard";
import CreateAccountForm from "../../components/auth/signup/CreateAccountForm";

const MobileSignup = ({ mode, groupId, location, history, myProfile, redirect }) => (
  <Container>
    <Row justify="center">
      <Col xs={12}>
        <Pane paddingTop={majorScale(2)}>
          <CreateAccountForm
            history={history}
            location={location}
            mode={mode}
            groupId={groupId}
            myProfile={myProfile}
            isMobile={true}
            redirect={redirect}
          />
        </Pane>
      </Col>
    </Row>
  </Container>
);

const DesktopSignup = ({ mode, groupId, location, history, myProfile, redirect }) => {
  const { data: mentorResult } = useQuery(CoachList.CoachPreviewQuery);
  const mentors = mentorResult?.coachPreview;

  return (
    <Container>
      <Row>
        <Pane display="flex" width="100%" flexWrap="wrap">
          {mentors &&
            mentors.map((mentor) => (
              <Col md={4} lg={3} key={`SignupPage-${mentor?.id}`} style={{ marginBottom: majorScale(2) }}>
                <MentorCard user={mentor?.user} nextAvailable={mentor?.nextAvailable} />
              </Col>
            ))}
        </Pane>
      </Row>
      <Overlay
        isShown={true}
        shouldAutoFocus={false}
        preventBodyScrolling={true}
        shouldCloseOnClick={false}
        shouldCloseOnEscape={false}
        containerProps={{ overflowY: "scroll", position: "fixed" }}
      >
        <Container>
          <Row justify="center">
            <Col xs={10} sm={10} md={8} lg={6} xl={6}>
              <Pane padding={majorScale(2)} marginY="12vmin" backgroundColor="white" elevation={4} borderRadius={8}>
                <CreateAccountForm
                  history={history}
                  location={location}
                  mode={mode}
                  groupId={groupId}
                  myProfile={myProfile}
                  redirect={redirect}
                />
              </Pane>
            </Col>
          </Row>
        </Container>
      </Overlay>
    </Container>
  );
};

const SignupPage = (props) => {
  const { data: profileResult } = useQuery(ProfileList.LayoutQuery);

  useEffect(() => {
    window.analytics.track("View Signup Page");
  });

  const { mode, groupId, redirect } = queryString.parse(props?.location?.search, { arrayFormat: "comma" });

  return (
    <div>
      <Visible xs>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>
        <MobileSignup mode={mode} groupId={groupId} myProfile={profileResult?.myProfile} redirect={redirect} {...props} />
      </Visible>
      <Hidden xs>
        <DesktopSignup mode={mode} groupId={groupId} myProfile={profileResult?.myProfile} redirect={redirect} {...props} />
      </Hidden>
    </div>
  );
};

export default withRouter(SignupPage);
