import _ from "lodash";
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Dialog, Heading, Pane, RadioGroup, toaster } from "evergreen-ui";
import { Formik, Form } from "formik";

import CandidateQueries from "../../../../queries/candidate";

const WorkAuthorizationForm = ({ myCandidateProfile, availableVisaTypes }) => {
  const [updateVisaStatus] = useMutation(CandidateQueries.mutations.UpdateVisaStatus, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results) => {
      toaster.success("Work authorization updated", { duration: 2, id: "work-authorization" });
    }
  });

  return (
    <Formik
      initialValues={{
        visaStatus: myCandidateProfile?.visaStatus || "authorized"
      }}
      onSubmit={(variables, { setSubmitting }) => {
        updateVisaStatus({ variables });
        setSubmitting(false);
      }}
      render={({ values, setFieldValue, submitForm }) => (
        <Form>
          <Pane>
            <Heading>Are you authorized to work lawfully in the United States?</Heading>
          </Pane>
          <RadioGroup
            value={values?.visaStatus}
            label="We only collect this information to ensure relevant opportunities are shown."
            options={availableVisaTypes.map(({ value, label }) => ({ label, value }))}
            onChange={(e) => {
              setFieldValue("visaStatus", e.target.value, false);
              submitForm();
            }}
          />
        </Form>
      )}
    />
  );
};

const EditWorkAuthorization = (props) => {
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);

  const { showWorkAuthorization, setShowWorkAuthorization, myCandidateProfile } = props;
  return (
    <Dialog
      isShown={showWorkAuthorization}
      title="Work Authorization"
      onCloseComplete={() => setShowWorkAuthorization(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
    >
      <WorkAuthorizationForm availableVisaTypes={profileOptions?.availableVisaTypes} myCandidateProfile={myCandidateProfile} />
    </Dialog>
  );
};

export default EditWorkAuthorization;
