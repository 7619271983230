const sections = [
  {
    headline: "Individual",
    sectionShot: "",
    markdown: `- Get access to content and coaches
- Max of 2 coaching sessions a month`
  },
  {
    headline: "Organization",
    sectionShot: "",
    markdown: `- Unlimited coaching per employee
- Analytics dashboard for HR
- Billed annually
- Support via email and phone, online help resources`
  }
];

export default sections;
