import React from "react";
import { Row, Col, Container, Hidden, Visible } from "react-grid-system";
import injectSheet from "react-jss";
import { Helmet } from "react-helmet";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";

import { Pane, majorScale } from "evergreen-ui";

import FAQLayout from "../../components/layout/FAQLayout";
import SectionList from "../../components/layout/SectionList";
import theme from "../../components/theme";

import {
  plusValueProps,
  plusFAQ,
  plusReasons,
  managersFAQ,
  salesLink,
  plusUseCases,
  meritFree,
  meritPlus,
  tagline,
  meritFreeExplainer,
  meritPlusExplainer,
  meritPlusOutcomes,
  coachRecsImage,
  coachGoalsImage,
  coachMatchImage
} from "../../content/plus";

const styles = {
  h1: {
    ...theme.h1,
    padding: 0,
    margin: 0,
    paddingTop: theme.spacing.grid * 2.5,
    textAlign: "center"
  },
  subtitle: {
    ...theme.b,
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid * 1.5,
    paddingBottom: theme.spacing.grid * 2
  },
  p: {
    ...theme.p,
    margin: 0,
    padding: 0,
    textAlign: "left"
  },
  h2: {
    ...theme.h2,
    margin: 0,
    padding: 0,
    color: "inherit",
    textDecoration: "inherit",
    textAlign: "center",
    paddingTop: theme.spacing.grid * 2,
    paddingBottom: theme.spacing.grid * 2
  },
  sku: {
    ...theme.h2,
    margin: 0,
    padding: 0,
    color: "inherit",
    textDecoration: "inherit",
    textAlign: "left",
    paddingTop: theme.spacing.grid * 2,
    paddingBottom: majorScale(1)
  },
  explainer: {
    ...theme.h4,
    paddingBottom: majorScale(2)
  },
  feature: {
    ...theme.p,
    paddingBottom: majorScale(1)
  },
  featureHidden: {
    ...theme.p,
    color: "gray",
    paddingBottom: majorScale(1)
  },
  h3: {
    ...theme.h3,
    textAlign: "left",
    margin: 0,
    paddingBottom: majorScale(2),
    padding: 0
  },
  h4: {
    ...theme.h4,
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingTop: majorScale(1),
    paddingBottom: majorScale(2)
  },
  button: {
    ...theme.button,
    textDecoration: "none",
    backgroundColor: theme.color.brand,
    color: theme.color.whiteText,
    textAlign: "center",
    border: `2px solid ${theme.color.brand}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 15,
    paddingLeft: 15,
    "&:hover": {
      color: theme.color.brand,
      border: "2px solid",
      borderColor: theme.color.brand,
      backgroundColor: "white",
      transition: "white .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
    }
  },
  link: {
    ...theme.s,
    marginTop: majorScale(2),
    color: "inherit",
    textDecoration: "inherit"
  },
  img: {
    width: "100%",
    maxWidth: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: majorScale(2)
  }
};

const handler = async (e) => {
  const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
  const PLAN_ID = process.env.REACT_APP_STRIPE_PLAN_ID;
  const URL = process.env.REACT_APP_URL;

  const stripe = await loadStripe(STRIPE_KEY);

  const { error } = await stripe.redirectToCheckout({
    items: [
      {
        plan: PLAN_ID,
        quantity: 1
      }
    ],
    successUrl: `${URL}/success`,
    cancelUrl: `${URL}/plus`
  });
  console.log(error);
};

class PlusPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <link href={theme.fonts} rel="stylesheet" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Merit Plus</title>
        </Helmet>
        <Container>
          <Row align="center" justify="center">
            <Col md={12}>
              <Pane display="flex" flexDirection="column">
                <div className={classes.h1}>Merit Plus</div>
                <div className={classes.subtitle}>Personalized career unblocking. $70/month.</div>
                <Pane display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <button className={classes.button} onClick={handler}>
                    Buy Now
                  </button>
                  <a className={classes.link} href={salesLink} target="_blank">
                    Or talk to us
                  </a>
                </Pane>
              </Pane>
            </Col>
          </Row>
          <Row align="center" justify="center">
            <Col md={12}>
              <Pane display="flex" flexDirection="column">
                <div className={classes.h2}>{tagline}</div>
                <img className={classes.img} src={"https://ucarecdn.com/cb828650-e04a-4ba2-9ba8-7949839dc018/speechbubbles.svg"} />
              </Pane>
            </Col>
          </Row>
          <SectionList title="What kind of support do you need?" items={plusReasons} />

          <Row align="center" justify="center">
            <Col md={10}>
              <Pane display="flex" flexDirection="column">
                <div className={classes.h2}>{meritPlusOutcomes}</div>
                {_.map(plusUseCases, (useCase) => {
                  return <div className={classes.h3}>{useCase}</div>;
                })}
              </Pane>
            </Col>
          </Row>
          <Row align="center" justify="center">
            <Col md={12}>
              <div className={classes.h2}>How does Merit Plus work?</div>
            </Col>
          </Row>
          <div style={{ marginTop: theme.spacing.grid, marginBottom: theme.spacing.grid }}>
            <Row align="center" justify="center" style={{ marginTop: theme.spacing.grid, marginBottom: theme.spacing.grid }}>
              <Col xs={9} md={5}>
                <img src={coachRecsImage} className={classes.img} />
              </Col>
              <Col xs={9} md={5}>
                <Hidden xs md>
                  <div className={classes.h3}>{plusValueProps[0].title}</div>
                </Hidden>
                <Visible xs md>
                  <div className={classes.h3}>{plusValueProps[0].title}</div>
                </Visible>
                <div className={classes.p}>{plusValueProps[0].description}</div>
              </Col>
            </Row>
            <Row align="center" justify="center" style={{ marginTop: theme.spacing.grid, marginBottom: theme.spacing.grid }}>
              <Col xs={9} md={5}>
                <Hidden xs md>
                  <div className={classes.h3}>{plusValueProps[1].title}</div>
                </Hidden>
                <Visible xs md>
                  <div className={classes.h3}>{plusValueProps[1].title}</div>
                </Visible>
                <div className={classes.p}>{plusValueProps[1].description}</div>
              </Col>
              <Col xs={9} md={5}>
                <img src={coachMatchImage} className={classes.img} />
              </Col>
            </Row>
            <Row align="center" justify="center" style={{ marginTop: theme.spacing.grid, marginBottom: theme.spacing.grid }}>
              <Col xs={9} md={5}>
                <img src={coachGoalsImage} className={classes.img} />
              </Col>
              <Col xs={9} md={5}>
                <Hidden xs md>
                  <div className={classes.h3}>{plusValueProps[2].title}</div>
                </Hidden>
                <Visible xs md>
                  <div className={classes.h3}>{plusValueProps[2].title}</div>
                </Visible>
                <div className={classes.p}>{plusValueProps[2].description}</div>
              </Col>
            </Row>
          </div>
          <Row align="top" justify="center">
            <Col md={5}>
              <Pane display="flex" flexDirection="column" marginTop={majorScale(2)} marginBottom={majorScale(2)}>
                <div className={classes.sku}>Merit Basic</div>
                <div className={classes.explainer}>{meritFreeExplainer}</div>
                {_.map(_.dropRight(meritFree, 3), (feature) => {
                  return <div className={classes.feature}>{feature}</div>;
                })}
                {_.map(_.drop(meritFree, 2), (feature) => {
                  return <div className={classes.featureHidden}>{feature}</div>;
                })}
              </Pane>
            </Col>
            <Col md={5}>
              <Pane display="flex" flexDirection="column" marginTop={majorScale(2)} marginBottom={majorScale(2)}>
                <div className={classes.sku}>Merit Plus</div>
                <div className={classes.explainer}>{meritPlusExplainer}</div>
                {_.map(meritPlus, (feature) => {
                  return <div className={classes.feature}>{feature}</div>;
                })}
              </Pane>
            </Col>
          </Row>
          <FAQLayout title="Frequently asked questions" faq={plusFAQ} />
          <FAQLayout title="For managers" icon={"computer"} faq={managersFAQ} />
        </Container>
      </div>
    );
  }
}

export default injectSheet(styles)(PlusPage);
