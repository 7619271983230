import React, { useEffect } from "react";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Avatar, Heading, Pane, Spinner, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";

import FeedQueries from "../../queries/feed";

import BackButton from "../../components/BackButton";
import DiscussionItem from "../../components/discussion/DiscussionItem";
import CommentIndex from "../../components/discussion/CommentIndex";
import ReplyForm from "../../components/discussion/ReplyForm";
import DiscussionAbout from "../../components/discussion/DiscussionAbout";
import DetailMeta from "./DetailMeta";

const ERROR_MSG = "Could not load this page. Please try again in a few moments.";

const DiscussionHeader = ({ post, myProfile, history, screenClass }) => {
  return (
    <Pane id="post" display="flex" flexDirection="column">
      <DiscussionItem long isHeading={true} history={history} post={post} myProfile={myProfile} />
    </Pane>
  );
};

const ReplyContainer = ({ myProfile, post, refetch }) => {
  return (
    <Pane display="flex" flexDirection="row" width="100%" marginTop={minorScale(6)} marginBottom={minorScale(3)}>
      <Pane width={majorScale(5)} marginRight={majorScale(3)}>
        <Avatar src={myProfile?.picture} name={myProfile?.fullName} size={majorScale(5)} />
      </Pane>
      <Pane width="100%">
        <ReplyForm post={post} myProfile={myProfile} onUpdate={() => refetch()} />
      </Pane>
    </Pane>
  );
};

const CommentIndexContainer = ({ post, myProfile }) => {
  const { data: commentsResult, fetchMore } = useQuery(FeedQueries.queries.Comments, { variables: { postId: post?.id } });

  let comments,
    next,
    content = null;

  if (commentsResult?.comments) {
    comments = commentsResult.comments;

    next = () =>
      fetchMore({
        query: FeedQueries.queries.Comments,
        variables: {
          postId: post.id,
          cursor: comments.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            comments: {
              __typename: fetchMoreResult.comments.__typename,
              pageInfo: fetchMoreResult.comments.pageInfo,
              edges: [...prev.comments.edges, ...fetchMoreResult.comments.edges]
            }
          });
        }
      });

    content = (
      <>
        <Pane
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          marginTop={minorScale(3)}
          marginBottom={minorScale(3)}
        >
          <Pane display="flex" flexDirection="row">
            <Heading size={500} marginRight={majorScale(2)}>
              Responses
            </Heading>
          </Pane>
        </Pane>
        <Pane width="100%" borderBottom="1px solid #E6E8F0" marginBottom={majorScale(3)} />
        <Pane display="flex" flexDirection="column">
          <CommentIndex comments={comments} myProfile={myProfile} post={post} next={next} />
        </Pane>
      </>
    );
  }

  return content;
};

const DiscussionDetailPage = ({ history, match }) => {
  const id = match?.params?.id;

  const { data: postResult, loading, refetch } = useQuery(FeedQueries.queries.PostById, { variables: { id } });

  useEffect(() => {
    window.analytics.track("View Post Detail", { id });
  });

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <div
          style={{
            display: "flex",
            flexDirection: screenClass?.includes("xs") ? "column" : "row",
            width: "100%",
            marginTop: majorScale(4)
          }}
        >
          <DetailMeta id={id} postResult={postResult} post={postResult?.postById} />
          <Col xs={12} sm={9} md={9} lg={9}>
            {loading && (
              <Pane
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                marginTop={majorScale(7)}
                marginBottom={majorScale(7)}
              >
                <Spinner />
              </Pane>
            )}
            {!loading && postResult?.postById && (
              <>
                <DiscussionHeader
                  post={postResult.postById}
                  myProfile={postResult?.myProfile}
                  history={history}
                  screenClass={screenClass}
                />
                <CommentIndexContainer post={postResult.postById} myProfile={postResult?.myProfile} />
                {postResult?.myProfile?.id && (
                  <ReplyContainer post={postResult.postById} myProfile={postResult?.myProfile} refetch={refetch} />
                )}
              </>
            )}
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <DiscussionAbout />
          </Col>
        </div>
      )}
    />
  );
};

export default DiscussionDetailPage;
