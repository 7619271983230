import React from "react";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";
import { Heading, Pane, Paragraph, Spinner, majorScale, minorScale } from "evergreen-ui";
import { Col, Hidden } from "react-grid-system";

import MemberCard from "./components/MemberCard";
import BackButton from "../../components/BackButton";
import FeedQueries from "../../queries/feed";

const initialQuery = (props) => {
  const {
    location: { search }
  } = props;
  const { cursor, text, sort, comments } = queryString.parse(search, { arrayFormat: "comma" });

  return {
    cursor: cursor ? cursor : "1",
    text: text ? text : undefined,
    comments: comments ? comments : 0,
    sort: sort ? sort : "RECENT"
  };
};

const loader = (
  <Pane display="flex" flexDirection="row" width="100%" justifyContent="center" paddingY={majorScale(4)}>
    <Spinner />
  </Pane>
);

const RecommendationPage = (props) => {
  const query = initialQuery(props);
  const { data, loading, fetchMore, refetch } = useQuery(FeedQueries.queries.FeedQuery, {
    variables: query
  });

  let posts, myProfile, next;

  if (data) {
    posts = data.posts;
    myProfile = data.myProfile;

    next = () =>
      fetchMore({
        query: FeedQueries.queries.FeedQuery,
        variables: {
          ...query,
          cursor: posts.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            posts: {
              __typename: fetchMoreResult.posts.__typename,
              pageInfo: fetchMoreResult.posts.pageInfo,
              edges: [...prev.posts.edges, ...fetchMoreResult.posts.edges]
            }
          });
        }
      });
  }

  return (
    <>
      <Hidden xs>
        <Col md={2}>
          <BackButton />
        </Col>
      </Hidden>
      <Col xs={12} md={8}>
        <Pane marginTop={majorScale(4)}>
          <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(4)}>
            <Heading size={700} marginBottom={minorScale(1)}>
              🖐 &nbsp; Here are folks in tech you can mentor today
            </Heading>
            <Paragraph size={500}>
              Looking for more ways to mentor? Offer a mentorship session! When you do, they'll get your message and your calendar
              availability. It's an easy way to raise your hand to help.
            </Paragraph>
          </Pane>
          {loading && !posts?.pageInfo?.edgeCount && (
            <Pane display="flex" alignItems="center" height="300px" flexDirection="column" justifyContent="center">
              {loader}
            </Pane>
          )}
          {posts?.pageInfo?.edgeCount > 0 && (
            <InfiniteScroll
              loader={loader}
              style={{ display: "flex", flexWrap: "wrap" }}
              hasMore={posts?.pageInfo?.hasNextPage}
              dataLength={posts?.edges?.length}
              next={() => next()}
            >
              {posts.edges.map(
                (post) =>
                  post?.id &&
                  post?.owner?.id && (
                    <MemberCard post={post} membersQuery={query} refetch={() => refetch({ ...query })} myProfile={myProfile} />
                  )
              )}
            </InfiniteScroll>
          )}
        </Pane>
      </Col>
    </>
  );
};

export default RecommendationPage;
