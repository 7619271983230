import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";
import { Heading, Pane, Paragraph, TextInput, majorScale, toaster, Autocomplete, defaultTheme as theme } from "evergreen-ui";
import "moment-timezone";
import Select from "react-select";

import ProfileList from "../../../queries/profile/ProfileList";

const INFO_WARNING = [
  `This information won’t change any functionality within Merit. We collect this information to ensure Merit is a representative and inclusive community. We will not share individual demographic information with any 3rd parties or publicly.`
];

const GENDER_PLACEHOLDER = `Please identify your gender`;
const ETHNICITY_PLACEHOLDER = `Please identify your ethnicities`;

const GENDER_TOAST = `Gender updated.`;
const ETHNICITY_TOAST = `Ethnicities updated.`;

const showSuccess = _.throttle((copy) => toaster.success(copy, { duration: 2 }), 5000);

const createSelectLabels = (labels) => {
  return _.map(labels, (label) => {
    return { value: label, label: label };
  });
};

const EthnicityForm = ({ selectedEthnicities, availableEthnicities }) => {
  const [updateEthnicities] = useMutation(ProfileList.UpdateEthnicities, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ]
  });

  return (
    <Pane marginTop={majorScale(2)}>
      <Select
        isMulti
        options={availableEthnicities}
        defaultValue={selectedEthnicities}
        placeholder={ETHNICITY_PLACEHOLDER}
        onChange={(selected) => {
          const ethnicities = _.map(selected, (ethnicity) => {
            return ethnicity.value;
          });
          updateEthnicities({ variables: { ethnicities } });
          showSuccess(ETHNICITY_TOAST);
        }}
      />
    </Pane>
  );
};

const GenderForm = ({ selectedGender, availableGenders }) => {
  const [updateGender] = useMutation(ProfileList.UpdateGender, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ]
  });

  return (
    <Pane marginTop={majorScale(2)}>
      <Autocomplete
        selectedItem={selectedGender}
        allowOtherValues={true}
        items={availableGenders}
        onChange={(changedItem) => {
          updateGender({
            variables: {
              gender: _.isNil(changedItem) ? null : changedItem
            }
          });
          showSuccess(GENDER_TOAST);
        }}
      >
        {({ getInputProps, getRef, inputValue, openMenu }) => (
          <TextInput
            width="100%"
            placeholder={GENDER_PLACEHOLDER}
            value={inputValue}
            ref={getRef}
            {...getInputProps({
              onFocus: () => {
                openMenu();
              }
            })}
          />
        )}
      </Autocomplete>
    </Pane>
  );
};

const DemographicsForm = ({ myProfile: profile }) => {
  window.analytics.track("View Settings: Demographics");

  const selectedEthnicities = createSelectLabels(profile.ethnicities);
  const availableEthnicities = createSelectLabels(profile.availableEthnicities);

  return (
    <>
      {profile && (
        <Pane marginTop={majorScale(4)}>
          <Heading size={600} marginBottom={majorScale(2)}>
            Demographics
          </Heading>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            padding={majorScale(4)}
            borderRadius={majorScale(2)}
            border={`1px solid ${theme.colors.gray500}`}
            backgroundColor="white"
            minHeight="100px"
          >
            {INFO_WARNING.map((warning) => (
              <Paragraph size={500}>{warning}</Paragraph>
            ))}
            <EthnicityForm selectedEthnicities={selectedEthnicities} availableEthnicities={availableEthnicities} />
            <GenderForm selectedGender={profile?.gender} availableGenders={profile?.availableGenders} />
          </Pane>
        </Pane>
      )}
    </>
  );
};

export default DemographicsForm;
