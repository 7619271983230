import React from "react";
import { Pane, Heading, Card, Paragraph, Text, Strong, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";

import HoverLink from "../HoverLink";

const Emoji = ({ emoji }) => (
  <Pane
    background="#EBF0FE"
    width={minorScale(9)}
    height={minorScale(9)}
    borderRadius={minorScale(1)}
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
    marginRight={majorScale(2)}
  >
    {emoji}
  </Pane>
);

const Guideline = ({ text, emoji }) => (
  <Pane display="flex" flexDirection="row" width="100%" alignItems="center" marginBottom={majorScale(2)}>
    <Emoji emoji={emoji} />
    <Heading size={400}>{text}</Heading>
  </Pane>
);

const DiscussionAbout = () => {
  return (
    <Pane marginTop={majorScale(4)} marginBottom={majorScale(3)}>
      <Heading size={600} marginBottom={minorScale(3)}>
        About Discussions
      </Heading>
      <Pane
        border={`1px solid ${theme.colors.gray500}`}
        paddingX={majorScale(2)}
        paddingY={majorScale(2)}
        marginBottom={minorScale(9)}
        borderRadius={majorScale(2)}
        backgroundColor="white"
      >
        <Paragraph size={500}>
          Discussions is where tech workers share tips, tricks, and stories about important career questions.
        </Paragraph>
        <br />
        <Paragraph size={500}>Start a discussion to get help from thousands of tech professionals.</Paragraph>
      </Pane>
      <Heading size={700} marginBottom={minorScale(3)}>
        Guidelines
      </Heading>
      <Guideline emoji="😄️" text="Respect the Code of Conduct" />
      <Guideline emoji="✍️" text="Keep it relevant to professional questions" />
      <Guideline emoji="🤓" text="Focus on substance over self-promotion" />
      <HoverLink samePage to="/guidelines">
        <Strong size={300} color={"#1E1EC6"}>
          Learn more...
        </Strong>
      </HoverLink>
    </Pane>
  );
};

export default DiscussionAbout;
