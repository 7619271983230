import React from "react";
import _ from "lodash";
import { Pane, Paragraph, Heading, majorScale, Card, Button, Strong, defaultTheme as theme } from "evergreen-ui";
import { Link } from "react-router-dom";

import SessionCard from "../../components/call/SessionCard";
import ProfileOverview from "../../components/profile/ProfileOverview";

const otherUser = ({ session: { user, coach }, myProfile }) => {
  return _.isEqual(myProfile?.id, coach?.user?.id) ? user : coach?.user;
};

const Feedback = (args) => {
  let { review, isAdmin, showOther, myProfile } = args;

  if (!review || !review.user) return null;

  const rating = `${review.rating}`;
  const responses = review.responses;
  let user = review.user;
  const session = review.session;

  const url = `/calls/${review.session.id}/review`;

  if (showOther && myProfile) {
    user = otherUser({ session, myProfile });
  }

  /* If it's my feedback, I can edit it. */
  const isEditable = review.user && review.user.id && myProfile ? _.isEqual(review.user.id, myProfile.id) : false;

  const renderQuestionAnswer = (response) => {
    const privacy = response.privacy;

    let question = null;

    /* Show feedback if its public, its legacy, if you are an admin or its your feedback. */
    if (_.isNil(privacy) || _.isEqual(privacy, "Public") || isAdmin || isEditable) {
      /* Skip empty feedback questions. */
      if (!_.isEmpty(response.answer)) {
        question = (
          <Pane key={`Response-${response.id}`} paddingTop={majorScale(1)}>
            <Heading size={400}>{response.question}</Heading>
            <Pane paddingTop={majorScale(1)} style={{ overflowWrap: "anywhere" }}>
              <Paragraph size={500}>{response.answer}</Paragraph>
            </Pane>
          </Pane>
        );
      }
    }

    return question;
  };

  const editButton = isEditable ? (
    <Button iconBefore="edit" appearance="minimal" is={Link} to={url}>
      Edit
    </Button>
  ) : null;

  const feedback = (
    <Pane
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      marginY={majorScale(2)}
      width="100%"
    >
      <Heading size={400}>{_.repeat("⭐️ ", rating)}</Heading>
      {editButton}
      {responses.map(renderQuestionAnswer)}
    </Pane>
  );

  return (
    <Pane>
      <Pane marginY={majorScale(2)}>
        <ProfileOverview profile={user} />
      </Pane>
      <Pane marginY={majorScale(2)}>
        <SessionCard session={session} />
      </Pane>
      <Pane marginY={majorScale(2)}>{feedback}</Pane>
    </Pane>
  );
};

export default Feedback;
