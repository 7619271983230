import _ from "lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { Button, Checkbox, Dialog, Pane, Heading, RadioGroup, Badge, Paragraph, toaster, majorScale, minorScale } from "evergreen-ui";

import CandidateQueries from "../../../../queries/candidate";
import ProfileList from "../../../../queries/profile/ProfileList";
import TextArea from "../../../../components/form/TextArea";

const jobSearchModes = [
  { value: "active", label: "Ready to interview and actively searching" },
  { value: "passive", label: "Open to exploring new opportunities" },
  { value: "inactive", label: "Not open to considering new opportunities" }
  // { value: "hiring", label: "I’m trying to hire" },
];

const challengeOptions = [
  {
    key: "resume",
    value: "Resume and portfolio review"
  },
  {
    key: "technical_interview",
    value: "Practicing technical interviews"
  },
  {
    key: "finding_roles",
    value: "Finding relevant roles"
  },
  {
    key: "offers",
    value: "Evaluating and negotiating offers"
  },
  {
    key: "all_of_the_above",
    value: "All of the above"
  },
  {
    key: "something_else",
    value: "Something else"
  },
  {
    key: "not_sure",
    value: "Not sure"
  }
];

const showSuccess = (copy) =>
  _.debounce(() => {
    toaster.success(copy, { duration: 2, id: copy });
  }, 2000);

const EditJobSearchStatus = ({ myCandidateProfile }) => {
  const [updateJobSearchStatus] = useMutation(CandidateQueries.mutations.SetJobSearchMode, {
    onCompleted: () => {
      showSuccess("Job search status updated.")();
    },
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: CandidateQueries.queries.MyCandidateProfile }],
    awaitRefetchQueries: true
  });

  const handleSubmit = (values, { setSubmitting }) => {
    updateJobSearchStatus({
      variables: {
        jobSearchMode: values.jobSearchMode
      }
    });
  };

  return (
    <Formik
      initialValues={{
        jobSearchMode: myCandidateProfile?.status || "inactive"
      }}
      validationSchema={Yup.object().shape({
        jobSearchMode: Yup.string().required()
      })}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, submitForm }) => (
        <Form>
          <Pane>
            <Heading>Are you currently looking for a job?</Heading>
            <Paragraph>We can add you to our talent directory and connect you with jobs. You can disable this any time.</Paragraph>
          </Pane>
          <Field name="jobSearchMode">
            {({ field, form }) => (
              <RadioGroup
                defaultValue={values?.jobSearchMode}
                options={jobSearchModes}
                onChange={(e) => {
                  setFieldValue("jobSearchMode", e.target.value, false);
                  submitForm();
                }}
              />
            )}
          </Field>
          {touched["jobSearchMode"] && errors["jobSearchMode"] && (
            <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
              {errors["jobSearchMode"]}
            </Badge>
          )}
        </Form>
      )}
    </Formik>
  );
};

const JobSearchFom = ({ myCandidateProfile }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    _.zipObject(_.keys(challengeOptions), _.fill(Array(challengeOptions.length), false))
  );
  const [setJobSearchChallenge] = useMutation(CandidateQueries.mutations.SetJobSearch, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CandidateQueries.queries.MyCandidateProfile
      }
    ],
    onCompleted: (results) => {
      if (results?.setJobSearch) {
        // _.isFunction(nextPage) && nextPage({ topicIds: results?.setJobSearch?.map(({ id }) => id )});
      }
    }
  });

  const updateChallenges = (selectedOptions, setFieldValue, submitForm) => {
    const selected = _.pickBy(selectedOptions, (value) => value);
    const selectedKeys = _.keys(selected);

    setFieldValue("challenges", selectedKeys);
    submitForm();
  };

  return (
    <Formik
      initialValues={{
        challenges: [],
        context: ""
      }}
      validationSchema={Yup.object({
        challenges: Yup.array().of(Yup.string()),
        context: Yup.string()
      })}
      onSubmit={(variables, { setSubmitting }) => {
        setJobSearchChallenge({ variables });
      }}
      render={({ values, errors, touched, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Pane>
            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane paddingBottom={minorScale(2)}>
                <Heading>What’s been your biggest challenge while finding a role?</Heading>
                <Paragraph>
                  We’ll use this information to help recommend mentors and topics on the next page. Select as many as you’d like.
                </Paragraph>
              </Pane>
              <Pane>
                {challengeOptions.map((option, index) => (
                  <Checkbox
                    key={`JobSearchForm-${option.key}`}
                    label={option.value}
                    name={`challenges.${index}`}
                    value={option.key}
                    checked={selectedOptions[option.key]}
                    onChange={(e) => {
                      if (selectedOptions[option.key]) {
                        selectedOptions[option.key] = false;
                        setSelectedOptions({ ...selectedOptions, [option.key]: false });
                      } else {
                        selectedOptions[option.key] = true;
                        setSelectedOptions({ ...selectedOptions, [option.key]: true });
                      }

                      updateChallenges(selectedOptions, setFieldValue, submitForm);
                    }}
                  />
                ))}
              </Pane>
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane paddingBottom={minorScale(2)}>
                <Heading>Anything else you can tell us?</Heading>
                <Paragraph>Our team will reach out if there are job recommendations</Paragraph>
              </Pane>
              <Pane>
                <Field
                  type="text"
                  name="context"
                  placeholder={
                    "I’ve been looking for a job for a couple of months now and I keep getting stuck on technical interviews. I would love to know how to manage this well."
                  }
                  component={TextArea}
                />
              </Pane>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const EditJobSearch = ({ showJobSearch, setShowJobSearch, myCandidateProfile }) => {
  return (
    <Dialog
      isShown={showJobSearch}
      title="Job search status"
      onCloseComplete={() => setShowJobSearch(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
      preventBodyScrolling={true}
    >
      <EditJobSearchStatus myCandidateProfile={myCandidateProfile} />
      {/* <JobSearchFom
        myCandidateProfile={myCandidateProfile}
      /> */}
    </Dialog>
  );
};

export default EditJobSearch;
