import _ from "lodash";
import React from "react";
import { useQuery } from "@apollo/client";
import {
  CrossIcon,
  Dialog,
  IconButton,
  Heading,
  Pane,
  Paragraph,
  Switch,
  Spinner,
  majorScale,
  minorScale,
  toaster,
  Button
} from "evergreen-ui";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";

import CandidateQueries from "../../queries/candidate";
import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import CandidateCard from "./CandidateCard";
// import Switch from "../form/Switch";

const CreateCandidateRequest = ({ candidate, myRecruiterProfile, setShowCandidateRequest, onUpdate }) => {
  const [createCandidateRequest, { loading }] = useMutation(CandidateQueries.mutations.CreateCandidateRequest, {
    onCompleted: () => {
      toaster.success("Request sent!");
      setShowCandidateRequest(false);
      if (_.isFunction(onUpdate)) {
        onUpdate();
      }
    },
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateRequests }],
    awaitRefetchQueries: true
  });

  const handleSubmit = (values, { setSubmitting }) => {
    createCandidateRequest({
      variables: {
        candidateId: candidate?.id,
        message: values?.message,
        jobUrl: values?.jobUrl,
        jobTitle: values?.jobTitle,
        canMatch: values?.canMatch,
        minimumSalary: candidate?.minimumSalary,
        minimumHourlyRate: candidate?.minimumHourlyRate,
        recruiterId: myRecruiterProfile?.id
      }
    });
  };
  return (
    <Formik
      initialValues={{
        message: "",
        jobUrl: "",
        jobTitle: "",
        canMatch: false
      }}
      validatationSchema={Yup.object().shape({
        message: Yup.string().required(),
        jobUrl: Yup.string().required(),
        jobTitle: Yup.string().required()
      })}
      onSubmit={handleSubmit}
      render={({ values, errors, touched, setFieldValue, submitForm }) => (
        <Form>
          <Pane>
            <Pane>
              <Heading size={600} marginBottom={majorScale(1)}>
                Compose Message
              </Heading>
              <Field
                type="text"
                name="message"
                placeholder="Start writing you message..."
                component={TextArea}
                marginBottom={majorScale(2)}
              />
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)}>
                Link to job listing
              </Heading>
              <Paragraph marginBottom={majorScale(1)}>
                If you add a link to the job listing, we'll include it in the email to the candidate
              </Paragraph>
              <Field type="text" name="jobTitle" placeholder="Senior Software Engineer" component={TextInput} height={majorScale(6)} />
              <Field
                type="text"
                name="jobUrl"
                placeholder="https://boards.greenhouse.io/vaulttec/jobs/484502"
                component={TextInput}
                height={majorScale(6)}
              />
            </Pane>

            <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={majorScale(2)}>
              <Switch
                marginRight={majorScale(2)}
                checked={values.canMatch}
                name="canMatch"
                onChange={(e) => setFieldValue("canMatch", e.target.checked)}
              />
              <Paragraph marginBottom={majorScale(1)}>
                The candidate's desired compensation is within the salary range for the job listing.
              </Paragraph>
            </Pane>

            <Pane display="flex" flexDirection="row" justifyContent="flex-end" marginBottom={majorScale(4)}>
              <Button type="submit" appearance="primary" isLoading={loading}>
                Send request
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const CandidateRequest = ({ showCandidateRequest, setShowCandidateRequest, myRecruiterProfile, candidate, onUpdate, user }) => {
  const profile = candidate?.user?.id ? candidate?.user : user;
  console.log("profile", profile);
  return (
    <Dialog
      isShown={showCandidateRequest}
      onCloseComplete={() => setShowCandidateRequest(false)}
      hasCancel={false}
      hasClose={false}
      hasFooter={false}
      hasHeader={false}
      preventBodyScrolling={true}
      shouldCloseOnEscapePress={false}
      shouldCloseOnOverlayClick={false}
    >
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginTop={majorScale(4)}
        marginBottom={majorScale(2)}
      >
        <Heading size={600} marginBottom={majorScale(1)}>
          {`Request to chat with ${profile?.fullName}`}
        </Heading>
        <IconButton type="button" onClick={() => setShowCandidateRequest(false)} icon={CrossIcon} appearance="minimal" />
      </Pane>
      <CandidateCard borderless candidate={candidate} profile={profile} myRecruiterProfile={myRecruiterProfile} />
      <CreateCandidateRequest
        candidate={candidate}
        myRecruiterProfile={myRecruiterProfile}
        setShowCandidateRequest={setShowCandidateRequest}
        onUpdate={onUpdate}
      />
    </Dialog>
  );
};

export default CandidateRequest;
