import _ from "lodash";
import React from "react";
import { Badge, Button, Pane, Paragraph, Text, TextInput, majorScale, Small } from "evergreen-ui";
import { withRouter, Link as BrowserLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { useQuery, useMutation } from "@apollo/client";

import AuthQueries from "../../queries/auth";
import ProfileList from "../../queries/profile/ProfileList";

const googleBtn = "https://ucarecdn.com/29e213d0-2fc2-4c1a-93fc-b0af36c28b33/btn_google_signin_light_normal_web2x.png";

const CreateAccountSchema = Yup.object().shape({
  email: Yup.string().email("Please provide your personal email").required("Please include your personal email")
});

const ErrorText = (text) => <Badge color="red">{text}</Badge>;

const EmailField = (formikArgs) => {
  const { field, ...props } = formikArgs;
  return (
    <Pane alignItems="center">
      <TextInput borderRadius={0} width="100%" {...field} {...props} />
    </Pane>
  );
};

const GoogleAuthButton = () => {
  const { data, loading, error } = useQuery(ProfileList.GoogleAuthLink);
  if (loading || error) {
    return <img src={googleBtn} width={191} />;
  } else if (data) {
    const { googleAuthLink } = data;

    return (
      <a href={googleAuthLink}>
        <img src={googleBtn} width={191} />
      </a>
    );
  }
};

const EmailSignup = (props) => {
  const { cta, mode, showGoogle, subtitle, groupId, redirect, history } = props;
  const [createAccount, { loading, error }] = useMutation(AuthQueries.mutations.CreateAccount, {
    awaitRefetchQueries: true,
    onCompleted: ({ createAccount }) => {
      if (window && window.analytics) {
        window.analytics.track("Create Account");

        if (_.isEqual("ACCOUNT_EXISTS", createAccount)) {
          // If the account already exists, redirect them to login
          let nextPage = "/login?accountExists=true";

          // TODO: make mode an enum
          if (mode === "Coach" || mode === "Both") {
            // TODO: we probably don't want to redirect here if user is already a mentor
            nextPage += "&origin=/apply/0";
          }
          history.push(nextPage);
        } else {
          // Give the user a temporary jwt to use for subsequent
          // profile requests and edits
          const jwt = createAccount;
          Cookies.set("token", jwt, { expires: 2 });

          let nextPage = `/signup/complete?mode=${mode}`;

          if (redirect && _.isString(redirect) && redirect.length > 0) {
            nextPage = `${nextPage}&redirect=${redirect}`;
          }

          if (groupId && _.isString(groupId) && groupId.length > 0) {
            nextPage = `${nextPage}&groupId=${groupId}`;
          }

          history.push(nextPage);
        }
      }
    }
  });

  return (
    <Formik
      validationSchema={CreateAccountSchema}
      initialValues={{
        email: ""
      }}
      onSubmit={(variables, { setSubmitting }) => {
        createAccount({ variables });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, values }) => (
        <Form>
          <Pane display="flex" flexDirection="column" alignItems="center">
            {subtitle ? <Paragraph marginBottom={majorScale(2)}>{subtitle}</Paragraph> : null}
            <Pane display="flex" flexDirection="row">
              <Field type="email" name="email" height={40} placeholder="Email address" minWidth={225} component={EmailField} />
              <Button
                appearance="primary"
                type="submit"
                height={40}
                minWidth={125}
                borderRadius={0}
                textAlign="center"
                disabled={isSubmitting}
              >
                {cta}
              </Button>
            </Pane>
            <Pane marginTop={majorScale(2)}>
              <GoogleAuthButton />
            </Pane>
            <Pane justifyContent="center" display="flex" alignItems="center" paddingTop={majorScale(2)}>
              {touched["email"] && errors["email"] && <ErrorText text={errors["email"]} />}
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default withRouter(EmailSignup);
