import React from "react";
import { Pane, Spinner } from "evergreen-ui";

const LoadingView = () => {
  return (
    <Pane display="flex" alignItems="center" justifyContent="center" height="calc(80vh - 100px)" width="100%">
      <Spinner />
    </Pane>
  );
};

export default LoadingView;
