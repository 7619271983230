export const coachCTA = "Get started";
export const coachHeadline = "Tech mentorship made easy";
export const coachSubtitle = "Merit is where tech leaders give back, grow their network, and get hired. For free.";

export const coachCustomizationImage = `https://ucarecdn.com/ea354da2-a325-43cd-a398-7f8f8894a416/session.png`;
export const coachMatchImage = "https://ucarecdn.com/b7551c50-193b-448e-aeb9-68be569dd2e0/mentorfeedback.png";
export const coachFeedbackImage = `https://ucarecdn.com/ead187ee-1953-4305-ba36-e87a9a2e49b0/Group2.png`;
export const coachLimits = "https://ucarecdn.com/4c858b03-154a-4f67-baa1-58141a5c5a3e/limits.png";
export const coachImpact = "https://ucarecdn.com/0179fc99-fa07-4c60-ac16-fef090cdc9b4/impact.png";

export const SALES_URL = "https://app.apollo.io/#/meet/inbound-router/dau-d6i-d6m";

export const benefitsRecruiters = [
  {
    title: "We qualify and identify candidates",
    description: "We get positive signal via mentorship sessions.",
    image: "https://ucarecdn.com/296e1088-7c9c-42ff-b64a-7b5d4153e7fe/SampleRecommendations.png"
  },
  {
    title: "Surface candidate recommendations",
    description: "Based on your requirements, recommendations, and interests.",
    image: "https://ucarecdn.com/28a92ff7-df5f-4389-95a4-2307c3c3e193/CandidateListingItem.png"
  },
  {
    title: "And then book on your calendar",
    description: "We handle outreach, intros, and scheduling.",
    image: "https://ucarecdn.com/25a1c7bf-0d43-45c7-8c19-0fa0e902661e/UpcomingInterviews.png"
  }
];

export const benefitsCoaches = [
  {
    title: "Create your profile and preferences",
    description:
      "Set your conversation topics, availability, and limits. Share your profile for seamless booking. Pause your hours when work gets busy."
  },
  {
    title: "We make it easy to make an impact",
    description:
      "Merit makes it easy by automating scheduling, prep work, and following up after sessions. Let us know when you’re available and what you want to talk about; we’ll handle the rest."
  },
  {
    title: "Use Merit. Get recruited.",
    description:
      "When you are open to new opportunities, we can highlight your profile to recruiters. Member reviews and feedback can help you get stand out from a stack of resumes and LinkedIn profiles."
  },
  {
    title: "Automate your mentoring.",
    description:
      "Set your conversation topics, availability, and limits. Pause your hours when work gets busy. Share your profile for seamless booking."
  }
];

export const coachFAQ = [
  {
    question: "Who can become a mentor on Merit?",
    answer: `Anyone who can help others in Product, Design, Engineering and/or Management. We don’t require prior mentorship or coaching experience, and many people sign up as a way to build that experience.`
  },
  {
    question: "What is the process to becoming a mentor?",
    answer: `There is a three step process to being a mentor on Merit: application, customization and training. First you submit your mentor application. If there is a good fit you'll get an invite to create your profile (i.e. set your availability, playlists and preferences). Once your profile is set up we'll schedule a mock session, where you can learn best practices, practice the format, and get feedback. Then you can turn your profile live and start having conversations.`
  },
  {
    question: "What is the time commitment to be a mentor?",
    answer: `Most mentors do a few conversations a month. The platform takes into account your schedule, interests and availability before people can book time with you. We know careers and work ebb and flow, so you can increase or decrease your conversation limit at any time. If you go on vacation or want to take a break from Merit, you can easily turn pause your profile.`
  },
  {
    question: "Are mentors compensated?",
    answer: `Merit mentors are not compensated. They use Merit to grow their network, give back to the community, learn skills and practice career conversations. We make money by charging hiring managers to post jobs within our app and network.`
  }
];

export const coachTestimonialTitle = "Here is what our mentors are saying";
export const coachTestimonials = [
  {
    name: `Herry`,
    function: `Product`,
    photo: `https://ucarecdn.com/f201995d-3ba2-441e-8a11-48b4a71a04f3/-/smart_resize/150x150/`,
    quote: `I really enjoyed speaking to Xiao and learning about her current experiences and challenges as a PM. She came to our session with defined questions, but also did a great job of letting our conversation flow, allowing us to explore new ideas.`
  },
  {
    name: `Kristen`,
    function: `Design`,
    photo: `https://ucarecdn.com/d2db67e6-a29f-42ea-a8bf-bbf9463f36d3/-/smart_resize/150x150/`,
    quote: `I really loved hearing Imani talk about her interests and tell me about products and apps I had never heard of!`
  },
  {
    name: `Chris`,
    function: `Design`,
    photo: `https://ucarecdn.com/29f0010d-d6fa-4bc0-b193-e458d6417baa/-/smart_resize/150x150/`,
    quote: `Lena was articulate, professional and was specific about the feedback she was looking to collect. She is is in a great place with her portfolio and really enjoyed walking through her work.`
  },
  {
    name: `Jackie`,
    function: `Engineering`,
    photo: `https://ucarecdn.com/e46be2ab-da27-4b80-82be-4452fe678865/-/smart_resize/150x150/`,
    quote: `Andre had good questions and gave context to the problems he was solving. Andre was friendly and it was a good conversation.`
  },
  {
    name: `Musse`,
    function: `Product`,
    photo: `https://ucarecdn.com/888c806b-7e43-4f08-8a2d-ca916061c853/-/smart_resize/150x150/`,
    quote: `Le came in with clear insights on the challenges she was having and open to learning through the experience. This enabled her to focus both on the tactical homework I gave her as well as the overarching themes important to consider for her career.`
  },
  {
    name: `KC`,
    function: `Product`,
    photo: `https://ucarecdn.com/2ebcebf8-bf5c-487b-9b2c-52f19954092d/-/smart_resize/150x150/`,
    quote: `Tanvi asked some great questions that were good prompts for reflection on my end. Was jazzed that she seemed excited - kept showing me her notes/notebook to reflect the things she was jotting down.`
  },
  {
    name: `Ellen`,
    function: `Product`,
    photo: `https://ucarecdn.com/b73134b1-7d86-4970-b4fc-576ca5e63fa8/-/smart_resize/150x150/`,
    quote: `Priya was opening to asking questions and jumping in together.`
  },
  {
    name: `Steven`,
    function: `Design`,
    photo: `https://ucarecdn.com/bb83c8f3-3d28-45d9-896c-f28a073b9143/-/smart_resize/150x150/`,
    quote: `Najeeb was a pleasure to speak with. He's an ambitious innovator with so many great ideas to build products that will be meaningful to many, especially during this pandemic!`
  },
  {
    name: `Kate`,
    function: `Design`,
    photo: `https://ucarecdn.com/1c53500d-3956-4f38-bace-47f67f134b02/-/smart_resize/150x150/`,
    quote: `Niko asked really thoughtful questions that I wish all PMs asked about giving feedback. Instead of asking "How can I give critical feedback?" he asked "How can I give feedback that isn't subjective?" and "What skills can I work on as someone who managers designers?"`
  },
  {
    name: `Luke`,
    function: `Design`,
    photo: `https://ucarecdn.com/613b9d80-9b6a-4953-8bdc-0977a5e067f9/-/smart_resize/150x150/`,
    quote: `Kamille is just a sweet, cool person and was just so genuinely energized by our conversation. I felt helpful and felt like I made a genuine connection. I want Kamille to do well!`
  }
];
