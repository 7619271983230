import _ from "lodash";
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { AnnotationIcon, Alert, Pane, Heading, CalendarIcon, Spinner, TabNavigation, Tab, majorScale, Button } from "evergreen-ui";
import { Link as BrowserLink, Redirect } from "react-router-dom";
import { fromUnixTime, isWithinInterval, sub, isBefore } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import SessionCard from "../../components/session/SessionCard";
import UpcomingActions from "../../components/session/UpcomingActions";
import PreviousActions from "../../components/session/PreviousActions";
import BackButton from "../../components/BackButton";
import FeedbackForm from "../../components/session/FeedbackForm";
import PrepareForm from "../../components/session/PrepareForm";
import FollowUpForm from "../../components/session/FollowUpForm";
import MyFeedbackDetail from "../../components/session/MyFeedbackDetail";

import SessionQueries from "../../queries/session";

const withinNextDay = (date, timeZone) => {
  if (!_.isNaN(date)) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return isWithinInterval(new Date(), {
    start: sub(zonedDate, { days: 1 }),
    end: zonedDate
  });
};

const isUpcoming = (date) => {
  if (!_.isNaN(date)) {
    date = fromUnixTime(Number(date) / 1000);
  }

  return isBefore(new Date(), date);
};

const TabToggle = ({ id, state, myFeedback, upcoming }) => {
  let options = [
    { title: "Prepare", value: "prepare" },
    { title: "Follow Up", value: "follow-up" }
  ];

  return (
    <Pane display="flex" flexDirection="row" justifyContent="flex-end" marginBottom={majorScale(2)}>
      <TabNavigation>
        {options.map(({ value, title }) => {
          return (
            <Tab appearance="primary" is={BrowserLink} isSelected={state === value} to={`/session/${id}/${value}`}>
              {title}
            </Tab>
          );
        })}
      </TabNavigation>
    </Pane>
  );
};

const SessionDetailPage = ({
  history,
  match: {
    params: { id, state }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.SessionAndReviewById, {
    variables: { id }
  });

  // If a session has been rescheduled or canceled then it can't be move again
  if (sessionAndReview?.sessionById && ["Rescheduled", "Canceled"].includes(sessionAndReview?.sessionById?.status)) {
    return <Redirect to={"/sessions/upcoming"} />;
  }

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row justify="flex-start" style={{ width: "100%" }}>
          {!screenClass?.includes("xs") && (
            <Col xs={12} sm={2} md={2} lg={2}>
              <BackButton />
            </Col>
          )}
          <Col xs={12} sm={8} md={8} lg={8}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={majorScale(4)}
              width="100%"
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={majorScale(2)}>
                <CalendarIcon marginRight={majorScale(1)} size={majorScale(2)} />
                {"My Session"}
              </Heading>
              {sessionAndReview?.sessionById?.id && (
                <>
                  {isUpcoming(sessionAndReview?.sessionById?.date) ? (
                    <UpcomingActions
                      session={sessionAndReview?.sessionById}
                      direction={"upcoming"}
                      tooSoon={withinNextDay(sessionAndReview?.sessionById?.date)}
                      tooMany={sessionAndReview?.sessionById?.rescheduleCount > 1}
                      onCompleted={() => history.push(`/sessions`)}
                      hideFeedback
                    />
                  ) : (
                    <PreviousActions
                      session={sessionAndReview?.sessionById}
                      direction={"previous"}
                      hideFeedback
                      onCompleted={() => history.push(`/sessions`)}
                      connectionBySessionId={sessionAndReview?.connectionBySessionId}
                    />
                  )}
                </>
              )}
            </Pane>
            {loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Spinner />
              </Pane>
            )}
            {error && !loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Alert title="There was an error loading this page. Please try again in a few moments" />
              </Pane>
            )}
            {sessionAndReview?.sessionById?.id && (
              <>
                <Pane width="100%">
                  <SessionCard myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} hideActions />
                </Pane>
                <TabToggle
                  id={id}
                  state={state}
                  myFeedback={sessionAndReview.feedbackForSession}
                  upcoming={isUpcoming(sessionAndReview?.sessionById?.date)}
                />
                {state === "prepare" && <PrepareForm myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />}
                {state === "follow-up" && <FollowUpForm myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />}
              </>
            )}
          </Col>
        </Row>
      )}
    />
  );
};

export default SessionDetailPage;
