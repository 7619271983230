import React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from "@apollo/client";
// import { setConfiguration } from 'react-grid-system';

import client from "./client";

// setConfiguration({
//   containerWidths: [375, 740, 960, 1140, 1140],
//   gutterWidth: 32
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
