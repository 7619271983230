const QueryUtils = {};

QueryUtils.PageInfo = `
  pageInfo {
    hasNextPage,
    nextPage,
    edgeCount
  }
`;

export default QueryUtils;
