import React, { useState } from "react";
import { startCase, isEqual } from "lodash";
import { Hidden, Visible } from "react-grid-system";

import {
  Text,
  Pane,
  SearchInput,
  SelectMenu,
  Button,
  minorScale,
  majorScale,
  CaretDownIcon,
  Position,
  Switch,
  defaultTheme as theme
} from "evergreen-ui";

import { INITIAL_FILTERS } from "../MentorsPage";

export const formatLabel = (label) => ({ label, value: label });

export const formatTopicLabel = (topic) => ({ label: topic.name, value: topic.id });

export const FILTER_TYPE = {
  TOPICS: "topics",
  SEARCH: "name",
  ROLES: "roles",
  LEVELS: "levels",
  SORT: "sort",
  EXPERIENCE: "experience",
  RELOCATION: "relocation",
  REMOTE: "remote",
  LOCATION: "location"
};

const SORT_LABELS = {
  DEFAULT: "relevant",
  NEWEST: "recently added",
  AVAILABILITY: "next available",
  REVIEWS: "most reviews",
  EXPERIENCE: "most sessions"
};

export const SearchBar = ({ type, value, onFilterUpdate, fullWidth }) => {
  const handleChange = (e) => {
    onFilterUpdate(type, e.target.value);
    window.analytics.track("Filter Leaders -> Name");
  };

  return (
    <SearchInput
      value={value}
      type={type}
      placeholder="Search by name"
      width={fullWidth ? "100%" : "195px"}
      marginRight={8}
      onChange={handleChange}
    />
  );
};

export const FilterSelect = ({ type, title, selected = [], options, onFilterUpdate, trackEvent }) => {
  const count = selected?.length;

  const handleSelect = (item) => {
    const updatedItems = [...selected, item.value];
    onFilterUpdate(type, updatedItems);
    window.analytics.track(trackEvent);
  };

  const handleDeselect = (item) => {
    const deselectedItemIndex = selected.indexOf(item.value);
    const updatedItems = selected.filter((_item, i) => i !== deselectedItemIndex);
    onFilterUpdate(type, updatedItems);
  };

  return (
    <SelectMenu isMultiSelect title={title} selected={selected} options={options} onSelect={handleSelect} onDeselect={handleDeselect}>
      <Button
        paddingLeft={minorScale(3)}
        paddingRight={majorScale(1)}
        justifyContent="space-between"
        iconAfter={<CaretDownIcon color="muted" />}
      >
        {title}
        {count > 0 && ` (${count})`}{" "}
      </Button>
    </SelectMenu>
  );
};

export const FilterToggle = ({ type, title, selected = false, onFilterUpdate, trackEvent }) => {
  const handleChange = (e) => {
    onFilterUpdate(type, e.target.checked);
    window.analytics.track(trackEvent);
  };

  return (
    <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
      <Switch marginRight={minorScale(3)} checked={selected} onChange={handleChange} />
      <Text>{title}</Text>
    </Pane>
  );
};

export const SortSelect = ({ type, selected, availableSorts = [], onFilterUpdate }) => {
  const [selectedSort, setSelectedSort] = useState(selected);
  const options = availableSorts.map((type) => ({
    value: type,
    label: startCase(SORT_LABELS[type])
  }));

  const handleSelect = (item) => {
    setSelectedSort(item.value);
    onFilterUpdate(type, item.value);
    window.analytics.track(`Sort Leaders -> ${item.value}`);
  };

  return (
    <SelectMenu
      title="Sort"
      options={options}
      selected={selected}
      position={Position.BOTTOM_RIGHT}
      onSelect={handleSelect}
      hasFilter={false}
    >
      <Pane cursor="pointer" display="flex" alignItems="center">
        <Text color="#696F8C">
          Sorted by <b>{SORT_LABELS[selectedSort]}</b>
        </Text>
        <CaretDownIcon marginLeft={minorScale(1)} color="muted" />
      </Pane>
    </SelectMenu>
  );
};

const FiltersRow = ({ selectedFilters = {}, filterData = {}, topicData = {}, onFilterUpdate, onFiltersReset }) => {
  const { availableTopics } = topicData;
  const { availableRoles, availableLevels, availableSorts } = filterData;

  return (
    <Pane width="100%" display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
      <Pane display="flex" alignItems="center" flexWrap="wrap" marginTop={majorScale(1)}>
        <Hidden xs>
          <SearchBar type={FILTER_TYPE.SEARCH} value={selectedFilters?.name} onFilterUpdate={onFilterUpdate} />
          <Pane marginRight={8}>
            <FilterSelect
              title="Job roles"
              type={FILTER_TYPE.ROLES}
              selected={selectedFilters?.roles}
              options={availableRoles?.map(formatLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter Leaders -> Roles"
            />
          </Pane>
        </Hidden>
        <Visible xs>
          <Pane marginBottom={majorScale(1)} marginRight={majorScale(1)}>
            <SearchBar type={FILTER_TYPE.SEARCH} value={selectedFilters?.name} onFilterUpdate={onFilterUpdate} />
            <FilterSelect
              title="Job roles"
              type={FILTER_TYPE.ROLES}
              selected={selectedFilters?.roles}
              options={availableRoles?.map(formatLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter Leaders -> Roles"
            />
          </Pane>
        </Visible>
        <Pane marginRight={8}>
          <FilterSelect
            title="Levels"
            type={FILTER_TYPE.LEVELS}
            selected={selectedFilters?.levels}
            options={availableLevels?.map(formatLabel)}
            onFilterUpdate={onFilterUpdate}
            trackEvent="Filter Leaders -> Levels"
          />
        </Pane>
        <Pane marginRight={8}>
          <FilterSelect
            title="Topics"
            type={FILTER_TYPE.TOPICS}
            selected={selectedFilters?.topics}
            options={availableTopics?.map(formatTopicLabel)}
            onFilterUpdate={onFilterUpdate}
            trackEvent="Filter Leaders -> Topics"
          />
        </Pane>
        {!isEqual(selectedFilters, INITIAL_FILTERS) && (
          <Pane marginRight={8}>
            <Button appearance="minimal" onClick={onFiltersReset} paddingX={majorScale(1)}>
              Clear filters
            </Button>
          </Pane>
        )}
      </Pane>
      <Pane marginTop={majorScale(1)}>
        <SortSelect
          selected={selectedFilters?.sort}
          type={FILTER_TYPE.SORT}
          availableSorts={availableSorts}
          onFilterUpdate={onFilterUpdate}
        />
      </Pane>
    </Pane>
  );
};

export default FiltersRow;
