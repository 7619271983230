import React, { useState } from "react";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  Heading,
  Pane,
  Paragraph,
  majorScale,
  minorScale,
  defaultTheme,
  Spinner,
  EditIcon,
  ShareIcon,
  StyleIcon
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";

import EditConflicts from "../profile/edit/calendar/EditConflicts";
import EditTimeZone from "../profile/edit/calendar/EditTimeZone";
import EditOpeningHours from "../profile/edit/calendar/EditOpeningHours";
import ProfileList from "../../queries/profile/ProfileList";
import BookSession from "../profile/scheduler/BookSession";

const width = {
  xs: 375,
  // xs: 540,
  sm: 740,
  md: 960,
  xl: 1140,
  xxl: 1140
};

const MentorSetupPage = (props) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const { data: profileResult } = useQuery(ProfileList.MyCoachingProfile);
  let myProfile, myCoachingProfile;

  if (profileResult) {
    myProfile = profileResult?.myProfile;
    myCoachingProfile = profileResult?.myCoachingProfile;
  }

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          style={{
            width: width[screenClass],
            minHeight: "calc(100vh - 120px)",
            paddingTop: majorScale(2),
            paddingBottom: majorScale(6)
          }}
        >
          <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
            <>
              <Pane marginY={majorScale(4)} display="flex" flexDirection="row" justifyContent="space-between">
                <Heading size={700}>
                  <StyleIcon marginRight={majorScale(1)} />
                  Configure your mentorship calendar
                </Heading>
                <Dialog
                  title="Calendar Preview"
                  isShown={showCalendar}
                  onCloseComplete={() => setShowCalendar(false)}
                  onCancel={() => setShowCalendar(false)}
                  preventBodyScrolling
                  shouldCloseOnEscapePress={true}
                  shouldCloseOnOverlayClick={true}
                  hasFooter={false}
                >
                  <BookSession profile={myProfile} coachByProfileId={myCoachingProfile} myProfile={myProfile} inline isPreview />
                </Dialog>
                <Button iconBefore={ShareIcon} appearance="minimal" onClick={() => setShowCalendar(true)}>
                  Preview Calendar
                </Button>
              </Pane>
              <Pane
                backgroundColor={"white"}
                borderRadius={minorScale(3)}
                border={`1px solid ${defaultTheme.colors.gray400}`}
                width="100%"
                padding={majorScale(4)}
                marginBottom={majorScale(3)}
              >
                {myProfile?.id && myCoachingProfile?.id ? (
                  <Pane display="flex" flexDirection="column">
                    <Pane marginBottom={majorScale(2)}>
                      <Heading size={600}>Configure your calendar</Heading>
                      <Paragraph size={500}>Make sure you’re scheduled for times that work best for you</Paragraph>
                    </Pane>
                    <EditConflicts myProfile={myProfile} />
                    <br />
                    <EditOpeningHours myCoachingProfile={myCoachingProfile} />
                    <br />
                    <EditTimeZone myProfile={myProfile} />
                    <Pane display="flex" justifyContent="space-between" width="100%" marginTop={majorScale(2)}>
                      <Button is={BrowserLink} to="/profile/edit">
                        Skip
                      </Button>
                      <Button is={BrowserLink} to="/profile/edit" appearance="primary">
                        Preview your profile
                      </Button>
                    </Pane>
                  </Pane>
                ) : (
                  <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Spinner />
                  </Pane>
                )}
              </Pane>
            </>
          </Col>
        </Row>
      )}
    />
  );
};

export default MentorSetupPage;
