import _ from "lodash";
import React, { useState } from "react";
import { Button, IconButton, Pane, Paragraph, CaretDownIcon, CrossIcon, SelectMenu, majorScale, toaster } from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";

import ProfileList from "../../../queries/profile/ProfileList";

const MULTIPLE_CONFLICTS = "Checking for conflicts on";
const AT_LEAST_ONE_WARNING = "Choose at least one calendar.";

const renderTitle = ({ selectedItems, optsDict }) => {
  const selectedItemsLength = selectedItems.length;
  let selectedTitle;

  if (_.isEqual(selectedItemsLength, 0)) {
    selectedTitle = "";
  } else if (_.isEqual(selectedItemsLength, 1)) {
    selectedTitle = optsDict[_.head(selectedItems)][0]["summary"];
  } else if (selectedItemsLength > 1) {
    selectedTitle = `${selectedItemsLength} selected...`;
  }

  return selectedTitle;
};

const handleSelect = ({ item, selected, setSelected, setSelectedNames, optsDict, onUpdate }) => {
  const selectedItems = [...selected, item.value];
  const title = renderTitle({ selectedItems, optsDict });

  setSelected(selectedItems);
  setSelectedNames(title);
  onUpdate(selectedItems);
};

const handleDeSelect = ({ item, selected, setSelected, setSelectedNames, optsDict, onUpdate }) => {
  const deselectedItemIndex = selected.indexOf(item.value);
  const selectedItems = selected.filter((_item, i) => i !== deselectedItemIndex);

  if (!selectedItems.length) {
    toaster.warning(AT_LEAST_ONE_WARNING, { duration: 2 });
  } else {
    const title = renderTitle({ selectedItems, optsDict });

    setSelected(selectedItems);
    setSelectedNames(title);
    onUpdate(selectedItems);
  }
};

const createOnUpdate = (updateCalendarConflicts, googleId) => (calendarIds) =>
  updateCalendarConflicts({
    variables: {
      calendarIds,
      googleId
    }
  });

const CalendarConflicts = ({ googleCalendar }) => {
  const { data: currentCalendarConflicts } = useQuery(ProfileList.CurrentCalendarConflicts, {
    variables: {
      googleId: googleCalendar?.googleId
    }
  });

  const { data: availableCalendars } = useQuery(ProfileList.AvailableGoogleCalendars, {
    variables: {
      googleId: googleCalendar?.googleId
    }
  });

  const [updateCalendarConflicts, { loading }] = useMutation(ProfileList.UpdateCalendarConflicts, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.CurrentCalendarConflicts }, { query: ProfileList.AvailableNylasCalendars }],
    onCompleted: () => toaster.success("Updated calendars to check.", { duration: 2, id: "updated-conflicts" })
  });

  const [disconnectGoogleCalendar, { loading: removing }] = useMutation(ProfileList.DisconnectGoogleCalendar, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.CurrentCalendarConflicts },
      { query: ProfileList.AvailableNylasCalendars },
      { query: ProfileList.GoogleConnectLink }
    ],
    onCompleted: () => toaster.success("Removed connection", { duration: 2, id: "removed-connection" })
  });

  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState(null);
  const onUpdate = createOnUpdate(updateCalendarConflicts, googleCalendar?.googleId);

  if (_.isNull(availableCalendars)) return null;

  if (availableCalendars?.availableGoogleCalendars && currentCalendarConflicts?.currentCalendarConflicts) {
    const options = availableCalendars.availableGoogleCalendars.map(({ id, summary }) => ({ value: id, label: summary }));

    const optsDict = _.groupBy(availableCalendars.availableGoogleCalendars, (cal) => cal.id);
    const conflicts =
      currentCalendarConflicts?.currentCalendarConflicts?.length > 0 ? currentCalendarConflicts?.currentCalendarConflicts : null;

    if (_.isEmpty(selected)) {
      setSelected(conflicts);
    }

    if (_.isNil(selectedNames)) {
      let selectedTitle = "";

      if (conflicts?.length > 1) {
        selectedTitle = `${conflicts?.length} selected...`;
      } else {
        selectedTitle = optsDict[_.head(conflicts)][0]["summary"];
      }

      setSelectedNames(selectedTitle);
    }

    return (
      <Pane display="flex" flexDirection="column" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
        <Pane display="flex" justifyContent="space-between" alignItems="center">
          <Pane>
            <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <IconButton
                iconBefore={CrossIcon}
                appearance="minimal"
                intent="danger"
                isLoading={removing}
                onClick={() =>
                  disconnectGoogleCalendar({
                    variables: {
                      googleId: googleCalendar?.googleId
                    }
                  })
                }
              />
              <Paragraph size={400}>{googleCalendar?.email}</Paragraph>
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Paragraph size={400} marginRight={majorScale(1)}>
              {MULTIPLE_CONFLICTS}
            </Paragraph>
            <SelectMenu
              isMultiSelect
              title={selectedNames}
              options={options}
              selected={selected || conflicts}
              onSelect={(item) =>
                handleSelect({
                  item,
                  selected,
                  setSelected,
                  setSelectedNames,
                  optsDict,
                  onUpdate
                })
              }
              onDeselect={(item) =>
                handleDeSelect({
                  item,
                  selected,
                  setSelected,
                  setSelectedNames,
                  optsDict,
                  onUpdate
                })
              }
            >
              <Button iconBefore={CaretDownIcon} isLoading={loading}>
                {selectedNames}
              </Button>
            </SelectMenu>
          </Pane>
        </Pane>
      </Pane>
    );
  } else return null;
};

const CalendarConflictsContainer = ({ googleCalendar }) => {
  return <>{googleCalendar?.length > 0 && googleCalendar.map((gCal) => <CalendarConflicts googleCalendar={gCal} />)}</>;
};

export default CalendarConflictsContainer;
