import _ from "lodash";
import React, { useState } from "react";
import { Button, Dialog, TrashIcon, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import FeedQueries from "../../queries/feed";

const SUCCESS_TOAST = "Successfully deleted.";

const resolvers = {
  comment: {
    mutation: FeedQueries.mutations.DeleteComment,
    header: "Are you sure you want to delete this reply?",
    confirmLabel: "Delete"
  },

  post: {
    mutation: FeedQueries.mutations.DeletePost,
    header: "Are you sure you want to delete this post?",
    confirmLabel: "Delete"
  },

  reply: {
    mutation: FeedQueries.mutations.DeleteReply,
    header: "Are you sure you want to delete this reply?",
    confirmLabel: "Delete"
  }
};

const findObj = ({ reply, comment, post }) => {
  if (reply) return reply;
  else if (comment) return comment;
  else return post;
};

const findObjType = ({ reply, comment, post }) => {
  if (reply) return "reply";
  else if (comment) return "comment";
  else return "post";
};

const DeleteItem = ({ reply, comment, post, myProfile, onDelete }) => {
  const [showDialog, setShowDialog] = useState(false);

  const obj = findObj({ reply, comment, post });
  const objType = findObjType({ reply, comment, post });

  const [removeItem] = useMutation(resolvers[objType]?.mutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: FeedQueries.queries.FeedQuery,
        variables: {
          cursor: "1",
          sort: "POPULAR"
        }
      },
      { query: FeedQueries.queries.Comments, variables: { postId: post?.id } },
      { query: FeedQueries.queries.Replies, variables: { commentId: comment?.id } }
    ],
    onCompleted: () => {
      toaster.success(SUCCESS_TOAST, { duration: 2 });

      if (onDelete && _.isFunction(onDelete)) {
        onDelete();
      }
    }
  });

  return (
    <>
      <Dialog
        title={resolvers[objType]["header"]}
        confirmLabel={resolvers[objType]["confirmLabel"]}
        isShown={showDialog}
        preventBodyScrolling
        shouldCloseOnEscapePress={true}
        shouldCloseOnOverlayClick={true}
        hasFooter={true}
        onCloseComplete={() => {
          const variables = _.isEqual(objType, "post") ? { postId: obj?.id } : { id: obj?.id };

          removeItem({ variables });
        }}
      />
      <Button
        key={`DeleteItem-${comment?.id ? comment.id : post?.id}`}
        size="small"
        fontWeight={600}
        display="flex"
        alignItems="center"
        justifyContent="center"
        appearance="minimal"
        fontSize={14}
        borderRadius={999}
        height={28}
        style={{ borderRadius: 99 }}
        onClick={() => setShowDialog(true)}
      >
        <TrashIcon color="#8F95B2" marginRight={5} />
        Delete
      </Button>
    </>
  );
};

export default DeleteItem;
