import _, { isEqual } from "lodash";
import React, { useState } from "react";

import { Button, Heading, Pane, Position, SelectMenu, majorScale, minorScale, CaretDownIcon, Text } from "evergreen-ui";

import { Formik, Field, Form } from "formik";
import { Hidden, Visible } from "react-grid-system";

import ProfileList from "../../queries/profile/ProfileList";
import SearchInput from "../form/SearchInput";
import PostCreate from "../post/PostCreate";
import { MOTIVATIONS } from "./constants";

const SORT_LABELS = {
  DEFAULT: "most mentors",
  POPULAR: "most popular",
  FRESHEST: "recently updated",
  NEWEST: "newest"
};

const formatLabel = (label) => ({ label, value: label });

const DEFAULT_FILTERS = {
  cursor: "1",
  name: "",
  levels: [],
  roles: [],
  motivations: [],
  sort: "DEFAULT"
};

const RoleFilter = ({ roles, availableRoles, onSelect, onDeselect }) => {
  const roleOpts = availableRoles ? availableRoles.map(formatLabel) : [];
  const title = roles.length > 0 ? `${roles.length} role${roles.length !== 1 ? "s" : ""}` : "Job roles";

  return (
    <SelectMenu
      isMultiSelect
      title={title}
      options={roleOpts}
      selected={roles}
      onSelect={onSelect}
      onDeselect={onDeselect}
      hasFilter={false}
    >
      <Button paddingLeft={minorScale(3)} paddingRight={majorScale(1)}>
        {title} <CaretDownIcon marginLeft={minorScale(1)} color="muted" />
      </Button>
    </SelectMenu>
  );
};

const MotivationsFilter = ({ motivations, availableMotivations, onSelect, onDeselect }) => {
  const formatMotivations = (value) => ({ label: MOTIVATIONS[value], value });
  const options = availableMotivations?.map(formatMotivations) || [];
  const title = motivations.length > 0 ? `${motivations.length} motivation${motivations.length !== 1 ? "s" : ""}` : " Motivations";

  return (
    <SelectMenu
      isMultiSelect
      title={title}
      options={options}
      selected={motivations}
      onSelect={onSelect}
      onDeselect={onDeselect}
      hasFilter={false}
    >
      <Button paddingLeft={minorScale(3)} paddingRight={majorScale(1)}>
        {title} <CaretDownIcon marginLeft={minorScale(1)} color="muted" />
      </Button>
    </SelectMenu>
  );
};

class TopicFilters extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeSelect = this.handleDeselect.bind(this);
    this.state = { selected: _.cloneDeep(props.query) };
  }

  handleSelect(item, key, setSelected) {
    const selected = [...this.state.selected[key], item.value];
    const updatedFilters = {
      selected: { ...this.state.selected, [key]: selected }
    };

    this.setState(updatedFilters, () => setSelected(selected));
  }

  handleDeselect(item, key, setSelected) {
    const deselectedItemIndex = this.state.selected[key].indexOf(item.value);
    const selected = this.state.selected[key].filter((_, i) => i !== deselectedItemIndex);
    const updatedFilters = {
      selected: { ...this.state.selected, [key]: selected }
    };

    this.setState(updatedFilters, () => setSelected(selected));
  }

  handleClear(updateSearchForm) {
    this.setState({ selected: DEFAULT_FILTERS }, () => {
      Object.entries(DEFAULT_FILTERS).map(([key, value]) => updateSearchForm(key)(value));
    });
  }

  sortResults(availableSorts, setSelected) {
    const opts = _.map(availableSorts, (value) => ({ value, label: SORT_LABELS[value] }));
    const selected = this?.state?.selected?.sort;

    const onSelected = (sort) => {
      this.setState(
        {
          selected: {
            ...this.state.selected,
            sort
          }
        },
        () => {
          setSelected(sort);
        }
      );
    };

    return (
      <Pane>
        <SelectMenu
          title="Sort By"
          hasFilter={false}
          options={opts}
          selected={selected}
          onSelect={(item) => onSelected(item.value)}
          onDeselect={(item) => onSelected(item.value)}
          position={Position.BOTTOM_RIGHT}
        >
          <Pane cursor="pointer" display="flex" alignItems="center">
            <Text color="#696F8C">
              Sorted by <b>{SORT_LABELS[selected]}</b>
            </Text>
            <CaretDownIcon marginLeft={minorScale(1)} color="muted" />
          </Pane>
        </SelectMenu>
      </Pane>
    );
  }

  renderSearchBar({ setFieldValue, submitForm }, options) {
    const fullWidth = options?.fullWidth;

    return (
      <Pane marginRight={fullWidth ? 0 : majorScale(1)}>
        <Field
          className="search-filter"
          width={fullWidth ? "100%" : 200}
          type="text"
          component={SearchInput}
          onChange={(e) => {
            window.analytics.track("Filter Topics -> Name");
            e.persist();
            setFieldValue("name", e.target.value, false);
            submitForm();
            this.setState({
              selected: {
                ...this.state.selected,
                name: e.target.value
              }
            });
          }}
          name="name"
          placeholder="Search topics"
          borderLeftColor="transparent"
        />
      </Pane>
    );
  }

  render() {
    const { updateSearch, topicFilters = {} } = this.props;
    const { availableJobRoles, availableMotivations, availableSorts } = topicFilters;
    const { selected } = this.state;

    return (
      <Formik
        initialValues={this.props.query}
        onSubmit={(updatedSearch) => {
          updateSearch(updatedSearch);
        }}
        render={(formikBag) => {
          const { setFieldValue, submitForm } = formikBag;

          const updateSearchForm = (field) => (choice) => {
            setFieldValue(field, choice, false);
            submitForm();
          };

          return (
            <Form>
              <Hidden xs>
                <Pane paddingTop={majorScale(3)} marginBottom={majorScale(2)} width="100%">
                  <Pane display="flex" alignItems="center" justifyContent="space-between">
                    <Pane display="flex">
                      {this.renderSearchBar({ setFieldValue, submitForm })}
                      <RoleFilter
                        roles={selected?.roles}
                        availableRoles={availableJobRoles}
                        onDeselect={(item) => this.handleDeselect(item, "roles", (val) => updateSearchForm("roles")(val))}
                        onSelect={(item) => {
                          window.analytics.track("Filter Topics -> Roles");
                          this.handleSelect(item, "roles", (val) => updateSearchForm("roles")(val));
                        }}
                      />
                      <Pane marginLeft={majorScale(1)}>
                        <MotivationsFilter
                          motivations={selected?.motivations}
                          availableMotivations={availableMotivations}
                          onDeselect={(item) => this.handleDeselect(item, "motivations", (val) => updateSearchForm("motivations")(val))}
                          onSelect={(item) => {
                            window.analytics.track("Filter Topics -> Motivation");
                            this.handleSelect(item, "motivations", (val) => updateSearchForm("motivations")(val));
                          }}
                        />
                      </Pane>
                      <Pane marginLeft={majorScale(1)}>
                        {!isEqual(selected, DEFAULT_FILTERS) && (
                          <Button paddingX={minorScale(3)} appearance="minimal" onClick={() => this.handleClear(updateSearchForm)}>
                            Clear filters
                          </Button>
                        )}
                      </Pane>
                    </Pane>
                    <Pane display="flex" alignItems="center">
                      {this.sortResults(availableSorts, updateSearchForm("sort"))}
                    </Pane>
                  </Pane>
                </Pane>
              </Hidden>
              <Visible xs>
                <Pane>
                  <Pane marginBottom={majorScale(1)}>{this.renderSearchBar({ setFieldValue, submitForm })}</Pane>
                  <Pane marginBottom={majorScale(1)} display="flex" justifyContent="space-between" alignItems="center">
                    <RoleFilter
                      roles={this?.state?.selected?.roles}
                      availableRoles={availableJobRoles}
                      onDeselect={(item) => this.handleDeselect(item, "roles", (val) => updateSearchForm("roles")(val))}
                      onSelect={(item) => {
                        window.analytics.track("Filter Topics -> Roles");
                        this.handleSelect(item, "roles", (val) => updateSearchForm("roles")(val));
                      }}
                    />
                    <MotivationsFilter
                      motivations={selected?.motivations}
                      availableMotivations={availableMotivations}
                      onDeselect={(item) => this.handleDeselect(item, "motivations", (val) => updateSearchForm("motivations")(val))}
                      onSelect={(item) => {
                        window.analytics.track("Filter Topics -> Motivation");
                        this.handleSelect(item, "motivations", (val) => updateSearchForm("motivations")(val));
                      }}
                    />
                  </Pane>
                  <Pane marginBottom={majorScale(1)} display="flex" justifyContent="space-between" alignItems="center">
                    <Heading>Sort By</Heading>
                    {this.sortResults(availableSorts, updateSearchForm("sort"))}
                  </Pane>
                  <Pane>
                    {!isEqual(this.state.selected, DEFAULT_FILTERS) && (
                      <Button width="100%" paddingX={minorScale(3)} appearance="minimal" onClick={() => this.handleClear(updateSearchForm)}>
                        Clear filters
                      </Button>
                    )}
                  </Pane>
                </Pane>
              </Visible>
            </Form>
          );
        }}
      />
    );
  }
}

export default TopicFilters;
