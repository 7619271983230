import _ from "lodash";
import React from "react";
import { Link as BrowserLink } from "react-router-dom";
import { Avatar, Pane, Link, Paragraph, Heading, Button, majorScale, defaultTheme as theme } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import TeamQueries from "../../queries/team";
import createJobTag from "./createJobTag";

const JobTags = ({ job }) => {
  const { location, role, level } = job;

  return (
    <Pane justifyContent="flex-start">
      {location ? createJobTag({ type: "Location", tag: location }) : null}
      {role ? createJobTag({ type: role, tag: role }) : null}
      {level ? createJobTag({ type: "Level", tag: level }) : null}
    </Pane>
  );
};

const ApplyButton = ({ job }) => {
  const [applyToJob] = useMutation(TeamQueries.mutations.ApplyToJob);

  return (
    <Button is="a" target="_blank" href={job?.link} appearance="primary" onClick={(e) => applyToJob({ variables: { jobId: job.id } })}>
      Apply
    </Button>
  );
};

const Job = ({ job, team, apply }) => {
  if (_.isEmpty(team) || _.isEmpty(job?.title)) return null;

  return (
    <Link is={BrowserLink} to={`/job/${job?.id}`} style={{ textDecoration: "none" }}>
      <Pane
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray500}`}
        borderRadius={majorScale(2)}
        padding={majorScale(2)}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginBottom={majorScale(2)}
      >
        <Pane display="flex" flexDirection="columnn">
          <Avatar src={team.logo} name={team.name} size={60} />
          <Pane alignItems="start" display="flex" flexDirection="column" justifyContent="center" paddingLeft={majorScale(2)}>
            <Heading size={500} paddingBottom={majorScale(1)}>
              {job.title}
            </Heading>
            <Paragraph size={500}>{job.team.name}</Paragraph>
            <JobTags job={job} />
          </Pane>
        </Pane>
        {apply && <ApplyButton job={job} />}
      </Pane>
    </Link>
  );
};

export default Job;
