import React from "react";
import Moment from "moment";
import { Button, Heading, Pane, SelectMenu, majorScale, minorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";

const TIMEZONE_TOAST = `Timezone updated.`;

const EditTimeZone = ({ myProfile, slim }) => {
  const [updateTimeZone] = useMutation(ProfileList.UpdateTimezone, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => toaster.success(TIMEZONE_TOAST, { duration: 2 })
  });

  const timezones = Moment.tz.names();
  const title = myProfile.timezone ? myProfile.timezone : "Select your timezone";
  const options = timezones.map((name) => ({ value: name, label: name }));
  const selected = myProfile.timezone ? myProfile.timezone : "America/New_York";

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingY={majorScale(1)}>
      {slim ? (
        <Heading marginBottom={minorScale(1)}>What is your timezone?</Heading>
      ) : (
        <Heading marginBottom={minorScale(1)}>🌎 &nbsp; What is your timezone?</Heading>
      )}
      <SelectMenu
        title={title}
        options={options}
        selected={selected}
        onSelect={(item) => {
          window.analytics.track("Update Timezone");

          updateTimeZone({
            variables: {
              timezone: item.value
            }
          });
        }}
      >
        <Button marginLeft={majorScale(1)} type="button">
          {title}
        </Button>
      </SelectMenu>
    </Pane>
  );
};

export default EditTimeZone;
