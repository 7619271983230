import _ from "lodash";
import React from "react";
import { Button, Heading, Pane, Paragraph, Text, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import { useQuery } from "@apollo/client";

import { Link as BrowserLink } from "react-router-dom";
import ProfileList from "../../../queries/profile/ProfileList";
import SessionQueries from "../../../queries/session";

import { MENTOR, MEMBER, CANDIDATE } from "./content";

const steps = [
  {
    icon: "🐣",
    text: "Getting Started",
    color: `#FFEFD2`,
    completed: true
  },
  {
    icon: "🐥",
    text: "First Steps",
    color: `#FFDFA6`,
    completed: false
  },
  {
    icon: "🦉",
    text: "Advanced",
    color: `#FFD079`,
    completed: false
  }
  // {
  //   icon: "🦅",
  //   text: "All Set",
  //   color: `#FFB020`,
  //   completed: false,
  // },
];

const ProgressBar = ({ current, isMobile }) => {
  return (
    <Pane
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      borderRadius={majorScale(2)}
      marginBottom={majorScale(2)}
      justifyContent="center"
    >
      {steps.map((step, index) => {
        let borderRadius;
        const isFirst = index === 0;
        const isLast = index === steps.length - 1;

        if (!isMobile) {
          if (isFirst) {
            borderRadius = {
              borderBottomLeftRadius: majorScale(2),
              borderTopLeftRadius: majorScale(2)
            };
          } else if (isLast) {
            borderRadius = {
              borderTopRightRadius: majorScale(2),
              borderBottomRightRadius: majorScale(2)
            };
          } else {
            borderRadius = null;
          }
        } else {
          if (isFirst) {
            borderRadius = {
              borderTopLeftRadius: majorScale(2),
              borderTopRightRadius: majorScale(2)
            };
          } else if (isLast) {
            borderRadius = {
              borderBottomLeftRadius: majorScale(2),
              borderBottomRightRadius: majorScale(2)
            };
          } else {
            borderRadius = null;
          }
        }

        const completed = current >= index;

        return (
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={majorScale(5)}
            width={isMobile ? "100%" : minorScale(45)}
            backgroundColor={completed ? step.color : "#F4F6FA"}
            opacity={completed ? undefined : "0.50"}
            border="1px solid #E6E8F0"
            {...borderRadius}
          >
            <Heading size={500}>{`${step.icon} ${step.text}`}</Heading>
          </Pane>
        );
      })}
    </Pane>
  );
};

const determineMemberStep = ({ myProfile, profileStrength }) => {
  const { myMostRecentBooking } = profileStrength;

  if (profileStrength?.myQuestionCount < 1) {
    return 0;
  } else if (!myMostRecentBooking?.id) {
    return 1;
  } else if (!myProfile?.bestPractices) {
    return 2;
  } else return 3;
};

const determineMentorStep = ({ profileStrength, offersResult }) => {
  const { myCoachingProfile, myProfile } = profileStrength;
  const { mySessionOffers } = offersResult;

  if (mySessionOffers?.edges?.length < 3) {
    return 0;
  } else if (!myCoachingProfile?.bestPractices) {
    return 1;
  } else if (!myProfile?.socialShare?.when) {
    return 2;
  } else return 3;
};

const determineCandidateStep = ({ profileStrength }) => {
  const { myProfile, myCandidateProfile, myMostRecentBooking } = profileStrength;

  if (!myCandidateProfile?.specialities?.length > 0) {
    return 0;
  } else if (!myProfile?.role?.title?.length > 0) {
    return 1;
  } else if (!myMostRecentBooking?.id) {
    return 2;
  } else return 3;
};

const isMentor = ({ myCoachingProfile }) => {
  return (
    myCoachingProfile &&
    (_.isEqual(myCoachingProfile?.status, "Active") ||
      _.isEqual(myCoachingProfile?.status, "Pending") ||
      _.isEqual(myCoachingProfile?.status, "Inactive"))
  );
};

const ProfileStrength = (profileContext) => {
  const { myProfile, setShowShare, isMobile, onButtonClick } = profileContext;

  const { data: profileStrength } = useQuery(ProfileList.ProfileStrengthQuery);
  const { data: offersResult } = useQuery(SessionQueries.queries.MyOfferedSessions);

  let button, allSteps, current;

  if (profileStrength && offersResult) {
    const isActiveCandidate = ["active", "passive"].includes(profileStrength?.myCandidateProfile?.status);

    allSteps = isMentor({ myCoachingProfile: profileStrength?.myCoachingProfile }) ? MENTOR : MEMBER;
    allSteps = isActiveCandidate ? CANDIDATE : allSteps;
    let determineSteps = profileStrength?.myCoachingProfile?.id ? determineMentorStep : determineMemberStep;
    determineSteps = isActiveCandidate ? determineCandidateStep : determineSteps;
    current = profileStrength && determineSteps({ profileStrength, myProfile, offersResult });

    if (!allSteps[current]?.link) {
      button = null;
    } else if (allSteps[current]?.link?.includes("?section=share")) {
      button = (
        <Button width={majorScale(20)} appearance="primary" iconBefore={allSteps[current]?.icon} onClick={() => setShowShare(true)}>
          {allSteps[current]?.cta}
        </Button>
      );
    } else {
      button = (
        <Button
          width={majorScale(20)}
          appearance="primary"
          iconBefore={allSteps[current]?.icon}
          is={BrowserLink}
          to={allSteps[current]?.link}
          onClick={() => {
            window.history.push(allSteps[current]?.link);
            // window.location.reload()
          }}
        >
          {allSteps[current]?.cta}
        </Button>
      );
    }
  }

  return profileStrength && !_.isEqual(current, 3) ? (
    <Pane
      backgroundColor="white"
      padding={majorScale(3)}
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      marginBottom={majorScale(3)}
    >
      <Heading size={700} marginBottom={majorScale(2)}>
        How to improve your profile
      </Heading>
      <ProgressBar current={current} isMobile={isMobile} />
      {profileStrength && offersResult && (
        <>
          <Pane display="flex" flexDirection="column">
            <Heading size={600} marginBottom={minorScale(1)}>
              {allSteps[current]?.header}
            </Heading>
            <Paragraph size={500}>{allSteps[current]?.copy}</Paragraph>
            <Pane display="flex" flexDirection="row" width="100%" justifyContent="flex-end" paddingTop={majorScale(1)}>
              {button}
            </Pane>
          </Pane>
        </>
      )}
    </Pane>
  ) : null;
};

export default ProfileStrength;
