import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Pane, Link, Spinner } from "evergreen-ui";

import ListEnd from "../ListEnd";
import PostCard from "../post/PostCard";
import DiscussionItem from "./DiscussionItem";

const END_MSG = "No more posts available.";

const footer = (
  <Pane width="100%" alignItems="center">
    <ListEnd message={END_MSG} />
  </Pane>
);

const loader = (
  <Pane display="flex" flexDirection="row" width="100%" justifyContent="center">
    <Spinner />
  </Pane>
);

const DiscussionIndex = ({ next, posts, myProfile, refetch, history }) =>
  posts?.edges ? (
    <InfiniteScroll
      style={{ display: "flex", flexWrap: "wrap" }}
      hasMore={posts.pageInfo.hasNextPage}
      dataLength={posts.edges.length}
      loader={loader}
      endMessage={footer}
      next={() => next()}
    >
      {posts.edges.map(
        (post) =>
          post?.id &&
          post?.owner?.id && (
            <DiscussionItem
              post={post}
              key={`DiscussionIndex-DiscussionItem-${post.id}`}
              myProfile={myProfile}
              refetch={refetch}
              history={history}
            />
          )
      )}
    </InfiniteScroll>
  ) : (
    loader
  );

export default DiscussionIndex;
