import React from "react";
import { Pane, Heading, Button, Text, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { Hidden, Row, Col } from "react-grid-system";

import topic from "../../../images/topic-placeholder.png";

const BigTopicCard = ({ title, to, description }) => {
  return (
    <Pane
      backgroundColor="white"
      borderRadius={minorScale(3)}
      border={`1px solid ${theme.colors.gray400}`}
      padding={majorScale(4)}
      marginBottom={majorScale(3)}
      display="flex"
      alignItems="center"
      justify-content="space-between"
    >
      <Pane paddingY={24}>
        <Heading className="dm-sans" fontSize={28} lineHeight={1.1} marginBottom={4}>
          {title}
        </Heading>
        <Pane>
          <Text fontWeight={400} fontSize={16}>
            {description}
          </Text>
        </Pane>
        <Button appearance="primary" size="medium" marginTop={32} textDecoration="none" is={BrowserLink} to={to}>
          Learn More
        </Button>
      </Pane>
      <Hidden xs sm>
        <img
          src={topic}
          alt="Topic page"
          style={{
            maxHeight: "250px",
            marginLeft: "auto",
            marginRight: "24px"
          }}
        />
      </Hidden>
    </Pane>
  );
};

const GuideSection = () => {
  return (
    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <Pane display="flex" flexDirection="row" justifyContent="start" width="100%" marginBottom={majorScale(3)}>
            <Heading size={600}>Learn mentorship best practices</Heading>
          </Pane>
        </Col>
        <Col xs={12}>
          <BigTopicCard
            to={`/guide/mentor/0`}
            title={"What makes a good mentorship session?"}
            description={"How to prepare and position yourself for successful mentorship sessions."}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default GuideSection;
