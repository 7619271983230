import React from "react";
import injectSheet from "react-jss";
import Link from "../Link";
import theme from "../theme";

const styles = {
  button: {
    ...theme.button,
    textDecoration: "none",
    backgroundColor: theme.color.brand,
    color: theme.color.whiteText,
    textAlign: "center",
    border: `2px solid ${theme.color.brand}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 15,
    paddingLeft: 15,
    "&:hover": {
      color: theme.color.brand,
      border: "2px solid",
      borderColor: theme.color.brand,
      backgroundColor: "white",
      transition: "white .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"
    }
  },
  wide: {
    width: theme.spacing.grid * 3
  }
};

const Button = ({ children, classes, title, link, isExternal, type, disabled, isWide }) => {
  if (link) {
    return (
      <Link to={link} target={isExternal ? "_blank" : null} className={`${classes.button} ${isWide ? classes.wide : null}`}>
        {title ? title : children}
      </Link>
    );
  } else {
    return (
      <button
        type={type ? type : "button"}
        disabled={disabled ? disabled : undefined}
        className={`${classes.button} ${isWide ? classes.wide : null}`}
      >
        {title ? title : children}
      </button>
    );
  }
};

export default injectSheet(styles)(Button);
