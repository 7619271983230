import React from "react";
import _ from "lodash";
import { Pill, majorScale } from "evergreen-ui";

const topicColors = new Map([
  ["Career", "purple"],
  ["Skill", "teal"],
  ["Product", "red"],
  ["Manager", "blue"],
  ["Eng", "green"],
  ["Design", "neutral"]
]);

export const splitTopics = (topics) => {
  const types = _.filter(topics, (topic) => {
    return _.isEqual(topic.name, "Career") || _.isEqual(topic.name, "Skill");
  });
  const functions = _.filter(topics, (topic) => {
    return _.isEqual(topic.name, "Product") || _.isEqual(topic.name, "Design") || _.isEqual(topic.name, "Engineering");
  });
  return { types, functions };
};

export const createPill = (topic, key) => {
  return (
    <Pill color={topicColors.get(topic.name)} marginRight={majorScale(1)} key={key ? key : undefined}>
      {topic.name}
    </Pill>
  );
};
