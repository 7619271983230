import gql from "graphql-tag";

import QueryUtils from "../utils";
import FeedQueries from "../feed";
import ProfileList from "../profile/ProfileList";
import CoachList from "../../routes/coach/CoachList";

const TopicQueries = {
  fragments: {
    topic: `
      id,
      name,
      description,
      slug
    `
  }
};

TopicQueries.TopicBySlug = gql`
  query TopicBySlug($slug: String) {
    myProfile {
      ${ProfileList.fragments.profileLite},
      topics {
        id,
        slug,
        name
      }
    },
    topicBySlug(slug: $slug) {
      ${TopicQueries.fragments.topic}
    }
  }
`;

TopicQueries.AddRemoveTopic = gql`
  mutation AddRemoveTopic($topicId: String) {
    addRemoveTopic(topicId: $topicId) {
      ${ProfileList.fragments.profileLite},
      topics {
        ${TopicQueries.fragments.topic}
      }
    }
  }
`;

TopicQueries.MentorsByTopic = CoachList.MoreLeadersQuery;
TopicQueries.PostsByTopic = FeedQueries.queries.FeedQuery;

export default TopicQueries;
