import React from "react";
import ReactMarkdown from "react-markdown";
import { Row, Col, Container } from "react-grid-system";
import injectSheet from "react-jss";
import { Helmet } from "react-helmet";

import theme from "../../components/theme";

import content from "../../content/conduct";
import overview from "../../content/conduct-overview";

const styles = {
  h1: {
    ...theme.h1,
    textAlign: "center",
    padding: 0,
    margin: 0,
    paddingTop: theme.spacing.grid * 2.5
  },
  contact: {
    textAlign: "left",
    ...theme.p
  }
};

const policy = `

Merit users are busy professionals - everyone takes time out of their daily work, lives, and careers, to help each other. So we take no-show's seriously, as they're a drag on everyone's time. Here's our policy:

- You can reschedule a session two (2) times. On the third attempt, you have to cancel the session.
- Members who accrue three (3) No Show sessions within 90 days may not book more sessions for a period of 30 days.
- Mentors who accrue three (3) No Show sessions within 90 days will have their mentorship calendar deactivated for a period of 30 days.

`;

class BookingPolicy extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <link href={theme.fonts} rel="stylesheet" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Merit | Booking Policy</title>
        </Helmet>
        <Container>
          <Row align="center" justify="center">
            <Col md={10}>
              <div className={classes.h1}>Booking Policy</div>
            </Col>
          </Row>
          <Row align="center" justify="center">
            <Col md={8}>
              <ReactMarkdown className={classes.contact}>{policy}</ReactMarkdown>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default injectSheet(styles)(BookingPolicy);
