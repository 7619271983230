import _ from "lodash";
import React from "react";
import { Pane, Heading, Paragraph, Select as EvergreenSelect, majorScale, minorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";

const SUCCESS_TOAST = "Meeting length updated.";
const MEETING_INFO = `Update how long you want the mentorship meetings to last.`;

const OPTIONS = [15, 30, 45, 60];

const EditMeeting = ({ myCoachingProfile }) => {
  const currentMeetingLength = myCoachingProfile.meetingLength;
  const [updateMeetingLength] = useMutation(ProfileList.UpdateMeetingLength, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }],
    onCompleted: () => {
      window.analytics.track("Update Meeting Length");
      toaster.success(SUCCESS_TOAST);
    }
  });

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
      <Pane>
        <Heading marginBottom={minorScale(1)}>⏱ &nbsp; How long should meetings last?</Heading>
        <Pane display="flex">
          <Pane flex={1}>
            <Paragraph size={500}>{MEETING_INFO}</Paragraph>
          </Pane>
          <Pane>
            <EvergreenSelect
              onChange={(e) => {
                const meetingLength = parseInt(e.target.value);
                updateMeetingLength({ variables: { meetingLength } });
              }}
            >
              {OPTIONS.map((option) => (
                <option selected={_.isEqual(currentMeetingLength, option)}>{option}</option>
              ))}
            </EvergreenSelect>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default EditMeeting;
