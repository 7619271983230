import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  EditIcon,
  Pane,
  Heading,
  InlineAlert,
  majorScale,
  StarIcon,
  Text,
  ThumbsUpIcon,
  ThumbsDownIcon,
  UnorderedList,
  ListItem,
  defaultTheme as theme
} from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";

import CandidateQueries from "../../../queries/candidate";
import EditTeamPreferences from "../edit/candidate/EditTeamPreferences";

const CandidateTeamPreferences = ({ profile, isEditing, isRecruiter }) => {
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile, {
    variables: {
      profileId: profile?.id
    }
  });
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const [showTeamPreferences, setShowTeamPreferences] = useState(false);

  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const teamAttributeCategories = _.reduce(
    profileOptions?.availableTeamFeatureCategories,
    (titleMapping, category) => {
      titleMapping[category?.value] = category?.label;
      return titleMapping;
    },
    {}
  );

  const teamPreferences = {};

  if (myCandidateProfile) {
    ({
      mustHave: teamPreferences["mustHave"],
      niceToHave: teamPreferences["niceToHave"],
      doNotWant: teamPreferences["doNotWant"]
    } = myCandidateProfile);
  }

  const preferenceStyles = {
    mustHave: {
      color: theme.colors.green500,
      title: "Must have",
      icon: StarIcon
    },
    niceToHave: {
      color: theme.colors.blue500,
      title: "Nice to have",
      icon: ThumbsUpIcon
    },
    doNotWant: {
      color: theme.colors.red500,
      title: "Do not want",
      icon: ThumbsDownIcon
    }
  };

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass.includes("xs");

        return !isRecruiter && (!myCandidateProfile?.id || _.isEqual(myCandidateProfile?.status, "inactive")) ? null : (
          <Card
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            backgroundColor="white"
            padding={majorScale(2)}
            marginBottom={majorScale(3)}
            marginTop={majorScale(3)}
            minHeight="100px"
            id="job-search"
          >
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
              <Pane display="flex" flexDirection="row" alignItems="center">
                <Heading fontWeight={500}>
                  <span style={{ marginRight: "6px" }}>💼</span>
                  Team Preferences
                </Heading>
                <EditTeamPreferences
                  showTeamPreferences={showTeamPreferences}
                  setShowTeamPreferences={setShowTeamPreferences}
                  myCandidateProfile={myCandidateProfile}
                  myProfile={profile}
                />
                {isEditing && (
                  <Button appearance="minimal" onClick={() => setShowTeamPreferences(true)}>
                    <EditIcon />
                  </Button>
                )}
              </Pane>
              {!isRecruiter && <InlineAlert>Only visible our hiring partners</InlineAlert>}
            </Pane>

            {!_.isEmpty(teamPreferences) && (
              <Pane
                display="grid"
                gridTemplateColumns={`repeat(${_.keys(teamPreferences)?.length}, 1fr)`}
                columnGap={majorScale(1)}
                width="100%"
              >
                {_.map(teamPreferences, (attributes, preference) => {
                  const { icon: Icon, title, color } = preferenceStyles[preference];
                  const attributesByCategory = _.groupBy(attributes, "category");

                  return (
                    <Pane key={preference}>
                      <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={majorScale(1)}>
                        <Heading size={400} color={color}>
                          <Icon marginRight={majorScale(1)} />
                          {title}
                        </Heading>
                      </Pane>

                      {_.map(attributesByCategory, (attributes, category) => (
                        <Pane>
                          <Heading size={300} marginBottom={majorScale(1)}>
                            {teamAttributeCategories[category]}
                          </Heading>
                          {attributes?.length > 0 && (
                            <UnorderedList>
                              {attributes.map(({ attribute }, index) => (
                                <ListItem key={index}>
                                  <Text>{attribute}</Text>
                                </ListItem>
                              ))}
                            </UnorderedList>
                          )}
                        </Pane>
                      ))}
                    </Pane>
                  );
                })}
              </Pane>
            )}
          </Card>
        );
      }}
    />
  );
};

export default CandidateTeamPreferences;
