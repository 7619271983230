import _ from "lodash";
import React from "react";
import ShowMore from "@tedconf/react-show-more";
import { Button, Pane, majorScale } from "evergreen-ui";
import { useQuery } from "@apollo/client";

import FeedQueries from "../../queries/feed";
import ReplyContainer from "./ReplyContainer";

const ReplyIndex = ({ postId, commentId, myProfile }) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(FeedQueries.queries.Replies, {
    variables: { commentId }
  });

  if (!commentId) return null;

  const pageSize = 3;
  let replies, next;

  if (loading || error) return null;

  if (data) {
    replies = data.replies;

    next = () =>
      fetchMore({
        query: FeedQueries.queries.Replies,
        variables: {
          commentId,
          cursor: replies.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            replies: {
              __typename: fetchMoreResult.replies.__typename,
              pageInfo: fetchMoreResult.replies.pageInfo,
              edges: [...prev.replies.edges, ...fetchMoreResult.replies.edges]
            }
          });
        }
      });
  }

  return _.isNil(replies) || _.isEmpty(replies) ? null : (
    <Pane>
      <ShowMore items={replies.edges} by={pageSize}>
        {({ current, onMore }) => (
          <React.Fragment>
            <Pane>
              {replies.edges.map((reply) => (
                <ReplyContainer reply={reply} myProfile={myProfile} postId={postId} onDelete={refetch} />
              ))}
            </Pane>
            <Button display={replies.pageInfo.hasNextPage ? "default" : "none"} appearance="minimal" onClick={next}>
              More replies
            </Button>
          </React.Fragment>
        )}
      </ShowMore>
    </Pane>
  );
};

export default ReplyIndex;
