import React from "react";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";
// import LeaderIndexPage from "./LeaderIndexPage"

export default withRoutes(Layout, [
  // {
  //   path: "/leaders/all",
  //   component: LeaderIndexPage,
  //   state: "Leaders",
  // }
]);
