import React from "react";
import queryString from "query-string";
import { Container, Row, Col } from "react-grid-system";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Alert, Button, Heading, Pane, Text, majorScale } from "evergreen-ui";

import TextInput from "../../components/form/TextInput";

const CHANGE_EMAIL = gql`
  mutation changeEmail($currentEmail: String!, $newEmail: String!, $password: String!, $verifyToken: String!) {
    changeEmail(currentEmail: $currentEmail, newEmail: $newEmail, password: $password, verifyToken: $verifyToken)
  }
`;

const ChangeEmailSchema = Yup.object().shape({
  currentEmail: Yup.string().email("Please provide a valid email").required("Please include your current email"),
  newEmail: Yup.string().email("Please provide a valid email").required("Please include your current email"),
  password: Yup.string()
    .min(2, "Please include a password")
    .max(50, "Please shorten the password you use")
    .required("Please include a password")
});

const ChangeEmailPage = ({ history, message, location: { search } }) => {
  const { email, verifyToken, updated } = queryString.parse(search, { arrayFormat: "comma" });
  const [changeEmail, { loading, error }] = useMutation(CHANGE_EMAIL, {
    onCompleted: () => {
      history.push("/settings");
    }
  });

  return (
    <Formik
      validationSchema={ChangeEmailSchema}
      initialValues={{
        currentEmail: email ? email : "",
        newEmail: updated ? updated : "",
        password: ""
      }}
      onSubmit={(args, { setSubmitting }) => {
        changeEmail({ variables: { ...args, verifyToken } });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting }) => {
        return (
          <Form>
            <Container justify="center">
              <Row>
                <Col xs={10} sm={8} md={6} align="center" offset={{ xs: 1, sm: 2, md: 3 }}>
                  <Pane>
                    <Heading size={600} margin={majorScale(2)}>
                      Change Email
                    </Heading>
                    <Text>Please provide the new email address - we'll send you an email to that address to verify the change.</Text>
                  </Pane>
                  <Pane marginTop={majorScale(2)}>
                    <Field type="email" name="currentEmail" placeholder="Current Email" component={TextInput} />
                    <Field type="email" name="newEmail" placeholder="New Email" component={TextInput} />
                    <Field type="password" name="password" placeholder="Your password" component={TextInput} />
                  </Pane>
                  {errors && errors.length ? (
                    <Pane paddingTop={majorScale(1)} paddingBottom={majorScale(2)}>
                      {errors.map((error) => (
                        <Alert intent="danger" title={error} />
                      ))}
                    </Pane>
                  ) : null}
                  {error && error.graphQLErrors ? (
                    <Pane paddingTop={majorScale(1)} paddingBottom={majorScale(2)}>
                      {error.graphQLErrors.map(({ message }) => (
                        <Alert intent="danger" title={message} />
                      ))}
                    </Pane>
                  ) : null}
                  <Pane>
                    <Button type="submit" appearance="primary" disabled={isSubmitting} marginBottom={majorScale(2)}>
                      Change Email
                    </Button>
                  </Pane>
                  <Pane minHeight={"50vh"} minWidth={"100vw"}>
                    &nbsp;
                  </Pane>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(ChangeEmailPage);
