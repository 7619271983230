import _ from "lodash";
import React, { useState } from "react";
import {
  Button,
  Pane,
  Heading,
  Text,
  Switch,
  majorScale,
  minorScale,
  defaultTheme as theme,
  BookIcon,
  OfficeIcon,
  MapMarkerIcon
} from "evergreen-ui";

import {
  SearchBar,
  FilterSelect,
  SortSelect,
  formatLabel,
  FilterToggle
  // FILTER_TYPE
} from "../../routes/mentors/sections/FiltersRow";
import LocationAutocomplete from "../LocationAutocomplete";
import { INITIAL_FILTERS } from "../../routes/candidate/CandidateIndexPage";

export const FILTER_TYPE = {
  SEARCH: "name",
  TOPICS: "topics",
  ROLES: "roles",
  LEVELS: "levels",
  TIME: "availableSoon",
  INDUSTRY: "industry",
  COMPANY_SIZE: "companySize",
  COMPANY_STAGE: "companyStage",
  SKILLS: "skills",
  FRAMEWORKS: "frameworks",
  LOCATION: "location",
  SAVED: "saved",
  STATUS: "status",
  SORT: "sort",
  EXPERIENCE: "experience",
  REMOTE: "remote",
  RELOCATION: "relocation"
};

const FiltersHeading = ({ title, icon }) => {
  const Icon = icon;

  return (
    <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
      <Icon size={majorScale(2)} color={theme.colors.gray600} marginRight={majorScale(1)} />
      <Heading size={400}>{title}</Heading>
    </Pane>
  );
};

const CandidateFilters = ({
  selectedFilters = {},
  filterData = {},
  onFilterUpdate,
  onLocationUpdate,
  onFiltersReset,
  locationInputRef
}) => {
  const [locKey, setLocKey] = useState(0);
  const { availableJobRoles, availableYearsOfExperience, availableLevels } = filterData;

  return (
    <Pane display="flex" flexDirection="column" padding={majorScale(2)} width="100%">
      <Pane
        display="flex"
        flexDirection="column"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray500}`}
        borderRadius={majorScale(2)}
        padding={majorScale(2)}
        marginBottom={majorScale(2)}
        width="100%"
      >
        <Heading size={500} marginBottom={majorScale(2)}>
          Source talent
        </Heading>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(2)}>
          <FiltersHeading title="Expertise" icon={BookIcon} />
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Job role"
              type={FILTER_TYPE.ROLES}
              selected={selectedFilters?.roles}
              options={availableJobRoles?.map(formatLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter Candidates -> Job role"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Years of experience"
              type={FILTER_TYPE.EXPERIENCE}
              selected={selectedFilters?.experience}
              options={availableYearsOfExperience?.map(formatLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter Candidates -> Years of experience"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Skills"
              type={FILTER_TYPE.SKILLS}
              selected={selectedFilters?.skills}
              options={filterData?.skills}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter candidates -> skills"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Frameworks"
              type={FILTER_TYPE.FRAMEWORKS}
              selected={selectedFilters?.frameworks}
              options={filterData?.frameworks}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter candidates -> frameworks"
            />
          </Pane>
        </Pane>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(2)}>
          <FiltersHeading title="Company" icon={OfficeIcon} />
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Industry"
              type={FILTER_TYPE.INDUSTRY}
              selected={selectedFilters?.industry}
              options={filterData?.industry}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter candidates -> Industry"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Team size"
              type={FILTER_TYPE.COMPANY_SIZE}
              selected={selectedFilters?.companySize}
              options={filterData?.companySize}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter candidates -> Company size"
            />
          </Pane>
        </Pane>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(2)}>
          <FiltersHeading title="Location" icon={MapMarkerIcon} />
          <Pane marginBottom={majorScale(1)}>
            <LocationAutocomplete
              key={locKey}
              type={FILTER_TYPE.LOCATION}
              title="Current location"
              disableUserUpdate={true}
              onSelected={onLocationUpdate}
              locationInputRef={locationInputRef}
            />
          </Pane>
          <Pane marginBottom={minorScale(1)}>
            <FilterToggle
              type={FILTER_TYPE.REMOTE}
              title="Open to remote work"
              selected={selectedFilters?.remote}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter Candidates -> Open to remote work"
            />
          </Pane>
          <Pane marginBottom={minorScale(1)}>
            <FilterToggle
              type={FILTER_TYPE.RELOCATION}
              title="Open to relocation"
              selected={selectedFilters?.relocation}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter Candidates -> Open to relocation"
            />
          </Pane>
        </Pane>

        {!_.isEqual(selectedFilters, INITIAL_FILTERS) && (
          <Pane marginRight={8}>
            <Button
              width="100%"
              appearance="minimal"
              onClick={() => {
                setLocKey(locKey + 1);
                onFiltersReset();
              }}
              paddingX={majorScale(1)}
            >
              Clear filters
            </Button>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

export default CandidateFilters;
