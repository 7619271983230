import _ from "lodash";
import React from "react";
import { Pane, Heading, Paragraph, Select as EvergreenSelect, Switch, majorScale, minorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";

const EditApproveSession = ({ myCoachingProfile }) => {
  const [toggleApproveSession] = useMutation(ProfileList.ToggleApproveSession, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => window.analytics.track("Update Approve Session")
  });

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
      <Heading marginBottom={minorScale(1)}>✅ &nbsp; Do you want to approve every session?</Heading>
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Paragraph size={500}>Manually approve each session that mentees request with you.</Paragraph>
        <Switch
          marginLeft={majorScale(2)}
          checked={myCoachingProfile.approveSession}
          onChange={() => {
            toggleApproveSession();
            toaster.success("Approve sessions updated.", {
              duration: 2
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default EditApproveSession;
