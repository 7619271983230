import React, { forwardRef, memo } from "react";
import { ArrowLeftIcon, Button } from "evergreen-ui";

const BackButton = forwardRef(function BackButton({ children = "Back", ...props }, ref) {
  return (
    <Button iconBefore={ArrowLeftIcon} {...props} ref={ref}>
      {children}
    </Button>
  );
});

BackButton.propTypes = {
  /** Composes the Button component as the base. */
  ...Button.propTypes
};

export default BackButton;
