import gql from "graphql-tag";

export default gql`
  mutation WriteMessageMutation($text: String!, $threadID: String!) {
    sendMessageToThread(text: $text, threadID: $threadID) {
      id
      text
      createdAt
      user {
        id
        picture
        fullName
      }
    }
  }
`;
