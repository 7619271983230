import _ from "lodash";
import React, { useState } from "react";
import Moment from "moment";
import {
  Alert,
  Button,
  Heading,
  Pane,
  Text,
  Select as EvergreenSelect,
  SelectMenu,
  majorScale,
  toaster,
  Paragraph,
  Tablist,
  Card,
  Tab
} from "evergreen-ui";
import { isEmail } from "validator";
import { useQuery, useMutation } from "@apollo/client";

import ProfileList from "../../../queries/profile/ProfileList";
import OpeningHoursForm from "../../nylas/OpeningHoursForm";
import CalendarConflicts from "./CalendarConflicts";
import VideoLink from "../../nylas/VideoLink";

const FORM_CTA = "Finish setup";

const CONFLICTS_INFO = "Merit can check your existing calendar for conflicts.";
const MULTIPLE_CONFLICTS = "Merit will check the following accounts for conflicts.";

const TIMEZONE_HEADER = `🌎 What is your timezone?`;
const TIMEZONE_INFO = `Set the time zone for your calendar.`;
const TIMEZONE_PLACEHOLDER = `Pick your timezone`;
const TIMEZONE_TOAST = `Timezone updated.`;

const MAX_SESIONS_HEADER = "📊 &nbsp; How many sessions do you want each month?";
const SESSIONS_PER_MONTH_INFO = `Merit will prevent you from receiving more session than your availability.`;

const OPENING_HOURS_HEADER = "⏰ When do you want to receive sessions?";
const OPENING_HOURS_INFO = "These are the times you be available to receive sessions.";

const FORM_WARNING = "Please check for any missing fields above.";
const VIDEO_WARNING = "Please provide a valid video conferencing link.";

const NOT_CONNECTED = "None";

const CONNECT_CALENDAR_BUTTON = "Connect calendar";
const USE_DIFFERENT_CALENDAR_BUTTON = "Change account";

let windowObjectReference = null;
let previousURL = null;

const FIND_VIDEO_LINK_INFO = [
  "This link will appear in meeting invitations so that members can join the session. Otherwise, we'll automatically generate a Zoom meeting link for each mentorship session that you can access in the invitation or in the Merit app."
];

const ZOOM = "Zoom";
const MEET = "Meet";

const mappings = [
  {
    title: ZOOM,
    pane: (
      <Pane>
        <VideoLink />
      </Pane>
    )
  },
  {
    title: MEET,
    pane: (
      <Pane>
        <Text>
          Make sure that Google Meet links are{" "}
          <a
            href="https://support.google.com/calendar/answer/9896448#zippy=%2Ccant-find-the-checkbox-automatically-add-google-meet-video"
            target="_blank"
          >
            automatically generated when a new event is created
          </a>
        </Text>
      </Pane>
    )
  }
];

// Receve a message from the pop up when the calendar connection is finished
const buildReceiveMessage = (refetchNylasInfo) => {
  const fn = (e) => {
    if (e && e.data && !_.isNil(e.data.nylasConnected)) {
      refetchNylasInfo();
    }
  };

  return fn;
};

const openSignInWindow = (url, name, receiveMessage) => {
  // remove any existing event listeners
  window.removeEventListener("message", receiveMessage);

  // window features
  const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    // If the pointer to the window object in memory does not exist
    // or if such pointer exists but the window was closed

    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousURL !== url) {
    // If the resouce to load is different, then we load it in the already
    // opened secondary window and then we bring such window back on top/in
    // front of its parent window
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    // The window reference must exist and the window is not closed; therefore
    // we can bring it back on top of any other window with the focus() method.
    // There would be no need to recreated the window or to reload the referenced resource
    windowObjectReference.focus();
  }

  // Add the listener for receiving a message from the popup
  window.addEventListener("message", (event) => receiveMessage(event), false);

  // Assign the previous URL
  previousURL = url;
};

const renderCheckForConflicts = ({ myProfile, nylasInfo, refetchNylasInfo }) => {
  let nylasAccount = nylasInfo && nylasInfo.nylasAccount && isEmail(nylasInfo.nylasAccount) ? nylasInfo.nylasAccount : NOT_CONNECTED;

  const nylasAuthLink = `/profile/calendar/edit`;
  const receiveMessage = buildReceiveMessage(refetchNylasInfo);

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(2)} paddingTop={majorScale(2)}>
      <Heading>🏁 &nbsp; Do you want to check for conflicts?</Heading>
      <Pane paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
        <Text>{CONFLICTS_INFO}</Text>
      </Pane>
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Text marginBottom={majorScale(1)} marginTop={majorScale(1)}>
          {`Connected to: ${nylasAccount}`}
        </Text>
        <Button appearance="primary" onClick={() => openSignInWindow(nylasAuthLink, "Connect Merit Calendar", receiveMessage)}>
          {_.isEqual(NOT_CONNECTED, nylasAccount) ? CONNECT_CALENDAR_BUTTON : USE_DIFFERENT_CALENDAR_BUTTON}
        </Button>
      </Pane>
    </Pane>
  );
};

const renderTimeZone = ({ myProfile, updateTimeZone }) => {
  const timezones = Moment.tz.names();
  const title = myProfile.timezone ? myProfile.timezone : "Select your timezone";
  const options = timezones.map((name) => ({ value: name, label: name }));
  const selected = myProfile.timezone ? myProfile.timezone : "America/New_York";

  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom={majorScale(2)}
      paddingTop={majorScale(2)}
    >
      <Heading>🌎 &nbsp; What is your timezone?</Heading>
      <SelectMenu
        title={title}
        options={options}
        selected={selected}
        onSelect={(item) => {
          window.analytics.track("Update Timezone");

          updateTimeZone({
            variables: {
              timezone: item.value
            }
          });
        }}
      >
        <Button marginLeft={majorScale(1)}>{title}</Button>
      </SelectMenu>
    </Pane>
  );
};

const renderCapacity = ({ myCoachingProfile, updateMaxSessions }) => {
  const currentMaxSessions = myCoachingProfile.maxSessions;

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(2)} paddingTop={majorScale(2)}>
      <Heading marginBottom={majorScale(1)}>📊 &nbsp; How many sessions do you want each month?</Heading>
      <Pane display="flex">
        <Pane flex={1}>
          <Text>{SESSIONS_PER_MONTH_INFO}</Text>
        </Pane>
        <Pane>
          <EvergreenSelect
            onChange={(e) => {
              const maxSessions = e.target.value;
              updateMaxSessions({ variables: { maxSessions } });
              toaster.success("Max sessions updated.");
            }}
          >
            {_.map(_.range(1, 11), (option) => (
              <option key={`option-${option}`} selected={_.isEqual(option, currentMaxSessions)}>
                {option}
              </option>
            ))}
          </EvergreenSelect>
        </Pane>
      </Pane>
    </Pane>
  );
};

const renderOpeningHours = ({ myCoachingProfile }) => {
  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(2)} paddingTop={majorScale(2)}>
      <Heading marginBottom={majorScale(1)}>⏰ &nbsp; When do you want to have sessions?</Heading>
      <Text>{OPENING_HOURS_INFO}</Text>
      <Pane paddingTop={majorScale(2)}>
        <OpeningHoursForm myCoachingProfile={myCoachingProfile} />
      </Pane>
    </Pane>
  );
};

const renderVideoLink = ({ tabs, selectedIndex, setSelectedIndex, videoLink }) => {
  return (
    <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
      <Pane>
        <Heading marginBottom={majorScale(1)}>🎥 &nbsp; How should mentees video call you?</Heading>
        {FIND_VIDEO_LINK_INFO.map((t) => (
          <Paragraph key={`info-${t}`} paddingBottom={majorScale(2)}>
            {t}
          </Paragraph>
        ))}
        <VideoLink />
        {missingVideoLink(videoLink) && (
          <Pane display="flex" justifyContent="flex-end" width="100%" paddingTop={majorScale(1)}>
            <Alert width="100%" intent="warning" title={VIDEO_WARNING} />
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

const missingVideoLink = (result) => {
  if (result) {
    const { videoLink } = result;

    return _.isEmpty(videoLink) || _.isEqual(videoLink, "meet.google.com");
  }
};

const ConnectCalendar = (props) => {
  const applying = props?.applying;

  const [tabs, setTabs] = useState([ZOOM, MEET]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data: nylasInfo, loading, error, refetch: refetchNylasInfo } = useQuery(ProfileList.NylasType);

  const [updateTimeZone] = useMutation(ProfileList.UpdateTimezone, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => toaster.success(TIMEZONE_TOAST, { duration: 2 })
  });

  const [updateMaxSessions] = useMutation(ProfileList.UpdateMaxSessions, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }]
  });

  const { data: videoLink } = useQuery(ProfileList.VideoLinkQuery);

  const {
    myProfile,
    myCoachingProfile,
    location: { search },
    handleNextStep
  } = props;

  return (
    <Pane display="flex" flexDirection="column" paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
      {applying ? (
        <>
          {myProfile && renderVideoLink({ tabs, selectedIndex, setSelectedIndex, videoLink })}
          {myCoachingProfile && renderCapacity({ myCoachingProfile, updateMaxSessions })}
        </>
      ) : (
        <>
          {myProfile && renderVideoLink({ tabs, selectedIndex, setSelectedIndex, videoLink })}
          {myCoachingProfile && renderOpeningHours({ myCoachingProfile })}
          {myCoachingProfile && renderCapacity({ myCoachingProfile, updateMaxSessions })}
          {myProfile && renderTimeZone({ myProfile, updateTimeZone })}
          {renderCheckForConflicts({ myProfile, nylasInfo, refetchNylasInfo })}
          <CalendarConflicts nylasInfo={nylasInfo} />
          {missingVideoLink(videoLink) && (
            <Pane display="flex" justifyContent="flex-end" width="100%" paddingTop={majorScale(1)}>
              <Alert width="100%" intent="warning" title={FORM_WARNING} />
            </Pane>
          )}
        </>
      )}
      <Pane display="flex" justifyContent="flex-end" width="100%" paddingTop={majorScale(2)}>
        <Button
          appearance="primary"
          disabled={missingVideoLink(videoLink)}
          onClick={(myCoachingProfile) => handleNextStep(myCoachingProfile)}
        >
          {FORM_CTA}
        </Button>
      </Pane>
    </Pane>
  );
};

export default ConnectCalendar;
