import _ from "lodash";
import React from "react";
import { Alert, Button, Dialog, Heading, Pane, Paragraph, minorScale, majorScale } from "evergreen-ui";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";

import VideoLink from "../../../components/nylas/VideoLink";
import ProfileList from "../../../queries/profile/ProfileList";

const PAGE_TITLE = "Add Zoom Link";

const FIND_VIDEO_LINK_INFO = [
  "This link will appear in meeting invitations so that members can join the session. Otherwise, we'll automatically generate a Zoom meeting link for each mentorship session that you can access in the invitation or in the Merit app."
];

const VIDEO_WARNING = "Please provide a valid video conferencing link.";

const missingVideoLink = (result) => {
  if (result) {
    const { videoLink } = result;

    return _.isEmpty(videoLink) || _.isEqual(videoLink, "meet.google.com");
  }
};

const SetVideoLink = () => {
  const { data: videoLink } = useQuery(ProfileList.VideoLinkQuery);

  return (
    <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
      <Pane>
        <Heading marginBottom={majorScale(1)}>🎥 &nbsp; How should mentees video call you?</Heading>
        {FIND_VIDEO_LINK_INFO.map((t) => (
          <Paragraph key={`info-${t}`} marginBottom={minorScale(3)}>
            {t}
          </Paragraph>
        ))}
        <VideoLink />
        {missingVideoLink(videoLink) && (
          <Pane display="flex" justifyContent="flex-end" width="100%">
            <Alert width="100%" intent="warning" title={VIDEO_WARNING} />
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

const ZoomSetup = ({ history, showEditor, setShowEditor }) => {
  return (
    <Dialog
      isShown={showEditor}
      onCloseComplete={() => setShowEditor(false)}
      confirmLabel="Activate Profile"
      cancelLabel="Continue Editing"
      hasClose={false}
      preventBodyScrolling={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      header={
        <Pane display="flex" justifyContent="space-between" alignItems="start" width="100%" flexDirection="column">
          <Pane>
            <Heading is="h4" size={600}>
              {PAGE_TITLE}
            </Heading>
          </Pane>
        </Pane>
      }
      footer={
        <Pane display="flex" justifyContent="space-between" alignItems="start" width="100%" minWidth="100%" flexDirection="row">
          <Button
            appearance="primary"
            onClick={() => {
              setShowEditor(false);
              history.push(history?.location?.pathname);
            }}
          >
            Finish
          </Button>
        </Pane>
      }
    >
      <SetVideoLink />
    </Dialog>
  );
};

export default withRouter(ZoomSetup);
