import React from "react";
import injectSheet from "react-jss";
import { Container, Row, Col, Hidden, Visible } from "react-grid-system";

import theme from "../theme";

const styles = {
  content: {
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "2rem"
  },
  root: {
    padding: "3rem 3rem 3rem 3rem",
    width: "100%",
    height: "100%"
  },
  headline: {
    textAlign: "center",
    letterSpacing: "1.05px",
    margin: 0,
    ...theme.h1
  },
  mobileHeadline: {
    ...theme.h2
  },
  heroContainer: {
    textAlign: "center"
  },
  heroShot: {
    width: "65%",
    margin: "auto",
    border: "2px solid #212529"
  },
  subtitle: {
    textAlign: "center",
    ...theme.content(24)
  },
  mobileSubtitle: {
    ...theme.content(18)
  }
};

const Hero = ({ classes, root, headline, subtitle, children, heroShot }) => {
  return (
    <Container className={classes.root}>
      <Row>
        <Col xs={12}>
          <Hidden xs>
            <h1 className={classes.headline}>{headline}</h1>
          </Hidden>
          <Visible xs>
            <h1 className={`${classes.headline} ${classes.mobileHeadline}`}>{headline}</h1>
          </Visible>
        </Col>
        <Col xs={12}>
          <Hidden xs>
            <h3 className={classes.subtitle}>{subtitle}</h3>
          </Hidden>
          <Visible xs>
            <h3 className={`${classes.subtitle} ${classes.mobileSubtitle}`}>{subtitle}</h3>
          </Visible>
        </Col>
        <Col xs={12} className={classes.content}>
          {children}
        </Col>
        {heroShot ? (
          <Col xs={12} md={10} offset={{ md: 1 }}>
            <div className={classes.heroContainer}>
              <img className={classes.heroShot} src={heroShot} />
            </div>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default injectSheet(styles)(Hero);
