import React from "react";
import { Row, Col, Hidden, Container, Visible } from "react-grid-system";
import { Helmet } from "react-helmet";
import injectSheet from "react-jss";
import ReactGA from "react-ga";
import _ from "lodash";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Bio from "../../components/team/Bio";
import theme from "../../components/theme";
import Button from "../../components/button/Button";

import {
  founderStory,
  team,
  problem,
  contributorsContext,
  contributors,
  rightStarURL,
  teamPhotoURL,
  keyContributors,
  teamPhotoMobile,
  trophy,
  contact,
  problemReference,
  twoBeers,
  twoBeersName,
  twoBeersLink
} from "../../content/about";

import { Pane } from "evergreen-ui";

const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

const SessionCount = gql`
  query SessionQuery {
    hoursCoached
    learnersOnboarded
    coachesLive
  }
`;

const styles = {
  p: {
    ...theme.p,
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid,
    textAlign: "center"
  },
  noLink: {
    textDecoration: "inherit",
    color: "inherit"
  },
  podcast: {
    ...theme.s,
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid,
    textAlign: "left"
  },
  leftAlign: {
    textAlign: "left"
  },
  h2: {
    ...theme.h2,
    margin: 0,
    padding: 0,
    color: "inherit",
    textDecoration: "inherit",
    textAlign: "center"
  },
  h2Mobile: {
    ...theme.h2Mobile,
    margin: 0,
    padding: 0,
    color: "inherit",
    textDecoration: "inherit",
    textAlign: "center"
  },
  h3: {
    ...theme.h3,
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing.grid * 0.5
  },
  row: {
    paddingTop: theme.spacing.grid * 2,
    paddingBottom: theme.spacing.grid * 2,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  },
  founders: {
    paddingTop: theme.spacing.grid * 3,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  },
  overview: {
    paddingBottom: theme.spacing.grid * 4,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  },
  decoration: {
    height: 75,
    width: 75,
    padding: theme.spacing.padding
  },
  teamPhoto: {
    maxWidth: "100%",
    paddingTop: theme.spacing.grid * 2,
    marginBottom: theme.spacing.grid * 2
  },
  teamPhotoMobile: {
    paddingBottom: theme.spacing.grid
  },
  bios: {
    paddingBottom: theme.spacing.grid * 6,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  },
  contributors: {
    paddingTop: theme.spacing.grid * 4,
    paddingBottom: theme.spacing.grid * 2,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  },
  contributorsHeader: {
    paddingTop: theme.spacing.grid * 4,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  },
  fullWidth: {
    marginRight: -15,
    marginLeft: -15
  },
  darkBackground: {
    background: theme.color.darkBackground
  }
};

const presentStat = (stat) => {
  let cleanedStat = stat;
  cleanedStat = Math.ceil(cleanedStat / 10) * 10;
  cleanedStat = "" + cleanedStat + "+";
  return cleanedStat;
};

const AboutPage = ({ classes }) => {
  const { data } = useQuery(SessionCount);
  window.analytics.track("Landing Page -> About");

  return (
    <div>
      <link href={theme.fonts} rel="stylesheet" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Story | Merit</title>
      </Helmet>
      <div className={classes.fullWidth}>
        <Container>
          <Row align="center" justify="center" className={classes.row}>
            <Col md={10}>
              <Hidden xs s>
                <h1 style={{ margin: 0 }}>
                  <a className={classes.h2}>{problem}</a>
                </h1>
              </Hidden>
              <Visible xs s>
                <h1 style={{ margin: 0 }}>
                  <a className={classes.h2Mobile}>{problem}</a>
                </h1>
              </Visible>
            </Col>
          </Row>
          <Row align="center" justify="center" className={classes.overview}>
            <Col sm={12} md={10} lg={8}>
              {founderStory.map((story) => (
                <div className={`${classes.p} ${classes.leftAlign}`}>{story}</div>
              ))}
            </Col>
          </Row>
          {data?.hoursCoached && (
            <Row align="center" justify="center" className={classes.overview}>
              <Col sm={4} md={3} lg={2}>
                <div className={`${classes.p}`}>Members</div>
                <div className={`${classes.h2}`}>{presentStat(data?.learnersOnboarded)}</div>
              </Col>
              <Col sm={4} md={3} lg={2}>
                <div className={`${classes.p}`}>Mentors</div>
                <div className={`${classes.h2}`}>{presentStat(data?.coachesLive)}</div>
              </Col>
              <Col sm={4} md={3} lg={2}>
                <div className={`${classes.p}`}>Conversations</div>
                <div className={`${classes.h2}`}>{presentStat(data?.hoursCoached)}</div>
              </Col>
            </Row>
          )}
          <Row align="center" justify="center">
            <Col md={6}>
              <Pane
                padding={theme.spacing.grid}
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingBottom={theme.spacing.grid * 4}
              >
                <Button isExternal title={contact} link={"mailto:team@get-merit.com"} />
              </Pane>
            </Col>
          </Row>
        </Container>
        <div className={classes.darkBackground}>
          <Container>
            <Row align="start" justify="center" className={classes.founders}>
              <Col md={12} lg={10}>
                <div className={classes.h2}>Made by tech workers</div>
                <Hidden xs s>
                  <img className={classes.teamPhoto} src={teamPhotoURL} />
                </Hidden>
                <Visible xs s>
                  <img className={`${classes.teamPhoto} ${classes.teamPhotoMobile}`} src={teamPhotoMobile} />
                </Visible>
              </Col>
            </Row>
            <Row align="start" justify="center" className={classes.bios}>
              <Hidden xs s>
                <Col md={5} lg={4}>
                  <Bio person={team[0]} />
                </Col>
                <Col md={5} lg={4} offset={{ md: 1 }}>
                  <Bio person={team[1]} />
                </Col>
              </Hidden>
              <Visible xs s>
                <Col md={5} lg={4} style={{ marginBottom: theme.spacing.grid }}>
                  <Bio person={team[0]} />
                </Col>
                <Col md={5} lg={4} offset={{ md: 1 }}>
                  <Bio person={team[1]} />
                </Col>
              </Visible>
            </Row>
          </Container>
        </div>
        <Container>
          <Row align="center" justify="center" className={classes.contributorsHeader}>
            <Col md={1}>
              <Hidden xs s>
                <img className={classes.decoration} src={trophy} />
              </Hidden>
            </Col>
            <Col md={6}>
              <div className={classes.h2}>Funded by tech workers</div>
              <div className={classes.p}>{contributorsContext}</div>
            </Col>
            <Col md={1}>
              <Hidden xs s>
                <img className={classes.decoration} src={rightStarURL} />
              </Hidden>
            </Col>
          </Row>
          <Row align="center" justify="center" className={classes.contributors}>
            <Col md={10}>
              <div>
                {keyContributors.map((contributor) => (
                  <div className={classes.h3}>{contributor}</div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default injectSheet(styles)(AboutPage);
