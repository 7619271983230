import _ from "lodash";
import React from "react";
import {
  Avatar,
  Button,
  CalendarIcon,
  CrossIcon,
  Pane,
  Heading,
  Paragraph,
  PersonIcon,
  Text,
  majorScale,
  minorScale,
  toaster,
  defaultTheme as theme
} from "evergreen-ui";
import { formatDistanceToNow, isValid } from "date-fns";
import { useMutation } from "@apollo/client";
import { Link as BrowserLink } from "react-router-dom";

import HoverLink from "../../components/HoverLink";
import SessionQueries from "../../queries/session";

const otherUser = ({ owner, user, myProfile }) => {
  return _.isEqual(myProfile?.id, user?.id) ? owner : user;
};

const formatDate = (createdAt) => {
  // https://date-fns.org/v2.27.0/docs/isValid
  createdAt = isValid(new Date(createdAt)) ? createdAt : Number(createdAt);

  // https://date-fns.org/v2.27.0/docs/formatDistanceToNow
  return `${formatDistanceToNow(new Date(createdAt))} ago`;
};

const ProfileOverview = ({ myProfile, offer }) => {
  const profile = otherUser({ ...offer, myProfile });
  const title = profile?.role?.team?.name;
  let role = `${profile?.role?.title}`;
  role = title ? `${role} at ${title}` : role;

  const size = minorScale(15);

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={majorScale(3)}>
      <HoverLink to={`/p/${profile?.slug}`} samePage>
        <Pane display="flex">
          <Pane display="flex" flexDirection="row" marginRight={majorScale(2)}>
            <Avatar src={profile?.picture} name={profile?.fullName} size={size} />
          </Pane>
          <Pane display="flex" flexDirection="column" justifyContent="center" height={size}>
            <Heading>
              {profile?.fullName}, <Text>{role}</Text>
            </Heading>
            <Text>
              {`Offered a mentorship session to help with your career question`}
              <b>{` ${offer?.post?.title}`}</b>
            </Text>
          </Pane>
        </Pane>
      </HoverLink>
      <Button is={BrowserLink} to={`/p/${profile?.slug}`} appearance="minimal" iconBefore={PersonIcon}>
        View Profile
      </Button>
    </Pane>
  );
};

const OfferCard = ({ myProfile, offer, onCompleted }) => {
  const [respond, { loading }] = useMutation(SessionQueries.mutations.OfferResponse, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: SessionQueries.queries.MySessionOffers }],
    onCompleted: () => {
      toaster.success("Responded to offer.");
    }
  });

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      borderRadius={majorScale(2)}
      marginBottom={majorScale(4)}
      padding={majorScale(4)}
      border={`1px solid ${theme.colors.gray500}`}
      backgroundColor="white"
    >
      <ProfileOverview offer={offer} myProfile={myProfile} />
      <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(3)}>
        <Heading>{`How can ${offer?.owner.name.first} help?`}</Heading>
        <Paragraph size={500} marginBottom={majorScale(2)}>
          {offer?.message}
        </Paragraph>
        <Heading size={400}>{`Sent ${formatDate(offer?.createdAt)}`}</Heading>
      </Pane>
      <Pane display="flex" flexDirection="row" width="100%" justifyContent="space-between">
        <Button
          iconBefore={CrossIcon}
          onClick={() =>
            respond({
              variables: {
                id: offer?.id,
                status: "Completed"
              }
            })
          }
        >
          Dismiss
        </Button>
        <Button appearance="primary" iconBefore={CalendarIcon} is={BrowserLink} to={`/request/${offer?.owner?.id}`}>
          Book Session
        </Button>
      </Pane>
    </Pane>
  );
};

export default OfferCard;
