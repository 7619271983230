import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  InlineAlert,
  Badge,
  Button,
  CaretDownIcon,
  CaretLeftIcon,
  CalendarIcon,
  EnvelopeIcon,
  Heading,
  Pane,
  Position,
  SelectMenu,
  Spinner,
  minorScale,
  majorScale,
  defaultTheme as theme,
  toaster,
  Alert
} from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { fromUnixTime, format } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { Formik, Field, Form } from "formik";
import { ScreenClassRender } from "react-grid-system";
import * as Yup from "yup";

import SessionQueries from "../../../queries/session";
import TextArea from "../../../components/form/TextArea";
import SignupDialog from "../../../components/SignupDialog";
import ChooseTime from "./ChooseTime";
import { HumanityCheckForm, areWeHuman } from "../../session/HumanityCheck";

const PLACEHOLDER =
  "How do I navigate my first job search remotely? I find networking to be difficult and awkward—and doing it remotely (slack groups, remote conferences, etc.) are the worst.";

const convertDate = (time) => {
  if (!_.isNaN(Number(time))) {
    return fromUnixTime(Number(time) / 1000);
  } else return time;
};

const formatSessionDate = (date, timeZone) => {
  date = convertDate(date);
  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eee LLLL do");
  const time = formatInTimeZone(zonedDate, timeZone, "hh:mm b (z)");

  return `${day} at ${time}`;
};

const BookSession = (bookingContext) => {
  const { coachByProfileId, profile, history, myProfile, isMobile, inline, isPreview } = bookingContext;

  const [timeSlot, setTimeSlot] = useState(null);
  const [timezone, setTimeZone] = useState(null);

  const [createSession, { loading }] = useMutation(SessionQueries.mutations.CreateSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "upcoming" }
      },
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "previous" }
      }
    ],
    onCompleted: ({ createSession }) => {
      toaster.success("Session booked.");
      history.push(`/session/${createSession?.id}/prepare`);
    }
  });

  const { data: existingRequest, loading: requestLoading } = useQuery(SessionQueries.queries.CurrentSessionRequest, {
    variables: {
      profileId: profile?.id
    }
  });

  const [requestSession, { loading: sendingRequest }] = useMutation(SessionQueries.mutations.RequestSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.MySessionRequests
      },
      {
        query: SessionQueries.queries.CurrentSessionRequest,
        variables: { profileId: profile?.id }
      }
    ],
    onCompleted: ({ requestSession }) => {
      toaster.success("Session request submitted.");
    }
  });

  useEffect(() => {
    if (myProfile?.id && _.isNull(timezone)) {
      setTimeZone(myProfile?.timezone ? myProfile.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone);
    }

    if (myProfile?.timezone !== timezone) {
      setTimeZone(myProfile?.timezone);
    }
  }, [myProfile, timezone]);

  const isPendingRequest = existingRequest?.sessionRequest?.status === "Pending";
  const title = coachByProfileId?.approveSession && !isPendingRequest ? "Request session" : "Book session";

  // if (myProfile?.id && _.isNull(timezone)) {
  //   setTimeZone(
  //     myProfile?.timezone ? myProfile.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
  //   )
  // }

  const schema = coachByProfileId?.openEnded
    ? Yup.object().shape({
        description: Yup.string()
          .min(100, "Please include more context about this session.")
          .max(10000000, "Please shorten your session")
          .required("Please include more context about this session.")
      })
    : Yup.object().shape({
        description: Yup.string()
          .min(100, "Please include more context about this session.")
          .max(10000000, "Please shorten your session")
          .required("Please include more context about this session."),
        topic: Yup.object().shape({
          value: Yup.string().min(1, "Please choose a topic").required("Please choose a topic"),
          label: Yup.string().min(1, "Please choose a topic").required("Please choose a topic")
        })
      });

  return (
    <Pane display="flex" width="100%">
      {requestLoading && !existingRequest?.sessionRequest?.id && (
        <Pane
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          backgroundColor="white"
          border={inline ? undefined : `1px solid ${theme.colors.gray500}`}
          borderRadius={inline ? undefined : majorScale(2)}
          padding={majorScale(3)}
          marginBottom={inline ? majorScale(2) : undefined}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Heading marginBottom={majorScale(1)}>Loading availability</Heading>
          <Spinner />
        </Pane>
      )}
      {existingRequest?.sessionRequest?.id && timezone && (
        <>
          {_.isEqual(existingRequest?.sessionRequest?.status, "Pending") ? (
            <Pane display="flex" flexDirection="column">
              <Alert
                title="Please choose a time to book your session."
                intent="info"
                marginTop={majorScale(1)}
                marginBottom={majorScale(2)}
              >
                {`Your session’s been accepted, you just need to select a different time. ${existingRequest?.sessionRequest?.response?.length > 0 ? `They added, "${existingRequest?.sessionRequest?.response}"` : ``}`}
              </Alert>
              <Pane
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                backgroundColor="white"
                border={inline ? undefined : `1px solid ${theme.colors.gray500}`}
                borderRadius={inline ? undefined : majorScale(2)}
                padding={majorScale(3)}
                marginBottom={inline ? majorScale(2) : undefined}
                width="100%"
              >
                <ChooseTime
                  title={title}
                  myProfile={profile}
                  mentorId={coachByProfileId?.id}
                  meetingLength={coachByProfileId?.meetingLength}
                  onChooseTz={(tz) => setTimeZone(tz)}
                  onSelect={(timeSlot) =>
                    createSession({
                      variables: {
                        mentorId: coachByProfileId?.id,
                        startTime: timeSlot?.start,
                        description: existingRequest?.sessionRequest?.message,
                        timeZone: timezone
                      }
                    })
                  }
                />
              </Pane>
            </Pane>
          ) : (
            <InlineAlert>
              {`You have requested a session for ${formatSessionDate(existingRequest?.sessionRequest?.date, timezone)}. If confirmed, you’ll receive a calendar invitation.`}
            </InlineAlert>
          )}
        </>
      )}
      {!requestLoading && !existingRequest?.sessionRequest?.id && (
        <Pane
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          backgroundColor="white"
          border={inline ? undefined : `1px solid ${theme.colors.gray500}`}
          borderRadius={inline ? undefined : majorScale(2)}
          padding={majorScale(3)}
          marginBottom={inline ? majorScale(2) : undefined}
          width="100%"
        >
          {_.isNull(timeSlot) ? (
            <ChooseTime
              title={title}
              myProfile={profile}
              mentorId={coachByProfileId?.id}
              meetingLength={coachByProfileId?.meetingLength}
              onSelect={(timeSlot) => setTimeSlot(timeSlot)}
              onChooseTz={(tz) => setTimeZone(tz)}
            />
          ) : (
            <>
              {!myProfile?.id ? (
                <SignupDialog
                  isShown={!_.isNull(timeSlot) && !myProfile?.id}
                  title="New to Merit?"
                  subtitle="Sign up to talk to mentors in engineering, product, and design."
                  mode="Learn"
                  cta="Create account"
                  redirect={`/p/${profile?.slug}`}
                />
              ) : (
                <>
                  {areWeHuman(myProfile) ? (
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        topic: { value: "", label: "" },
                        description: ""
                      }}
                      onSubmit={({ topic, description }, { setSubmitting }) => {
                        if (isPreview) {
                          toaster.success("This is just a test!", { duration: 2 });
                        } else {
                          if (coachByProfileId?.approveSession) {
                            requestSession({
                              variables: {
                                profileId: coachByProfileId?.user?.id,
                                message: description,
                                date: timeSlot?.start,
                                topicId: topic?.value?.length > 0 ? topic?.value : undefined
                              }
                            });
                          } else {
                            createSession({
                              variables: {
                                mentorId: coachByProfileId?.id,
                                startTime: timeSlot?.start,
                                topicId: topic?.value?.length > 0 ? topic?.value : undefined,
                                description,
                                timeZone: timezone
                              }
                            });
                          }
                        }

                        setSubmitting(false);
                      }}
                      render={({ setFieldValue, isSubmitting, errors, touched, values: { topic, description } }) => (
                        <Form style={isMobile ? { width: "100%" } : undefined}>
                          <Pane display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" width="100%">
                            <Heading marginBottom={isMobile ? majorScale(1) : null}>
                              {`What are you looking to discuss?${coachByProfileId?.openEnded ? "" : "*"}`}
                            </Heading>
                            <SelectMenu
                              title="Sort"
                              options={coachByProfileId?.playlists.map((t) => ({ value: t.id, label: t.name }))}
                              selected={topic}
                              position={Position.BOTTOM_RIGHT}
                              onSelect={(choice) => setFieldValue("topic", choice, false)}
                            >
                              <Button type="button" paddingLeft={minorScale(3)} paddingRight={majorScale(1)}>
                                {topic?.label ? topic.label : "Select a topic"}
                                <CaretDownIcon marginLeft={minorScale(1)} color="muted" />
                              </Button>
                            </SelectMenu>
                          </Pane>
                          {touched["topic"] && errors["topic"] && (
                            <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                              {errors["topic"]?.value}
                            </Badge>
                          )}
                          <Pane
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            width="100%"
                            marginTop={majorScale(2)}
                            marginBottom={majorScale(2)}
                          >
                            <Heading marginBottom={majorScale(1)}>Please share anything that will help in advance of our meeting*</Heading>
                            <Field type="text" name="description" placeholder={PLACEHOLDER} component={TextArea} />
                          </Pane>
                          <Pane display="flex" flexDirection="row" justifyContent="space-between">
                            <Button iconBefore={CaretLeftIcon} appearance="minimal" onClick={() => setTimeSlot(null)}>
                              {formatInTimeZone(timeSlot?.start, timezone, "EEE MMM dd hh:mm (z)")}
                            </Button>
                            <Button
                              type="submit"
                              appearance="primary"
                              isLoading={loading}
                              iconBefore={coachByProfileId.approveSession ? EnvelopeIcon : CalendarIcon}
                              disabled={
                                isSubmitting ||
                                sendingRequest ||
                                (!coachByProfileId?.openEnded && !topic?.value) ||
                                description?.length < 100
                              }
                            >
                              {title}
                            </Button>
                          </Pane>
                        </Form>
                      )}
                    />
                  ) : (
                    <Pane width="100%">
                      <HumanityCheckForm profile={profile} myProfile={myProfile} onCompleted={() => {}} />
                    </Pane>
                  )}
                </>
              )}
            </>
          )}
        </Pane>
      )}
    </Pane>
  );
};

const BookSessionContainer = (bookSessionContext) => {
  return <ScreenClassRender render={(screenClass) => <BookSession isMobile={screenClass?.includes("xs")} {...bookSessionContext} />} />;
};

export default withRouter(BookSessionContainer);
