import _ from "lodash";
import React, { useState } from "react";
import { Button, Dialog, IconButton, CrossIcon, Pane, Heading, Paragraph, majorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Moment from "moment";
import { fromUnixTime, isAfter } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";

import TextInput from "../../../../components/form/TextInput";
import ProfileList from "../../../../queries/profile/ProfileList";

const COACHING_VACATION_BUTTON = "Update vacation dates";
const COACHING_VACATION_COPY = "Going on vacation or taking a leave?";

const VacationSchema = Yup.object().shape({
  start: Yup.date().min(new Date(), "The start date needs to be in the future.").required("Please include a start date"),
  end: Yup.date()
    .min(new Date(), "The end date needs to be in the future.")
    .when("start", (end, schema) => end && schema.min(end))
    .required("Please include an end date"),
  reason: Yup.string("Please provide a reason for pausing!").required("Please provide a reason for pausing!")
});

const formateDate = (date, timeZone) => {
  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return formatInTimeZone(zonedDate, timeZone, "yyyy-MM-dd");
};

const formatStartDate = (date, timeZone) => {
  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return formatInTimeZone(zonedDate, timeZone, "eee LLL do");
};

const hasNotStarted = (date, timeZone) => {
  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return isAfter(zonedDate, new Date());
};

const EditVacation = ({ myProfile, myCoachingProfile }) => {
  const [isVacationShown, setVacationShown] = useState(false);
  const [updatePauseDates] = useMutation(ProfileList.UpdatePauseDates, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      toaster.success("Vacation dates saved!");
      window.analytics.track("Set Vacation Dates");
      setVacationShown(false);
    }
  });

  const [removePauseDates] = useMutation(ProfileList.RemovePauseDates, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      toaster.success("Removed vacation dates!");
      window.analytics.track("Remove Vacation Dates");
      if (isVacationShown) {
        setVacationShown(false);
      }
    }
  });

  const reason = _.isNil(myCoachingProfile.pauseCoaching) ? "" : myCoachingProfile.pauseCoaching.reason;
  const timezone = myProfile?.timezone?.length > 0 ? myProfile.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  let start, end;

  if (myCoachingProfile.pauseCoaching) {
    start = formateDate(myCoachingProfile?.pauseCoaching?.start, timezone);
    end = formateDate(myCoachingProfile?.pauseCoaching?.end, timezone);
  }

  return (
    <Pane>
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingBottom={majorScale(2)}>
        <Pane>
          <Paragraph size={500}>{COACHING_VACATION_COPY}</Paragraph>
        </Pane>
        <Pane>
          <Dialog
            isShown={isVacationShown}
            onCloseComplete={() => setVacationShown(false)}
            hasFooter={false}
            header={
              <Pane display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Pane display="flex" flexDirection="column">
                  <Heading size={500}>Update vacation dates</Heading>
                  {hasNotStarted(myCoachingProfile?.pauseCoaching?.start, timezone) && (
                    <Paragraph size={500}>
                      {`Your mentor profile will be paused starting ${formatStartDate(myCoachingProfile?.pauseCoaching?.start)}.`}
                    </Paragraph>
                  )}
                </Pane>

                <IconButton onClick={() => setVacationShown(false)} icon={CrossIcon} appearance="minimal" />
              </Pane>
            }
          >
            <Formik
              validationSchema={VacationSchema}
              initialValues={{
                start: start?.length ? start : null,
                end: end?.length ? end : null,
                reason: reason?.length ? reason : ""
              }}
              onSubmit={({ start, end, reason }, { setSubmitting }) => {
                updatePauseDates({
                  variables: {
                    start: Moment(start).tz(timezone),
                    end: Moment(end).tz(timezone),
                    reason
                  }
                });
                setSubmitting(false);
              }}
              render={({ errors, status, touched, isSubmitting }) => {
                return (
                  <Form>
                    <Pane>
                      <Heading size={400} paddingBottom={majorScale(1)}>
                        Start Date
                      </Heading>
                      <Field type="date" name="start" component={TextInput} />
                      <Heading size={400} paddingBottom={majorScale(1)}>
                        End Date
                      </Heading>
                      <Field type="date" name="end" component={TextInput} />
                      <Heading size={400} paddingBottom={majorScale(1)}>
                        Reason
                      </Heading>
                      <Field type="text" name="reason" component={TextInput} />
                      <Pane
                        display="flex"
                        justifyContent={myCoachingProfile?.pauseCoaching?.start ? "space-between" : "flex-end"}
                        marginBottom={majorScale(2)}
                      >
                        {myCoachingProfile?.pauseCoaching?.start && (
                          <Button type="button" appearance="minimal" onClick={() => removePauseDates()}>
                            Remove vacation dates
                          </Button>
                        )}
                        <Button isDisabled={start && end && reason} appearance="primary" type="submit">
                          {myCoachingProfile?.pauseCoaching?.start ? "Update vacation dates" : "Set vacation dates"}
                        </Button>
                      </Pane>
                    </Pane>
                  </Form>
                );
              }}
            />
          </Dialog>
          <Button onClick={() => setVacationShown(true)} appearance="default">
            {myCoachingProfile?.pauseCoaching?.start ? COACHING_VACATION_BUTTON : "Create vacation dates"}
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default EditVacation;
