import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import {
  Avatar,
  BookIcon,
  BriefcaseIcon,
  Button,
  EditIcon,
  Heading,
  Pane,
  Paragraph,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";

import EditCandidateWorkExperience from "../edit/candidate/EditCandidateWorkExperience";
import CandidateQueries from "../../../queries/candidate";

const LEVEL_ICONS = {
  "0-1 years": "https://ucarecdn.com/34419730-ae3c-4ff3-b354-3ce154d6a770/01years.svg",
  "1-2 years": "https://ucarecdn.com/44b70a81-28cf-42b2-af3c-61f211171313/12years.svg",
  "2-4 years": "https://ucarecdn.com/ff205f2f-6f21-4154-a2ed-517895f31364/24years.svg",
  "4-6 years": "https://ucarecdn.com/b6a3580f-8636-4e2b-ac85-a21f9e4cafa0/46years.svg",
  "6-10 years": "https://ucarecdn.com/fd469769-f794-4276-a8d0-3b1e9a7f1932/610years.svg",
  "10-15 years": "https://ucarecdn.com/f18b6cc7-e90a-48cd-baaf-340085348c8d/1015years.svg",
  "15+ years": "https://ucarecdn.com/0f37579d-2711-4a67-9091-94d5f095a499/15years.svg"
};

export const SkillSection = ({ myCandidateProfile, small }) => {
  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      marginTop={small ? undefined : majorScale(1)}
      marginBottom={small ? majorScale(1) : undefined}
    >
      <BookIcon marginRight={majorScale(1)} color={small ? theme.colors.gray600 : theme.colors.gray800} />
      <Heading size={small ? 400 : 500} color={small ? undefined : theme.colors.gray800} marginRight={majorScale(1)}>
        Skilled in
      </Heading>
      {myCandidateProfile?.specialities.map((speciality, index) => (
        <>
          {speciality.proficiency && (
            <>
              <Avatar src={speciality.proficiency && LEVEL_ICONS[speciality.proficiency]} marginRight={minorScale(1)} />
              <Paragraph size={300} marginRight={majorScale(1)}>
                {speciality.skill}
              </Paragraph>
            </>
          )}
        </>
      ))}
    </Pane>
  );
};

const ExpertiseSection = ({ profile, isEditing, location, isRecruiter }) => {
  const [showWorkExperience, setShowWorkExperience] = useState(false);
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile, {
    variables: {
      profileId: profile?.id
    }
  });

  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const yearsOfExperience = profileOptions?.availableYearsOfExperience;

  return (
    <>
      <EditCandidateWorkExperience
        showWorkExperience={showWorkExperience}
        setShowWorkExperience={setShowWorkExperience}
        myCandidateProfile={myCandidateProfile}
        myProfile={profile}
        expertiseCopy={true}
      />
      <Pane display="flex" flexDirection="row" justifyContent={isEditing ? "space-between" : "flex-start"} alignItems="center">
        <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" marginTop={majorScale(1)}>
          <BriefcaseIcon marginRight={majorScale(1)} color={theme.colors.gray800} />
          <Heading size={500} color={theme.colors.gray800} marginRight={majorScale(1)}>
            {profile?.jobRole}
          </Heading>
        </Pane>
        {isEditing && (
          <Button type="button" iconBefore={EditIcon} appearance="minimal" onClick={() => setShowWorkExperience(true)}>
            Edit Expertise
          </Button>
        )}
      </Pane>
      <SkillSection myCandidateProfile={myCandidateProfile} />
    </>
  );
};

export default withRouter(ExpertiseSection);
