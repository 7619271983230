import _ from "lodash";
import React from "react";
import { Pane, Heading, Paragraph, Select as EvergreenSelect, majorScale, minorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";

const EditMinNotice = ({ myCoachingProfile }) => {
  const [updateMinNotice] = useMutation(ProfileList.UpdateBookingNotice, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      toaster.success("Min notice updated.");
      window.analytics.track("Update Minimum Booking Notice");
    }
  });

  const currentMinNotice = myCoachingProfile.minNotice;

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
      <Heading marginBottom={minorScale(1)}>🌚 &nbsp; When is the latest notice for getting a session?</Heading>
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Paragraph size={500}>Set the minimum days notice to get a Merit session.</Paragraph>
        <Pane>
          <EvergreenSelect
            onChange={(e) => {
              const minNotice = e.target.value;
              updateMinNotice({
                variables: {
                  minNotice: parseInt(minNotice)
                }
              });
            }}
          >
            {_.map(_.range(1, 21), (option) => (
              <option selected={_.isEqual(option, currentMinNotice)}>{option}</option>
            ))}
          </EvergreenSelect>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default EditMinNotice;
