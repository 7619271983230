import React, { useState } from "react";
import { Avatar, Pane, majorScale } from "evergreen-ui";

import Reply from "./Reply";
import ReplyUpdateForm from "./ReplyUpdateForm";

const ReplyContainer = (replyProps) => {
  const { reply } = replyProps;
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  if (!reply || !reply.owner) return null;

  return (
    <Pane display="flex" paddingTop={majorScale(2)} flexDirection="column">
      <Pane display="flex" width="100%" justifyContent="space-between">
        <Pane display="flex" alignItems="start" width="100%">
          <Avatar border="default" marginTop={majorScale(1)} src={reply.owner?.picture} name={reply.owner?.fullName} size={majorScale(4)} />
          {showEdit ? (
            <ReplyUpdateForm onClose={() => setShowEdit(false)} onUpdate={() => setShowEdit(false)} {...replyProps} />
          ) : (
            <Reply showDelete={showDelete} setShowDelete={setShowDelete} setShowEdit={setShowEdit} showEdit={showEdit} {...replyProps} />
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

export default ReplyContainer;
