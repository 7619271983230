import React, { useState } from "react";
import { Button, Dialog, Heading, Pane, Text, Tooltip, InfoSignIcon, majorScale } from "evergreen-ui";

import PostForm from "../post/PostForm";
import EmailSignup from "../../routes/landing/EmailSignup";
import { areWeHuman, HumanityCheckForm } from "../../routes/session/HumanityCheck";
import { learnersCTA } from "../../content/learners";

const CREATE_POST_TITLE = "Start a discussion";

const SIGNUP_HEADER = "New to Merit?";
const SIGNUP_TEXT = "Sign up to talk to senior leaders in product, design & engineering.";

const DiscussionCreate = ({ myProfile, query, history, topic, fullWidth }) => {
  const [isShown, setShown] = useState(false);

  const content = myProfile?.id ? (
    <HumanityCheckForm
      fluid
      myProfile={myProfile}
      onCompleted={() => {
        setShown(false);
        history.push(`/discussion/create`);
      }}
    />
  ) : (
    <>
      <Pane display="flex" flexDirection="row" width="100%" marginBottom={majorScale(3)}>
        <Text>{SIGNUP_TEXT}</Text>
      </Pane>
      <EmailSignup mode="Learn" cta={learnersCTA} showGoogle={true} />
    </>
  );

  return (
    <>
      <Dialog
        title={myProfile ? CREATE_POST_TITLE : SIGNUP_HEADER}
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        preventBodyScrolling
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
        hasFooter={false}
      >
        {content}
      </Dialog>
      <Button
        appearance="primary"
        width={fullWidth ? "100%" : null}
        onClick={() => {
          window.analytics.track("Feed -> Ask A Question");
          window.analytics.track("Ask A Question");

          if (myProfile?.id && areWeHuman(myProfile)) {
            const url = topic?.id ? `/discussion/create?topic=${topic?.id}&name=${topic?.name}` : `/discussion/create`;

            history.push(url);
          } else {
            setShown(true);
          }
        }}
      >
        {CREATE_POST_TITLE}
      </Button>
    </>
  );
};

export default DiscussionCreate;
