import _ from "lodash";
import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Badge, Button, Heading, Pane, Paragraph, majorScale } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import ApplicationQueries from "../../queries/application";
import ProfileList from "../../queries/profile/ProfileList";

import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";

import WorkExperienceForm from "../profile/WorkExperienceForm";
import CandidateQueries from "../../queries/candidate";

const ROLE_PLACEHOLDER = `Please choose your job role`;
const LEVEL_PLACEHOLDER = `Please choose your job level`;
const MOTIVATION_PLACEHOLDER = `Please choose option(s) that best fit`;

const CoachCareerSchema = Yup.object().shape({
  jobRoles: Yup.array().of(Yup.string()).required("Please include the job roles that best describe you"),
  level: Yup.string().required("Please include your highest job level attained"),
  motivations: Yup.array().of(Yup.string()).required("Please include at least one motivation for mentoring"),
  reason: Yup.string().max(8000, "Please shorten your motivations for mentoring")
});

const createLabel = (label) => ({ value: label, label });

const errorText = (text) => <Badge color="red">{text}</Badge>;

const CoachCareerForm = (props) => {
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const [coachCareerUpdate, { loading, error }] = useMutation(ApplicationQueries.mutations.ExpandApplication, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ApplicationQueries.queries.MyProfile },
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery }
    ],
    onCompleted: (result) => {
      window.analytics.track("Coach Application Career");

      props.handleNextStep(result);
    }
  });

  let {
    availableRoles,
    availableLevels,
    availableMotivations,
    myProfile: { jobRoles, level, coach, role }
  } = props;

  availableRoles = availableRoles.map(createLabel);
  availableLevels = availableLevels.map(createLabel);
  availableMotivations = availableMotivations.map(createLabel);

  const selectedRoles = jobRoles ? jobRoles.map(createLabel) : null;
  const selectedLevel = level ? createLabel(level) : null;
  const selectedMotivations = coach && coach.motivations ? coach.motivations.map(createLabel) : null;
  const selectedReason = coach && coach.reason ? coach.reason : "";

  const featuresByGrouping = _.reduce(
    profileOptions?.availableTeamFeatureCategories,
    (acc, category) => {
      acc[category.value] = _.chain(profileOptions?.availableTeamFeatures)
        .filter({ category: category.value })
        .map((feature) => ({ label: feature.attribute, value: feature.attribute }))
        .value();

      return acc;
    },
    {}
  );

  return (
    <Formik
      validationSchema={CoachCareerSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        level: selectedLevel ? selectedLevel.value : null,
        jobRoles: _.map(selectedRoles, "value"),
        motivations: _.map(selectedMotivations, "value"),
        reason: selectedReason
      }}
      onSubmit={(variables, { setSubmitting }) => {
        coachCareerUpdate({ variables });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Pane display="flex" flexDirection="column">
            {/* Current Role */}
            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What is your current role?
            </Heading>
            <Select
              options={availableRoles}
              defaultValue={selectedRoles}
              isClearable={true}
              placeholder={ROLE_PLACEHOLDER}
              onChange={(selected) => {
                if (selected?.value) {
                  setFieldValue("jobRoles", [selected.value]);
                }
              }}
            />
            {errors.jobRoles ? errorText(errors.jobRoles) : null}
            {/* Current Level */}
            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What is your current level in that role?
            </Heading>
            <Select
              options={availableLevels}
              defaultValue={selectedLevel}
              isClearable={true}
              placeholder={LEVEL_PLACEHOLDER}
              onChange={(selected) => {
                if (selected && selected.value) {
                  setFieldValue("level", selected.value);
                }
              }}
            />
            {errors.level ? errorText(errors.level) : null}

            {/* Motivations */}
            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What do you want to get out of Merit?
            </Heading>

            <Pane paddingBottom={majorScale(2)}>
              <Select
                isMulti
                options={availableMotivations}
                defaultValue={selectedMotivations}
                placeholder={MOTIVATION_PLACEHOLDER}
                onChange={(selected) => {
                  const motivations = _.map(selected, (motivation) => motivation.value);
                  setFieldValue("motivations", motivations);
                }}
              />
              {errors.motivations ? errorText(errors.motivations) : null}
            </Pane>

            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What is your current or last company and title?
            </Heading>

            <WorkExperienceForm
              role={role}
              lastExperience={true}
              companySizeOptions={featuresByGrouping.companySize}
              industryOptions={featuresByGrouping.industry}
              hideDelete
            />

            <Paragraph size={600} paddingBottom={majorScale(1)}>
              Please share any additional context.
            </Paragraph>
            {/* Reason for coaching */}
            <Field type="text" name="reason" component={TextArea} />

            {/* Form Submission */}
            <Pane display="flex" justifyContent="flex-end" paddingTop={majorScale(2)}>
              <Button appearance="primary" type="submit" isLoading={loading}>
                {props.lastStep ? "Submit Application" : "Next Step"}
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default CoachCareerForm;
