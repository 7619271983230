import _ from "lodash";
import React from "react";
import { Heading, Pane, Text, majorScale } from "evergreen-ui";
import { Query } from "@apollo/client";
import gql from "graphql-tag";

class BookSessionForm extends React.Component {
  render() {
    const { playlist } = this.props;

    // The prompt
    // The profile
    // Choose a time

    // Choose person (done)
    // Choose a prompt (maybe done)
    // Choose a time

    return (
      <Pane>
        <Heading>Turtles</Heading>
      </Pane>
    );
  }
}

export default BookSessionForm;
