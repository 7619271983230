import _ from "lodash";
import qs from "query-string";
import React from "react";
import { ScreenClassRender } from "react-grid-system";
import { Col } from "react-grid-system";
import {
  Alert,
  Button,
  Heading,
  InlineAlert,
  Pane,
  Paragraph,
  PeopleIcon,
  SearchIcon,
  Spinner,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";

import ConnectionQueries from "../../queries/connection";
import { useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";
import ConnectionCard from "../../components/connection/ConnectionCard";
import emptyPhoto from "../../images/empty-chat.svg";

const isCoach = ({ status }) => {
  return _.isEqual(status, "Active") || _.isEqual(status, "Inactive") || _.isEqual(status, "Pending");
};

const serializeList = (items) => {
  const length = items.length;

  if (length === 0) {
    return "";
  } else if (length === 1) {
    return items[0];
  } else if (length === 2) {
    return items.join(" or ");
  } else {
    const lastItem = items[length - 1];
    const remainingItems = items.slice(0, length - 1).join(", ");
    return `${remainingItems}, or ${lastItem}`;
  }
};

const MissingRecommendations = ({ missing }) => {
  let mentorNames = missing ? serializeList(missing.map(({ viewing }) => viewing?.fullName)) : "";

  const copy = `You haven't recommended recently connected mentor${missing?.length > 0 ? "s" : ""} ${mentorNames}. Please provide, or decline, a recommendation for them to continue using Merit. Passing it forward helps them look good to their current and future employers!`;

  return (
    <Pane display="flex" flexDirection={"row"} justifyContent="space-between" paddingTop={majorScale(4)} width="100%">
      <Alert width="100%" title={copy} />
    </Pane>
  );
};

const EmptyConnectionsView = ({ myCoachingProfile }) => {
  let title = "No connections... yet!";
  let details = "Get career help today by booking a session with an available mentor";
  let cta = "Explore Mentors";
  let link = "/discover";

  if (myCoachingProfile?.id && isCoach(myCoachingProfile)) {
    title = "No connections... yet!";
    details = "Mentor others in tech today by helping mentees with their career questions.";
    cta = "Explore Members";
    link = "/members";
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
      <Button appearance="primary" iconBefore={SearchIcon} is={BrowserLink} to={link} marginBottom={majorScale(1)}>
        {cta}
      </Button>
    </Pane>
  );
};

const MyConnectionPage = ({ history, location }) => {
  const { missingRecommendation } = qs.parse(location?.search, { arrayFormat: "comma" });
  const { data: myConnectionsResult, loading, error, fetchMore } = useQuery(ConnectionQueries.queries.MyConnections);

  const next = () =>
    fetchMore({
      query: ConnectionQueries.queries.MyConnections,
      variables: {
        cursor: myConnectionsResult?.myConnections?.pageInfo?.nextPage
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        return Object.assign({}, previousResult, {
          myConnections: {
            __typename: previousResult.myConnections.__typename,
            pageInfo: fetchMoreResult.myConnections.pageInfo,
            edges: [...previousResult.myConnections.edges, ...fetchMoreResult.myConnections.edges]
          }
        });
      }
    });

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");
        return (
          <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
            {missingRecommendation && <MissingRecommendations missing={myConnectionsResult?.missingRecommendations} />}
            <Pane
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={missingRecommendation ? majorScale(2) : majorScale(4)}
              width="100%"
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={isMobile ? minorScale(1) : majorScale(3)}>
                <PeopleIcon size={majorScale(2)} marginRight={majorScale(1)} />
                {`My connections ${myConnectionsResult?.myConnections?.pageInfo?.edgeCount ? `(${myConnectionsResult?.myConnections?.pageInfo?.edgeCount})` : ""}`}
              </Heading>
            </Pane>
            <>
              {myConnectionsResult?.myConnections?.pageInfo?.edgeCount > 0 && (
                <Pane width="100%" justifyContent="center" display="flex" flexDirection="column">
                  <InfiniteScroll
                    next={next}
                    hasMore={myConnectionsResult?.myConnections?.pageInfo?.hasNextPage}
                    dataLength={myConnectionsResult?.myConnections?.edges?.length}
                    loader={
                      <Pane display="flex" justifyContent="center" alignItems="center">
                        <Spinner />
                      </Pane>
                    }
                  >
                    {myConnectionsResult?.myConnections?.edges?.map((connection) => (
                      <ConnectionCard connection={connection} id={connection?.id} />
                    ))}
                  </InfiniteScroll>
                </Pane>
              )}
              {!myConnectionsResult?.myConnections?.pageInfo?.edgeCount && !loading && <EmptyConnectionsView />}
            </>
          </Col>
        );
      }}
    />
  );
};

export default MyConnectionPage;
