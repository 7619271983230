import React from "react";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Button, Heading, Pane, Paragraph, majorScale, minorScale, defaultTheme } from "evergreen-ui";

import TextInput from "../../components/form/TextInput";

const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

const RequestResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Please include your email")
});

const RequestResetPasswordPage = ({ history, message }) => {
  const [requestResetPassword, { loading }] = useMutation(REQUEST_RESET_PASSWORD);

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          style={{
            width: width[screenClass],
            minHeight: "calc(100vh - 120px)",
            paddingTop: majorScale(2),
            paddingBottom: majorScale(6)
          }}
        >
          <Col xs={12} sm={10} md={8} lg={6} offset={{ sm: 1, md: 2, lg: 3 }}>
            <Formik
              validationSchema={RequestResetPasswordSchema}
              initialValues={{ email: "" }}
              onSubmit={({ email }, { setSubmitting }) => {
                requestResetPassword({ variables: { email } });
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Pane
                  backgroundColor={"white"}
                  borderRadius={minorScale(3)}
                  border={`1px solid ${defaultTheme.colors.gray400}`}
                  width="100%"
                  padding={majorScale(4)}
                  marginBottom={majorScale(3)}
                >
                  <Form>
                    <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(2)}>
                      <Heading size={700} marginBottom={minorScale(1)}>
                        🔍 Forgot your password?
                      </Heading>
                      <Paragraph size={500}>
                        Let us know your email to reset your password. If there's an account associated you'll receive a link to create a
                        new password.
                      </Paragraph>
                    </Pane>
                    <Pane paddingTop={majorScale(2)}>
                      <Field type="email" name="email" placeholder="Email" component={TextInput} />
                    </Pane>
                    {isSubmitting ? (
                      <Pane paddingBottom={majorScale(2)}>
                        <Paragraph size={500}>
                          Thanks! If there's an account associated with this email you'll receive an email shortly.
                        </Paragraph>
                      </Pane>
                    ) : null}
                    <Pane display="flex" justifyContent="center">
                      <Button type="submit" appearance="primary" disabled={isSubmitting} isLoading={loading} marginBottom={majorScale(2)}>
                        Reset Password
                      </Button>
                    </Pane>
                  </Form>
                </Pane>
              )}
            />
          </Col>
        </Row>
      )}
    />
  );
};

export default withRouter(RequestResetPasswordPage);
