import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  Badge,
  Button,
  Heading,
  Pane,
  majorScale,
  Paragraph,
  Alert,
  Autocomplete as EvergreenAutoComplete,
  TextInput as TextInputControl
} from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import LocationAutocomplete from "../../components/LocationAutocomplete";
import ApplicationQueries from "../../queries/application";
import ProfileList from "../../queries/profile/ProfileList";

import TextInput from "../form/TextInput";
import PhotoUploader from "../PhotoUploader";

const GENDER_PLACEHOLDER = `Please identify your gender`;
const ETHNICITY_PLACEHOLDER = `Please identify your ethnicities`;
const PRONOUN_PLACEHOLDER = `Please identify your pronouns`;

const PLACE_ERROR = `Please add your location.`;
const PHOTO_ERROR = `Please add your photo.`;
const GENDER_ERROR = `Please add your gender.`;
const ETHNICITY_ERROR = `Please add your ethnicities.`;
const PRONOUN_ERROR = `Please add your pronouns.`;

const INFO_WARNING =
  "We collect this to ensure Merit is a representative community. We do not share individual information with any 3rd parties or publicly.";

const PROFILE_WARNING = "We want to ensure everyone on Merit is a real person.";

// https://stackoverflow.com/questions/13927099/validate-url-with-or-without-protocol
const URL_REGEX = /(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+?\.(?:[a-zA-Z])|\d+\.\d+\.\d+\.\d+)/;

const CoachProfileSchema = Yup.object().shape({
  picture: Yup.string().required("Please provide a picture!"),
  website: Yup.string().matches(URL_REGEX, "Please provide a valid website").required("Please provide a valid website"),
  ethnicities: Yup.array().of(Yup.string()),
  gender: Yup.string()
});

const createLabel = (label) => ({ value: label, label });

const findWebsite = ({ linkedin, twitter, website }) => {
  const isFilled = (str) => !_.isNil(str) && !_.isEmpty(str);

  if (isFilled(linkedin)) {
    return linkedin;
  } else if (isFilled(twitter)) {
    return twitter;
  } else if (isFilled(website)) {
    return website;
  } else return "";
};

const Error = ({ text }) => (
  <Badge marginTop={majorScale(2)} color="red">
    {text}
  </Badge>
);

const GenderField = ({ selectedGender, availableGenders, onSelect }) => {
  const [updateGender] = useMutation(ProfileList.UpdateGender, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ]
  });

  const onChange = (changedItem) => {
    onSelect(changedItem);
    updateGender({
      variables: {
        gender: _.isNil(changedItem) ? null : changedItem
      }
    });
  };

  return (
    <>
      {availableGenders && (
        <EvergreenAutoComplete selectedItem={selectedGender} allowOtherValues={true} onChange={onChange} items={availableGenders}>
          {({ getInputProps, getRef, inputValue, openMenu }) => (
            <Pane display="flex">
              <TextInputControl
                flex="1"
                placeholder={GENDER_PLACEHOLDER}
                value={inputValue}
                ref={getRef}
                {...getInputProps({
                  onFocus: () => {
                    openMenu();
                  }
                })}
              />
            </Pane>
          )}
        </EvergreenAutoComplete>
      )}
    </>
  );
};

const PronounField = ({ selectedPronouns, availablePronouns, onSelect }) => {
  const [updatePronouns] = useMutation(ProfileList.UpdatePronouns, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }]
  });

  const onChange = (changedItem) => {
    onSelect(changedItem);
    updatePronouns({
      variables: {
        pronouns: _.isNil(changedItem) ? null : changedItem
      }
    });
  };

  return (
    <>
      {availablePronouns && (
        <EvergreenAutoComplete selectedItem={selectedPronouns} allowOtherValues={true} onChange={onChange} items={availablePronouns}>
          {({ getInputProps, getRef, inputValue, openMenu }) => (
            <Pane display="flex">
              <TextInputControl
                flex="1"
                placeholder={PRONOUN_PLACEHOLDER}
                value={inputValue}
                ref={getRef}
                {...getInputProps({
                  onFocus: () => {
                    openMenu();
                  }
                })}
              />
            </Pane>
          )}
        </EvergreenAutoComplete>
      )}
    </>
  );
};

const CoachProfileForm = (formProps) => {
  const { availableGenders, availableEthnicities, availablePronouns, myProfile, lastStep, handleNextStep, isMobile } = formProps;
  const { gender, ethnicities, picture, pronouns, place } = myProfile;

  const selectedGender = gender ? gender : null;
  const selectedEthnicities = ethnicities ? ethnicities.map(createLabel) : null;
  const selectedPronouns = pronouns ? pronouns : null;

  const [createCoachApplication] = useMutation(ApplicationQueries.mutations.CreateApplication, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ApplicationQueries.queries.MyProfile
      }
    ],
    onCompleted: (result) => {
      window.analytics.track("New Coach Application");
      handleNextStep(result);
    }
  });

  return (
    <Formik
      validationSchema={CoachProfileSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        picture: !_.isNil(picture) && !_.isEmpty(picture) ? picture : "",
        website: findWebsite(myProfile),
        gender: selectedGender ? selectedGender : "",
        ethnicities: _.map(selectedEthnicities, "value"),
        pronouns: selectedPronouns ? selectedPronouns : null
      }}
      onSubmit={(submitted, { setSubmitting }) => {
        const { website, gender, ethnicities, pronouns } = submitted;

        createCoachApplication({ variables: { website, gender, ethnicities, pronouns } });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Pane display="flex" flexDirection="column">
            {/* Website */}
            <Pane display="flex" justifyContent="space-between">
              <Heading size={600} paddingBottom={majorScale(2)}>
                What is your LinkedIn (or personal website)?*
              </Heading>
              <Button
                is="a"
                href="https://www.linkedin.com/in/"
                target="_blank"
                appearance="minimal"
                iconBefore={<FontAwesomeIcon icon={faLinkedinIn} />}
              >
                Find my LinkedIn
              </Button>
            </Pane>
            <Field type="text" name="website" placeholder="" component={TextInput} />

            {/* Profile Photo */}
            <Pane display="flex" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"} alignItems="start">
              <Pane>
                <Heading size={600} paddingBottom={isMobile ? null : majorScale(2)}>
                  Please upload a profile photo*
                </Heading>
                <Paragraph size={500} paddingBottom={isMobile ? majorScale(1) : null}>
                  {PROFILE_WARNING}
                </Paragraph>
              </Pane>
              <PhotoUploader
                onCompleted={(picture) => {
                  setFieldValue("picture", picture);
                }}
              />
            </Pane>
          </Pane>
          {errors?.picture && <Error text={PHOTO_ERROR} />}

          {/* Demographics */}
          <Pane>
            <Heading size={600} paddingBottom={majorScale(2)} paddingTop={majorScale(2)}>
              What is your ethnicity and gender?
            </Heading>
            <Alert appearance="card" intent="none" marginBottom={majorScale(2)}>
              {INFO_WARNING}
            </Alert>

            {/* Ethnicity */}
            <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
              <Select
                isMulti
                options={availableEthnicities.map(createLabel)}
                defaultValue={selectedEthnicities}
                placeholder={ETHNICITY_PLACEHOLDER}
                onChange={(selected) => {
                  const ethnicities = _.map(selected, (ethnicity) => ethnicity.value);
                  setFieldValue("ethnicities", ethnicities, false);
                }}
              />
            </Pane>
            {errors?.ethnicities && <Error text={ETHNICITY_ERROR} />}
            <GenderField
              selectedGender={selectedGender}
              availableGenders={availableGenders}
              onSelect={(selected) => setFieldValue("gender", selected, false)}
            />
            {errors?.gender && <Error text={GENDER_ERROR} />}

            <Pane paddingTop={majorScale(2)}>
              <PronounField
                selectedPronouns={selectedPronouns}
                availablePronouns={availablePronouns}
                onSelect={(selected) => setFieldValue("pronouns", selected, false)}
              />
            </Pane>
            {errors?.pronouns && <Error text={PRONOUN_ERROR} />}
          </Pane>

          <Pane display="flex" justifyContent="flex-end" paddingTop={majorScale(2)}>
            <Button appearance="primary" type="submit" disabled={isSubmitting}>
              {lastStep ? "Submit Application" : "Next Step"}
            </Button>
          </Pane>
        </Form>
      )}
    />
  );
};

export default CoachProfileForm;
