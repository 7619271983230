import gql from "graphql-tag";

import ProfileList from "../profile/ProfileList";
import QueryUtils from "../utils";

const ConversationQueries = {
  fragments: {}
};

ConversationQueries.fragments.message = `
  id,
  text,
  createdAt,
  isBot,
  user {
    id,
    picture,
    fullName
  }
`;

ConversationQueries.fragments.thread = `
  id,
  users {
    ${ProfileList.fragments.profile}
  }
`;

ConversationQueries.MostRecentConversationQuery = gql`
  query MostRecentConversationQuery($threadID: String) {
    mostRecentConversation {
      ${ConversationQueries.fragments.thread}
    },
  }
`;

ConversationQueries.ConversationThreadDetail = gql`
  query MatchConverstionsQuery($threadID: String) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    threadById(threadID: $threadID) {
      ${ConversationQueries.fragments.thread},
      messages {
        ${ConversationQueries.fragments.message}
      }
    }
  }
`;

ConversationQueries.RecentConversations = gql`
  query RecentConversationsQuery($cursor: String) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    mostRecentConversation {
      ${ConversationQueries.fragments.thread}
    },
    conversations(cursor: $cursor) {
      edges {
        ${ConversationQueries.fragments.thread},
        recentMessage {
          id,
          text,
          createdAt
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

ConversationQueries.MoreRecentConversations = gql`
  query RecentConversationsQuery($cursor: String) {
    conversations(cursor: $cursor) {
      __typename,
      edges {
        ${ConversationQueries.fragments.thread}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

ConversationQueries.ConversationsWithMatches = gql`
  query RecentConversationsQuery {
    conversationsWithMatches {
      ${ConversationQueries.fragments.thread}
    }
  }
`;

ConversationQueries.SessionsByConversation = gql`
  query SessionsByConversation($threadID: String) {
    myProfile {
      id
      slug
      timezone
    }
    findSessionsByConversation(threadID: $threadID) {
      id
      date
      topic
      description
      cleanDescription
      rescheduleLink
      cancelLink
      type
      user {
        id
        slug
        fullName
        picture
        bio
        linkedin
        name {
          first
          last
        }
      }
      coach {
        id
        status
        user {
          id
          slug
          fullName
          picture
          bio
          linkedin
          schedule
          videoLink
          name {
            first
            last
          }
        }
      }
    }
  }
`;

ConversationQueries.MatchByThread = gql`
  query MatchByThreadQuery($threadID: String) {
    matchByThread(threadID: $threadID) {
      id,
      owner {
        ${ProfileList.fragments.profile}
      },
      other {
        ${ProfileList.fragments.profile}
      },
      topic,
      status,
      frequency,
      expirationDate,
    }
  }
`;

ConversationQueries.HasConversation = gql`
  query ConversationQuery($userID: String) {
    hasConversation(userID: $userID) {
      id
    }
  }
`;

ConversationQueries.WriteMessageMutation = gql`
  mutation WriteMessageMutation($text: String!, $threadID: String!) {
    sendMessageToThread(text: $text, threadID: $threadID) {
      id
      text
      createdAt
      user {
        id
        picture
        fullName
      }
    }
  }
`;

export default ConversationQueries;
