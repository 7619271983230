import React, { useState } from "react";
import queryString from "query-string";
import { Col, ScreenClassRender } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Heading, Pane, Paragraph, majorScale } from "evergreen-ui";

import FeedQueries from "../../queries/feed";
import Loader from "../../components/Loader";
import DiscussionIndex from "../../components/discussion/DiscussionIndex";
import DiscussionFilters from "../../components/discussion/DiscussionFilters";
import DiscussionCreate from "../../components/discussion/DiscussionCreate";
import DiscussionAbout from "../../components/discussion/DiscussionAbout";

import DiscussionMeta from "./IndexMeta";

const TITLE = "Ask questions, start a discussion";
const SUBTITLE = "Thousands in tech are here to help you grow your career";

const initialQuery = (props) => {
  const {
    location: { search }
  } = props;
  const { cursor, text, sort } = queryString.parse(search, { arrayFormat: "comma" });

  return {
    cursor: cursor ? cursor : "1",
    text: text ? text : undefined,
    sort: sort ? sort : "RECENT"
  };
};

const renderUrl = ({ cursor, text, sort }) => {
  let search = `cursor=${cursor}`;

  search = text && text.length ? `${search}&text=${encodeURIComponent(text)}` : search;
  search = sort && sort.length ? `${search}&sort=${encodeURIComponent(sort)}` : search;

  return search;
};

const DiscussionIndexPage = (props) => {
  const [query, setQuery] = useState(initialQuery(props));
  const { data, fetchMore, refetch } = useQuery(FeedQueries.queries.FeedQuery, {
    variables: query
  });

  let posts, myProfile, next, updateFeed;

  if (data) {
    posts = data.posts;
    myProfile = data.myProfile;

    updateFeed = (updated) => {
      props.history.push({
        location: props.location.pathname,
        search: renderUrl(updated)
      });

      refetch({ variables: { ...updated } });
      setQuery(updated);
    };

    next = () =>
      fetchMore({
        query: FeedQueries.queries.FeedQuery,
        variables: {
          ...query,
          cursor: posts.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            posts: {
              __typename: fetchMoreResult.posts.__typename,
              pageInfo: fetchMoreResult.posts.pageInfo,
              edges: [...prev.posts.edges, ...fetchMoreResult.posts.edges]
            }
          });
        }
      });
  }

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <div
          style={{
            display: "flex",
            flexDirection: screenClass?.includes("xs") ? "column" : "row",
            width: "100%"
          }}
        >
          <DiscussionMeta />
          <Col xs={12} sm={9} md={9} lg={9}>
            <Pane
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              marginY={majorScale(4)}
            >
              <Pane display="flex" flexDirection="column" justifyContent="center">
                <Heading size={800}>{TITLE}</Heading>
                <Paragraph size={500}>{SUBTITLE}</Paragraph>
              </Pane>
            </Pane>
            <DiscussionFilters query={query} updateFeed={updateFeed} />
            {posts && posts.edges ? (
              <DiscussionIndex myProfile={myProfile} posts={posts} next={next} query={query} refetch={refetch} history={props?.history} />
            ) : (
              <Pane width="100%" alignItems="center">
                <Loader />
              </Pane>
            )}
          </Col>
          <Col xs={12} sm={3} md={3} lg={3}>
            <Pane display="flex" flexDirection="row" width="100%" justifyContent="center" alignItems="center" marginY={majorScale(4)}>
              {data?.myProfile && <DiscussionCreate myProfile={data?.myProfile} query={query} history={props?.history} fullWidth />}
            </Pane>
            <DiscussionAbout />
          </Col>
        </div>
      )}
    />
  );
};

export default DiscussionIndexPage;
