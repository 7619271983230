import React from "react";
import { format } from "date-fns-tz";
import { CalendarIcon, Strong, Pane, Paragraph, Heading, Text, Avatar, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";

const formatRole = (user) => [user?.role?.title, user?.role?.team?.name].filter(Boolean).join(", ");

const formatImg = (pic) => {
  if (pic?.includes("cloudflare")) {
    return pic;
  } else if (pic?.includes("google")) {
    return pic?.replace("s100", "s300");
  } else {
    return pic?.includes("smart_faces")
      ? pic
      : pic?.replace("smart_resize", "scale_crop")?.replace("150x150", "300x300")?.concat("smart/smart_faces");
  }
};

const renderDate = (start) => {
  const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();

  let dayStr, timeStr;

  try {
    dayStr = format(new Date(start), "ccc MMM do", { timeZone }); // Tue Oct 28th
    timeStr = format(new Date(start), "h:mm bb", { timeZone }); // 2 pm
  } catch (err) {
    return ``;
  }

  return `${dayStr} at ${timeStr}`;
};

const MentorCard = ({ user, nextAvailable }) => {
  const userPicture = formatImg(user?.picture) || "";

  return (
    <BrowserLink
      to={`/p/${user?.slug}`}
      style={{
        textDecoration: "none",
        cursor: "pointer"
      }}
    >
      <Pane
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray500}`}
        borderRadius={majorScale(2)}
        padding={majorScale(2)}
        width="100%"
      >
        <Pane position="relative">
          <Avatar
            src={userPicture}
            name={user?.fullName || "Mentor"}
            shape="square"
            width="100%"
            borderRadius={majorScale(2)}
            height="246px"
            size={246}
          />
          <Pane
            position="absolute"
            bottom="-5px"
            right="-5px"
            background="white"
            borderRadius="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={56}
            height={56}
          >
            <Avatar
              size={40}
              src={user?.role?.team?.logo ? user?.role?.team?.logo : "https://ucarecdn.com/3892b394-7718-4ecf-88bb-d559bb5a2b99/office.svg"}
              name={user?.role?.team?.name}
            />
          </Pane>
        </Pane>
        <Heading size={500} marginTop={minorScale(1)}>
          {user?.fullName}
        </Heading>
        <Paragraph size={400} className="line-clamp-1">
          {formatRole(user)}
        </Paragraph>
        {nextAvailable?.start && (
          <Pane display="flex" alignItems="center" marginTop={4}>
            <CalendarIcon marginRight={4} color="#8F95B2" size={12} />
            <Text size={300}>
              <Strong size={300}>Available</Strong> {renderDate(nextAvailable.start)}
            </Text>
          </Pane>
        )}
      </Pane>
    </BrowserLink>
  );
};

export default MentorCard;
