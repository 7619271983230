import _ from "lodash";
import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { Pane, majorScale, Overlay } from "evergreen-ui";

import MentorCard from "../../components/mentor/MentorCard";
import { useQuery } from "@apollo/client";
import CoachList from "../coach/CoachList";
import CreateCandidateProfile from "../../components/auth/signup/CreateCandidateProfile";
import JobSearchForm from "../../components/auth/signup/JobSearchForm";

const setupPages = [
  {
    component: CreateCandidateProfile,
    title: "💼 Tell us more about your job search"
  },
  {
    component: JobSearchForm,
    title: "💼 Tell us more about your job search"
  }
];

const CandidateSetupPage = ({ history, match: { params } }) => {
  const [step, setStep] = useState(parseInt(params?.step) || 0); // set this to the step in the url
  const { data: mentorResult } = useQuery(CoachList.CoachPreviewQuery);
  const mentors = mentorResult?.coachPreview;
  const CurrentStep = setupPages[step]?.component;

  return (
    <div>
      <Container>
        <Row>
          <Pane display="flex" width="100%" flexWrap="wrap">
            {mentors &&
              mentors.map((mentor) => (
                <Col md={4} lg={3} key={`CandidateSetupPage-${mentor?.id}`} style={{ marginBottom: majorScale(2) }}>
                  <MentorCard user={mentor?.user} nextAvailable={mentor?.nextAvailable} />
                </Col>
              ))}
          </Pane>
        </Row>
        <Overlay
          isShown={true}
          preventBodyScrolling={true}
          shouldCloseOnClick={false}
          shouldCloseOnEscapePress={false}
          containerProps={{ overflowY: "scroll" }}
        >
          <Container>
            <Row justify="center">
              <Col xs={10} sm={10} md={8} lg={8} xl={6}>
                <Pane padding={majorScale(4)} marginY="12vmin" backgroundColor="white" elevation={4} borderRadius={8}>
                  <CurrentStep
                    totalSteps={setupPages.length}
                    currentStep={step + 1}
                    title={setupPages[step]?.title}
                    nextPage={(results) => {
                      const topicIds = results?.topicIds;

                      if (_.isEqual(step, setupPages.length - 1)) {
                        let next = "";

                        next = "/discover?";

                        if (topicIds?.length > 0) {
                          next += `topics=${topicIds.join(",")}`;
                        }

                        if (results?.jobRole) {
                          next += `&roles=${results?.jobRole}`;
                        }

                        history.push(next);
                      } else {
                        setStep(step + 1);
                        history.push(`/candidate/setup/${step + 1}`);
                      }
                    }}
                  />
                </Pane>
              </Col>
            </Row>
          </Container>
        </Overlay>
      </Container>
    </div>
  );
};

export default CandidateSetupPage;
