import React from "react";
import { Link as BrowserLink } from "react-router-dom";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Avatar, Button, Heading, Pane, Paragraph, majorScale, minorScale, defaultTheme } from "evergreen-ui";

import ApplicationQueries from "../../queries/application";

const width = {
  xs: 375,
  // xs: 540,
  sm: 740,
  md: 960,
  xl: 1140,
  xxl: 1140
};

const ApplicationAccepted = (props) => {
  const { data: applicationContext, loading } = useQuery(ApplicationQueries.queries.MyProfile);

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          style={{
            width: width[screenClass],
            minHeight: "calc(100vh - 120px)",
            paddingTop: majorScale(2),
            paddingBottom: majorScale(6)
          }}
        >
          <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
            <>
              <Pane marginY={majorScale(4)}>
                <Heading size={700}>Congrats! You’re accepted as a mentor on Merit</Heading>
              </Pane>
              <Pane
                backgroundColor={"white"}
                borderRadius={minorScale(3)}
                border={`1px solid ${defaultTheme.colors.gray400}`}
                width="100%"
                padding={majorScale(4)}
                marginBottom={majorScale(3)}
              >
                <Pane
                  flexDirection="column"
                  display="flex"
                  justifyContent="space-between"
                  paddingBottom={majorScale(2)}
                  paddingX={majorScale(4)}
                >
                  <Heading size={700}>👋🏾 Welcome to Merit!</Heading>
                </Pane>
                <Pane display="flex" flexDirection="column" paddingX={majorScale(4)} paddingY={majorScale(2)}>
                  <Pane>
                    <Paragraph size={500}>
                      {`Hey${applicationContext?.myProfile?.name?.first ? ` ${applicationContext?.myProfile?.name?.first},` : `,`}`}
                    </Paragraph>
                    <br />
                    <Paragraph size={500}>
                      <b>We want to personally thank you for creating a mentorship profile on Merit.</b> We created Merit to help anyone,
                      from any background, access the mentorship and network necessary to have a successful career.
                    </Paragraph>
                    <br />
                    <Paragraph size={500}>
                      Similarly, we believe mentorship is a way to give back to the tech community, develop leadership skills, and grow your
                      own network in industry. Thanks for coming on board as we work towards this mission!
                    </Paragraph>
                    <br />
                    <Paragraph size={500}>
                      <b>Next steps</b>
                    </Paragraph>
                    <Paragraph size={500}>
                      Based your application we’ve filled out some of your mentorship profile, like your topics and availability, for you.
                      On the next page you’ll finish setting up your mentorship calendar. After that, you’ll activate your profile!
                    </Paragraph>
                    <br />
                    <Paragraph size={500}>
                      <b>Thanks!</b>
                    </Paragraph>
                    <Paragraph size={500}>Randy and Kirk</Paragraph>
                    <Paragraph size={500}>Founders, Merit</Paragraph>
                  </Pane>
                  <Pane marginY={majorScale(2)}>
                    <Avatar
                      size={majorScale(10)}
                      marginRight={majorScale(1)}
                      src="https://ucarecdn.com/96c525b1-8e13-43ab-81c5-a4c78bbc23d9/-/scale_crop/300x300/smart/smart_faces"
                    />
                    <Avatar
                      size={majorScale(10)}
                      marginRight={majorScale(1)}
                      src="https://ucarecdn.com/dd0ed49f-22a4-4d2a-a443-123be0f1f14c/-/scale_crop/300x300/smart/smart_faces"
                    />
                  </Pane>
                  <Pane display="flex" justifyContent="space-between" width="100%" marginTop={majorScale(2)}>
                    <Button is={BrowserLink} to="/profile/edit">
                      Skip
                    </Button>
                    <Button is={BrowserLink} to="/mentor/setup" appearance="primary">
                      Configure mentorship calendar
                    </Button>
                  </Pane>
                </Pane>
              </Pane>
            </>
          </Col>
        </Row>
      )}
    />
  );
};

export default withRouter(ApplicationAccepted);
