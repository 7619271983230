import gql from "graphql-tag";

import QueryUtils from "../utils";
import ProfileList from "../profile/ProfileList";

const SessionQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

SessionQueries.fragments.report = `
  id,
  question,
  response,
  status,
  createdAt,
  updatedAt,
  user {
    ${ProfileList.fragments.profile}
  },
`;

SessionQueries.fragments.session = `
  id,
  date,
  topic,
  description,
  cleanDescription,
  rescheduleLink,
  cancelLink,
  rescheduleId,
  type,
  status,
  user, {
    id,
    fullName,
    slug,
    name {
      first,
      last
    },
    role {
      id,
      title,
      team {
        id,
        name
      }
    },
    picture,
    bio,
    linkedin
  }
  coach {
    id,
    status,
    user {
      id,
      fullName,
      slug,
      name {
        first,
        last
      },
      role {
        id,
        title,
        team {
          id,
          name
        }
      },
      picture,
      bio,
      linkedin
    }
  },
  report {
    ${SessionQueries.fragments.report}
  }
`;

SessionQueries.fragments.sessionDetail = `
  id,
  topic,
  date,
  description,
  type,
  user {
    id,
    fullName,
    picture,
    slug,
    name {
      first,
      last
    }
    roles {
      id,
      title,
      team {
        id,
        name
      }
    },
    linkedin,
    website,
    twitter
  },
  coach {
    id,
    user {
      id,
      fullName,
      picture,
      slug,
      name {
        first,
        last
      },
      roles {
        id,
        title,
        team {
          id,
          name
        }
      },
      linkedin,
      website,
      twitter
    }
  }
`;

SessionQueries.fragments.sessionOffer = `
  id,
  message,
  status,
  createdAt,
  updatedAt,
`;

SessionQueries.fragments.sessionRequest = `
  id,
  message,
  status,
  createdAt,
  updatedAt,
  date,
  response,
  user {
    id,
    fullName,
    slug,
    name {
      first,
      last
    },
    role {
      id,
      title,
      team {
        id,
        name
      }
    },
    picture,
    bio,
    linkedin
  },
  owner {
    id,
    fullName,
    slug,
    name {
      first,
      last
    },
    role {
      id,
      title,
      team {
        id,
        name
      }
    },
    picture,
    bio,
    linkedin
  }
`;

SessionQueries.fragments.sessionReminder = `
  id,
  reason,
  when,
  date,
  createdAt,
  updatedAt,
  user {
    id,
  },
  session {
    id,
  }
`;

SessionQueries.fragments.sessionRequestDetail = `
  id,
  message,
  status,
  createdAt,
  updatedAt,
  date,
  user {
    id,
    fullName,
    slug,
    name {
      first,
      last
    },
    role {
      id,
      title,
      team {
        id,
        name
      }
    },
    picture,
    bio,
    linkedin
  },
  owner {
    id,
    fullName,
    slug,
    name {
      first,
      last
    },
    role {
      id,
      title,
      team {
        id,
        name
      }
    },
    picture,
    bio,
    linkedin
  }
`;

SessionQueries.fragments.ReviewFragment = `
  id,
  rating,
  session {
    ${SessionQueries.fragments.session},
  },
  responses {
    id,
    question,
    answer,
    privacy
  },
  user {
    id,
    fullName,
    name {
      first,
      last
    },
    picture,
    bio,
    linkedin
  }
`;

SessionQueries.fragments.review = `
  id,
  rating,
  responses {
    id,
    question,
    answer,
    required,
    privacy,
    placeholder,
    name
  },
  user {
    id,
  }
`;

SessionQueries.queries.MySessions = gql`
  query MySessionsQuery($cursor: String, $direction: String) {
    myProfile {
      id,
      timezone,
      slug,
    },
    myCoachingProfile {
      id,
      status
    },
    mySessions(cursor: $cursor, direction: $direction) {
      edges {
        ${SessionQueries.fragments.session},
        rescheduleCount,
        myReview {
          id
        },
        thread {
          id,
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MyIncompleteSessions = gql`
  query MyIncompleteSessions($cursor: String) {
    myIncompleteSessions(cursor: $cursor) {
      ${QueryUtils.PageInfo},
      edges {
        ${SessionQueries.fragments.session},
        thread {
          id,
        },
        myReview {
          id
        }
      }
    }
  }
`;

SessionQueries.queries.MyFeedback = gql`
  query MyFeedback($cursor: String) {
    myProfile {
      id,
      timezone,
    },
    myCoachingProfile {
      id,
      status
    },
    myFeedback(cursor: $cursor) {
      edges {
        ${SessionQueries.fragments.ReviewFragment},
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MoreSessionsQuery = gql`
  query MoreMySessionsQuery($cursor: String, $direction: String) {
    mySessions(cursor: $cursor, direction: $direction) {
      __typename,
      edges {
        ${SessionQueries.fragments.session},
        thread {
          id,
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MyMostRecentBooking = gql`
  query MyMostRecentBooking {
    myMostRecentBooking {
      id
      date
      description
    }
  }
`;

SessionQueries.queries.RequestSession = gql`
  query RequestSessionQuery($profileId: String!) {
    profile(profileID: $profileId) {
      id,
      fullName,
      name {
        first,
        last
      },
      picture,
      bio,
      role {
        id,
        title,
        team {
          id,
          name,
          logo
        }
      }
    },
    sessionRequest(profileId: $profileId) {
      ${SessionQueries.fragments.sessionRequest}
    }
    coachByProfileId(profileID: $profileId) {
      id,
      isAvailable
    },
    myProfile {
      ${ProfileList.fragments.profile}
    }
  }
`;

SessionQueries.queries.MySessionRequests = gql`
  query MySessionRequests($cursor: String) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    mySessionRequests(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MyPendingRequests = gql`
  query MySessionRequests($cursor: String) {
    myProfile {
      id
    },
    myPendingRequests(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MySessionOffers = gql`
  query MySessionOffers($cursor: String) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    mySessionOffers(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail},
        post {
          id,
          title,
          details,
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MyOfferedSessions = gql`
  query MyOfferedSessions($cursor: String) {
    myProfile {
      id,
    },
    myOfferedSessions(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail},
        post {
          id,
          title,
          details,
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MySessionOffer = gql`
  query MySessionOffer($profileId: String) {
    myProfile {
      id,
    },
    mySessionOffer(profileId: $profileId) {
      ${SessionQueries.fragments.sessionOffer}
    }
  }
`;

SessionQueries.queries.MyOfferedSession = gql`
  query MyOfferedSession($profileId: String) {
    myProfile {
      id,
    },
    myOfferedSession(profileId: $profileId) {
      ${SessionQueries.fragments.sessionOffer}
    }
  }
`;

SessionQueries.queries.MyReceivedRequests = gql`
  query MyReceivedRequests($cursor: String) {
    myProfile {
      id
    },
    myReceivedRequests(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MoreReceivedRequests = gql`
  query MySessionRequests($cursor: String) {
    myReceivedRequests(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MoreSessionRequestsQuery = gql`
  query MySessionRequests($cursor: String) {
    mySessionRequests(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.sessionRequestDetail}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.SubmittedReviews = gql`
  query SubmittedReviews($cursor: String) {
    myProfile {
      id,
      isAdmin
    },
    submittedReviews(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.ReviewFragment}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.MoreSubmittedReviews = gql`
  query MoreSubmittedReviews($cursor: String) {
    myProfile {
      id,
      isAdmin
    },
    submittedReviews(cursor: $cursor) {
      __typename,
      edges {
        ${SessionQueries.fragments.ReviewFragment},
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

SessionQueries.queries.CurrentSessionRequest = gql`
  query CurrentRequest($profileId: String, $slug: String) {
    sessionRequest(profileId: $profileId, slug: $slug) {
      ${SessionQueries.fragments.sessionRequest}
    }
  }
`;

SessionQueries.mutations.RequestSession = gql`
  mutation RequestSessionQuery(
    $profileId: String!, $message: String,
    $topicId: String, $date: String,
  ) {
    requestSession(
      profileId: $profileId, message: $message,
      topicId: $topicId, date: $date
    ) {
      ${SessionQueries.fragments.sessionRequest}
    }
  }
`;

SessionQueries.mutations.RequestResponse = gql`
  mutation RequestResponse($id: String!, $status: String!, $response: String) {
    requestResponse(id: $id, status: $status, response: $response) {
      ${SessionQueries.fragments.sessionRequestDetail}
    }
  }
`;

SessionQueries.mutations.OfferSession = gql`
  mutation OfferSession($profileId: String!, $message: String, $postId: String) {
    offerSession(profileId: $profileId, message: $message, postId: $postId) {
      ${SessionQueries.fragments.sessionOffer}
    }
  }
`;

SessionQueries.mutations.OfferResponse = gql`
  mutation OfferResponse($id: String!, $status: String!) {
    offerResponse(id: $id, status: $status) {
      ${SessionQueries.fragments.sessionRequestDetail}
    }
  }
`;

SessionQueries.queries.SessionReminderRequest = gql`
  query SessionReminderRequest($sessionId: String!) {
    myProfile {
      id,
    },
    sessionReminderOptions,
    sessionById(sessionID: $sessionId) {
      ${SessionQueries.fragments.sessionDetail}
    }
  }
`;

SessionQueries.mutations.CreateSessionReminder = gql`
  mutation CreateSessionReminder($sessionId: String!, $when: String, $reason: String) {
    createSessionReminder(sessionId: $sessionId, reason: $reason, when: $when) {
      ${SessionQueries.fragments.sessionReminder}
    }
  }
`;

SessionQueries.queries.PendingReminders = gql`
  query PendingReminders {
    myProfile {
      id,
    },
    pendingSessionReminders {
      ${SessionQueries.fragments.sessionReminder},
      session {
        ${SessionQueries.fragments.sessionDetail},
        thread {
          id
        }
      }
    }
  }
`;

SessionQueries.mutations.DismissReminder = gql`
  mutation DismissReminder($reminderId: String!) {
    dismissSessionReminder(reminderId: $reminderId) {
      ${SessionQueries.fragments.sessionReminder}
    }
  }
`;

SessionQueries.mutations.SkipReview = gql`
  mutation skipReview($sessionID: String!, $reason: String) {
    skipReview(sessionID: $sessionID, reason: $reason) {
      id
    }
  }
`;

SessionQueries.mutations.MarkNoShow = gql`
  mutation markNoShow($sessionId: String, $reason: String) {
    markNoShow(sessionId: $sessionId, reason: $reason) {
      ${SessionQueries.fragments.session}
    }
  }
`;

SessionQueries.queries.MissingReviews = gql`
  query MissingReviews {
    myProfile {
      id,
      timezone
    },
    missingReview {
      ${SessionQueries.fragments.session}
    }
  }
`;

SessionQueries.queries.SessionAndReview = gql`
  query SessionQuery($sessionID: String!) {
    myProfile {
      id,
    },
    responsesbySession(sessionID: $sessionID) {
      ${SessionQueries.fragments.review}
    },
    sessionById(sessionID: $sessionID) {
      ${SessionQueries.fragments.session}
    },
  }
`;

SessionQueries.queries.SessionAndReviewById = gql`
  query SessionQuery($id: String!) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    responsesbySession(sessionID: $id) {
      ${SessionQueries.fragments.review}
    },
    feedbackForSession(sessionID: $id) {
      ${SessionQueries.fragments.review},
    },
    reportBySessionId(sessionId: $id) {
      ${SessionQueries.fragments.report}
    },
    connectionBySessionId(sessionId: $id) {
      id,
      connectedAt,
      sessionsCompleted,
      amItheMentor,
      conversationThread {
        id,
      }
      viewing {
        ${ProfileList.fragments.profile}
      },
      recommendation {
        id,
        question,
        response,
        status,
        createdAt,
        updatedAt,
        owner {
          ${ProfileList.fragments.profile}
        },
        user {
          ${ProfileList.fragments.profile}
        },
      }
    }
    sessionById(sessionID: $id) {
      ${SessionQueries.fragments.session},
      questions,
      context,
      actions,
      resources,
      rescheduleCount,
      thread {
        id,
      }
    }
  }
`;

SessionQueries.queries.RescheduleSessionQuery = gql`
  query SessionQuery($id: String!) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    sessionById(sessionID: $id) {
      ${SessionQueries.fragments.session},
      rescheduleCount
    }
  }
`;

SessionQueries.mutations.UpdateSessionPrep = gql`
  mutation UpdateSessionPrep($id: String, $questions: [String], $context: String) {
    updateSessionPrep(id: $id, questions: $questions, context: $context) {
      ${SessionQueries.fragments.session},
      questions,
      context
    }
  }
`;

SessionQueries.mutations.UpdateSessionFollowUp = gql`
  mutation UpdateSessionFollowUp($id: String, $actions: [String], $resources: [String]) {
    updateSessionFollowUp(id: $id, actions: $actions, resources: $resources) {
      ${SessionQueries.fragments.session},
      actions,
      resources
    }
  }
`;

SessionQueries.mutations.ReviewSession = gql`
  mutation reviewSession(
    $sessionID: String!, $rating: Int, $message: String, $lesson: String, $improvement: String, $admin: String,
    $success: String, $testimonial: String, $expertise: String, $questions: String, $stories: String, $actions: String
  ) {
    reviewSession(
      sessionID: $sessionID, rating: $rating, message: $message, lesson: $lesson, improvement: $improvement, admin: $admin,
      success: $success, testimonial: $testimonial, expertise: $expertise, questions: $questions, stories: $stories, actions: $actions
    ) {
      ${SessionQueries.fragments.review}
    }
  }
`;

SessionQueries.queries.UpcomingSlots = gql`
  query UpcomingSlots($mentorId: String) {
    upcomingSlots(mentorId: $mentorId) {
      start
      end
    }
  }
`;

SessionQueries.mutations.CreateSession = gql`
  mutation CreateSession(
    $mentorId: String, $startTime: String, $topicId: String,
    $description: String, $timeZone: String,
  ) {
    createSession(
      mentorId: $mentorId, startTime: $startTime, topicId: $topicId,
      description: $description, timeZone: $timeZone
    ) {
      ${SessionQueries.fragments.session}
    }
  }
`;

SessionQueries.mutations.CancelSession = gql`
  mutation CancelSession($id: String, $reason: String, $details: String) {
    cancelSession(id: $id, reason: $reason, details: $details) {
      ${SessionQueries.fragments.session}
    }
  }
`;

SessionQueries.mutations.RescheduleSession = gql`
  mutation RescheduleSession($id: String, $reason: String, $details: String, $startTime: String, $timeZone: String) {
    rescheduleSession(id: $id, reason: $reason, details: $details, startTime: $startTime, timeZone: $timeZone) {
      ${SessionQueries.fragments.session}
    }
  }
`;

SessionQueries.mutations.ReportSession = gql`
  mutation ReportSession($sessionId: String!, $response: String, $status: String) {
    reportSession(sessionId: $sessionId, response: $response, status: $status) {
      ${SessionQueries.fragments.report}
    }
  }
`;

export default SessionQueries;
