import React from "react";
import {
  Avatar,
  Badge,
  Button,
  CalendarIcon,
  ChatIcon,
  Pane,
  PersonIcon,
  Popover,
  Position,
  Strong,
  Text,
  majorScale,
  minorScale
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { format } from "date-fns-tz";
import { isValid } from "date-fns";
import { useQuery } from "@apollo/client";

import ProfileList from "../../queries/profile/ProfileList";

const renderDate = (start) => {
  start = isValid(new Date(start)) ? start : Number(start);

  const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();

  const dayStr = format(new Date(start), "ccc MMM do", { timeZone }); // Tue Oct 28th
  const timeStr = format(new Date(start), "h:mm bb", { timeZone }); // 2 pm

  return `${dayStr} at ${timeStr}`;
};

const renderTitle = (role) => {
  if (!role?.id) return null;

  if (role?.title && role?.team?.name) {
    return `${role.title}, ${role.team.name}`;
  } else if (role?.title && !role?.team?.name) {
    return role.title;
  } else return role?.team?.name;
};

const ProfilePreview = ({ children, user }) => {
  const { data: mentorResult } = useQuery(ProfileList.MentorDetail, {
    variables: { profileID: user?.id, slug: user?.slug }
  });

  const mentor = mentorResult?.coachByProfileId;

  return (
    <Popover
      trigger="hover"
      position={Position.BOTTOM_LEFT}
      content={
        <Pane
          width={480}
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexDirection="column"
          paddingY={minorScale(3)}
          paddingX={minorScale(6)}
        >
          <Pane display="flex" flexDirection="row" justifyContent="start" width="100%" marginBottom={majorScale(2)}>
            <Pane
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="center"
              width={majorScale(6)}
              marginRight={minorScale(5)}
            >
              <Avatar src={user?.picture} name={user?.fullName} size={majorScale(5)} />
              {mentor?.id && mentor?.status?.includes("Active") && (
                <Badge zIndex={1} color="green" marginTop={-majorScale(1)}>
                  Mentor
                </Badge>
              )}
            </Pane>
            <Pane display="flex" flexDirection="column">
              <Text size={500}>{user?.fullName}</Text>
              {user?.role && <Text size={300}>{renderTitle(user.role)}</Text>}
              {mentor?.nextAvailable?.start && (
                <Pane display="flex" alignItems="center" marginTop={4}>
                  <CalendarIcon marginRight={4} color="#8F95B2" size={12} />
                  <Text size={300}>
                    <Strong size={300}>Available</Strong> {renderDate(mentor.nextAvailable.start)}
                  </Text>
                </Pane>
              )}
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" width="100%">
            {mentorResult?.coachByProfileId && (
              <Button is={BrowserLink} to={`/p/${user?.slug}`} iconBefore={CalendarIcon} appearance="minimal">
                Book Session
              </Button>
            )}
            <Button iconBefore={PersonIcon} is={BrowserLink} to={`/p/${user?.slug}`} appearance="minimal">
              View Profile
            </Button>
            {mentorResult?.hasConversation?.id && (
              <Button iconBefore={ChatIcon} is={BrowserLink} to={`/conversation/${mentorResult.hasConversation.id}`} appearance="minimal">
                Send Message
              </Button>
            )}
          </Pane>
        </Pane>
      }
    >
      {children}
    </Popover>
  );
};

export default ProfilePreview;
