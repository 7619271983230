import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row } from "react-grid-system";
import { Pane, Spinner } from "evergreen-ui";

import ListLoadingView from "../ListLoadingView";
import NewTopicCard from "./NewTopicCard";

const TopicIndex = (props) => {
  const { topics, next, loading } = props;

  return (
    <Row align="start" justify="center" style={{ marginBottom: "50px" }}>
      {topics?.edges && (
        <Pane display="block" width="100%">
          <InfiniteScroll
            style={{ display: "flex", flexWrap: "wrap" }}
            hasMore={topics.pageInfo.hasNextPage}
            dataLength={topics.edges.length}
            loader={<ListLoadingView title={"Loading topics"} />}
            next={next}
          >
            {topics.edges.map((topic) => (
              <Col xs={12} key={topic.id}>
                <Pane borderBottom="1px solid #E6E8F0">
                  <NewTopicCard topic={topic} />
                </Pane>
              </Col>
            ))}
          </InfiniteScroll>
        </Pane>
      )}
      {topics?.edges && !topics.edges.length && !loading && <ListLoadingView title={"No topics found"} hideSpinner />}
      {!topics && <Spinner />}
    </Row>
  );
};

export default TopicIndex;
