import React from "react";
import { Row, Col } from "react-grid-system";
import { Badge, Pane, Heading, Spinner, majorScale } from "evergreen-ui";
import queryString from "query-string";
import { useQuery } from "@apollo/client";

import MentorCard from "../../../components/mentor/MentorCard";
import ShowMore from "../components/ShowMore";
import CoachList from "../../coach/CoachList";
import PlaylistQueries from "../../../queries/playlist";

const MentorRow = ({ pageInfo, mentors }) => {
  const _mentors = mentors.filter((m) => m.user);

  return (
    <Row>
      {_mentors.slice(0, 4).map((mentor) => (
        <Col md={3} key={mentor?.id} style={{ marginBottom: majorScale(1) }}>
          <MentorCard user={mentor.user} key={mentor.id} nextAvailable={mentor.nextAvailable} />
        </Col>
      ))}
    </Row>
  );
};

const MentorsSection = ({ filters, mentors }) => {
  const { data: topicInfo } = useQuery(PlaylistQueries.TopicsByIds, {
    variables: {
      ids: filters?.topics
    }
  });

  const showNext = topicInfo?.topicsByIds?.length > 0 ? `/mentors?${queryString.stringify(filters, { arrayFormat: "comma" })}` : "/mentors";

  return (
    <Col xs={12} style={{ marginBottom: majorScale(5) }}>
      <Row>
        <Col xs={12}>
          <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%" marginBottom={majorScale(3)}>
            {topicInfo?.topicsByIds?.length > 0 ? (
              <Heading size={600}>
                Here are a few mentors that can help with{" "}
                {topicInfo?.topicsByIds?.map((t) => (
                  <Badge marginRight={majorScale(1)} color="purple">
                    {t.name}
                  </Badge>
                ))}
              </Heading>
            ) : (
              <Heading size={600}>Find the right mentors for your next step</Heading>
            )}
            <ShowMore to={showNext} />
          </Pane>
        </Col>
      </Row>
      {mentors?.leaders?.edges?.length ? (
        <MentorRow pageInfo={mentors?.leaders?.pageInfo} mentors={mentors?.leaders?.edges} />
      ) : (
        <Row>
          <Col xs={12}>
            <Pane width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" height={300}>
              <Spinner />
            </Pane>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default MentorsSection;
