import _ from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, CalendarIcon, Dialog, Pane, Paragraph, Heading, IconButton, CrossIcon, toaster, majorScale } from "evergreen-ui";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { HumanityCheckForm, areWeHuman } from "../../../routes/session/HumanityCheck";
import SessionQueries from "../../../queries/session";
import TextArea from "../../../components/form/TextArea";
import CalendarView from "./CalendarView";

const SessionRequestSchema = Yup.object().shape({
  profileId: Yup.string().required(),
  message: Yup.string().required("Please include your reason for booking a session")
});

const RequestDetail = ({ history, description, profile, onCompleted }) => {
  const [requestSession] = useMutation(SessionQueries.mutations.RequestSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: SessionQueries.queries.MySessionRequests },
      {
        query: SessionQueries.queries.CurrentSessionRequest,
        variables: { profileId: profile?.id }
      }
    ],
    onCompleted: (result) => {
      toaster.success("Request submitted.", { duration: 2 });
      history.push(`/p/${profile?.slug}`);

      if (_.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });

  return (
    <Formik
      validationSchema={SessionRequestSchema}
      initialValues={{
        profileId: profile?.id,
        message: description ? description : ""
      }}
      onSubmit={(submitted, { setSubmitting }) => {
        requestSession({ variables: { ...submitted } });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Pane display="flex" flexDirection="column" width="100%">
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            {/* Form header */}
            <Pane marginBottom={majorScale(2)}>
              <Field
                type="text"
                name="message"
                placeholder="Hi! I'd like to talk about my current situation at my job..."
                component={TextArea}
              />
            </Pane>

            {/* Submission Button */}
            <Pane display="flex" justifyContent="flex-end" marginBottom={majorScale(2)}>
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                Submit Request
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const RequestView = withRouter((bookingContext) => {
  const { myProfile, profile, hasConversation, isCurrentUser, showRequest: isShown } = bookingContext;

  const { data: currentRequest } = useQuery(SessionQueries.queries.CurrentSessionRequest, {
    variables: {
      profileId: profile?.id,
      slug: profile?.slug
    }
  });

  const { data: currentOffer } = useQuery(SessionQueries.queries.MySessionOffer, {
    variables: {
      profileId: profile?.id
    }
  });

  const [showRequest, setShowRequest] = useState(isShown);
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (!myProfile?.id) return null;

  const isHuman = areWeHuman(myProfile);

  const content = isHuman ? (
    <>
      {showRequest && (
        <RequestDetail
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          setShowRequest={setShowRequest}
          {...bookingContext}
          onCompleted={(result) => {
            setShowRequest(false);
          }}
        />
      )}
    </>
  ) : (
    <Pane width="100%">
      <HumanityCheckForm fluid profile={profile} myProfile={myProfile} onCompleted={() => setShowRequest(true)} />
    </Pane>
  );

  const header = showConfirmation ? (
    <Pane display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Heading is="h4" size={600}>
        {`Request Confirmed!`}
      </Heading>
      <IconButton onClick={() => setShowRequest(false)} icon={CrossIcon} appearance="minimal" />
    </Pane>
  ) : (
    <Pane display="flex" justifyContent="space-between" alignItems="start" width="100%">
      <Pane display="flex" flexDirection="column">
        <Heading is="h4" size={600} marginBottom={majorScale(1)}>
          {`Request to talk with ${profile?.name?.first}`}
        </Heading>
        <Paragraph size={500}>
          {`Please provide a bit of context for why you want to talk to ${profile?.name?.first}. If they accept, you'll be notified and will receive access to their mentorship calendar.`}
        </Paragraph>
      </Pane>
      <IconButton onClick={() => setShowRequest(false)} icon={CrossIcon} appearance="minimal" />
    </Pane>
  );

  const accepted =
    _.isEqual(currentRequest?.sessionRequest?.status, "Accepted") || _.isEqual(currentOffer?.mySessionOffer?.status, "Active");
  const myCalendar = _.isEqual(myProfile?.id, profile?.id);

  return (
    <>
      {((currentRequest && accepted) || myCalendar) && (
        <CalendarView
          profile={profile}
          coachByProfileId={bookingContext?.coachByProfileId}
          myProfile={myProfile}
          isCurrentUser={isCurrentUser}
          hasConversation={bookingContext?.hasConversation}
          showCalendar={isShown || myCalendar}
          description={bookingContext?.description}
        />
      )}
      {currentRequest && !accepted && (
        <>
          <Dialog
            header={!isHuman ? null : header}
            isShown={showRequest}
            onCloseComplete={() => {
              setShowRequest(false);
              bookingContext.history.push(`/p/${profile?.slug}`);
            }}
            hasClose={true}
            hasCancel={false}
            hasConfirm={false}
            hasFooter={false}
            preventBodyScrolling={true}
            width="600px"
          >
            {content}
          </Dialog>
          <Button
            disabled={currentRequest && currentRequest?.sessionRequest?.id}
            iconBefore={CalendarIcon}
            appearance={hasConversation && !isCurrentUser ? "default" : "primary"}
            onClick={() => {
              bookingContext.history.push(`/p/${profile?.slug}?request=true`);
              setShowRequest(true);
            }}
          >
            {currentRequest?.sessionRequest?.id ? "Request Pending" : "Send Request"}
          </Button>
        </>
      )}
    </>
  );
});

export default RequestView;
