import React from "react";
import { Row, Col } from "react-grid-system";
import { Pane, Heading, Spinner, majorScale } from "evergreen-ui";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";

import FeedQueries from "../../../queries/feed";
import ShowMore from "../components/ShowMore";
import HoverLink from "../../../components/HoverLink";
import DiscussionItem from "../../../components/discussion/DiscussionItem";

const AnswerCard = withRouter(({ history, comment, myProfile }) => {
  return (
    <Pane>
      {comment?.post?.title.length > 0 && (
        <HoverLink samePage to={`/d/${comment?.post?.id}`}>
          <Heading marginBottom={majorScale(2)}>{comment.post.title}</Heading>
        </HoverLink>
      )}
      <DiscussionItem myProfile={myProfile} comment={comment} post={comment?.post} history={history} hideReplies hideResponses />
    </Pane>
  );
});

const AnswersSection = () => {
  const { data: answersResult } = useQuery(FeedQueries.queries.RecentComments);

  return (
    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%" marginBottom={majorScale(3)}>
            <Heading size={600}>Learn from recent career discussions</Heading>
            <ShowMore to="/discussions" />
          </Pane>
        </Col>
      </Row>
      {answersResult?.recentComments?.edges?.length ? (
        <Row>
          {answersResult?.recentComments?.edges?.slice(0, 2).map((comment) => (
            <Col md={6} id={comment?.id}>
              <AnswerCard hideReplies myProfile={answersResult?.myProfile} comment={comment} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <Pane width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" height={300}>
              <Spinner />
            </Pane>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default AnswersSection;
