import gql from "graphql-tag";
import ProfileList from "../profile/ProfileList";
import QueryUtils from "../utils";

import SessionQueries from "../session";

const ConnectionQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

ConnectionQueries.fragments.ConnectionRecommendation = `
  id,
  question,
  response,
  status,
  createdAt,
  updatedAt,
  owner {
    ${ProfileList.fragments.profile}
  },
  user {
    ${ProfileList.fragments.profile}
  },
`;

ConnectionQueries.fragments.Connection = `
  id,
  connectedAt,
  sessionsCompleted,
  amItheMentor,
  conversationThread {
    id,
  }
  viewing {
    ${ProfileList.fragments.profile}
  },
  recommendation {
    ${ConnectionQueries.fragments.ConnectionRecommendation}
  }
`;

ConnectionQueries.queries.MyConnections = gql`
  query MyConnections($cursor: String) {
    myCoachingProfile {
      id,
      status,
    },
    myConnections(cursor: $cursor) {
      edges {
        ${ConnectionQueries.fragments.Connection}
      },
      ${QueryUtils.PageInfo}
    },
    missingRecommendations {
      ${ConnectionQueries.fragments.Connection}
    }
  }
`;

ConnectionQueries.queries.ConnectionById = gql`
  query ConnectionById($id: String!) {
    myCoachingProfile {
      id,
      status,
    },
    connectionById(id: $id) {
      ${ConnectionQueries.fragments.Connection}
    }
  }
`;

ConnectionQueries.queries.MissingRecommendations = gql`
  query MissingRecommendations {
    missingRecommendations {
      edges {
        ${ConnectionQueries.fragments.Connection}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

ConnectionQueries.queries.SessionsByConnectionId = gql`
  query SessionsByConnectionId($id: String!, $cursor: String, $direction: String) {
    sessionsByConnectionId(id: $id, cursor: $cursor, direction: $direction) {
      edges {
        ${SessionQueries.fragments.session}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

ConnectionQueries.mutations.WriteConnectionRecommendation = gql`
  mutation WriteConnectionRecommendation($connectionId: String, $response: String, $status: String) {
    writeConnectionRecommendation(connectionId: $connectionId, response: $response, status: $status) {
      ${ConnectionQueries.fragments.ConnectionRecommendation}
    }
  }
`;

export default ConnectionQueries;
