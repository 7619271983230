import React from "react";
import { Row, Col, Hidden, Visible, Container } from "react-grid-system";
import { Helmet } from "react-helmet";
import injectSheet from "react-jss";
import ReactGA from "react-ga";
import queryString from "query-string";
import Button from "../../components/button/Button";
import FAQLayout from "../../components/layout/FAQLayout";
import theme from "../../components/theme";
import Testimonial from "../../components/profile/Testimonial";
import EmailSignup from "./EmailSignup";

import {
  benefitsCoaches,
  coachFAQ,
  coachHeadline,
  coachSubtitle,
  coachCTA,
  coachCustomizationImage,
  coachFeedbackImage,
  coachMatchImage,
  coachTestimonials,
  coachLimits,
  coachImpact,
  coachTestimonialTitle
} from "../../content/coaches";

import { getRecruited, recruitingTestimonial } from "../../content/learners";

import { Pane, defaultTheme, majorScale } from "evergreen-ui";

const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

const styles = {
  p: {
    ...theme.p,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  b: {
    ...theme.b,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  h1: {
    ...theme.h1,
    margin: 0,
    textAlign: "center"
  },
  h1Mobile: {
    ...theme.h1Mobile,
    textAlign: "center",
    margin: 0,
    paddingTop: majorScale(4)
  },
  h6: {
    ...theme.h6,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  h2: {
    ...theme.h2,
    textAlign: "left",
    margin: 0,
    padding: 0
  },
  h2Mobile: {
    ...theme.h2Mobile,
    textAlign: "left",
    margin: 0,
    padding: 0
  },
  subtitle: {
    ...theme.b,
    textAlign: "center",
    paddingTop: majorScale(4),
    paddingBottom: majorScale(4)
  },
  subtitleMobile: {
    ...theme.p,
    textAlign: "center",
    paddingTop: majorScale(4),
    paddingBottom: majorScale(4)
  },
  testimonials: {
    paddingTop: theme.spacing.grid * 4,
    paddingBottom: theme.spacing.grid * 3
  },
  tweets: {
    background: theme.color.darkBackground,
    paddingRight: theme.spacing.grid,
    paddingLeft: theme.spacing.grid,
    paddingBottom: theme.spacing.grid * 4
  },
  benefitDescription: {
    ...theme.p,
    margin: 0,
    padding: 0,
    textAlign: "left",
    paddingTop: majorScale(2)
  },
  fullWidth: {
    marginRight: -15,
    marginLeft: -15
  },
  image: {
    width: "100%",
    display: "block",
    marginLeft: "auto"
  }
};

class CoachPage extends React.Component {
  render() {
    ReactGA.initialize(GOOGLE_ANALYTICS);
    ReactGA.pageview("coaches");

    window.analytics.track("Landing Page -> Mentors");

    const { classes } = this.props;
    const {
      location: { search }
    } = this.props;
    const { showGoogle } = queryString.parse(search);

    return (
      <div>
        <link href={theme.fonts} rel="stylesheet" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Become a Mentor | Merit</title>
        </Helmet>
        <div className={classes.fullWidth}>
          <Container>
            <Row align="start" justify="center">
              <Col md={8} xs={12}>
                <Pane
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  padding={majorScale(2)}
                  margin={majorScale(2)}
                >
                  <Hidden xs md>
                    <h1 className={classes.h1}>{coachHeadline}</h1>
                    <div className={classes.subtitle}>{coachSubtitle}</div>
                  </Hidden>
                  <Visible xs md>
                    <h1 className={classes.h1Mobile}>{coachHeadline}</h1>
                    <div className={classes.subtitleMobile}>{coachSubtitle}</div>
                  </Visible>
                  <EmailSignup cta={coachCTA} mode={"Coach"} showGoogle={showGoogle} />
                </Pane>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
              <Hidden xs md>
                <Col md={6}>
                  <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
                    <img src={coachFeedbackImage} alt="We make it easy to make an impact" className={classes.image} />
                  </Pane>
                </Col>
                <Col md={6}>
                  <Pane padding={majorScale(2)} margin={majorScale(2)}>
                    <div className={classes.h2}>{benefitsCoaches[0].title}</div>
                    <div className={classes.benefitDescription}>{benefitsCoaches[0].description}</div>
                  </Pane>
                </Col>
              </Hidden>
              <Visible xs md>
                <Col md={6}>
                  <Pane padding={majorScale(2)} margin={majorScale(2)}>
                    <div className={classes.h2Mobile}>{benefitsCoaches[0].title}</div>
                    <div className={classes.benefitDescription}>{benefitsCoaches[0].description}</div>
                  </Pane>
                </Col>
                <Col md={6}>
                  <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
                    <img src={coachFeedbackImage} className={classes.image} alt={benefitsCoaches[0].title} />
                  </Pane>
                </Col>
              </Visible>
            </Row>

            <Row align="center" justify="center" style={{ paddingBottom: majorScale(6) }}>
              <Col md={6}>
                <Pane padding={majorScale(2)} margin={majorScale(2)}>
                  <Hidden xs md>
                    <div className={classes.h2}>{benefitsCoaches[1].title}</div>
                  </Hidden>
                  <Visible xs md>
                    <div className={classes.h2Mobile}>{benefitsCoaches[1].title}</div>
                  </Visible>
                  <div className={classes.benefitDescription}>{benefitsCoaches[1].description}</div>
                </Pane>
              </Col>
              <Col md={6}>
                <Pane
                  backgroundColor="white"
                  border={`1px solid ${defaultTheme.colors.gray500}`}
                  borderRadius={majorScale(2)}
                  padding={majorScale(4)}
                  marginLeft={majorScale(2)}
                >
                  <img src={coachCustomizationImage} alt="We make it easy to make an impact" className={classes.image} />
                </Pane>
              </Col>
            </Row>

            <Row align="center" justify="center" style={{ paddingBottom: majorScale(2) }}>
              <Hidden xs md>
                <Col md={6}>
                  <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
                    <Testimonial testimonial={recruitingTestimonial} />
                  </Pane>
                </Col>
                <Col md={6}>
                  <Pane padding={majorScale(2)} margin={majorScale(2)}>
                    <div className={classes.h2}>{benefitsCoaches[2].title}</div>
                    <div className={classes.benefitDescription}>{benefitsCoaches[2].description}</div>
                  </Pane>
                </Col>
              </Hidden>
              <Visible xs md>
                <Col md={6}>
                  <Pane padding={majorScale(2)} margin={majorScale(2)}>
                    <div className={classes.h2Mobile}>{benefitsCoaches[2].title}</div>
                    <div className={classes.benefitDescription}>{benefitsCoaches[2].description}</div>
                  </Pane>
                </Col>
                <Col md={6}>
                  <Pane
                    backgroundColor="white"
                    border={`1px solid ${defaultTheme.colors.gray500}`}
                    borderRadius={majorScale(2)}
                    padding={majorScale(2)}
                    margin={majorScale(2)}
                  >
                    <img src={getRecruited} alt={benefitsCoaches[2].title} className={classes.image} />
                  </Pane>
                </Col>
              </Visible>
            </Row>

            <Row justify="center">
              <Col xs={12} md={10}>
                <Pane padding={majorScale(2)} margin={majorScale(2)}>
                  <div className={classes.h2}>{coachTestimonialTitle}</div>
                </Pane>
              </Col>
              {coachTestimonials.map((testimonial) => (
                <Col xs={12} sm={6} md={4}>
                  <Testimonial testimonial={testimonial} marginBottom />
                </Col>
              ))}
            </Row>
          </Container>
          <Container>
            <FAQLayout title="Frequently asked questions" faq={coachFAQ} />
          </Container>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(CoachPage);
