import React from "react";
import qs from "query-string";
import {
  Alert,
  Button,
  EditIcon,
  Heading,
  InlineAlert,
  Pane,
  Paragraph,
  RemoveIcon,
  StarIcon,
  majorScale,
  minorScale,
  defaultTheme as theme,
  toaster
} from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";
import { fromUnixTime, format } from "date-fns";
import { Formik, Form, Field } from "formik";

import ConnectionQueries from "../../queries/connection";
import { showSuccess, isMentor } from "../../components/session/utils";
import ConnectionCard from "../../components/connection/ConnectionCard";
import { Col, ScreenClassRender } from "react-grid-system";
import TextArea from "../../components/form/TextArea";
import _ from "lodash";

const MENTOR_QUESTION = "Would you want to work with this person? What are their strengths? Weaknesses?";
const MENTOR_RESPONSE = "Feel free to be honest! Only prospective recruiters will see this recommendation.";

const MENTEE_QUESTION = "How has it been chatting with them?";
const MENTEE_RESPONSE = "This public feedback you provide helps them look good to their bosses and prospective employers.";

const MENTOR_PLACEHOLDER =
  "They are super coachable - they did everything I recommended in the previous session, including following up and starting their networking journey. I'm confident they'll go on to do great things!";
const MEMBER_PLACEHOLDER =
  "They know what they're talking about. For any questions, they were able to look from completely different angles. Why? Because they have been there before and know what works in each case. I loved having the opportunity to lead from them. Thank you!";

export const showWarning = _.debounce((msg) => toaster.notify("You declined to recommend.", { duration: 2, id: "report" }), 1000, {
  maxWait: 3000
});

const MissingRecommendation = ({ viewing }) => {
  const copy = `You haven't recommended recently connected mentor ${viewing?.fullName}. Please provide, or decline, a recommendation for them to continue using Merit. Passing it forward helps them look good to their current and future employers!`;

  return (
    <Pane display="flex" flexDirection={"row"} justifyContent="space-between" paddingTop={majorScale(4)} width="100%">
      <Alert width="100%" title={copy} />
    </Pane>
  );
};

const ConnectionRecommendationForm = ({ history, connection, existingRecommendation }) => {
  const [writeConnectionRecommendation, { loading: saving }] = useMutation(ConnectionQueries.mutations.WriteConnectionRecommendation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ConnectionQueries.queries.ConnectionById,
        variables: {
          id: connection.id
        }
      },
      { query: ConnectionQueries.queries.MyConnections }
    ],
    onCompleted: ({ writeConnectionRecommendation }) => {
      if (_.isEqual(writeConnectionRecommendation?.status, "Rejected")) {
        showWarning("Recommendation declined.");
      } else {
        showSuccess("Recommendation saved.");
      }

      history.push(`/connections`);
    }
  });

  const CTA = existingRecommendation?.id ? "Update" : "Submit";
  const isMentor = connection?.amItheMentor;

  return (
    <Formik
      initialValues={{
        response: existingRecommendation ? existingRecommendation?.response : "",
        status: existingRecommendation ? existingRecommendation?.status : "Inactive",
        connectionId: connection?.id
      }}
      onSubmit={({ response, status, connectionId }) => {
        writeConnectionRecommendation({
          variables: {
            response,
            status: !_.isEqual(status, "Rejected") ? "Completed" : status,
            connectionId
          }
        });
      }}
      render={({ setFieldValue, submitForm }) => (
        <Form>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(2)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Pane marginBottom={majorScale(2)}>
                  <Heading size={500}>{isMentor ? MENTOR_QUESTION : MENTEE_QUESTION}</Heading>
                  <Paragraph size={400}>{isMentor ? MENTOR_RESPONSE : MENTEE_RESPONSE}</Paragraph>
                </Pane>
                <Pane>
                  <Field
                    name={"response"}
                    type="textarea"
                    defaultValue={existingRecommendation?.response}
                    component={TextArea}
                    placeholder={isMentor ? MENTOR_PLACEHOLDER : MEMBER_PLACEHOLDER}
                  />
                </Pane>
                <Pane display="flex" flexDirection="row" justifyContent="space-between" paddingTop={majorScale(2)}>
                  <Button
                    isLoading={saving}
                    iconBefore={RemoveIcon}
                    type="submit"
                    appearance="minimal"
                    intent="none"
                    onClick={() => setFieldValue("status", "Rejected", true)}
                  >
                    Decline to recommend
                  </Button>
                  <Button
                    isLoading={saving}
                    iconBefore={EditIcon}
                    type="submit"
                    appearance="primary"
                    onClick={() => setFieldValue("status", "Completed", true)}
                  >
                    {`${CTA} recommendation`}
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const ConnectionRecommendationFormContainer = ({
  history,
  location,
  match: {
    params: { id, state }
  }
}) => {
  const { missingRecommendation } = qs.parse(location?.search, { arrayFormat: "comma" });
  const { data: connectionResult } = useQuery(ConnectionQueries.queries.ConnectionById, { variables: { id } });

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
            {missingRecommendation && <MissingRecommendation viewing={connectionResult?.connectionById?.viewing} />}

            <Pane
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={majorScale(4)}
              marginBottom={majorScale(1)}
              width="100%"
            >
              <Heading display="flex" alignItems="center" size={700}>
                <StarIcon size={majorScale(2)} marginRight={majorScale(1)} />
                Write a recommendation
              </Heading>
            </Pane>

            <Pane display="flex" width="100%" marginBottom={isMobile ? minorScale(1) : majorScale(3)}>
              {connectionResult?.connectionById?.recommendation?.status === "Completed" && (
                <Alert title="You have already submitted this recommendation, however you can still update it." width="100%" />
              )}
              {connectionResult?.connectionById?.recommendation?.status === "Rejected" && (
                <Alert
                  title="You have already declined to recommend. However you can still update it with a new recommendation."
                  intent="warning"
                  width="100%"
                />
              )}
            </Pane>

            {connectionResult?.connectionById && (
              <Pane width="100%" justifyContent="center" display="flex" flexDirection="column">
                <ConnectionCard connection={connectionResult?.connectionById} hideActions recommendation />
              </Pane>
            )}

            {connectionResult?.connectionById?.id && (
              <ConnectionRecommendationForm
                history={history}
                connection={connectionResult?.connectionById}
                existingRecommendation={connectionResult?.connectionById?.recommendation}
              />
            )}
          </Col>
        );
      }}
    />
  );
};

export default ConnectionRecommendationFormContainer;
