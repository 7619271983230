import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { isString, isNumber } from "lodash";
import { withRouter } from "react-router-dom";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Pane, Paragraph, Heading, Spinner, majorScale, TabNavigation, Tab, minorScale, defaultTheme as theme } from "evergreen-ui";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";

import CandidateQueries from "../../queries/candidate";
import CandidateCard from "../../components/candidate/CandidateCard";
import CandidateFilters from "../../components/candidate/CandidateFilters";
import emptyPhoto from "../../images/empty-chat.svg";

const TITLE = "Find someone for your team";

const getArray = (item = []) => (item && isString(item) ? [item] : item);

export const INITIAL_FILTERS = {
  roles: [],
  experience: [],
  skills: [],
  industry: [],
  companySize: [],
  lat: undefined,
  lon: undefined,
  remote: false,
  relocation: false,
  status: "active"
};

const getFiltersFromURL = (location) => {
  const params = queryString.parse(location.search, { arrayFormat: "comma" });

  return {
    roles: getArray(params?.roles),
    experience: getArray(params?.experience),
    skills: getArray(params?.skills),
    industry: getArray(params?.industry),
    frameworks: getArray(params?.frameworks),
    companySize: getArray(params?.companySize),
    lat: isNumber(params?.lat) ? parseFloat(params?.lat) : undefined,
    lon: isNumber(params?.lon) ? parseFloat(params?.lon) : undefined,
    remote: params?.remote?.includes("true") ? true : false,
    relocation: params?.relocation?.includes("true") ? true : false,
    status: params?.status || "active"
  };
};

const updateFiltersInURL = (history, updatedFilters) => {
  const stringified = queryString.stringify(updatedFilters, {
    arrayFormat: "comma"
  });
  history.push({ search: stringified });
};

const EmptySearchView = () => {
  const title = "No candidates found";
  const details = "Update your search to find more candidates!";

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
    </Pane>
  );
};

const CandidateTable = ({ pageInfo, candidates, fetchMore, myRecruiterProfile, onUpdateCard, isMobile }) => {
  const _candidates = candidates.filter((m) => m.user);

  return (
    <Pane marginTop={majorScale(2)}>
      {_candidates && _candidates.length > 0 ? (
        <InfiniteScroll
          hasMore={pageInfo?.hasNextPage}
          dataLength={_candidates.length}
          loader={
            <Row>
              <Col xs={12}>
                <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <Spinner />
                </Pane>
              </Col>
            </Row>
          }
          next={fetchMore}
          style={{ overflow: "hidden" }}
        >
          <Row>
            {_candidates.map((candidate) => (
              <Col xs={12} key={candidate?.id}>
                <CandidateCard candidate={candidate} myRecruiterProfile={myRecruiterProfile} onUpdate={onUpdateCard} isMobile={isMobile} />
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      ) : (
        <EmptySearchView />
      )}
    </Pane>
  );
};

const CandidateIndexPage = ({ location, history }) => {
  const filtersFromURL = getFiltersFromURL(location);
  const [selectedFilters, setSelectedFilters] = useState(filtersFromURL);

  const { data: recruiterResult } = useQuery(CandidateQueries.queries.MyRecruiterProfile);
  const { data: filterData } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const {
    data: candidateResult,
    fetchMore,
    loading,
    refetch
  } = useQuery(CandidateQueries.queries.CandidateSearch, {
    variables: {
      cursor: "1",
      ...selectedFilters
    }
  });

  if (recruiterResult && !recruiterResult.myRecruiterProfile) {
    history.push("/dashboard");
  }

  const fetchMoreCallback = (prev, { fetchMoreResult }) => {
    return fetchMoreResult
      ? {
          ...prev,
          candidateSearch: {
            __typename: fetchMoreResult.candidateSearch.__typename,
            pageInfo: fetchMoreResult.candidateSearch.pageInfo,
            edges: [...prev.candidateSearch.edges, ...fetchMoreResult.candidateSearch.edges]
          }
        }
      : prev;
  };

  const handleFetchMore = () =>
    fetchMore({
      variables: {
        cursor: candidateResult?.candidateSearch?.pageInfo?.nextPage
      },
      updateQuery: fetchMoreCallback
    });

  const handleFilterUpdate = (type, value) => {
    const updatedFilters = { ...selectedFilters, [type]: value };
    setSelectedFilters(updatedFilters);
    updateFiltersInURL(history, updatedFilters);
  };

  const onLocationUpdate = (formattedLocation, inputRef) => {
    const updatedFilters = {
      ...selectedFilters,
      lat: formattedLocation?.lat,
      lon: formattedLocation?.long
    };

    setSelectedFilters(updatedFilters);
    updateFiltersInURL(history, updatedFilters);
  };

  const handleFilterReset = () => {
    setSelectedFilters(INITIAL_FILTERS);
    updateFiltersInURL(history, INITIAL_FILTERS);
  };

  const isViewingActive = selectedFilters.status === "active";

  const { companySize, industry } = _.reduce(
    filterData?.availableTeamFeatureCategories,
    (acc, category) => {
      acc[category.value] = _.chain(filterData?.availableTeamFeatures)
        .filter({ category: category.value })
        .map((feature) => ({ label: feature.attribute, value: feature.attribute }))
        .value();

      return acc;
    },
    {}
  );
  const skills = _.map(filterData?.availableSkills, ({ attribute }) => ({ label: attribute, value: attribute }));
  const frameworks = _.map(filterData?.availableFrameworks, (value) => ({ label: value, value }));

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <Pane width="100%">
            <Row>
              <Col xs={12} sm={3} lg={3}>
                <CandidateFilters
                  selectedFilters={selectedFilters}
                  filterData={{ ...filterData, companySize, industry, skills, frameworks }}
                  onFilterUpdate={handleFilterUpdate}
                  onFiltersReset={handleFilterReset}
                  onLocationUpdate={onLocationUpdate}
                />
              </Col>
              <Col xs={12} sm={9} lg={9}>
                <Pane
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  marginTop={majorScale(4)}
                  marginBottom={majorScale(1)}
                >
                  <Heading is="h1" size={800} marginBottom={majorScale(1)}>
                    {candidateResult?.candidateSearch?.pageInfo.edgeCount > 0
                      ? `Search results (${candidateResult?.candidateSearch?.pageInfo.edgeCount})`
                      : TITLE}
                  </Heading>
                  <TabNavigation>
                    <Tab
                      id="active"
                      onClick={() => handleFilterUpdate("status", "active")}
                      appearance="minimal"
                      isSelected={isViewingActive}
                      marginRight={majorScale(1)}
                      paddingY={minorScale(5)}
                      fontWeight={500}
                      size={400}
                      color={isViewingActive ? "#3366FF" : "#696F8C"}
                      borderBottom={isViewingActive ? "2px solid #3366FF" : "2px solid white"}
                    >
                      Active
                    </Tab>
                    <Tab
                      id="passive"
                      onClick={() => handleFilterUpdate("status", "passive")}
                      appearance="minimal"
                      isSelected={!isViewingActive}
                      marginRight={majorScale(1)}
                      paddingY={minorScale(5)}
                      fontWeight={500}
                      size={400}
                      color={!isViewingActive ? "#3366FF" : "#696F8C"}
                      borderBottom={!isViewingActive ? "2px solid #3366FF" : "2px solid white"}
                    >
                      Passive
                    </Tab>
                  </TabNavigation>
                </Pane>
                {!candidateResult?.candidateResult?.pageInfo?.edgeCount > 0 ? (
                  <>
                    {loading ? (
                      <Pane>
                        <Row style={{ height: 360 }}>
                          <Col xs={12} style={{ height: "100%" }}>
                            <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center" height="100%">
                              <Spinner />
                            </Pane>
                          </Col>
                        </Row>
                      </Pane>
                    ) : (
                      <CandidateTable
                        pageInfo={candidateResult?.candidateSearch?.pageInfo}
                        candidates={candidateResult?.candidateSearch?.edges}
                        fetchMore={handleFetchMore}
                        myRecruiterProfile={recruiterResult?.myRecruiterProfile}
                        onUpdateCard={refetch}
                        isMobile={isMobile}
                      />
                    )}
                  </>
                ) : (
                  <CandidateTable
                    pageInfo={candidateResult?.candidateSearch?.pageInfo}
                    candidates={candidateResult?.candidateSearch?.edges}
                    fetchMore={handleFetchMore}
                    myRecruiterProfile={recruiterResult?.myRecruiterProfile}
                    onUpdateCard={refetch}
                    isMobile={isMobile}
                  />
                )}
              </Col>
            </Row>
          </Pane>
        );
      }}
    />
  );
};

export default withRouter(CandidateIndexPage);
