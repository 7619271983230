import _ from "lodash";
import React from "react";
import { Tab, Pane, Tablist, majorScale, Textarea, toaster, Button, Heading, Text, Card } from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";

const PRODUCT = "🛠 \u00a0 Product";
const ENGINEERING = "🤖 \u00a0 Engineering";
const DESIGN = "🖌 \u00a0 Design";
const ALL = "👥 \u00a0 All";

const SHARE_HEADER = `✨ Share in your communities`;
const SHARE_MOTIVATION = `If you had a great experinece with Merit, please share with your co-workers and friends! The more people use Merit, the more topics, leaders and conversations you can have.`;

class CommunityShare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndexRole: 0,
      roleTabs: [PRODUCT, ENGINEERING, DESIGN, ALL]
    };
  }

  renderCopyButton(copy, label) {
    const handler = (e) => {
      this.setState({ linkCopied: true }, () => {
        navigator.clipboard.writeText(copy);
        toaster.success(`Copied to clipboard!`, { duration: 2 });
      });
    };

    return <Button onClick={handler}>{label}</Button>;
  }

  renderHeader(title, context) {
    return (
      <Pane padding={majorScale(1)} paddingBottom={majorScale(2)}>
        <Heading paddingBottom={majorScale(2)}>{title}</Heading>
        <Text>{context}</Text>
      </Pane>
    );
  }

  renderInstallMeritSocial(instructions, profileLink, mode) {
    const { redirect, redirectBtn } = this.props;

    return (
      <Pane isplay="flex" flexDirection="row" justifyContent="start" alignItems="start">
        {instructions ? <Pane paddingBottom={majorScale(2)}>{instructions}</Pane> : null}
        <Pane padding={majorScale(1)}>
          <Textarea value={profileLink} style={{ resize: "none" }} height={140} />
        </Pane>
        <Pane display="flex" justifyContent={mode ? "flex-end" : "space-between"} padding={majorScale(1)}>
          {this.props.redirect && this.props.redirectBtn ? (
            <Button appearance="minimal" is={BrowserLink} to={this.props.redirect}>
              {this.props.redirectBtn}
            </Button>
          ) : null}
          {this.renderCopyButton(profileLink, `🔗  \u00a0 Copy`)}
        </Pane>
      </Pane>
    );
  }

  render() {
    const { mode, motivation, header } = this.props;

    const productBlurb = `Merit is the place for impactful career conversations. New PMs use it to learn how to launch products, practice mock interviewing, review resume feedback and learning more about PM at different companies. Talk to leaders here: https://www.get-merit.com/topics/all?cursor=1&roles=Product%20Management`;
    const engineeringBlurb = `Merit is the place for impactful career conversations. Navigate career challenges like deciding between technical and management paths, leading technical projects and asking for a raise or promotion. Talk to leaders here: https://www.get-merit.com/topics/all?cursor=1&roles=Software%20Engineering`;
    const designBlurb = `Merit is the place for impactful career conversations. New designers use it to user research and UX skills, get career advice and learn about new roles and industries. Talk to leaders here: https://www.get-merit.com/topics/all?cursor=1&roles=Product%20Design`;
    const allBlurb = `Merit is the place for impactful career conversations. Learn hard skills like user research and prototyping, get career advice and learn about new roles and industries. Talk to leaders here: https://www.get-merit.com/topics/all?cursor=1`;

    const sharingMappings = [
      { title: PRODUCT, pane: this.renderInstallMeritSocial(null, productBlurb, mode) },
      { title: ENGINEERING, pane: this.renderInstallMeritSocial(null, engineeringBlurb, mode) },
      { title: DESIGN, pane: this.renderInstallMeritSocial(null, designBlurb, mode) },
      { title: ALL, pane: this.renderInstallMeritSocial(null, allBlurb, mode) }
    ];

    const motivationCopy = this.props.motivation ? motivation : SHARE_MOTIVATION;
    const headerCopy = this.props.header ? header : SHARE_HEADER;

    return (
      <Card elevation={1} padding={majorScale(2)} margin={majorScale(2)}>
        <Pane display="flex" flexDirection="row" justifyContent="start" alignItems="start">
          <Pane>
            {this.renderHeader(headerCopy, motivationCopy)}
            <Tablist>
              {this.state.roleTabs.map((tab, index) => (
                <Tab
                  key={tab}
                  id={tab}
                  onSelect={() => this.setState({ selectedIndexRole: index })}
                  isSelected={index === this.state.selectedIndexRole}
                  aria-controls={`panel-${tab}`}
                >
                  {sharingMappings[index].title}
                </Tab>
              ))}
            </Tablist>
            <Pane marginTop={majorScale(2)}>
              {this.state.roleTabs.map((tab, index) => (
                <Pane
                  key={tab}
                  id={`panel-${tab}`}
                  role="tabpanel"
                  aria-labelledby={tab}
                  aria-hidden={index !== this.state.selectedIndexRole}
                  display={index === this.state.selectedIndexRole ? "block" : "none"}
                >
                  {sharingMappings[index].pane}
                </Pane>
              ))}
            </Pane>
          </Pane>
        </Pane>
      </Card>
    );
  }
}

export default CommunityShare;
