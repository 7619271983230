import _ from "lodash";
import React from "react";
import { InlineAlert, ThumbsUpIcon, ThumbsDownIcon, Text, Pane, Paragraph, Heading, majorScale, defaultTheme as theme } from "evergreen-ui";

import { ProfileOverview, SessionDetail } from "./SessionCard";

const TESTIMONIAL = "Write a public testimonial";

const Response = ({ response }) => (
  <>
    {response?.answer?.length > 0 && (
      <Pane marginTop={majorScale(1)}>
        <Heading size={400} marginBottom={majorScale(1)}>
          {_.isEqual(response?.name, "testimonial") ? TESTIMONIAL : response.question}
        </Heading>
        <Paragraph size={500}>{response.answer}</Paragraph>
      </Pane>
    )}
  </>
);

const Rating = ({ rating }) => {
  let badge = _.isEqual(rating, 5)
    ? {
        icon: theme.colors.green400,
        text: theme.colors.green600,
        copy: "It was great!",
        btn: ThumbsUpIcon
      }
    : {
        icon: "#FFB020", // not exported in theme for some reason
        text: "#996A13",
        copy: "Not so great",
        btn: ThumbsDownIcon
      };

  const Icon = badge.btn;

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} marginTop={majorScale(1)}>
      <Heading size={400}>{"How was the session?"}</Heading>
      <Pane display="flex" alignItems="center">
        <Pane display="flex" marginRight={majorScale(2)}>
          <Icon color={badge.icon} />
        </Pane>
        <Text size={500} color={badge.text}>
          {badge.copy}
        </Text>
      </Pane>
    </Pane>
  );
};

const FeedbackCard = ({ myProfile, feedback, hideSession }) => {
  return (
    <Pane display="flex" flexDirection="column" width="100%" backgroundColor="white">
      {!hideSession && <ProfileOverview session={feedback?.session} myProfile={myProfile} hideMsgs={true} showDetail />}
      {!hideSession && <SessionDetail myProfile={myProfile} session={feedback?.session} hideDetail />}
      <Rating rating={feedback?.rating} />
      {feedback.responses.map((response) => (
        <Response response={response} />
      ))}
    </Pane>
  );
};

export default FeedbackCard;
