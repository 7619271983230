import _ from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import {
  Alert,
  Button,
  CalendarIcon,
  SearchIcon,
  Heading,
  Pane,
  Paragraph,
  Pill,
  Spinner,
  ShareIcon,
  Tab,
  TabNavigation,
  CaretUpIcon,
  CaretDownIcon,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { Col, ScreenClassRender } from "react-grid-system";
import { Redirect, Link as BrowserLink } from "react-router-dom";
import qs from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";

import SessionQueries from "../../queries/session";
import SessionCard from "../../components/session/SessionCard";
import FeedbackCard from "../../components/session/FeedbackCard";
import RequestPreview from "../../components/session/RequestPreview";
import OfferCard from "../../components/session/OfferCard";
import SessionPreview from "../../components/session/SessionPreview";
import LoadingView from "../profile/components/LoadingView";
import emptyPhoto from "../../images/empty-chat.svg";

const ERROR_MSG = "There was an error loading this page. Please refresh in a few moments.";
const MISSING_REVIEW_PROMPT = "You haven’t reviewed your recent sessions. Please leave a review before you can use Merit.";

const Meta = ({ direction }) => {
  const title = _.isEqual(direction, "upcoming") ? "Upcoming Sessions | Merit" : "Previous Sessions | Merit";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:url" property="og:url" content={window.location.href} />
      <meta name="twitter:title" property="twitter:title" content={title} />
    </Helmet>
  );
};

const isCoach = ({ status }) => {
  return _.isEqual(status, "Active") || _.isEqual(status, "Inactive") || _.isEqual(status, "Pending");
};

const EmptyFeedbackView = () => {
  const title = "No feedback... yet!";
  const details = "Mentor others in tech today by helping mentees with their career questions.";
  const cta = "Explore Members";
  let link = "/members";

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
      <Button appearance="primary" iconBefore={SearchIcon} is={BrowserLink} to={link} marginBottom={majorScale(1)}>
        {cta}
      </Button>
    </Pane>
  );
};

const EmptySessionView = ({ myCoachingProfile }) => {
  let title = "No sessions booked... yet!";
  let details = "Get career help today by booking a session with an available mentor";
  let cta = "Explore Mentors";
  let link = "/discover";

  if (myCoachingProfile?.id && isCoach(myCoachingProfile)) {
    title = "No sessions booked... yet!";
    details = "Mentor others in tech today by helping mentees with their career questions.";
    cta = "Explore Members";
    link = "/members";
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <img
        src={emptyPhoto}
        alt="empty profile"
        style={{
          width: "192px",
          opacity: 0.9,
          marginBottom: majorScale(1)
        }}
      />
      <Heading size={600} marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>{details}</Paragraph>
      <Button appearance="primary" iconBefore={SearchIcon} is={BrowserLink} to={link} marginBottom={majorScale(1)}>
        {cta}
      </Button>
    </Pane>
  );
};

const MyFeedbackRequestDetail = ({ myProfile, missingReview }) => {
  const [showMore, setShowMore] = useState(false);
  let title = "My Feedback Requests";

  if (missingReview?.length > 0) {
    title = `${title} (${missingReview?.length})`;
  }

  const cutoff = 3;
  const numItems = showMore ? missingReview?.length : cutoff;

  return (
    <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
      <Pane display="flex" justifyContent="space-between">
        <Heading size={500} marginBottom={majorScale(2)}>
          {title}
        </Heading>
        {missingReview?.length > cutoff && (
          <Button paddingX="10px" color="#3366FF" appearance="minimal" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "See more"}
          </Button>
        )}
      </Pane>
      <Pane justifyContent="center" display="flex" flexDirection="column">
        {missingReview?.slice(0, numItems)?.map((session, index) => (
          <SessionPreview key={`FeedbackRequest-${session?.id}`} session={session} myProfile={myProfile} />
        ))}
      </Pane>
    </Col>
  );
};

const MySessionOffersDetail = ({ myProfile, myOffers, title }) => {
  const [showMore, setShowMore] = useState(false);

  const cutoff = 3;
  const numItems = showMore ? myOffers?.edges?.length : cutoff;

  return (
    <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
      <Pane display="flex" justifyContent="space-between">
        <Heading size={600} marginBottom={majorScale(3)}>
          {title}
        </Heading>
        {myOffers?.edges?.length > cutoff && (
          <Button paddingX="10px" color="#3366FF" appearance="minimal" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "See more"}
          </Button>
        )}
      </Pane>
      <Pane justifyContent="center" display="flex" flexDirection="column">
        {myOffers?.edges?.slice(0, numItems)?.map((offer, index) => (
          <OfferCard myProfile={myProfile} offer={offer} />
        ))}
      </Pane>
    </Col>
  );
};

const MyRequestsDetail = ({ myProfile, myRequests, myPendingRequests, fetchMore, title, query, typeName }) => {
  const [showMore, setShowMore] = useState(false);
  const [showPendingRequests, setPendingRequests] = useState(false);

  const next = () =>
    fetchMore({
      query,
      variables: {
        cursor: myRequests?.pageInfo?.nextPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          [typeName]: {
            __typename: fetchMoreResult[typeName].__typename,
            pageInfo: fetchMoreResult[typeName].pageInfo,
            edges: [...prev[typeName].edges, ...fetchMoreResult[typeName].edges]
          }
        });
      }
    });

  if (myRequests?.pageInfo?.edgeCount > 0) {
    title = `${title} (${myRequests?.pageInfo?.edgeCount})`;
  }

  const cutoff = 3;
  const numItems = showMore ? myRequests?.edges?.length : cutoff;

  return (
    <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
      <Pane display="flex" justifyContent="space-between">
        <Heading size={600} marginBottom={majorScale(3)}>
          {title}
        </Heading>
        {myRequests?.edges?.length > cutoff && (
          <Button paddingX="10px" color="#3366FF" appearance="minimal" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "See more"}
          </Button>
        )}
      </Pane>
      <Pane display="flex" justifyContent="center" flexDirection="column">
        {showMore ? (
          <InfiniteScroll
            hasMore={myRequests?.pageInfo?.hasNextPage}
            dataLength={myRequests?.edges?.length}
            loader={
              <Pane display="flex" flexDirection="row" width="100%" justifyContent="center" alignItems="center">
                <Spinner />
              </Pane>
            }
            next={next}
          >
            {myRequests?.edges?.slice(0, numItems)?.map((request, index) => (
              <RequestPreview key={`RequestPreview-${request?.id}`} request={request} myProfile={myProfile} />
            ))}
          </InfiniteScroll>
        ) : (
          <>
            {myRequests?.edges?.slice(0, numItems)?.map((request, index) => (
              <RequestPreview key={`RequestPreview-${request?.id}`} request={request} myProfile={myProfile} />
            ))}
          </>
        )}
      </Pane>
      {myPendingRequests?.edges?.length > 0 && (
        <>
          <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginY={majorScale(1)}>
            <Button
              appearance="minimal"
              onClick={() => setPendingRequests(!showPendingRequests)}
              iconBefore={showPendingRequests ? CaretDownIcon : CaretUpIcon}
            >
              {showPendingRequests ? "Hide" : "Show"} Pending Requests
            </Button>
          </Pane>
          {showPendingRequests && (
            <Pane>
              {myPendingRequests?.edges?.length > 0 && (
                <InfiniteScroll
                  hasMore={myPendingRequests?.pageInfo?.hasNextPage}
                  dataLength={myPendingRequests?.edges?.length}
                  loader={
                    <Pane display="flex" flexDirection="row" width="100%" justifyContent="center" alignItems="center">
                      <Spinner />
                    </Pane>
                  }
                  next={next}
                >
                  {myPendingRequests?.edges?.map((request, index) => (
                    <RequestPreview key={`RequestPreview-${request?.id}`} request={request} myProfile={myProfile} />
                  ))}
                </InfiniteScroll>
              )}
            </Pane>
          )}
        </>
      )}
    </Col>
  );
};

const FeedbackListDetail = ({ myProfile, myFeedback, fetchMore }) => {
  const next = () =>
    fetchMore({
      query: SessionQueries.queries.MyFeedback,
      variables: {
        cursor: myFeedback?.pageInfo?.nextPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          myFeedback: {
            __typename: fetchMoreResult.myFeedback.__typename,
            pageInfo: fetchMoreResult.myFeedback.pageInfo,
            edges: [...prev.myFeedback.edges, ...fetchMoreResult.myFeedback.edges]
          }
        });
      }
    });

  let title = "Feedback";
  const subheader =
    "Below is feedback you’ve received from prior mentorship sessions. On your public profile we only deplay your positive feedback.";

  if (myFeedback?.pageInfo?.edgeCount > 0) {
    title = `${title} (${myFeedback.pageInfo?.edgeCount})`;
  }

  return (
    <>
      <Pane display="flex" width="100%" flexDirection="column" marginBottom={majorScale(3)}>
        <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={majorScale(1)}>
          <Heading size={600}>{title}</Heading>
          <Button appearance="minimal" iconBefore={ShareIcon} is="a" href={`/p/${myProfile?.slug}`} target="_blank">
            View on profile
          </Button>
        </Pane>
        <Paragraph size={500}>{subheader}</Paragraph>
      </Pane>
      <Pane justifyContent="center" display="flex" flexDirection="column">
        <InfiniteScroll
          hasMore={myFeedback?.pageInfo?.hasNextPage}
          dataLength={myFeedback?.edges?.length}
          loader={
            <Pane display="flex" justifyContent="center" alignItems="center">
              <Spinner />
            </Pane>
          }
          next={next}
        >
          {myFeedback?.edges?.map((feedback) => (
            <Pane
              display="flex"
              flexDirection="column"
              width="100%"
              backgroundColor="white"
              border={`1px solid ${theme.colors.gray500}`}
              borderRadius={majorScale(2)}
              marginBottom={majorScale(4)}
              paddingX={majorScale(4)}
              paddingY={majorScale(4)}
            >
              <FeedbackCard myProfile={myProfile} feedback={feedback} />
            </Pane>
          ))}
        </InfiniteScroll>
      </Pane>
    </>
  );
};

const SessionListDetail = ({ direction, mySessions, myProfile, fetchMore }) => {
  const next = () =>
    fetchMore({
      query: SessionQueries.queries.MySessions,
      variables: {
        direction,
        cursor: mySessions?.pageInfo?.nextPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          mySessions: {
            __typename: fetchMoreResult.mySessions.__typename,
            pageInfo: fetchMoreResult.mySessions.pageInfo,
            edges: [...prev.mySessions.edges, ...fetchMoreResult.mySessions.edges]
          }
        });
      }
    });

  let title = "Sessions";

  if (mySessions?.pageInfo?.edgeCount > 0) {
    title = `${title} (${mySessions?.pageInfo?.edgeCount})`;
  }

  return (
    <>
      <Pane display="flex" width="100%">
        <Heading size={600} marginBottom={majorScale(2)}>
          {title}
        </Heading>
      </Pane>
      <Pane justifyContent="center" display="flex" flexDirection="column">
        <InfiniteScroll
          hasMore={mySessions?.pageInfo?.hasNextPage}
          dataLength={mySessions?.edges?.length}
          loader={
            <Pane display="flex" justifyContent="center" alignItems="center">
              <Spinner />
            </Pane>
          }
          next={next}
        >
          {mySessions?.edges?.map((session) => (
            <SessionCard myProfile={myProfile} session={session} direction={direction} showWarnings />
          ))}
        </InfiniteScroll>
      </Pane>
    </>
  );
};

const IncompleteSessionList = ({ myProfile }) => {
  const [showIncomplete, hideIncomplete] = useState(false);
  const { data: myIncompleteResult, fetchMore } = useQuery(SessionQueries.queries.MyIncompleteSessions, {});

  let myIncompleteSessions;

  if (myIncompleteResult) {
    myIncompleteSessions = myIncompleteResult?.myIncompleteSessions;
  }

  const next = () =>
    fetchMore({
      query: SessionQueries.queries.MyIncompleteSessions,
      variables: {
        cursor: myIncompleteResult?.myIncompleteSessions?.pageInfo?.nextPage
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          myIncompleteSessions: {
            __typename: fetchMoreResult.myIncompleteSessions.__typename,
            pageInfo: fetchMoreResult.myIncompleteSessions.pageInfo,
            edges: [...prev.myIncompleteSessions.edges, ...fetchMoreResult.myIncompleteSessions.edges]
          }
        });
      }
    });

  return (
    <>
      {myIncompleteSessions?.edges?.length > 0 && (
        <>
          <Pane display="flex" width="100%" justifyContent="center" marginBottom={majorScale(4)} paddingTop={majorScale(4)}>
            {showIncomplete ? (
              <Button appearance="minimal" iconBefore={CaretDownIcon} onClick={() => hideIncomplete(false)}>
                Hide Incomplete Sessions
              </Button>
            ) : (
              <Button appearance="minimal" iconBefore={CaretUpIcon} onClick={() => hideIncomplete(true)}>
                Show Incomplete Sessions
              </Button>
            )}
          </Pane>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            {showIncomplete && (
              <InfiniteScroll
                next={next}
                hasMore={myIncompleteResult?.myIncompleteSessions?.pageInfo?.hasNextPage}
                dataLength={myIncompleteResult?.myIncompleteSessions?.edges?.length}
                loader={
                  <Pane display="flex" flexDirection="row" width="100%" justifyContent="center" alignItems="center">
                    <Spinner />
                  </Pane>
                }
              >
                {myIncompleteSessions?.edges.map((session) => (
                  <SessionCard myProfile={myProfile} session={session} direction={"previous"} hideActions />
                ))}
              </InfiniteScroll>
            )}
          </Pane>
        </>
      )}
    </>
  );
};

const isActiveMentor = (sessionsResult) => {
  return sessionsResult?.myCoachingProfile?.id && ["Active", "Pending", "Inactive"].includes(sessionsResult?.myCoachingProfile?.status);
};

const MyActivityPage = ({
  history,
  location,
  match: {
    params: { direction }
  }
}) => {
  const [options, setOptions] = useState(null);
  const {
    data: sessionsResult,
    error,
    fetchMore,
    loading
  } = useQuery(SessionQueries.queries.MySessions, {
    variables: {
      direction: ["previous", "upcoming"].includes(direction) ? direction : "previous"
    }
  });

  const feedbackQuery = useQuery(SessionQueries.queries.MyFeedback);

  const { data: myReceivedResult, fetchMore: fetchMoreReceived } = useQuery(SessionQueries.queries.MyReceivedRequests);

  const { data: myRequestsResult, fetchMore: fetchMoreRequests } = useQuery(SessionQueries.queries.MySessionRequests);

  const { data: myFeedbackRequests, fetchMore: fetchMoreMissingReviews } = useQuery(SessionQueries.queries.MissingReviews);

  const { data: myPendingRequestsResult } = useQuery(SessionQueries.queries.MyPendingRequests);

  const { data: myOffersResult } = useQuery(SessionQueries.queries.MySessionOffers);

  if (!direction) return <Redirect to="/sessions/upcoming" />;

  const { missingReview } = qs.parse(location?.search, { arrayFormat: "comma" });

  // A bit verbose solution to help with weird caching issues of myCoachingProfile
  if (_.isNull(options) && sessionsResult?.myProfile) {
    if (isActiveMentor(sessionsResult)) {
      setOptions([
        { title: "Upcoming", value: "upcoming" }
        // { title: "Previous", value: "previous" },
        // { title: "Feedback", value: "feedback" }
      ]);
    } else {
      setOptions([
        { title: "Upcoming", value: "upcoming" }
        // { title: "Previous", value: "previous" },
      ]);
    }
  }

  let upcomingNotifs, previousNotifs;

  if (myReceivedResult && myRequestsResult) {
    upcomingNotifs =
      myReceivedResult?.myReceivedRequests?.pageInfo?.edgeCount +
      myRequestsResult?.mySessionRequests?.pageInfo?.edgeCount +
      myOffersResult?.mySessionOffers?.pageInfo?.edgeCount;
  }

  if (myFeedbackRequests) {
    previousNotifs = myFeedbackRequests?.missingReview?.length;
  }

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <div style={{ width: "100%" }}>
            <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
              {direction && <Meta direction={direction} />}
              <Pane
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                justifyContent="space-between"
                marginBottom={missingReview ? null : majorScale(2)}
                borderBottom="1px solid #efefef"
                paddingTop={majorScale(4)}
                width="100%"
              >
                <Heading display="flex" alignItems="center" size={700} marginBottom={isMobile ? minorScale(1) : majorScale(2)}>
                  <CalendarIcon marginRight={majorScale(1)} size={majorScale(2)} />
                  {"Upcoming activity"}
                </Heading>
                {/* {options?.length > 0 && (
                <TabNavigation>
                  {options.map(({ value, title }) => {
                    let badge, showBadge

                    if (_.isEqual(value, "upcoming") && (upcomingNotifs > 0)) {
                      showBadge = true

                      badge = (
                        <Pill marginLeft={majorScale(1)} color="red">
                          {upcomingNotifs}
                        </Pill>
                      )
                    }

                    if (_.isEqual(value, "previous") && (previousNotifs > 0)) {
                      showBadge = true

                      badge = (
                        <Pill marginLeft={majorScale(1)} color="red">
                          {previousNotifs}
                        </Pill>
                      )
                    }

                    return (
                      <Tab
                        appearance="primary"
                        is={BrowserLink}
                        to={`/sessions/${value}`}
                        id={value}
                        isSelected={direction === value}>
                        {title}
                        {showBadge && badge}
                      </Tab>
                    )
                  })}
                </TabNavigation>
              )} */}
              </Pane>
              {missingReview && (
                <Pane
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  marginTop={majorScale(2)}
                  marginBottom={majorScale(6)}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Alert intent="warning" title={MISSING_REVIEW_PROMPT} />
                </Pane>
              )}
            </Col>
            {_.isEqual(direction, "upcoming") && myOffersResult?.mySessionOffers?.pageInfo?.edgeCount > 0 && (
              <MySessionOffersDetail
                myProfile={myOffersResult.myProfile}
                myOffers={myOffersResult?.mySessionOffers}
                title="My Session Offers"
              />
            )}
            {_.isEqual(direction, "upcoming") && myReceivedResult?.myReceivedRequests?.pageInfo?.edgeCount > 0 && (
              <MyRequestsDetail
                myProfile={myReceivedResult?.myProfile}
                myRequests={myReceivedResult?.myReceivedRequests}
                myPendingRequests={myPendingRequestsResult?.myPendingRequests}
                fetchMore={fetchMoreReceived}
                typeName="myReceivedRequests"
                query={SessionQueries.queries.MyReceivedRequests}
                title="My Requests"
              />
            )}
            {_.isEqual(direction, "upcoming") && myRequestsResult?.mySessionRequests?.pageInfo?.edgeCount > 0 && (
              <MyRequestsDetail
                myProfile={myRequestsResult?.myProfile}
                myRequests={myRequestsResult?.mySessionRequests}
                fetchMore={fetchMoreRequests}
                query={SessionQueries.queries.MySessionRequests}
                title="My Accepted Requests"
              />
            )}
            {_.isEqual(direction, "previous") && myFeedbackRequests?.missingReview?.length && (
              <MyFeedbackRequestDetail
                myProfile={myFeedbackRequests?.myProfile}
                missingReview={myFeedbackRequests?.missingReview}
                fetchMore={fetchMoreMissingReviews}
              />
            )}
            <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
              {!loading && !_.isEqual(direction, "feedback") && sessionsResult && (
                <>
                  {sessionsResult?.mySessions?.edges?.length > 0 ? (
                    <SessionListDetail fetchMore={fetchMore} direction={direction} {...sessionsResult} />
                  ) : (
                    <>
                      {_.isEqual(direction, "upcoming") && !(upcomingNotifs > 0) && (
                        <EmptySessionView myCoachingProfile={sessionsResult?.myCoachingProfile} />
                      )}
                      {_.isEqual(direction, "previous") && !(previousNotifs > 0) && (
                        <EmptySessionView myCoachingProfile={sessionsResult?.myCoachingProfile} />
                      )}
                    </>
                  )}
                </>
              )}
              {!loading && _.isEqual(direction, "feedback") && (
                <>
                  {sessionsResult && feedbackQuery?.data?.myFeedback?.edges?.length > 0 ? (
                    <FeedbackListDetail
                      myProfile={sessionsResult?.myProfile}
                      myFeedback={feedbackQuery?.data?.myFeedback}
                      fetchMore={feedbackQuery?.fetchMore}
                    />
                  ) : (
                    <EmptyFeedbackView />
                  )}
                </>
              )}
              {!loading && sessionsResult?.myProfile?.id && _.isEqual(direction, "previous") && (
                <>
                  <IncompleteSessionList myProfile={sessionsResult?.myProfile} />
                </>
              )}
              {loading && <LoadingView />}
              {error && <Alert intent="danger" title={ERROR_MSG} />}
            </Col>
          </div>
        );
      }}
    />
  );
};

export default MyActivityPage;
