import React from "react";
import { SearchInput, Badge, Pane, majorScale } from "evergreen-ui";

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

export default ({ field, form: { touched, errors }, ...props }) => (
  <Pane alignItems="center">
    <SearchInput width="100%" {...field} {...props} />
    {touched[field.name] && errors[field.name] && errorText(errors[field.name])}
  </Pane>
);
