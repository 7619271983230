import React from "react";
import { Card, Pane, Paragraph, Heading, Text, OrderedList, UnorderedList, ListItem, majorScale } from "evergreen-ui";

export const steps = [
  {
    url: "https://youtu.be/DiF1K_YJpJA",
    title: "How do I use Merit?",
    description: "Learn about to best way to use Merit."
  },
  {
    url: "https://youtu.be/ro15jHRARuQ",
    title: "Coaches & Playlists",
    description: "Learn how to explore Merit."
  },
  {
    url: "https://youtu.be/qpa74Gn8jko",
    title: "Coaching topics",
    description: "Learn how to approach coaching sessions."
  }
];

export const icons = {
  tada: "https://ucarecdn.com/d9aa70ea-1601-4bd7-b71d-7bfae3c174b5/Tada3x.png",
  calendar: "https://ucarecdn.com/1e744b4b-5aeb-4b39-b3a5-d81eaeec6d32/Calendar3x.png",
  messages: "https://ucarecdn.com/163a734b-c8e1-4c8f-be88-a11d6d9a6f02/Messages3x.png",
  select: "https://ucarecdn.com/6e9b1d6c-3d2a-4b64-9fbd-c4c656169e3a/Select3x.png",
  paper: "https://ucarecdn.com/88624be8-6187-4e0d-8c3b-de792e8c68df/Paper3x.png"
};

export const LearnerGuide = [
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane textAlign="center" padding={majorScale(2)}>
      <img src={icons.select} width={100} />
    </Pane>
    <Pane>
      <Heading paddingBottom={majorScale(2)}>Talk to multiple people</Heading>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        Book time with multiple people. This exposes you to multiple perspectives and helps you notice common patterns and trends.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        The power of the Merit network is the space and flexibility to explore, in a safe space, to find the people and format that works
        best for you.
      </Paragraph>
    </Pane>
  </Pane>,
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane textAlign="center" padding={majorScale(2)}>
      <img src={icons.calendar} width={100} />
    </Pane>
    <Pane>
      <Heading paddingBottom={majorScale(2)}>Respect people’s time</Heading>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        When booking a mentor’s time, book a time that is easy and realistic for you to show up.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        If you are unable to meet at the scheduled date & time, re-schedule as early as possible to a time that you can commit to.
      </Paragraph>
    </Pane>
  </Pane>,
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane textAlign="center" padding={majorScale(2)}>
      <img src={icons.messages} width={100} />
    </Pane>
    <Pane>
      <Heading paddingBottom={majorScale(2)}>Keep conversations focused</Heading>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        People get the best results when they stick to just one topic per session. We've found trying to address multiple questions in a
        single session makes it hard to make meaningful progress on any single issue.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        You can always book another call to discuss other topics.
      </Paragraph>
    </Pane>
  </Pane>,
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane textAlign="center" padding={majorScale(2)}>
      <img src={icons.paper} width={100} />
    </Pane>
    <Pane>
      <Heading paddingBottom={majorScale(2)}>Share context</Heading>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        Write a clear description of your problem or question in the session. Share the details and questions in advance so the other person
        can come prepared.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        The more context you give, the easier it is for the other person to provide relevant stories, questions, and suggestions.
      </Paragraph>
    </Pane>
  </Pane>,
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane textAlign="center" padding={majorScale(2)}>
      <img src={icons.tada} width={100} />
    </Pane>
    <Pane>
      <Heading paddingBottom={majorScale(2)}>Share feedback and updates</Heading>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        After completing a session, please leave feedback: what you got out of the session, your takeaways, and what the next steps are.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(1)}>
        Giving feedback and updates is both a way to help mentors get better and also is a small way to give back to those who helped you
        along the way.
      </Paragraph>
    </Pane>
  </Pane>
];
