import React from "react";
import { Pill } from "evergreen-ui";

const getAvailability = ({ isAvailable }) => {
  const text = isAvailable ? `Active` : `Busy`;
  const color = isAvailable ? "green" : "neutral";

  return (
    <Pill zIndex={2} marginTop={-10} color={color}>
      {text}
    </Pill>
  );
};

export default getAvailability;
