import _ from "lodash";
import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Card, Pane, Alert, Spinner, Paragraph, majorScale, toaster } from "evergreen-ui";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";

import ProfileList from "../../queries/profile/ProfileList";
import TextInput from "../form/TextInput";

const VIDEO_LINK_SUCCESS = "Updated your video link";
const ERROR_MSG = "Something went wrong. Please refresh this page!";
const DEFAULT_VIDEO_LINK = "meet.google.com";

const CONNECT_ZOOM_BUTTON = "Connect Zoom";
const DISCONNECT_ZOOM_BTN = "Disconnect Zoom";

const ZOOM_DISCONNECTED = "Zoom disconnected.";
const ZOOM_CONNECT_INFO = "Have an existing Zoom account?";
const ZOOM_DISCONNECT_INFO = "Connected to your Zoom account.";

const showSuccess = _.debounce(() => {
  toaster.success(VIDEO_LINK_SUCCESS, { duration: 2 });
}, 1000);

const VideoLinkSchema = Yup.object().shape({
  videoLink: Yup.string().url("Must be a valid URL. (Did you forget 'https://' ?)")
});

const initialVideoLink = (link) => {
  if (_.isEqual(link, initialVideoLink)) return "";
  else return link;
};

let windowObjectReference = null;
let previousURL = null;

const openSignInWindow = (url, name, receiveMessage) => {
  // remove any existing event listeners
  window.removeEventListener("message", receiveMessage);

  // window features
  const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    // If the pointer to the window object in memory does not exist
    // or if such pointer exists but the window was closed

    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousURL !== url) {
    // If the resouce to load is different, then we load it in the already
    // opened secondary window and then we bring such window back on top/in
    // front of its parent window
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    // The window reference must exist and the window is not closed; therefore
    // we can bring it back on top of any other window with the focus() method.
    // There would be no need to recreated the window or to reload the referenced resource
    windowObjectReference.focus();
  }

  // Add the listener for receiving a message from the popup
  window.addEventListener("message", (event) => receiveMessage(event), false);

  // Assign the previous URL
  previousURL = url;
};

const VideoLink = () => {
  const [updatedLink, setUpdatedLink] = useState(null);
  const { loading, error, data, refetch: refetchVideoLink } = useQuery(ProfileList.VideoLinkQuery);
  const { data: zoomAuthData, refetch: refetchZoom } = useQuery(ProfileList.ZoomAuthLink);
  const [disconnectZoom, { data: disconnectResult, loading: runningDisconnect }] = useMutation(ProfileList.DisconnectZoom, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyProfileQuery },
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.VideoLinkQuery },
      { query: ProfileList.ZoomAuthLink }
    ],
    onCompleted: () => {
      refetchVideoLink();
      toaster.notify(ZOOM_DISCONNECTED, { duration: 2 });
    }
  });

  const [updateVideoLink, { data: result, loading: updatingVideoLink }] = useMutation(ProfileList.UpdateVideoLink, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyProfileQuery },
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.VideoLinkQuery },
      { query: ProfileList.ZoomAuthLink }
    ],
    onCompleted: () => showSuccess()
  });

  let zoomAuthLink = zoomAuthData ? zoomAuthData.zoomAuthLink : null;
  let zoomMeetingUrl = zoomAuthData && zoomAuthData.myProfile && zoomAuthData.myProfile.zoomMeetingUrl;

  if (zoomMeetingUrl && _.isNull(updatedLink)) {
    setUpdatedLink(zoomMeetingUrl);
  }

  if (loading) return <Spinner />;
  if (error) return <Alert intent="danger" title={ERROR_MSG} />;
  if (data) {
    const videoLink = data && data.videoLink ? data.videoLink : DEFAULT_VIDEO_LINK;
  }

  return (
    <Formik
      validationSchema={VideoLinkSchema}
      initialValues={{
        videoLink: initialVideoLink(data?.videoLink)
      }}
      onSubmit={({ videoLink }, { setSubmitting }) => {
        updateVideoLink({ variables: { videoLink } });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Card padding={majorScale(2)} background="tint1">
            <Paragraph size={500}>
              💡 Tip: You can provide a Zoom URL by heading to{" "}
              <a target="_blank" href="https://us02web.zoom.us/meeting">
                Zoom Settings → Personal Room
              </a>
            </Paragraph>
          </Card>
          <Pane display="flex" flexDirection="row" justifyContent="space-between" paddingTop={majorScale(2)}>
            <Pane width="100%">
              <Field type="text" name="videoLink" placeholder="Your video link" maxWidth="100%" onBlur={submitForm} component={TextInput} />
            </Pane>
            <Button type="button" appearance="primary" isLoading={updatingVideoLink} onClick={() => submitForm()}>
              {updatingVideoLink ? "Updating..." : "Update Link"}
            </Button>
          </Pane>
          {/*
            <Pane display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems='center'>
              <Text>
                {updatedLink && !_.isEmpty(updatedLink) ? ZOOM_DISCONNECT_INFO : ZOOM_CONNECT_INFO}
              </Text>
              {updatedLink && !_.isEmpty(updatedLink) ? (
                <Button type="button"
                        isLoading={runningDisconnect}
                        onClick={() => {
                          disconnectZoom()
                          setUpdatedLink(null)
                          setFieldValue('videoLink', DEFAULT_VIDEO_LINK)
                        }}>
                  {DISCONNECT_ZOOM_BTN}
                </Button>
              ) : <Button appearance="primary"
                      type="button"
                      onClick={() => {
                        openSignInWindow(
                          zoomAuthLink, 'Connect Zoom Account', (e) => {
                            if (e && e.data && e.data.zoomConnected) {
                              setUpdatedLink(e.data.updatedLink)
                              setFieldValue('videoLink', e.data.updatedLink)
                            }
                          }
                        )
                      }}>
                {CONNECT_ZOOM_BUTTON}
              </Button>}
            </Pane>
            */}
        </Form>
      )}
    />
  );
};

export default VideoLink;
