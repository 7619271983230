export const leftStarURL = "https://ucarecdn.com/4a6d1bc5-d086-4c3e-9610-be94dca831e6/leftStar.svg";
export const rightStarURL = "https://ucarecdn.com/5d987c82-b5a8-435d-93ca-566a0aeea797/rightStar.svg";
export const teamPhotoURL = "https://ucarecdn.com/3340a361-cfcb-492e-8137-49f1fd549280/-/resize/1000x500/";
export const chart = "https://ucarecdn.com/77105468-b5cf-4e2b-ac25-268b02e542ae/chart.svg";
export const computer = "https://ucarecdn.com/138e0050-b4d9-4682-8f5b-bdae88e31de1/computer.svg";
export const teamPhotoMobile = "https://ucarecdn.com/3340a361-cfcb-492e-8137-49f1fd549280/-/resize/360x180/";
export const trophy = "https://ucarecdn.com/0d23737e-64d9-47e5-92ce-2be2f36f76a8/trophy.svg";

export const lightBulb = "https://ucarecdn.com/99cc6bcb-a931-49cc-a953-4d2ffa03b1bc/lightbulb.svg";
export const balloon = "https://ucarecdn.com/5fdeb312-3f35-4deb-bf27-f27fc0a37db2/balloon.svg";

// export const logo = 'https://ucarecdn.com/91b65a39-7ce4-46f8-a4b9-ee046f8ce440/Merit_social_red.svg'
export const logo = "https://ucarecdn.com/bbae9f19-e479-42fe-a68b-8d907dc906f5/Merit.svg";

export const signUpLink = "https://airtable.com/shr14GMwYgqEIU02H";
export const teamPic = "https://ucarecdn.com/3340a361-cfcb-492e-8137-49f1fd549280/DSC03676EnhancedNR.jpg";

export const founderStory = [
  `Grow your network, grow your career.`,
  `Mentorship is the most effective way to build your network and career. Not everyone has the same access, so we built Merit for the under-networked tech worker to grow their craft, career, and confidence.`,
  `We connect curious, eager members with mentors who want to impact the tech industry for the better. Our vision is a real meritocracy for the tech industry, and we’ll get there, one conversation at a time.`
];

export const problem = `Democratize the professional network`;

export const problemReference = `https://mk0kaporcenter5ld71a.kinstacdn.com/wp-content/uploads/2017/08/TechLeavers2017.pdf#page=17`;

export const contact = `Interested in helping? Contact us`;

export const contributorsContext = `Our cap table is 60% people of color and 40% women.`;

const randyBio = `
Randy is a Y-Combinator alumni and /dev/color member who was the CTO of Jopwell, the leading diversity recruiting platform. Randy studied Economics and Computer Science at Princeton.
`;

const kirkBio = `
Kirk has nearly a decade of product leadership at Microsoft, Hightower & VTS. He has worked as a PM, designer, and software engineer. Kirk studied Computer Science at the University of Waterloo.
`;

export const keyContributors = [
  "Isaac Oates (Founder/CEO, Justworks)",
  "Brandon Weber (Founder/CEO, Nava, fmr. Founder/CEO, VTS)",
  "Bo Ren (Managing Director, Silicon Valley Bank)",
  "Noah Weiss (CPO, Slack)",
  "Yuhki Yamashita (VP of Design, Figma)",
  "Bailey Richardson (Head of Marketing, Substack)",
  "Jed Brown (Head of Product Management, Microsoft)",
  "Will Larson (CTO, Carta, fmr. CTO, Calm)",
  "Ellen Chisa (Partner at Boldstart Ventures, fmr. CEO/Founder, Dark",
  "Donald DeSantis (Founder/VP Product, Nava, fmr. Founder/VP Product, VTS)",
  "Andrew Hogue (Director of Engineering, Google)",
  "Matt Hackett (Chief Strategy Officer, Headway, fmr. Founder/CTO, Beme)",
  "Niall Smart (CTO and Co-Founder, VTS)",
  "Walter Chen (Founder, Sacra, Animalz, and iDoneThis)",
  "Shiva Rajaraman (VP Product, OpeaSea)",
  "And more..."
];

export const contributors = [
  "Adebimbola Ogunyale",
  "Adrian Lumley",
  "Alex Solod",
  "Alexander Holman",
  "Anastasiia Kobzarenko",
  "Andrew Badr",
  "Andrew Chen",
  "Andrew Choi",
  "Andrew Hogue",
  "Andrew Werner",
  "Arjun Kannan",
  "Ashley Mackley",
  "Bailey Richardson",
  "Belinda Ju",
  "Benjamin Mackley",
  "Bianca Brown",
  "Billy Tobon",
  "Bo Ren",
  "Brandon Weber",
  "Brennan Robbins",
  "Brian Stoner",
  "Cameron Porter",
  "Catt Small",
  "Chris Scott",
  "Christian Kaczmarczyk",
  "Christopher Lorn",
  "Daani Sarma",
  "Dan Ubilla",
  "David Drew",
  "David Hammer",
  "Dion Ridley",
  "Donald DeSantis",
  "Doug Norfleet",
  "Edlyn Yuen",
  "Ellen Chisa",
  "Emilie Hsieh",
  "Fred Shack",
  "Gabriel Chen",
  "Gary Chou",
  "Greg Gonalzes",
  "Gustavo Matias",
  "Haley Bryant",
  "Hiro Shinn",
  "Isaac Oates",
  "Ivan Butyliuk",
  "James Turnbull",
  "Jen Hau",
  "Jennifer Boram Kim",
  "Jordan Pisarcik",
  "Joseph Sunga",
  "Josh Chen",
  "Julia Norfleet",
  "Julie Drew",
  "Kalia Aragon",
  "Katie Suskin",
  "Kazoo Sone",
  "KC Oh",
  "Kevin Huynh",
  "Kevin Weatherman",
  "Lalit Kapoor",
  "Marco Matos",
  "Lizzy Koetsier",
  "Matt Hackett",
  "Michelle Chu",
  "Milyza Flores",
  "Myles McGinley",
  "Neil Gangal",
  "Niall Smart",
  "Nicholas Bong",
  "Nicholas Wu",
  "Nikolas Lazaris",
  "Noah Weiss",
  "Nonso Maduka",
  "Pablo Napolitano",
  "Phil Aroian",
  "Qiana Patterson",
  "Rachel Spurrier",
  "Randal Drew",
  "Randall Brown",
  "Rob Sami",
  "Sam Huleatt",
  "Samuel DeBrule",
  "Scott Hu",
  "Sha-Mayn Teh",
  "Shiva Rajaraman",
  "Shobita Das",
  "Stephanie Del Paggio",
  "Susan Chen",
  "Teresa Man",
  "Thomas Antonio",
  "Tin Nguyen",
  "Tony Chang",
  "Urim Choi",
  "Victoria Vassileva",
  "Vincent Lai",
  "Vlad Shulman",
  "Walter Chen",
  "Warren Wan",
  "Yuhki Yamashita",
  "Zakiya Sharpe",
  "Radhika Parashar",
  "Nikitha Suryadevara",
  "Shane D’Souza",
  "Saumaya Sharma",
  "Jed Brown",
  "Allison Tran",
  "Niraj Kumar",
  "Larry James Erwin",
  "Alvin Deng",
  "Hilal Koc Savci",
  "Lauren Budorick",
  "Godwin Chan",
  "Matt Schaarr",
  "Kelly Cheng",
  "Angela Jo",
  "Will Larson"
];

export const team = [
  {
    name: {
      first: "Kirk",
      last: "Fernandes"
    },
    bio: kirkBio,
    email: "kirk@get-merit.com",
    twitter: "https://twitter.com/k3fernan",
    linkedin: "https://www.linkedin.com/in/kirk-fernandes-753a3126/",
    title: "Founder",
    pic: "https://ucarecdn.com/2866e6d7-7345-4ddd-b1de-3f346165d654/Kirk02.png"
  },
  {
    name: {
      first: "Randy",
      last: "Brown"
    },
    bio: randyBio,
    email: "randy@get-merit.com",
    twitter: "https://twitter.com/randybrown_",
    linkedin: "https://www.linkedin.com/in/randolphebrown/",
    title: "Founder",
    pic: "https://ucarecdn.com/62969f64-fcd7-44b6-9fbb-fd454d71cd05/Randy01.png"
  }
];
