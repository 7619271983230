import _ from "lodash";
import React, { useState } from "react";
import { formatDistanceToNow, isValid } from "date-fns";
import { ScreenClassRender } from "react-grid-system";
import ReactMarkdown from "react-markdown";
import { useQuery } from "@apollo/client";
import {
  Avatar,
  Heading,
  ListItem,
  Pane,
  Paragraph,
  UnorderedList,
  Strong,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";

import HoverLink from "../HoverLink";
import ProfilePreview from "../profile/ProfilePreview";
import Upvote from "./Upvote";
import Reply from "./Reply";
import EditItem from "./EditItem";
import ReplyForm from "./ReplyForm";
import ReplyCommentForm from "./ReplyCommentForm";
import DeleteItem from "./DeleteItem";
import ReplyItem from "./ReplyItem";
import ReplyIndex from "./ReplyIndex";

import FeedQueries from "../../queries/feed";

const PREVIEW_LENGTH = 330;

const Photo = ({ owner }) => (
  <HoverLink samePage to={`/p/${owner?.slug}`}>
    <Pane display="flex" flexDirection="column" justifyContent="start" width={majorScale(5)} marginRight={minorScale(5)}>
      <Avatar src={owner?.picture} name={owner?.fullName} size={majorScale(5)} />
    </Pane>
  </HoverLink>
);

const formatDate = (createdAt) => {
  // https://date-fns.org/v2.27.0/docs/isValid
  createdAt = isValid(new Date(createdAt)) ? createdAt : Number(createdAt);

  // https://date-fns.org/v2.27.0/docs/formatDistanceToNow
  return `${formatDistanceToNow(new Date(createdAt))} ago`;
};

const formatDetails = ({ details, id, long }) => {
  return long
    ? details
    : _.truncate(details, {
        length: PREVIEW_LENGTH,
        omission: ` [See More...](/d/${id} "See More...")`
      });
};

const findObj = ({ reply, comment, post }) => {
  if (reply) return reply;
  else if (comment) return comment;
  else return post;
};

const findObjType = ({ reply, comment, post }) => {
  if (reply) return "reply";
  else if (comment) return "comment";
  else return "post";
};

const DiscussionActions = (actionProps) => {
  const { reply, post, comment, myProfile, hideReplies, history, refetch, gutter, hideResponses } = actionProps;

  const [showEdit, setShowEdit] = useState(false);
  const [showReply, setShowReply] = useState(false);

  const objType = findObjType({ reply, comment, post });
  const obj = findObj({ reply, comment, post });

  const myItem = (myProfile?.id && _.isEqual(myProfile.id, obj?.owner?.id)) || myProfile?.isAdmin;

  const onReply = hideResponses ? () => history.push(`/d/${post?.id}`) : () => setShowReply(true);
  const showEditForm = () => setShowEdit(true);
  const goToForm = () => history.push(`/d/edit/${post?.id}`);

  const editOptions = {
    comment: showEditForm,
    post: goToForm,
    reply: onReply
  };

  const onEdit = editOptions[objType];

  const onDelete = () => {
    if (_.isFunction(refetch)) {
      refetch();
    }

    if (history?.location?.pathname?.includes(`${post?.id}`)) {
      history.goBack();
    }
  };

  return (
    <>
      <Pane width="100%" display="flex" flexDirection="row" marginTop={minorScale(3)} marginBottom={gutter ? minorScale(3) : undefined}>
        <Upvote reply={reply} comment={comment} post={post} myProfile={myProfile} responses={obj?.reactions} />
        {_.isEqual(objType, "comment") && <ReplyItem myProfile={myProfile} post={post} comment={comment} reply={reply} onReply={onReply} />}
        {!hideReplies && (
          <HoverLink noUnderline samePage to={`/d/${post?.id}#post`}>
            <Reply comment={comment} post={post} myProfile={myProfile} />
          </HoverLink>
        )}
        {myItem && (
          <>
            <EditItem myProfile={myProfile} post={post} comment={comment} reply={reply} onEdit={onEdit} />
            <DeleteItem myProfile={myProfile} post={post} comment={comment} reply={reply} onDelete={onDelete} />
          </>
        )}
      </Pane>
      {showReply && ["comment", "reply"].find((t) => objType === t) && (
        <Pane width="100%" display="flex" flexDirection="row" marginTop={majorScale(3)}>
          <ReplyCommentForm
            fullWidth
            reply={reply}
            post={post}
            comment={comment}
            myProfile={myProfile}
            onCancel={() => setShowReply(false)}
            onUpdate={() => setShowReply(false)}
          />
        </Pane>
      )}
      {myItem && showEdit && _.isEqual(objType, "comment") && (
        <Pane width="100%" display="flex" flexDirection="row" marginTop={majorScale(3)}>
          <ReplyForm
            fullWidth
            post={post}
            comment={comment}
            myProfile={myProfile}
            onCancel={() => setShowEdit(false)}
            onUpdate={() => setShowEdit(false)}
          />
        </Pane>
      )}
    </>
  );
};

const DiscussionDetail = ({ id, title, owner, topic, details, createdAt, long, isHeading }) => {
  const copy = formatDetails({ details, id, long });
  const date = formatDate(createdAt);

  return (
    <Pane display="flex" flexDirection="column">
      <Pane display="flex" flexDirection="row">
        <HoverLink samePage to={`/d/${id}`}>
          <Heading size={600} is={isHeading ? "h1" : undefined}>
            {title}
          </Heading>
        </HoverLink>
      </Pane>
      <Pane display="flex" flexDirection="row" alignItems="center">
        <HoverLink samePage to={`/p/${owner?.slug}`}>
          <ProfilePreview user={owner}>
            <Paragraph size={400}>{owner?.fullName}</Paragraph>
          </ProfilePreview>
        </HoverLink>
        {topic?.name && <Paragraph size={400}>{`\u00a0·\u00a0`}</Paragraph>}
        {topic?.name && (
          <HoverLink samePage to={`/q/${topic?.slug}`}>
            <Paragraph size={400}>{topic?.name}</Paragraph>
          </HoverLink>
        )}
        {date && (
          <>
            <Paragraph size={400}>{`\u00a0·\u00a0`}</Paragraph>
            <HoverLink samePage to={`/d/${id}`}>
              <Paragraph size={500}>{date}</Paragraph>
            </HoverLink>
          </>
        )}
      </Pane>
      <Pane marginTop={majorScale(2)}>
        <ReactMarkdown
          components={{
            p: ({ ...context }) => {
              return <Paragraph marginBottom={majorScale(1)} {...context} />;
            },
            // : ({ title, href, children }) => (
            //   <Paragraph size={500}>
            //     {children}
            //   </Paragraph>
            // ),
            ol: UnorderedList,
            li: ListItem,

            a: ({ title, href, children }) => {
              title = title ? title : children && _.head(children)?.props?.value;

              return (
                <HoverLink to={href}>
                  <Strong size={500} color={theme.colors.blue500}>
                    {title}
                  </Strong>
                </HoverLink>
              );
            }
          }}
        >
          {copy}
        </ReactMarkdown>
      </Pane>
    </Pane>
  );
};

const Replies = ({ comment, post, myProfile }) => {
  const { data: replyResult, fetchMore } = useQuery(FeedQueries.queries.Replies, { variables: { commentId: comment?.id } });

  let content = null;
  let replies, next;

  if (replyResult) {
    replies = replyResult?.replies;

    next = () =>
      fetchMore({
        query: FeedQueries.queries.Replies,
        variables: {
          commentId: comment?.id,
          cursor: replies?.pageInfo?.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            replies: {
              __typename: fetchMoreResult.replies.__typename,
              pageInfo: fetchMoreResult.replies.pageInfo,
              edges: [...prev.replies.edges, ...fetchMoreResult.replies.edges]
            }
          });
        }
      });

    content = (
      <Pane display="flex" flexDirection="column" width="100%">
        <ReplyIndex post={post} comment={comment} replies={replies} myProfile={myProfile} next={next} />
      </Pane>
    );
  }

  return content;
};

const DiscussionItem = (itemProps) => {
  const { reply, post, comment, hideReplies, myProfile, long, history, refetch, hideResponses, isHeading } = itemProps;

  const obj = findObj({ reply, comment, post });

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <>
          <Pane
            backgroundColor="white"
            border={reply ? undefined : `1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            padding={majorScale(2)}
            width="100%"
          >
            <Pane display="flex" flexDirection="column">
              <Pane display="flex" flexDirection="row">
                <Photo {...obj} />
                <Pane width="100%">
                  <DiscussionDetail isHeading={isHeading} long={long} {...obj} />
                  {!screenClass?.includes("xs") && (
                    <DiscussionActions
                      reply={reply}
                      comment={comment}
                      post={post}
                      myProfile={myProfile}
                      hideReplies={hideReplies}
                      hideResponses={hideResponses}
                      long={long}
                      history={history}
                      refetch={refetch}
                    />
                  )}
                </Pane>
              </Pane>
              {!reply && comment?.id && !hideResponses && (
                <Pane display="flex" paddingLeft={minorScale(15)} marginTop={majorScale(2)} width="100%">
                  <Replies comment={comment} post={post} myProfile={myProfile} />
                </Pane>
              )}
            </Pane>
            {screenClass?.includes("xs") && (
              <DiscussionActions
                reply={reply}
                comment={comment}
                post={post}
                myProfile={myProfile}
                hideReplies={hideReplies}
                long={long}
                history={history}
                refetch={refetch}
                hideResponses={hideResponses}
                gutter
              />
            )}
          </Pane>
          {(!long || !isHeading) && <Pane width="100%" marginBottom={majorScale(3)} />}
        </>
      )}
    />
  );
};

export default DiscussionItem;
