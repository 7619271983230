import _ from "lodash";
import React, { useState } from "react";
import { Alert, Button, Pane, majorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import DegreeForm from "../../../components/profile/DegreeForm";
import ProfileList from "../../../queries/profile/ProfileList";

const DEGREE_DELETED_TOAST = "Degree deleted.";
const DEGREE_ADDED_TOAST = "Degree added.";
const EMPTY_DEGREES_COPY = "No degrees listed.";

const CreateDegree = ({ onCreate }) => {
  const emptyDegree = {
    schoolName: null,
    schoolID: null,
    schoolLogo: null,
    schoolDomain: null,
    fieldName: null,
    majorName: null,
    start: null,
    end: null
  };

  const [createDegree] = useMutation(ProfileList.CreateDegree, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: ({ createDegree }) => {
      toaster.success(DEGREE_ADDED_TOAST, { duration: 2 });
    }
  });

  return (
    <Button
      width="100%"
      onClick={() => {
        const created = _.cloneDeep(emptyDegree);
        createDegree({ variables: { ...created } });
      }}
    >
      Add Degree
    </Button>
  );
};

const EditEducationContainer = (props) => {
  const degrees = props?.myProfile?.degrees;
  const [deleteDegree] = useMutation(ProfileList.DeleteDegree, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }]
  });

  const [upDownDegree] = useMutation(ProfileList.UpDownDegree, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }]
  });

  const isLast = (index, degrees) => index === degrees.length - 1;

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between" alignItems="start">
        <CreateDegree />
      </Pane>
      <Pane marginTop={majorScale(2)}>
        {degrees && !_.isEmpty(degrees) ? (
          degrees.map((degree, index) => (
            <Pane
              key={`DegreeForm-${degree?.id}-${index}`}
              marginBottom={!isLast(index, degrees) ? majorScale(3) : null}
              borderBottom={!isLast(index, degrees) ? `1px solid #E6E8F0` : null}
            >
              <DegreeForm
                degree={degree}
                items={degrees}
                position={index}
                key={`DegreeForm-${degree?.id}-${index}`}
                onDelete={() => {
                  deleteDegree({ variables: { degreeID: degree?.id } });

                  toaster.success(DEGREE_DELETED_TOAST, { duration: 2 });
                  window.analytics.track("Update Education History");
                }}
                onMove={(direction) => {
                  upDownDegree({
                    variables: {
                      degreeID: degree?.id,
                      direction
                    }
                  });
                }}
              />
            </Pane>
          ))
        ) : (
          <Pane>
            <Alert intent="warning" title={EMPTY_DEGREES_COPY} marginTop={majorScale(2)} marginBottom={majorScale(2)} />
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

export default EditEducationContainer;
