import _ from "lodash";
import React from "react";
import { Card, CrossIcon, Heading, InlineAlert, Pane, Paragraph, majorScale, defaultTheme as theme, toaster, Avatar } from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import AsyncSelect from "react-select/async";

import CandidateQueries from "../../../queries/candidate";
import { clearbitOrgOptions } from "../../../components/profile/AutoFill";

const HiddenCompaniesForm = () => {
  const [hideOrShowCompany] = useMutation(CandidateQueries.mutations.HideOrShowCompany, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results) => {
      toaster.success("Company hidden", { duration: 2, id: "hide-company" });
    }
  });
  return (
    <Formik
      initialValues={{
        name: "",
        logo: "",
        domain: ""
      }}
      onSubmit={({ name, logo, domain }, { setSubmitting }) => {
        hideOrShowCompany({
          variables: {
            name,
            logo,
            domain,
            hide: true
          }
        });

        setSubmitting(false);
      }}
      render={({ values, setFieldValue, submitForm }) => (
        <Form>
          <Pane display="flex" flexDirection="column" width="100%">
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={clearbitOrgOptions}
              onChange={(selected) => {
                const { domain, logo, name } = selected?.value;

                setFieldValue("domain", domain, false);
                setFieldValue("logo", logo, false);
                setFieldValue("name", name, false);

                submitForm();
              }}
            />
          </Pane>
        </Form>
      )}
    />
  );
};

const HiddenCompany = ({ id, name, logo, website }) => {
  const [hideOrShowCompany] = useMutation(CandidateQueries.mutations.HideOrShowCompany, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results) => {
      toaster.success("Company hidden", { duration: 2, id: "hide-company" });
    }
  });

  return (
    <Pane display="flex" flexDirection="row" justifyContent="start" alignItems="center" marginBottom={majorScale(2)}>
      <CrossIcon
        marginRight={majorScale(2)}
        onClick={() =>
          hideOrShowCompany({
            variables: {
              teamId: id,
              hide: false
            }
          })
        }
      />
      <Avatar marginRight={majorScale(2)} shape="square" src={logo} alt={name} size={60} />
      <Pane display="flex" flexDirection="column">
        <Heading size={400}>{name}</Heading>
        <Paragraph>{website}</Paragraph>
      </Pane>
    </Pane>
  );
};

const CandidateHiddenCompanies = () => {
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile);
  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const isActiveCandidate = myCandidateProfile?.id && !_.isEqual(myCandidateProfile?.status, "inactive");

  return !isActiveCandidate ? null : (
    <Card
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      backgroundColor="white"
      padding={majorScale(2)}
      marginBottom={majorScale(3)}
      marginTop={majorScale(3)}
      minHeight="100px"
      id="hidden-companies"
    >
      <Pane display="flex" flexDirection="row" justifyContent="start" marginBottom={majorScale(1)} alignItems="center">
        <Pane display="flex" flexDirection="column" alignItems="start" justifyContent="start">
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            width="100%"
            marginBottom={majorScale(1)}
          >
            <Heading fontWeight={500}>
              <span style={{ marginRight: "6px" }}>🙈</span>
              Blocked Companies
            </Heading>
            <InlineAlert>Only visible to you</InlineAlert>
          </Pane>
          <Paragraph size={500}>
            If you’re worried about your current or prior employer seeing your profile, Block them from seeing it. Any employer listed here
            will not be able to search for or view your profile.
          </Paragraph>
        </Pane>
      </Pane>
      <HiddenCompaniesForm />
      <Pane display="flex" flexDirection="column" marginTop={majorScale(2)}>
        {myCandidateProfile?.hiddenCompanies?.map(({ id, name, logo, website }) => (
          <HiddenCompany id={id} name={name} logo={logo} website={website} />
        ))}
      </Pane>
    </Card>
  );
};

export default CandidateHiddenCompanies;
