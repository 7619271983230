import _ from "lodash";
import React from "react";
import { Button, CaretDownIcon, Dialog, Heading, majorScale, Pane, Paragraph, SelectMenu, TagInput, toaster } from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Form, Field } from "formik";

import CandidateQueries from "../../../../queries/candidate";

const SelectFeatureByCategory = (props) => {
  const { featuresByGrouping, category, values, field, setFieldValue, onSelect } = props;

  const chosenByCategory = _.intersection(
    featuresByGrouping[category?.value].map((k) => k.value),
    _.map(values[field], "value")
  );

  return (
    <Pane
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      marginBottom={majorScale(1)}
      marginTop={majorScale(1)}
    >
      <Heading size={300}>{category.label}</Heading>
      <SelectMenu
        isMultiSelect
        title={`Choose ${category?.label}`}
        options={featuresByGrouping[category?.value]}
        selected={_.map(values[field], "value")}
        onSelect={(feature) => {
          const newFeatures = _.uniq([...values[field], feature]);
          setFieldValue(field, newFeatures, false);
          onSelect();
        }}
        onDeselect={(feature) => {
          const newFeatures = _.filter(values[field], (f) => f.value !== feature?.value);
          setFieldValue(field, newFeatures, false);
          onSelect();
        }}
      >
        <Button iconAfter={CaretDownIcon} type="button">
          {chosenByCategory?.length > 0 ? `${chosenByCategory?.length} chosen` : `None chosen`}
        </Button>
      </SelectMenu>
    </Pane>
  );
};

const TeamPreferencesForm = (props) => {
  const [updateTeamPreferences] = useMutation(CandidateQueries.mutations.UpdateTeamPreferences, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results) => {
      toaster.success("Team preferences updated", { duration: 2, id: "team-preferences" });
    }
  });

  const { availableTeamFeatures, availableTeamFeatureCategories } = props;

  // Reformat the data structure to work for display
  const featuresByGrouping = _.reduce(
    availableTeamFeatureCategories,
    (acc, category) => {
      acc[category.value] = _.chain(availableTeamFeatures)
        .filter({ category: category.value })
        .map((feature) => ({ label: feature.attribute, value: feature.attribute }))
        .value();

      return acc;
    },
    {}
  );

  const attToOp = ({ attribute }) => ({ label: attribute, value: attribute });
  return (
    <Formik
      initialValues={{
        mustHave: props.myCandidateProfile?.mustHave?.map(attToOp) || [],
        niceToHave: props.myCandidateProfile?.niceToHave?.map(attToOp) || [],
        doNotWant: props.myCandidateProfile?.doNotWant?.map(attToOp) || []
      }}
      onSubmit={(variables, { setSubmitting }) => {
        const optToAtt = ({ value }) => _.omit(_.find(availableTeamFeatures, { attribute: value }), "__typename");

        updateTeamPreferences({
          variables: {
            mustHave: _.map(variables.mustHave, optToAtt),
            niceToHave: _.map(variables.niceToHave, optToAtt),
            doNotWant: _.map(variables.doNotWant, optToAtt)
          }
        });
        setSubmitting(false);
      }}
      render={({ values, setFieldValue, submitForm }) => (
        <Form>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)}>
            <Paragraph>
              Pick the qualities you want in your next job. These will help us connect you to companies and roles that fit these
              preferences.
            </Paragraph>
          </Pane>

          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)}>
            <Heading>Must have</Heading>
            <Paragraph>You will only accept a job offer if it has these attributes.</Paragraph>
            {availableTeamFeatureCategories.map((category) => (
              <SelectFeatureByCategory
                key={category.value}
                featuresByGrouping={featuresByGrouping}
                category={category}
                values={values}
                field="mustHave"
                setFieldValue={setFieldValue}
                onSelect={() => {
                  submitForm();
                }}
              />
            ))}
          </Pane>

          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)}>
            <Heading>Nice to have</Heading>
            <Paragraph>You would love it if companies had these attributes, but not dealbreakers.</Paragraph>
            {availableTeamFeatureCategories.map((category) => (
              <SelectFeatureByCategory
                key={category.value}
                featuresByGrouping={featuresByGrouping}
                category={category}
                values={values}
                field="niceToHave"
                setFieldValue={setFieldValue}
                onSelect={() => submitForm()}
              />
            ))}
          </Pane>

          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)}>
            <Heading>Do not want</Heading>
            <Paragraph>You won’t accept a job offer if it has these attributes.</Paragraph>
            {availableTeamFeatureCategories.map((category) => (
              <SelectFeatureByCategory
                key={category.value}
                featuresByGrouping={featuresByGrouping}
                category={category}
                values={values}
                field="doNotWant"
                setFieldValue={setFieldValue}
                onSelect={() => submitForm()}
              />
            ))}
          </Pane>
        </Form>
      )}
    />
  );
};

const EditTeamPreferences = (props) => {
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);

  const { myCandidateProfile, showTeamPreferences, setShowTeamPreferences } = props;

  return (
    <Dialog
      isShown={showTeamPreferences}
      title="What type of team do you want to work on?"
      onCloseComplete={() => setShowTeamPreferences(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
    >
      <TeamPreferencesForm
        myCandidateProfile={myCandidateProfile}
        availableTeamFeatures={profileOptions?.availableTeamFeatures}
        availableTeamFeatureCategories={profileOptions?.availableTeamFeatureCategories}
      />
    </Dialog>
  );
};

export default EditTeamPreferences;
