import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import qs from "query-string";
import {
  Badge,
  Button,
  Card,
  EditIcon,
  Heading,
  InlineAlert,
  Pane,
  Paragraph,
  OrderedList,
  majorScale,
  defaultTheme as theme,
  minorScale,
  UnorderedList,
  ListItem
} from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";

import CandidateQueries from "../../../queries/candidate";

import EditJobSearch from "../edit/candidate/EditJobSearch";
import EditSalaryPreferences from "../edit/candidate/EditSalaryPreferences";
import EditWorkAuthorization from "../edit/candidate/EditWorkAuthorization";
import EditRolePreferences from "../edit/candidate/EditRolePreferences";

const jobSearchModes = {
  active: {
    intent: "success",
    label: "Actively looking"
  },

  passive: {
    intent: "none",
    label: "Open to exploring"
  },

  inactive: {
    intent: "none",
    label: "Create a candidate profile"
  }
};

const setupContractTypes = ({ employmentType }) => {
  if (_.isEqual(employmentType, "full_time")) {
    return `Permanent roles at a minimum annual salary of `;
  } else if (_.isEqual(employmentType, "part_time")) {
    return `Part-time roles at a minimum hourly rate of`;
  } else if (_.isEqual(employmentType, "contract")) {
    return `Contract roles at a minimum hourly rate of`;
  } else if (_.isEqual(employmentType, "internship")) {
    return `Internship roles`;
  } else return null;
};

const renderSalary = (number, timeframe) => {
  const str = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  }).format(number);

  return `${str}/${timeframe}`;
};

const renderPreference = ({ employmentPreference }) => {
  const preferences = {
    full_time: "Full-time preferred",
    part_time: "Part-time preferred",
    contract: "Contract preferred",
    internship: "Internship preferred"
  };

  return preferences[employmentPreference];
};

export const JobPreference = ({ isEditing, setShowSalary, showSalary, myCandidateProfile }) => (
  <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" marginBottom={majorScale(1)}>
    <Pane display="flex" flexDirection="row" alignItems="center">
      <Heading size={400} marginRight={majorScale(1)}>
        Looking for
      </Heading>
      {myCandidateProfile?.employmentPreference && <Badge>{renderPreference(myCandidateProfile)}</Badge>}
    </Pane>
    {isEditing && <EditSalaryPreferences setShowSalary={setShowSalary} showSalary={showSalary} myCandidateProfile={myCandidateProfile} />}
    {isEditing && (
      <Button appearance="minimal" onClick={() => setShowSalary(true)}>
        <EditIcon />
      </Button>
    )}
  </Pane>
);

export const ContractTypes = ({ isEditing, setShowSalary, showSalary, myCandidateProfile }) => {
  if (!myCandidateProfile?.employmentType?.length > 0) return null;
  if (myCandidateProfile?.minimumSalary === 0 && myCandidateProfile?.minimumHourlyRate === 0) return null;

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" marginBottom={majorScale(1)}>
      {myCandidateProfile?.employmentType?.length > 0 && (
        <UnorderedList>
          {myCandidateProfile?.employmentType?.map((employmentType, index) => {
            const rate = _.isEqual(employmentType, "full_time") ? myCandidateProfile?.minimumSalary : myCandidateProfile?.minimumHourlyRate;
            const timeframe = _.isEqual(employmentType, "full_time") ? "yr" : "hr";

            return (
              <ListItem>
                {setupContractTypes({ employmentType })} <b>{!_.isEqual(employmentType, "internship") && renderSalary(rate, timeframe)}</b>
              </ListItem>
            );
          })}
        </UnorderedList>
      )}
    </Pane>
  );
};

export const LocationPreference = ({ isEditing, myCandidateProfile, setShowRole, showRole, profile }) => (
  <Pane>
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" marginBottom={majorScale(1)}>
      <Pane display="flex" flexDirection="row">
        <Heading size={400} marginRight={majorScale(1)}>
          Location
        </Heading>
        {myCandidateProfile?.workStylePreference && <Badge>{`${myCandidateProfile?.workStylePreference} preferred`}</Badge>}
      </Pane>
      {isEditing && (
        <EditRolePreferences showRole={showRole} setShowRole={setShowRole} myCandidateProfile={myCandidateProfile} myProfile={profile} />
      )}
      {isEditing && (
        <Button appearance="minimal" onClick={() => setShowRole(true)}>
          <EditIcon />
        </Button>
      )}
    </Pane>

    {/* Work Location */}
    {myCandidateProfile?.workStyle?.includes("hybrid") && myCandidateProfile?.locationPreference?.length > 0 && (
      <Pane display="flex" flexDirection="column" alignItems="start" marginBottom={minorScale(1)}>
        <Heading size={300}>On-site or near, in priority order</Heading>
        {myCandidateProfile?.employmentType?.length > 0 && (
          <OrderedList>
            {myCandidateProfile?.locationPreference?.map((loc, index) => {
              return <ListItem>{`${loc?.city}, ${loc?.region}`}</ListItem>;
            })}
          </OrderedList>
        )}
      </Pane>
    )}

    {/* Timezone */}
    {myCandidateProfile?.workStyle?.includes("remote") && (
      <Pane marginBottom={majorScale(1)}>
        <Heading size={300}>Remote in timezone</Heading>
        <Paragraph size={400}>{myCandidateProfile?.timezonePreference}</Paragraph>
      </Pane>
    )}
  </Pane>
);

const CandidateJobSearch = ({ profile, isEditing, location, isRecruiter }) => {
  const { section } = qs.parse(location?.search, { arrayFormat: "comma" });
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile, {
    variables: {
      profileId: profile?.id
    }
  });
  const [showJobSearch, setShowJobSearch] = useState(section === "job-search");
  const [showSalary, setShowSalary] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [showWorkAuthorization, setShowWorkAuthorization] = useState(false);

  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const isAuthorized = myCandidateProfile?.visaStatus === "authorized";
  const wasImported = isEditing && myCandidateProfile?.automatedFields?.includes("skills");
  // const requiresSponsorship = myCandidateProfile?.requiresSponsorship
  const jobSearchMode = jobSearchModes[myCandidateProfile?.status] || { intent: "none", label: "Not looking" };

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass.includes("xs");

        return (
          <Card
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            backgroundColor="white"
            padding={majorScale(2)}
            marginBottom={majorScale(3)}
            marginTop={majorScale(3)}
            minHeight="100px"
            id="job-search"
          >
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(2)} alignItems="center">
              <Heading fontWeight={500}>
                <span style={{ marginRight: "6px" }}>🏆</span>
                Job Search
              </Heading>
              {!isRecruiter && <InlineAlert>Only visible our hiring partners</InlineAlert>}
            </Pane>
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
              <Heading size={400} marginRight={majorScale(1)}>
                Are you looking for a job?
              </Heading>
              <Pane display="flex" flexDirection="row">
                <InlineAlert intent={jobSearchMode.intent}>{jobSearchMode?.label}</InlineAlert>
                {isEditing && (
                  <Button appearance="minimal" onClick={() => setShowJobSearch(true)}>
                    <EditIcon />
                  </Button>
                )}
              </Pane>
              <EditJobSearch setShowJobSearch={setShowJobSearch} showJobSearch={showJobSearch} myCandidateProfile={myCandidateProfile} />
            </Pane>

            {myCandidateProfile?.id && !_.isEqual(myCandidateProfile?.status, "inactive") && (
              <>
                {/* Role Preferences */}
                <Pane>
                  <JobPreference
                    isEditing={isEditing}
                    setShowSalary={setShowSalary}
                    showSalary={showSalary}
                    myCandidateProfile={myCandidateProfile}
                  />
                  <ContractTypes
                    isEditing={isEditing}
                    setShowSalary={setShowSalary}
                    showSalary={showSalary}
                    myCandidateProfile={myCandidateProfile}
                  />
                </Pane>
                {/* Location Preference */}
                <Pane>
                  <LocationPreference
                    isEditing={isEditing}
                    myCandidateProfile={myCandidateProfile}
                    setShowRole={setShowRole}
                    showRole={showRole}
                    profile={profile}
                  />
                </Pane>
                {/* Work Authorization */}
                <Pane>
                  <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    marginBottom={minorScale(1)}
                  >
                    <Heading size={400}>USA Work Authorization</Heading>
                    <EditWorkAuthorization
                      showWorkAuthorization={showWorkAuthorization}
                      setShowWorkAuthorization={setShowWorkAuthorization}
                      myCandidateProfile={myCandidateProfile}
                    />
                    {isEditing && (
                      <Button appearance="minimal" onClick={() => setShowWorkAuthorization(true)}>
                        <EditIcon />
                      </Button>
                    )}
                  </Pane>
                  <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={majorScale(1)}
                    width="100%"
                    paddingRight={majorScale(2)}
                  >
                    <Paragraph size={400}>Are you authorized to work in the United States?</Paragraph>
                    <InlineAlert intent={isAuthorized ? "success" : "none"}>{isAuthorized ? "Yes" : "Require sponsorship"}</InlineAlert>
                  </Pane>
                </Pane>
              </>
            )}

            {wasImported && (
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                marginTop={majorScale(2)}
                marginBottom={majorScale(1)}
                alignItems="center"
              >
                <Paragraph size={400}>
                  <span style={{ marginRight: majorScale(1) }}>✨</span>
                  Automatically Imported
                </Paragraph>
              </Pane>
            )}
          </Card>
        );
      }}
    />
  );
};

export default CandidateJobSearch;
