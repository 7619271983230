import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import qs from "query-string";
import { Alert, Pane, Paragraph, Spinner, Heading, majorScale } from "evergreen-ui";

import ProfileList from "../../../../queries/profile/ProfileList";

const CalendarConnect = ({ location, history }) => {
  const [connectedAccount, setConnectedAccount] = useState(null);
  const params = qs.parse(location?.search, { arrayFormat: "comma" });
  const { accessToken, refreshToken } = params;
  const [connectGoogleCalendar] = useMutation(ProfileList.ConnectGoogleCalendar, {
    awaitRefetchQueries: true,
    // refetchQueries: [{ query: ProfileList.GoogleCalendarConnection }],
    onCompleted: (result) => {
      // Let the pop up know that the calendar is connected and close the window
      window.opener.postMessage({ googleConnected: result });
      window.close();
    }
  });

  if (_.isNull(connectedAccount) && accessToken && refreshToken) {
    setConnectedAccount(true);
    connectGoogleCalendar({
      variables: {
        accessToken,
        refreshToken
      }
    });
  }

  return (
    <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100vw" height="100vh">
      <Heading marginBottom={majorScale(2)}>Connecting Google Calendar</Heading>
      <Spinner />
    </Pane>
  );
};

export default CalendarConnect;
