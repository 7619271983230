import React from "react";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { Button, Heading, Pane, Paragraph, majorScale, minorScale, defaultTheme } from "evergreen-ui";

import TextInput from "../../components/form/TextInput";
import theme from "../../components/theme";

const REQUEST_RESET_PASSWORD = gql`
  mutation resetPassword($resetToken: String!, $password: String!) {
    resetPassword(resetToken: $resetToken, password: $password) {
      jwt
      id
      name {
        first
        last
      }
      linkedin
      bio
      schedule
      mode
      role {
        title
        team {
          name
        }
      }
    }
  }
`;

const GET_USER = gql`
  query getProfile {
    myProfile {
      id
      name {
        first
        last
      }
      linkedin
      bio
      schedule
      role {
        title
        team {
          name
        }
      }
    }
  }
`;

const equalTo = (ref, msg) => {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path
    },
    test: function (value) {
      return value === this.resolve(ref);
    }
  });
};

Yup.addMethod(Yup.string, "equalTo", equalTo);

const ResetPasswordToken = Yup.object().shape({
  password: Yup.string()
    .min(4, "Please include your new password")
    .max(50, "Please shorten the password you use")
    .required("Please include a password"),
  passwordConfirm: Yup.string()
    .min(4, "Please confirm your new password")
    .max(50, "Please shorten the password you use")
    .equalTo(Yup.ref("password"), "Passwords must match")
    .required("Required")
});

const ResetPasswordPage = ({ history, match }) => {
  const [resetPassword, { loading }] = useMutation(REQUEST_RESET_PASSWORD, {
    onCompleted: (result) => {
      if (result?.resetPassword?.mode?.includes("Recruit")) {
        history.push("/candidates/all");
      } else {
        history.push("/conversations");
      }
    },
    update: (cache, { data: { resetPassword } }) => {
      if (resetPassword) {
        const { jwt } = resetPassword;
        Cookies.set("token", jwt, { expires: 365 });

        // Update the cache to have the logged in user
        cache.writeQuery({
          query: GET_USER,
          data: { myProfile: resetPassword }
        });
      }
    }
  });

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          style={{
            width: width[screenClass],
            minHeight: "calc(100vh - 120px)",
            paddingTop: majorScale(2),
            paddingBottom: majorScale(6)
          }}
        >
          <Col xs={12} sm={10} md={8} lg={6} offset={{ sm: 1, md: 2, lg: 3 }}>
            <Formik
              validationSchema={ResetPasswordToken}
              initialValues={{ password: "", passwordConfirm: "" }}
              onSubmit={({ password }, { setSubmitting }) => {
                let resetToken = match ? match.params.token : "";
                resetPassword({ variables: { password, resetToken } });
                setSubmitting(false);
              }}
              render={({ errors, status, touched, isSubmitting }) => (
                <Pane
                  backgroundColor={"white"}
                  borderRadius={minorScale(3)}
                  border={`1px solid ${defaultTheme.colors.gray400}`}
                  width="100%"
                  padding={majorScale(4)}
                  marginBottom={majorScale(3)}
                >
                  <Form>
                    <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(2)}>
                      <Heading size={700} marginBottom={minorScale(1)}>
                        ✍️ &nbsp; Create a new password
                      </Heading>
                      <Paragraph size={500}>Please make a new password to use going forward</Paragraph>
                    </Pane>
                    <Pane>
                      <Field type="password" name="password" placeholder="Your new password" component={TextInput} />
                    </Pane>
                    <Pane>
                      <Field type="password" name="passwordConfirm" placeholder="Please confirm your password" component={TextInput} />
                    </Pane>
                    <Pane display="flex" justifyContent="center">
                      <Button type="submit" appearance="primary" disabled={isSubmitting} isLoading={loading}>
                        Create New Password
                      </Button>
                    </Pane>
                  </Form>
                </Pane>
              )}
            />
          </Col>
        </Row>
      )}
    />
  );
};

export default withRouter(ResetPasswordPage);
