import _ from "lodash";
import React from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import injectSheet from "react-jss";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import { Button, Link, Pane, majorScale } from "evergreen-ui";
import FAQLayout from "../../components/layout/FAQLayout";
import theme from "../../components/theme";
import Testimonial from "../../components/profile/Testimonial";
import EmailSignup from "./EmailSignup";

import MentorCard from "../../components/mentor/MentorCard";
import LandingMeta from "./LandingMeta";
import BlogSection from "../discover/sections/BlogSection";

import {
  faq,
  howItWorks,
  headline,
  headlineContext,
  learnersCTA,
  testimonials,
  scheduling,
  recruitingTestimonial
} from "../../content/learners";

const Kit = gql`
  query Kit($slug: String) {
    kit(slug: $slug) {
      id
      title
      subtitle
      logo
      slug
      headline
      description
      roles
      motivations
    }
  }
`;

const RandomCoaches = gql`
  query RandomCoaches {
    randomCoaches {
      id
      user {
        id
        slug
        fullName
        name {
          first
          last
        }
        bio
        picture
        jobRole
        level
        role {
          title
          team {
            id
            name
            logo
          }
        }
        roles {
          id
          title
          team {
            id
            name
          }
        }
      }
      status
    }
  }
`;

const styles = {
  p: {
    ...theme.p,
    textAlign: "center",
    paddingTop: majorScale(4),
    paddingBottom: majorScale(4)
  },
  b: {
    ...theme.b,
    textAlign: "center",
    paddingTop: majorScale(4),
    paddingBottom: majorScale(4)
  },
  s: {
    ...theme.s,
    textAlign: "center"
  },
  h1: {
    ...theme.h1,
    margin: 0,
    textAlign: "center"
  },
  h1Mobile: {
    ...theme.h1Mobile,
    textAlign: "center",
    margin: 0,
    paddingTop: majorScale(4)
  },
  h2: {
    ...theme.h2,
    margin: 0,
    textAlign: "left"
  },
  h2Mobile: {
    ...theme.h2Mobile,
    margin: 0,
    textAlign: "left"
  },
  image: {
    // marginTop: majorScale(3),
    // marginBottom: majorScale(3),
  },
  benefitDescription: {
    ...theme.p,
    margin: 0,
    padding: 0,
    textAlign: "left",
    paddingTop: majorScale(2)
  },
  tweets: {
    background: theme.color.darkBackground,
    paddingRight: theme.spacing.grid,
    paddingLeft: theme.spacing.grid,
    paddingBottom: theme.spacing.grid * 4
  },
  topics: {
    paddingRight: theme.spacing.grid,
    paddingLeft: theme.spacing.grid,
    paddingBottom: theme.spacing.grid * 2
  },
  testimonials: {
    paddingTop: theme.spacing.grid * 3,
    paddingBottom: theme.spacing.grid * 3
  },
  fullWidth: {
    marginRight: -15,
    marginLeft: -15
  }
};

const PlaylistPage = (props) => {
  const { data: sampleMentors } = useQuery(RandomCoaches);

  const { classes } = props;
  const { link, footer } = props;
  const search = props.location ? props.location.search : null;
  const { showGoogle } = queryString.parse(search);

  window.analytics.track("Landing Page -> Members");

  let topicHeadline = howItWorks[0].title;
  let topicDescription = howItWorks[0].description;
  let pageTitle = headline;
  let pageSubtitle = headlineContext;
  let logo = props?.location?.pathname?.includes("/climb") && "https://ucarecdn.com/eda940bf-746b-4634-afde-24b1fe224cd9/climb2.png";
  let pageMeta = <LandingMeta />;

  const slug = props?.match?.params?.slug;

  const { loading, error } = useQuery(Kit, { variables: { slug } });

  if (loading || error) {
  }

  return (
    <div>
      <link href={theme.fonts} rel="stylesheet" />
      {pageMeta}
      <LandingMeta />
      <div className={classes.fullWidth}>
        <Container>
          <Row align="start" justify="center">
            <Col md={8} xs={12}>
              <Pane
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                padding={majorScale(2)}
                margin={majorScale(2)}
              >
                <Hidden xs md>
                  {logo && (
                    <div>
                      <img src={logo} alt="Climb Credit" style={{ maxWidth: 200, marginBottom: majorScale(3) }} />
                    </div>
                  )}
                  <h1 className={classes.h1}>{pageTitle}</h1>
                  <div className={classes.b}>{pageSubtitle}</div>
                </Hidden>
                <Visible xs md>
                  {logo && (
                    <div>
                      <img src={logo} alt="Climb Credit" style={{ maxWidth: 200, marginBottom: majorScale(3) }} />
                    </div>
                  )}
                  <h1 className={classes.h1Mobile}>{pageTitle}</h1>
                  <div className={classes.p}>{pageSubtitle}</div>
                </Visible>
                <EmailSignup cta={learnersCTA} mode={"Learn"} showGoogle={showGoogle} />
              </Pane>
            </Col>
          </Row>
        </Container>
        <Container>
          <div>
            <Container>
              <Row align="center" justify="center" className={classes.testimonials} background="red">
                <Col md={10}>
                  <Hidden xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <h2 className={classes.h2} style={{ textAlign: "left" }}>
                        {topicHeadline}
                      </h2>
                    </Pane>
                    <div className={classes.benefitDescription}>{topicDescription}</div>
                  </Hidden>
                  <Visible xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <h2 className={classes.h2Mobile} style={{ textAlign: "left" }}>
                        {topicHeadline}
                      </h2>
                    </Pane>
                    <div className={classes.benefitDescription}>{topicDescription}</div>
                  </Visible>
                </Col>
              </Row>
              <Row>
                {sampleMentors?.randomCoaches?.length > 0 &&
                  _.take(sampleMentors?.randomCoaches, 4).map((mentor) => (
                    <Col xs={12} sm={6} md={4} lg={3}>
                      <MentorCard user={mentor?.user} />
                      <Visible xs>
                        <Pane display="flex" width="100%" marginBottom={majorScale(2)} />
                      </Visible>
                    </Col>
                  ))}
              </Row>
              <Row>
                <Col sm={12}>
                  <Pane display="flex" justifyContent="center" alignItems="center" width={"100%"} paddingTop={majorScale(6)}>
                    <Link href={"/mentors"} style={{ textDecoration: "none" }}>
                      <Button appearance="primary">Browse all mentors</Button>
                    </Link>
                  </Pane>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row align="center" justify="center" className={classes.testimonials}>
                <Col md={6}>
                  <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
                    <img src={scheduling} style={{ width: "100%" }} />
                  </Pane>
                </Col>
                <Col md={6}>
                  <Hidden xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <div className={classes.h2} style={{ textAlign: "left" }}>
                        {howItWorks[1].title}
                      </div>
                    </Pane>
                    <div className={classes.benefitDescription}>{howItWorks[1].description}</div>
                  </Hidden>
                  <Visible xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <div className={classes.h2Mobile} style={{ textAlign: "left" }}>
                        {howItWorks[1].title}
                      </div>
                    </Pane>
                    <div className={classes.benefitDescription}>{howItWorks[1].description}</div>
                  </Visible>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row align="center" justify="center" className={classes.testimonials}>
                <Col md={6}>
                  <Hidden xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <div className={classes.h2} style={{ textAlign: "left" }}>
                        {howItWorks[2].title}
                      </div>
                    </Pane>
                    <div className={classes.benefitDescription}>{howItWorks[2].description}</div>
                  </Hidden>
                  <Visible xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <div className={classes.h2Mobile} style={{ textAlign: "left" }}>
                        {howItWorks[2].title}
                      </div>
                    </Pane>
                    <div className={classes.benefitDescription} style={{ marginBottom: majorScale(6) }}>
                      {howItWorks[2].description}
                    </div>
                  </Visible>
                </Col>
                <Col md={6}>
                  <Pane display="flex" alignItems="center" justifyContent="space-between" paddingRight={majorScale(3)}>
                    <Testimonial testimonial={recruitingTestimonial} />
                  </Pane>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row align="center" justify="center" className={classes.testimonials}>
                <Col md={10}>
                  <Hidden xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <div className={classes.h2} style={{ textAlign: "left" }}>
                        {howItWorks[3].title}
                      </div>
                    </Pane>
                    <div className={classes.benefitDescription}>{howItWorks[3].description}</div>
                  </Hidden>
                  <Visible xs md>
                    <Pane display="flex" alignItems="center" justifyContent="space-between">
                      <div className={classes.h2Mobile} style={{ textAlign: "left" }}>
                        {howItWorks[3].title}
                      </div>
                    </Pane>
                    <div className={classes.benefitDescription}>{howItWorks[3].description}</div>
                  </Visible>
                </Col>
              </Row>
              <BlogSection onlyPosts />
              <Row>
                <Col sm={12}>
                  <Pane display="flex" justifyContent="center" alignItems="center" width={"100%"} paddingTop={majorScale(6)}>
                    <Link href={"https://blog.get-merit.com"} style={{ textDecoration: "none" }}>
                      <Button appearance="primary">Read more</Button>
                    </Link>
                  </Pane>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={12} md={10}>
                  <Pane padding={majorScale(2)} margin={majorScale(2)} display="flex" alignItems="center" justifyContent="center">
                    <div className={classes.h2}>{"What our members are saying"}</div>
                  </Pane>
                </Col>
                {testimonials.map((testimonial) => (
                  <Col xs={12} sm={6} md={4}>
                    <Testimonial testimonial={testimonial} marginBottom />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </Container>
        <Container>
          <FAQLayout title="Frequently asked questions" faq={faq} />
          {footer}
        </Container>
      </div>
    </div>
  );
};

export default injectSheet(styles)(PlaylistPage);
