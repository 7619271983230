import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { formatDistanceToNow } from "date-fns";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Heading,
  Pane,
  Paragraph,
  PlusIcon,
  RemoveIcon,
  StatusIndicator,
  TextInput,
  majorScale,
  defaultTheme as theme
} from "evergreen-ui";

import TextArea from "../form/TextArea";
import { isMentor } from "./utils";

import SessionQueries from "../../queries/session";
import ProfileList from "../../queries/profile/ProfileList";

const QUESTIONS_TITLE = "What are three questions that you’re looking to discuss?";
const QUESTIONS_INFO =
  "Ensure it’s a productive conversation and write out what you’re looking to ask. We’ll share the questions and notes ahead of the meeting.";
const MENTOR_QUESTIONS_INFO = "These are questions submitted in advance of the session.";

const CONTEXT_TITLE = "What have you tried so far? Any additional context that would be helpful?";
const CONTEXT_INFO =
  "We recommend jotting down any relevant experiences to jog your memory. Specific examples, stories, or events are a great way to get more specific and actionable help.";
const MENTOR_CONTEXT_INFO = "This is additional context submitted in advanced of the session.";

const PrepareForm = ({ myProfile, session }) => {
  const [saved, setSaved] = useState(null);
  const [updatePrep, { loading: saving }] = useMutation(SessionQueries.mutations.UpdateSessionPrep, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.SessionAndReview,
        variables: {
          sessionID: session.id
        }
      },
      {
        query: SessionQueries.queries.SessionAndReviewById,
        variables: {
          id: session.id
        }
      },
      { query: ProfileList.LayoutQuery }
    ],
    onCompleted: () => {
      setSaved(`${formatDistanceToNow(new Date())}`);
    }
  });

  return (
    <Formik
      initialValues={{
        questions: session?.questions ? session.questions : [],
        context: session?.context ? session.context : ""
      }}
      onSubmit={({ questions, context }) => {
        updatePrep({
          variables: {
            questions,
            context,
            id: session?.id
          }
        });
      }}
      render={({ values: { questions, context }, errors, status, touched, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Pane marginBottom={majorScale(2)}>
                  <Heading size={500} marginBottom={majorScale(1)}>
                    {QUESTIONS_TITLE}
                  </Heading>
                  <Paragraph size={500}>{QUESTIONS_INFO}</Paragraph>
                </Pane>
                <Pane>
                  {questions.map((question, index) => (
                    <Pane width="100%" display="flex" flexDirection="row" marginBottom={majorScale(2)} alignItems="center">
                      <TextInput
                        width="100%"
                        value={questions[index]}
                        onBlur={(e) => {
                          questions[index] = e.target.value;
                          setFieldValue("questions", questions, false);
                        }}
                        onChange={(e) => {
                          questions[index] = e.target.value;
                          setFieldValue("questions", questions, false);
                        }}
                      />
                      <RemoveIcon
                        marginLeft={majorScale(2)}
                        color="danger"
                        onClick={() => {
                          questions.splice(index, 1);
                          setFieldValue("questions", questions);
                        }}
                      />
                    </Pane>
                  ))}
                  <Button appearance="minimal" iconBefore={PlusIcon} onClick={() => questions.push("")}>
                    {"Add Question"}
                  </Button>
                </Pane>
              </Pane>
            </Pane>

            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Heading size={500} marginBottom={majorScale(1)}>
                  {CONTEXT_TITLE}
                </Heading>
                <Paragraph size={500} marginBottom={majorScale(2)}>
                  {CONTEXT_INFO}
                </Paragraph>
                <Field
                  name={"context"}
                  type="textarea"
                  defaultValue={context}
                  component={TextArea}
                  placeholder={""}
                  onBlur={() => setFieldValue("context", context, false)}
                />
              </Pane>
            </Pane>

            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              paddingTop={majorScale(2)}
              paddingBottom={majorScale(6)}
            >
              {saved && <Paragraph marginRight={majorScale(1)}>{`Saved ${saved} ago.`}</Paragraph>}
              <Button type="submit" appearance="primary" intent="primary" isLoading={saving}>
                Save Notes
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const PrepareDetail = ({ myProfile, session }) => {
  const { context, questions } = session;

  return (
    <>
      <Pane display="flex" justifyContent="center" flexDirection="column">
        <Pane marginBottom={majorScale(2)}>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            padding={majorScale(4)}
            borderRadius={majorScale(2)}
            border={`1px solid ${theme.colors.gray500}`}
            backgroundColor="white"
            minHeight="100px"
          >
            <Pane marginBottom={majorScale(2)}>
              <Heading size={500} marginBottom={majorScale(1)}>
                {QUESTIONS_TITLE}
              </Heading>
              <Paragraph size={500}>{MENTOR_QUESTIONS_INFO}</Paragraph>
            </Pane>
            <Pane>
              {questions.map((question, index) => (
                <Pane width="100%" display="flex" flexDirection="row" marginBottom={majorScale(2)} alignItems="center">
                  <StatusIndicator color="info">{question}</StatusIndicator>
                </Pane>
              ))}
            </Pane>
          </Pane>
        </Pane>

        <Pane marginBottom={majorScale(2)}>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            padding={majorScale(4)}
            borderRadius={majorScale(2)}
            border={`1px solid ${theme.colors.gray500}`}
            backgroundColor="white"
            minHeight="100px"
          >
            <Heading size={500} marginBottom={majorScale(1)}>
              {CONTEXT_TITLE}
            </Heading>
            <Paragraph size={500} marginBottom={majorScale(2)}>
              {MENTOR_CONTEXT_INFO}
            </Paragraph>
            <Paragraph>{context}</Paragraph>
          </Pane>
        </Pane>
      </Pane>
    </>
  );
};

const PrepareFormContainer = ({ myProfile, session }) => {
  const showMentorView = isMentor({ myProfile, session });

  return (
    <>
      {showMentorView ? <PrepareDetail myProfile={myProfile} session={session} /> : <PrepareForm myProfile={myProfile} session={session} />}
    </>
  );
};

export default PrepareFormContainer;
