import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Pane, Spinner } from "evergreen-ui";

import DiscussionItem from "./DiscussionItem";

const loader = (
  <Pane display="flex" flexDirection="row" width="100%" justifyContent="center">
    <Spinner />
  </Pane>
);

const CommentIndex = ({ comments, next, ...props }) =>
  comments?.edges?.length ? (
    <InfiniteScroll
      style={{ display: "flex", flexWrap: "wrap" }}
      hasMore={comments.pageInfo.hasNextPage}
      dataLength={comments.edges.length}
      loader={loader}
      next={() => next()}
    >
      {comments.edges.map((comment) =>
        comment?.id && comment?.owner?.id ? (
          <DiscussionItem key={`CommentIndex-DiscussionItem-${comment.id}`} long comment={comment} hideReplies {...props} />
        ) : null
      )}
    </InfiniteScroll>
  ) : null;

export default CommentIndex;
