import _ from "lodash";
import React from "react";
import injectSheet from "react-jss";

import Link from "../Link";
import theme from "../theme";

const styles = {
  link: {
    textDecoration: "none",
    color: theme.color.whiteText,

    ...theme.h1,

    "&:hover": {
      color: theme.color.brand,
      transition: "color .15s cubic-bezier(0.215, 0.61, 0.355, 1)"
    }
  },
  mobile: {
    ...theme.h3,
    textDecoration: "none",
    color: theme.color.text,
    fontSize: "16px",
    fontWeight: 400,
    padding: 0,
    margin: 0,
    textAlign: "center",
    "&:hover": {
      textDecoration: "underline",
      transition: "color .15s cubic-bezier(0.215, 0.61, 0.355, 1)"
    }
  },
  selected: {
    fontWeight: 700,
    color: "#000"
  }
};

const NavItem = ({ classes, item: { title, link }, mobile, location }) => {
  const selected = location?.pathname === link;

  return (
    <span>
      {mobile ? (
        <Link to={link} style={{ ...styles.mobile, ...(selected ? styles.selected : {}) }}>
          {title}
        </Link>
      ) : (
        <Link to={link} className={classes.link}>
          {title}
        </Link>
      )}
    </span>
  );
};

export default injectSheet(styles)(NavItem);
