import _ from "lodash";
import React from "react";
import { Dialog, Pane, Button, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";
import FeedQueries from "../../queries/feed";

const ReplyDelete = ({ reply, onUpdate, onDelete, commentId, postId }) => {
  const [mutation, { loading, error }] = useMutation(FeedQueries.mutations.DeleteReply, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.FeedQuery },
      { query: FeedQueries.queries.PostById, variables: { id: postId } },
      { query: FeedQueries.queries.Comments, variables: { postId } },
      { query: FeedQueries.queries.Replies, variables: { commentId } }
    ],
    onCompleted: () => {
      toaster.success("Reply deleted.", { duration: 2 });

      if (onDelete && _.isFunction(onDelete)) {
        onDelete({ reply });
      }
    }
  });

  if (!reply) return null;

  const deleteReply = () =>
    mutation({
      variables: {
        id: reply.id
      }
    });

  return (
    <Dialog
      isShown={true}
      title="Are you sure you want to delete this reply?"
      intent="danger"
      onCloseComplete={onUpdate}
      hasFooter={false}
      preventBodyScrolling
    >
      <Pane display="flex" justifyContent="space-between" width="100%">
        <Button appearance="minimal" onClick={onUpdate}>
          Cancel
        </Button>
        <Button appearance="primary" onClick={deleteReply}>
          Delete
        </Button>
      </Pane>
    </Dialog>
  );
};

export default ReplyDelete;
