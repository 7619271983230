import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  const title = `Discussions | Merit`;
  const description = `Learn and grow with discussions. Thousands in tech are here to help you in your career.`;
  const twitterURL = `@meritmentor`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:description" property="og:description" content={description} />

      <meta name="og:url" property="og:url" content={window.location.href} />

      <meta name="twitter:card" property="twitter:card" content="summary" />
      <meta name="twitter:site" property="twitter:site" content={twitterURL} />
      <meta name="twitter:title" property="twitter:title" content={title} />
      <meta name="twitter:description" property="twitter:description" content={description} />
    </Helmet>
  );
};

export default Meta;
