import _ from "lodash";
import React, { useState } from "react";
import { Alert, Button, Pane, Heading, Paragraph, majorScale, minorScale } from "evergreen-ui";

import { useQuery } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";
import CalendarConflicts from "../../../../components/onboarding/coach/CalendarConflicts";
import isEmail from "validator/lib/isEmail";

let windowObjectReference = null;
let previousURL = null;

// Receve a message from the pop up when the calendar connection is finished
const buildReceiveMessage = (refetchGoogleInfo, setShowWarning) => {
  const fn = (e) => {
    if (e?.data?.googleConnected?.googleCalendarConnect) {
      refetchGoogleInfo();
    } else {
      setShowWarning(true);
    }
  };

  return fn;
};

const openSignInWindow = (url, name, receiveMessage, setShowWarning) => {
  // remove any existing event listeners
  window.removeEventListener("message", receiveMessage);
  setShowWarning(false);

  // window features
  const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    // If the pointer to the window object in memory does not exist
    // or if such pointer exists but the window was closed

    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousURL !== url) {
    // If the resouce to load is different, then we load it in the already
    // opened secondary window and then we bring such window back on top/in
    // front of its parent window
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    // The window reference must exist and the window is not closed; therefore
    // we can bring it back on top of any other window with the focus() method.
    // There would be no need to recreated the window or to reload the referenced resource
    windowObjectReference.focus();
  }

  // Add the listener for receiving a message from the popup
  window.addEventListener("message", (event) => receiveMessage(event), false);

  // Assign the previous URL
  previousURL = url;
};

const GoogleSignInButton = ({ clickButton, existingConnection }) => {
  return (
    <Button
      iconBefore={<img src={"https://ucarecdn.com/87701a43-99c5-4986-bd3f-528c5ac809de/-/scale_crop/30x30/smart/"} />}
      appearance="default"
      onClick={clickButton}
    >
      {"Sync Google Calendar"}
    </Button>
  );
};

const EditConflicts = () => {
  const [showWarning, setShowWarning] = useState(false);
  const { data: googleResult, refetch: refetchGoogleInfo } = useQuery(ProfileList.GoogleConnectLink);
  const { data: nylasInfo } = useQuery(ProfileList.NylasType);
  const receiveMessage = buildReceiveMessage(refetchGoogleInfo, setShowWarning);

  const clickGoogleButton = () =>
    openSignInWindow(googleResult?.googleConnectLink, "Connect Merit Calendar", receiveMessage, setShowWarning);

  const existingConnection = googleResult?.myProfile?.googleCalendar && googleResult?.myProfile?.googleCalendar?.length > 0;

  return (
    <>
      <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
        <Pane display="flex" flexDirection="row" justifyContent="space-between">
          <Pane>
            <Heading marginBottom={minorScale(1)}>🏁 &nbsp; Do you want to check for conflicts?</Heading>
            <Paragraph>Merit can check your existing Google Calendar for conflicts.</Paragraph>
          </Pane>
          {!existingConnection && <GoogleSignInButton clickButton={clickGoogleButton} existingConnection={existingConnection} />}
        </Pane>

        {existingConnection ? (
          <>
            <CalendarConflicts googleCalendar={googleResult?.myProfile?.googleCalendar} existingConnection={existingConnection} />
            <Pane display="flex" flexDirection="row" justifyContent="flex-end" paddingTop={majorScale(1)}>
              <GoogleSignInButton clickButton={clickGoogleButton} existingConnection={existingConnection} />
            </Pane>
          </>
        ) : (
          <Pane display="flex" flexDirection="column" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
            {!showWarning && (
              <Alert intent="warning" title="Not checking calendar conflicts">
                {nylasInfo?.nylasAccount && isEmail(nylasInfo?.nylasAccount)
                  ? "You need to re-connect your Google Calendar to check for conflicts."
                  : "Connect your Google Calendar to check for conflicts."}
              </Alert>
            )}
          </Pane>
        )}
        {showWarning && (
          <Pane display="flex" flexDirection="column" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
            <Alert intent="danger" title="Connection not successful">
              <Paragraph marginY={majorScale(1)}>
                {`Be sure to check all the boxes on the screen to ensure proper syncing and click “Continue.”`}
              </Paragraph>
              <img
                style={{ width: 500, marginTop: majorScale(1) }}
                src={"https://ucarecdn.com/4df2b7b3-e5ed-4ec8-bd37-725f6a503fd0/ScreenShot20230112at102023AM2.png"}
              />
            </Alert>
          </Pane>
        )}
      </Pane>
    </>
  );
};

export default EditConflicts;
