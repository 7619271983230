import React, { useState } from "react";
import _ from "lodash";
import { Link, Avatar, Pane, Heading, Text, Button, Pill, majorScale, defaultTheme as theme, toaster } from "evergreen-ui";

import CoachAvailabilityPill from "../coach/CoachAvailabilityPill";
import HoverLink from "../HoverLink";

import { Link as BrowserLink } from "react-router-dom";

import { useQuery } from "@apollo/client";
import ConversationQueries from "../../queries/conversation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const truncRole = (role) => {
  if (_.isEqual("Software Engineering", role)) {
    return "Engineering";
  } else if (_.isEqual("Product Management", role)) {
    return "Product";
  } else if (_.isEqual("Product Design", role)) {
    return "Design";
  } else {
    return role;
  }
};

const truncLevel = (level) => {
  if (_.isEqual("Individual Contributor", level)) {
    return "IC";
  } else {
    return level;
  }
};

const cleanLink = (link) => {
  if (!link.includes(`https://`)) {
    link = `https://${link}`;
  }

  return link;
};

const renderMessage = (conversationResult) => {
  return conversationResult?.thread?.id ? (
    <Button appearance="minimal" is={BrowserLink} iconBefore="chat" to={`/conversation/${conversationResult?.thread?.id}`}>
      Message
    </Button>
  ) : null;
};

const renderBio = (profile) => {
  const jobRole = profile.jobRole;
  const level = profile.level;
  const shortRole = truncRole(jobRole);
  const shortLevel = truncLevel(level);

  return (
    <Pane display="flex" flexDirection="column" justifyContent="flex-end">
      {_.isNil(jobRole) ? null : (
        <Pill color="orange" marginBottom={majorScale(1)}>
          {shortRole}
        </Pill>
      )}
      {_.isNil(level) ? null : (
        <Pill color="teal" marginBottom={majorScale(1)}>
          {shortLevel}
        </Pill>
      )}
    </Pane>
  );
};

const renderTwitter = (profile) => {
  const twitter = profile.twitter ? (
    <Link is="a" target="_blank" href={cleanLink(profile.twitter)} marginLeft={majorScale(1)}>
      <FontAwesomeIcon icon={faTwitter} />
    </Link>
  ) : null;

  return twitter;
};

const renderLinkedIn = (profile) => {
  const linkedin = profile.linkedin ? (
    <Link is="a" target="_blank" href={cleanLink(profile.linkedin)} marginLeft={majorScale(1)}>
      <FontAwesomeIcon icon={faLinkedinIn} />
    </Link>
  ) : null;

  return linkedin;
};

const renderWebsite = (profile) => {
  if (!profile?.website) return null;

  return (
    <Link is="a" target="_blank" href={cleanLink(profile.website)} marginLeft={majorScale(1)}>
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </Link>
  );
};

const ProfileOverview = (props) => {
  const { data: conversationResult } = useQuery(ConversationQueries.HasConversation, {
    variables: { userID: props?.profile?.id }
  });

  const { profile, coachByProfileId, clickable, mini, overrideAvailability } = props;
  if (!profile?.id) return null;

  const title = profile?.role?.title ? profile?.role?.title : "";
  const team = profile?.role?.team?.name ? profile?.role?.team?.name : "";
  let availability = null;

  if (!_.isNil(coachByProfileId)) {
    if (!_.isNil(overrideAvailability)) {
      /* For the landing page we'll always show the coach is active. */
      availability = <CoachAvailabilityPill isAvailable={overrideAvailability} />;
    } else {
      const isAvailable = coachByProfileId.isAvailable && coachByProfileId.status === "Active";
      availability = <CoachAvailabilityPill isAvailable={isAvailable} />;
    }
  }

  let rightPane = null;

  if (clickable) {
    if (!mini) {
      rightPane = renderBio(profile);
    }
  } else {
    rightPane = renderMessage(conversationResult);
  }

  const card = (
    <Pane
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      width="100%"
      justifyContent="space-between"
      display="flex"
      alignItems="center"
    >
      <Pane display="flex" alignItems="center">
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Avatar border="default" src={profile.picture} name={profile.fullName} size={majorScale(7)} />
          {availability}
        </Pane>
        <Pane display="flex" flexDirection="column" alignItems="start" paddingLeft={majorScale(2)}>
          <Heading>
            <HoverLink to={`/p/${profile?.slug}`}>{profile.fullName}</HoverLink>
            {profile.pronouns ? ` (${profile.pronouns})` : null}
            {clickable ? null : renderTwitter(profile)}
            {clickable ? null : renderLinkedIn(profile)}
            {clickable ? null : renderWebsite(profile)}
          </Heading>
          <Text>{title}</Text>
          <Text>{team}</Text>
        </Pane>
      </Pane>
      {rightPane}
    </Pane>
  );

  if (clickable) {
    const defaultLink = profile && profile.slug ? `/p/${profile.slug}` : `/profile/${profile.id}`;

    return (
      <Link is={BrowserLink} to={defaultLink} style={{ textDecoration: "none" }}>
        {card}
      </Link>
    );
  } else {
    return card;
  }
};

export default ProfileOverview;
