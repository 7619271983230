import React, { useState } from "react";
import { Button, Pane, majorScale } from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";

import ReplyForm from "./ReplyForm";

const WRITE_REPLY_TITLE = "Reply";

const CreateReply = ({ commentId, postId, myProfile, onShown }) => {
  const [isShown, setShown] = useState(false);

  const closePanel = () => {
    setShown(false);
    onShown();
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Pane
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={["xs"].includes(screenClass) ? null : majorScale(6)}
          justifyContent="flex-end"
          width="100%"
        >
          {isShown ? (
            <ReplyForm commentId={commentId} postId={postId} onUpdate={closePanel} onClose={closePanel} />
          ) : (
            <Button
              appearance="minimal"
              size="small"
              onClick={() => {
                window.analytics.track("Feed -> Respond to comment");
                setShown(true);

                if (onShown) {
                  onShown();
                }
              }}
            >
              {WRITE_REPLY_TITLE}
            </Button>
          )}
        </Pane>
      )}
    />
  );
};

export default CreateReply;
