import _ from "lodash";
import React, { useState } from "react";
import { Alert, Pane, majorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import Group from "../../../components/group/Group";
import ProfileQuery from "../../../components/profile/ProfileQuery";
import { clearbitOrgOptions } from "../../../components/profile/AutoFill";
import GroupQueries from "../../../queries/group";
import AsyncSelect from "react-select/async";

const GROUP_DELETED_TOAST = "Group removed.";
const GROUP_ADDED_TOAST = "Group added.";
const EMPTY_LIST = "No groups selected.";

const CreateGroup = ({ onCreate, myProfile }) => {
  const [addGroup] = useMutation(GroupQueries.mutations.AddToGroup, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileQuery, variables: { slug: myProfile?.slug } }],
    onCompleted: () => {
      onCreate();
      toaster.success(GROUP_ADDED_TOAST, { duration: 2 });
      window.analytics.track("Update Groups");
    }
  });

  return (
    <AsyncSelect
      style={{ width: "100%" }}
      cacheOptions
      loadOptions={clearbitOrgOptions}
      onChange={(selected) => {
        const { domain, logo, name } = selected.value;
        addGroup({ variables: { domain, logo, name } });
      }}
    />
  );
};

const EditGroupsForm = ({ myProfile }) => {
  const [groups, setGroups] = useState(myProfile?.groups);
  const [deleteGroup] = useMutation(GroupQueries.mutations.RemoveFromGroup, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileQuery, variables: { slug: myProfile?.slug } }]
  });

  return (
    <Pane>
      <Pane display="flex" flexDirection="column">
        <CreateGroup onCreate={() => setGroups(myProfile?.groups)} />
      </Pane>
      <Pane marginTop={majorScale(2)}>
        {groups && !_.isEmpty(groups) ? (
          groups.map((group, index) => (
            <Group
              group={group}
              key={`GroupsForm-group-${group.id}`}
              actionTitle="Remove"
              action={() => {
                deleteGroup({ variables: { groupId: group?.id } });
                setGroups(_.reject(groups, { id: group.id }));

                toaster.success(GROUP_DELETED_TOAST, { duration: 2 });
                window.analytics.track("Update Groups");
              }}
            />
          ))
        ) : (
          <Pane>
            <Alert intent="warning" title={EMPTY_LIST} marginTop={majorScale(2)} marginBottom={majorScale(2)} />
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

export default EditGroupsForm;
