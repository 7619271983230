import _ from "lodash";
import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import { Checkbox, Dialog, Heading, Pane, Paragraph, RadioGroup, majorScale, toaster, minorScale } from "evergreen-ui";
import * as Yup from "yup";

import CandidateQueries from "../../../../queries/candidate";
import TextInput from "../../../../components/form/TextInput";

const showSuccess = (copy) =>
  _.throttle(() => {
    toaster.success(copy, { duration: 2, id: copy });
  }, 5000);

const CONTRACT_TYPES = [
  { value: "full_time", label: "Permanent (full-time)" },
  { value: "part_time", label: "Part-time" },
  { value: "contract", label: "Contract" },
  { value: "internship", label: "Internship" }
];

const EMPLOYMENT_PREFERENCES = [
  { value: "full_time", label: "Prefer permanent roles" },
  { value: "part_time", label: "Prefer part-time roles" },
  { value: "contract", label: "Prefer contract roles" },
  { value: "internship", label: "No preferences" }
];

const initEmploymentOptions = ({ myCandidateProfile, setEmploymentOptions }) => {
  let initial = {};

  if (myCandidateProfile?.employmentType?.length) {
    initial = _.zipObject(_.map(CONTRACT_TYPES, "value"), _.fill(Array(CONTRACT_TYPES.length), false));
    _.forEach(myCandidateProfile.employmentType, (type) => {
      initial[type] = true;
    });
  } else {
    initial = _.zipObject(_.map(CONTRACT_TYPES, "value"), _.fill(Array(CONTRACT_TYPES.length), false));
  }

  setEmploymentOptions(initial);
};

const EmploymentTypeForm = ({ myCandidateProfile }) => {
  const [selectedEmploymentOptions, setEmploymentOptions] = useState(null);
  const [updateEmploymentType] = useMutation(CandidateQueries.mutations.UpdateEmploymentType, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CandidateQueries.queries.MyCandidateProfile
      }
    ],
    onCompleted: (result) => {
      showSuccess("Salary preferences updated.")();
    }
  });

  const chooseEmploymentType = (selectedOptions, setFieldValue) => {
    const selected = _.pickBy(selectedOptions, (value) => value);
    const selectedKeys = _.keys(selected);

    setFieldValue("employmentType", selectedKeys, false);
  };

  if (_.isNull(selectedEmploymentOptions)) {
    initEmploymentOptions({ myCandidateProfile, setEmploymentOptions });
  }

  return (
    <Formik
      initialValues={{
        employmentType: myCandidateProfile?.employmentType || ["full_time"],
        employmentPreference: myCandidateProfile?.employmentPreference || "permanent",
        minimumSalary: myCandidateProfile?.minimumSalary || 0,
        minimumHourlyRate: myCandidateProfile?.minimumHourlyRate || 0
      }}
      validationSchema={Yup.object().shape({
        employmentType: Yup.array().of(Yup.string()),
        employmentPreference: Yup.string(),
        minimumSalary: Yup.number(),
        minimumHourlyRate: Yup.number()
      })}
      onSubmit={(variables, { setSubmitting }) => {
        updateEmploymentType({
          variables: {
            employmentType: variables?.employmentType,
            employmentPreference: variables?.employmentPreference,
            minimumSalary: Number(variables?.minimumSalary),
            minimumHourlyRate: Number(variables?.minimumHourlyRate)
          }
        });
      }}
      render={({ values, setFieldValue, submitForm }) => (
        <Pane>
          <Pane>
            <Heading>What type of employment are you seeking?</Heading>
            <Paragraph>You can select multiple.</Paragraph>
          </Pane>
          <Pane>
            {CONTRACT_TYPES.map(({ value, label }) => (
              <Checkbox
                key={`EmploymentType-${value}`}
                label={label}
                value={value}
                checked={selectedEmploymentOptions[value]}
                onChange={(e) => {
                  if (selectedEmploymentOptions[value]) {
                    selectedEmploymentOptions[value] = false;
                    setEmploymentOptions({ ...selectedEmploymentOptions, [value]: false });
                  } else {
                    selectedEmploymentOptions[value] = true;
                    setEmploymentOptions({ ...selectedEmploymentOptions, [value]: true });
                  }

                  chooseEmploymentType(selectedEmploymentOptions, setFieldValue);
                  submitForm();
                }}
              />
            ))}
          </Pane>

          <Pane>
            <Heading>What is your employment preference?</Heading>
          </Pane>
          <RadioGroup
            value={values?.employmentPreference}
            options={EMPLOYMENT_PREFERENCES}
            onChange={(e) => {
              setFieldValue("employmentPreference", e.target.value, false);
              submitForm();
            }}
          />

          {_.values(selectedEmploymentOptions).some((value) => value) && (
            <Pane>
              <Heading marginBottom={minorScale(1)}>Compensation Preferences</Heading>
            </Pane>
          )}

          {selectedEmploymentOptions.full_time && (
            <Pane
              width="100%"
              display="flex"
              flexDirection="row"
              marginBottom={minorScale(1)}
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size={400}>Minimum base salary</Heading>
              <Field
                type="number"
                name="minimumSalary"
                placeholder="$ / year"
                component={TextInput}
                height={majorScale(5)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("minimumSalary", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>
          )}

          {(selectedEmploymentOptions.contract || selectedEmploymentOptions.part_time || selectedEmploymentOptions.internship) && (
            <Pane
              width="100%"
              display="flex"
              flexDirection="row"
              marginBottom={minorScale(1)}
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size={400}>{"Hourly rate (for contract roles)"}</Heading>
              <Field
                type="number"
                name="minimumHourlyRate"
                placeholder="$ / hour"
                component={TextInput}
                height={majorScale(5)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("minimumHourlyRate", e.target.value, false);
                  submitForm();
                }}
              />
            </Pane>
          )}
        </Pane>
      )}
    />
  );
};

const EditSalaryPreferences = ({ showSalary, setShowSalary, myCandidateProfile }) => {
  return (
    <Dialog
      isShown={showSalary}
      title="What type of role are you looking for?"
      onCloseComplete={() => setShowSalary(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
    >
      <EmploymentTypeForm myCandidateProfile={myCandidateProfile} />
    </Dialog>
  );
};

export default EditSalaryPreferences;
