import React from "react";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Alert, Heading, Pane, majorScale } from "evergreen-ui";

import Loader from "../../components/Loader";
import Feedback from "../../components/call/Feedback";

import GET_REVIEW_QUERY from "./ReviewQuery";

const ERROR_MSG = "We were not able to load this review. Please try again in a few minutes.";

const ReviewDetail = ({ match }) => {
  const {
    data: reviewResult,
    loading,
    error
  } = useQuery(GET_REVIEW_QUERY, {
    variables: { reviewID: match?.params?.reviewID }
  });

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <>
      {!reviewResult && loading && <Loader />}
      {!reviewResult && error && <Alert intent="danger" title={ERROR_MSG} />}
      {reviewResult && (
        <>
          {!reviewResult?.reviewById || !reviewResult?.reviewById?.session ? (
            <Redirect to="/" />
          ) : (
            <ScreenClassRender
              render={(screenClass) => (
                <Row
                  justify="flex-start"
                  style={{
                    width: width[screenClass],
                    minHeight: "calc(100vh - 120px)",
                    paddingBottom: majorScale(4),
                    flexDirection: "column"
                  }}
                >
                  <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
                    <Pane
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      marginTop={majorScale(4)}
                      marginBottom={majorScale(1)}
                    >
                      <Heading is="h1" size={800} marginBottom={majorScale(2)}>
                        Session Feedback
                      </Heading>
                    </Pane>
                    <Feedback review={reviewResult?.reviewById} isAdmin={reviewResult?.myProfile?.isAdmin} />
                  </Col>
                </Row>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReviewDetail;
