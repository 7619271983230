import _ from "lodash";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  EditIcon,
  Heading,
  IconButton,
  InlineAlert,
  ListItem,
  Pane,
  Paragraph,
  Text,
  UnorderedList,
  UploadIcon,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";
import { format, isValid, formatDistance } from "date-fns";

import CandidateQueries from "../../../queries/candidate";
import EditWorkExperienceContainer from "../edit/EditWorkExperienceContainer";
import ReactMarkdown from "react-markdown";

const safeDate = (date) => (isValid(new Date(date)) ? new Date(date) : Number(date));
const formatDate = (date) => format(safeDate(date), "MMM yyyy");

const formatDuration = ({ startDate, endDate }) => {
  if (startDate && endDate) return formatDistance(safeDate(endDate), safeDate(startDate));
  else return formatDistance(new Date(), safeDate(startDate));
};

const renderWorkDates = ({ startDate, endDate, isCurrentJob }) => {
  if (!startDate) return null;
  if (isCurrentJob || !endDate) {
    return `${formatDate(startDate)} - Present`;
  } else return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

const CandidateWorkExperience = (context) => {
  const { title, profile, isEditing, location, isRecruiter, editForm, myProfile } = context;

  const [showEditor, setShowEditor] = useState(context?.showEditor || false);
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile, {
    variables: {
      profileId: profile?.id
    }
  });

  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const EditForm = isEditing && editForm ? editForm : null;

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass.includes("xs");

        return !myCandidateProfile?.id || _.isEqual(myCandidateProfile?.status, "inactive") ? null : (
          <Card
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            backgroundColor="white"
            padding={majorScale(2)}
            marginBottom={majorScale(3)}
            marginTop={majorScale(3)}
            minHeight="100px"
            id="work-experience"
          >
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
              <Pane display="flex" flexDirection="row" alignItems="center">
                <Heading fontWeight={500}>
                  <span style={{ marginRight: "6px" }}>💼</span>
                  Work Experience
                </Heading>
                {!isRecruiter && (
                  <IconButton marginLeft={majorScale(1)} icon={EditIcon} appearance="minimal" onClick={() => setShowEditor(true)} />
                )}
                {editForm && showEditor && (
                  <Dialog
                    isShown={showEditor}
                    title={`Edit Work Experience`}
                    onCloseComplete={() => setShowEditor(false)}
                    confirmLabel="Finish"
                    hasCancel={false}
                    preventBodyScrolling={true}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                  >
                    <EditForm myProfile={profile} />
                  </Dialog>
                )}
              </Pane>
              {!isRecruiter && <InlineAlert>Only visible our hiring partners</InlineAlert>}
            </Pane>
            <Pane display="flex" flexDirection="column">
              {profile?.roles?.length > 0 &&
                profile?.roles.map((role) => (
                  <Pane width="100%" flexDirection="column" marginBottom={majorScale(2)} key={`roles-${role?.id}`}>
                    <Pane display="flex" flexDirection="row" justifyContent="space-between">
                      <Pane display="flex" flexDirection="row">
                        <Avatar
                          src={role?.team?.logo ? role?.team.logo : "https://ucarecdn.com/3892b394-7718-4ecf-88bb-d559bb5a2b99/office.svg"}
                          objectFit="contain"
                          borderRadius={0}
                          size={majorScale(6)}
                          marginRight={majorScale(2)}
                        />
                        <Pane display="flex" flexDirection="column">
                          <Heading color={theme.colors.gray800} size={400}>
                            {role?.team?.name}
                          </Heading>
                          <Heading color={theme.colors.gray800} size={300}>
                            {role?.title}
                          </Heading>
                        </Pane>
                      </Pane>
                      <Pane display="flex" flexDirection="column" alignItems="end">
                        <Heading color={theme.colors.gray800} size={300}>
                          {renderWorkDates(role)}
                        </Heading>
                        {role?.startDate && (
                          <Heading color={theme.colors.gray800} size={300}>
                            {formatDuration(role)}
                          </Heading>
                        )}
                      </Pane>
                    </Pane>
                    {role?.description && (
                      <Pane marginY={majorScale(1)} flexDirection="column">
                        <ReactMarkdown
                          components={{
                            p: Paragraph,
                            ul: UnorderedList,
                            li: ListItem
                          }}
                        >
                          {role?.description}
                        </ReactMarkdown>
                      </Pane>
                    )}
                  </Pane>
                ))}
            </Pane>
          </Card>
        );
      }}
    />
  );
};

export default withRouter(CandidateWorkExperience);
