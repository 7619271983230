import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = withRouter(({ history, location, children }) => {
  const [prev, setPrev] = useState(null);

  useEffect(() => {
    if (!prev || (location?.pathname && location?.pathname !== prev)) {
      setPrev(location?.pathname);
      window.scrollTo(0, 0);
    }
  }, [prev, location.pathname]);

  return children;
});

export default ScrollToTop;
