import _ from "lodash";
import React from "react";
import { Button, ChatIcon } from "evergreen-ui";
import { useQuery } from "@apollo/client";

import FeedQueries from "../../queries/feed";

const Reply = ({ post, comment, myProfile }) => {
  const query = comment ? FeedQueries.queries.Replies : FeedQueries.queries.Comments;
  const variables = comment ? { commentId: comment?.id } : { postId: post?.id };

  const { data: responsesResult } = useQuery(query, { variables });

  let copy = "Reply";

  const objs = comment ? responsesResult?.replies : responsesResult?.comments;

  if (objs?.edges?.length) {
    const len = objs?.edges?.length;

    if (len > 1) {
      copy = `${len} replies`;
    } else {
      copy = `1 reply`;
    }
  }

  const myReply = myProfile?.id ? _.find(objs?.edges, ["owner.id", myProfile.id]) : false;

  return (
    <Button
      key={`Reply-${post?.id}`}
      size="small"
      fontWeight={600}
      display="flex"
      alignItems="center"
      justifyContent="center"
      appearance="minimal"
      fontSize={14}
      color={myReply ? "#1E1EC6" : null}
      borderRadius={999}
      height={28}
    >
      <ChatIcon color="#8F95B2" marginRight={copy ? 5 : 0} />
      {copy}
    </Button>
  );
};

export default Reply;
