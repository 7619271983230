import _ from "lodash";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useMutation, useQuery } from "@apollo/client";
import { Pane, TextInput } from "evergreen-ui";

import ProfileList from "../queries/profile/ProfileList";
import ApplicationQueries from "../queries/application";

const findLocale = (field) => (address) => _.find(address, ({ types }) => types.includes(field));

const formatLocation = (location) => {
  const { formatted_address: formatted, address_components, place_id: placeId } = location;
  const city = findLocale("locality")(address_components)["long_name"];
  const region = findLocale("administrative_area_level_1")(address_components)["long_name"];
  const country = findLocale("country")(address_components)["long_name"];
  const lat = location.geometry.location.lat();
  const long = location.geometry.location.lng();

  return { formatted, city, region, country, lat, long, placeId };
};

const LocationAutocomplete = ({ onSelected, title, myProfile, disableUserUpdate, type, fullWidth }) => {
  const [updateMyPlace] = useMutation(ProfileList.UpdateMyPlace, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyPlace }, { query: ApplicationQueries.queries.MyProfile }]
  });

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyA8XP4eQ8FyyWkkKR69qI7QVC-4mPEj-QM",
    onPlaceSelected: (place, inputRef) => {
      if (place?.place_id || place?.formatted) {
        const formatted = formatLocation(place);

        if (!disableUserUpdate) {
          updateMyPlace({ variables: formatted });
        }

        if (_.isFunction(onSelected)) {
          onSelected(formatted, inputRef, type);
        }
      }
    }
  });

  return (
    <>
      <Pane display="flex">
        <TextInput
          flex="1"
          ref={ref}
          placeholder={title ? title : undefined}
          width={fullWidth ? "100%" : undefined}
          defaultValue={myProfile?.place?.formatted}
        />
      </Pane>
    </>
  );
};

export default LocationAutocomplete;
