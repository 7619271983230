import _ from "lodash";
import React, { useState } from "react";
import { Row, Col, ScreenClassRender, Hidden, Visible } from "react-grid-system";
import ReactMarkdown from "react-markdown";
import { Link as BrowserLink, withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import {
  Avatar,
  ArrowLeftIcon,
  Button,
  ChatIcon,
  Pane,
  Paragraph,
  Text,
  Table,
  PersonIcon,
  UnorderedList,
  ListItem,
  Heading,
  Spinner,
  majorScale,
  minorScale,
  defaultTheme as theme,
  OrderedList
} from "evergreen-ui";
import VirtualList from "@segment/react-tiny-virtual-list";
import InfiniteScroll from "react-infinite-scroll-component";
import { fromUnixTime, formatDistanceToNow, isBefore } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";
import { Helmet } from "react-helmet";

import TextArea from "../../components/form/TextArea";
import HoverLink from "../../components/HoverLink";
import PreviousActions from "../../components/session/PreviousActions";
import UpcomingActions from "../../components/session/UpcomingActions";
import ConversationQueries from "../../queries/conversation";
import WriteMessageMutation from "../../components/conversation/WriteMessageMutation";

const CHAR_LIMIT = 45;
const DESCRIPTION_LIMIT = 200;

const getOtherPerson = (users, me) => {
  return _.find(users, (conversationUser) => {
    return !_.isEqual(conversationUser?.id, me?.id);
  });
};

const isOther = (thread, me) => {
  if (!thread) return null;
  else return _.find(thread.users, (user) => !_.isEqual(me.id, user.id));
};

const formatSessionDate = (date, timeZone) => {
  timeZone = timeZone?.length ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eee LLLL do");
  const time = formatInTimeZone(zonedDate, timeZone, "h:mm b (z)");

  return `${day} at ${time}`;
};

const formatDate = (date, timeZone) => {
  if (!timeZone || _.isNull(timeZone)) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return formatInTimeZone(zonedDate, timeZone, "eeee LLLL do");
};

const formatDateAgo = (date, timeZone) => {
  if (!timeZone || _.isNull(timeZone)) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return `${formatDistanceToNow(zonedDate)} ago`;
};

const formatTime = (date, timeZone) => {
  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(fromUnixTime(Number(date) / 1000), timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return `${formatInTimeZone(zonedDate, timeZone, "h:mm b")}`;
};

const isPrevious = (date) => {
  const utcDate = fromUnixTime(Number(date) / 1000);

  return isBefore(utcDate, new Date());
};

const ConversationMeta = () => {
  const title = "Messages | Merit";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window.location.href} />
      <title>{title}</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="title" property="og:title" content={title} />
    </Helmet>
  );
};

const ConversationContext = ({ threadID }) => {
  const [showDetail, setShowDetail] = useState(false);
  const { data: contextResult } = useQuery(ConversationQueries.SessionsByConversation, {
    variables: { threadID }
  });

  let session, myProfile, previous, truncated;

  if (contextResult) {
    session = _.head(contextResult?.findSessionsByConversation);
    myProfile = contextResult?.myProfile;
    previous = isPrevious(session?.date);
    truncated = _.truncate(session?.cleanDescription, {
      length: DESCRIPTION_LIMIT
    });
  }

  return (
    <>
      {session?.id && (
        <Pane
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray500}`}
          borderBottomRightRadius={majorScale(2)}
          borderTopRightRadius={majorScale(2)}
        >
          <Pane
            height={majorScale(10)}
            alignItems="center"
            display="flex"
            flexDirection="row"
            borderBottom="1px solid #edeff5"
            paddingX={majorScale(2)}
            borderTopRightRadius={majorScale(2)}
            backgroundColor="white"
          >
            <Heading size={500} alignItems="center" display="flex" paddingLeft={majorScale(1)} flexDirection="row">
              {`${previous ? "Previous" : "Upcoming"} Session`}
            </Heading>
          </Pane>
          <Pane display="flex" flexDirection="column" padding={majorScale(2)}>
            <Heading size={100} marginBottom={minorScale(3)}>
              {formatSessionDate(session?.date, myProfile?.timezone)}
            </Heading>
            <Heading size={300} marginBottom={minorScale(3)}>
              Topic
            </Heading>
            <Paragraph wordBreak="break-word" marginBottom={minorScale(3)}>
              {showDetail ? session?.cleanDescription : truncated}
            </Paragraph>
            {session?.cleanDescription?.length > DESCRIPTION_LIMIT && (
              <Button appearance="minimal" onClick={() => setShowDetail(!showDetail)}>
                {showDetail ? "Show less" : "Show more"}
              </Button>
            )}
            <Pane display="flex" flexDirection="row" width="100%" justifyContent="space-between">
              {previous ? <PreviousActions session={session} isCol /> : <UpcomingActions session={session} isCol />}
            </Pane>
          </Pane>
        </Pane>
      )}
    </>
  );
};

const ConversationCell = withRouter(({ conversation, myProfile, history, threadID, setShowList }) => {
  let otherPerson = getOtherPerson(conversation.users, myProfile);

  /* Edge case handling for when you book a conversation with yourself. */
  if (_.isNil(otherPerson)) {
    otherPerson = myProfile;
  }

  return (
    <Table.Row
      isSelectable
      isSelected={_.isEqual(threadID, conversation.id)}
      key={conversation.id}
      onSelect={() => {
        setShowList(false);
        history.push(`/messages/${conversation.id}`);
      }}
      height={majorScale(14)}
    >
      <Table.Cell>
        <Pane display="flex" alignItems="top" margin={0} padding={minorScale(3)}>
          <Avatar src={otherPerson.picture} name={otherPerson.fullName} size={majorScale(6)} marginRight={minorScale(3)} />
          <Pane display="flex" flexDirection="column" justifyContent="center">
            <Heading size={400} marginBottom={minorScale(2)}>
              {otherPerson.fullName}
            </Heading>
            {conversation?.recentMessage?.text && (
              <>
                <Paragraph size={300} marginBottom={minorScale(2)}>
                  {conversation.recentMessage.text.length > CHAR_LIMIT
                    ? _.truncate(conversation.recentMessage.text, {
                        length: CHAR_LIMIT,
                        separator: "..."
                      })
                    : conversation.recentMessage.text}
                </Paragraph>
                <Heading size={100}>{formatDateAgo(conversation.recentMessage.createdAt)}</Heading>
              </>
            )}
          </Pane>
        </Pane>
      </Table.Cell>
    </Table.Row>
  );
});

const ConversationList = ({ threadID, handleEmptyThread, isMobile, setShowList, data, fetchMore }) => {
  const next = () =>
    fetchMore({
      query: ConversationQueries.RecentConversations,
      variables: { cursor: data?.conversations?.pageInfo?.nextPage },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          conversations: {
            __typename: fetchMoreResult.conversations.__typename,
            pageInfo: fetchMoreResult.conversations.pageInfo,
            edges: [...prev.conversations.edges, ...fetchMoreResult.conversations.edges]
          }
        });
      }
    });

  let mostRecentConversation, myProfile;

  if (data) {
    mostRecentConversation = data?.mostRecentConversation;
    myProfile = data?.myProfile;

    if (!threadID) {
      handleEmptyThread({ mostRecentConversation });
    }
  }

  const CellLoader = (
    <Pane height={majorScale(14)} width="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      <Spinner />
    </Pane>
  );

  return (
    <Pane
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderBottomLeftRadius={majorScale(2)}
      borderTopLeftRadius={majorScale(2)}
      borderTopRightRadius={isMobile ? majorScale(2) : null}
      borderBottomRightRadius={isMobile ? majorScale(2) : null}
      width="100%"
      paddingBottom={17}
      marginBottom={isMobile ? majorScale(4) : null}
    >
      <Pane
        paddingLeft={isMobile ? majorScale(2) : majorScale(4)}
        paddingRight={isMobile ? majorScale(2) : null}
        height={majorScale(10)}
        alignItems="center"
        display="flex"
        borderBottom="1px solid #edeff5"
        justifyContent={isMobile ? "space-between" : null}
      >
        <Heading alignItems="center" display="flex" flexDirection="row" size={500}>
          <ChatIcon size={minorScale(4)} color="#8F95B2" marginRight={majorScale(1)} />
          Messages
        </Heading>
      </Pane>
      <Table style={{ border: 0 }}>
        {data?.conversations?.edges?.length > 0 ? (
          <InfiniteScroll
            style={{ height: 800 }}
            hasMore={data?.conversations?.pageInfo?.hasNextPage}
            dataLength={data?.conversations?.edges.length}
            loader={CellLoader}
            next={() => next()}
          >
            {data?.conversations.edges.map((conversation) => (
              <ConversationCell threadID={threadID} conversation={conversation} myProfile={myProfile} setShowList={setShowList} />
            ))}
          </InfiniteScroll>
        ) : (
          <Pane
            minHeight={800}
            height={majorScale(14)}
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          ></Pane>
        )}
      </Table>
    </Pane>
  );
};

const MessageForm = ({ threadID, sendMessageToThread, sendingMsg }) => {
  return (
    <Formik
      initialValues={{ message: "" }}
      onSubmit={({ message }, { setSubmitting, resetForm }) => {
        window.analytics.track("Sent Update");

        sendMessageToThread({ variables: { threadID, text: message } });
        setSubmitting(false);
        resetForm({ message: "" });
      }}
      render={({ values, status, touched, isSubmitting }) => {
        return (
          <Pane paddingX={majorScale(3)} paddingY={majorScale(3)} borderTop="1px solid #edeff5">
            <Form>
              <Pane>
                <Field
                  name="message"
                  component={TextArea}
                  placeholder="Type your message"
                  style={{
                    text: {
                      resize: "none",
                      marginTop: majorScale(2)
                    }
                  }}
                />
                <Pane display="flex" justifyContent="space-between">
                  <div />
                  <Button marginTop={majorScale(2)} marginBottom={majorScale(2)} appearance="primary" type="submit" isLoading={sendingMsg}>
                    Message
                  </Button>
                </Pane>
              </Pane>
            </Form>
          </Pane>
        );
      }}
    />
  );
};

const showDateBreak = (messages, index) => {
  const prior = messages[index];
  const current = messages[index - 1];

  if (_.isEqual(index, 0)) return true;
  else if (prior?.createdAt && !_.isEqual(formatDate(current.createdAt), formatDate(prior.createdAt))) return true;
  else return false;
};

const Message = ({ index, me, threadById }) => {
  if (!me || !threadById) return null;

  const message = threadById.messages[index];
  const showDate = showDateBreak(threadById.messages, index);

  return (
    <Pane key={`message-detail-${message?.id}`} borderRadius={majorScale(2)}>
      {showDate && (
        <Pane width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" paddingY={majorScale(2)}>
          <Heading size={100}>{formatDate(message?.createdAt, me?.timezone)}</Heading>
        </Pane>
      )}
      <Pane padding={majorScale(2)} style={{ overflowWrap: "break-word", wordWrap: "break-word" }} display="flex" flexDirection="row">
        <Avatar size={40} marginRight={majorScale(2)} name={message?.user?.fullName} src={message?.user?.picture} />
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" flexDirection="row" marginBottom={minorScale(1)} alignItems="center">
            <Heading size={400} marginRight={majorScale(1)}>
              {message?.user?.fullName}
            </Heading>
            <Heading size={100}>{formatTime(message?.createdAt)}</Heading>
          </Pane>
          {message?.isBot ? (
            <Paragraph color="#8f95b2">{message.text}</Paragraph>
          ) : (
            <ReactMarkdown
              components={{
                p: Paragraph,
                ul: UnorderedList,
                li: ListItem,
                ol: OrderedList
              }}
            >
              {message.text}
            </ReactMarkdown>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

const ConversationDetail = ({ threadID, isMobile, setShowList, screenClass, handleEmpty, isOnlyConversation }) => {
  const { data } = useQuery(ConversationQueries.ConversationThreadDetail, {
    variables: { threadID },
    pollInterval: 500
  });

  const [sendMessageToThread, { loading: sendingMsg, error: sendError }] = useMutation(WriteMessageMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ConversationQueries.ConversationThreadDetail,
        variables: { threadID }
      }
    ]
  });

  let threadById, me, viewing, role;

  if (data) {
    if (!data?.threadById) {
      handleEmpty();
    } else {
      threadById = data.threadById;
      me = data.myProfile;
      viewing = isOther(threadById, me);

      const title = viewing?.role?.team?.name;
      role = `${viewing?.role?.title}`;
      role = title ? `${role} at ${title}` : role;
    }
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      minHeight={isMobile ? 400 : 600}
      borderTop={`1px solid ${theme.colors.gray500}`}
      borderBottom={`1px solid ${theme.colors.gray500}`}
      borderRight={`1px solid ${theme.colors.gray500}`}
      borderLeft={isMobile ? `1px solid ${theme.colors.gray500}` : null}
      borderRadius={isMobile ? majorScale(2) : null}
      backgroundColor="white"
      width="100%"
    >
      <Pane
        paddingRight={majorScale(2)}
        paddingLeft={majorScale(2)}
        minHeight={majorScale(10)}
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid #edeff5"
      >
        {viewing && (
          <>
            <Pane display="flex" flexDirection="row">
              {isMobile && !isOnlyConversation && (
                <Pane display="flex" flexDirection="row" alignItems="center">
                  <ArrowLeftIcon marginRight={majorScale(1)} onClick={() => setShowList(true)} />
                </Pane>
              )}
              <Avatar src={viewing?.picture} name={viewing?.fullName} size={isMobile ? 40 : 60} marginRight={minorScale(3)} />
              <Pane display="flex" flexDirection="column" justifyContent="center">
                <HoverLink samePage to={`/p/${viewing?.slug}`}>
                  <Heading size={500} marginBottom={minorScale(1)}>
                    {viewing && viewing.fullName ? viewing.fullName : "Conversations"}
                  </Heading>
                </HoverLink>
                {viewing?.role?.title?.length > 0 && <Paragraph>{role}</Paragraph>}
              </Pane>
            </Pane>
            {!isMobile && !screenClass?.includes("md") && (
              <Button is={BrowserLink} iconBefore={PersonIcon} to={`/p/${viewing?.slug}`} appearance="minimal">
                View Profile
              </Button>
            )}
          </>
        )}
      </Pane>
      <Pane>
        {threadById ? (
          <VirtualList
            itemCount={threadById.messages.length}
            scrollOffset={600}
            scrollToAlignment="end"
            height={isMobile ? 400 : 600}
            itemSize={majorScale(8)}
            overscanCount={10}
            estimatedItemSize={majorScale(8)}
            renderItem={({ index }) => (
              <Message index={index} threadById={threadById} me={me} isMobile={isMobile} id={`message-${index}`} />
            )}
          />
        ) : (
          <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            minHeight={600}
          >
            <Heading marginBottom={majorScale(2)}>Loading</Heading>
            <Spinner />
          </Pane>
        )}
      </Pane>
      {viewing && (
        <MessageForm threadID={threadID} sendMessageToThread={sendMessageToThread} sendingMsg={sendingMsg} sendError={sendError} />
      )}
    </Pane>
  );
};

const ConversationPage = (props) => {
  const {
    history,
    match: {
      params: { threadID }
    }
  } = props;
  const { data, fetchMore } = useQuery(ConversationQueries.RecentConversations);
  const [showList, setShowList] = useState(null);

  const handleEmpty = ({ mostRecentConversation }) => {
    history.push(`/messages/${mostRecentConversation?.id}`);
  };

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs") || screenClass?.includes("sm");
        const isOnlyConversation = !(data?.conversations?.edges.length > 1);

        return (
          <Row
            align="start"
            justify={isMobile ? "center" : "flex-start"}
            style={{
              width: "100%",
              paddingTop: majorScale(2),
              paddingBottom: majorScale(4)
            }}
          >
            <ConversationMeta />
            {data?.mostRecentConversation && (
              <>
                {isMobile ? (
                  <Col xs={12} sm={12} md={4} lg={3} style={{ padding: 0 }}>
                    {showList ? (
                      <ConversationList
                        isMobile={isMobile}
                        setShowList={setShowList}
                        threadID={threadID}
                        handleEmptyThread={handleEmpty}
                        data={data}
                        fetchMore={fetchMore}
                      />
                    ) : (
                      <ConversationDetail
                        threadID={threadID}
                        isMobile={isMobile}
                        setShowList={setShowList}
                        screenClass={screenClass}
                        handleEmpty={() => handleEmpty({ mostRecentConversation: data?.mostRecentConversation })}
                        isOnlyConversation={isOnlyConversation}
                      />
                    )}
                  </Col>
                ) : (
                  <>
                    <Col xs={12} sm={12} md={4} lg={3} style={{ padding: 0 }}>
                      <ConversationList
                        isMobile={isMobile}
                        setShowList={setShowList}
                        threadID={threadID}
                        handleEmptyThread={handleEmpty}
                        data={data}
                        fetchMore={fetchMore}
                      />
                    </Col>
                    <Col sm={12} md={5} lg={6} style={{ padding: 0 }}>
                      <ConversationDetail
                        threadID={threadID}
                        isMobile={isMobile}
                        setShowList={setShowList}
                        screenClass={screenClass}
                        handleEmpty={() => handleEmpty({ mostRecentConversation: data?.mostRecentConversation })}
                        isOnlyConversation={isOnlyConversation}
                      />
                    </Col>
                    <Hidden xs>
                      <Col sm={12} md={3} lg={3} style={{ padding: 0 }}>
                        <ConversationContext threadID={threadID} />
                      </Col>
                    </Hidden>
                  </>
                )}
              </>
            )}
          </Row>
        );
      }}
    />
  );
};

export default withRouter(ConversationPage);
