import gql from "graphql-tag";

import QueryUtils from "../utils";
import ProfileList from "../profile/ProfileList";
import CoachList from "../../routes/coach/CoachList";
import PlaylistQueries from "../playlist";

const GroupQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

GroupQueries.fragments.group = `
  id,
  name,
  slug,
  status,
  logo,
  description,
  website,
  twitter,
  linkedin
`;

GroupQueries.queries.GroupById = gql`
  query GroupDetail($id: String) {
    groupById(id: $id) {
      id
      slug
    }
  }
`;

GroupQueries.queries.GroupBySlug = gql`
  query GroupDetail($slug: String!) {
    myProfile {
      ${ProfileList.fragments.profile},
    },
    groupBySlug(slug: $slug) {
      ${GroupQueries.fragments.group},
      leaders {
        ${CoachList.fragments.coach}
      },
      topics {
        ${PlaylistQueries.fragments.playlist},
        coaches {
          ${CoachList.fragments.coach}
        }
      }
    }
  }
`;

GroupQueries.queries.Groups = gql`
  query GroupList($cursor: String) {
    groups(cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo}
      edges {
        ${GroupQueries.fragments.group}
      }
    }
  }
`;

GroupQueries.mutations.AddToGroup = gql`
  mutation AddToGroup($name: String, $logo: String, $domain: String) {
    addToGroup(name: $name, logo: $logo, domain: $domain) {
      id
      name
      logo
      description
    }
  }
`;

GroupQueries.mutations.RemoveFromGroup = gql`
  mutation RemoveFromGroup($groupId: String!) {
    removeFromGroup(groupId: $groupId) {
      id
      name
      logo
      description
    }
  }
`;

export default GroupQueries;
