import React, { useState } from "react";
import { Link as BrowserLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Card, Link, Pane, Paragraph, Heading, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import MotivationPill from "./MotivationPill";

import PlaylistQueries from "../../queries/playlist";
import ProfileList from "../../queries/profile/ProfileList";

const leaveJoinCopy = ({ isUpdating, isInTopic }) => {
  let copy = "";

  if (isUpdating) {
    if (isInTopic) {
      copy = "Leaving topic";
    } else {
      copy = "Joining topic";
    }
  } else {
    if (isInTopic) {
      copy = "Leave";
    } else {
      copy = "Join";
    }
  }

  return copy;
};

const PlaylistSimple = (cardProps) => {
  const { playlist, mini, leaveJoin, isInTopic } = cardProps;

  const [chooseRemove, { loading: isUpdating }] = useMutation(PlaylistQueries.ChooseRemovePlaylistMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyCoachingProfile,
        query: ProfileList.MyProfileQuery
      }
    ]
  });

  const [hover, setHover] = useState(false);
  const motivations = playlist?.motivations;

  const onSelect = () =>
    chooseRemove({
      variables: { playlistId: playlist.id }
    });

  return (
    <Card
      onClick={leaveJoin ? onSelect : undefined}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      display="flex"
      justifyContent="start"
      alignItems="start"
      flexDirection="column"
      padding={majorScale(2)}
      minHeight={leaveJoin ? undefined : "215px"}
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
    >
      {!mini && motivations?.length > 0 && (
        <Pane display="flex" marginBottom={majorScale(2)}>
          {motivations.map((item) => (
            <MotivationPill motivation={item} key={item} />
          ))}
        </Pane>
      )}
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height={40} width="100%">
        <Heading size={500}>{playlist.name}</Heading>
        {leaveJoin && (
          <Button appearance="minimal" intent={isInTopic ? "danger" : "success"} isLoading={isUpdating}>
            {leaveJoinCopy({ isInTopic, isUpdating })}
          </Button>
        )}
      </Pane>
      <Paragraph size={500} marginTop={majorScale(2)}>
        {playlist.description}
      </Paragraph>
    </Card>
  );
};

const PlaylistSimpleContainer = (props) => {
  const { leaveJoin, playlist } = props;
  const link = `/mentors?cursor=1&topics=${playlist.id}`;

  return leaveJoin ? (
    <PlaylistSimple {...props} />
  ) : (
    <Link to={link} is={BrowserLink} style={{ textDecoration: "none" }}>
      <PlaylistSimple {...props} />
    </Link>
  );
};

export default PlaylistSimpleContainer;
