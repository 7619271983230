import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";
import { Row, Col, Container, Hidden, Visible } from "react-grid-system";
import { Button, Heading, Overlay, Pane, Paragraph, majorScale } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import PhotoUploader from "../../components/PhotoUploader";
import ProfileList from "../../queries/profile/ProfileList";
import TextInput from "../../components/form/TextInput";
import LocationAutocomplete from "../../components/LocationAutocomplete";
import { inNorthAmerica } from "../../routes/auth/LocationCheck";
import EditTimeZone from "../profile/edit/calendar/EditTimeZone";

const HEADER_TEXT = "🙈 Heads Up!";
const SUBMIT_CTA = "Update profile";
const PROFILE_WARNING = "Show your personality! We want to ensure everyone on Merit is a real person.";

// https://stackoverflow.com/questions/13927099/validate-url-with-or-without-protocol
const URL_REGEX = /(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+?\.(?:[a-zA-Z])|\d+\.\d+\.\d+\.\d+)/;

const HumanSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Please include your full name")
    .max(100, "Please shorten the name you put in")
    .required("Please provide your name"),
  website: Yup.string().matches(URL_REGEX, "Please provide a valid website").required("Please provide a valid website"),
  place: Yup.string().required("Please provide your location.")
});

const hasName = ({ name }) => {
  return name?.first?.length > 0 && name?.last?.length > 0;
};

export const LocationBlock = ({ myProfile }) => {
  return (
    <Pane>
      <Pane>
        <Heading>Hmmm... Something seems off here</Heading>
        <Paragraph></Paragraph>
      </Pane>
    </Pane>
  );
};

export const HumanityCheckForm = ({ myProfile, profile, onCompleted, fluid }) => {
  const INTRO_TEXT = `Let ${profile?.name?.first} know who you are! Please add some profile info so that they know you're a real human.`;

  const [beAHuman] = useMutation(ProfileList.ShowHumanity, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: ProfileList.LayoutQuery }],
    onCompleted: ({ showHumanity }) => {
      if (window && window.analytics) {
        window.analytics.track("Be a Human");
      }
      onCompleted(showHumanity);
    }
  });

  return (
    <Formik
      validationSchema={HumanSchema}
      initialValues={{
        website: "",
        name: hasName(myProfile) ? myProfile.fullName : "",
        place: myProfile?.place?.formatted ? myProfile.place.formatted : ""
      }}
      onSubmit={(variables, { setSubmitting }) => {
        beAHuman({ variables });
        setSubmitting(false);
      }}
      render={({ errors, status, touched, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Pane padding={fluid ? null : majorScale(4)}>
            {/* Intro */}
            <Pane>
              <Heading size={600}>{HEADER_TEXT}</Heading>
              <Paragraph size={500} paddingBottom={majorScale(2)}>
                {INTRO_TEXT}
              </Paragraph>
            </Pane>

            {/* Profile Photo */}
            <Hidden xs>
              <Pane display="flex" justifyContent="space-between" alignItems="start" paddingTop={majorScale(2)}>
                <PhotoUploader />
                <Pane>
                  <Heading>Upload your profile picture</Heading>
                  <Paragraph size={500} paddingBottom={majorScale(2)}>
                    {PROFILE_WARNING}
                  </Paragraph>
                </Pane>
              </Pane>
            </Hidden>
            <Visible xs>
              <Pane display="flex" flexDirection="column" alignItems="start" paddingTop={majorScale(2)}>
                <Pane>
                  <Heading>Upload your profile picture</Heading>
                  <Paragraph size={500} paddingBottom={majorScale(2)}>
                    {PROFILE_WARNING}
                  </Paragraph>
                </Pane>
                <PhotoUploader />
              </Pane>
            </Visible>

            {/* Name */}
            {!hasName(myProfile) && (
              <>
                <Heading paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
                  What is your first and last name?
                </Heading>
                <Field type="text" name="name" placeholder="Your name" component={TextInput} />
              </>
            )}

            <Pane marginTop={majorScale(1)}>
              <EditTimeZone myProfile={myProfile} slim />
            </Pane>

            <>
              <Heading paddingTop={majorScale(2)}>Where are you located?</Heading>
              <Paragraph size={500} paddingBottom={majorScale(2)}>
                Merit works best for those working in the US or Canada.
              </Paragraph>
              <LocationAutocomplete myProfile={myProfile} onSelected={(chosen) => setFieldValue("place", chosen, false)} />
            </>

            {/* Website */}
            <Pane display="flex" justifyContent="space-between">
              <Heading paddingTop={myProfile?.fullName?.length < 0 ? null : majorScale(2)}>What is your LinkedIn?</Heading>
              <Button
                is="a"
                href="https://www.linkedin.com/in/"
                target="_blank"
                appearance="minimal"
                iconBefore={<FontAwesomeIcon icon={faLinkedinIn} />}
              >
                Find my LinkedIn
              </Button>
            </Pane>
            <Paragraph size={500} paddingBottom={majorScale(2)}>
              This helps mentors know more about your background before the session.
            </Paragraph>
            <Field type="text" name="website" placeholder="" component={TextInput} />

            {/* Action Items */}
            <Pane display="flex" justifyContent="flex-end" paddingBottom={majorScale(2)}>
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                {SUBMIT_CTA}
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export const areWeHuman = (myProfile) => {
  const { linkedin, twitter, website, name } = myProfile;
  const socials = _.reject([linkedin, twitter, website], _.isEmpty);
  const inRegion = myProfile?.place?.id && inNorthAmerica({ place: myProfile?.place });

  return !_.isEmpty(socials) && name?.first && name?.last && inRegion;
};

// Only show the form if their profile is too sparse
const HumanityCheck = ({ profile, myProfile, onCompleted }) => {
  return areWeHuman(myProfile) ? null : (
    <Overlay
      isShown={true}
      preventBodyScrolling={true}
      shouldCloseOnEscape={true}
      shouldCloseOnClick={false}
      containerProps={{ overflowY: "scroll" }}
    >
      <Container>
        <Row justify="center">
          <Col xs={12} sm={10} md={8} lg={6} xl={6}>
            <Pane marginY="12vmin" backgroundColor="white" elevation={4} borderRadius={8}>
              <HumanityCheckForm profile={profile} myProfile={myProfile} onCompleted={onCompleted} />
            </Pane>
          </Col>
        </Row>
      </Container>
    </Overlay>
  );
};

export default HumanityCheck;
