import React from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";

import withRoutes from "../withRoutes";

import Layout from "../../components/layout/Layout";
import ProfileList from "../../queries/profile/ProfileList";

// import ProfilePage from './ProfilePage'
// import ProfileView from './ProfileView'
import NewProfileView from "./NewProfileView";
// import ProfileRequest from './ProfileRequest'
// import ProfileCalendarForm from './ProfileCalendarForm'
import MentorSettings from "./MentorSettings";
import CalendarConnect from "./edit/calendar/CalendarConnect";

const ProfileEditRedirect = ({ location }) => {
  const { data } = useQuery(ProfileList.MyProfileQuery);

  return data && data?.myProfile ? (
    <Redirect to={`/p/${data?.myProfile?.slug}/edit${location?.search ? location.search : ``}`} />
  ) : (
    <Redirect to={`/login?origin=/profile/edit${location?.search ? location.search : ``}`} />
  );
};

const ProfileRedirect = ({ location }) => {
  const { data } = useQuery(ProfileList.MyProfileQuery);

  return data && data?.myProfile?.slug ? (
    <Redirect to={`/p/${data?.myProfile?.slug}${location?.search ? location.search : ``}`} />
  ) : (
    <Redirect to={`/login?origin=/profile/me${location?.search ? location.search : ``}`} />
  );
};

export default withRoutes(Layout, [
  {
    path: "/calendar/connect",
    component: CalendarConnect,
    state: "Profile"
  },
  {
    path: "/mentor/settings",
    component: MentorSettings,
    exact: true,
    privateRoute: true
  },
  {
    path: "/profile/edit",
    component: ProfileEditRedirect,
    state: "Profile",
    exact: true
  },
  {
    path: "/me",
    component: ProfileRedirect,
    state: "Profile",
    exact: true
  },
  // {
  //   path: "/profile/calendar/edit",
  //   component: ProfileCalendarForm,
  //   state: 'Profile',
  //   privateRoute: true,
  //   hideNav: true,
  // },
  // {
  //   path: "/profile/:profileID/new_message",
  //   component: ProfileRequest,
  //   state: 'Profile',
  // },
  // {
  //   path: "/p/old/:slug",
  //   component: ProfileView,
  //   state: "Profile"
  // },
  {
    path: "/p/:slug/edit",
    component: NewProfileView,
    state: "Profile",
    reviewRoute: true
  },
  {
    path: "/p/:slug",
    component: NewProfileView,
    state: "Profile"
  },
  // {
  //   path: "/profile/:profileID/:access",
  //   component: ProfilePage,
  //   state: 'Profile',
  // },
  {
    path: "/profile/:profileID",
    component: NewProfileView,
    state: "Profile"
  }
]);
