import gql from "graphql-tag";

import QueryUtils from "../utils";
import ProfileList from "../profile/ProfileList";

const FeedQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

FeedQueries.fragments.comment = `
  id,
  details,
  photo,
  video,
  createdAt,
  updatedAt,
  owner {
    ${ProfileList.fragments.profileLite}
  }
`;

FeedQueries.fragments.post = `
  id,
  title,
  details,
  photo,
  video,
  createdAt,
  updatedAt,
  topic {
    id,
    name,
    slug,
  },
  owner {
    ${ProfileList.fragments.profileLite}
  }
`;

FeedQueries.fragments.reaction = `
  id,
  reaction,
  post {
    id,
  },
  comment {
    id,
  },
  owner {
    id,
  }
`;

FeedQueries.queries.PostById = gql`
  query PostById($id: String) {
    myProfile {
      ${ProfileList.fragments.profileLite}
    },
    postById(id: $id) {
      ${FeedQueries.fragments.post},
      reactions {
        __typename,
        ${QueryUtils.PageInfo},
        edges {
          ${FeedQueries.fragments.reaction}
        }
      },
      mostRecentComment {
        ${FeedQueries.fragments.comment}
      }
    }
  }
`;

FeedQueries.queries.FeedQuery = gql`
  query FeedQuery($cursor: String, $topic: String, $sort: String, $text: String, $comments: Int) {
    myProfile {
      ${ProfileList.fragments.profile},
    },
    posts(cursor: $cursor, topic: $topic, sort: $sort, text: $text, comments: $comments) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.post},
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction}
          }
        },
      }
    }
  }
`;

FeedQueries.queries.PostTopics = gql`
  query PostTopics {
    postTopics {
      id
      count
      topic {
        id
        name
        slug
      }
    }
  }
`;

FeedQueries.queries.Comments = gql`
  query Comments($postId: String, $cursor: String) {
    comments(postId: $postId, cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.comment},
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction},
          }
        },
      }
    }
  }
`;

FeedQueries.queries.Replies = gql`
  query Replies($commentId: String, $cursor: String) {
    replies(commentId: $commentId, cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.comment},
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction}
          }
        },
      }
    }
  }
`;

FeedQueries.queries.RecentComments = gql`
  query RecentComments($cursor: String) {
    myProfile {
      ${ProfileList.fragments.profileLite}
    },
    recentComments(cursor: $cursor) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.comment},
        post {
          ${FeedQueries.fragments.post}
        },
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction}
          }
        }
      }
    }
  }
`;

FeedQueries.mutations.CreatePost = gql`
  mutation CreatePost($title: String!, $details: String!, $topic: String!, $photo: String) {
    createPost(title: $title, details: $details, topic: $topic, photo: $photo) {
      ${FeedQueries.fragments.post}
    }
  }
`;

FeedQueries.mutations.UpdatePost = gql`
  mutation UpdatePost($id: String!, $title: String!, $details: String!, $topic: String!, $photo: String) {
    updatePost(id: $id, title: $title, details: $details, topic: $topic, photo: $photo) {
      ${FeedQueries.fragments.post}
    }
  }
`;

FeedQueries.mutations.DeletePost = gql`
  mutation DeletePost($postId: String!) {
    deletePost(postId: $postId) {
      id
    }
  }
`;

FeedQueries.mutations.CreateComment = gql`
  mutation CreateComment($postId: String, $details: String, $photo: String) {
    createComment(postId: $postId, details: $details, photo: $photo) {
      ${FeedQueries.fragments.comment}
    }
  }
`;

FeedQueries.mutations.UpdateComment = gql`
  mutation UpdateComment($id: String, $details: String!, $photo: String) {
    updateComment(id: $id, details: $details, photo: $photo) {
      ${FeedQueries.fragments.comment}
    }
  }
`;

FeedQueries.mutations.DeleteComment = gql`
  mutation DeleteComment($id: String!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

FeedQueries.mutations.CreateReply = gql`
  mutation CreateReply($commentId: String!, $details: String, $photo: String) {
    createReply(commentId: $commentId, details: $details, photo: $photo) {
      ${FeedQueries.fragments.comment}
    }
  }
`;

FeedQueries.mutations.UpdateReply = gql`
  mutation UpdateReply($id: String!, $details: String, $photo: String) {
    updateReply(id: $id, details: $details, photo: $photo) {
      ${FeedQueries.fragments.comment}
    }
  }
`;

FeedQueries.mutations.DeleteReply = gql`
  mutation DeleteReply($id: String!) {
    deleteReply(id: $id) {
      id
    }
  }
`;

FeedQueries.mutations.AddReaction = gql`
  mutation AddReaction($postId: String, $commentId: String, $reaction: String) {
    addReaction(postId: $postId, commentId: $commentId, reaction: $reaction) {
      ${FeedQueries.fragments.reaction}
    }
  }
`;

FeedQueries.mutations.RemoveReaction = gql`
  mutation RemoveReaction($id: String) {
    removeReaction(id: $id) {
      ${FeedQueries.fragments.reaction}
    }
  }
`;

export default FeedQueries;
