import _ from "lodash";
import React from "react";
import {
  AnnotationIcon,
  Avatar,
  Button,
  ChatIcon,
  CrownIcon,
  Heading,
  IconButton,
  MapMarkerIcon,
  Pane,
  Paragraph,
  PersonIcon,
  PulseIcon,
  defaultTheme,
  majorScale,
  minorScale
} from "evergreen-ui";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as BrowserLink } from "react-router-dom";

import HoverLink from "../HoverLink";

const roundNumber = (num) => {
  if (num < 10) return `${num}`;
  else {
    num = Math.floor(num / 10) * 10;
    return `${num}+`;
  }
};

const AVATAR_LOGO = "https://ucarecdn.com/3892b394-7718-4ecf-88bb-d559bb5a2b99/office.svg";
const RECOMMENDATION_COPY =
  "Your recommendation helps them in their job search. Your recommendation is only viewable to recruiters reviewing their candidate profile.";

const ConnectionCard = ({ connection, recommendation, hideActions, isMobile }) => {
  const profile = connection?.viewing;
  const imgSize = majorScale(8);

  if (!profile?.fullName || !profile?.slug) return null;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      backgroundColor="white"
      border={`1px solid ${defaultTheme.colors.gray500}`}
      borderRadius={majorScale(2)}
      marginBottom={majorScale(2)}
      padding={majorScale(2)}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)}>
        <HoverLink samePage to={`/p/${profile?.slug}`}>
          <Pane display="flex">
            <Pane display="flex" flexDirection="row" marginRight={majorScale(2)}>
              <Avatar src={profile?.picture} name={profile?.fullName} size={imgSize} />
            </Pane>
            <Pane display="flex" flexDirection="column" justifyContent="center" height={imgSize}>
              <Pane display="flex" flexDirection="row" alignItems="center" marginRight={majorScale(2)} marginBottom={minorScale(1)}>
                <Heading size={600}>{profile?.fullName}</Heading>
                {profile?.pronouns?.length > 0 && (
                  <Heading size={400} marginLeft={minorScale(1)}>
                    ({profile?.pronouns})
                  </Heading>
                )}
              </Pane>
              <Pane display="flex" flexDirection="row">
                {/* Location */}
                {profile?.place?.formatted?.length > 0 && (
                  <Pane display="flex" flexDirection="row" marginRight={majorScale(1)}>
                    <MapMarkerIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                    <Heading size={400}>{profile?.place?.formatted}</Heading>
                  </Pane>
                )}

                {/* Sessions Completed */}
                <Pane display="flex" flexDirection="row" marginRight={majorScale(1)}>
                  <CrownIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                  <Heading size={400}>
                    {connection?.sessionsCompleted > 0
                      ? `${roundNumber(connection?.sessionsCompleted)} session${connection?.sessionsCompleted > 1 ? `s` : ``} completed`
                      : "New connection"}
                  </Heading>
                </Pane>
              </Pane>

              {profile?.role?.team?.name && (
                <Pane display="flex" flexDirection="row">
                  {isMobile ? (
                    <Pane display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start">
                      <Pane display="flex" flexDirection="row" justifyContent="flex-start">
                        <Avatar
                          shape="square"
                          size={majorScale(2)}
                          marginRight={minorScale(1)}
                          src={profile?.role?.team?.logo?.length > 0 ? profile?.role?.team?.logo : AVATAR_LOGO}
                        />
                        <Heading size={400}>{profile?.role?.team?.name}</Heading>
                      </Pane>
                      {profile?.role?.title?.length > 0 && <Paragraph>{profile?.role?.title}</Paragraph>}
                    </Pane>
                  ) : (
                    <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                      <Avatar
                        shape="square"
                        size={majorScale(2)}
                        marginRight={minorScale(1)}
                        src={profile?.role?.team?.logo?.length > 0 ? profile?.role?.team?.logo : AVATAR_LOGO}
                      />
                      <Heading size={400}>{profile?.role?.team?.name}</Heading>
                      {profile?.role?.title?.length > 0 && <Paragraph marginLeft={majorScale(1)}>{profile?.role?.title}</Paragraph>}
                    </Pane>
                  )}
                </Pane>
              )}
            </Pane>
          </Pane>
        </HoverLink>
        {profile?.linkedin && (
          <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            {hideActions && !recommendation ? (
              <IconButton icon={ChatIcon} is={BrowserLink} appearance="minimal" to={`/messages/${connection?.conversationThread?.id}`} />
            ) : (
              <Button appearance="minimal" is="a" href={profile?.linkedin} target="_blank">
                <FontAwesomeIcon size="md" icon={faLinkedinIn} />
              </Button>
            )}
            <IconButton icon={PersonIcon} is={BrowserLink} appearance="minimal" to={`/p/${profile?.slug}`} />
          </Pane>
        )}
      </Pane>
      {recommendation && (
        <Pane>
          <Paragraph size={500}>{RECOMMENDATION_COPY}</Paragraph>
        </Pane>
      )}
      {!hideActions && (
        <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Button is={BrowserLink} to={`/connection/${connection?.id}`} iconBefore={PulseIcon} appearance="minimal">
            View activity
          </Button>
          <Pane>
            <Button is={BrowserLink} to={`/messages/${connection?.conversationThread?.id}`} iconBefore={ChatIcon}>
              Message
            </Button>
            <Button
              is={BrowserLink}
              to={`/recommend/${connection?.id}`}
              iconBefore={AnnotationIcon}
              marginLeft={majorScale(1)}
              appearance={connection?.recommendation?.id ? "default" : "primary"}
            >
              {connection?.recommendation?.id ? "Edit recommendation" : "Recommend"}
            </Button>
          </Pane>
        </Pane>
      )}
    </Pane>
  );
};

export default ConnectionCard;
