import _ from "lodash";
import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Badge, Pane, majorScale } from "evergreen-ui";

const END_MSG = "You've reached the end of the list.";

export default ({ message }) => (
  <Pane display="flex" justifyContent="center" marginTop={majorScale(2)} marginBottom={majorScale(2)}>
    <Badge color="blue">{message ? message : END_MSG}</Badge>
  </Pane>
);
