import React from "react";
import { withRouter } from "react-router-dom";
import { ArrowLeftIcon, Button, Pane, majorScale, defaultTheme as theme } from "evergreen-ui";
import { ScreenClassRender } from "react-grid-system";

const isMobile = (screenClass) => screenClass.includes("xs");

const BackButton = withRouter(({ history, styling, mobile }) => (
  <ScreenClassRender
    render={(screenClass) => (
      <>
        {history?.goBack && history?.length > 2 && (
          <Pane
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            paddingTop={mobile || isMobile(screenClass) ? null : majorScale(4)}
            paddingX={mobile || isMobile(screenClass) ? null : majorScale(2)}
            {...styling}
          >
            <Button
              height={48}
              color={theme.colors.blue500}
              iconBefore={ArrowLeftIcon}
              appearance="minimal"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Pane>
        )}
      </>
    )}
  />
));

export default BackButton;
