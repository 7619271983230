export const MOTIVATION_COLORS = {
  TRANSITION: "green",
  NETWORK: "blue",
  TRAINING: "orange",
  PROMOTION: "yellow",
  JOB_SEARCH: "purple"
};

export const MOTIVATION_TAGS = {
  TRANSITION: "Transitions",
  NETWORK: "Mentorship",
  TRAINING: "Learning",
  PROMOTION: "Promotions",
  JOB_SEARCH: "Job Search"
};
