import React from "react";
import { Pane, Paragraph, Heading, Text, OrderedList, UnorderedList, ListItem, majorScale } from "evergreen-ui";

export const GoodSession = [
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane padding={majorScale(2)}>
      <Heading size={600} marginBottom={majorScale(2)}>
        What makes a good session?
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Members are coming to you because they either can't or don't feel comfortable talking about their issues at work or with their own
        network.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Because of that, when people book calls with you on Merit, we think of how the call went as a <b>Hierarchy of Needs.</b> The first
        level is good, and if you get further, it only adds to the experience.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Not every call needs to achieve all steps, as there's a lot of value to even getting from zero to one.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        🗼 Hierarchy of Needs
      </Heading>
      <OrderedList marginBottom={majorScale(2)}>
        <ListItem>
          <Paragraph size={500}>
            I feel <b>heard</b>: Often might be using Merit because they can't talk about this at work.
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph size={500}>
            I feel <b>comfortable</b>: This should not feel like talking to your boss or HR. More like talking to a cooler older cousin. The
            goal isn't to impress them, it's to help them.
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph size={500}>
            I feel <b>motivated</b>: Now they are being more forward-looking empowered or optimistic about the situation. Often times just
            knowing their problem is real and they can solve it is enough
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph size={500}>
            I have more <b>options</b> than I came with (ideas, knowledge, tactics). Merit calls can be a place to brainstorm and
            collaborate on solutions. Often times they just need to talk it out but other times they can be blocked
          </Paragraph>
        </ListItem>
      </OrderedList>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        In sum: the ideal end state is that the other person feels heard, comfortable, motivated, and has the next steps (ideas, knowledge,
        tactics) to tackle their question or problem.
      </Paragraph>
    </Pane>
  </Pane>,
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane padding={majorScale(2)}>
      <Heading size={600} marginBottom={majorScale(2)}>
        How should I approach a session?
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        We've found the following best practices to be an effective way to help drive the conversations. These best practices aren't formal
        rules so much as what we've seen work.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        🧩 Ask lots of questions
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Especially when you're talking to someone for the first time, you'll need plenty of context to understand what's going on. Ask lots
        of questions to learn more about where they're coming from. This also makes the conversation more comfortable for both of you.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        If the other person feels like you've asked plenty of questions, and in turn, know a lot about their situation, they'll feel
        comfortable that you know where they're coming from.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        👩‍🏫 Demonstrate experience
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Just as you would start an essay with a topic sentence, you should ground your questions, comments, and perspective in your own
        experience.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        The easiest way to accomplish this is to use simple statements like, "I've seen this before" or "I've done this before when I..."
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Sometimes people go straight into telling people what to do. But if you don't clarify where you're coming from people will easily
        get lost.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        📖 Share stories
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        The beauty of a Merit conversation is that you can talk to someone in a related role without the potential conflicts that could come
        with working directly with the person.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        That means that sharing stories is gold for these career conversations. The stories of your career successes and failures paint an
        easy-to-digest lesson for the other person to take home.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        We've found that many of the biggest lessons shared during a conversation come from sharing the story of your prior experiences.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        ✔️ End with an action item
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Try to end the call with an action item to do next. This ensures the person leaves with more options than they came with. It also
        makes it easy for them to follow up with how things went. You can share any resources or links in the app, but it is not required or
        expected.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Since members request your time, our view is that it's on them to be proactive about seeing through their next steps. So if you want
        to help them with something specific after the session, that's awesome, but it's by no means a requirement for an effective session.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        🤔 Reflect on the session
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        After you complete a session, Merit will ask what went well and what could have gone better. Take some time to think through what
        you thought made the session useful and where you could have done better. These become useful later as you do more sessions.
      </Paragraph>
    </Pane>
  </Pane>,
  <Pane width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Pane padding={majorScale(2)}>
      <Heading size={600} marginBottom={majorScale(3)}>
        What is the format of these sessions?
      </Heading>
      <Heading size={500} marginBottom={majorScale(2)}>
        ⏲️ How long are they?
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        Sessions are booked for 45 minutes. It's just you and the other person. Merit doesn't record anything so the calls are off the
        record.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        We find that not all sessions need full time, and how long they take depends mostly on the topic. The more tactical session may take
        only 20 minutes, while more open-ended questions will typically take the full time.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(4)}>
        We like to joke that if someone's asking how to do a job search, that may only take 20 minutes. But if they're asking what to do
        with their life, that may take a bit longer.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        🌀 Are they recurring or one-off?
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(2)}>
        By default sessions are one-off, but some have follow-ups. It's up to you to decide if you want to continue a conversation. You can
        always suggest they update you via the app before they book more time with you.
      </Paragraph>
      <Paragraph size={500} marginBottom={majorScale(4)}>
        Merit is a place for impactful career conversations, not a mentorship program. It's less about matching mentors to mentees but
        rather making each session relevant, impactful, and enjoyable.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        📅 How far in advance can I get booked?
      </Heading>
      <Paragraph size={500} marginBottom={majorScale(4)}>
        You won't get booked less than a day in advance. The scheduler adds a 15-minute buffer after the call to give you time for
        follow-ups.
      </Paragraph>
      <Heading size={500} marginBottom={majorScale(2)}>
        🔑 What should I do right before?
      </Heading>
      <UnorderedList marginBottom={majorScale(4)}>
        <ListItem>Review the description and write out any relevant stories or lessons</ListItem>
        <ListItem>Review their LinkedIn to get a sense of their background</ListItem>
        <ListItem>Make sure you are in a soundproof room, can talk freely, and have good light</ListItem>
        <ListItem>Turn your video on, make sure your mic works</ListItem>
        <ListItem>Avoid multitasking - it could look like you're not paying attention</ListItem>
      </UnorderedList>
    </Pane>
  </Pane>
];
