import React from "react";
import queryString from "query-string";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Alert, Button, Heading, Pane, Paragraph, Text, majorScale, minorScale, defaultTheme } from "evergreen-ui";

import TextInput from "../../components/form/TextInput";

const REQUEST_CHANGE_EMAIL = gql`
  mutation requestChangeEmail($currentEmail: String!, $newEmail: String!) {
    requestChangeEmail(currentEmail: $currentEmail, newEmail: $newEmail)
  }
`;

const RequestChangeEmailSchema = Yup.object().shape({
  currentEmail: Yup.string().email("Please provide a valid email").required("Please include your current email"),
  newEmail: Yup.string().email("Please provide a valid email").required("Please include your current email")
});

const CONFIRM_COPY = "Thanks! You'll receive a verification email to this new address shortly.";

const RequestChangeEmailPage = ({ history, message, location: { search } }) => {
  const { email } = queryString.parse(search, { arrayFormat: "comma" });
  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  const [requestChangeEmail, { loading, error }] = useMutation(REQUEST_CHANGE_EMAIL, {
    onCompleted: () => {
      history.push("/settings");
    }
  });

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          style={{
            width: width[screenClass],
            minHeight: "calc(100vh - 120px)",
            paddingTop: majorScale(2),
            paddingBottom: majorScale(6)
          }}
        >
          <Col xs={12} sm={10} md={8} lg={6} offset={{ sm: 1, md: 2, lg: 3 }}>
            <Formik
              validationSchema={RequestChangeEmailSchema}
              initialValues={{
                currentEmail: email ? email : "",
                newEmail: ""
              }}
              onSubmit={(args, { setSubmitting, setStatus }) => {
                requestChangeEmail({ variables: { ...args } });
              }}
              render={({ errors, touched, isSubmitting }) => {
                return (
                  <Form>
                    <Pane
                      backgroundColor={"white"}
                      borderRadius={minorScale(3)}
                      border={`1px solid ${defaultTheme.colors.gray400}`}
                      width="100%"
                      padding={majorScale(4)}
                      marginBottom={majorScale(3)}
                    >
                      <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(4)}>
                        <Heading size={700} marginBottom={minorScale(1)}>
                          📧 Need to change your email?
                        </Heading>
                        <Paragraph size={500}>
                          Please provide the new email address - we'll send you an email to that address to verify the change.
                        </Paragraph>
                      </Pane>
                      <Field type="email" name="currentEmail" placeholder="Current Email" component={TextInput} />
                      <Field type="email" name="newEmail" placeholder="New Email" component={TextInput} />
                      {isSubmitting && !errors.length ? <Alert intent="success" title={CONFIRM_COPY} /> : null}
                      {errors && errors.length ? errors.map((error) => <Alert intent="danger" title={error} />) : null}
                      {error && error.graphQLErrors
                        ? error.graphQLErrors.map(({ message }) => <Alert intent="danger" title={message} />)
                        : null}
                      <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)} display="flex" justifyContent="center">
                        <Button type="submit" appearance="primary" disabled={isSubmitting} marginBottom={majorScale(2)}>
                          Verify Email
                        </Button>
                      </Pane>
                    </Pane>
                  </Form>
                );
              }}
            />
          </Col>
        </Row>
      )}
    ></ScreenClassRender>
  );
};

export default withRouter(RequestChangeEmailPage);
