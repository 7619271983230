import _ from "lodash";
import React from "react";
import { Formik, Field, Form } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { Button, CrossIcon, HandIcon, Pane, Paragraph, majorScale, toaster } from "evergreen-ui";
import * as Yup from "yup";

import SessionQueries from "../../queries/session";
import FeedQueries from "../../queries/feed";
import TextArea from "../../components/form/TextArea";

const SUCCESS_TOAST = "Offered a session successfully.";

const SessionOfferForm = Yup.object().shape({
  profileId: Yup.string().required(),
  postId: Yup.string().required(),
  message: Yup.string().required("Please include your reason for offering a session")
});

const OfferForm = (offerProps) => {
  const { post, onUpdate, onCancel, myOfferedSession, membersQuery } = offerProps;

  const [offerSession, { loading: isLoading }] = useMutation(SessionQueries.mutations.OfferSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: SessionQueries.queries.MyOfferedSession, variables: { profileId: post?.owner?.id } },
      { query: FeedQueries.queries.FeedQuery, variables: membersQuery }
    ],
    onCompleted: () => {
      toaster.success(SUCCESS_TOAST, { duration: 2 });
      if (_.isFunction(onUpdate)) {
        onUpdate();
      }
    }
  });

  const existingOffer = myOfferedSession?.id;

  return (
    <>
      <Formik
        validationSchema={SessionOfferForm}
        initialValues={{
          profileId: post?.owner?.id,
          postId: post?.id,
          message: myOfferedSession?.message || ""
        }}
        onSubmit={(variables, { setSubmitting }) => {
          offerSession({ variables });
          setSubmitting(false);
        }}
        render={({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Pane paddingX={0}>
              <Field
                type="text"
                name="message"
                placeholder="E.g. Hey! I was a product manager at small, medium, and large startups before working in my current role. I think my experience could be helpful as you figure out how to navigate your next career step. Please book some time on my calendar if you’d want to chat!"
                component={TextArea}
              />
            </Pane>

            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              paddingX={0}
              paddingTop={majorScale(2)}
              paddingBottom={majorScale(4)}
            >
              <Button
                type="button"
                iconBefore={CrossIcon}
                onClick={() => {
                  if (_.isFunction(onCancel)) {
                    onCancel();
                  }
                }}
              >
                Cancel
              </Button>
              <Button iconBefore={HandIcon} appearance="primary" type="submit" isLoading={isLoading} disabled={isSubmitting}>
                {existingOffer ? "Edit Session Offer" : "Offer Session"}
              </Button>
            </Pane>
          </Form>
        )}
      />
    </>
  );
};

export default OfferForm;
