import _ from "lodash";
import React, { useState } from "react";
import { Button, Dialog, Pane, Icon, Text, Paragraph, RadioGroup, majorScale, toaster } from "evergreen-ui";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";

import ProfileList from "../../../../queries/profile/ProfileList";
import TextArea from "../../../../components/form/TextArea";

const COACHING_DISABLE = "Pause Sessions";
const COACHING_ENABLE = "Resume Sessions";

const COACHING_DISABLE_COPY = `You can always come back. We won't take it personally. :)`;
const COACHING_ENABLE_COPY = `So glad to have you back!`;

const COACHING_DISABLE_WARNING_COPY = "Need to take a break from sessions?";
const COACHING_ENABLE_WARNING_COPY = "Want to start accepting sessions again?";

const RESUME_TOAST = "Sessions resumed!";
const PAUSE_TOAST = "Sessions paused!";

const REASON_INFO = "We would love to know more about why you are pausing your sessions.";
const REASON_DETAILS = "Any other details to share?";

const PauseReasons = [
  { label: "Busy with work", value: "BUSY" },
  { label: "Too many sessions", value: "TOO_MANY_SESSIONS" },
  { label: "In between jobs", value: "JOB_SEARCH" },
  { label: "Not enough sessions", value: "TOO_FEW_SESSIONS" },
  { label: "Going or currently on vacation", value: "VACATION" },
  { label: "Not enjoying the mentor experience", value: "POOR_EXPERIENCE" },
  { label: "No longer interested in mentoring", value: "NOT_INTERESTED" },
  { label: "Other", value: "OTHER" }
];

const StatusSchema = Yup.object().shape({
  tag: Yup.string().required("Please mark why you're pausing your profile"),
  reason: Yup.string()
});

const EditPause = ({ myCoachingProfile }) => {
  const [isShown, setShown] = useState(false);
  const [updateStatus, { loading }] = useMutation(ProfileList.UpdateStatus, {
    onCompleted: () => {
      toaster.success(toast);
      window.analytics.track("Update Mentor Status");

      if (isShown) {
        setShown(false);
      }
    }
  });

  const coachingStatus = myCoachingProfile?.status;
  const active = _.isEqual(coachingStatus, "Active");
  const title = active ? COACHING_DISABLE : COACHING_ENABLE;
  const intent = active ? "danger" : "success";
  const status = active ? "Inactive" : "Active";
  const warningCopy = active ? COACHING_DISABLE_WARNING_COPY : COACHING_ENABLE_WARNING_COPY;
  const copy = active ? COACHING_DISABLE_COPY : COACHING_ENABLE_COPY;
  const reasonPlaceholder = "Work is getting really busy etc.";
  const icon = active ? "pause" : "repeat";
  const toast = active ? RESUME_TOAST : PAUSE_TOAST;

  return (
    <Pane display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
      <Dialog isShown={isShown} title={warningCopy} intent={intent} onCloseComplete={() => setShown(false)} hasFooter={false}>
        <Formik
          validationSchema={StatusSchema}
          initialValues={{ tag: "BUSY", reason: "" }}
          onSubmit={({ reason, tag }, { setSubmitting }) => {
            updateStatus({ variables: { reason, status, tag } });
            setSubmitting(false);
          }}
          render={({ values, setFieldValue, errors, status, touched, isSubmitting }) => {
            return (
              <Form>
                <Pane>
                  {active && (
                    <Pane paddingBottom={majorScale(2)}>
                      <Paragraph size={500} marginBottom={majorScale(2)}>
                        {REASON_INFO}
                      </Paragraph>
                      <RadioGroup
                        marginBottom={majorScale(2)}
                        value={values?.tag}
                        options={PauseReasons}
                        onChange={(e) => setFieldValue("tag", e.target.value, false)}
                      />
                      <Paragraph size={500} marginBottom={majorScale(2)}>
                        {REASON_DETAILS}
                      </Paragraph>
                      <Field type="text" name="reason" placeholder={reasonPlaceholder} component={TextArea} />
                    </Pane>
                  )}
                  <Pane paddingBottom={majorScale(2)}>
                    <Paragraph size={500}>{copy}</Paragraph>
                  </Pane>
                  <Pane display="flex" justifyContent="flex-end" marginBottom={majorScale(3)}>
                    <Button appearance="primary" intent={intent} type="submit" isLoading={loading} disabled={isSubmitting}>
                      {title}
                    </Button>
                  </Pane>
                </Pane>
              </Form>
            );
          }}
        />
      </Dialog>
      <Paragraph size={500}>{warningCopy}</Paragraph>
      <Button appearance="minimal" onClick={() => setShown(true)} intent={intent}>
        <Icon marginRight={majorScale(1)} icon={icon} />
        {title}
      </Button>
    </Pane>
  );
};

export default EditPause;
