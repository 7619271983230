import React, { useState } from "react";
import { Pane, majorScale, minorScale, SelectMenu, Text, CaretDownIcon, Position } from "evergreen-ui";
import { Formik, Field, Form } from "formik";

import SearchInput from "../form/SearchInput";

const sorts = [
  // { label: "Relevant", value: "DEFAULT" },
  { label: "Recent", value: "RECENT" },
  { label: "Popular", value: "POPULAR" }
];

const SORT_LABELS = {
  // DEFAULT: "relevant",
  POPULAR: "popular",
  RECENT: "recent"
};

const DiscussionFilters = ({ query, updateFeed }) => {
  const [selectedSort, setSelectedSort] = useState(query?.sort);

  return (
    <Formik
      initialValues={query}
      onSubmit={(updated, { setSubmitting, setValues }) => {
        updateFeed(updated);
      }}
      render={(formikBag) => {
        const { setFieldValue, submitForm } = formikBag;

        return (
          <Form>
            <Pane display="flex" flexDirection="row" width="100%" justifyContent="space-between" marginBottom={majorScale(3)}>
              <Field
                type="text"
                name="text"
                placeholder="Search"
                component={SearchInput}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("text", e.target.value, false);
                  submitForm();
                }}
              />
              <SelectMenu
                title="Sort"
                options={sorts}
                selected={selectedSort}
                position={Position.BOTTOM_RIGHT}
                onSelect={(item) => {
                  setSelectedSort(item.value);
                  setFieldValue("sort", item.value, null);
                  submitForm();
                }}
                hasFilter={false}
              >
                <Pane cursor="pointer" display="flex" alignItems="center">
                  <Text color="#696F8C">
                    Sorted by <b>{SORT_LABELS[selectedSort]}</b>
                  </Text>
                  <CaretDownIcon marginLeft={minorScale(1)} color="muted" />
                </Pane>
              </SelectMenu>
            </Pane>
          </Form>
        );
      }}
    />
  );
};

export default DiscussionFilters;
