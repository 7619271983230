import React from "react";
import { Pane, Heading, Paragraph, Switch, majorScale, minorScale, toaster } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import ProfileList from "../../../../queries/profile/ProfileList";

const EditOpenEnded = ({ myCoachingProfile }) => {
  const [toggleOpenEnded] = useMutation(ProfileList.ToggleOpenEnded, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      window.analytics.track("Update Open Ended Sessions");
    }
  });

  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
      <Heading marginBottom={minorScale(1)}>🎱 &nbsp; Do you want open-ended sessions?</Heading>
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Paragraph size={500}>Members can book sessions outside of your chosen topics.</Paragraph>
        <Switch
          marginLeft={majorScale(2)}
          checked={myCoachingProfile.openEnded}
          onChange={() => {
            toggleOpenEnded();
            toaster.success("Open Ended topics updated.", {
              duration: 2
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default EditOpenEnded;
