export default `

## **Responsibilities**

1. **Be polite and friendly.** If you may have misunderstood something, gently ask for a clarification. Respect others when they do the same.
2. **Be open and honest about your intentions.** Assume others are doing the same.
3. **Respect people’s privacy.** If someone wishes to be left alone, please respect that.
4. **Respect people’s identities and life experiences**, they will be different from yours.
5. **Own your actions.** Ensure that your behavior does not have a negative impact, regardless of intent. If you have wronged someone, take care to make amends. Know that acceptance and forgiveness is not owed to you.
6. **Look out for others.** Create boundaries to your own behavior and consider how you can create safe space for others.

## **No-Show Policy**

Merit users are busy professionals - everyone takes time out of their daily work, lives, and careers, to help each other. So we take no-show's seriously, as they're a drag on everyone's time. Here's our policy:

- You can reschedule a session two (2) times. On the third attempt, you have to cancel the session.
- Members who accrue three (3) Canceled or No Show sessions within 90 days may not book more sessions for a period of 30 days.
- Mentors who accrue three (3) Canceled or No Show sessions within 90 days will have their mentorship calendar deactivated for a period of 30 days.

## **Unacceptable Behaviors**

If you are unsure if something is appropriate behavior, it probably isn’t. Each person you interact with can define where that line is for them. Impact matters more than intent. Anyone asked to stop any harassing behavior is expected to comply immediately. Harassment includes (but is not limited to):

- Offensive comments related to age, gender, gender identity and expression, sexual orientation, disability, mental illness, neuro(a)typicality, physical appearance, body size, race, religion, socioeconomic status or background or clothing.
- Referring to people in a way that misidentifies their gender and/or rejects the validity of their gender identity; for instance by using incorrect pronouns or forms of address (misgendering).
- Physical contact and simulated physical contact (eg, textual descriptions like “hug” or “backrub”) without consent or after a request to stop. Unwelcome sexual attention.
- Gratuitous or off-topic sexual images or behavior.
- Deliberate intimidation, threats of violence, or incitement of violence towards any individual, including encouraging a person to commit suicide or to engage in self-harm.
- Stalking or following. Continued one-on-one communication after requests to cease. Constant private messaging to pick people’s brains rather than having open conversations in public channels
- Harassing photography or recording, including logging online activity for harassment purposes.
- Publication of non-harassing private communication.
- Microaggressions, which are small comments or questions, either intentional or unintentional, that marginalize people by communicating hostile, derogatory, or negative beliefs.
- Retaliating against anyone who files a formal complaint that someone has violated these codes or laws.

**We prioritize marginalized people’s safety over privileged people’s comfort.** The administrators will not act on complaints regarding:

- “Reverse”-isms, including “reverse racism,” “reverse sexism,” and “cisphobia”.
- Reasonable communication of boundaries, such as “leave me alone,” “go away,” or “I’m not discussing this with you.”
- Communicating in a “tone” you don’t find congenial.
- Criticizing racist, sexist, cissexist, or otherwise oppressive behavior or assumptions.

# **Consequences**

Members asked to stop any harassing behavior are expected to comply immediately. If a member engages in harassing behavior, the administrators may take any action they deem appropriate, up to and including removing them from Merit.

## **Reporting**

If you are being harassed, notice that someone else is being harassed, or have any other concerns, immediately contact us via Email: team@get-merit.com

We will respect confidentiality requests for the purpose of protecting victims of abuse. We reserve the right to reject any report we believe to have been made in bad faith. Reports intended to silence legitimate criticism may be deleted without response.

## **Acknowledgements**

We are greatly appreciative of the multiple sources from which we’ve based this code of conduct on:

- [Letters for Black Lives](https://lettersforblacklives.com/community-code-of-conduct-5c02a3c39ecd)
- [Women In Tech chat](https://witchat.github.io/#codeofconduct)
- [LGBTQ in Tech](http://lgbtq.technology/coc.html)
- [Vox Product](http://code-of-conduct.voxmedia.com/)
- [18F](https://github.com/CDCgov/code-of-conduct)
- [Recurse Center](https://www.recurse.com/manual)
- [Design Systems Slack](https://github.com/sushiandrobots/design-systems-slack/blob/master/_code-of-conduct.md)
- [Orbital Studios](https://help.orbital.nyc/#code-of-conduct)

`;
