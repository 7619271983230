import React from "react";
import { Col } from "react-grid-system";
import { Pane, Spinner } from "evergreen-ui";

const LoadingView = ({ title, hideSpinner }) => {
  return (
    <Col xs={12}>
      <Pane
        display="flex"
        justifyContent="center"
        background="tint2"
        borderRadius="4px"
        alignItems="center"
        paddingY="10px"
        paddingX="20px"
        marginTop="24px"
      >
        {!hideSpinner && <Spinner size={18} />}
        <Pane marginLeft={12} color="#474d66" marginTop={2}>
          {title}
        </Pane>
      </Pane>
    </Col>
  );
};

export default LoadingView;
