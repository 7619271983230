import _ from "lodash";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "@apollo/client";
import { Alert, Button, CalendarIcon, CrossIcon, Dialog, Heading, IconButton, Text, majorScale, Pane, Paragraph } from "evergreen-ui";
import IFrame from "react-iframe";
import { Link as BrowserLink, withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { HumanityCheckForm, areWeHuman } from "../../../routes/session/HumanityCheck";
import { formatSessionDate } from "../../../components/call/SessionCard";
import TextArea from "../../../components/form/TextArea";
import SessionQueries from "../../../queries/session";
import ProfileList from "../../../queries/profile/ProfileList";
import ConversationQueries from "../../../queries/conversation";
import Loader from "../../../components/Loader";

const ERROR_MSG = "We weren’t able to load this calendar. Please refresh in a few moments.";

const InitialMessageSchema = Yup.object().shape({
  message: Yup.string().min(10, "Please write a longer message").max(10000, "Please shorten the message you put in")
  // .required("Please provide an initial message"),
});

const ConfirmationPage = ({ lastBooking, myProfile, profile, onCompleted }) => {
  const { data: threadResult } = useQuery(ConversationQueries.HasConversation, {
    variables: { userID: profile?.id }
  });

  const [sendMessageToThread, { loading: sendingMsg }] = useMutation(ConversationQueries.WriteMessageMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ConversationQueries.ConversationThreadDetail,
        variables: { threadID: threadResult?.hasConversation?.id }
      }
    ]
  });

  const copy = ({ description, profile, date, myProfile }) => [
    `Your session is confirmed for ${formatSessionDate(date, myProfile?.timezone)}.`,
    `Before your session, we recommend sharing the questions and background with ${profile?.name?.first}.`
  ];

  return threadResult ? (
    <Formik
      validationSchema={InitialMessageSchema}
      initialValues={{
        threadID: threadResult?.hasConversation?.id,
        message: ""
      }}
      onSubmit={({ threadID, message }, { setSubmitting }) => {
        sendMessageToThread({
          variables: {
            threadID,
            text: message
          }
        });
        setSubmitting(false);

        if (_.isFunction(onCompleted)) {
          onCompleted();
        }
      }}
      render={() => (
        <Form>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
            {copy({ ...lastBooking, myProfile, profile }).map((info) => (
              <Paragraph>{info}</Paragraph>
            ))}
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
            <Heading marginBottom={majorScale(2)}>{`Any additional information ${profile?.name?.first} should know?`}</Heading>
            <Field name="message" component={TextArea} placeholder={`Type your message to ${profile?.name?.first}`} type="text" />
          </Pane>
          <Pane display="flex" flexDirection="row-reverse">
            <Button marginTop={majorScale(2)} marginBottom={majorScale(2)} appearance="primary" type="submit" isLoading={sendingMsg}>
              Send Message
            </Button>
          </Pane>
        </Form>
      )}
    />
  ) : (
    <Loader />
  );
};

const prefillLink = ({ calendar, schedule, myProfile, description }) => {
  // In case the mentor is still using Calendly
  let bookingLink = calendar ? calendar : schedule;
  bookingLink = `${bookingLink}?`;

  bookingLink = myProfile && myProfile.fullName ? `${bookingLink}&name=${myProfile.fullName}` : bookingLink;
  bookingLink = myProfile && myProfile.email ? `${bookingLink}&email=${myProfile.email}` : bookingLink;
  bookingLink = description ? `${bookingLink}&description=${description}&a1=${description}` : bookingLink;

  return bookingLink;
};

const CalendarDetail = withRouter((bookingContext) => {
  window.analytics.track("View Calendar");
  const [lastBooking, setLastBooking] = useState(null);

  const { history, setShowConfirmation, setShowCalendar, profile, description } = bookingContext;

  const { data: bookingDetails } = useQuery(ProfileList.ViewCalendar, {
    variables: { profileID: profile?.id }
  });

  const { data: bookingResult, error } = useQuery(SessionQueries.queries.MyMostRecentBooking, {
    pollInterval: 500
  });

  let schedule, calendar, myProfile;
  let content = <Loader />;

  if (bookingDetails?.myProfile) {
    schedule = bookingDetails?.coachByProfileId?.schedule;
    calendar = bookingDetails?.profile?.calendar;
    myProfile = bookingDetails?.myProfile;
  }

  if (error) {
    content = <Alert title={ERROR_MSG} />;
  }

  if (_.isNull(lastBooking) && bookingResult?.myMostRecentBooking?.id) {
    setLastBooking(bookingResult?.myMostRecentBooking);
  }

  if (bookingResult && bookingDetails) {
    if (lastBooking?.id && !_.isEqual(lastBooking.id, bookingResult?.myMostRecentBooking?.id)) {
      setShowConfirmation(true);
      content = (
        <ConfirmationPage
          lastBooking={bookingResult?.myMostRecentBooking}
          myProfile={bookingDetails?.myProfile}
          profile={bookingDetails?.profile}
          onCompleted={() => {
            history.push(`/session/${lastBooking?.id}/prepare`);
            setShowCalendar(false);
          }}
        />
      );
    } else {
      content = (
        <Pane>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <Pane>
            <Paragraph marginBottom={majorScale(1)}>
              After booking, if not redirected after a few moments, <BrowserLink to={"/messages"}> click here to proceed.</BrowserLink>
            </Paragraph>
          </Pane>
          <Pane display="flex" flexDirection="column" width="100%">
            <IFrame
              id={profile.id}
              url={prefillLink({ calendar, schedule, myProfile, description })}
              width={`calc('100%')`}
              height="900px"
              frameBorder={"0"}
            />
          </Pane>
        </Pane>
      );
    }
  }

  return content;
});

const CalendarView = withRouter((bookingContext) => {
  const { myProfile, profile, hasConversation, isCurrentUser, showCalendar: isShown, hideButton } = bookingContext;

  const [showCalendar, setShowCalendar] = useState(isShown);
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (!myProfile?.id) return null;

  const isHuman = areWeHuman(myProfile);

  const content = isHuman ? (
    <>
      {showCalendar && (
        <CalendarDetail
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          setShowCalendar={setShowCalendar}
          {...bookingContext}
        />
      )}
    </>
  ) : (
    <Pane width="100%">
      <HumanityCheckForm profile={profile} myProfile={myProfile} onCompleted={() => setShowCalendar(true)} />
    </Pane>
  );

  const header = showConfirmation ? (
    <Pane display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Heading is="h4" size={600}>
        {`Session Confirmed!`}
      </Heading>
      <IconButton onClick={() => setShowCalendar(false)} icon={CrossIcon} appearance="minimal" />
    </Pane>
  ) : (
    <Pane display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Heading is="h4" size={600}>
        {`Book Session with ${profile?.name?.first}`}
      </Heading>
      <IconButton onClick={() => setShowCalendar(false)} icon={CrossIcon} appearance="minimal" />
    </Pane>
  );

  return (
    <>
      <Dialog
        header={header}
        isShown={showCalendar}
        onCloseComplete={() => {
          setShowCalendar(false);
          bookingContext.history.push(`/p/${profile?.slug}`);
        }}
        hasClose={true}
        hasCancel={false}
        hasConfirm={false}
        hasFooter={false}
        preventBodyScrolling={true}
        width={showConfirmation || !isHuman ? "600px" : "100vw"}
        contentContainerProps={showConfirmation ? undefined : { width: `calc('100vw')` }}
        containerProps={{ overflowY: "scroll" }}
        maxWidth={showConfirmation ? undefined : `calc('100vw - ${majorScale(2)}')`}
        topOffset={showConfirmation ? undefined : 10}
      >
        {content}
      </Dialog>
      {!hideButton && (
        <Button
          iconBefore={CalendarIcon}
          appearance={hasConversation && !isCurrentUser ? "default" : "primary"}
          onClick={() => setShowCalendar(true)}
        >
          Book Session
        </Button>
      )}
    </>
  );
});

export default CalendarView;
