import React from "react";
import { Avatar, Heading, Pane, Paragraph, Text, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";

const Testimonial = ({ testimonial, classes, marginBottom }) => {
  return (
    <Pane
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(4)}
      width="100%"
      marginBottom={marginBottom ? majorScale(2) : null}
    >
      <Pane display="flex" justifyContent="flex-start" alignItems="center" marginBottom={majorScale(2)}>
        <Avatar name={testimonial?.name} size={64} src={testimonial.photo} />
        <Pane paddingLeft={majorScale(1)} display="flex" flexDirection="column">
          <Heading size={600}>{testimonial.name}</Heading>
          <Text size={500}>{testimonial.function}</Text>
        </Pane>
      </Pane>
      <Pane>
        <Text size={600} marginBottom={minorScale(1)}>
          {testimonial.quote}
        </Text>
      </Pane>
    </Pane>
  );
};

export default Testimonial;
