import _ from "lodash";
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert, Button, Heading, Pane, StarIcon, Paragraph, Text, majorScale, toaster, defaultTheme as theme } from "evergreen-ui";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import Slider from "rc-slider";

import SessionQueries from "../../queries/session";
import ProfileList from "../../queries/profile/ProfileList";
import LoadingView from "../profile/components/LoadingView";
import SessionCard from "../../components/session/SessionCard";
import BackButton from "../../components/BackButton";
import PreviousActions from "../../components/session/PreviousActions";
import TextArea from "../../components/form/TextArea";

const ERROR_MSG = "There was an error loading this page. Please refresh in a few moments.";
const SUBMITTED_SUCCESS = "Feedback submitted.";

const Meta = () => {
  const title = "Review Session | Merit";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:url" property="og:url" content={window.location.href} />
      <meta name="twitter:title" property="twitter:title" content={title} />
    </Helmet>
  );
};

const renderHeader = (privacy) => {
  if (_.isEqual(privacy, "null")) return "Feedback";
  else if (_.isEqual(privacy, "Public")) return "Write a public testimonial";
  else return `${privacy} Feedback`;
};

const Questions = ({ responses }) => {
  const infoText = {
    Private: "This is just for your own reflection. The other person will not see it.",
    Admin: "This is just for the Merit staff - it helps us improve the platform for future sessions."
  };

  return _.chain(responses)
    .groupBy("privacy")
    .map((questions, privacy) => (
      <Pane
        display="flex"
        flexDirection="column"
        width="100%"
        padding={majorScale(4)}
        borderRadius={majorScale(2)}
        border={`1px solid ${theme.colors.gray500}`}
        backgroundColor="white"
        minHeight="100px"
        marginTop={majorScale(4)}
      >
        <Heading size={500}>{renderHeader(privacy)}</Heading>
        {infoText[privacy] && <Heading size={400}>{infoText[privacy]}</Heading>}
        {questions.map((question) => (
          <>
            <Paragraph size={500} marginBottom={majorScale(1)}>
              {question.question}
            </Paragraph>
            <Field
              name={question.name}
              type="textarea"
              defaultValue={question.answer}
              component={TextArea}
              placeholder={question.placeholder}
            />
          </>
        ))}
      </Pane>
    ))
    .value();
};

const getAnswer = (responses, name) => {
  let answer = _.chain(responses)
    .filter((response) => {
      return _.isEqual(response.name, name);
    })
    .head()
    .value();

  answer = answer ? answer.answer : null;
  return answer;
};

const FeedbackForm = ({ myProfile, session, responses, onCompleted, rating, history }) => {
  const [reviewSession] = useMutation(SessionQueries.mutations.ReviewSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.SessionAndReview,
        variables: {
          sessionID: session.id
        }
      },
      { query: ProfileList.LayoutQuery }
    ],
    onCompleted: () => {
      toaster.success(SUBMITTED_SUCCESS, { duration: 2 });

      // Track that the review was completed
      window.analytics.track("Session Review Completed", {
        id: session.id,
        coach: session.coach.id,
        coachName: session.coach.user.fullName
      });

      if (_.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });

  const allResponses = responses?.responses;
  return (
    <Formik
      initialValues={{
        rating,
        message: getAnswer(allResponses, "message") ? getAnswer(allResponses, "message") : "",
        lesson: getAnswer(allResponses, "lesson") ? getAnswer(allResponses, "lesson") : "",
        improvement: getAnswer(allResponses, "improvement") ? getAnswer(allResponses, "improvement") : "",
        admin: getAnswer(allResponses, "admin") ? getAnswer(allResponses, "admin") : "",
        success: getAnswer(allResponses, "success") ? getAnswer(allResponses, "success") : "",
        testimonial: getAnswer(allResponses, "testimonial") ? getAnswer(allResponses, "testimonial") : "",
        expertise: getAnswer(allResponses, "expertise") ? getAnswer(allResponses, "expertise") : "",
        questions: getAnswer(allResponses, "questions") ? getAnswer(allResponses, "questions") : "",
        stories: getAnswer(allResponses, "stories") ? getAnswer(allResponses, "stories") : "",
        actions: getAnswer(allResponses, "actions") ? getAnswer(allResponses, "actions") : "",
        responses
      }}
      onSubmit={(reviewProps) => {
        const { rating, message, lesson, improvement, admin, responses, success, testimonial, expertise, questions, stories, actions } =
          reviewProps;

        reviewSession({
          variables: {
            sessionID: session.id,
            rating,
            message,
            lesson,
            improvement,
            admin,
            responses,
            success,
            testimonial,
            expertise,
            questions,
            stories,
            actions
          }
        });
      }}
      render={({ values: { rating, responses }, errors, status, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            marginTop={majorScale(4)}
            marginBottom={majorScale(1)}
          >
            <Heading size={700}>
              <StarIcon size={16} />
              {" Leave Session Feedback"}
            </Heading>
            <PreviousActions session={session} direction="previous" hideFeedback onCompleted={() => history.push(`/sessions`)} />
          </Pane>
          <SessionCard myProfile={myProfile} session={session} hideMsgs />
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Heading size={500} marginBottom={majorScale(2)}>
                  {`How would you rate the session?`}
                </Heading>
                <Slider
                  defaultValue={rating}
                  min={1}
                  max={5}
                  id="rating"
                  style={{ marginBottom: majorScale(4) }}
                  onChange={(value) => setFieldValue("rating", value)}
                  marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                />
              </Pane>
              <Questions responses={responses?.responses} />
            </Pane>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              paddingTop={majorScale(2)}
              paddingBottom={majorScale(6)}
            >
              <Button type="submit" appearance="minimal" intent="none">
                Skip Feedback
              </Button>
              <Button type="submit" appearance="primary" intent="success">
                Save Feedback
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const FeedbackFormContainer = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.SessionAndReview, {
    variables: {
      sessionID: id
    }
  });

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <>
          <Meta />
          <Row
            justify="flex-start"
            style={{
              width: width[screenClass],
              backgroundColor: theme.colors.gray100,
              minHeight: "calc(100vh - 120px)",
              paddingY: majorScale(4)
            }}
          >
            <Col sm={2} md={2} lg={2} xs={12}>
              <BackButton />
            </Col>
            <Col lg={8} md={8} sm={8} xs={12}>
              {loading && <LoadingView />}
              {error && <Alert intent="danger" title={ERROR_MSG} />}
              {sessionAndReview && !sessionAndReview?.sessionById?.id && <Redirect to={`/sessions`} />}
              {sessionAndReview && sessionAndReview?.sessionById?.id && (
                <FeedbackForm
                  session={sessionAndReview?.sessionById}
                  responses={sessionAndReview?.responsesbySession}
                  myProfile={sessionAndReview?.myProfile}
                  rating={sessionAndReview?.responsesbySession?.rating}
                  history={history}
                  onCompleted={() => history.push(`/feedback/${id}/submitted`)}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    />
  );
};

export default FeedbackFormContainer;
