import _ from "lodash";
import React from "react";
import { Avatar, Button, Card, Heading, Pane, Text, majorScale, Link, Strong } from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";

class Group extends React.Component {
  render() {
    const { group, action, actionTitle, isCompact } = this.props;
    const button =
      _.isFunction(action) && _.isString(actionTitle) ? (
        <Pane paddingLeft={majorScale(2)}>
          <Button appearance="minimal" intent="danger" onClick={() => action()}>
            {actionTitle}
          </Button>
        </Pane>
      ) : null;

    const compactGroup = (
      <Link is={BrowserLink} to={`/group/${group.id}`} style={{ textDecoration: "none" }} target="_blank">
        <Card
          elevation={1}
          width={175}
          padding={majorScale(2)}
          marginBottom={majorScale(2)}
          marginRight={majorScale(2)}
          display="flex"
          flexDirection="column"
        >
          <Pane display="flex" justifyContent="center">
            <Avatar src={group.logo} size={majorScale(12)} />
          </Pane>
          <Pane display="flex" justifyContent="center">
            <Strong marginTop={majorScale(1)}>{group.name}</Strong>
          </Pane>
        </Card>
      </Link>
    );

    if (!_.isNil(isCompact) && isCompact) {
      return compactGroup;
    } else {
      return (
        <Pane
          padding={majorScale(2)}
          display="flex"
          flexDirection="row"
          marginBottom={majorScale(2)}
          justifyContent="stretch"
          alignItems="center"
          background
        >
          <Avatar src={group.logo} size={majorScale(8)} />
          <Pane paddingLeft={majorScale(2)} display="flex" flexDirection="column" justifyContent="stretch" width={"100%"}>
            <Pane display="flex" alignItems="start" flexDirection="column">
              <Heading>{group.name}</Heading>
              <Text>{_.truncate(group.description, { length: 100 })}</Text>
            </Pane>
          </Pane>
          {button}
        </Pane>
      );
    }
  }
}

export default Group;
