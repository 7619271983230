import _ from "lodash";
import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { Button, Heading, Pane, Paragraph, majorScale, toaster, defaultTheme as theme } from "evergreen-ui";
import moment from "moment";
import Select from "react-select";

import ProfileList from "../../../queries/profile/ProfileList";

const TIMEZONE_PLACEHOLDER = `Pick your timezone`;
const TIMEZONE_TOAST = `Timezone updated.`;

const createSelectLabels = (labels) => labels.map((label) => ({ value: label, label }));

const AutoDetectTimezone = ({ selectedTimezone, updateTimezone }) => {
  const click = () => {
    selectedTimezone = { value: moment.tz.guess(), label: moment.tz.guess() };
    updateTimezone({
      variables: {
        timezone: moment.tz.guess()
      }
    });
    toaster.success(TIMEZONE_TOAST, { duration: 2 });
  };

  return _.isNil(selectedTimezone) ? (
    <Button onClick={click} appearance="minimal" margin={0} padding={0}>
      Auto detect
    </Button>
  ) : null;
};

const TimezoneField = ({ selectedTimezone, availableTimezones }) => {
  const [updateTimezone] = useMutation(ProfileList.UpdateTimezone, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ]
  });

  return (
    <Pane marginBottom={majorScale(2)}>
      <Heading size={500} paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
        What is your preferred timezone?
      </Heading>
      <Pane display="flex" justifyContent="space-between" alignItems="center" marginBottom={majorScale(1)}>
        <Paragraph size={500}>Your timezone affects the dates shown in our app.</Paragraph>
        <AutoDetectTimezone selectedTimezone={selectedTimezone} updateTimezone={updateTimezone} />
      </Pane>
      <Select
        options={availableTimezones}
        value={selectedTimezone}
        isClearable={true}
        isSearchable={true}
        placeholder={TIMEZONE_PLACEHOLDER}
        onChange={(selected) => {
          updateTimezone({
            variables: {
              timezone: _.isNil(selected) ? null : selected.value
            }
          });
          toaster.success(TIMEZONE_TOAST, { duration: 2 });
        }}
      />
    </Pane>
  );
};

const ProfileOverviewForm = ({ myProfile }) => {
  useEffect(() => {
    window.analytics.track("View Settings: Profile");
  });

  const selectedTimezone = _.isNil(myProfile.timezone) ? null : { value: myProfile.timezone, label: myProfile.timezone };
  const timezones = createSelectLabels(moment.tz.names());

  return (
    <Pane marginTop={majorScale(4)}>
      <Heading size={600} marginBottom={majorScale(2)}>
        Account Information
      </Heading>
      <Pane
        display="flex"
        flexDirection="column"
        width="100%"
        padding={majorScale(4)}
        borderRadius={majorScale(2)}
        border={`1px solid ${theme.colors.gray500}`}
        backgroundColor="white"
        minHeight="100px"
      >
        <TimezoneField selectedTimezone={selectedTimezone} availableTimezones={timezones} />

        <Pane marginBottom={majorScale(2)}>
          <Heading size={500} paddingTop={majorScale(1)}>
            Update your account
          </Heading>
        </Pane>

        <Pane display="flex" justifyContent="space-between" marginTop={majorScale(1)} alignItems="center">
          <Paragraph size={500}>Click here to reset your password.</Paragraph>
          <Button is={Link} to={`/request_reset_password`} appearance="minimal">
            Reset Password
          </Button>
        </Pane>

        <Pane display="flex" justifyContent="space-between" marginTop={majorScale(1)} alignItems="center">
          <Paragraph size={500}>Click here to change your email.</Paragraph>
          <Button is={Link} to={`/request-change-email?email=${myProfile.email}`} appearance="minimal">
            Change Email
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default ProfileOverviewForm;
