import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Button, Dialog, Pane, Textarea, Text, majorScale, minorScale, toaster } from "evergreen-ui";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileList from "../../../queries/profile/ProfileList";

const SHARE_SUCCESS = "Awesome! You've let your network know you're a mentor!";
const SHARING_HEADER = "Tell your network that you’re a mentor!";
const SHARING_INFO = [
  "Let folks in your network help you get mentorship sessions sooner by sharing your profile! ✨",
  "Even if your calendar fills up, we redirect mentees to currently available mentors with similar skillsets. It’s a win-win!",
  "Here’s a sample message:"
];

const LINKEDIN_INFO = [
  "We find LinkedIn is a great place to share your mentor profile.  Once you click on the link below, we’ll direct you to LinkedIn to post!"
];

const CreateSampleMessage = (link) =>
  `Looking to navigate your career in tech? Book time with me on Merit - let’s talk!

I setup 1:1 office hours with folks who are early in their career in tech. I’m looking to give back and broaden my understanding of the field. Especially if you identify as someone under-represented in tech, I’d love to help! ✨

I’m happy to talk about career transitions, learning new skills, and connect you to other resources wherever I can be helpful.

More info here:

${link}

Feel free to share in your networks - thanks!`;

const CopyShareMessage = ({ myProfile, copy, setCopy, onCopy }) => (
  <Pane display="flex" flexDirection="column">
    <Pane marginBottom={majorScale(2)} display="flex" flexDirection="column">
      {SHARING_INFO.map((text) => (
        <Text marginBottom={minorScale(2)}>{text}</Text>
      ))}
    </Pane>

    <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
      <Textarea value={copy} onChange={(e) => setCopy(e.target.value)} style={{ resize: "none" }} minHeight={majorScale(30)} />
    </Pane>

    <Pane display="flex" flexDirection="row-reverse" marginBottom={majorScale(4)}>
      <Button
        appearance="primary"
        onClick={() => {
          navigator.clipboard.writeText(copy);
          toaster.success(`Copied to clipboard!`, { duration: 2 });
          onCopy();
        }}
      >
        {`🔗 Copy`}
      </Button>
    </Pane>
  </Pane>
);

const PostToLinkedIn = ({ myProfile, copy, setCopy, onShare }) => (
  <Pane display="flex" flexDirection="column">
    <Pane marginBottom={majorScale(2)} display="flex" flexDirection="column">
      <Text marginBottom={minorScale(2)}>{LINKEDIN_INFO}</Text>
    </Pane>

    <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
      <Textarea value={copy} style={{ resize: "none" }} minHeight={majorScale(30)} />
    </Pane>

    <Pane display="flex" flexDirection="row-reverse" marginBottom={majorScale(4)}>
      <Button
        is="a"
        href={`${myProfile?.linkedin}/overlay/create-post`}
        target="_blank"
        appearance="primary"
        iconBefore={<FontAwesomeIcon icon={faLinkedinIn} />}
        onClick={() => onShare()}
      >
        {`Open LinkedIn`}
      </Button>
    </Pane>
  </Pane>
);

const ProfileShare = ({ showShare, myProfile, location, history, setShowShare }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [shareOnSocial] = useMutation(ProfileList.ShareOnSocial, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: ProfileList.ProfileStrengthQuery }],
    onCompleted: () => {
      toaster.success(SHARE_SUCCESS, { duration: 2 });
      history.push(`/p/${myProfile?.slug}/edit`);
      setShowShare(false);
    }
  });

  const profileLink = `${window?.origin}/p/${myProfile?.slug}`;
  const [copy, setCopy] = useState(CreateSampleMessage(profileLink));

  return (
    <>
      <Dialog
        isShown={showShare}
        title={SHARING_HEADER}
        onCancel={() => setShowShare(false)}
        onCloseComplete={() => setShowShare(false)}
        confirmLabel="Finish"
        hasCancel={false}
        preventBodyScrolling={true}
        hasFooter={false}
      >
        {linkCopied ? (
          <PostToLinkedIn
            myProfile={myProfile}
            copy={copy}
            setCopy={setCopy}
            onShare={() => {
              shareOnSocial({
                variables: { where: "LinkedIn" }
              });
            }}
          />
        ) : (
          <CopyShareMessage myProfile={myProfile} copy={copy} setCopy={setCopy} onCopy={() => setLinkCopied(true)} />
        )}
      </Dialog>
    </>
  );
};

export default withRouter(ProfileShare);
