import _ from "lodash";
import React, { useState } from "react";
import { Alert, Button, Heading, Menu, Pane, Text, majorScale, minorScale } from "evergreen-ui";

import HoverLink from "../HoverLink";

const withoutEmoji = (str) => {
  return str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );
};

const LIST_CUTOFF = 10;

const DiscussionTopicIndex = (props) => {
  const { postTopics } = props;
  const [showMore, setShowMore] = useState(false);
  const numItems = showMore ? postTopics?.length : LIST_CUTOFF;

  return (
    postTopics &&
    postTopics.length > 0 && (
      <Pane display="flex" flexDirection="column" width="100%">
        <Heading size={700} marginBottom={majorScale(3)}>
          Discussions By Topic
        </Heading>
        {postTopics.slice(0, numItems).map(({ topic, id, slug }) => (
          <HoverLink samePage noUnderline to={`/q/${topic?.slug}`} id={`DiscussionTopicIndex-Topic-${id}`}>
            <Pane display="flex" flexDirection="row" width="100%" alignItems="center" marginBottom={majorScale(2)}>
              <Pane
                background="#EBF0FE"
                width={minorScale(9)}
                height={minorScale(9)}
                borderRadius={minorScale(1)}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                marginRight={majorScale(2)}
              >
                <Heading size={600} textDecoration="none" style={{ textDecoration: "none !important" }}>
                  {String.fromCodePoint(topic.name.codePointAt(0))}
                </Heading>
              </Pane>
              <Heading size={400}>{_.trim(withoutEmoji(topic.name))}</Heading>
            </Pane>
          </HoverLink>
        ))}
        {postTopics.length > LIST_CUTOFF && (
          <Button paddingX="10px" color="#3366FF" appearance="minimal" marginTop={majorScale(1)} onClick={() => setShowMore(!showMore)}>
            {showMore ? "See less" : "See more"}
          </Button>
        )}
      </Pane>
    )
  );
};

export default DiscussionTopicIndex;
