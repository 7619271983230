export const plusValueProps = [
  {
    title: "Monthly coach recommendations",
    heroShot: "https://ucarecdn.com/3e44873c-34d7-4551-820f-c60f9b1c884e/browse.svg",
    description: "Get monthly coach recommendations based on your goals, requests or interests."
  },
  {
    title: "Primary coach matching",
    heroShot: "https://ucarecdn.com/6fe72d54-6cfd-4ea0-acea-86f71805d2b4/conversation.svg",
    description: "Set up recurring sessions with your favorite coach to go deep on a topic or work through a longer term goal."
  },

  {
    title: "Regular goal check-ins",
    heroShot: "https://ucarecdn.com/2feabe61-0528-4c4f-91e2-48084785e144/Calendar.sv",
    description: "Review your goals and progress regularly with a Merit Experience Manager."
  }
];

export const plusReasons = [
  {
    title: "Personalized & Actionable",
    heroShot: "https://ucarecdn.com/96b5b11a-ca6c-4cb0-8add-05bbb6359350/Lightbulb.svg",
    description: "Careers are messy, so we want something that adapts to where you are right now"
  },
  {
    title: "Accountability & Support",
    heroShot: "https://ucarecdn.com/c074bb47-887d-4e5c-bc7a-b4d3d389feb6/smiley.svg",
    description: "Jobs are emotional - it’s critical to have layers of support without the extra baggage"
  },
  {
    title: "Grows with you",
    heroShot: "https://ucarecdn.com/fd34ef63-46f9-441e-8de3-378e5dae84a5/trophy.svg",
    description: "And when you achieve a milestone, your support structure will have to evolve with you."
  }
];

export const salesLink = "https://calendly.com/merit-coaches/merit";
export const salesCopy = "Book Demo";

export const plusFAQ = [
  {
    question: "How do you start?",
    answer:
      "Learners are connected with an Experience Manager who they meet with quarterly. Based on current career goals and challenges, the Experience Manager pairs the learner with a Primary Coach who they connect with on a recurring basis and/or also receive a set of recommended monthly coaches to discuss a specific topic in depth. Learners can choose which best suits their learning style."
  },
  {
    question: "How much does it cost?",
    answer: "Merit costs $70 per month, paid month-to-month."
  },
  {
    question: "How many sessions can I book?",
    answer: "There is no limit to the number of sessions you can book. Most active learners book at least one session a month."
  },
  {
    question: "What are the demographics of your customer base?",
    answer: "67% are people of color and 55% are women."
  },
  {
    question: "Are Merit coaches paid?",
    answer: "Merit coaches are not paid, they volunteer their time."
  },
  {
    question: "Will I receive a certificate?",
    answer: "We can provide a certificate of completion upon request."
  }
];

export const plusUseCases = [
  "👩🏽‍🏫 Gain accountability and feedback from a coach",
  "💰 Get a raise, promotion or new role",
  "🎫 Change roles (such as engineering → product)",
  "🎯 Get the most out of your first tech job",
  "🧑‍🤝‍🧑 Handle year one as a manager",
  "🛣️ Decide between being a manager or an individual contributor",
  "⛰️ Level up a skill such as user interviewing, prototyping or giving feedback",
  "😟 Get support during a merger, acquisition or layoff"
];

export const tagline = `What does growth look like? \n It’s messy. 🙈`;

export const meritFreeExplainer = `Career and skill discovery`;
export const meritPlusExplainer = `Career and skill achievement`;

export const meritPlusOutcomes = `What can I achieve with Merit Plus?`;

export const meritFree = [
  "✅ Access to playlists & coaches",
  "✅ Ad-hoc coaching sessions",
  "❌ 1 Coach recommendation",
  "❌ 1 Goal check-in",
  "❌ Primary coach matching"
];

export const meritPlus = [
  "✅ Access to playlists & coaches",
  "✅ Ad-hoc coaching sessions",
  "✅ Monthly coach recommendations",
  "✅ Regular goal check-ins",
  "✅ Primary coach matching"
];

export const managersFAQ = [
  {
    question: "What is Merit?",
    answer: `Career coaching designed to empower learners of all levels and backgrounds. Merit connects learners and coaches to provide career advice and grow skills. Coaches participate to improve their coaching abilities and gain exposure to a wide variety of learners and career backgrounds. Learners and coaches are connected through video calls. Getting started is as simple as choosing a topic and booking a time.`
  },
  {
    question: "Who are the coaches?",
    answer:
      "Merit coaches are Directors, Managers, and Senior Individuals Contributors at a variety of small and large companies. We screen and train all of our coaches, and they all agree to abide by our Code of Conduct. They coach Learners on the topics they practice every day at their jobs."
  },
  {
    question: "Who uses Merit?",
    answer:
      "Merit works best for Product Managers, Software Engineers, and Product Designers. Those in transition to more team responsibility get the most value out of Merit. (E.g. first year into management, becoming a product manager, or being promoted to a tech lead.)"
  },
  {
    question: "What do Learners get out of the coaching sessions?",
    answer:
      "Learners have an opportunity to provide feedback on every call - this helps the coaches improve their craft and helps Merit improve the overall experience. "
  }
];

export const coachRecsImage = `https://ucarecdn.com/c4fc01e3-6ef9-4d93-b6d1-8522f0f449c3/ScreenShot20200520at172543.png`;
export const coachGoalsImage = `https://ucarecdn.com/217f0250-a6ba-41a9-8af1-f6c9f450814b/ScreenShot20200520at172714.png`;
export const coachMatchImage = `https://ucarecdn.com/c3aeb6eb-b11c-4fa3-9fae-690d1784f15e/match2.png`;
