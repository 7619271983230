import _ from "lodash";
import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import { Button, Dialog, Heading, Pane, Spinner, majorScale } from "evergreen-ui";
import ShowMore from "@tedconf/react-show-more";
import { useQuery } from "@apollo/client";

import PlaylistQueries from "../../../queries/playlist";
import NewTopicCard from "../../../components/topic/NewTopicCard";
import TopicFilters from "../../../components/topic/TopicFilters";

const PAGE_SIZE = 100;

const initialQuery = {
  cursor: "1",
  name: "",
  levels: [],
  roles: [],
  motivations: [],
  sort: "DEFAULT",
  pageSize: PAGE_SIZE
};

const EditTopicsContainer = (props) => {
  const { showEditor, setShowEditor, myCoachingProfile } = props;
  const [query, setQuery] = useState(initialQuery);

  const {
    data: topicResult,
    refetch,
    fetchMore,
    loading
  } = useQuery(PlaylistQueries.TopicSearchQuery, {
    variables: query
  });

  let topicSearch, topicFilters, updateSearch, next, topicsById;

  if (topicResult?.topicSearch) {
    topicSearch = topicResult.topicSearch;
    topicFilters = topicResult.topicFilters;

    updateSearch = (updated) => {
      setQuery(updated);
      refetch({ variables: { ...updated } });
    };

    topicsById = _.groupBy(myCoachingProfile?.playlists, (t) => t?.id);

    next = () =>
      fetchMore({
        query: PlaylistQueries.TopicSearchQuery,
        variables: {
          ...query,
          cursor: topicSearch?.pageInfo?.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            topicSearch: {
              __typename: fetchMoreResult.topicSearch.__typename,
              pageInfo: fetchMoreResult.topicSearch.pageInfo,
              edges: [...prev.topicSearch.edges, ...fetchMoreResult.topicSearch.edges]
            }
          });
        }
      });
  }

  return (
    <Dialog
      width={800}
      isShown={showEditor}
      onCloseComplete={() => setShowEditor(false)}
      confirmLabel="Finish"
      hasCancel={false}
      hasClose={false}
      preventBodyScrolling={true}
      header={
        <Pane display="flex" justifyContent="space-between" alignItems="start" width="100%" flexDirection="column">
          <Pane>
            <Heading is="h4" size={600}>
              Edit Mentorship Topics
            </Heading>
          </Pane>
          <Pane display="flex" width="100%" flexDirection="column">
            {topicSearch?.edges && <TopicFilters updateSearch={updateSearch} query={query} topicFilters={topicFilters} />}
          </Pane>
        </Pane>
      }
    >
      {!loading && topicSearch?.edges && (
        <Row>
          <ShowMore items={topicSearch?.edges} by={PAGE_SIZE}>
            {({ current, onMore }) => (
              <>
                <Pane>
                  {topicSearch?.edges?.map((topic) => (
                    <Col xs={12} key={topic.id}>
                      <Pane borderBottom="1px solid #E6E8F0">
                        <NewTopicCard topic={topic} hasTopic={_.hasIn(topicsById, topic.id)} isEditing />
                      </Pane>
                    </Col>
                  ))}
                </Pane>
                {topicSearch?.pageInfo?.hasNextPage && (
                  <Button
                    width="100%"
                    marginY={majorScale(1)}
                    paddingY={majorScale(1)}
                    appearance="minimal"
                    onClick={next}
                    isLoading={loading}
                  >
                    Show More
                  </Button>
                )}
              </>
            )}
          </ShowMore>
        </Row>
      )}

      {loading && (
        <Pane width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Spinner />
        </Pane>
      )}
    </Dialog>
  );
};

export default EditTopicsContainer;
