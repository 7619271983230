import _ from "lodash";
import React, { useState } from "react";
import { Alert, Button, Pane, majorScale, toaster, defaultTheme as theme, PlusIcon } from "evergreen-ui";
import { useMutation, useQuery } from "@apollo/client";

import WorkExperienceForm from "../../../components/profile/WorkExperienceForm";
import ProfileList from "../../../queries/profile/ProfileList";
import ProfileQuery from "../../../components/profile/ProfileQuery";
import CandidateQueries from "../../../queries/candidate";

const ROLE_DELETED_TOAST = "Role deleted.";
const ROLE_ADDED_TOAST = "Role added.";
const EMPTY_ROLES_COPY = "No roles listed.";

const deleteNotif = _.debounce(() => toaster.success(ROLE_DELETED_TOAST, { duration: 2 }), 1000);
const createNotif = _.debounce(() => toaster.success(ROLE_ADDED_TOAST, { duration: 2 }), 1000);

const CreateRole = ({ onCreate, myProfile }) => {
  const emptyRole = {
    teamName: null,
    teamID: null,
    title: null,
    startDate: null,
    endDate: null,
    teamLogo: null,
    teamDomain: null
  };

  const [createRole] = useMutation(ProfileList.CreateRole, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery },
      { query: ProfileQuery, variables: { slug: myProfile?.slug } }
    ],
    onCompleted: ({ createRole }) => {
      createNotif();
    }
  });

  return (
    <Button
      iconBefore={PlusIcon}
      onClick={() => {
        const created = _.cloneDeep(emptyRole);
        createRole({ variables: { ...created } });
      }}
    >
      Add Role
    </Button>
  );
};

const EditWorkExperienceContainer = (props) => {
  const roles = props?.myProfile?.roles;
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);
  const [deleteRole] = useMutation(ProfileList.DeleteRole, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery },
      { query: ProfileQuery, variables: { slug: props?.myProfile?.slug } }
    ]
  });

  const [upDownRole] = useMutation(ProfileList.UpDownRole, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery },
      { query: ProfileQuery, variables: { slug: props?.myProfile?.slug } }
    ]
  });

  const isLast = (index, roles) => index === roles.length - 1;

  const featuresByGrouping = _.reduce(
    profileOptions?.availableTeamFeatureCategories,
    (acc, category) => {
      acc[category.value] = _.chain(profileOptions?.availableTeamFeatures)
        .filter({ category: category.value })
        .map((feature) => ({ label: feature.attribute, value: feature.attribute }))
        .value();

      return acc;
    },
    {}
  );

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between" alignItems="start">
        <CreateRole myProfile={props?.myProfile} />
      </Pane>
      <Pane marginTop={majorScale(2)}>
        {roles && !_.isEmpty(roles) ? (
          roles.map((role, index) => (
            <Pane
              marginBottom={majorScale(3)}
              key={`WorkExperienceForm-${role?.id}-${index}`}
              paddingBottom={!isLast(index, roles) ? majorScale(2) : null}
              borderBottom={!isLast(index, roles) ? `1px solid #E6E8F0` : null}
            >
              <WorkExperienceForm
                role={role}
                items={roles}
                position={index}
                myProfile={props?.myProfile}
                companySizeOptions={featuresByGrouping?.companySize}
                industryOptions={featuresByGrouping?.industry}
                onDelete={() => {
                  deleteRole({ variables: { roleID: role.id } });
                  deleteNotif();
                  window.analytics.track("Update Work Experience");
                }}
                onMove={(direction) => {
                  upDownRole({
                    variables: {
                      roleID: role.id,
                      direction
                    }
                  });
                }}
              />
            </Pane>
          ))
        ) : (
          <Pane>
            <Alert intent="warning" title={EMPTY_ROLES_COPY} marginTop={majorScale(2)} marginBottom={majorScale(2)} />
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

export default EditWorkExperienceContainer;
