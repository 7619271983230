import React from "react";
import {
  RefreshIcon,
  DeleteIcon,
  CrossIcon,
  EditIcon,
  Pane,
  Popover,
  Menu,
  Button,
  CameraIcon,
  DisableIcon,
  EnvelopeIcon,
  InfoSignIcon,
  majorScale
} from "evergreen-ui";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { fromUnixTime, isToday } from "date-fns";
import { Link as BrowserLink } from "react-router-dom";
import { ScreenClassRender } from "react-grid-system";

import { isMentor } from "./utils";

const ViewVideoLink = ({ session, mentorIsViewing, isMobile, sessionIsToday }) => {
  const MentorLink = gql`
    query MentorVideoLink($profileID: String!) {
      profile(profileID: $profileID) {
        id
        videoLink
      }
    }
  `;

  const SessionVideoLink = gql`
    query SessionVideoLink($sessionID: String!) {
      sessionById(sessionID: $sessionID) {
        id
        videoLink
      }
    }
  `;

  const { data: profileResult } = useQuery(MentorLink, {
    variables: {
      profileID: session?.coach?.user?.id
    }
  });

  const { data: sessionResult } = useQuery(SessionVideoLink, {
    variables: {
      sessionID: session?.id
    }
  });

  const videoLink = sessionResult?.sessionById?.videoLink ? sessionResult?.sessionById?.videoLink : profileResult?.profile?.videoLink;

  return (
    <Button
      marginRight={isMobile ? null : majorScale(1)}
      marginBottom={isMobile ? majorScale(1) : null}
      appearance={sessionIsToday ? "primary" : "secondary"}
      iconBefore={CameraIcon}
      is="a"
      href={videoLink}
      target="_blank"
    >
      Join Meeting
    </Button>
  );
};

const UpcomingActions = ({ session, myProfile, isCol, hideFeedback }) => {
  const options =
    session?.rescheduleCount > 1
      ? [
          {
            icon: DeleteIcon,
            title: "Cancel",
            link: `/session/${session?.id}/cancel`
          }
        ]
      : [
          {
            icon: RefreshIcon,
            title: "Reschedule",
            link: `/session/${session?.id}/reschedule`
          },
          {
            icon: DeleteIcon,
            title: "Cancel",
            link: `/session/${session?.id}/cancel`
          }
        ];

  const mentorIsViewing = isMentor({ session, myProfile });
  const sessionIsToday = isToday(fromUnixTime(Number(session?.date) / 1000));

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = isCol || (screenClass.includes("xs") ? true : false);

        return (
          <Pane
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            flexWrap="wrap"
            width={hideFeedback ? "" : "100%"}
            marginTop={hideFeedback ? null : majorScale(3)}
          >
            <Popover
              content={
                <Menu>
                  <Menu.Group title={"Need to change?"}>
                    {options.map(({ icon, title, link }) => (
                      <Menu.Item key={`option-${title}`} icon={icon} is={BrowserLink} to={link}>
                        {title}
                      </Menu.Item>
                    ))}
                  </Menu.Group>
                  <Menu.Group title={"Something else?"}>
                    <Menu.Item icon={InfoSignIcon} is={BrowserLink} to={"/booking-policy"}>
                      Booking Rules
                    </Menu.Item>
                    <Menu.Item icon={EnvelopeIcon} is={"a"} href="mailto:support@get-merit.com">
                      Contact Support
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <Button marginBottom={isMobile ? majorScale(1) : null} appearance="minimal" iconBefore={DisableIcon}>
                Need to change
              </Button>
            </Popover>
            {!hideFeedback && (
              <Pane display="flex" flexDirection={isMobile ? "column" : "row"}>
                <ViewVideoLink isMobile={isMobile} session={session} mentorIsViewing={mentorIsViewing} sessionIsToday={sessionIsToday} />
                <Button appearance="primary" is={BrowserLink} to={`/session/${session?.id}/prepare`} iconBefore={EditIcon}>
                  {mentorIsViewing ? "Review notes" : "Prepare for session"}
                </Button>
              </Pane>
            )}
          </Pane>
        );
      }}
    />
  );
};

export default UpcomingActions;
