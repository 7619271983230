import _ from "lodash";
import React, { useState } from "react";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  Button,
  Checkbox,
  CaretDownIcon,
  Dialog,
  Heading,
  Pane,
  Paragraph,
  SelectMenu,
  toaster,
  majorScale,
  PlusIcon,
  IconButton,
  CrossIcon
} from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";

import CandidateQueries from "../../../../queries/candidate";
import ProfileQuery from "../../../../components/profile/ProfileQuery";
import { format } from "date-fns";

const initSkills = ({ skillsByRole, myCandidateProfile }) => {
  return _.reduce(
    skillsByRole,
    (allSkills, { value: aSkill }) => {
      allSkills[aSkill] = _.find(myCandidateProfile?.specialities, { skill: aSkill }) ? true : false;

      return allSkills;
    },
    {}
  );
};

const onMove = (direction, skills, skill, setFieldValue) => {
  const current = skills?.indexOf(skill);
  const updated = _.isEqual(direction, "up") ? current - 1 : current + 1;
  const change = skills.splice(current, 1)[0];

  skills.splice(updated, 0, change);
  setFieldValue("specialities", skills, false);
};

const CandidateWorkExperienceForm = (props) => {
  const [skillsByRole, setSkillsByRoles] = useState({});
  const [updateSpecialities] = useMutation(CandidateQueries.mutations.UpdateSpecialties, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: CandidateQueries.queries.MyCandidateProfile },
      {
        query: ProfileQuery,
        variables: {
          slug: props?.myProfile?.slug
        }
      }
    ],
    onCompleted: () => {
      if (props?.setUpdatedAt) {
        props.setUpdatedAt(new Date());
      }
    }
  });

  const { myCandidateProfile, myProfile, availableJobRoles, availableYearsOfExperience, availableSkills, expertiseCopy } = props;

  // Reformat the data structure to work for display
  const categoryMapping = {
    "Software Engineering": "engineering",
    "Product Design": "design",
    "Product Management": "product",
    Other: "other",
    Management: "management"
  };

  const featuresByGrouping = _.reduce(
    availableJobRoles,
    (acc, category) => {
      acc[categoryMapping[category]] = _.chain(availableSkills)
        .filter({ category: categoryMapping[category] })
        .map((feature) => ({ label: feature.attribute, value: feature.attribute }))
        .value();

      return acc;
    },
    {}
  );

  const chooseSkills = (skillsByRole, setFieldValue) => {
    const selected = _.chain(skillsByRole)
      .pickBy((value, key) => value)
      .keys()
      .value();

    setFieldValue("skillNames", selected, false);
  };

  return (
    <Formik
      initialValues={{
        specialities: myCandidateProfile?.specialities?.map((v) => _.omit(v, "__typename")) || [],
        skillNames: myCandidateProfile?.skillNames || [],
        jobRole: myCandidateProfile?.jobRole || myProfile?.jobRole || ""
      }}
      onSubmit={({ jobRole, specialities }, { setSubmitting }) => {
        updateSpecialities({
          variables: {
            jobRole,
            specialities
          }
        });
        setSubmitting(false);
      }}
      render={({ values, setFieldValue, submitForm }) => {
        if (_.isEmpty(skillsByRole) && values.jobRole && featuresByGrouping[categoryMapping[values.jobRole]]?.length > 0) {
          const initial = initSkills({ skillsByRole: featuresByGrouping[categoryMapping[values?.jobRole]], myCandidateProfile });
          setSkillsByRoles(initial);
        }

        return (
          <Form>
            <Pane marginBottom={majorScale(3)}>
              <Pane marginBottom={majorScale(1)}>
                <Heading>{expertiseCopy ? "" : "What type of position are you looking for?"}</Heading>
                {!expertiseCopy && (
                  <Paragraph marginBottom={majorScale(1)}>This will help us match you with the right opportunities.</Paragraph>
                )}
              </Pane>
              <SelectMenu
                title="Select role"
                options={availableJobRoles.map((value) => ({ value, label: value }))}
                hasFilter={false}
                hasTitle={false}
                onSelect={(role) => {
                  if (!_.isEqual(role?.value, values?.jobRole)) {
                    setFieldValue("jobRole", role?.value, false);
                    setFieldValue("specialities", [], false);
                    setFieldValue("skillNames", [], false);

                    submitForm();
                  }
                }}
              >
                <Button iconAfter={CaretDownIcon} type="button">
                  {values?.jobRole || "Select job role"}
                </Button>
              </SelectMenu>
            </Pane>

            {featuresByGrouping[categoryMapping[values.jobRole]]?.length > 0 && (
              <>
                <Pane marginBottom={majorScale(1)}>
                  <Heading>What are your specialities?</Heading>
                  <Paragraph>What are your topic specialities? Choose up to 3</Paragraph>
                </Pane>
                <Pane display="grid" gridTemplateColumns={"repeat(3, 1fr)"} width="100%">
                  {featuresByGrouping[categoryMapping[values.jobRole]]?.map((feature) => {
                    return (
                      <Checkbox
                        key={feature.value}
                        label={feature.label}
                        name="specialities"
                        value={feature.value}
                        disabled={values?.specialities?.length >= 3 && !skillsByRole[feature.value]}
                        marginTop={majorScale(1)}
                        marginBottom={majorScale(2)}
                        checked={skillsByRole[feature.value]}
                        onChange={(e) => {
                          if (skillsByRole[feature.value]) {
                            setSkillsByRoles({ ...skillsByRole, [feature.value]: false });

                            const updated = _.filter(values.specialities, ({ skill }) => !_.isEqual(skill, feature.value));
                            setFieldValue("specialities", updated, false);
                          } else {
                            setSkillsByRoles({ ...skillsByRole, [feature.value]: true });
                            setFieldValue(
                              "specialities",
                              [
                                ...values.specialities,
                                {
                                  jobRole: categoryMapping[values.jobRole],
                                  skill: feature?.value,
                                  proficiency: ""
                                }
                              ],
                              false
                            );
                          }

                          chooseSkills(skillsByRole, setFieldValue);
                          submitForm();
                        }}
                      />
                    );
                  })}
                </Pane>
              </>
            )}

            <Pane marginBottom={majorScale(1)}>
              <Heading>Rank your specialties and add years of experience</Heading>
              <Paragraph>1 = highest proficiency level, 3 = lowest proficiency level</Paragraph>
            </Pane>
            {values?.specialities?.map(({ skill }, index) => (
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                marginBottom={majorScale(1)}
              >
                <Paragraph>{`${index + 1}. ${skill}`}</Paragraph>
                <Pane display="flex" flexDirection="row">
                  {values?.specialities?.length > 1 && (
                    <Pane>
                      {index !== 0 && (
                        <Button
                          type="button"
                          padding={0}
                          appearance="minimal"
                          onClick={() => {
                            onMove("up", values?.specialities, values?.specialities[index], setFieldValue);
                            submitForm();
                          }}
                        >
                          <ArrowUpIcon />
                        </Button>
                      )}
                      {index !== values?.specialities.length - 1 && (
                        <Button
                          type="button"
                          padding={0}
                          appearance="minimal"
                          onClick={() => {
                            onMove("down", values?.specialities, values?.specialities[index], setFieldValue);
                            submitForm();
                          }}
                        >
                          <ArrowDownIcon />
                        </Button>
                      )}
                    </Pane>
                  )}

                  <SelectMenu
                    hasTitle={false}
                    hasFilter={false}
                    options={availableYearsOfExperience.map((value) => ({ value, label: value }))}
                    selected={values?.specialities[index]?.proficiency}
                    onSelect={(item) => {
                      let updated = _.cloneDeep(values.specialities);
                      updated[index].proficiency = item?.value;

                      setFieldValue("specialities", updated, false);
                      submitForm();
                    }}
                  >
                    <Button type="button" iconAfter={CaretDownIcon}>
                      {values?.specialities[index]?.proficiency || "Select years of experience"}
                    </Button>
                  </SelectMenu>
                </Pane>
              </Pane>
            ))}
          </Form>
        );
      }}
    />
  );
};

const CandidateFrameworkForm = (props) => {
  const [updateFrameworks] = useMutation(CandidateQueries.mutations.UpdateFrameworks, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: CandidateQueries.queries.MyCandidateProfile },
      {
        query: ProfileQuery,
        variables: {
          slug: props?.myProfile?.slug
        }
      }
    ],
    onCompleted: () => {
      if (props?.setUpdatedAt) {
        props.setUpdatedAt(new Date());
      }
    }
  });

  const [safeUpdate] = useState(() => _.debounce(updateFrameworks, 250, { maxWait: 1000 }));

  return (
    <Formik
      initialValues={{ frameworks: props?.myCandidateProfile?.frameworks || [] }}
      onSubmit={({ frameworks }, { setSubmitting }) => {
        safeUpdate({
          variables: {
            frameworks
          }
        });

        setSubmitting(false);
      }}
      render={({ values, setFieldValue, submitForm }) => {
        return (
          <Form>
            <Pane marginBottom={majorScale(1)}>
              <Heading>Rank your top 5 frameworks, language and skills</Heading>
              <Paragraph>1 = highest proficiency level, 3 = lowest proficiency level</Paragraph>
            </Pane>
            {values?.frameworks?.map((framework, index) => (
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                marginBottom={majorScale(1)}
              >
                <Pane display="flex" flexDirection="row">
                  {values?.frameworks?.length > 1 && (
                    <Pane>
                      {index !== 0 && (
                        <Button
                          type="button"
                          padding={0}
                          appearance="minimal"
                          onClick={() => {
                            onMove("up", values?.frameworks, values?.frameworks[index], setFieldValue);
                            submitForm();
                          }}
                        >
                          <ArrowUpIcon />
                        </Button>
                      )}
                      {index !== values?.frameworks.length - 1 && (
                        <Button
                          type="button"
                          padding={0}
                          appearance="minimal"
                          onClick={() => {
                            onMove("down", values?.frameworks, values?.frameworks[index], setFieldValue);
                            submitForm();
                          }}
                        >
                          <ArrowDownIcon />
                        </Button>
                      )}
                    </Pane>
                  )}
                  <SelectMenu
                    hasTitle={false}
                    options={props?.availableFrameworks.map((value) => ({ value, label: value }))}
                    selected={values?.frameworks[index]}
                    onSelect={(item) => {
                      let updated = _.cloneDeep(values.frameworks);
                      updated[index] = item?.value;

                      setFieldValue("frameworks", updated, false);
                      submitForm();
                    }}
                  >
                    <Button type="button" iconAfter={CaretDownIcon}>
                      {values?.frameworks[index] || "Select framework"}
                    </Button>
                  </SelectMenu>
                </Pane>
                <IconButton
                  icon={CrossIcon}
                  appearance="minimal"
                  onClick={() => {
                    const updated = _.filter(values.frameworks, (framework, i) => !_.isEqual(i, index));
                    setFieldValue("frameworks", updated, false);
                    submitForm();
                  }}
                />
              </Pane>
            ))}
            {values?.frameworks?.length < 5 && (
              <Pane display="flex" flexDirection="row" width="100%">
                <Button
                  type="button"
                  appearance="minimal"
                  iconBefore={PlusIcon}
                  onClick={() => setFieldValue("frameworks", [...values.frameworks, ""], false)}
                >
                  Add Skill
                </Button>
              </Pane>
            )}
          </Form>
        );
      }}
    />
  );
};

const EditCandidateWorkExperience = (props) => {
  const [updatedAt, setUpdatedAt] = useState(null);
  const { data: profileOptions } = useQuery(CandidateQueries.queries.CandidateProfileOptions);

  const { showWorkExperience, setShowWorkExperience, myCandidateProfile, expertiseCopy, myProfile } = props;

  return (
    <Dialog
      isShown={showWorkExperience}
      title="Skills and Frameworks"
      onCloseComplete={() => setShowWorkExperience(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
      footer={() => (
        <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
          {updatedAt && <Paragraph marginRight={majorScale(1)}>{`Updated at ${format(updatedAt, "HH:mm")}`}</Paragraph>}
          <Button type="button" onClick={() => setShowWorkExperience(false)} appearance="primary">
            Finish updating
          </Button>
        </Pane>
      )}
    >
      <CandidateWorkExperienceForm
        myCandidateProfile={myCandidateProfile}
        myProfile={myProfile}
        availableSkills={profileOptions?.availableSkills}
        availableYearsOfExperience={profileOptions?.availableYearsOfExperience}
        availableJobRoles={profileOptions?.availableJobRoles}
        expertiseCopy={expertiseCopy}
        setUpdatedAt={setUpdatedAt}
      />
      <CandidateFrameworkForm
        myCandidateProfile={myCandidateProfile}
        myProfile={myProfile}
        availableFrameworks={profileOptions?.availableFrameworks}
        setUpdatedAt={setUpdatedAt}
      />
    </Dialog>
  );
};

export default EditCandidateWorkExperience;
