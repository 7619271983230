export const icons = [
  "https://ucarecdn.com/0a233982-88bd-4365-8c4f-f476e50b9f9f/icon1.svg",
  "https://ucarecdn.com/31a5f963-e119-42b1-8cb4-8e694f1b8aea/icon2.svg",
  "https://ucarecdn.com/6aa21a78-4283-4fdd-ad50-a784df3eeac7/icon3.svgg",
  "https://ucarecdn.com/b6e1b027-3d04-4acd-aac5-3922eac442b0/icon4.svg"
];

export const leftBorder = "https://ucarecdn.com/dc054c20-60a4-4165-9161-808dbd09fd40/leftBorder.svg";
export const rightBorder = "https://ucarecdn.com/8affc25f-8755-4d83-afe2-03204638daee/rightBorder.svg";

export const getRecruited = "https://ucarecdn.com/489a17aa-879d-4e7a-a6fc-56bf02383d58/Reviews.png";

export const howItWorks = [
  {
    title: "Access to a qualified and diverse network",
    description: "Our community is majority people of color and women. Search mentors by topic, level, role, or location."
  },
  {
    title: "Easy to ask for and get help",
    description: "We make it easy by automating scheduling, prep work, and following up between sessions."
  },
  {
    title: "Use Merit. Get recruited.",
    description:
      "The more you use Merit, the more we will highlight your profile to recruiters. Mentor reviews and feedbacks can help you get stand out from a stack of resumes and LinkedIn profiles."
  },
  {
    title: "Career advice and interviews, every week.",
    description: `Weekly tips, interviews, and guides from our from leaders in tech.`
  }
];

export const faq = [
  {
    question: "Who can use Merit?",
    answer: `Right now we work best for people on Software Teams - such as Product Management, Product Design, Software Engineering or Management - and located in the United States or Canada.`
  },
  {
    question: "Do you match me with someone long-term? How many mentors can I talk to?",
    answer: `Merit is a network, and the benefit is being able to use the network of mentors and not a single person. We focus on connecting you to relevant and available mentors based on your topics. Some members talk to a single mentor multiple times, others talk to a bunch of mentors at once. You decide what works for you.`
  },
  {
    question: "How many conversations can I schedule?",
    answer: `We do not limit the number of conversations you can have with mentors. However, each mentor sets how many conversations they want per month. If you want to talk to a mentor who is currently busy, we'll suggest available mentors in similar topics.`
  },
  {
    question: "How much does this cost?",
    answer: `Using Merit to connect with senior mentors is free. We make money by charging hiring managers to post jobs within our app and network.`
  }
];

export const recruitingTestimonial = {
  name: `Ninhan`,
  function: `Design`,
  photo: `https://ucarecdn.com/c4d865ac-78b5-4051-b3f5-c5ae82bfc0f8/-/smart_resize/300x300/`,
  quote: `Leslie was a great mentor and designer. She is very friendly and has a lot of knowledge about design. She and I went over my resume. Her suggestions are inspiring to me. I definitely need more feedback and suggestions from her. Thank you, Leslie!`
};

export const scheduling = "https://ucarecdn.com/52f3bc39-772d-41fe-aca1-47c6029c8506/Group1.png";

export const testimonials = [
  {
    _id: "63bc3cd7f0ef7700045a4a0c",
    name: "Marc",
    last: "Luna",
    photo: "https://ucarecdn.com/7c0a8e6b-7c53-4aea-8d36-34321b6350e6/-/smart_resize/300x300/",
    quote:
      "Bryan provided an excellent coaching session. It's always good to get peer review.\n\nHe gave me solid feedback on making my resume more story-driven for senior product-level roles. "
  },
  {
    _id: "63c193cc4034f10004aea193",
    name: "Anika",
    last: "Chowdhury",
    photo: "https://ucarecdn.com/35c414e3-17c0-4da0-8a66-12bfd94969d6/-/smart_resize/300x300/",
    quote:
      "Nastia was amazing and our conversation was insightful. She was very receptive of my questions and answered them in great details. She gave me advice on how I can improve my chances of landing internships. She also got me resume feedback from someone in the field I'm interested in, which shows how she went above and beyond to help. I really enjoyed our session and found it helpful."
  },
  {
    _id: "63d134a43304fd000495911c",
    name: "Danielle",
    last: "B",
    photo: "https://ucarecdn.com/62d621c7-875d-42ee-99fc-fe0f94884d28/-/smart_resize/300x300/",
    quote:
      "Yoko was great as always! She provides really thoughtful answers to my questions and helps me feel more confident in my skills as a designer. Would definitely recommend booking a session with her."
  },
  {
    _id: "63d2aa324d575600042cd9d3",
    name: "Bishoy",
    last: "Gaid",
    photo: "https://ucarecdn.com/8539a568-9e07-46be-8087-c99a8b95f220/-/smart_resize/300x300/",
    quote:
      "Yan is awesome! He really provides me with such great advice and feedback and always brings in experiences he had to share with me. There is always a new takeaway when I meet with Yan which is super powerful and appreciated. "
  },
  {
    _id: "63d43c4ea224b90004dbed90",
    name: "Quan",
    last: "Nguyen",
    photo: "https://ucarecdn.com/f7ec9101-be2c-4b8c-a603-6ac52af949a7/-/smart_resize/300x300/",
    quote:
      "Andrea was amazing! She provided incredibly valuable insights, especially on how I could succeed in my next internship, and advice that will help me in my career. She was knowledgeable, patient, and had a clear understanding of the topics we discussed."
  },
  {
    _id: "63e1520ef7aa840004df6f28",
    name: "Guo",
    last: "Chen",
    photo: "https://ucarecdn.com/f4bd09cd-694c-42d8-82b2-fb238f1bc423/-/smart_resize/300x300/",
    quote:
      "Catt was amazing!! I had questions regarding my design career trajectory as a junior designer, and she provided great insights and recommendations! Our chat made me much more comfortable and confident in my next career move as a junior designer! "
  },
  {
    name: `Sint`,
    function: `Product`,
    photo: `https://ucarecdn.com/108d79db-94a4-4d3f-9198-e640b0e5c24e/-/smart_resize/300x300/`,
    quote: `I love exchanging PM challenges and experiences with Urim. I find the responses from Urim makes me think and analyze my situation better. I also feel very "calm" after our conversation sometimes believe that I still understand product management work and can navigate through situations better over time with practice.`
  },
  {
    name: `Imani`,
    function: `Design`,
    photo: `https://ucarecdn.com/12ca9773-92f9-4fd9-9b4f-b90b52b16ed0/-/smart_resize/300x300/`,
    quote: `The sessions with Kristen were phenomenal! She is very consistent, a great communicator and overall awesome mentor. Every meeting ended with actionable next steps so I always had a direction to go in. It's very clear that she cares about contributing to the design community.`
  },
  {
    name: `Omar`,
    function: `Product`,
    photo: `https://ucarecdn.com/6336172e-db58-4b5e-bf14-10ba7cb05cc3/-/smart_resize/300x300/`,
    quote: `John was awesome! Extremely thoughtful, patient and informative. For all the PMs out there, I can't recommend him enough!.`
  },
  {
    name: `Pearl`,
    function: `Product`,
    photo: `https://ucarecdn.com/f88b6ae0-6be4-46e2-b641-ee07ef830dd8/-/smart_resize/300x300/`,
    quote: `I really enjoyed the conversation with Urim. He was extremely knowledgeable about the product management space, and had specific insight into the questions I had. He was also very supportive and encouraging.`
  },
  {
    name: `Mireille`,
    function: `Engineering`,
    photo: `https://ucarecdn.com/4e1baedf-051d-4a60-bcdf-59e67c12e5a4/-/smart_resize/300x300/`,
    quote: `I have started to refine my professional story to highlight what makes me stand out and experiences that are relevant to the position I am targeting. I really appreciate you sharing your experience and wisdom.`
  },
  {
    name: `Polina`,
    function: `Design`,
    photo: `https://ucarecdn.com/55115e7a-9e92-4a39-b99e-b60cadbc28d6/-/smart_resize/300x300/`,
    quote: `Chris is very attentive and helpful. He gave a lot of valuable suggestions on how to improve my portfolio. Also, he shared useful insights on how to find a job as a Junior Designer. He really helped me along my career pathway!`
  },
  {
    name: `Sambhavi`,
    function: `Design`,
    photo: `https://ucarecdn.com/031a0389-7b2d-4d2c-b07a-e410473b355f/-/smart_resize/300x300/`,
    quote: `It was great speaking to you Will, the practical advice you gave helped me make few concrete decisions and put me on to the required track. Your motivating words made me re-think few decisions as well.`
  },
  {
    name: `Anh`,
    function: `Engineering`,
    photo: `https://ucarecdn.com/fdda8932-1990-41d0-9f55-de680697471b/-/smart_resize/300x300/`,
    quote: `Allison is very open and shares many great insights: transitioning from software to PM or finding your true callings when searching for jobs. They are all extremely relevant to me. Thus, together we revamp my job hunt process and interview preparation.`
  },
  {
    name: `Cybele`,
    function: `Design`,
    photo: `https://ucarecdn.com/1048fb37-2f50-4a1c-a545-f954dbc21703/-/smart_resize/300x300/`,
    quote: `I was very impressed with Kim's professionalism, willingness to work with me .`
  },
  {
    name: `Joseph`,
    function: `Product`,
    photo: `https://ucarecdn.com/e533f263-7672-4443-b3ab-b2f79c512b32/-/smart_resize/300x300/`,
    quote: `I appreciated how straight forward you were in helping me tease through my thinking. It was great to hear how your career progressed from a more technical side of engineer to product. I also appreciated your overview of how different a large company can be in relation to PM/Product roles.`
  }
];

export const bookingImage = `https://ucarecdn.com/0f6aab62-02cf-4b85-9622-a51ea511b427/ScreenShot20210427at160841.png`;

// export const headline = 'Find a mentor'
export const headline = "Level up your tech career";
export const headlineContext =
  "Merit is where tech workers find mentors, get career advice, and connect with the best companies to grow their career. For free.";
export const testimonialsHeadline = `Free mentorship from tech leaders`;
export const testimonialsHeadlineBottom = ``;
export const learnersCTA = "Get started";

export const questionHeader = "Have a specific career question?";
export const questionSubtitle = "We’ll find experienced mentors to help answer your question";
export const sampleQuestion = "How do I become a tech lead?";
export const questionCTA = "Ask question";
