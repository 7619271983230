import _ from "lodash";
import React, { useState } from "react";
import { Button, Dialog, Pane, Text, toaster, majorScale } from "evergreen-ui";
import { useMutation } from "@apollo/client";
import TopicQueries from "../../queries/topic";
import ProfileList from "../../queries/profile/ProfileList";
import EmailSignup from "../../routes/landing/EmailSignup";
import { learnersCTA } from "../../content/learners";

const FOLLOW_TOPIC = "Follow topic";
const UNFOLLOW = "Unfollow topic";

const SIGNUP_HEADER = "New to Merit?";
const SIGNUP_TEXT = "Sign up to talk to senior leaders in product, design & engineering.";

const checkHasTopic = ({ myProfile, topic }) => {
  return myProfile?.topics?.map((aTopic) => aTopic.id).includes(topic.id);
};

const FollowTopic = ({ myProfile, topic }) => {
  const [isShown, setShown] = useState(false);
  const [addRemoveTopic, { loading, error }] = useMutation(TopicQueries.AddRemoveTopic, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: TopicQueries.TopicBySlug, variables: { slug: topic?.slug } }],
    onCompleted: ({ addRemoveTopic: myProfile }) => {
      const copy = checkHasTopic({ myProfile, topic }) ? "Following topic." : "Unfollowed topic";

      toaster.success(copy, { duration: 2 });
    }
  });

  const hasTopic = myProfile ? checkHasTopic({ myProfile, topic }) : null;

  return (
    <>
      <Dialog
        title={myProfile ? FOLLOW_TOPIC : SIGNUP_HEADER}
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        preventBodyScrolling
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
        hasFooter={false}
      >
        <Pane display="flex" flexDirection="row" width="100%" marginBottom={majorScale(3)}>
          <Text>{SIGNUP_TEXT}</Text>
        </Pane>
        <EmailSignup mode="Learn" cta={learnersCTA} showGoogle={true} />
      </Dialog>
      <Button
        onClick={() => {
          if (myProfile) {
            addRemoveTopic({
              variables: {
                topicId: topic?.id
              }
            });
          } else {
            setShown(true);
          }
        }}
      >
        {hasTopic ? UNFOLLOW : FOLLOW_TOPIC}
      </Button>
    </>
  );
};

export default FollowTopic;
