import _ from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Pane,
  Heading,
  Text,
  DocumentOpenIcon,
  FolderCloseIcon,
  TickIcon,
  PersonIcon,
  ShareIcon,
  CrossIcon,
  BriefcaseIcon,
  minorScale,
  majorScale,
  defaultTheme as theme,
  toaster,
  Paragraph
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { formatDistanceToNow, isValid } from "date-fns";

import { JobPreference, ContractTypes, LocationPreference } from "../../routes/profile/components/CandidateJobSearch";

import CandidateRequest from "./CandidateRequest";
import CandidateQueries from "../../queries/candidate";

const formatDate = (createdAt) => {
  // https://date-fns.org/v2.27.0/docs/isValid
  createdAt = isValid(new Date(createdAt)) ? createdAt : Number(createdAt);

  // https://date-fns.org/v2.27.0/docs/formatDistanceToNow
  return `${formatDistanceToNow(new Date(createdAt))} ago`;
};

const RequestOptions = ({ request, onUpdate }) => {
  const [oldStatus, setOldStatus] = useState(request?.status);
  const [respondToRequest, { loading }] = useMutation(CandidateQueries.mutations.RespondToCandidateRequest, {
    onCompleted: () => {
      const statusMap = {
        Accepted: "accepted",
        Rejected: "rejected",
        Archived: "archived",
        Active: "active"
      };

      if (_.isFunction(onUpdate)) {
        onUpdate({ status: statusMap[oldStatus] });
        setOldStatus(request?.status);
      }
    },
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateRequests }],
    awaitRefetchQueries: true
  });

  return (
    <Pane display="flex" flexDirection="row" justifyContent={request?.status === "Active" ? "space-between" : "flex-end"}>
      {request?.status === "Active" && (
        <>
          <Button
            isLoading={loading}
            iconBefore={CrossIcon}
            onClick={() => {
              toaster.success("Ignored request.", { duration: 2 });
              respondToRequest({
                variables: {
                  id: request?.id,
                  status: "Rejected"
                }
              });
            }}
          >
            Ignore request
          </Button>
          <Button
            isLoading={loading}
            appearance="primary"
            iconBefore={TickIcon}
            onClick={() => {
              toaster.success("We connected you over email! Check your inbox.", { duration: 2 });
              respondToRequest({
                variables: {
                  id: request?.id,
                  status: "Accepted"
                }
              });
            }}
          >
            Accept request
          </Button>
        </>
      )}
      {request?.status === "Accepted" && (
        <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <Paragraph marginRight={majorScale(1)}>{`Sent ${formatDate(request?.createdAt)}`}</Paragraph>
          <Button
            isLoading={loading}
            appearance="primary"
            iconBefore={FolderCloseIcon}
            isDisabled={true}
            onClick={() =>
              respondToRequest({
                variables: {
                  id: request?.id,
                  status: "Archived"
                }
              })
            }
          >
            Archive request
          </Button>
        </Pane>
      )}
      {request?.status === "Archived" && (
        <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <Paragraph marginRight={majorScale(1)}>{`Sent ${formatDate(request?.createdAt)}`}</Paragraph>
          <Button
            isLoading={loading}
            appearance="primary"
            iconBefore={CrossIcon}
            isDisabled={true}
            onClick={() =>
              respondToRequest({
                variables: {
                  id: request?.id,
                  status: "Accepted"
                }
              })
            }
          >
            Reactivate
          </Button>
        </Pane>
      )}
    </Pane>
  );
};

const CandidateRequestCard = ({ myCandidateProfile, candidateRequest, recruiter, onUpdate, borderless }) => {
  const user = recruiter?.user;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      border={borderless ? null : `1px solid ${theme.colors.gray500}`}
      borderRadius={borderless ? null : majorScale(2)}
      padding={borderless ? null : majorScale(2)}
      marginBottom={borderless ? null : majorScale(2)}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(2)}>
        <Pane display="flex" flexDirection="row">
          <Avatar name={user?.fullName} src={user?.picture} size={majorScale(8)} marginRight={majorScale(2)} />
          <Pane display="flex" flexDirection="column" justifyContent="center">
            <Pane display="flex" flexDirection="row">
              <Heading>{user?.fullName}</Heading>
              {user?.pronouns?.length > 0 && <Heading marginLeft={minorScale(1)}>({user?.pronouns})</Heading>}
            </Pane>
            {/* <Pane>
              <Text>
                {overview({ ...user, ...candidate })}
              </Text>
            </Pane> */}
          </Pane>
        </Pane>
        <Pane>
          {candidateRequest?.jobListing?.url && (
            <Button appearance="minimal" iconBefore={BriefcaseIcon} is="a" href={candidateRequest?.jobListing?.url} target="_blank">
              View role
            </Button>
          )}
        </Pane>
      </Pane>
      <Pane marginBottom={majorScale(2)}>
        <Heading size={400} marginBottom={majorScale(1)}>
          {`${recruiter?.user?.name?.first}'s message`}
        </Heading>
        <Paragraph>{candidateRequest?.message}</Paragraph>
      </Pane>
      <Pane>
        <RequestOptions request={candidateRequest} onUpdate={onUpdate} />
      </Pane>
    </Pane>
  );
};

export default CandidateRequestCard;
