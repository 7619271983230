import React, { useEffect } from "react";
import { Link as BrowserLink } from "react-router-dom";
import { ScreenClassRender, Col, Hidden } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Alert, Button, Heading, Pane, Text, majorScale, toaster, defaultTheme as theme } from "evergreen-ui";

import ProfileList from "../../../queries/profile/ProfileList";
import ApplicationQueries from "../../../queries/application";
import { GoodSession } from "../../../components/session/tutorial/LeaderTutorialSteps";
import BackButton from "../../../components/BackButton";

const nextScreen = (currentStep) => {
  return currentStep + 1 < GoodSession.length ? `/guide/mentor/${currentStep + 1}` : `/profile/edit`;
};

const NextStep = withRouter(({ history, myCoachingProfile, currentStep }) => {
  const isLastStep = currentStep + 1 === GoodSession.length;
  const [readBestPractices] = useMutation(ApplicationQueries.mutations.ReadBestPractices, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      toaster.success("Finished reading.", { duration: 2 });
      history.push(nextScreen(currentStep));
    }
  });

  return isLastStep ? (
    <Button appearance="primary" onClick={() => readBestPractices()}>
      Finish Reading
    </Button>
  ) : (
    <Button appearance="primary" is={BrowserLink} to={nextScreen(currentStep)}>
      Next Step
    </Button>
  );
});

const LeaderTutorialPage = ({ history, match }) => {
  const { data } = useQuery(ProfileList.MyCoachingProfile);
  const currentStep = match?.params?.step ? parseInt(match?.params?.step) : 0;

  useEffect(() => {
    window.analytics.track("View Leader Tutorial");
    window.analytics.track("View Mentor Tutorial");
  });

  let nextStep;

  if (data) {
    const { myCoachingProfile } = data;

    nextStep = (
      <Pane display="flex" flexDirection="row-reverse" paddingY={majorScale(4)}>
        <NextStep currentStep={currentStep} myCoachingProfile={myCoachingProfile} />
      </Pane>
    );
  }

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <div
          style={{
            display: "flex",
            flexDirection: screenClass?.includes("xs") ? "column" : "row",
            width: "100%"
          }}
        >
          <Hidden xs>
            <Col sm={2} md={2} lg={2} xs={12}>
              <BackButton />
            </Col>
          </Hidden>
          <Col lg={8} md={8} sm={8} xs={12}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              marginTop={majorScale(4)}
              marginBottom={majorScale(1)}
            >
              <Heading size={700} marginRight={majorScale(1)}>
                ✨ Mentor Best Practices
              </Heading>
              <Text size={600} paddingRight={majorScale(1)} paddingTop={majorScale(1)}>
                {`${currentStep + 1} of ${GoodSession.length}`}
              </Text>
            </Pane>

            <Pane
              display="flex"
              flexDirection="column"
              width="100%"
              padding={majorScale(4)}
              borderRadius={majorScale(2)}
              border={`1px solid ${theme.colors.gray500}`}
              backgroundColor="white"
              minHeight="100px"
            >
              {GoodSession[currentStep]}
            </Pane>

            {nextStep}
          </Col>
          <Hidden xs>
            <Col md={2} sm={1} xs={12}>
              <Pane
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="start"
                paddingTop={majorScale(4)}
                paddingX={majorScale(2)}
              >
                <Alert appearance="card" intent="none" title="Booked a session and looking for how to prepare?" marginBottom={32}>
                  <Text>Learn how to get the most of these career conversations.</Text>
                  <Pane display="flex" flexDirection="row-reverse">
                    <Button is={BrowserLink} appearance="minimal" marginTop={majorScale(2)} to={"/guide/member"}>
                      Learn more
                    </Button>
                  </Pane>
                </Alert>
              </Pane>
            </Col>
          </Hidden>
        </div>
      )}
    />
  );
};

export default LeaderTutorialPage;
