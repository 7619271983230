import React from "react";
import injectSheet from "react-jss";
import { Row, Col } from "react-grid-system";
import ReactMarkdown from "react-markdown";

const styles = {
  detail: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  detailText: {
    font: {
      family: "SFProText-Regular",
      size: 16,
      color: "#212529",
      weight: 500
    }
  },
  headline: {
    textAlign: "left",
    letterSpacing: "1.05px",
    font: {
      family: "Open Sans, Bold",
      size: 32,
      color: "#212529",
      letterSpacing: 0.1,
      weight: 500
    },
    padding: "16px 0 16px 0",
    marginTop: 0,
    marginBottom: 8
  },
  slim: {
    padding: "8px 0 8px 0",
    marginBottom: 2,
    textAlign: "center"
  },
  root: {
    paddingBottom: "6rem"
  },
  sectionShot: {
    width: "90%",
    margin: "auto"
  },
  subtitle: {
    textAlign: "center",
    font: {
      family: "SFProText-Regular",
      size: 16,
      color: "#212529",
      weight: 400
    }
  }
};

const Section = ({ classes, headline, sectionShot, detail, children }) => {
  const sectionDetail = detail ? (
    <ReactMarkdown escapeHtml={false} className={classes.detailText}>
      {detail}
    </ReactMarkdown>
  ) : (
    children
  );

  if (!sectionShot) {
    return (
      <Row className={classes.root}>
        <Col offset={{ xs: 1, sm: 2, md: 3 }} xs={10} sm={8} md={6} className={classes.detail}>
          <h3 className={`${classes.headline} ${classes.slim}`}>{headline}</h3>
          {sectionDetail}
        </Col>
      </Row>
    );
  } else
    return (
      <Row className={classes.root}>
        <Col offset={{ md: 2 }} md={4} className={classes.detail}>
          <h3 className={classes.headline}>{headline}</h3>
          {sectionDetail}
        </Col>
        <Col md={4}>
          <img className={classes.sectionShot} src={sectionShot} />
        </Col>
      </Row>
    );
};

export default injectSheet(styles)(Section);
