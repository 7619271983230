import _ from "lodash";
import React from "react";
import { Button, Tooltip, majorScale } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import FeedQueries from "../../queries/feed";

const REACTION_CTA = "Did you find this useful?";

// Takes a series of reaction responses and renders your view of it
const Reaction = ({ comment, post, myProfile, responses }) => {
  const myReaction = _.find(responses.edges, ["owner.id", myProfile.id]);

  const [addReaction, { data: added }] = useMutation(FeedQueries.mutations.AddReaction, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.PostById, variables: { id: post?.id } },
      { query: FeedQueries.queries.FeedQuery },
      { query: FeedQueries.queries.Comments, variables: { postId: post?.id } }
    ]
  });

  const [removeReaction, { data: removed }] = useMutation(FeedQueries.mutations.RemoveReaction, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.PostById, variables: { id: post.id } },
      { query: FeedQueries.queries.FeedQuery },
      { query: FeedQueries.queries.Comments, variables: { postId: post?.id } }
    ]
  });

  // Assume this is a comment if there's a comment and id, otherwise it's a post
  const obj = comment && comment.id ? { commentId: comment.id } : { postId: post?.id };
  const copy = responses?.edges?.length ? `👍 ${responses.edges.length}` : `👍`;

  return (
    <Tooltip content={REACTION_CTA}>
      <Button
        key={`Reaction-${comment?.id ? comment.id : post?.id}`}
        marginRight={majorScale(1)}
        paddingLeft={0}
        paddingRight={0}
        intent={"none"}
        appearance={myReaction ? "default" : "minimal"}
        background={myReaction ? "#F3F6FF" : null}
        onClick={() => {
          if (myReaction) {
            removeReaction({
              variables: {
                id: myReaction.id
              }
            });
          } else {
            addReaction({
              variables: {
                reaction: `👍`,
                ...obj
              }
            });
          }
        }}
      >
        {copy}
      </Button>
    </Tooltip>
  );
};

export default Reaction;
