import React from "react";
import { Link as BrowserLink } from "react-router-dom";

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to BrowserLink
const Link = ({ children, to, activeClassName, ...other }) => {
  // This example assumes that any internal link (intended for ReactRouter)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use RouterLink for internal links, and <a> for others
  return internal ? (
    <BrowserLink to={to} {...other}>
      {children}
    </BrowserLink>
  ) : (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

export default Link;
