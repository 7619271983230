import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Avatar, Button, EditIcon, Pane, Popover, Text, majorScale, minorScale } from "evergreen-ui";

import ProfileList from "../../../queries/profile/ProfileList";
import Uploader from "../../../components/Uploader";

const PhotoPicker = ({ disableClose, setDisableClose, setPicture, onUpdate }) => {
  const [updatePhoto] = useMutation(ProfileList.UpdateProfilePhoto, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }, { query: ProfileList.MyProfileQuery }],
    onCompleted: ({ updateProfile }) => setPicture(updateProfile?.picture)
  });

  return (
    <Popover
      shouldCloseOnExternalClick={disableClose}
      content={
        <Pane paddingY={majorScale(4)} paddingX={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
          <Uploader
            tab="file"
            onDialogLoad={() => setDisableClose(false)}
            onUploadComplete={(file) => {
              window.analytics.track("Update Profile Photo");
              setPicture(file?.cdnUrl);
              updatePhoto({ variables: { picture: file?.cdnUrl } });
              setDisableClose(true);
            }}
          />
          <Text fontSize="12px" marginTop={minorScale(3)}>
            A clear, square, headshot wider than 500 pixels works best.
          </Text>
        </Pane>
      }
    >
      <Button iconBefore={EditIcon} appearance="minimal">
        Edit photo
      </Button>
    </Popover>
  );
};

const EditProfilePhoto = ({ myProfile, isSmallScreen }) => {
  const [picture, setPicture] = useState(myProfile?.picture);
  const [disableClose, setDisableClose] = useState(true);

  return (
    <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="baseline">
      <Avatar src={picture} name={myProfile?.fullName} size={isSmallScreen ? 140 : 175} marginTop="-80px" border="6px solid white" />
      <PhotoPicker setPicture={setPicture} disableClose={disableClose} setDisableClose={setDisableClose} />
    </Pane>
  );
};

export default EditProfilePhoto;
