import _ from "lodash";
import React from "react";
import ReactMarkdown from "react-markdown";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  EditIcon,
  Heading,
  Icon,
  IconButton,
  Link,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  Paragraph,
  Strong,
  Text,
  TrashIcon,
  UnorderedList,
  ListItem,
  Badge,
  Small,
  majorScale,
  toaster,
  Pill,
  OrderedList
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { Mutation } from "@apollo/client";
import Moment from "moment";
import { ScreenClassRender } from "react-grid-system";

import FeedQueries from "../../queries/feed";
import PostDelete from "./PostDelete";
import ProfilePreview from "../feed/ProfilePreview";
import Comment from "../comment/Comment";
import CommentIndex from "../comment/CommentIndex";
import CreateComment from "../comment/CreateComment";
import ReactionIndex from "../reaction/ReactionIndex";

class PostCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDelete: false,
      showEdit: false,
      titleHover: false,
      profileHover: false
    };
  }

  deletePost({ postId }) {
    return (
      <Menu.Item intent="danger" icon={TrashIcon} onClick={() => this.setState({ showDelete: true })}>
        {this.state.showDelete ? <PostDelete postId={postId} isShown={true} /> : null}
        Delete
      </Menu.Item>
    );
  }

  editPost({ postId }) {
    return (
      <Link is={BrowserLink} to={`/post/edit/${postId}`}>
        <Menu.Item icon={EditIcon}>Edit</Menu.Item>
      </Link>
    );
  }

  canEdit({ myProfile, owner }) {
    if (myProfile.isAdmin) return true;
    else if (_.isEqual(myProfile.id, owner.id)) return true;
    else return false;
  }

  renderPostOptions({ myProfile, owner, postId }) {
    if (!myProfile || !owner) return null;
    if (!this.canEdit({ myProfile, owner })) return null;

    const menu = (
      <Menu>
        <Menu.Group>{this.editPost({ postId })}</Menu.Group>
        <Menu.Group>{this.deletePost({ postId })}</Menu.Group>
      </Menu>
    );

    return (
      <Pane>
        <Popover position={Position.BOTTOM_LEFT} content={menu}>
          <IconButton icon={MoreIcon} appearance="minimal" />
        </Popover>
      </Pane>
    );
  }

  renderTopic(topic) {
    return (
      topic?.id && (
        <Link to={`/leaders/all?cursor=1&topics=${topic.id}`} is={BrowserLink} style={{ textDecoration: "none" }}>
          <Badge isInteractive>{topic.name}</Badge>
        </Link>
      )
    );
  }

  renderPhoto({ photo }) {
    if (!photo || _.isEmpty(photo)) return null;
    else
      return (
        <Pane display="flex" flexDirection="column" justifyContent="center" width="100%">
          <img src={photo} width="100%" height={majorScale(48)} style={{ objectFit: "contain" }} />
        </Pane>
      );
  }

  renderComments({ postId, myProfile }) {
    return (
      <Pane display="flex" flexDirection="column" justifyContent="center" width="100%">
        <CommentIndex postId={postId} myProfile={myProfile} />
        <CreateComment myProfile={myProfile} postId={postId} />
      </Pane>
    );
  }

  renderReactions({ post, myProfile }) {
    return (
      <Pane display="flex" flexDirection="column" justifyContent="center">
        <ReactionIndex post={post} myProfile={myProfile} />
      </Pane>
    );
  }

  renderPostDetails({ title, details, photo, link, noLink, owner, topic, myProfile, id, createdAt }) {
    const postDate = new Moment(createdAt).format("MMM Do YYYY");

    return (
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
        <Pane display="flex">
          <Avatar src={owner.picture} size={majorScale(5)} marginRight={majorScale(1)} />
          <Pane display="flex" flexDirection="column" justifyContent="flex-start">
            <Link
              is={BrowserLink}
              to={`/p/${owner.slug}`}
              onMouseEnter={() => this.setState({ profileHover: !this.state.profileHover })}
              onMouseLeave={() => this.setState({ profileHover: !this.state.profileHover })}
              textDecoration={this.state.profileHover ? "underline" : "none"}
              target="_blank"
              onClick={() => window.analytics.track("Post -> View Profile")}
            >
              <Pane>
                <Strong size={400}>{owner.fullName}</Strong>
                {!_.isNil(owner.coach) && (
                  <Pill marginLeft={majorScale(1)} color="blue">
                    Mentor
                  </Pill>
                )}
              </Pane>
            </Link>
            <Paragraph size={400}>{postDate}</Paragraph>
          </Pane>
        </Pane>
        <Pane>{this.renderPostOptions({ myProfile, owner, postId: id })}</Pane>
      </Pane>
    );
  }

  renderCard({ link, noLink }) {
    const { myProfile, post } = this.props;
    if (!post) return null;

    const { id, title, details, owner, createdAt, topic, photo, mostRecentComment } = post;

    const questionCard = (
      <ScreenClassRender
        render={(screenClass) => (
          <Card
            display="flex"
            elevation={2}
            border={1}
            margin={["xs"].includes(screenClass) ? null : majorScale(2)}
            padding={majorScale(3)}
            alignItems="start"
            justifyContent="space-between"
            flexDirection="column"
            width="100%"
          >
            {this.renderPostDetails({ title, details, photo, link, noLink, owner, myProfile, id, topic, createdAt })}
            <Link
              is={BrowserLink}
              to={`/post/${id}`}
              onMouseEnter={() => this.setState({ titleHover: !this.state.titleHover })}
              onMouseLeave={() => this.setState({ titleHover: !this.state.titleHover })}
              textDecoration={this.state.titleHover ? "underline" : "none"}
              target="_blank"
            >
              <Heading marginBottom={majorScale(1)} marginTop={majorScale(2)}>
                {title}
              </Heading>
            </Link>
            <Pane marginBottom={majorScale(2)}>
              <ReactMarkdown
                components={{
                  p: Paragraph,
                  ul: UnorderedList,
                  li: ListItem,
                  ol: OrderedList
                }}
              >
                {details}
              </ReactMarkdown>
            </Pane>
            {this.renderPhoto({ photo })}
            <Pane width={"100%"} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              {this.renderTopic(topic)}
              {post && post.id ? this.renderReactions({ post, myProfile }) : null}
            </Pane>

            {post && post.id ? this.renderComments({ postId: id, myProfile }) : null}
          </Card>
        )}
      ></ScreenClassRender>
    );

    return questionCard;
  }

  render() {
    const { noLink, link, post } = this.props;
    const defaultLink = `/post/${post.id}`;

    if (!post.id || !post.owner) return null;

    return this.renderCard({
      noLink,
      link: link ? link : defaultLink
    });
  }
}

export default PostCard;
