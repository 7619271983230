import _ from "lodash";
import React from "react";
import {
  AnnotationIcon,
  Button,
  CrossIcon,
  DeleteIcon,
  EditIcon,
  UnresolveIcon,
  DisableIcon,
  Menu,
  Pane,
  Popover,
  RefreshIcon,
  StarIcon,
  majorScale,
  toaster,
  WarningSignIcon
} from "evergreen-ui";
import { useMutation } from "@apollo/client";
import { ScreenClassRender } from "react-grid-system";
import { Link as BrowserLink } from "react-router-dom";

import SessionQueries from "../../queries/session";

const PreviousActions = ({ session, direction, onCompleted, hideFeedback, isCol, connectionBySessionId }) => {
  const [updateSession] = useMutation(SessionQueries.mutations.SkipReview, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction }
      }
    ],
    onCompleted: (args) => {
      toaster.success("Session updated.");
      if (onCompleted && _.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });

  const options = [
    {
      icon: RefreshIcon,
      title: "Was rescheduled",
      reason: "Canceled"
    },
    {
      icon: DeleteIcon,
      title: "Was canceled",
      reason: "Canceled"
    },
    {
      icon: UnresolveIcon,
      title: "They didn't show up",
      reason: "No Show"
    }
  ];

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = isCol || (screenClass.includes("xs") ? true : false);
        const sessionId = session?.id ? session.id : session;
        // {options.map(({ icon, title, reason }) => {
        //   if (reason.includes("No Show")) {
        //     return (
        //       <Menu.Item
        //         key={`option-${reason}`}
        //         icon={icon}
        //         is={BrowserLink}
        //         to={`/session/${sessionId}/no-show`}>
        //         {title}
        //       </Menu.Item>
        //     )
        //   } else {
        //     return (
        //       <Menu.Item
        //         key={`option-${reason}`}
        //         icon={icon}
        //         onClick={() =>
        //           updateSession({
        //             variables: { reason, sessionID: session?.id }
        //           })
        //         }>
        //         {title}
        //       </Menu.Item>
        //     )
        //   }
        // })}
        return (
          <Pane
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            flexWrap="wrap"
            width={hideFeedback ? "" : "100%"}
            marginTop={hideFeedback ? null : majorScale(3)}
          >
            <Pane display="flex" flexDirection={isMobile ? "column" : "row"}>
              <Popover
                content={
                  <Menu>
                    <Menu.Group title={"Didn't Happen"}>
                      <Menu.Item
                        key={`option-${options[2].reason}`}
                        icon={options[1].icon}
                        onClick={() =>
                          updateSession({
                            variables: {
                              sessionID: sessionId,
                              reason: "CANCELED"
                            }
                          })
                        }
                      >
                        {options[1].title}
                      </Menu.Item>
                      <Menu.Item
                        key={`option-${options[2].reason}`}
                        icon={options[2].icon}
                        is={BrowserLink}
                        to={`/session/${sessionId}/no-show`}
                      >
                        {options[2].title}
                      </Menu.Item>
                    </Menu.Group>
                    <Menu.Divider />
                    <Menu.Group title={"Something else?"}>
                      <Menu.Item icon={CrossIcon} intent="danger" is={"a"} href="mailto:support@get-merit.com">
                        Contact support
                      </Menu.Item>
                    </Menu.Group>
                  </Menu>
                }
              >
                <Button marginBottom={isMobile ? majorScale(1) : null} appearance="minimal" iconBefore={DisableIcon}>
                  Didn't happen
                </Button>
              </Popover>
              <Button
                marginBottom={isMobile ? majorScale(1) : null}
                appearance={"secondary"}
                is={BrowserLink}
                to={`/session/${session?.id}/report`}
                iconBefore={WarningSignIcon}
              >
                {session?.report?.id ? "Edit report" : "Report issue"}
              </Button>
              {connectionBySessionId?.id && (
                <Button
                  is={BrowserLink}
                  to={`/recommend/${connectionBySessionId?.id}`}
                  iconBefore={AnnotationIcon}
                  marginLeft={majorScale(1)}
                  appearance={connectionBySessionId?.recommendation?.id ? "default" : "primary"}
                >
                  {connectionBySessionId?.recommendation?.id ? "Edit recommendation" : "Recommend"}
                </Button>
              )}
            </Pane>
            {!hideFeedback && (
              <Pane display="flex" flexDirection={isMobile ? "column" : "row"}>
                <Button
                  marginBottom={isMobile ? majorScale(1) : null}
                  appearance={"primary"}
                  is={BrowserLink}
                  to={`/session/${session?.id}/follow-up`}
                  iconBefore={EditIcon}
                >
                  {"Follow up"}
                </Button>
              </Pane>
            )}
          </Pane>
        );
      }}
    />
  );
};

export default PreviousActions;
