import _ from "lodash";
import React from "react";
import ShowMore from "@tedconf/react-show-more";
import { Button, Pane, majorScale } from "evergreen-ui";
import { useQuery, Query } from "@apollo/client";

import FeedQueries from "../../queries/feed";
import CommentContainer from "./CommentContainer";

const CommentIndex = ({ postId, myProfile, onDelete }) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(FeedQueries.queries.Comments, { variables: { postId } });

  let comments, next;

  if (loading || error) return null;
  const pageSize = 3;

  if (data) {
    comments = data.comments;

    next = () =>
      fetchMore({
        query: FeedQueries.queries.Comments,
        variables: {
          postId,
          cursor: comments.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            comments: {
              __typename: fetchMoreResult.comments.__typename,
              pageInfo: fetchMoreResult.comments.pageInfo,
              edges: [...prev.comments.edges, ...fetchMoreResult.comments.edges]
            }
          });
        }
      });
  }

  return _.isNil(comments) || _.isEmpty(comments) ? null : (
    <Pane>
      <ShowMore items={comments.edges} by={pageSize}>
        {({ current, onMore }) => (
          <React.Fragment>
            <Pane>
              {comments.edges.map((comment) => (
                <CommentContainer comment={comment} myProfile={myProfile} postId={postId} onDelete={refetch} />
              ))}
            </Pane>
            <Button display={comments.pageInfo.hasNextPage ? "default" : "none"} appearance="minimal" onClick={next}>
              More responses
            </Button>
          </React.Fragment>
        )}
      </ShowMore>
    </Pane>
  );
};

export default CommentIndex;
