import React from "react";
import { Heading, Pane, majorScale } from "evergreen-ui";
import { withRouter } from "react-router-dom";

import HoverLink from "../HoverLink";
import DiscussionItem from "./DiscussionItem";

const AnswerCard = withRouter(({ history, comment, myProfile }) => {
  return (
    <Pane>
      {comment?.post?.title.length > 0 && (
        <HoverLink samePage to={`/d/${comment?.post?.id}`}>
          <Heading marginBottom={majorScale(2)}>{comment.post.title}</Heading>
        </HoverLink>
      )}
      <DiscussionItem
        myProfile={myProfile}
        comment={comment}
        post={comment?.post}
        history={history}
        hideReplies
        hideResponses
        long={true}
      />
    </Pane>
  );
});

export default AnswerCard;
