import _ from "lodash";
import React from "react";
import queryString from "query-string";
import { Col, Row, Container, Hidden } from "react-grid-system";
import { Query, useQuery } from "@apollo/client";
import { Alert, Pane, majorScale } from "evergreen-ui";

import FeedQueries from "../../queries/feed";
import PostCard from "../../components/post/PostCard";
import BackButton from "../../components/button/BackButton";

const POST_NOT_FOUND = "Could not find this post.";

const leftPanel = ({ history }) => {
  return history?.length > 2 ? (
    <Pane display="flex" alignItems="start" flexDirection="column" padding={majorScale(2)}>
      <BackButton marginBottom={majorScale(2)} onClick={() => history.goBack()} />
    </Pane>
  ) : null;
};

const PostDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  let post, myProfile;

  const { data: postDetail, loading, error } = useQuery(FeedQueries.queries.PostById, { variables: { id } });

  if (error) {
    return <Alert title={POST_NOT_FOUND} />;
  }

  if (postDetail?.postById) {
    post = postDetail.postById;
    myProfile = postDetail?.myProfile;
  }

  return (
    <Container>
      <Row align="start" justify="start">
        <Hidden xs>
          <Col sm={3}>{leftPanel({ history })}</Col>
        </Hidden>
        <Col xs={12} sm={9} md={6}>
          {post?.id && <PostCard post={post} noLink={true} myProfile={myProfile} key={`PostDetail-PostCard-${post.id}`} />}
        </Col>
      </Row>
    </Container>
  );
};

export default PostDetail;
