import _ from "lodash";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Pane, majorScale, toaster, IconButton, AddIcon } from "evergreen-ui";

import ProfileList from "../../queries/profile/ProfileList";
import OpeningHours from "./OpeningHours";

const HOURS_SUCCESS = "Updated your opening hours.";

const defaultOpeningHours = [
  {
    days: ["F"],
    start: "09:00",
    end: "13:00"
  }
];

const OpeningHoursForm = ({ myCoachingProfile }) => {
  let [openingHours, setOpeningHours] = useState(null);
  const [updateHours, { data }] = useMutation(ProfileList.UpdateHours, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => toaster.success(HOURS_SUCCESS, { duration: 2 })
  });

  if (_.isNil(myCoachingProfile)) return null;
  if (_.isNil(openingHours)) {
    const initial =
      myCoachingProfile.openingHours && !_.isEmpty(myCoachingProfile.openingHours)
        ? myCoachingProfile.openingHours
        : _.cloneDeep(defaultOpeningHours);

    setOpeningHours(initial);
  }

  const mutateHours = (updated) =>
    updateHours({
      variables: {
        openingHours: updated.map(({ days, start, end }) => ({ days, start, end }))
      }
    });

  const removeHours = (index) => {
    const fn = () => {
      openingHours.splice(index, 1);

      setOpeningHours(_.cloneDeep(openingHours));
      mutateHours(_.cloneDeep(openingHours));
    };

    return fn;
  };

  return (
    <Pane display="flex" flexDirection="column" justifyContent="space-between">
      {openingHours &&
        openingHours.map((oph, index) => (
          <OpeningHours
            key={`OpeningHoursForm-${index}`}
            openingHours={oph}
            onRemove={index && index > 0 ? removeHours(index) : null}
            onUpdate={({ days, start, end }) => {
              const updated = _.cloneDeep(openingHours);
              updated[index] = { days, start, end };

              setOpeningHours(updated);
              mutateHours(updated);
            }}
          />
        ))}

      <Pane display="flex" justifyContent="flex-end" width="100%">
        <Button
          iconBefore={AddIcon}
          intent="success"
          appearance="minimal"
          onClick={() => {
            const updated = [..._.cloneDeep(openingHours), ..._.cloneDeep(defaultOpeningHours)];

            setOpeningHours(updated);
            mutateHours(updated);
          }}
        >
          Add slots
        </Button>
      </Pane>
    </Pane>
  );
};

export default OpeningHoursForm;
