import gql from "graphql-tag";

import QueryUtils from "../utils";
import ProfileList from "../profile/ProfileList";

const TeamQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

TeamQueries.fragments.team = `
  id,
  name,
  logo,
  website,
  description
`;

TeamQueries.fragments.job = `
  id,
  status,
  title,
  role,
  description,
  location,
  level,
`;

TeamQueries.fragments.jobApplication = `
  id,
  user {
    ${ProfileList.fragments.profile}
  },
  job {
    ${TeamQueries.fragments.job}
  },
  createdAt,
  updatedAt,
`;

TeamQueries.fragments.teamExtended = `
  id,
  name,
  description,
  logo,
  cover,
  website,
  twitter,
  linkedin,
  blog,
  teamSize,
  funding,
  businessModel,
  benefits,
  slug,
  industry {
    id,
    name,
    keyword
  },
  posts {
    id,
    header,
    subheader,
    content,
    photo
  }
`;

TeamQueries.queries.TeamDetailQuery = gql`
  query TeamDetailQuery($teamID: String!) {
    teamById(teamID: $teamID) {
      ${TeamQueries.fragments.teamExtended},
      coaches {
        ${ProfileList.fragments.coach},
        user {
          ${ProfileList.fragments.profile}
        }
      }
    }
  }
`;

TeamQueries.queries.TeamListQuery = gql`
  query TeamListQuery($industries: [String!]) {
    industries {
      id,
      name,
      keyword
    },
    teams(industries: $industries) {
      edges {
        ${TeamQueries.fragments.team}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

TeamQueries.queries.MoreTeamsQuery = gql`
  query MoreTeamsQuery($cursor: String) {
    teams(cursor: $cursor) {
      __typename,
      edges {
        ${TeamQueries.fragments.team}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

TeamQueries.queries.JobDetailQuery = gql`
  query JobDetailQuery($jobId: String!) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    jobById(jobId: $jobId) {
      ${TeamQueries.fragments.job},
      link,
      owner {
        ${ProfileList.fragments.profile},
        coach {
          ${ProfileList.fragments.coach}
        }
      },
      team {
        ${TeamQueries.fragments.team}
      }
    }
  }
`;

TeamQueries.queries.JobListQuery = gql`
  query JobListQuery($roles: [String!], $levels: [String!], $industries: [String!]) {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    myCoachingProfile {
      ${ProfileList.fragments.coach}
    },
    roles,
    industries {
      id,
      name,
      keyword
    },
    jobs(roles: $roles, levels: $levels, industries: $industries) {
      edges {
        ${TeamQueries.fragments.job},
        team {
          ${TeamQueries.fragments.team}
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

TeamQueries.queries.TeamJobListQuery = gql`
  query TeamJobListQuery($teamId: String!) {
    jobsByTeam(teamId: $teamId) {
      edges {
        ${TeamQueries.fragments.job},
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

TeamQueries.queries.MoreJobsQuery = gql`
  query MoreJobsQuery($roles: [String!], $levels: [String!], $industries: [String!], $cursor: String) {
    jobs(roles: $roles, levels: $levels, industries: $industries, cursor: $cursor) {
      __typename,
      edges {
        ${TeamQueries.fragments.job},
        team {
          ${TeamQueries.fragments.team}
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

TeamQueries.queries.MyOpenJobs = gql`
  query MyOpenJobs($profileId: String!) {
    myOpenJobs(profileId: $profileId) {
      ${TeamQueries.fragments.job},
      team {
        ${TeamQueries.fragments.team}
      }
    }
  }
`;

TeamQueries.mutations.ApplyToJob = gql`
  mutation ApplyToJob($jobId: String!) {
    applyToJob(jobId: $jobId) {
      ${TeamQueries.fragments.jobApplication}
    }
  }
`;

export default TeamQueries;
