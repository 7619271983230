import React from "react";
import injectSheet from "react-jss";
import _ from "lodash";

import theme from "../theme";

import { Pane } from "evergreen-ui";

import { Row, Col, Hidden, Visible } from "react-grid-system";

import { computer, lightBulb, balloon } from "../../content/about";

const styles = {
  h2: {
    ...theme.h2,
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing.grid * 2
  },
  h2Mobile: {
    ...theme.h2Mobile,
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing.grid * 2
  },
  h3: {
    ...theme.h3,
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingTop: 15,
    paddingBottom: 15
  },
  p: {
    ...theme.p,
    textAlign: "left",
    margin: 0,
    padding: 0
  },
  row: {
    paddingTop: theme.spacing.grid * 4,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid,
    paddingBottom: theme.spacing.grid * 2.5
  },
  qa: {
    borderTop: `2px solid ${theme.color.highlight}`,
    paddingBottom: theme.spacing.grid * 2.5
  }
};

const FAQLayout = ({ classes, faq, title, icon }) => {
  let img = balloon;

  if (!_.isNil(icon)) {
    if (_.isEqual(icon, "computer")) {
      img = computer;
    } else if (_.isEqual(icon, "lightblub")) {
      img = lightBulb;
    }
  }

  return (
    <Row align="start" justify="center" className={classes.row}>
      <Col md={4}>
        <Hidden xs md>
          <div className={classes.h2}>{title}</div>
          <img src={img} className={classes.image} />
        </Hidden>
        <Visible xs md>
          <div className={classes.h2Mobile}>{title}</div>
        </Visible>
      </Col>
      <Col md={8}>
        <Pane>
          {faq.map((item) => (
            <div className={classes.qa}>
              <div className={classes.h3}>{item.question}</div>
              <div className={classes.p}>{item.answer}</div>
            </div>
          ))}
        </Pane>
      </Col>
    </Row>
  );
};

export default injectSheet(styles)(FAQLayout);
