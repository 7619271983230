import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";

import { withoutEmoji } from "../discover/utils";

const MESSAGE = "Merit connects tech talent to senior leaders to grow craft, career and community.";

const baseURL = () => {
  const backupUrl = process.env.NODE_ENV !== "production" ? "http://localhost:3001/" : "https://merit-app-dev.herokuapp.com/";

  let base = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : backupUrl;

  if (base?.includes("/graphql")) {
    base = _.replace(base, "/graphql", "");
  }

  return base;
};

const mentorDesc = (profile) => {
  const preface = {
    "Software Engineering": `${profile?.fullName} is looking to mentor software engineers.`,
    "Product Management": `${profile?.fullName} is looking to mentor product managers.`,
    "Product Design": `${profile?.fullName} is looking to mentor product designers.`,
    Management: `${profile?.fullName} is looking to mentor folks in tech.`,
    Other: `${profile?.fullName} is looking to mentor folks in tech.`
  };

  const role = profile?.jobRole?.length > 0 ? profile?.jobRole : _.head(profile.jobRoles);
  const chosen = preface[role];

  const topics = profile?.coachingPlaylists;

  let topicMsg = "";

  if (topics?.length > 0) {
    const topic1 = _.lowerCase(withoutEmoji(topics[0]?.name));
    const topic2 = _.lowerCase(withoutEmoji(topics[1]?.name));
    topicMsg = `Chat with ${profile?.fullName} about ${topic1}, ${topic2}, and more.`;
  }

  return `${chosen?.length > 0 ? chosen : preface["Other"]} ${topicMsg} ${MESSAGE}`;
};

const Meta = ({ profile, mentor, slug }) => {
  const shareUrl = profile?.id ? `${baseURL()}/share/${slug}` : "";

  const mentorTitle = mentor?.id && profile?.fullName ? `${profile?.fullName} | Ask mentorship questions on Merit` : `${slug} | Merit`;
  const memberTitle = profile?.fullName ? `${profile?.fullName} | Merit` : `${slug} | Merit`;

  const title = mentor?.id ? mentorTitle : memberTitle;
  const description = mentor?.id ? mentorDesc(profile) : MESSAGE;
  const twitterURL = `@meritmentor`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window.location.href} />
      <title>{title}</title>
      {profile?.id && !mentor?.id && <meta name="robots" content="noindex,nofollow" />}
      <meta property="og:type" content="profile" />
      <meta name="description" property="og:description" content={description} />
      <meta name="title" property="og:title" content={title} />
      <meta name="image" property="og:image" content={shareUrl} />
      <meta name="url" property="og:url" content={window.location.href} />

      <meta name="twitter:card" property="twitter:card" content="summary" />
      <meta name="twitter:site" property="twitter:site" content={twitterURL} />
      <meta name="twitter:title" property="twitter:title" content={title} />
      <meta name="twitter:description" property="twitter:description" content={description} />
      <meta name="twitter:image" property="twitter:image" content={shareUrl} />
    </Helmet>
  );
};

export default Meta;
