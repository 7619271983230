import React from "react";
import { Link as BrowserLink } from "react-router-dom";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { Avatar, Button, Heading, Pane, Paragraph, majorScale, minorScale, defaultTheme } from "evergreen-ui";

const width = {
  xs: 375,
  // xs: 540,
  sm: 740,
  md: 960,
  xl: 1140,
  xxl: 1140
};

const WaitlistApplicationPage = (props) => (
  <ScreenClassRender
    render={(screenClass) => (
      <Row
        style={{
          width: width[screenClass],
          minHeight: "calc(100vh - 120px)",
          paddingTop: majorScale(2),
          paddingBottom: majorScale(6)
        }}
      >
        <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
          <Pane
            backgroundColor={"white"}
            borderRadius={minorScale(3)}
            border={`1px solid ${defaultTheme.colors.gray400}`}
            width="100%"
            padding={majorScale(4)}
            marginBottom={majorScale(3)}
          >
            <Pane
              flexDirection="column"
              display="flex"
              justifyContent="space-between"
              paddingBottom={majorScale(2)}
              paddingX={majorScale(4)}
            >
              <Heading size={700}>📝 On our mentor waitlist</Heading>
            </Pane>
            <Pane display="flex" flexDirection="column" paddingX={majorScale(4)} paddingY={majorScale(2)}>
              <Pane>
                <Heading marginBottom={majorScale(2)}>Thanks for applying to mentor on Merit!</Heading>
                <Paragraph size={500}>
                  Mentoring on Merit works best for people who are senior or further and work on software teams: Product Management, Design,
                  and Software Engineering.
                </Paragraph>
                <br />
                <Paragraph size={500}>
                  After reviewing your profile, at the moment, we don’t think it’s a perfect fit to mentor on Merit. When we think you’re a
                  fit, we’ll reach out! And if you think you’re seeing this in error, please message us at support@get-merit.com.
                </Paragraph>
                <br />
                <Paragraph size={500}>
                  In the meantime, you can still contribute to the community by booking time with mentors. Discover more below to get
                  started.
                </Paragraph>
                <br />
                <Paragraph size={500}>
                  <b>Thanks!</b>
                </Paragraph>
                <Paragraph size={500}>Randy and Kirk</Paragraph>
                <Paragraph size={500}>Founders, Merit</Paragraph>
              </Pane>
              <Pane marginY={majorScale(2)}>
                <Avatar
                  size={majorScale(10)}
                  marginRight={majorScale(1)}
                  src="https://ucarecdn.com/96c525b1-8e13-43ab-81c5-a4c78bbc23d9/-/scale_crop/300x300/smart/smart_faces"
                />
                <Avatar
                  size={majorScale(10)}
                  marginRight={majorScale(1)}
                  src="https://ucarecdn.com/dd0ed49f-22a4-4d2a-a443-123be0f1f14c/-/scale_crop/300x300/smart/smart_faces"
                />
              </Pane>
              <Pane display="flex" justifyContent="center" width="100%" paddingTop={majorScale(2)}>
                <Button is={BrowserLink} to="/discover?hello=true" appearance="primary">
                  Browse Merit
                </Button>
              </Pane>
            </Pane>
          </Pane>
        </Col>
      </Row>
    )}
  />
);

export default withRouter(WaitlistApplicationPage);
