import _ from "lodash";
import React, { useState } from "react";
import gql from "graphql-tag";
import { Query, Mutation, useQuery, useMutation } from "@apollo/client";

import { Avatar, Pane, majorScale } from "evergreen-ui";

import Uploader from "./Uploader";

const GET_PROFILE_PIC = gql`
  query myProfile {
    myProfile {
      id
      picture
    }
  }
`;

const UPDATE_PROFILE_PIC = gql`
  mutation updateProfile($picture: String) {
    updateProfile(picture: $picture) {
      id
      picture
    }
  }
`;

const PhotoUploader = ({ onCompleted }) => {
  const { data: profilePic } = useQuery(GET_PROFILE_PIC);

  const [isHovering, setIsHovering] = useState(false);
  const [picture, setPicture] = useState(profilePic?.myProfile?.picture || "");

  const [updateProfile] = useMutation(UPDATE_PROFILE_PIC, {
    onCompleted: ({ updateProfile }) => {
      setPicture(updateProfile.picture);
      setIsHovering(false);

      if (_.isFunction(onCompleted)) {
        onCompleted({ updateProfile });
      }
    }
  });

  return (
    <Pane width="100%" onMouseEnter={() => setIsHovering(!isHovering)} onMouseLeave={() => setIsHovering(!isHovering)}>
      <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Avatar src={picture} size={125} marginBottom={majorScale(1)} />
        <Uploader
          tab="file"
          onUploadComplete={(file) => {
            updateProfile({
              variables: {
                picture: file.cdnUrl
              }
            });
          }}
        />
      </Pane>
    </Pane>
  );
};

export default PhotoUploader;
