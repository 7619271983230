import _ from "lodash";
import React from "react";
import { Link as BrowserLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Heading, Link, Pane, Text, majorScale, minorScale } from "evergreen-ui";

import PlaylistQueries from "../../queries/playlist";
import ProfileList from "../../queries/profile/ProfileList";

const withoutEmoji = (str) => {
  return str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );
};

const ChooseRemoveTopic = ({ cardWidth, hasTopic, topic }) => {
  const [chooseRemove, { loading: isUpdating }] = useMutation(PlaylistQueries.ChooseRemovePlaylistMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: ProfileList.MyCoachingProfile }],
    onCompleted: () => window.analytics.track("Update Mentorship Topics")
  });

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={majorScale(14)}
      minHeight={majorScale(14)}
      minWidth={cardWidth}
    >
      <Button
        isLoading={isUpdating}
        intent={hasTopic ? "danger" : "success"}
        onClick={() =>
          chooseRemove({
            variables: { playlistId: topic?.id }
          })
        }
      >
        {hasTopic ? "Leave" : "Join"}
      </Button>
    </Pane>
  );
};

const NewTopicCard = ({ topic, isEditing, hasTopic }) => {
  const cardWidth = majorScale(18);

  const card = (
    <Pane display="flex" flexDirection="row" width="100%" paddingY={majorScale(3)}>
      {/* Emoji Card */}
      <Pane
        background="#EBF0FE"
        width={cardWidth}
        minWidth={cardWidth}
        height={majorScale(14)}
        minHeight={majorScale(14)}
        borderRadius={minorScale(3)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        marginRight={minorScale(5)}
      >
        <Heading size={900} textDecoration="none">
          {String.fromCodePoint(topic.name.codePointAt(0))}
        </Heading>
      </Pane>

      {/* Topic Information */}
      <Pane display="flex" flexDirection="column" justifyContent="center" width={isEditing ? `calc(100% - ${cardWidth}px)` : null}>
        <Heading size={700} marginBottom={minorScale(2)}>
          {_.trim(withoutEmoji(topic.name))}
        </Heading>
        <Text size={500}>{topic.description}</Text>
      </Pane>

      {isEditing && <ChooseRemoveTopic cardWidth={cardWidth} hasTopic={hasTopic} topic={topic} />}
    </Pane>
  );

  return isEditing ? (
    card
  ) : (
    <Link to={`/q/${topic?.slug}`} is={BrowserLink} style={{ textDecoration: "none" }}>
      {card}
    </Link>
  );
};

export default NewTopicCard;
