import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
import { Alert, Dialog, Heading, Pane, Text, majorScale, minorScale, toaster } from "evergreen-ui";

import ProfileList from "../../../queries/profile/ProfileList";
import TextArea from "../../../components/form/TextArea";

const SUCCESS_TOAST = "Updated profile.";

const roles = ["Software Engineering", "Product Management", "Product Design", "Other"].map((role) => ({ value: role, label: role }));

const CURRENT_JOB_LEVEL = "What is your current job level?";
const LEVEL_TOAST = "Level updated.";

const CURRENT_JOB_ROLE = "What is your current job role(s)?";
const JOB_ROLE_INFO = "This helps us curate a better experience on the platform.";
const JOB_ROLE_SUCCESS = "Job roles updated.";

const YOUR_BIO = "What is your professional background?";
const BIO_INFO = " A short bio helps break the ice when connecting with others.";
const BIO_PLACEHOLDER =
  "I got into tech through an untraditional path. I thought I wanted to be a writer, but then after trying an engineering class I was hooked and decided to sign up for a bootcamp...";
const BIO_SUCCESS = "Bio updated.";

const showSuccess = (copy) =>
  _.debounce(() => {
    toaster.success(copy, { duration: 2 });
  }, 2000);

const ProfileAboutSchema = Yup.object().shape({
  bio: Yup.string().max(50000, "Please shorten your bio")
});

const createSelectLabels = (labels) => labels?.map((value) => ({ value, label: value }));

const ChooseLevel = ({ myProfile }) => {
  const [updateLevel] = useMutation(ProfileList.UpdateLevel, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ],
    onCompleted: () => {
      window.analytics.track("Update Job Level");
      showSuccess(LEVEL_TOAST)();
    }
  });

  const selectedLevel = { value: myProfile.level, label: myProfile.level };
  const availableLevels = createSelectLabels(myProfile?.availableLevels);

  return (
    <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
      <Pane marginBottom={majorScale(2)}>
        <Heading size={400}>{CURRENT_JOB_LEVEL}</Heading>
      </Pane>
      <Select
        options={availableLevels}
        value={selectedLevel}
        isClearable={false}
        isSearchable={true}
        onChange={(selected) => {
          updateLevel({
            variables: {
              level: _.isNil(selected) ? null : selected.value
            }
          });
        }}
      />
    </Pane>
  );
};

const ChooseJobRole = ({ myProfile }) => {
  const [updateProfile] = useMutation(ProfileList.UpdateProfile, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyCoachingProfile
      }
    ],
    onCompleted: () => {
      window.analytics.track("Update Profile Job Role");
      showSuccess(JOB_ROLE_SUCCESS)();
    }
  });

  const jobRoles = myProfile?.jobRoles ? myProfile.jobRoles.map((jobRole) => ({ value: jobRole, label: jobRole })) : [];

  return (
    <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
      <Pane marginBottom={majorScale(1)}>
        <Heading size={400} marginBottom={minorScale(2)}>
          {CURRENT_JOB_ROLE}
        </Heading>
        <Text marginBottom={minorScale(2)}>{JOB_ROLE_INFO}</Text>
      </Pane>
      <Select
        isMulti
        isClearable={false}
        options={roles}
        defaultValue={jobRoles}
        marginBottom={minorScale(1)}
        onChange={(selected) => {
          if (selected?.length) {
            const jobRoles = _.map(selected, (jobRole) => {
              return jobRole.value;
            });

            updateProfile({ variables: { jobRoles } });
          }
        }}
      />
    </Pane>
  );
};

const WriteBio = ({ myProfile }) => {
  const [updateProfile] = useMutation(ProfileList.UpdateProfile, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyCoachingProfile
      }
    ],
    onCompleted: () => {
      showSuccess(BIO_SUCCESS)();
      window.analytics.track("Update Profile Bio");
    }
  });

  const ProfileBioSchema = Yup.object().shape({
    bio: Yup.string().max(50000, "Please shorten your bio")
  });

  return (
    <Formik
      validationSchema={ProfileBioSchema}
      initialValues={{
        bio: myProfile?.bio?.length ? myProfile.bio : ""
      }}
      onSubmit={(variables, { setSubmitting, setValues }) => {
        updateProfile({ variables });
        setSubmitting(false);
      }}
      render={({ setFieldValue, handleChange, submitForm, errors, values }) => (
        <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
          <Pane marginBottom={majorScale(1)}>
            <Heading size={400} marginBottom={minorScale(2)}>
              {YOUR_BIO}
            </Heading>
            <Text marginBottom={minorScale(2)}>{BIO_INFO}</Text>
          </Pane>
          <Field
            type="text"
            name="bio"
            placeholder={BIO_PLACEHOLDER}
            component={TextArea}
            onBlur={(e) => {
              e.persist();
              setFieldValue("bio", e.target.value, false);
              submitForm();
            }}
          />
        </Pane>
      )}
    />
  );
};

const EditAboutMe = ({ myProfile, showAbout, setShowAbout }) => {
  return (
    <Dialog
      isShown={showAbout}
      title="Edit Profile Overview"
      onCloseComplete={() => setShowAbout(false)}
      confirmLabel="Finish"
      hasCancel={false}
      hasClose={false}
    >
      <Pane>
        <ChooseLevel myProfile={myProfile} />
        <ChooseJobRole myProfile={myProfile} />
        <WriteBio myProfile={myProfile} />
      </Pane>
    </Dialog>
  );
};

export default EditAboutMe;
