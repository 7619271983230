import React from "react";
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

export default (layoutComponent, routes, options) => {
  const withLayout = ({ component, props, state, subState, privateRoute, reviewRoute, hideNav, noGutter, hideFooter, bgColor }) => {
    const Component = component;
    const Layout = layoutComponent;

    return (
      <Layout
        state={state}
        subState={subState ? subState : undefined}
        privateRoute={privateRoute}
        reviewRoute={reviewRoute}
        options={options}
        noGutter={noGutter}
        hideNav={hideNav}
        hideFooter={hideFooter}
        bgColor={bgColor}
      >
        <Component {...props} />
      </Layout>
    );
  };

  const withRoute = ({ component, path, exact, privateRoute, state, subState, reviewRoute, hideNav, noGutter, hideFooter, bgColor }) => (
    <Route
      exact={exact}
      path={path}
      key={path}
      component={(props) =>
        withLayout({ component, props, state, privateRoute, subState, reviewRoute, hideNav, noGutter, hideFooter, bgColor })
      }
    />
  );

  return routes.map(withRoute);
};
