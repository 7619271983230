import _ from "lodash";
import React from "react";
import { useQuery } from "@apollo/client";
import { Col, Row, Container } from "react-grid-system";
import { Alert, Heading, Pane, Spinner, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import queryString from "query-string";

import FeedQueries from "../../queries/feed";
import PostForm from "../../components/post/PostForm";
import DiscussionAbout from "../../components/discussion/DiscussionAbout";
import Breadcrumb from "../../components/Breadcrumb";

const PAGE_TITLE = "Start a discussion";
const POST_NOT_FOUND = "Could not find this post.";

const canEdit = ({ myProfile, owner }) => {
  if (myProfile?.isAdmin) return true;
  else if (_.isEqual(myProfile?.id, owner?.id)) return true;
  else return false;
};

const DiscussionFormPage = ({ match, history, location }) => {
  const id = match?.params?.id;
  const { topic, name } = queryString.parse(location?.search, { arrayFormat: "comma" });
  const { data: postResult, loading, error } = useQuery(FeedQueries.queries.PostById, { variables: { id } });

  let content = null;

  if (error) {
    content = <Alert title={POST_NOT_FOUND} />;
  }

  if (loading) {
    content = (
      <Pane display="flex" width="100%">
        <Spinner />
      </Pane>
    );
  }

  if (postResult?.myProfile) {
    const { postById, myProfile } = postResult;

    if (postById?.id && !canEdit({ myProfile, owner: postById?.owner })) {
      history.push(`/d/${id}`);
    }

    content = (
      <>
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          marginTop={majorScale(4)}
          marginBottom={majorScale(1)}
        >
          <Heading is="h1" size={800} marginBottom={majorScale(2)}>
            {PAGE_TITLE}
          </Heading>
        </Pane>
        <Pane
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray500}`}
          borderRadius={majorScale(2)}
          padding={majorScale(4)}
          width="100%"
        >
          <PostForm
            post={postById}
            myProfile={myProfile}
            topicId={topic}
            topicName={name}
            onUpdate={(noop, updatedPost) => {
              history.push(`/d/${updatedPost?.id}`);
            }}
          />
        </Pane>
      </>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Col xs={12} sm={9} md={9} lg={9}>
        {content}
      </Col>
      <Col sm={3} md={3} lg={3}>
        <DiscussionAbout />
      </Col>
    </div>
  );
};

export default DiscussionFormPage;
