import _ from "lodash";
import React, { useState } from "react";
import { Row, Col, Container } from "react-grid-system";
import { Alert, Button, Card, Heading, Pane, SelectMenu, SegmentedControl, Spinner, Switch, Text, majorScale, toaster } from "evergreen-ui";
import { Query, Mutation, useQuery, useMutation } from "@apollo/client";
import { Link as BrowserLink } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from "query-string";

import Loader from "../../components/Loader";
import ListEnd from "../../components/ListEnd";
import PlaylistSimple from "../../components/playlist/PlaylistSimple";
import { createPill, splitTopics } from "../../components/playlist/Topic";

import TopicFilters from "../../components/topic/TopicFilters";
import PlaylistQueries from "../../queries/playlist";
import ProfileList from "../../queries/profile/ProfileList";
import ListLoadingView from "../../components/ListLoadingView";

const ERROR_MSG = "We were not able to load this page. Please try again.";
const END_MSG = "No more topics";
const MIN_CHOICES = 5;

const UPDATED_TOPICS = "Topics updated.";

const FINISH_CTA = "Finish";

const TopicsHeader = ({ next }) => {
  return (
    <>
      <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
        <Heading size={600} paddingBottom={majorScale(2)}>
          Choose topics
        </Heading>
        <Button appearance="primary" is={BrowserLink} to={next} onClick={() => toaster.success(UPDATED_TOPICS)}>
          {FINISH_CTA}
        </Button>
      </Pane>
      <Text>{`Please choose a few topics that you want to talk about.`}</Text>
    </>
  );
};

const isInTopic = ({ topic, myProfile }) => {
  let inTopic = false;

  _.each(topic?.coaches, (coach) => {
    if (_.isEqual(coach?.user?.id, myProfile?.id)) {
      inTopic = true;

      return;
    }
  });

  return inTopic;
};

const TopicsForm = ({ loading, topics, next, myProfile }) => {
  return (
    <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)} marginTop={majorScale(2)}>
      <InfiniteScroll
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "-15px",
          marginRight: "-15px"
        }}
        hasMore={topics.pageInfo.hasNextPage}
        dataLength={topics.edges.length}
        loader={<ListLoadingView title={"Loading topics"} />}
        next={next}
      >
        {topics.edges.map((topic) => (
          <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4} key={topic.id}>
            <PlaylistSimple key={`TopicsForm-${topic.id}`} playlist={topic} isInTopic={isInTopic({ topic, myProfile })} leaveJoin />
          </Col>
        ))}
      </InfiniteScroll>
      {topics?.edges && !topics.edges.length && !loading && <ListLoadingView title={"No topics found"} hideSpinner />}
    </Pane>
  );
};

const getArray = (item) => (item && _.isString(item) ? [item] : item);

const initialQuery = (props) => {
  const {
    location: { search }
  } = props;
  const { name, levels, roles, motivations, cursor, hello, question, sort } = queryString.parse(search, { arrayFormat: "comma" });

  return {
    cursor: cursor ? cursor : "1",
    name: name ? name : "",
    levels: levels ? getArray(levels) : [],
    roles: roles ? getArray(roles) : [],
    motivations: motivations ? getArray(motivations) : [],
    sort: sort ? sort : "DEFAULT"
  };
};

const renderSearchUrl = (query) => {
  const parseList = (list) => encodeURIComponent(_.join(list, ","));
  const { name, levels, roles, motivations, cursor, sort } = query;

  let search = `cursor=${cursor}`;

  search = name && name.length ? `${search}&name=${encodeURIComponent(name)}` : search;
  search = levels && levels.length ? `${search}&levels=${parseList(levels)}` : search;
  search = roles && roles.length ? `${search}&roles=${parseList(roles)}` : search;
  search = motivations && motivations.length ? `${search}&motivations=${parseList(motivations)}` : search;
  search = sort && sort.length ? `${search}&sort=${sort}` : search;

  return search;
};

const setNextPage = (props) => {
  const search = props.location?.search;
  const redirect = queryString.parse(search)?.redirect;
  const next = redirect ? redirect : "/settings/mentor";

  return next;
};

const TopicsFormContainer = (props) => {
  let topicSearch, next, updateSearch;

  const [query, setQuery] = useState(initialQuery(props));
  const [redirect, setRedirect] = useState(setNextPage(props));
  const { data: mentorResult } = useQuery(ProfileList.MyCoachingProfile);
  const { data: topicSearchResult, refetch, fetchMore, loading } = useQuery(PlaylistQueries.TopicFormQuery, { variables: query });

  if (topicSearchResult) {
    topicSearch = topicSearchResult.topicSearch;

    updateSearch = (query) => {
      setQuery(query);
      refetch({ variables: { ...query } });

      props.history.push({
        location: props.location.pathname,
        search: renderSearchUrl(query)
      });
    };

    next = () =>
      fetchMore({
        query: PlaylistQueries.TopicFormQuery,
        variables: {
          ...query,
          cursor: topicSearch.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            topicSearch: {
              __typename: fetchMoreResult.topicSearch.__typename,
              pageInfo: fetchMoreResult.topicSearch.pageInfo,
              edges: [...prev.topicSearch.edges, ...fetchMoreResult.topicSearch.edges]
            }
          });
        }
      });
  }

  return (
    <Container>
      <Row align="start" justify="center">
        <Col xs={12} sm={10}>
          <TopicsHeader next={redirect} />

          <TopicFilters updateSearch={updateSearch} query={query} topicFilters={topicSearchResult?.topicFilters} />
        </Col>
        <Col xs={12} sm={10}>
          {loading && <Loader />}

          {!loading && topicSearch?.edges?.length > 0 && mentorResult?.myProfile && (
            <TopicsForm loading={loading} topics={topicSearch} next={next} myProfile={mentorResult.myProfile} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TopicsFormContainer;
