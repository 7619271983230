import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import qs from "qs";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { withRouter, Link as BrowserLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Button,
  CalendarIcon,
  Dialog,
  EditIcon,
  Heading,
  ShareIcon,
  Pane,
  Tab,
  TabNavigation,
  majorScale,
  defaultTheme as theme
} from "evergreen-ui";

import ProfileList from "../../queries/profile/ProfileList";
import BackButton from "../../components/BackButton";
import ProfilePaused from "../../components/profile/ProfilePaused";

import EditOpeningHours from "./edit/calendar/EditOpeningHours";
import EditConflicts from "./edit/calendar/EditConflicts";
import OldEditConflicts from "./edit/calendar/OldEditConflicts";
import EditTimeZone from "./edit/calendar/EditTimeZone";
import EditMeeting from "./edit/calendar/EditMeeting";
import EditVideoLink from "./edit/calendar/EditVideoLink";
import EditMaxSessions from "./edit/calendar/EditMaxSessions";
import EditOpenEnded from "./edit/calendar/EditOpenEnded";
import EditApproveSession from "./edit/calendar/EditApproveSession";
import EditMinNotice from "./edit/calendar/EditMinNotice";
import EditVacation from "./edit/calendar/EditVacation";
import EditPause from "./edit/calendar/EditPause";
import EditTopics from "./edit/EditTopics";
import BookSession from "./scheduler/BookSession";

const SettingSections = [
  {
    key: "calendar",
    text: "Calendar Settings",
    link: "#calendar"
  },
  {
    key: "session",
    text: "Session Setup",
    link: "#session"
  },
  {
    key: "limits",
    text: "Mentorship Limits",
    link: "#limits"
  },
  {
    key: "pause",
    text: "Pause Sessions",
    link: "#pause"
  }
];

const PageSection = ({ title, id, children, sideButton }) => {
  return (
    <Pane marginTop={majorScale(4)} id={id}>
      <Pane display="flex" flexDirection="row" justifyContent="space-between">
        <Heading size={600} marginBottom={majorScale(2)}>
          {title}
        </Heading>
        {sideButton}
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        width="100%"
        padding={majorScale(4)}
        borderRadius={majorScale(2)}
        border={`1px solid ${theme.colors.gray500}`}
        backgroundColor="white"
      >
        {children}
      </Pane>
    </Pane>
  );
};

const MentorSettings = withRouter(({ location, history }) => {
  const [showTopicsEditor, setShowTopicsEditor] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const { data: profileResult } = useQuery(ProfileList.MyCoachingProfile);

  const showGoogle = location?.search?.includes("google");
  let myProfile, myCoachingProfile;

  if (profileResult) {
    myProfile = profileResult?.myProfile;
    myCoachingProfile = profileResult?.myCoachingProfile;
  }

  const pageTitle = "Mentor Settings | Merit";

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row
          justify="flex-start"
          style={{
            backgroundColor: theme.colors.gray100,
            minHeight: "calc(100vh - 120px)",
            paddingY: majorScale(4),
            width: width[screenClass]
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="canonical" href={window.location.href} />
            <title>{pageTitle}</title>
            <meta name="robots" content="noindex,nofollow" />

            <meta name="title" property="og:title" content={pageTitle} />
            <meta name="url" property="og:url" content={window.location.href} />
          </Helmet>
          <Col sm={2} md={2} lg={2} xs={12}>
            <BackButton />
          </Col>
          <Col lg={8} md={8} sm={8} xs={12}>
            <Pane marginTop={majorScale(4)}>
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                marginBottom={majorScale(1)}
              >
                <Heading size={700} marginRight={majorScale(1)}>
                  <CalendarIcon marginRight={majorScale(1)} />
                  Edit Mentor Settings
                </Heading>
                <Dialog
                  title="Calendar Preview"
                  isShown={showCalendar}
                  onCloseComplete={() => setShowCalendar(false)}
                  onCancel={() => setShowCalendar(false)}
                  preventBodyScrolling
                  shouldCloseOnEscapePress={true}
                  shouldCloseOnOverlayClick={true}
                  hasFooter={false}
                >
                  <BookSession profile={myProfile} coachByProfileId={myCoachingProfile} myProfile={myProfile} inline isPreview />
                </Dialog>
                {myProfile?.id && myCoachingProfile?.id && (
                  <Button appearance="minimal" iconBefore={ShareIcon} onClick={() => setShowCalendar(true)}>
                    Preview Calendar
                  </Button>
                )}
              </Pane>
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                marginBottom={majorScale(4)}
              >
                <TabNavigation>
                  {SettingSections.map(({ key, text, link }, index) => {
                    const isSelected = location?.hash ? link === location.hash : index === 0;

                    return (
                      <Tab
                        appearance="minimal"
                        key={key}
                        is="a"
                        href={link}
                        id={link}
                        isSelected={isSelected}
                        marginRight={majorScale(2)}
                        paddingY={majorScale(2)}
                        fontWeight={500}
                        color={isSelected ? theme.colors.blue500 : theme.colors.muted}
                        borderBottom={`2px solid ${isSelected ? theme.colors.blue500 : theme.colors.gray100}`}
                      >
                        {text}
                      </Tab>
                    );
                  })}
                </TabNavigation>
              </Pane>
            </Pane>
            {myCoachingProfile?.isPaused?.start?.length > 0 && (
              <Pane>
                <ProfilePaused isPaused={profileResult?.myCoachingProfile?.isPaused} myProfile={profileResult?.myProfile} />
              </Pane>
            )}
            {profileResult?.myProfile && (
              <Pane paddingBottom={majorScale(8)}>
                <PageSection
                  title="Calendar Settings"
                  id="calendar"
                  sideButton={
                    <>
                      <Button
                        appearance="primary"
                        iconBefore={EditIcon}
                        onClick={() => setShowTopicsEditor(true)}
                        marginBottom={majorScale(2)}
                      >
                        Edit Topics
                      </Button>
                      <EditTopics
                        showEditor={showTopicsEditor}
                        setShowEditor={setShowTopicsEditor}
                        myCoachingProfile={myCoachingProfile}
                        myProfile={myProfile}
                      />
                    </>
                  }
                >
                  <EditOpeningHours myCoachingProfile={myCoachingProfile} />
                  <EditTimeZone myProfile={myProfile} />
                  <EditConflicts myProfile={myProfile} />
                </PageSection>

                <PageSection title="Session Setup" id="session">
                  <EditMeeting myCoachingProfile={myCoachingProfile} />
                  <EditVideoLink />
                </PageSection>

                <PageSection title="Mentorship Limits" id="limits">
                  <EditMaxSessions myCoachingProfile={myCoachingProfile} />
                  <EditMinNotice myCoachingProfile={myCoachingProfile} />
                  <EditOpenEnded myCoachingProfile={myCoachingProfile} />
                  <EditApproveSession myCoachingProfile={myCoachingProfile} />
                </PageSection>

                <PageSection title="Pause Sessions" id="pause">
                  <EditVacation myCoachingProfile={myCoachingProfile} myProfile={myProfile} />
                  <EditPause myCoachingProfile={myCoachingProfile} />
                </PageSection>
              </Pane>
            )}
          </Col>
        </Row>
      )}
    />
  );
});

export default MentorSettings;
