import React from "react";
import { Redirect } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import withRoutes from "../withRoutes";

// import GuruPage from './GuruPage'
// import BookCheckinPage from './BookCheckinPage'
// import CareerGoalForm from "./CareerGoalForm"
// import CareerGoalDetail from "./CareerGoalDetail"

export default withRoutes(Layout, [
  {
    path: "/guru",
    component: () => <Redirect to="/guru/0" />,
    privateRoute: true,
    exact: true,
    state: "Sessions"
  }
  // {
  //   path: "/guru/:step",
  //   component: GuruPage,
  //   privateRoute: true,
  //   state: "Sessions",
  // },
  // {
  //   path: "/checkin",
  //   component: BookCheckinPage,
  //   privateRoute: true,
  //   state: "Sessions",
  // },
  // {
  //   path: "/career_goal",
  //   component: CareerGoalForm,
  //   privateRoute: true,
  //   state: "Sessions"
  // },
  // {
  //   path: "/recommendations",
  //   component: CareerGoalDetail,
  //   privateRoute: true,
  //   state: "Sessions",
  // }
]);
