import _ from "lodash";
import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Button, CrossIcon, Icon, Text, Pane, majorScale, minorScale } from "evergreen-ui";
import { withRouter } from "react-router-dom";

import ProfileList from "../queries/profile/ProfileList";

const ActivateNotification = ({ myCoachingProfile, location, myProfile, onActivate }) => {
  const [activateProfile] = useMutation(ProfileList.UpdateStatus, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyCoachingProfile }],
    onCompleted: () => {
      if (_.isFunction(onActivate)) {
        onActivate();
      }
    }
  });

  if (!_.isEqual(myCoachingProfile?.status, "Accepted")) return null;
  if (location?.pathname.includes("/mentor/setup")) return null;
  if (location?.pathname.includes("/profile/calendar/edit")) return null;
  if (location?.pathname.includes("/choose-topics")) return null;
  if (location?.pathname.includes("/apply/")) return null;
  if (location?.pathname.includes("/application/")) return null;

  const ACTIVATE_CTA = "Activate";
  const ACTIVATE_TITLE = "✨ Activate your mentor profile";
  const ACTIVATE_INFO = "People won’t be able to book time with you until you activate your profile!";

  const onProfile = location?.pathname?.includes(`/p/${myProfile?.slug}`);

  return (
    <Pane
      textAlign="center"
      maxWidth={640}
      position="absolute"
      marginLeft="auto"
      marginRight="auto"
      left={0}
      right={0}
      zIndex={10}
      top={onProfile ? majorScale(10) : majorScale(1)}
    >
      <Alert intent="info" title={ACTIVATE_TITLE}>
        <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={minorScale(2)}>
          <Text>{ACTIVATE_INFO}</Text>
          <Button
            appearance="primary"
            intent="success"
            onClick={() => {
              activateProfile({ variables: { status: "Active" } });
              window.analytics.track("Coach Activated");
            }}
          >
            <Icon marginRight={majorScale(1)} icon="repeat" />
            {ACTIVATE_CTA}
          </Button>
        </Pane>
      </Alert>
    </Pane>
  );
};

const ConnectCalendarNotification = ({ myCoachingProfile, location, myProfile, history }) => {
  const [dismissCalendar] = useMutation(ProfileList.DismissConnectionNotif, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery },
      { query: ProfileList.GoogleConnectLink }
    ]
  });

  const { data: googleResult } = useQuery(ProfileList.GoogleConnectLink);

  if (!_.isEqual(myCoachingProfile?.status, "Active")) return null;

  const onProfile = location?.pathname?.includes(`/p/${myProfile?.slug}`);
  const existingConnection = googleResult?.myProfile?.googleCalendar && googleResult?.myProfile?.googleCalendar?.length > 0;

  const googleCalendarDismissed = googleResult?.myProfile?.googleCalendarDismissed;

  const ACTIVATE_CTA = "Connect Google Calendar";
  const ACTIVATE_TITLE = "Calendar not connected";
  const ACTIVATE_INFO =
    "Your mentor calendar is not synced with your calendar. This means members can book time with you when you’re not actually available.";

  if (!googleResult) return null;

  if (existingConnection) return null;
  if (googleCalendarDismissed) return null;
  if (onProfile) return null;

  if (location?.pathname.includes("/mentor/settings")) return null;
  if (location?.pathname.includes("/profile/calendar/edit")) return null;
  if (location?.pathname.includes("/choose-topics")) return null;
  if (location?.pathname.includes("/apply/")) return null;
  if (location?.pathname.includes("/application/")) return null;
  else {
    return (
      <Pane
        textAlign="center"
        maxWidth={640}
        position="absolute"
        marginLeft="auto"
        marginRight="auto"
        left={0}
        right={0}
        zIndex={10}
        top={onProfile ? majorScale(10) : majorScale(1)}
      >
        <Alert intent="warning" title={ACTIVATE_TITLE}>
          <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={minorScale(2)}>
            <Text>{ACTIVATE_INFO}</Text>
          </Pane>
          <Pane display="flex" justifyContent="space-between" alignItems="center" paddingY={minorScale(2)}>
            <CrossIcon
              onClick={() => {
                dismissCalendar({
                  variables: { googleCalendarDismissed: true }
                });
                window.analytics.track("Dismissed Calendar");
              }}
            />
            <Button
              appearance="primary"
              onClick={() => {
                history.push("/mentor/settings");
              }}
            >
              <Icon marginRight={majorScale(1)} icon="repeat" />
              {ACTIVATE_CTA}
            </Button>
          </Pane>
        </Alert>
      </Pane>
    );
  }
};

const MentorNotification = ({ myCoachingProfile, myProfile, location, onActivate, history }) => {
  if (!myCoachingProfile || !myCoachingProfile.id) return null;

  if (_.isEqual(myCoachingProfile?.status, "Accepted")) {
    return <ActivateNotification myCoachingProfile={myCoachingProfile} myProfile={myProfile} location={location} onActivate={onActivate} />;
  } else {
    return (
      <ConnectCalendarNotification myCoachingProfile={myCoachingProfile} myProfile={myProfile} location={location} history={history} />
    );
  }
};

export default withRouter(MentorNotification);
