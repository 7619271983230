import React from "react";
import { Alert, Button, Pane, Spinner, Text, toaster, majorScale } from "evergreen-ui";
import { Hidden, Visible } from "react-grid-system";
import { Query, Mutation, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import CoachList from "../../routes/coach/CoachList";

const RemindMeButton = ({ coach }) => {
  const [remindMe] = useMutation(CoachList.RemindMeMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CoachList.CoachReminderQuery, variables: { coachId: coach.id } }],
    onCompleted: () => {
      toaster.success(`Reminder set for ${coach?.user?.fullName}`, { description: `You'll be emailed when they are available.` });
    }
  });

  return (
    <>
      <Visible xs>
        <Pane alignItems="center" display="flex" justifyContent="space-between" flexDirection="row">
          <Text marginRight={majorScale(2)}>
            {`${coach.user.name.first} has too many Merit sessions right now. Subscribe to get a reminder when they are availabile to talk.`}
          </Text>
        </Pane>
        <Pane alignItems="center" display="flex" justifyContent="space-between" flexDirection="row">
          <Button onClick={() => remindMe({ variables: { coachId: coach?.id } })} appearance="primary">
            <FontAwesomeIcon icon={faCalendar} />
            <Text padding={majorScale(1)} color="white">
              Remind Me
            </Text>
          </Button>
        </Pane>
      </Visible>
      <Hidden xs>
        <Pane alignItems="center" display="flex" justifyContent="space-between" flexDirection="row">
          <Text marginRight={majorScale(2)}>
            {`${coach.user.name.first} has too many Merit sessions right now. Subscribe to get a reminder when they are availabile to talk.`}
          </Text>
          <Button onClick={() => remindMe({ variables: { coachId: coach?.id } })} appearance="primary">
            <FontAwesomeIcon icon={faCalendar} />
            <Text padding={majorScale(1)} color="white">
              Remind Me
            </Text>
          </Button>
        </Pane>
      </Hidden>
    </>
  );
};

const CoachReminderButton = ({ coach }) => {
  const { loading, data, error } = useQuery(CoachList.CoachReminderQuery, {
    variables: {
      coachId: coach?.id
    }
  });

  return (
    <>
      {loading && <Spinner />}
      {error && <Alert intent="danger" title="We weren't able to load this. please refresh!" />}
      {data && !data?.coachReminder && <RemindMeButton coach={coach} />}
      {data && data?.coachReminder && (
        <Button disabled>
          <FontAwesomeIcon icon={faCalendar} />
          <Text padding={majorScale(1)}>Reminder Set</Text>
        </Button>
      )}
    </>
  );
};

export default CoachReminderButton;
