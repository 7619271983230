import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Pane, Spinner } from "evergreen-ui";

import ListEnd from "../ListEnd";
import PostCard from "./PostCard";

const END_MSG = "No more posts available.";

class PostIndex extends React.Component {
  render() {
    const { myProfile, posts, next } = this.props;

    const postComponent = (post) =>
      post && post.id && post.owner && post.owner.id ? (
        <PostCard post={post} key={`PostIndex-PostCard-${post.id}`} myProfile={myProfile} />
      ) : null;

    const footer = (
      <Pane width="100%" alignItems="center">
        <ListEnd message={END_MSG} />
      </Pane>
    );

    return posts && posts.edges ? (
      <InfiniteScroll
        style={{ display: "flex", flexWrap: "wrap" }}
        next={posts.pageInfo.nextPage}
        hasMore={posts.pageInfo.hasNextPage}
        dataLength={posts.edges.length}
        loader={<Spinner />}
        endMessage={footer}
        next={() => next()}
      >
        {posts.edges.map(postComponent)}
      </InfiniteScroll>
    ) : (
      <Spinner />
    );
  }
}

export default PostIndex;
