import React from "react";
import injectSheet from "react-jss";

import { Row, Col, Hidden, Visible } from "react-grid-system";

import theme from "../theme";

import { Pane } from "evergreen-ui";

const styles = {
  icon: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: 120,
    margin: theme.spacing.grid
  },
  h3: {
    ...theme.h3,
    textAlign: "center",
    margin: 0,
    padding: theme.spacing.grid,
    paddingBottom: 0
  },
  h2: {
    ...theme.h2,
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid * 4
  },
  h2Mobile: {
    ...theme.h2Mobile,
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid * 4
  },
  p: {
    ...theme.p,
    textAlign: "center",
    margin: 0,
    padding: theme.spacing.grid * 0.25
  },
  row: {
    paddingBottom: theme.spacing.grid * 3,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid
  }
};

const SectionList = ({ classes, items, title, mobile, children }) => {
  return (
    <Row align="start" justify="center" className={classes.row}>
      {title ? (
        <Col md={12}>
          <Hidden xs md>
            <div className={classes.h2}>{title}</div>
          </Hidden>
          <Visible xs md>
            <div className={classes.h2Mobile}>{title}</div>
          </Visible>
        </Col>
      ) : null}
      {items.map((item) => (
        <Col md={3} lg={4}>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingTop={theme.spacing.grid * 4}
            alignItems="center"
          >
            <img src={item.heroShot} className={classes.icon} alt="icon" />
            <div className={classes.h3}>{item.title}</div>
            <div className={classes.p}>{item.description}</div>
          </Pane>
        </Col>
      ))}
    </Row>
  );
};

export default injectSheet(styles)(SectionList);
