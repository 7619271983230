import React, { useEffect } from "react";
import { Row, Col, Container, ScreenClassRender, Hidden } from "react-grid-system";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Heading, Pane, majorScale, defaultTheme as theme } from "evergreen-ui";

import ProfileList from "../../../queries/profile/ProfileList";
import { LearnerGuide } from "../../../components/session/tutorial/LearnerTutorialSteps";
import BackButton from "../../../components/BackButton";

const FinishedReading = ({ history }) => {
  const { data: myProfile } = useQuery(ProfileList.MyProfileQuery);
  const [readBestPractices] = useMutation(ProfileList.ReadMemberBestPractices, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }],
    onCompleted: () => {
      history.goBack && history.length > 2 && history.goBack();
    }
  });

  let content;

  if (myProfile) {
    content = !myProfile?.myProfile?.bestPractices ? (
      <Button appearance="primary" onClick={readBestPractices}>
        Finished Reading
      </Button>
    ) : null;
  }

  return <>{content}</>;
};

const LearnerTutorialPage = ({ history }) => {
  useEffect(() => {
    window.analytics.track("View Learner Tutorial");
    window.analytics.track("View Member Tutorial");
  });

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <Hidden xs>
        <Col sm={2} md={2} lg={2} xs={12}>
          <BackButton />
        </Col>
      </Hidden>
      <Col lg={8} md={8} sm={8} xs={12}>
        <Pane justifyContent="center" display="flex" flexDirection="column" paddingBottom={majorScale(6)}>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            marginTop={majorScale(4)}
            marginBottom={majorScale(1)}
          >
            <Heading size={700} marginRight={majorScale(1)}>
              Mentorship Session Best Practices
            </Heading>
            <FinishedReading history={history} />
          </Pane>
          {LearnerGuide.map((step, index) => (
            <Pane
              display="flex"
              flexDirection="column"
              width="100%"
              padding={majorScale(4)}
              borderRadius={majorScale(2)}
              border={`1px solid ${theme.colors.gray500}`}
              backgroundColor="white"
              minHeight="100px"
              marginTop={majorScale(2)}
            >
              {step}
            </Pane>
          ))}
        </Pane>
      </Col>
    </div>
  );
};

export default LearnerTutorialPage;
