import _ from "lodash";
import React, { useState } from "react";
import { Row, Col, Container } from "react-grid-system";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import { Link as BrowserLink } from "react-router-dom";
import { Alert, Card, Heading, Pane, majorScale, toaster, Button, ArrowTopRightIcon } from "evergreen-ui";

import Loader from "../../components/Loader";
import BackButton from "../../components/button/BackButton";
import ProfileList from "../../queries/profile/ProfileList";

import ConnectCalendar from "../../components/onboarding/coach/ConnectCalendar";
import ChooseYourVideo from "../../components/onboarding/coach/ChooseYourVideo";

const ERROR_MSG = "We were not able to load this page. Please try again.";

const ONBOARDING_TITLE = "🗓 Customize Your Calendar";
const SETUP_COMPLETE = "Finished calendar setup.";
const VIEW_MY_CALENADR = "View my calendar";

const CoachOnboardingSteps = [
  {
    title: "⏰ Set your office hours",
    Component: ConnectCalendar
  }
];

const getCurrentStep = ({ match: { params } }) => {
  return params.step ? parseInt(params.step) : 0;
};

const renderLeftPanel = ({ history, match }) => {
  const currentStep = getCurrentStep({ match });

  if (history.length > 2 && !_.isEqual(currentStep, 0)) {
    return (
      <Pane display="flex" justifyContent="flex-end" padding={majorScale(2)}>
        <BackButton
          marginBottom={majorScale(2)}
          onClick={() => {
            history.goBack();
          }}
        />
      </Pane>
    );
  } else {
    return null;
  }
};

const CalendarOnboardingPage = (props) => {
  const [redirect, setRedirect] = useState(null);
  const { data, loading, error } = useQuery(ProfileList.MyCoachingProfile);

  const leftPanel = renderLeftPanel({ ...props });
  const currentStep = getCurrentStep(props);

  if (_.isNil(redirect) && props.location.search && props.location.search) {
    if (props.location.search.length > 0) {
      const { origin } = queryString.parse(props.location.search);

      setRedirect(origin);
    }
  }

  const { Component: OnboardingStep } = CoachOnboardingSteps[currentStep];
  const isLastStep = _.isEqual(currentStep, CoachOnboardingSteps.length - 1);

  let id = "";
  if (data) {
    id = data.myProfile.id;
  }

  return (
    <Container>
      <Row align="start" justify="start">
        <Col xs={12} sm={3} md={3} lg={3}>
          {leftPanel}
        </Col>
        <Col xs={12} sm={9} md={9} lg={6}>
          <Pane minHeight="60vh" display="flex" flexDirection="column">
            <Card display="flex" elevation={2} border={2} flexDirection="column" padding={majorScale(4)} marginBottom={majorScale(4)}>
              <Pane display="flex" justifyContent="space-between" alignItems="center">
                <Heading size={600}>{ONBOARDING_TITLE}</Heading>
                <Button is={BrowserLink} iconBefore="ArrowTopRightIcon" to={`/calendar/${id}`} appearance="minimal" target="_blank">
                  {VIEW_MY_CALENADR}
                </Button>
              </Pane>
              <OnboardingStep
                {...data}
                {...props}
                handleNextStep={(result) => {
                  if (isLastStep) {
                    const nextPage = redirect && redirect.length ? redirect : `/mentor/setup`;

                    toaster.success(SETUP_COMPLETE, { duration: 2 });

                    props.history.push(nextPage);
                  } else {
                    let url = `/mentor/setup/calendar/${currentStep + 1}`;

                    if (result && result.editToken && !_.isEmpty(result.editToken)) {
                      url = `${url}?editToken=${result.editToken}`;
                    }

                    props.history.push(url);
                  }
                }}
              />
            </Card>
          </Pane>
        </Col>
      </Row>
    </Container>
  );
};

export default CalendarOnboardingPage;
