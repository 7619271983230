import Layout from "../../components/layout/Layout";
import withRoutes from "../withRoutes";
import ConnectionPage from "./ConnectionPage.js";
import ConnectionDetailPage from "./ConnectionDetailPage";
import ConnectionRecommendationForm from "./ConnectionRecommendationForm";

export default withRoutes(Layout, [
  {
    path: "/connections",
    component: ConnectionPage,
    state: "Connections",
    privateRoute: true,
    reviewRoute: true
  },
  {
    path: "/recommend/:id",
    component: ConnectionRecommendationForm,
    state: "Connections",
    privateRoute: true,
    reviewRoute: true
  },
  {
    path: "/connection/:id",
    component: ConnectionDetailPage,
    state: "Connections",
    privateRoute: true,
    reviewRoute: true
  }
]);
