import React, { useState } from "react";
import { Popover, Button, Card, Pane, Text, majorScale, minorScale, PlusIcon } from "evergreen-ui";

import Uploader from "./Uploader";

const FileUploader = ({ onChange, title, hasToggle }) => {
  const [disableClose, setDisableClose] = useState(false);

  return (
    <Popover
      shouldCloseOnExternalClick={disableClose}
      content={
        <Card background="white" width={320}>
          <Pane paddingY={majorScale(4)} paddingX={majorScale(2)} display="flex" flexDirection="column" alignItems="center">
            <>
              <Uploader
                onUploadComplete={({ cdnUrl, name }) => {
                  setDisableClose(true);
                  onChange({ url: cdnUrl, filename: name });
                }}
              />
            </>
            <Text fontSize="12px" marginTop={minorScale(3)}>
              Preferred formats are .pdf, .doc, or .docx.
            </Text>
          </Pane>
        </Card>
      }
    >
      <Button type="button" appearance="minimal" iconBefore={PlusIcon}>
        {title || "Upload a file"}
      </Button>
    </Popover>
  );
};

export default FileUploader;
