import _ from "lodash";
import React, { useState } from "react";
import { Col, Row, Container, ScreenClassRender } from "react-grid-system";
import { ArrowLeftIcon, Button, Pane, majorScale } from "evergreen-ui";

const Breadcrumb = ({ history }) =>
  history?.goBack &&
  history.length > 2 && (
    <ScreenClassRender
      render={(screenClass) => (
        <Row>
          <Pane width="100%" height={50} background="#EBF0FE" marginTop={-majorScale(3)} marginBottom={majorScale(8)}>
            <Container style={{ height: "100%" }}>
              <Row style={{ height: "100%" }}>
                <Col xs={12} sm={3} md={3}>
                  <Pane height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="start">
                    <Button height={48} color="#1E1EC6" iconBefore={ArrowLeftIcon} appearance="minimal" onClick={() => history.goBack()}>
                      Back
                    </Button>
                  </Pane>
                </Col>
              </Row>
            </Container>
          </Pane>
        </Row>
      )}
    />
  );

export default Breadcrumb;
