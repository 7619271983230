import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Col, Hidden, ScreenClassRender } from "react-grid-system";
import { Pane, Paragraph, Heading, Spinner, majorScale, minorScale } from "evergreen-ui";

import MemberCard from "./components/MemberCard";
import FeedQueries from "../../queries/feed";
import BackButton from "../../components/BackButton";

const RecommendationDetailPage = ({ history, match }) => {
  const id = match?.params?.id;

  const query = {
    cursor: "1",
    sort: "RECENT",
    comments: 0
  };

  const { data: postResult, loading } = useQuery(FeedQueries.queries.PostById, {
    variables: { id }
  });

  const { refetch } = useQuery(FeedQueries.queries.FeedQuery, {
    variables: query
  });

  useEffect(() => window.analytics.track("View Post Detail", { id }));

  return (
    <>
      <Hidden xs>
        <Col md={2}>
          <BackButton />
        </Col>
      </Hidden>
      <Col md={8}>
        {loading && (
          <Pane
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            marginTop={majorScale(7)}
            marginBottom={majorScale(7)}
          >
            <Spinner />
          </Pane>
        )}
        <Pane marginTop={majorScale(4)}>
          <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(4)}>
            <Heading size={700} marginBottom={minorScale(1)}>
              🖐 &nbsp; Here are folks in tech you can mentor today
            </Heading>
            <Paragraph size={500}>
              Looking for more ways to mentor? Offer a mentorship session! When you do, they'll get your message and your calendar
              availability. It's an easy way to raise your hand to help.
            </Paragraph>
          </Pane>
          <MemberCard
            post={postResult?.postById}
            membersQuery={query}
            refetch={() => refetch({ ...query })}
            myProfile={postResult?.myProfile}
            onUpdate={() => history.push("/members")}
          />
        </Pane>
      </Col>
    </>
  );
};

export default RecommendationDetailPage;
