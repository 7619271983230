import React from "react";
import { Pane, Heading, majorScale } from "evergreen-ui";
import { useQuery } from "@apollo/client";

import MemberCard from "../../discover/components/MemberCard";
import FeedQueries from "../../../queries/feed";

const RecentQuestions = ({ profile, posts, myProfile }) => {
  const query = {
    cursor: "1",
    comments: 0,
    sort: "RECENT"
  };

  const { refetch } = useQuery(FeedQueries.queries.FeedQuery, {
    variables: query
  });

  const showPosts = posts?.edges?.length > 0;

  return (
    <Pane id="questions">
      {showPosts && (
        <Pane width="100%">
          <Heading paddingBottom={majorScale(3)} size={500}>
            Recent Questions
          </Heading>
        </Pane>
      )}
      {showPosts &&
        posts.edges.map((post) => (
          <MemberCard hideTop post={post} membersQuery={query} refetch={() => refetch({ ...query })} myProfile={myProfile} />
        ))}
    </Pane>
  );
};

export default RecentQuestions;
