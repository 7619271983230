import _ from "lodash";
import React from "react";
import { Tab, Pane, Tablist, majorScale, Textarea, toaster, Button, Heading, Text, Card } from "evergreen-ui";

import { Link as BrowserLink } from "react-router-dom";
import { Row, Col } from "react-grid-system";

import CommunityShare from "../../components/share/CommunityShare";
import BackButton from "../../components/button/BackButton";

class SharePage extends React.Component {
  renderLeftPanel() {
    if (this.props.history.length > 2 && !_.isEqual(this.currentStep, 0)) {
      return (
        <Pane display="flex" justifyContent="flex-end" padding={majorScale(2)}>
          <BackButton
            appearance="minimal"
            marginBottom={majorScale(2)}
            onClick={() => {
              this.props.history.goBack();
            }}
          />
        </Pane>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      match: {
        params: { mode }
      }
    } = this.props;
    const fromNav = _.isEqual(mode, "nav");

    return (
      <Row align="start" justify={fromNav ? "start" : "center"}>
        {fromNav ? (
          <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={4}>
            {this.renderLeftPanel()}
          </Col>
        ) : null}
        <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={4}>
          <Pane minHeight="60vh">
            {mode && _.isEqual(mode, "review") ? (
              <CommunityShare redirect={`/feedback/reviews/requests`} redirectBtn="Skip" />
            ) : (
              <CommunityShare />
            )}
          </Pane>
        </Col>
      </Row>
    );
  }
}

export default SharePage;
