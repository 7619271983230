import React from "react";
import { Textarea, Pane, Badge, majorScale } from "evergreen-ui";

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

export default ({ field, form: { touched, errors }, children, ...props }) => (
  <Pane>
    <Textarea minHeight={100} {...field} {...props} />
    {touched[field.name] && errors[field.name] && errorText(errors[field.name])}
    {children ? children : null}
  </Pane>
);
