import React from "react";
import { Helmet } from "react-helmet";
import { Visible } from "react-grid-system";

const Meta = ({ logo, title, subtitle, headline, description }) => {
  const metaTitle = title ? `${title} | Merit` : "Merit";
  const metaDescription = description
    ? description
    : `Connect with a mentor through Merit. Merit connects tech talent to senior leaders to grow their career, craft, and community.`;
  const twitterURL = `@meritmentor`;
  const picture = logo ? logo : "https://ucarecdn.com/f637b3cc-42b2-40d7-a508-ea3b125c6b79/MeritScreen.png";

  return (
    <Helmet>
      <meta charSet="utf-8" />

      <link rel="canonical" href={window.location.href} />
      <title>{metaTitle}</title>
      <meta property="og:type" content="website" />
      <meta name="description" property="og:description" content={metaDescription} />
      <meta name="title" property="og:title" content={metaTitle} />
      <meta name="image" property="og:image" content={picture} />
      <meta name="url" property="og:url" content={window.location.href} />

      <meta name="twitter:card" property="twitter:card" content="summary" />
      <meta name="twitter:site" property="twitter:site" content={twitterURL} />
      <meta name="twitter:title" property="twitter:title" content={metaTitle} />
      <meta name="twitter:description" property="twitter:description" content={metaDescription} />
      <meta name="twitter:image" property="twitter:image" content={picture} />
    </Helmet>
  );
};

export default Meta;
