import _ from "lodash";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faGithub, faFigma } from "@fortawesome/free-brands-svg-icons";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Heading,
  Pane,
  Paragraph,
  PlusIcon,
  MinusIcon,
  CrossIcon,
  RadioGroup,
  Text,
  majorScale,
  minorScale,
  Popover
} from "evergreen-ui";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import CandidateQueries from "../../../queries/candidate";
import ProfileList from "../../../queries/profile/ProfileList";
import TextInput from "../../form/TextInput";
// import Uploader from '../../Uploader';
import FileUploader from "../../FileUploader";

const JOB_ROLES = ["Software Engineering", "Product Management", "Product Design", "Other"];

const CreateCandidateProfileSchema = Yup.object().shape({
  resume: Yup.string().url("Please provide a valid URL"),
  linkedin: Yup.string().url("Please provide a valid URL"),
  portfolio: Yup.string().url("Please provide a valid URL"),
  jobRole: Yup.string().required("Please provide your job role"),
  visaStatus: Yup.string().required("Please provide your visa status")
});

const visaStatuses = [
  { value: "authorized", label: "Authorized and do not need sponsorship" },
  { value: "not_authorized", label: "Not Authorized and will need sponsorship (includes H-1B or similar visas)." }
];

const CreateCandidateProfileForm = ({ title, nextPage, currentStep, totalSteps }) => {
  const [viewMoreLinks, setViewMoreLinks] = useState(false);
  const [createCandidateProfile] = useMutation(CandidateQueries.mutations.CreateCandidateProfile, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results) => {
      if (results?.createCandidateProfile) {
        _.isFunction(nextPage) && nextPage();
      }
    }
  });

  return (
    <Formik
      initialValues={{
        resume: "",
        filename: "",
        linkedin: "",
        github: "",
        jobRole: _.head(JOB_ROLES),
        visaStatus: _.head(visaStatuses)?.value,
        portfolio: ""
      }}
      validationSchema={CreateCandidateProfileSchema}
      onSubmit={(variables, { setsubmitting }) => {
        createCandidateProfile({ variables });
      }}
      render={({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Pane>
            <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={majorScale(4)}>
              <Heading size={600}>{title}</Heading>

              <Heading size={600}>{`${currentStep}/${totalSteps}`}</Heading>
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane>
                <Heading paddingBottom={minorScale(2)}>Your resume</Heading>
                <Paragraph size={200} marginBottom={majorScale(1)}>
                  {
                    "If you have it handy, please upload your resume. We’ll automatically fill in your profile using AI and notify you when it’s done! ✨"
                  }
                </Paragraph>
                {values.filename ? (
                  <Button
                    appearance="minimal"
                    iconBefore={CrossIcon}
                    onClick={() => {
                      setFieldValue("filename", "");
                      setFieldValue("resume", "");
                    }}
                  >
                    {values.filename}
                  </Button>
                ) : (
                  <FileUploader
                    title={"Upload your resume"}
                    onChange={({ url, filename }) => {
                      setFieldValue("filename", filename);
                      setFieldValue("resume", url);
                    }}
                  />
                )}
              </Pane>
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={minorScale(1)}>
                <Heading size={500} paddingBottom={minorScale(2)}>
                  LinkedIn Profile URL
                </Heading>
                <Button
                  is="a"
                  href="https://www.linkedin.com/in/"
                  target="_blank"
                  appearance="minimal"
                  iconBefore={<FontAwesomeIcon icon={faLinkedinIn} />}
                >
                  Find my LinkedIn
                </Button>
              </Pane>
              <Field
                type="text"
                name="linkedin"
                placeholder="https://www.linkedin.com/in/"
                component={TextInput}
                height={majorScale(5)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("linkedin", e.target.value, false);
                }}
              />
              {touched["linkedin"] && errors["linkedin"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["linkedin"]}
                </Badge>
              )}
              {!viewMoreLinks && (
                <Pane>
                  <Button appearance="minimal" iconBefore={PlusIcon} onClick={() => setViewMoreLinks(true)}>
                    {"Add more links (e.g. GitHub, portfolio, etc.)"}
                  </Button>
                </Pane>
              )}
            </Pane>

            {viewMoreLinks && (
              <>
                <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
                  <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={minorScale(1)}>
                    <Heading paddingBottom={minorScale(2)}>GitHub Profile URL</Heading>
                    <Button
                      is="a"
                      href="https://www.github.com/"
                      target="_blank"
                      appearance="minimal"
                      iconBefore={<FontAwesomeIcon icon={faGithub} />}
                    >
                      Find my GitHub
                    </Button>
                  </Pane>
                  <Field
                    type="text"
                    name="github"
                    placeholder="https://www.github.com/abc"
                    component={TextInput}
                    height={majorScale(5)}
                    onBlur={(e) => {
                      e.persist();
                      setFieldValue("github", e.target.value, false);
                    }}
                  />
                  {touched["github"] && errors["github"] && (
                    <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                      {errors["github"]}
                    </Badge>
                  )}
                </Pane>
                <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)}>
                  <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Heading paddingBottom={minorScale(2)}>Your portfolio URL</Heading>
                    <Button
                      is="a"
                      href="https://www.figma.com/"
                      target="_blank"
                      appearance="minimal"
                      iconBefore={<FontAwesomeIcon icon={faFigma} />}
                    >
                      Find a Figma
                    </Button>
                  </Pane>
                  <Field
                    type="text"
                    name="portfolio"
                    placeholder="https://www.figma.com/proto/abc/my-portfolio"
                    component={TextInput}
                    height={majorScale(5)}
                    onBlur={(e) => {
                      e.persist();
                      setFieldValue("portfolio", e.target.value, false);
                    }}
                  />
                  {touched["portfolio"] && errors["portfolio"] && (
                    <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                      {errors["portfolio"]}
                    </Badge>
                  )}
                </Pane>
                <Pane marginBottom={majorScale(3)}>
                  <Button appearance="minimal" iconBefore={MinusIcon} onClick={() => setViewMoreLinks(false)}>
                    {"Hide extra links"}
                  </Button>
                </Pane>
              </>
            )}

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Heading size={500} paddingBottom={minorScale(2)}>
                {"What’s kind of role are you looking for?"}
              </Heading>
              <RadioGroup
                defaultValue={values?.jobRole}
                options={_.map(JOB_ROLES, (value) => ({ value, label: value }))}
                onChange={(e) => setFieldValue("jobRole", e.target.value, false)}
              />
              {touched["jobRole"] && errors["jobRole"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["jobRole"]}
                </Badge>
              )}
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Heading size={500} paddingBottom={minorScale(2)}>
                Are you authorized to work lawfully in the United States?
              </Heading>
              <Pane paddingBottom={minorScale(2)}>
                <Paragraph size={200}>{"We only collect this information to ensure relevant opportunities are shown."}</Paragraph>
              </Pane>
              <RadioGroup
                defaultValue={values?.visaStatus}
                options={visaStatuses}
                onChange={(e) => setFieldValue("visaStatus", e.target.value, false)}
              />
              {touched["visaStatus"] && errors["visaStatus"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["visaStatus"]}
                </Badge>
              )}
            </Pane>

            <Pane display="flex" justifyContent="flex-end">
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                Continue
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default withRouter(CreateCandidateProfileForm);
