import _ from "lodash";
import React, { useState } from "react";
import { Button, Pane, majorScale } from "evergreen-ui";
import { useQuery, useMutation } from "@apollo/client";

import CreateReaction from "./CreateReaction";
import Reaction from "./Reaction";

const ReactionIndex = ({ post, myProfile, comment }) => {
  if (!myProfile) return null;
  if (!post && !comment) return null;

  const obj = comment && comment.reactions ? comment : post;

  return (
    <Pane display="flex" flexDirection="row">
      <Reaction myProfile={myProfile} responses={obj.reactions} post={post} comment={comment} />
    </Pane>
  );
};

export default ReactionIndex;
