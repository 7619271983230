import _ from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Button,
  CalendarIcon,
  PersonIcon,
  CrossIcon,
  SmallTickIcon,
  Pane,
  Heading,
  Paragraph,
  Text,
  majorScale,
  minorScale,
  toaster,
  ChatIcon
} from "evergreen-ui";
import { fromUnixTime } from "date-fns";
import { useMutation } from "@apollo/client";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";
import { Link as BrowserLink } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import HoverLink from "../../components/HoverLink";
import SessionQueries from "../../queries/session";
import TextArea from "../form/TextArea";

const otherUser = ({ owner, user, myProfile }) => {
  return _.isEqual(myProfile?.id, user?.id) ? owner : user;
};

const formatRequestDate = (date, timeZone) => {
  timeZone = timeZone?.length ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eee LLLL do");
  const time = formatInTimeZone(zonedDate, timeZone, "hh:mm b");

  return `${day} at ${time}`;
};

const formatSessionDate = (date, timeZone) => {
  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(fromUnixTime(Number(date) / 1000), timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eeee LLLL d");
  const time = formatInTimeZone(zonedDate, timeZone, "h:mm b (z)");

  return `${day} at ${time}`;
};

const ProfileOverview = ({ myProfile, request }) => {
  const profile = otherUser({ ...request, myProfile });
  const title = profile?.role?.team?.name;
  let role = profile?.role?.title ? `${profile?.role?.title}` : null;
  role = title ? `${role} at ${title}` : role;

  const size = minorScale(15);

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={majorScale(3)}>
      <HoverLink to={`/p/${profile?.slug}`} samePage>
        <Pane display="flex">
          <Pane display="flex" flexDirection="row" marginRight={majorScale(2)}>
            <Avatar src={profile?.picture} name={profile?.fullName} size={size} />
          </Pane>
          <Pane display="flex" flexDirection="column" justifyContent="center" height={size}>
            <Heading>{profile?.fullName}</Heading>
            {role?.length > 0 && <Text>{role}</Text>}
          </Pane>
        </Pane>
      </HoverLink>
      <Button is={BrowserLink} to={`/p/${profile?.slug}`} appearance="minimal" iconBefore={PersonIcon}>
        View Profile
      </Button>
    </Pane>
  );
};

const SessionResponseForm = ({ respond, request, setShowForm, loading }) => {
  return (
    <Formik
      initialValues={{
        response: ""
      }}
      validationSchema={Yup.object().shape({
        response: Yup.string()
      })}
      onSubmit={({ response }, { setSubmitting }) => {
        respond({
          variables: {
            id: request?.id,
            status: "Pending",
            response
          }
        });

        setSubmitting(false);
        setShowForm(false);
      }}
      render={({ isSubmitting }) => (
        <Form>
          <Pane display="flex" flexDirection="column" width="100%">
            <Pane marginBottom={majorScale(1)}>
              <Heading size={400}>Please let them know when would be a better time</Heading>
              <Paragraph>We’ll notify them and provide your availability to choose a different time for this meeting.</Paragraph>
            </Pane>
            <Field
              type="text"
              name="response"
              placeholder="For example: Happy to chat! Would talking the following Monday or Tuesday work for you? That would work better for my schedule next week. Thanks!"
              component={TextArea}
            />
            <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%" marginTop={majorScale(1)}>
              <Button type="button" iconBefore={CrossIcon} isLoading={loading} onClick={() => setShowForm(false)}>
                Cancel
              </Button>
              <Button type="submit" appearance="primary" iconBefore={ChatIcon} disabled={isSubmitting}>
                Send response
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

// As the person being requested, I'm looking at an pending request
// Either accept the request or accept it
const ReceiverActions = ({ request, onCompleted }) => {
  const [showForm, setShowForm] = useState(false);
  const [respond, { loading }] = useMutation(SessionQueries.mutations.RequestResponse, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: SessionQueries.queries.MyReceivedRequests },
      { query: SessionQueries.queries.MySessionRequests },
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "upcoming" }
      },
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "previous" }
      }
    ],
    onCompleted: () => {
      toaster.success("Responded to request.");
      if (_.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });
  console.log({ loading });
  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%">
      {showForm ? (
        <SessionResponseForm respond={respond} request={request} setShowForm={setShowForm} loading={loading} />
      ) : (
        <>
          <Button
            iconBefore={CrossIcon}
            isLoading={loading}
            onClick={() => {
              respond({
                variables: {
                  id: request?.id,
                  status: "Rejected"
                }
              });
            }}
          >
            Dismiss Request
          </Button>
          <Button isLoading={loading} iconBefore={CalendarIcon} onClick={() => setShowForm(true)}>
            Request different time
          </Button>
          <Button
            appearance="primary"
            isLoading={loading}
            iconBefore={SmallTickIcon}
            onClick={() => {
              respond({
                variables: {
                  id: request?.id,
                  status: "Accepted"
                }
              });
            }}
          >
            Accept Request
          </Button>
        </>
      )}
    </Pane>
  );
};

// As the request owner, I'm looking at an approved request
// Either book a session or dismiss the accepted request
const OwnerActions = ({ request, onCompleted }) => {
  const [respond] = useMutation(SessionQueries.mutations.RequestResponse, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: SessionQueries.queries.MyReceivedRequests }, { query: SessionQueries.queries.MySessionRequests }],
    onCompleted: () => {
      toaster.success("Request dismissed.");
      if (_.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });

  return (
    <>
      <Button
        iconBefore={CrossIcon}
        onClick={() =>
          respond({
            variables: {
              id: request?.id,
              status: "Completed"
            }
          })
        }
      >
        Ignore Request
      </Button>
      <Button appearance="primary" iconBefore={CalendarIcon} is={BrowserLink} to={`/request/${request?.user?.id}`}>
        Book Session
      </Button>
    </>
  );
};

const RequestCard = ({ myProfile, request, onCompleted }) => {
  const formattedDate = formatRequestDate(request?.createdAt, myProfile?.timezone);
  const { owner, user, date } = request;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      borderRadius={majorScale(2)}
      marginBottom={majorScale(4)}
      paddingTop={majorScale(4)}
    >
      <ProfileOverview request={request} myProfile={myProfile} />
      <Pane display="flex" flexDirection="column" width="100%" marginBottom={majorScale(3)}>
        {date && (
          <>
            <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
              <CalendarIcon color="#101840" size={majorScale(2)} marginRight={majorScale(1)} />
              <Heading size={400}>{`Requested for ${formatSessionDate(request?.date, myProfile?.timezone)}`}</Heading>
            </Pane>
            <Heading size={200} marginBottom={majorScale(3)}>
              After you accept this request, we'll send you both a calendar invitation for this time.
            </Heading>
          </>
        )}
        <Paragraph marginBottom={majorScale(1)}>{request?.message}</Paragraph>
        {!date && <Heading size={200}>{`Sent ${formattedDate}`}</Heading>}
      </Pane>
      <Pane display="flex" flexDirection="row" width="100%" justifyContent="space-between">
        {_.isEqual(myProfile?.id, owner?.id) ? (
          <OwnerActions owner={owner} user={user} request={request} onCompleted={onCompleted} />
        ) : (
          <ReceiverActions owner={owner} user={user} request={request} onCompleted={onCompleted} />
        )}
      </Pane>
    </Pane>
  );
};

export default RequestCard;
