import React from "react";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";
// import TopicIndexPage from "./TopicIndexPage"
import NewTopicIndexPage from "./NewTopicIndexPage";
import TopicDetailPage from "./TopicDetailPage";

export default withRoutes(Layout, [
  {
    path: "/topics/all",
    component: NewTopicIndexPage,
    state: "Topics"
  },
  {
    path: "/new-topics/all",
    component: NewTopicIndexPage,
    state: "Topics"
  },
  {
    path: "/q/:slug",
    component: TopicDetailPage,
    start: "Topics"
  }
]);
