import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet";
import { Col, Row, Container } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Link as BrowserLink } from "react-router-dom";
import { Button, Heading, Pane, Paragraph, Spinner, majorScale, minorScale } from "evergreen-ui";

import TopicQueries from "../../queries/topic";
import FeedQueries from "../../queries/feed";
import Loader from "../../components/Loader";
import DiscussionTopicIndex from "../../components/discussion/DiscussionTopicIndex";
import DiscussionAbout from "../../components/discussion/DiscussionAbout";
import DiscussionItem from "../../components/discussion/DiscussionItem";
import DiscussionCreate from "../../components/discussion/DiscussionCreate";
import FollowTopic from "../../components/topic/FollowTopic";
import MentorCard from "../../components/mentor/MentorCard";
import Breadcrumb from "../../components/Breadcrumb";

const Meta = ({ topic }) => {
  const title = _.join([`${topic?.name}`, "Merit"], " | ");
  const description = `${topic?.description}`;
  const twitterURL = `@meritmentor`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:description" property="og:description" content={description} />
      <meta name="og:url" property="og:url" content={window.location.href} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitterURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

const withoutEmoji = (str) => {
  return str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ""
  );
};

const TopicHeader = ({ topic, history, myProfile }) => {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      backgroundColor="#EBF0FE"
      borderRadius={6}
      padding={majorScale(4)}
      marginBottom={majorScale(4)}
    >
      <Pane display="flex" flexDirection="row" width="100%" alignItems="center" marginBottom={majorScale(2)}>
        <Pane
          background="white"
          width={minorScale(9)}
          height={minorScale(9)}
          borderRadius={minorScale(1)}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          marginRight={majorScale(2)}
        >
          <Heading size={700}>{String.fromCodePoint(topic?.name.codePointAt(0))}</Heading>
        </Pane>
        <Heading size={700} is="h1">
          {_.trim(withoutEmoji(topic?.name))}
        </Heading>
      </Pane>
      <Pane>
        <Paragraph size={400} marginBottom={majorScale(2)}>
          {topic.description}
        </Paragraph>
      </Pane>
      <Pane>
        <FollowTopic myProfile={myProfile} topic={topic} />
      </Pane>
    </Pane>
  );
};

const TopicMentorsIndex = ({ topicBySlug, maxShown, seeMore }) => {
  const preview = maxShown || 3;

  const { data: mentorsByTopic } = useQuery(TopicQueries.MentorsByTopic, {
    variables: {
      cursor: "1",
      topics: [topicBySlug?.id],
      name: ""
    }
  });

  let content = <Loader />;

  if (mentorsByTopic?.leaders?.edges?.length) {
    content = (
      <Pane display="flex" flexDirection="column">
        <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Heading size={700}>Relevant Mentors</Heading>
          <Button is={BrowserLink} to={`/mentors?cursor=1&topics=${topicBySlug?.id}`} color="#1E1EC6" appearance="minimal">
            {`See More (${mentorsByTopic.leaders.pageInfo.edgeCount})`}
          </Button>
        </Pane>
        <Pane display="flex" flexDirection="row" justifyContent="space-between" paddingTop={majorScale(3)} flexWrap="wrap">
          {_.take(mentorsByTopic.leaders.edges, preview).map((leader) => (
            <Pane maxWidth={224} marginBottom={majorScale(5)}>
              <MentorCard key={`TopicMentorsIndex-${leader.id}`} user={leader.user} nextAvailable={leader.nextAvailable} />
            </Pane>
          ))}
        </Pane>
        {mentorsByTopic?.leaders?.edges?.length > 0 && seeMore && (
          <Pane width="100%" display="flex" alignItems="center" justifyContent="center">
            <Button is={BrowserLink} to={`/mentors?cursor=1&topics=${topicBySlug?.id}`} color="#1E1EC6" appearance="minimal">
              {`See More (${mentorsByTopic.leaders.pageInfo.edgeCount})`}
            </Button>
          </Pane>
        )}
      </Pane>
    );
  }

  return content;
};

const TopicDiscussionsIndex = ({ topicBySlug, history }) => {
  const PIVOT = 2;
  const variables = { cursor: "1", topic: topicBySlug?.id };

  const { data: postsByTopic, fetchMore } = useQuery(TopicQueries.PostsByTopic, { variables });

  let posts,
    myProfile,
    next = null;
  let first;
  let rest;

  let content = (
    <Pane display="flex" flexDirection="column">
      <TopicMentorsIndex seeMore maxShown={6} topicBySlug={topicBySlug} />
    </Pane>
  );

  if (postsByTopic?.posts?.edges?.length) {
    posts = postsByTopic.posts;
    myProfile = postsByTopic.myProfile;

    first = posts?.edges?.slice(0, PIVOT);
    rest = posts?.edges?.length > PIVOT ? _.drop(posts?.edges, PIVOT) : null;

    next = () =>
      fetchMore({
        query: TopicQueries.PostsByTopic,
        variables: {
          ...variables,
          cursor: posts.pageInfo.nextPage
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            posts: {
              __typename: fetchMoreResult.posts.__typename,
              pageInfo: fetchMoreResult.posts.pageInfo,
              edges: [...prev.posts.edges, ...fetchMoreResult.posts.edges]
            }
          });
        }
      });

    content = (
      <Pane display="flex" flexDirection="column">
        <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Heading size={700}>Relevant Discussions</Heading>
          <DiscussionCreate myProfile={myProfile} history={history} topic={topicBySlug} />
        </Pane>
        <Pane display="flex" flexDirection="column" paddingTop={majorScale(3)}>
          {first?.length > 0 &&
            first?.map((post) => <DiscussionItem post={post} key={`DiscussionIndex-DiscussionItem-${post?.id}`} myProfile={myProfile} />)}
        </Pane>
        <TopicMentorsIndex topicBySlug={topicBySlug} />
        <Pane display="flex" flexDirection="column" paddingTop={majorScale(3)}>
          <InfiniteScroll
            style={{ display: "flex", flexWrap: "wrap" }}
            hasMore={posts.pageInfo.hasNextPage}
            dataLength={posts.edges.length}
            loader={Loader}
            next={() => next()}
          >
            {rest?.map((post) => (
              <DiscussionItem post={post} key={`DiscussionIndex-DiscussionItem-${post?.id}`} myProfile={myProfile} />
            ))}
          </InfiniteScroll>
        </Pane>
      </Pane>
    );
  }

  return content;
};

const TopicDetailPage = ({ match, history }) => {
  const slug = match?.params?.slug;

  const { data: topicResult, loading } = useQuery(TopicQueries.TopicBySlug, {
    variables: { slug }
  });

  const { data: postsByTopic } = useQuery(FeedQueries.queries.PostTopics);

  return (
    <>
      <Meta topic={topicResult?.topicBySlug} />
      <Breadcrumb history={history} />
      <Container>
        <Row>
          <Col xs={12} sm={8} md={8} lg={8}>
            {loading && (
              <Pane
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                marginTop={majorScale(7)}
                marginBottom={majorScale(7)}
              >
                <Spinner />
              </Pane>
            )}
            {!loading && topicResult && topicResult?.topicBySlug && (
              <TopicDiscussionsIndex history={history} topicBySlug={topicResult.topicBySlug} />
            )}
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            {topicResult && topicResult?.topicBySlug && (
              <TopicHeader topic={topicResult?.topicBySlug} history={history} myProfile={topicResult?.myProfile} />
            )}
            <DiscussionAbout />
            {postsByTopic && <DiscussionTopicIndex postTopics={postsByTopic?.postTopics} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TopicDetailPage;
