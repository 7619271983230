import _ from "lodash";
import React from "react";
import { format } from "date-fns-tz";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  ChatIcon,
  CrownIcon,
  Heading,
  HeartIcon,
  MapMarkerIcon,
  Pane,
  Paragraph,
  Text,
  defaultTheme,
  majorScale,
  minorScale,
  CalendarIcon,
  StarIcon,
  StarEmptyIcon,
  BriefcaseIcon
} from "evergreen-ui";

import { Link as BrowserLink } from "react-router-dom";
import HoverLink from "../HoverLink";
import { SkillSection } from "../../routes/profile/components/ExpertiseSection";
import { AVATAR_LOGO } from "../profile/AutoFill";
import CoachList from "../../routes/coach/CoachList";
import { useMutation } from "@apollo/client";

const renderDate = (start) => {
  const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();

  let dayStr, timeStr;

  try {
    dayStr = format(new Date(start), "ccc MMM do", { timeZone }); // Tue Oct 28th
    timeStr = format(new Date(start), "h:mm bb", { timeZone }); // 2 pm
  } catch (err) {
    return ``;
  }

  return `${dayStr} at ${timeStr}`;
};

const roundNumber = (num) => {
  if (num < 10) return `${num}`;
  else {
    num = Math.floor(num / 10) * 10;
    return `${num}+`;
  }
};

const NewMentorCard = ({ mentor, nextAvailable, onUpdate, refetchQueries, isMobile }) => {
  const [updateSave, { loading: isSaving }] = useMutation(
    mentor?.mySave?.status === "Active" ? CoachList.UnsaveMentor : CoachList.SaveMentor,
    {
      awaitRefetchQueries: true,
      refetchQueries,
      onCompleted: () => {
        if (_.isFunction(onUpdate)) {
          onUpdate();
        }
      }
    }
  );

  const user = mentor?.user;

  return (
    <Pane
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      border={`1px solid ${defaultTheme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      marginBottom={majorScale(2)}
      key={`MentorCard-${mentor?.id}`}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(2)}>
        <Pane display="flex" flexDirection="row">
          <HoverLink to={`/p/${user.slug}`}>
            <Avatar name={user?.fullName} src={user?.picture} size={majorScale(8)} marginRight={majorScale(2)} />
          </HoverLink>
          <Pane display="flex" flexDirection="column" justifyContent="center">
            <HoverLink to={`/p/${user.slug}`}>
              <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
                <Heading size={500}>{user?.fullName}</Heading>
                {user?.pronouns?.length > 0 && (
                  <Heading size={500} marginLeft={minorScale(1)}>
                    ({user?.pronouns})
                  </Heading>
                )}
              </Pane>
            </HoverLink>
            <Pane display="flex" flexDirection={isMobile ? "column" : "row"}>
              {user?.place?.formatted?.length > 0 && (
                <Pane display="flex" flexDirection="row" marginRight={majorScale(1)} marginBottom={isMobile ? majorScale(1) : undefined}>
                  <MapMarkerIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                  <Heading size={400}>{user?.place?.formatted}</Heading>
                </Pane>
              )}
              <Pane display="flex" flexDirection="row" marginRight={majorScale(1)} marginBottom={isMobile ? majorScale(1) : undefined}>
                <CrownIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                <Heading size={400}>
                  {mentor?.totalSessions > 0
                    ? `${roundNumber(mentor?.totalSessions)} session${mentor?.totalSessions > 1 ? `s` : ``}`
                    : "New mentor"}
                </Heading>
              </Pane>
              {mentor?.totalReviews > 0 && (
                <Pane display="flex" flexDirection="row" marginRight={majorScale(1)}>
                  <HeartIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                  <Heading size={400}>{`${roundNumber(mentor?.totalReviews)} feedback`}</Heading>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Button appearance="minimal" is="a" href={user?.linkedin} target="_blank">
            <FontAwesomeIcon size="md" icon={faLinkedinIn} />
          </Button>
        </Pane>
      </Pane>
      <Pane marginBottom={majorScale(1)}>
        <Pane display="flex" flexDirection="row">
          {isMobile ? (
            <Pane display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start">
              <Pane display="flex" flexDirection="row" justifyContent="flex-start">
                <Avatar
                  shape="square"
                  size={majorScale(2)}
                  marginRight={minorScale(1)}
                  src={user?.role?.team?.logo?.length > 0 ? user?.role?.team?.logo : AVATAR_LOGO}
                />
                <Heading size={400}>{user?.role?.team?.name}</Heading>
              </Pane>
              <Paragraph>
                {[user?.role?.companySize, user?.role?.companyIndustry].filter((i) => _.isString(i) && i.length > 0).join(" · ")}
              </Paragraph>
            </Pane>
          ) : (
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <Avatar
                shape="square"
                size={majorScale(2)}
                marginRight={minorScale(1)}
                src={user?.role?.team?.logo?.length > 0 ? user?.role?.team?.logo : AVATAR_LOGO}
              />
              <Heading size={400}>{user?.role?.team?.name}</Heading>
              <Paragraph marginLeft={majorScale(1)}>
                {[user?.role?.companySize, user?.role?.companyIndustry].filter((i) => _.isString(i) && i.length > 0).join(" · ")}
              </Paragraph>
            </Pane>
          )}
        </Pane>
      </Pane>
      <Pane marginBottom={majorScale(1)}>
        {isMobile ? (
          <Pane display="flex" flexDirection="column">
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="start">
              <BriefcaseIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
              <Heading size={400}>{user?.jobRoles[0]}</Heading>
            </Pane>
            <Pane display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start">
              <Paragraph>{[user?.role?.title, user?.level].filter((i) => _.isString(i) && i.length > 0).join(" · ")}</Paragraph>
            </Pane>
          </Pane>
        ) : (
          <Pane display="flex" flexDirection="row">
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <BriefcaseIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
              <Heading size={400}>{user?.jobRoles[0]}</Heading>
              <Paragraph marginLeft={majorScale(1)}>
                {[user?.role?.title, user?.level].filter((i) => _.isString(i) && i.length > 0).join(" · ")}
              </Paragraph>
            </Pane>
          </Pane>
        )}
      </Pane>
      {mentor?.user?.candidate?.specialities.length > 0 && <SkillSection small={true} myCandidateProfile={mentor?.user?.candidate} />}
      <Pane marginBottom={majorScale(2)}>
        {isMobile ? (
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)}>
            {mentor?.playlists.length > 0 && (
              <>
                <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                  <ChatIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                  <Heading size={400}>Talk to me about</Heading>
                </Pane>

                {_.take(mentor?.playlists, 2).map((p) => (
                  <Text marginLeft={majorScale(1)}>{p.name}</Text>
                ))}
              </>
            )}
          </Pane>
        ) : (
          <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
            {mentor?.playlists.length > 0 && (
              <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <ChatIcon color={defaultTheme.colors.gray600} marginRight={minorScale(1)} />
                <Heading size={400}>Talk to me about</Heading>
                {_.take(mentor?.playlists, 2).map((p) => (
                  <Text marginLeft={majorScale(1)}>{p.name}</Text>
                ))}
              </Pane>
            )}
          </Pane>
        )}
        <Paragraph>{user?.bio}</Paragraph>
        <Paragraph>{mentor?.reason}</Paragraph>
      </Pane>
      <Pane
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={nextAvailable?.start ? "space-between" : "flex-end"}
        alignItems="center"
      >
        {nextAvailable?.start && (
          <Pane display="flex" flexDirection="row">
            <CalendarIcon marginRight={minorScale(1)} color={defaultTheme.colors.gray600} />
            <Heading size={400}>{`Next available ${renderDate(nextAvailable.start)}`}</Heading>
          </Pane>
        )}
        <Pane
          display={isMobile ? "flex" : undefined}
          width={isMobile ? "100%" : undefined}
          justifyContent={isMobile ? "space-between" : undefined}
        >
          <Button
            isLoading={isSaving}
            appearance="minimal"
            iconBefore={mentor?.mySave?.status === "Active" ? StarIcon : StarEmptyIcon}
            marginRight={minorScale(1)}
            onClick={() => updateSave({ variables: { mentorId: mentor?.id } })}
          >
            {mentor?.mySave?.status === "Active" ? "Saved" : "Save"}
          </Button>
          <Button appearance="primary" iconBefore={CalendarIcon} is={BrowserLink} to={`/p/${user.slug}`}>
            View calendar
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default NewMentorCard;
