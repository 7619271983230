import React from "react";
import { Heading, Pane, Paragraph, minorScale, majorScale } from "evergreen-ui";

import OpeningHoursForm from "../../../../components/nylas/OpeningHoursForm";

const OPENING_HOURS_INFO = "These are the times you be available to receive sessions.";

const EditOpeningHours = ({ myCoachingProfile }) => {
  return (
    <Pane display="flex" flexDirection="column" paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
      <Heading marginBottom={minorScale(1)}>⏰ &nbsp; When do you want to have sessions?</Heading>
      <Paragraph size={500}>{OPENING_HOURS_INFO}</Paragraph>
      <Pane paddingY={majorScale(1)}>
        <OpeningHoursForm myCoachingProfile={myCoachingProfile} />
      </Pane>
    </Pane>
  );
};

export default EditOpeningHours;
