import _ from "lodash";
import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Alert, Button, Pane, Heading, CalendarIcon, RadioGroup, Spinner, majorScale, toaster, defaultTheme as theme } from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";

import SessionCard from "../../components/session/SessionCard";
import BackButton from "../../components/BackButton";

import SessionQueries from "../../queries/session";

const NO_SHOW_INFO =
  "Select the reason to the best of your knowledge. Knowing why someone didn’t appear to a session helps us ensure a better experience for everyone.";

const NO_SHOW_OPTIONS = [
  {
    label: "Something came up, and they did not reschedule in time",
    value: "LATE_CANCEL"
  },
  {
    label: "They could not access the video conference information",
    value: "TECHINCAL_ISSUE"
  },
  {
    label: "They did not remember when the session was supposed to start",
    value: "FORGOT"
  },
  {
    label: "They thought the session was a different time or date",
    value: "MISCOMMUNICATION"
  },
  {
    label: "We rescheduled to a different time",
    value: "RESCHEDULED"
  },
  {
    label: "I don’t know",
    value: "UNKNOWN"
  }
];

const NoShowForm = ({ myProfile, session, onCompleted }) => {
  const [markNoShow, { loading }] = useMutation(SessionQueries.mutations.MarkNoShow, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "previous" }
      },
      {
        query: SessionQueries.queries.SessionAndReviewById,
        variables: { id: session?.id }
      }
    ],
    onCompleted: () => {
      toaster.success("Session updated.");
      if (onCompleted && _.isFunction(onCompleted)) {
        onCompleted();
      }
    }
  });

  return (
    <Formik
      initialValues={{
        reason: NO_SHOW_OPTIONS[0]?.value,
        sessionId: session?.id
      }}
      onSubmit={(variables, { setSubmitting }) => {
        markNoShow({ variables });
        setSubmitting(false);
      }}
      render={({ values: { reason }, setFieldValue, isSubmitting }) => (
        <Form>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Heading size={400} marginBottom={majorScale(2)}>
                  {NO_SHOW_INFO}
                </Heading>
                <Pane>
                  <RadioGroup
                    value={reason}
                    size={majorScale(2)}
                    options={NO_SHOW_OPTIONS}
                    onChange={(e) => setFieldValue("reason", e.target.value)}
                  />
                </Pane>
                <Pane display="flex" flexDirection="row" justifyContent="flex-end">
                  <Button type="submit" appearance="primary" isLoading={isSubmitting || loading}>
                    Mark No Show
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const NoShowFormContainer = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.SessionAndReviewById, {
    variables: {
      id
    }
  });

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row justify="flex-start" style={{ width: "100%" }}>
          <Col xs={12} sm={2} md={2} lg={2}>
            <BackButton />
          </Col>
          <Col xs={12} sm={8} md={8} lg={8}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom="1px solid #efefef"
              paddingTop={screenClass?.includes("xs") ? null : majorScale(4)}
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={majorScale(2)}>
                <CalendarIcon marginRight={majorScale(1)} size={majorScale(2)} />
                Mark No-Show
              </Heading>
            </Pane>
            {loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Spinner />
              </Pane>
            )}
            {error && !loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Alert title="There was an error loading this page. Please try again in a few moments" />
              </Pane>
            )}
            {sessionAndReview?.sessionById?.id && (
              <>
                <Pane width="100%">
                  <SessionCard myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />
                  <Pane display="flex" marginBottom={majorScale(2)}>
                    <Heading size={600}>{"Do you know why they did not show up?"}</Heading>
                  </Pane>
                  <NoShowForm
                    myProfile={sessionAndReview?.myProfile}
                    session={sessionAndReview?.sessionById}
                    onCompleted={() => history.push(`/sessions/previous`)}
                  />
                </Pane>
              </>
            )}
          </Col>
        </Row>
      )}
    />
  );
};

export default NoShowFormContainer;
