import React from "react";
import { Redirect } from "react-router-dom";

import Layout from "../../components/layout/Layout";
import withRoutes from "../withRoutes";
import ConversationPage from "./ConversationPage.js";

export default withRoutes(Layout, [
  {
    path: "/messages",
    exact: true,
    component: ConversationPage,
    state: "Messages",
    privateRoute: true,
    reviewRoute: true
  },
  {
    path: "/messages/:threadID",
    component: ConversationPage,
    state: "Messages",
    privateRoute: true,
    reviewRoute: true
  },
  {
    path: "/conversations",
    exact: true,
    state: "Conversations",
    privateRoute: true,
    reviewRoute: true,
    component: () => {
      return <Redirect to="/messages" />;
    }
  },
  {
    path: "/conversation/:threadID",
    state: "Conversations",
    privateRoute: true,
    reviewRoute: true,
    component: ({
      match: {
        params: { threadID }
      }
    }) => {
      return <Redirect to={`/messages/${threadID}`} />;
    }
  }
]);
