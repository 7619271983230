import React from "react";
import { Row, Col, Container } from "react-grid-system";
import injectSheet from "react-jss";
import { Helmet } from "react-helmet";

import { Pane, majorScale } from "evergreen-ui";

import theme from "../../components/theme";

import { lightBulb, balloon, leftStarURL, rightStarURL } from "../../content/about";

const styles = {
  h1: {
    ...theme.h1,
    padding: 0,
    margin: 0,
    paddingTop: theme.spacing.grid * 2.5,
    textAlign: "center"
  },
  subtitle: {
    ...theme.b,
    textAlign: "center",
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid * 1.5,
    paddingBottom: theme.spacing.grid * 2
  },
  p: {
    ...theme.p,
    margin: 0,
    padding: 0,
    textAlign: "left"
  },
  h2: {
    ...theme.h2,
    margin: 0,
    padding: 0,
    color: "inherit",
    textDecoration: "inherit",
    textAlign: "left",
    paddingTop: theme.spacing.grid * 2,
    paddingBottom: theme.spacing.grid * 2
  },
  h3: {
    ...theme.h3,
    textAlign: "left",
    margin: 0,
    padding: 0
  },
  h4: {
    ...theme.h4,
    textAlign: "left",
    margin: 0,
    padding: 0,
    paddingTop: majorScale(1),
    paddingBottom: majorScale(2)
  }
};

class SuccessPage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <link href={theme.fonts} rel="stylesheet" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Merit Plus</title>
        </Helmet>
        <Container>
          <Row align="center" justify="center">
            <Col md={12}>
              <Pane marginTop={majorScale(2)} display="flex" alignItems="center" flexDirection="column" minHeight={"100vh"}>
                <Pane display="flex" flexDirection="row">
                  <img src={leftStarURL} />
                  <img src={rightStarURL} />
                </Pane>
                <div className={classes.h1}>Thank you!</div>
                <div className={classes.subtitle}>We will email you shortly to set-up your onboarding to Merit Plus.</div>
                <img src={balloon} />
              </Pane>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default injectSheet(styles)(SuccessPage);
