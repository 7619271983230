import _ from "lodash";
import React from "react";
import { Pane, Paragraph, Heading, CaretDownIcon, Popover, Position, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import { Col, Hidden, Visible } from "react-grid-system";

const TITLE = "Let’s talk about";
const SUBTITLE = "Get personal advice from leaders in your industry";

const ROLES = {
  ALL_TECH: "Tech Careers",
  SOFTWARE: "Software Engineering",
  DESIGN: "Product Design",
  PRODUCT: "Product Management"
};

const Emoji = ({ emoji }) => {
  return (
    <Pane
      width={50}
      height={35}
      background="#F3F6FE"
      borderRadius={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize={12}
    >
      {emoji}
    </Pane>
  );
};

const PageSelect = ({ role, onChangeRole }) => {
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      animationDuration={0}
      statelessProps={{ borderRadius: "12px" }}
      content={({ close }) => (
        <Pane width={320} paddingX={24} paddingY={18} paddingBottom={24}>
          <Pane
            display="flex"
            alignItems="center"
            paddingY={12}
            cursor="pointer"
            onClick={() => {
              onChangeRole(null);
              close();
            }}
          >
            <Emoji emoji="🔭" />
            <Heading marginLeft={16} fontWeight={500}>
              All Tech Careers
            </Heading>
          </Pane>
          <Pane
            display="flex"
            alignItems="center"
            paddingY={12}
            cursor="pointer"
            onClick={() => {
              onChangeRole(ROLES.DESIGN);
              close();
            }}
          >
            <Emoji emoji="💻" />
            <Heading marginLeft={16} fontWeight={500}>
              Product Design
            </Heading>
          </Pane>
          <Pane
            display="flex"
            alignItems="center"
            paddingY={12}
            cursor="pointer"
            onClick={() => {
              onChangeRole(ROLES.PRODUCT);
              close();
            }}
          >
            <Emoji emoji="🎨" />
            <Heading marginLeft={16} fontWeight={500}>
              Product Management
            </Heading>
          </Pane>
          <Pane
            display="flex"
            alignItems="center"
            paddingY={12}
            cursor="pointer"
            onClick={() => {
              onChangeRole(ROLES.SOFTWARE);
              close();
            }}
          >
            <Emoji emoji="📊" />
            <Heading marginLeft={16} fontWeight={500}>
              Software Engineering
            </Heading>
          </Pane>
        </Pane>
      )}
    >
      <Pane display="flex" alignItems="center" justifyContent="space-between" cursor="pointer" borderRadius={999}>
        <Heading className="dm-sans" fontSize={32} color={theme.colors.blue500} lineHeight={1.1}>
          {role}
        </Heading>
        <CaretDownIcon size="24px" marginLeft={minorScale(1)} color={theme.colors.blue500} />
      </Pane>
    </Popover>
  );
};

const HeroSection = ({ kit, role, onChangeRole, myProfile }) => {
  const DEFAULT = "Tech Careers";

  return (
    <Col
      xs={12}
      style={{
        paddingTop: majorScale(4),
        paddingBottom: majorScale(4)
      }}
    >
      <Hidden xs>
        <Pane display="flex" flexDirection="row" width="100%" alignItems="center">
          <Heading size={900} marginRight={majorScale(1)}>
            {kit?.title?.length > 0 ? kit.title : TITLE}
          </Heading>
          <PageSelect role={role || DEFAULT} onChangeRole={onChangeRole} />
        </Pane>
      </Hidden>
      <Visible xs>
        <Pane display="flex" flexDirection="row" width="100%" alignItems="center">
          <Heading size={900} marginRight={majorScale(1)}>
            {kit?.title?.length > 0 ? kit.title : TITLE}
          </Heading>
        </Pane>
        <Pane display="flex" flexDirection="row" width="100%" alignItems="center">
          <PageSelect role={role || DEFAULT} onChangeRole={onChangeRole} />
        </Pane>
      </Visible>
      <Paragraph size={500}>{SUBTITLE}</Paragraph>
    </Col>
  );
};

export default HeroSection;
