import React from "react";
import { Redirect } from "react-router-dom";

import Layout from "../../components/layout/Layout";
// import PlaylistView from "./PlaylistView"
import withRoutes from "../withRoutes";

export default withRoutes(Layout, [
  {
    exact: true,
    path: "/playlists/all",
    component: ({ location: { search } }) => <Redirect to={`/topics/all${search}`} />
  },
  {
    path: "/playlist/:id",
    component: ({
      match: {
        params: { id }
      }
    }) => <Redirect to={`/leaders/all?cursor=1&topics=${id}`} />
  },
  // {
  //   path: "/topic/:id",
  //   component: PlaylistView,
  //   state: "Topics",
  // },
  {
    path: "/t/:slug",
    component: ({
      match: {
        params: { slug }
      }
    }) => <Redirect to={`/topics/all?cursor=1&name=${slug}`} />
  }
]);
