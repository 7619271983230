import React from "react";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import ReactMarkdown from "react-markdown";
import { useQuery } from "@apollo/client";
import { Heading, Pane, Paragraph, Text, OrderedList, UnorderedList, ListItem, majorScale, defaultTheme as theme } from "evergreen-ui";

import { aboutDiscussions, questions, questionHeader, answers, answerHeader } from "../../content/guidelines";

import FeedQueries from "../../queries/feed";
import BackButton from "../../components/BackButton";
import GuidelinesMeta from "./GuidelinesMeta";

const ContentBlock = ({ content }) => (
  <ReactMarkdown
    components={{
      p: ({ children }) => <Paragraph size={500}>{children}</Paragraph>,
      ol: ({ children, ordered }) => {
        const ListFormat = ordered ? OrderedList : UnorderedList;

        return <ListFormat>{children}</ListFormat>;
      },
      li: ListItem
    }}
  >
    {content}
  </ReactMarkdown>
);

const GuidePage = ({ history }) => {
  const { data: postsByTopic } = useQuery(FeedQueries.queries.PostTopics);

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <>
          <GuidelinesMeta />
          <Row
            justify="flex-start"
            style={{
              backgroundColor: theme.colors.gray100,
              minHeight: "calc(100vh - 120px)",
              paddingY: majorScale(4),
              width: width[screenClass]
            }}
          >
            <Col sm={2} md={2} lg={2} xs={12}>
              <BackButton />
            </Col>
            <Col lg={8} md={8} sm={8} xs={12}>
              <Pane
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                marginY={majorScale(4)}
              >
                <Pane display="flex" flexDirection="column" justifyContent="center">
                  <Heading size={800}>About Discussions</Heading>
                </Pane>
              </Pane>
              <Pane
                backgroundColor="white"
                border={`1px solid ${theme.colors.gray500}`}
                borderRadius={majorScale(2)}
                padding={majorScale(2)}
                width="100%"
                marginBottom={majorScale(2)}
              >
                <ContentBlock content={aboutDiscussions} />
              </Pane>
              <Pane
                backgroundColor="white"
                border={`1px solid ${theme.colors.gray500}`}
                borderRadius={majorScale(2)}
                padding={majorScale(2)}
                width="100%"
                marginBottom={majorScale(2)}
              >
                <Heading size={500}>{questionHeader}</Heading>
                <ContentBlock content={questions} />
              </Pane>
              <Pane
                backgroundColor="white"
                border={`1px solid ${theme.colors.gray500}`}
                borderRadius={majorScale(2)}
                padding={majorScale(2)}
                width="100%"
                marginBottom={majorScale(2)}
              >
                <Heading size={500}>{answerHeader}</Heading>
                <ContentBlock content={answers} />
              </Pane>
            </Col>
          </Row>
        </>
      )}
    />
  );
};

export default GuidePage;
