import React from "react";
import { Redirect } from "react-router-dom";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

import SettingsPage from "./SettingsPage";
import TopicsForm from "./TopicsForm";
import SharePage from "./SharePage";

export default withRoutes(Layout, [
  {
    exact: true,
    path: "/settings",
    component: SettingsPage,
    privateRoute: true
  },
  {
    exact: true,
    path: "/share/:mode",
    component: SharePage,
    privateRoute: true
  },
  {
    path: "/settings/:section",
    component: SettingsPage,
    state: "Profile",
    privateRoute: true
  },
  {
    exact: true,
    path: "/choose-topics",
    component: TopicsForm,
    privateRoute: true
  },
  {
    exact: true,
    path: "/add_playlists",
    component: () => {
      return <Redirect to={"/choose-topics"} />;
    }
  }
]);
