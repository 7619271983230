import React, { useState } from "react";
import { Badge, Button, Heading, Pane, SelectMenu, majorScale, toaster } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";

import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import FeedQueries from "../../queries/feed";
import ProfileList from "../../queries/profile/ProfileList";

const PostSchema = Yup.object().shape({
  title: Yup.string().min(1, "Please include a title").max(10000, "Please shorten the title").required("Please provide a title"),
  details: Yup.string().min(1, "Please provide some details").max(1000000, "Please shorten the details"),
  topic: Yup.string()
});

const SUCCESS_TOAST = "Successfully posted.";
const CURRENT_QUESTION = "Do you have a specific career question you want advice on?";
const QUESTION_DETAILS = "Anything else we should know? (Optional)";
const QUESTION_TOPIC = "What topic best applies to this question?";
const SAMPLE_QUESTION = "What does it mean to be a senior product manager?";
const SAMPLE_CONTEXT = "I'm at a series B startup and my product team grew from three to five this year....";

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

const TopicForm = (formProps) => {
  const { availableTopics, initialTopicId, initialTopicTitle, setSelected } = formProps;
  const [topicId, setTopicId] = useState(initialTopicId || "");
  const [topicTitle, setTopicTitle] = useState(initialTopicTitle || "Tag a topic");

  const opts = availableTopics.map(({ id, name }) => ({ value: id, label: name }));

  return (
    <SelectMenu
      options={opts}
      title={topicTitle}
      selected={topicId}
      onSelect={(item) => {
        setTopicId(item.value);
        setTopicTitle(item.label);
        setSelected(item.value);
      }}
      onDeselect={(item) => {
        setTopicId("");
        setTopicTitle("");
      }}
    >
      <Button iconBefore="chat" type="button">
        {topicTitle}
      </Button>
    </SelectMenu>
  );
};

const PostForm = (props) => {
  const { query, isShown, post, onUpdate } = props;
  const { data: optionsResult } = useQuery(ProfileList.TopicOptions);

  const availableTopics = optionsResult?.availableTopics;
  const variables = query ? query : { cursor: "1", sort: "DEFAULT" };

  const [postMutation, { loading }] = useMutation(post?.id ? FeedQueries.mutations.UpdatePost : FeedQueries.mutations.CreatePost, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.FeedQuery, variables },
      { query: FeedQueries.queries.FeedQuery, variables: { cursor: "1", sort: "RECENT" } }
    ],
    onCompleted: (result) => {
      toaster.success(SUCCESS_TOAST, { duration: 2 });
      const updated = result?.updatePost ? result.updatePost : result?.createPost;

      onUpdate(!isShown, updated);
    }
  });

  return availableTopics?.length > 0 ? (
    <Formik
      validationSchema={PostSchema}
      initialValues={{
        id: post?.id ? post.id : undefined,
        title: post?.title ? post.title : "",
        details: post?.details ? post.details : "",
        topic: post?.topic?.id ? post.topic.id : ""
      }}
      onSubmit={(variables, { setSubmitting }) => {
        postMutation({ variables });
        setSubmitting(false);
      }}
      render={({ touched, errors, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Pane>
            {/* Title */}
            <Pane>
              <Heading marginBottom={majorScale(2)}>{CURRENT_QUESTION}</Heading>
              <Field type="text" name="title" placeholder={SAMPLE_QUESTION} component={TextInput} />
            </Pane>

            {/* Details */}
            <Pane>
              <Heading paddingBottom={majorScale(2)}>{QUESTION_DETAILS}</Heading>
              <Field type="text" name="details" placeholder={SAMPLE_CONTEXT} component={TextArea} />
            </Pane>

            <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Heading paddingY={majorScale(2)}>{QUESTION_TOPIC}</Heading>
              <TopicForm
                availableTopics={availableTopics}
                initialTopicId={values.topic}
                initialTopicTitle={post?.topic?.title}
                setSelected={(value) => setFieldValue("topic", value)}
              />
            </Pane>

            {/* Error Messages */}
            <Pane display="flex" justifyContent="space-between" paddingTop={majorScale(2)}>
              {touched["topic"] && errors["topic"] && errorText(errors["topic"])}
            </Pane>

            <Pane
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              marginTop={majorScale(2)}
              marginBottom={majorScale(2)}
            >
              <Button appearance="primary" isLoading={isSubmitting || loading} type="submit" size="large">
                Ask!
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  ) : null;
};

export default PostForm;
