import React from "react";
import _ from "lodash";

import { Avatar, Heading, Pane, majorScale } from "evergreen-ui";
import axios from "axios";

// Determine the backend URL for the frontend. This app can be in three
// states at a given time -- Production, A QA Box, or Local
// QA Box's run with NODE_ENV set to production but with a pre-determined API url
const backupUrl = process.env.NODE_ENV !== "production" ? "http://localhost:3001/" : "https://merit-app-dev.herokuapp.com/";

let BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : backupUrl;

BACKEND_URL = BACKEND_URL.replace("/graphql", "");

export const AVATAR_LOGO = "https://ucarecdn.com/3892b394-7718-4ecf-88bb-d559bb5a2b99/office.svg";

export const createOption = (name, logo, domain) => {
  return {
    value: { name, logo, domain },
    label: (
      <Pane display="flex" alignItems="center">
        <Avatar src={logo?.length > 0 ? logo : AVATAR_LOGO} size={majorScale(3)} />
        <Pane marginLeft={majorScale(2)}>
          <Heading>{`${name}${domain ? ` (${domain})` : ""}`}</Heading>
        </Pane>
      </Pane>
    )
  };
};

export const clearbitOrgOptions = (inputValue, callback) => {
  if (!inputValue?.length > 0) {
    callback(null);
  } else {
    axios.get(`${BACKEND_URL}/v1/companies/suggest?query=${inputValue}`).then((res) => {
      let options;

      // Uses the Clearbit Logo API to find company information
      // [{"name":"Merit","domain":"get-merit.com","logo":"https://logo.clearbit.com/get-merit.com"}]
      options = _.map(res.data, (option) => ({
        value: option,
        label: (
          <Pane display="flex" alignItems="center">
            <Avatar src={option?.logo ? option.logo : AVATAR_LOGO} size={majorScale(3)} />
            <Pane marginLeft={majorScale(2)}>
              <Heading>{`${option.name}${option.domain ? ` (${option.domain})` : ""}`}</Heading>
            </Pane>
          </Pane>
        )
      }));

      // If someone searching for something, and it can't be found
      // Create an option for the company to be created
      let searchCopy = "Search for your organization by name or URL";
      if (inputValue && inputValue.length > 1 && options.length < 1) {
        searchCopy = `${inputValue} (Add your organization)`;
      }

      options.push({
        value: { name: inputValue, domain: "", logo: AVATAR_LOGO },
        label: (
          <Pane display="flex" alignItems="center">
            <Avatar src={AVATAR_LOGO} size={majorScale(3)} />
            <Pane marginLeft={majorScale(2)}>
              <Heading>{searchCopy}</Heading>
            </Pane>
          </Pane>
        )
      });

      // Return the opts to the form field
      callback(options);
    });
  }
};
