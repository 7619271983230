import _ from "lodash";
import React, { useState } from "react";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { Alert, Button, EditIcon, Pane, Text, majorScale } from "evergreen-ui";
import { useQuery } from "@apollo/client";

import EditCandidateDocs from "../../routes/profile/edit/candidate/EditCandidateDocs";
import CandidateQueries from "../../queries/candidate";

const width = {
  xs: 375,
  sm: 740,
  md: 960,
  xl: 1140,
  xxl: 1140
};

const ResumeNotif = () => {
  const [showDocs, setShowDocs] = useState(false);
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile);

  const myCandidateProfile = candidateResult?.myCandidateProfile;
  const isActiveCandidate = _.isEqual(myCandidateProfile?.status, "passive") || _.isEqual(myCandidateProfile?.status, "active");
  const hasResume = candidateResult?.myCandidateProfile?.id && myCandidateProfile?.resume?.url;

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <>
          {isActiveCandidate && !hasResume && (
            <Row
              style={{
                minHeight: 50,
                paddingTop: majorScale(1),
                paddingBottom: majorScale(2),
                marginBottom: majorScale(3),
                flexDirection: "column",
                display: "flex",
                width: width[screenClass]
              }}
            >
              <Col>
                <Alert id="alert" title="Your profile is not visible to recruiters" intent="warning" marginTop={majorScale(2)}>
                  <Pane justifyContent="space-between" alignItems="center" display="flex" flexDirection="row">
                    <Text>Please include a link to your resume or portfolio before activating your candidate profile.</Text>
                    <Button iconBefore={EditIcon} appearance="minimal" onClick={() => setShowDocs(true)}>
                      Upload Resume
                    </Button>
                    <EditCandidateDocs myCandidateProfile={myCandidateProfile} showDocs={showDocs} setShowDocs={setShowDocs} />
                  </Pane>
                </Alert>
              </Col>
            </Row>
          )}
        </>
      )}
    />
  );
};

export default ResumeNotif;
