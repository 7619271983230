export const inNorthAmerica = ({ place }) => {
  const accepted = ["United States", "Canada", "USA"];

  return accepted?.includes(place?.country);
};

export const warningList = ({ place }) => {
  const warned = [
    "India",
    "Nigeria",
    "Ethiopia",
    "Egypt",
    "DR Congo",
    "Tanzania",
    "South Africa",
    "Kenya",
    "Uganda",
    "Algeria",
    "Sudan",
    "Morocco",
    "Angola",
    "Mozambique",
    "Ghana",
    "Madagascar",
    "Cameroon",
    "Côte d'Ivoire",
    "Niger",
    "Burkina Faso",
    "Mali",
    "Malawi",
    "Zambia",
    "Senegal",
    "Chad",
    "Somalia",
    "Zimbabwe",
    "Guinea",
    "Rwanda",
    "Benin",
    "Burundi",
    "Tunisia",
    "South Sudan",
    "Togo",
    "Sierra Leone",
    "Libya",
    "Congo",
    "Liberia",
    "Central African Republic",
    "Mauritania",
    "Eritrea",
    "Namibia",
    "Gambia",
    "Botswana",
    "Gabon",
    "Lesotho",
    "Guinea-Bissau",
    "Equatorial Guinea",
    "Mauritius",
    "Eswatini",
    "Djibouti",
    "Comoros",
    "Cabo Verde",
    "Sao Tome & Principe",
    "Seychelles"
  ];

  return warned?.includes(place?.country);
};
