import React from "react";
import { Button, Dialog, Pane, Heading, Text, Tooltip, InfoSignIcon, majorScale } from "evergreen-ui";

import PostForm from "./PostForm";

const CREATE_POST_TITLE = "🙋🏽 Ask a question";

class PostCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false
    };
  }

  render() {
    const { myProfile, slim } = this.props;

    return !myProfile ? null : (
      <Pane
        padding={slim ? undefined : majorScale(2)}
        justifyContent="flex-end"
        display="flex"
        onClick={() => this.setState({ isShown: true })}
      >
        <Dialog
          title={CREATE_POST_TITLE}
          isShown={this.state.isShown}
          onCloseComplete={() => this.setState({ isShown: false })}
          preventBodyScrolling
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
          hasFooter={false}
        >
          {this.state.isShown ? <PostForm myProfile={myProfile} onUpdate={(isShown) => this.setState({ isShown })} /> : null}
        </Dialog>
        <Button
          appearance="primary"
          size={slim ? undefined : "large"}
          onClick={() => {
            window.analytics.track("Feed -> Ask a Question");
            this.setState({ isShown: true });
          }}
        >
          {CREATE_POST_TITLE}
        </Button>
      </Pane>
    );
  }
}

export default PostCreate;
