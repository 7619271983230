import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import withRoutes from "../withRoutes";

import Layout from "../../components/layout/Layout";

// import TeamListPage from './TeamListPage'
// import JobListPage from './JobListPage'
// import JobDetailPage from './JobDetailPage'
// import TeamDetailPage from './TeamDetailPage'

export default withRoutes(Layout, [
  // {
  //   exact: true,
  //   path: "/teams/all",
  //   component: TeamListPage,
  //   state: "Teams",
  //   subState: "Teams",
  //   privateRoute: true,
  // },
  // {
  //   exact: true,
  //   path: "/team/:teamID",
  //   component: TeamDetailPage,
  //   state: "Teams",
  //   subState: "Teams",
  //   privateRoute: true,
  // },
  // {
  //   exact: true,
  //   path: "/jobs/all",
  //   component: JobListPage,
  //   state: "Jobs",
  // },
  // {
  //   path: "/job/:jobId",
  //   component: JobDetailPage,
  //   state: "Jobs",
  // }
]);
