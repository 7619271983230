import _ from "lodash";
import React, { useState } from "react";
import { Avatar, Button, Dialog, Heading, Pane, StyleIcon, Text, majorScale } from "evergreen-ui";

import RequestCard from "./RequestCard";

const RequestPreview = ({ request, myProfile }) => {
  const [showDetail, setShowDetail] = useState(false);

  const { owner, user } = request;
  const other = _.isEqual(myProfile?.id, user?.id) ? owner : user;
  const title = other?.role?.team?.name;
  let role = other?.role?.title;
  role = title ? `${role} at ${title}` : role;

  return (
    <Pane
      display="flex"
      border={"1px solid #C1C4D6"}
      borderRadius={majorScale(2)}
      paddingX={majorScale(2)}
      paddingY={majorScale(2)}
      justifyContent="space-between"
      alignItems="center"
      marginBottom={majorScale(2)}
      backgroundColor="white"
    >
      <Pane display="flex">
        <Avatar src={other?.picture} name={other?.fullName} size={majorScale(7)} marginRight={majorScale(2)} />
        <Pane display="flex" flexDirection="column" justifyContent="center">
          <Heading>{other?.fullName}</Heading>
          {role?.length > 0 && <Text>{role}</Text>}
        </Pane>
      </Pane>
      <Dialog hasHeader={false} hasFooter={false} isShown={showDetail} onCloseComplete={() => setShowDetail(false)}>
        <RequestCard request={request} myProfile={myProfile} onCompleted={() => setShowDetail(false)} />
      </Dialog>
      <Button appearance="primary" iconBefore={StyleIcon} onClick={() => setShowDetail(true)}>
        Review
      </Button>
    </Pane>
  );
};

export default RequestPreview;
