import React from "react";
import { useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";
import qs from "query-string";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";
import Loader from "../../components/Loader";
import BookSessionForm from "./BookSessionForm";
// import RequestSessionForm from "./RequestSessionForm";
import SessionReportForm from "./SessionReportForm";
import MyActivityPage from "./MyActivityPage";
// import ReminderForm from "./reminder/ReminderForm";
import LearnerTutorialPage from "./tutorial/LearnerTutorialPage";
import LeaderTutorialPage from "./tutorial/LeaderTutorialPage";
import SessionDetailPage from "./SessionDetailPage";
import NoShowForm from "./NoShowForm";
import CancelSessionPage from "./CancelSessionPage";
import RescheduleSessionPage from "./RescheduleSessionPage";

import ProfileList from "../../queries/profile/ProfileList";

const RequestRedirect = ({
  location,
  match: {
    params: { profileId }
  }
}) => {
  const { data: profileResult } = useQuery(ProfileList.CalendarRedirect, {
    variables: { profileID: profileId }
  });

  const { description } = qs.parse(location?.search, { arrayFormat: "comma" });

  return (
    <>
      {profileResult?.profile?.slug ? (
        <Redirect to={`/p/${profileResult?.profile?.slug}?request=show${description ? `&description=${description}` : ""}`} />
      ) : (
        <Loader />
      )}
    </>
  );
};

const CalendarRedirect = ({
  location,
  match: {
    params: { profileID }
  }
}) => {
  const { data: profileResult } = useQuery(ProfileList.CalendarRedirect, {
    variables: { profileID }
  });

  const { description } = qs.parse(location?.search, { arrayFormat: "comma" });

  return (
    <>
      {profileResult?.profile?.slug ? (
        <Redirect to={`/p/${profileResult?.profile?.slug}?calendar=show${description ? `&description=${description}` : ""}`} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default withRoutes(Layout, [
  {
    path: "/book/:profileId",
    component: BookSessionForm,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/request/:profileId",
    component: RequestRedirect,
    state: "Sessions",
    privateRoute: true
  },
  // {
  //   path: "/session/:sessionId/reminder",
  //   component: ReminderForm,
  //   state: "Sessions",
  //   privateRoute: true,
  // },
  {
    path: "/session/:id/cancel",
    component: CancelSessionPage,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/session/:id/reschedule",
    component: RescheduleSessionPage,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/session/:id/no-show",
    component: NoShowForm,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/session/:id/report",
    component: SessionReportForm,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/session/:id/:state",
    component: SessionDetailPage,
    state: "Sessions",
    privateRoute: true,
    reviewRoute: true
  },
  {
    exact: true,
    path: "/sessions",
    component: MyActivityPage,
    state: "Sessions",
    privateRoute: true,
    reviewRoute: true
  },
  {
    exact: true,
    path: "/sessions/:direction",
    component: MyActivityPage,
    state: "Sessions",
    privateRoute: true,
    reviewRoute: true
  },
  {
    path: "/calendar/:profileID",
    state: "Sessions",
    privateRoute: true,
    component: CalendarRedirect
  },
  {
    path: "/tutorial/learner",
    component: LearnerTutorialPage,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/tutorial/leader/:step",
    component: LeaderTutorialPage,
    state: "Training",
    privateRoute: true
  },
  {
    path: "/tutorial/leader",
    component: ({ location: { search } }) => <Redirect to={`/tutorial/leader/0${search}`} />
  },
  {
    path: "/guide/member",
    component: LearnerTutorialPage,
    state: "Sessions",
    privateRoute: true
  },
  {
    path: "/guide/mentor/:step",
    component: LeaderTutorialPage,
    state: "Training",
    privateRoute: true
  },
  {
    path: "/guide/mentor",
    component: ({ location: { search } }) => <Redirect to={`/guide/mentor/0${search}`} />
  }
]);
