import _ from "lodash";
import React from "react";
import { Pill, majorScale } from "evergreen-ui";

const truncTag = (tag) => {
  if (_.isEqual("Software Engineering", tag)) {
    return "Engineering";
  } else if (_.isEqual("Product Management", tag)) {
    return "Product";
  } else if (_.isEqual("Product Design", tag)) {
    return "Design";
  } else if (_.isEqual("Individual Contributor", tag)) {
    return "IC";
  } else {
    return tag;
  }
};

const tagColors = new Map([
  ["Location", "purple"],
  ["Role", "teal"],
  ["Level", "yellow"],
  ["Product Management", "red"],
  ["Manager", "blue"],
  ["Software Engineering", "green"],
  ["Product Design", "neutral"]
]);

export default ({ tag, type }) => (
  <Pill color={tagColors.get(type)} marginRight={majorScale(1)}>
    {truncTag(tag)}
  </Pill>
);
