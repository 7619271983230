import _ from "lodash";
import React from "react";
import {
  Avatar,
  Button,
  CalendarIcon,
  ChatIcon,
  MoreIcon,
  Pane,
  Heading,
  InlineAlert,
  Paragraph,
  Text,
  majorScale,
  minorScale,
  defaultTheme as theme
} from "evergreen-ui";
import { fromUnixTime, isWithinInterval, sub, subHours } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";
import { Link as BrowserLink } from "react-router-dom";

import HoverLink from "../../components/HoverLink";
import PreviousActions from "./PreviousActions";
import UpcomingActions from "./UpcomingActions";

const otherUser = ({ user, coach, myProfile }) => {
  return _.isEqual(myProfile?.id, coach?.user?.id) ? user : coach.user;
};

const formatSessionDate = (date, timeZone) => {
  timeZone = timeZone?.length ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eeee LLLL d");
  let time = formatInTimeZone(zonedDate, timeZone, "h:mm b (z)");

  if (time.includes("noon")) {
    time = time.replace("noon", "PM");
  }

  return `${day} at ${time}`;
};

const withinNextDay = (date, timeZone) => {
  if (!_.isNaN(date)) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return isWithinInterval(new Date(), {
    start: sub(zonedDate, { days: 1 }),
    end: zonedDate
  });
};

export const SessionDetail = ({ myProfile, session, hideDetail }) => {
  const timezone = myProfile?.timezone;
  const { date, cleanDescription, description } = session;
  const formattedDate = formatSessionDate(date, timezone);
  const details = _.isEmpty(cleanDescription) ? description : cleanDescription;

  return (
    <Pane display="flex" flexDirection="column">
      <Pane display="flex" flexDirection="row">
        <CalendarIcon color="#101840" size={majorScale(2)} marginRight={majorScale(1)} />
        <Heading size={400}>{formattedDate}</Heading>
      </Pane>
      {!hideDetail && (
        <Pane paddingTop={majorScale(1)} style={{ overflowWrap: "anywhere" }}>
          <Paragraph size={500}>{details}</Paragraph>
        </Pane>
      )}
    </Pane>
  );
};

export const ProfileOverview = ({ myProfile, session, hideMsgs, showDetail }) => {
  const profile = otherUser({ ...session, myProfile });
  const title = profile?.role?.team?.name;
  let role = profile?.role?.title;
  role = title ? `${role} at ${title}` : role;

  const size = majorScale(7);

  return (
    <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(3)}>
      <HoverLink to={`/p/${profile?.slug}`} samePage>
        <Pane display="flex">
          <Pane display="flex" flexDirection="row" marginRight={majorScale(2)}>
            <Avatar src={profile?.picture} name={profile?.fullName} size={size} />
          </Pane>
          <Pane display="flex" flexDirection="column" justifyContent="center" height={size}>
            <Heading size={500}>{profile?.fullName}</Heading>
            {profile?.role?.title?.length ? <Paragraph size={500}>{role}</Paragraph> : undefined}
          </Pane>
        </Pane>
      </HoverLink>
      {!hideMsgs && (
        <Button is={BrowserLink} to={`/messages/${session?.thread?.id}`} appearance="minimal">
          <ChatIcon />
        </Button>
      )}
      {showDetail && (
        <Button is={BrowserLink} to={`/session/${session?.id}/my-feedback`} appearance="minimal" iconBefore={MoreIcon}>
          View Session
        </Button>
      )}
    </Pane>
  );
};

const SessionCard = ({ myProfile, session, direction, justDetail, hideMsgs, hideActions, hideProfile, showWarnings }) => {
  if (!direction?.length) {
    direction = subHours(new Date(), 1) < session?.date ? "upcoming" : "previous";
  }

  const isUpcoming = _.isEqual(direction, "upcoming");

  const container = justDetail
    ? {
        marginY: majorScale(4)
      }
    : {
        border: `1px solid ${theme.colors.gray500}`,
        borderRadius: majorScale(2),
        marginBottom: majorScale(4),
        paddingX: majorScale(4),
        paddingY: majorScale(4)
      };

  return (
    <Pane display="flex" flexDirection="column" width="100%" backgroundColor="white" {...container}>
      {!hideProfile && <ProfileOverview session={session} myProfile={myProfile} direction={direction} hideMsgs={hideMsgs} />}
      {isUpcoming && showWarnings && session?.rescheduleCount > 1 && (
        <InlineAlert intent="warning" marginBottom={majorScale(3)}>
          You've already rescheduled twice - to change again you'll need to book a new session.
        </InlineAlert>
      )}
      <SessionDetail session={session} myProfile={myProfile} />
      {!hideActions && (
        <>
          {direction ? (
            isUpcoming ? (
              <UpcomingActions session={session} direction={direction} tooMany={session?.rescheduleCount > 1} />
            ) : (
              <PreviousActions session={session} direction={direction} />
            )
          ) : null}
        </>
      )}
    </Pane>
  );
};

export default SessionCard;
