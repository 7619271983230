import React from "react";
import ReactMarkdown from "react-markdown";
import { Row, Col } from "react-grid-system";
import injectSheet from "react-jss";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/Layout";
import Hero from "../../components/layout/Hero";
import AddToSlack from "../../components/auth/AddToSlack";

import { overview } from "../../content/waitlist";
import headerLinks from "../../content/headerLinks";

const styles = {
  root: {
    textAlign: "left",
    margin: {
      left: "0 !important",
      right: "0 !important"
    },
    font: {
      family: "SFProText-Light",
      size: 24,
      color: "#212529",
      weight: 400
    }
  },
  contact: {
    textAlign: "left",
    font: {
      family: "SFProText-Light",
      size: 24,
      color: "#212529",
      weight: 400
    }
  },
  noPadding: {
    padding: "0 !important"
  },
  noMargin: {
    marginLeft: "0 !important",
    marginRight: "0 !important"
  }
};

class SlackRedirect extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Merit | Thanks for sigining up!</title>
        </Helmet>
        <Hero headline={"Thanks for signing up!"}>
          <Col xs={12} sm={10} md={8} offset={{ sm: 1, md: 2 }} className={classes.noPadding}>
            <ReactMarkdown source={overview} className={`${classes.root} ${classes.noMargin}`} />
            <Col xs={12}>
              <AddToSlack />
            </Col>
          </Col>
        </Hero>
      </div>
    );
  }
}

export default injectSheet(styles)(SlackRedirect);
