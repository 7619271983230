import React, { Component } from "react";
import uploadcare from "uploadcare-widget";

// Original Source; modified to use JSS
// https://github.com/uploadcare/uploadcare-widget-react-demo/blob/master/src/components/Uploader.js

class Uploader extends Component {
  componentDidMount() {
    const widget = uploadcare.Widget(this.uploader);
    const widgetElement = uploadcare.jQuery(this.uploader).next(".uploadcare--widget");
    const { value, onChange, onUploadComplete, className, complete } = this.props;

    widgetElement.addClass(className);

    if (typeof value !== "undefined") {
      widget.value(value);
    }
    if (typeof onChange === "function") {
      widget.onChange((files) => {
        console.log({ files: this.files });
        if (files) {
          this.files = this.files && this.files.files ? this.files.files() : [this.files];
        } else {
          this.files = null;
        }

        onChange(files);
      });
    }
    if (typeof onUploadComplete === "function") {
      widget.onUploadComplete(onUploadComplete);
    }
    widget.onDialogOpen((dialog) => {
      this.dialog = dialog;

      if (this?.props?.onDialogLoad) {
        this.props.onDialogLoad();
      }
    });
  }

  componentWillUnmount() {
    if (this.dialog) {
      this.dialog.reject();
    }
    if (this.files) {
      uploadcare.jQuery.when.apply(null, this.files).cancel();
    }

    const widgetElement = uploadcare.jQuery(this.uploader).next(".uploadcare--widget");
    const widget = widgetElement.data("uploadcareWidget");

    if (widget && widget.inputElement === this.uploader) {
      widgetElement.remove();
    }
  }

  getInputAttributes() {
    const attributes = Object.assign({}, this.props);

    delete attributes.value;
    delete attributes.onChange;
    delete attributes.onUploadComplete;
    delete attributes.complete;

    return attributes;
  }

  render() {
    const attributes = this.getInputAttributes();

    return <input type="hidden" ref={(input) => (this.uploader = input)} {...attributes} />;
  }
}

export default Uploader;
