import _ from "lodash";
import React from "react";
import { Badge, Button, Pane, Textarea, Text, majorScale, toaster } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";

import FeedQueries from "../../queries/feed";

const CommentSchema = Yup.object().shape({
  details: Yup.string()
    .min(1, "Please provide some details")
    .max(10000000, "Please shorten your reply")
    .required("Please provide some details")
});

const { UpdateComment, CreateComment } = FeedQueries.mutations;
const { FeedQuery, Comments } = FeedQueries.queries;

const SUCCESS_TOAST = "Successfully replied.";

const placeholder = ({ post, comment }) => {
  const obj = comment ? comment : post;
  const objOwner = obj?.owner?.fullName;
  const objType = comment ? "comment" : "post";

  // return objOwner ? `Reply to ${objOwner}` :
  if (objOwner) {
    if (_.isEqual(objType, "post")) {
      return `This has been one of my big challenges over the course of my career! Between all of the responsibilities that can be expected of you, I also can feel overwhelmed. Here’s what I’ve done in the past to make this easier to manage….`;
    } else return `Reply to ${objOwner}`;
  } else {
    return `Reply to this ${objType}`;
  }
};

const errorText = (text) => (
  <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
    {text}
  </Badge>
);

const ReplyTextArea = ({ field, form: { errors, submitted }, children, ...props }) => (
  <Pane>
    <Textarea minHeight={100} {...field} {...props} />
    {errors[field.name] && errorText(errors[field.name])}
    {children ? children : null}
  </Pane>
);

const ReplyForm = ({ comment, post, onUpdate, onCancel, fullWidth }) => {
  const mutation = comment?.id ? UpdateComment : CreateComment;
  const postId = post?.id;

  const [submitReply, { loading }] = useMutation(mutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: FeedQuery,
        variables: {
          cursor: "1",
          sort: "POPULAR"
        }
      },
      { query: Comments, variables: { postId } }
    ],
    onCompleted: () => {
      toaster.success(SUCCESS_TOAST, { duration: 2 });
      onUpdate();
    }
  });

  return (
    <Formik
      validationSchema={CommentSchema}
      validateOnChange={false}
      initialValues={{
        details: comment && comment.details ? comment.details : "",
        id: comment && comment.id ? comment.id : undefined
      }}
      onSubmit={(variables, { setSubmitting, resetForm }) => {
        submitReply({ variables: { postId, ...variables } });
        setSubmitting(false);
        resetForm({ details: "" });
      }}
      render={({ isSubmitting, values, setFieldValue }) => (
        <Form
          style={
            fullWidth
              ? {
                  width: "100%"
                }
              : null
          }
        >
          <Pane>
            <Field type="text" name="details" placeholder={placeholder({ post, comment })} component={ReplyTextArea} />
          </Pane>

          <Pane display="flex" flexDirection="row" justifyContent="space-between" marginTop={majorScale(2)} marginBottom={majorScale(2)}>
            <Pane>
              <Text>
                We use{" "}
                <a href="https://commonmark.org/help/" target="_blank">
                  markdown
                </a>{" "}
                for formatting.
              </Text>
            </Pane>
            <Pane>
              {values?.details?.length > 0 && (
                <Button
                  appearance="minimal"
                  type="button"
                  onClick={() => {
                    setFieldValue("details", "");

                    if (_.isFunction(onCancel)) {
                      onCancel();
                    }
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button appearance="primary" isLoading={loading} type="submit">
                Comment
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default ReplyForm;
