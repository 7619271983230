import React from "react";
import { Dialog, Position } from "evergreen-ui";

import EmailSignup from "../routes/landing/EmailSignup";

const TITLE = "New to Merit?";
const SUBTITLE = "Sign up to talk to senior leaders in product, design & engineering.";
const CTA = "Sign Up";

const SignupDialog = (signupContext) => {
  const { isShown, title, subtitle, mode, cta, groupId, redirect } = signupContext;

  return (
    <Dialog title={title ? title : TITLE} isShown={isShown} hasFooter={false} position={Position.BOTTOM_RIGHT}>
      <EmailSignup
        cta={cta ? cta : CTA}
        mode={mode ? mode : "Learn"}
        subtitle={subtitle ? subtitle : SUBTITLE}
        groupId={groupId}
        showGoogle={true}
        redirect={redirect}
      />
    </Dialog>
  );
};

export default SignupDialog;
