import React from "react";
import { Row, Col, Container, Visible, Hidden } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Link as BrowserLink } from "react-router-dom";
import { Button, Pane, Paragraph, Heading, Overlay, majorScale, minorScale } from "evergreen-ui";

import CoachList from "../coach/CoachList";
import MentorCard from "../../components/mentor/MentorCard";

const LocationBlock = () => {
  return (
    <Pane padding={majorScale(2)}>
      <Heading size={700} marginBottom={minorScale(3)}>
        You're on our waitlist!
      </Heading>
      <Paragraph marginBottom={majorScale(1)}>
        Thanks for signing up for Merit. We're focused on creating the best experience possible for everyone on the site.
      </Paragraph>
      <Paragraph marginBottom={majorScale(1)}>
        Right now that means we're focused on folks based in the US or Canada that work in software engineering, product design, or product
        management.
      </Paragraph>
      <Paragraph marginBottom={majorScale(1)}>This focus lets us ensure high quality career conversations on our site.</Paragraph>
      <Paragraph marginBottom={majorScale(1)}>
        We’ll let you know once we expand our geographic reach. If you think you received this message in error, contact us at
        team@get-merit.com
      </Paragraph>
      <Paragraph marginBottom={majorScale(1)}>Thanks!</Paragraph>
      <Pane display="flex" flexDirection="row" justifyContent="flex-end" paddingTop={majorScale(1)}></Pane>
    </Pane>
  );
};

const LocationBlockContainer = (props) => {
  const { data: mentorResult } = useQuery(CoachList.CoachPreviewQuery);
  const mentors = mentorResult?.coachPreview;

  return (
    <div>
      <Visible xs>
        <Container>
          <Row justify="center">
            <Col xs={12}>
              <Pane paddingTop={majorScale(2)}>
                <LocationBlock />
              </Pane>
            </Col>
          </Row>
        </Container>
      </Visible>
      <Hidden xs>
        <Container>
          <Row>
            <Pane display="flex" width="100%" flexWrap="wrap">
              {mentors &&
                mentors.map((mentor) => (
                  <Col md={4} lg={3} key={`SignupPage-${mentor?.id}`} style={{ marginBottom: majorScale(2) }}>
                    <MentorCard user={mentor?.user} nextAvailable={mentor?.nextAvailable} />
                  </Col>
                ))}
            </Pane>
          </Row>
          <Overlay
            isShown={true}
            preventBodyScrolling={true}
            shouldCloseOnClick={false}
            shouldCloseOnEscape={false}
            containerProps={{ overflowY: "scroll" }}
          >
            <Container>
              <Row justify="center">
                <Col xs={10} sm={10} md={8} lg={6} xl={6}>
                  <Pane padding={majorScale(2)} marginY="12vmin" backgroundColor="white" elevation={4} borderRadius={8}>
                    <LocationBlock />
                  </Pane>
                </Col>
              </Row>
            </Container>
          </Overlay>
        </Container>
      </Hidden>
    </div>
  );
};

export default LocationBlockContainer;
