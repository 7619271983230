import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Row, Col, ScreenClassRender, Hidden, Visible } from "react-grid-system";
import { Link, Avatar, Pane, Paragraph, Heading, majorScale, minorScale, defaultTheme as theme } from "evergreen-ui";
import GhostContentAPI from "@tryghost/content-api";

const api = new GhostContentAPI({
  url: "https://merit.ghost.io",
  key: "fd2311bebbfe0df7e32db83924",
  version: "v3"
});

const TagsRow = ({ tags }) => {
  return (
    <Row>
      <Col>
        <Pane
          backgroundColor="white"
          border={`1px solid ${theme.colors.gray500}`}
          borderRadius={majorScale(2)}
          paddingX={majorScale(2)}
          paddingTop={majorScale(2)}
          width="100%"
          display="flex"
          flexDirection="column"
          marginBottom={majorScale(1)}
        >
          {tags?.length > 0 &&
            tags.map((tag) => (
              <Link is="a" href={tag?.url} style={{ textDecoration: "none" }}>
                <Pane width="100%" marginBottom={majorScale(2)} display="flex" flexDirection="row" justifyContent="space-between">
                  <Heading>{tag.name}</Heading>
                  <Paragraph>{`${tag?.count?.posts} posts`}</Paragraph>
                </Pane>
              </Link>
            ))}
        </Pane>
      </Col>
    </Row>
  );
};

const BlogList = ({ posts }) => {
  return (
    <Row>
      {posts?.length > 0 &&
        posts.map((post) => (
          <Col xs={12} sm={12} md={6} lg={4}>
            <Link is="a" href={post?.url} style={{ textDecoration: "none" }}>
              <Pane
                backgroundColor="white"
                border={`1px solid ${theme.colors.gray500}`}
                borderRadius={majorScale(2)}
                padding={majorScale(2)}
                width="100%"
                display="flex"
                flexDirection="row"
                marginBottom={majorScale(1)}
              >
                <Pane display="flex" flexDirection="column">
                  <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={majorScale(2)}>
                    <Avatar
                      src={post?.feature_image || post?.twitter_image}
                      size={"100%"}
                      borderRadius={majorScale(2)}
                      shape="square"
                      name={post?.title}
                    />
                  </Pane>
                  <Heading marginBottom={majorScale(1)}>{post?.title}</Heading>
                  <Paragraph size={500} marginBottom={minorScale(1)}>
                    {_.truncate(post?.excerpt, { length: 120 })}
                  </Paragraph>
                  <Paragraph size={100}>{`${post?.reading_time} MIN READ`}</Paragraph>
                </Pane>
              </Pane>
            </Link>
          </Col>
        ))}
    </Row>
  );
};

const BlogRow = ({ posts }) => {
  return (
    <Row>
      <Col>
        {posts?.length > 0 &&
          posts.map((post) => (
            <Link is="a" href={post?.url} style={{ textDecoration: "none" }}>
              <Pane
                backgroundColor="white"
                border={`1px solid ${theme.colors.gray500}`}
                borderRadius={majorScale(2)}
                padding={majorScale(2)}
                width="100%"
                display="flex"
                flexDirection="row"
                marginBottom={majorScale(1)}
              >
                <Hidden xs>
                  <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginRight={majorScale(3)}>
                    <Avatar
                      src={post?.feature_image || post?.twitter_image}
                      size={majorScale(12)}
                      borderRadius={majorScale(2)}
                      shape="square"
                      name={post?.title}
                    />
                  </Pane>
                </Hidden>
                <Pane display="flex" flexDirection="column">
                  <Visible xs>
                    <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={majorScale(2)}>
                      <Avatar
                        src={post?.feature_image || post?.twitter_image}
                        size={"100%"}
                        borderRadius={majorScale(2)}
                        shape="square"
                        name={post?.title}
                      />
                    </Pane>
                  </Visible>
                  <Heading marginBottom={majorScale(1)}>{post?.title}</Heading>
                  <Paragraph size={500} marginBottom={minorScale(1)}>
                    {post?.excerpt}
                  </Paragraph>
                  <Paragraph size={100}>{`${post?.reading_time} MIN READ`}</Paragraph>
                </Pane>
              </Pane>
            </Link>
          ))}
      </Col>
    </Row>
  );
};

const BlogSection = ({ onlyPosts }) => {
  const [posts, setPosts] = useState(null);
  const [tags, setTags] = useState(null);

  useEffect(() => {
    api.posts.browse({ limit: 3 }).then(setPosts);
    api.tags
      .browse({
        filter: "visibility:public",
        include: "count.posts"
      })
      .then(setTags);
  }, []);

  return onlyPosts ? (
    <ScreenClassRender render={(screenClass) => <BlogList posts={posts} />} />
  ) : (
    <ScreenClassRender
      render={(screenClass) => (
        <div
          style={{
            display: "flex",
            flexDirection: screenClass?.includes("xs") ? "column" : "row",
            width: "100%"
          }}
        >
          <Col xs={12} sm={8}>
            <Row>
              <Col xs={12}>
                <Pane
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginBottom={majorScale(3)}
                  marginTop={majorScale(3)}
                >
                  <Heading size={600}>Read the latest on our blog</Heading>
                  <Link is="a" href="https://blog.get-merit.com">
                    <Pane color={theme.colors.blue500} fontWeight={500}>
                      <span>Show more</span>
                    </Pane>
                  </Link>
                </Pane>
              </Col>
            </Row>
            <BlogRow posts={posts} />
          </Col>
          <Col xs={12} sm={4}>
            <Row>
              <Col xs={12}>
                <Pane
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginBottom={majorScale(3)}
                  marginTop={majorScale(3)}
                >
                  <Heading size={600}>Browse blog by topic</Heading>
                  <Link is="a" href="https://blog.get-merit.com">
                    <Pane color={theme.colors.blue500} fontWeight={500}>
                      <span>View all</span>
                    </Pane>
                  </Link>
                </Pane>
              </Col>
            </Row>
            <TagsRow tags={tags} />
          </Col>
        </div>
      )}
    />
  );
};

export default BlogSection;
