import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

import CoachAvailabilityList from "./CoachAvailabilityList.js";
import ReviewDetail from "./ReviewDetail.js";
// import GoalsView from './GoalsView.js'
import SchedulerPage from "./SchedulerPage";
import ZoomScheduler from "./ZoomScheduler";

export default withRoutes(Layout, [
  {
    exact: true,
    path: "/admin/coaches",
    component: CoachAvailabilityList,
    privateRoute: true
  },
  {
    path: "/admin/review/:reviewID",
    component: ReviewDetail,
    privateRoute: true
  },
  // {
  //   path: "/admin/goals",
  //   component: GoalsView,
  //   privateRoute: true,
  // },
  {
    path: "/admin/scheduler",
    component: SchedulerPage
  },
  {
    path: "/admin/zoom",
    component: ZoomScheduler
  }
]);
