import React from "react";
import { AnnotationIcon, BookIcon, SearchIcon, StarIcon, HandIcon, ShareIcon } from "evergreen-ui";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MENTOR = [
  {
    header: "Send 3 session offers",
    copy: "Get started mentoring today! Offer a mentorship session for a recently submitted career question. Offering help lets members know you’re available and happy to help.",
    cta: "Offer Sessions",
    icon: HandIcon,
    link: "/members"
  },
  {
    header: "Read mentorship best practices",
    copy: "After conducting thousands of mentorship sessions, we’ve written up our best practices for successful conversations. Read our guide and get the most out of mentorship.",
    cta: "Read Guide",
    icon: BookIcon,
    link: "/guide/mentor"
  },
  {
    header: "Tell your network you’re a mentor",
    copy: "You’re offering help and know best practices. Congrats! You are actively mentoring under-networked folks in tech. Tell your network and connect to more members.",
    cta: "Share Profile",
    icon: ShareIcon,
    link: "?section=share"
  },
  {
    header: "Your profile is looking good!",
    copy: "Thanks for joining Merit and we’re looking forward to helping you however we can."
  }
];

export const MEMBER = [
  {
    header: "Ask a question",
    copy: "Let mentors know how they can help! Ask a career question and you’ll receive mentorship session offers from relevant mentors.",
    cta: "Ask Question",
    icon: AnnotationIcon,
    link: "/discussion/create"
  },
  {
    header: "Book a session",
    copy: "Accelerate your career by booking a mentorship session. Many mentors are available to help!",
    cta: "Explore Mentors",
    icon: SearchIcon,
    link: "/mentors"
  },
  {
    header: "Read best practices",
    copy: "Get the most out of your upcoming mentorship session by reading our member best practices.",
    cta: "Read Best Practices",
    icon: BookIcon,
    link: "/guide/member"
  },
  {
    header: "Your profile is looking good!",
    copy: "Thanks for joining Merit and we’re looking forward to helping you however we can."
  }
];

export const CANDIDATE = [
  {
    header: "Add your skills",
    copy: "Providing your skills, frameworks, and proficiency to let us recruiters know opportunities that could match your experience.",
    cta: "Edit skills",
    icon: AnnotationIcon,
    link: "?section=skills"
  },
  {
    header: "Add your job history",
    copy: "Go beyond a resume and let employers know your skills and expertise!",
    cta: "Add job history",
    icon: AnnotationIcon,
    link: "?section=work-experience"
  },
  {
    header: "Book a mentorship session",
    copy: "Learn how to improve in the job search by booking your first mentorship session.",
    cta: "Explore mentors",
    icon: SearchIcon,
    link: "/mentors"
  },
  {
    header: "Your profile is looking good!",
    copy: "Thanks for joining Merit and we’re looking forward to helping you however we can."
  }
];
