import withRoutes from "../withRoutes";

import Layout from "../../components/layout/Layout";

// import NominationPage from './NominationPage.js'

export default withRoutes(Layout, [
  // {
  //   path: "/nominate",
  //   component: NominationPage,
  //   state: 'Nominate',
  //   exact: true
  // }
]);
