import { join, truncate } from "lodash";
import React from "react";
import { Helmet } from "react-helmet";

const DEFAULT_DESCRIPTION = "Learn and grow with discussions. Thousands in tech are here to help you in your career.";

const truncated = (title) => (title?.length > 0 ? truncate(title, { length: 45, omission: "..." }) : "");

const Meta = ({ id, post, postResult }) => {
  const title = join([truncated(post?.title), "Merit"], " | ");
  const description = `Posted${post?.topic?.name ? ` in ${post?.topic.name}` : ""} by ${post?.owner?.fullName}`;
  const twitterURL = `@meritmentor`;

  return (
    <Helmet>
      {postResult && !post?.id && <meta name="robots" content="noindex,nofollow" />}
      <meta charSet="utf-8" />
      <link rel="canonical" href={`${process.env.REACT_APP_URL}/d/${id}`} />
      <title>{title}</title>

      <meta property="og:type" content="website" />
      <meta name="description" property="og:description" content={description} />
      <meta name="title" property="og:title" content={title} />

      <meta name="url" property="og:url" content={window.location.href} />

      <meta name="twitter:card" property="twitter:card" content="summary" />
      <meta name="twitter:site" property="twitter:site" content={twitterURL} />
      <meta name="twitter:title" property="twitter:title" content={title} />
      <meta name="twitter:description" property="twitter:description" content={description} />
    </Helmet>
  );
};

export default Meta;
