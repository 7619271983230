import React from "react";
import { useQuery } from "@apollo/client";
import { Col, Row } from "react-grid-system";
import { Pane, Heading, Spinner, majorScale } from "evergreen-ui";

import FeedQueries from "../../../queries/feed";
import ShowMore from "../components/ShowMore";
import HoverLink from "../../../components/HoverLink";
import MemberCard from "../components/MemberCard";

const MembersRow = ({ posts, myProfile, refetch, query, isMobile }) => {
  return (
    <Row>
      {posts.slice(0, 2).map((post) => (
        <Col md={6} key={post?.id}>
          <MemberCard post={post} myProfile={myProfile} membersQuery={query} isMobile={isMobile} refetch={() => refetch({ ...query })} />
        </Col>
      ))}
    </Row>
  );
};

const MembersSection = ({ isMobile }) => {
  const query = {
    cursor: "1",
    comments: 0,
    sort: "RECENT"
  };

  const { data: members, refetch } = useQuery(FeedQueries.queries.FeedQuery, {
    variables: { ...query }
  });

  return (
    <Col xs={12}>
      <Row>
        <Col xs={12}>
          <Pane display="flex" flexDirection="row" justifyContent="space-between" width="100%" marginBottom={majorScale(3)}>
            <HoverLink samePage to={"/members"}>
              <Heading size={600}>Help a member with their career question</Heading>
            </HoverLink>
            <ShowMore to="/members" />
          </Pane>
        </Col>
      </Row>
      {members?.posts?.pageInfo?.edgeCount > 0 ? (
        <MembersRow
          pageInfo={members?.posts?.pageInfo}
          posts={members?.posts?.edges}
          myProfile={members?.myProfile}
          query={query}
          refetch={refetch}
          isMobile={isMobile}
        />
      ) : (
        <Row>
          <Col xs={12}>
            <Pane width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" height={300}>
              <Spinner />
            </Pane>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default MembersSection;
