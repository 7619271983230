import _ from "lodash";
import React from "react";
import { fromUnixTime, format } from "date-fns";
import { Col } from "react-grid-system";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Alert, Button, Pane, Paragraph, Heading, defaultTheme as theme, majorScale, toaster } from "evergreen-ui";

import Loader from "../../components/Loader";
const PAGE_LOAD_ERROR = "We were not able to load this page. Please try refreshing.";

const SCHEDULER_QUERY = gql`
  query CurrentScheduler {
    googleSchedulerLink
    schedulerZoomAuthLink
    zoomScheduler {
      id
      email
      createdAt
      updatedAt
    }
    scheduler {
      id
      email
      createdAt
      updatedAt
    }
  }
`;

const DELETE_SCHEDULER = gql`
  mutation DeleteScheduler($id: String!) {
    deleteScheduler(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;

const formatDate = (date) => {
  return format(fromUnixTime(date / 1000), "MMM do yyyy HH:MM");
};

const ExistingScheduler = ({ id, email, createdAt, updatedAt }) => {
  const [deleteScheduler] = useMutation(DELETE_SCHEDULER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: SCHEDULER_QUERY }],
    onCompleted: ({ deleteScheduler }) => {
      if (_.isNull(deleteScheduler) || !deleteScheduler?.id) {
        toaster.warning("You are not authorized to do that");
      } else {
        toaster.success("Deleted scheduler");
      }
    }
  });

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      marginBottom={majorScale(4)}
      paddingX={majorScale(4)}
      paddingY={majorScale(4)}
    >
      <Pane>
        <Heading>{`Current scheduler: ${email}`}</Heading>
        <Paragraph size={500}>{`Created at ${formatDate(createdAt)}`}</Paragraph>
        <Paragraph size={500}>{`Last updated at ${formatDate(updatedAt)}`}</Paragraph>
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button marginTop={majorScale(2)} onClick={() => deleteScheduler({ variables: { id } })} appearance="primary" intent="danger">
          Delete Scheduler
        </Button>
      </Pane>
    </Pane>
  );
};

const CreateScheduler = ({ link }) => (
  <Pane
    display="flex"
    flexDirection="column"
    width="100%"
    backgroundColor="white"
    border={`1px solid ${theme.colors.gray500}`}
    borderRadius={majorScale(2)}
    marginBottom={majorScale(4)}
    paddingX={majorScale(4)}
    paddingY={majorScale(4)}
  >
    <Paragraph size={500}>Press below to create a new scheduler.</Paragraph>
    <Pane display="flex" justifyContent="flex-end">
      <Button marginTop={majorScale(2)} is="a" href={link} appearance="primary">
        Create Scheduler
      </Button>
    </Pane>
  </Pane>
);

const ZoomRoom = ({ id, email, createdAt, updatedAt }) => {
  // const [deleteScheduler] = useMutation(DELETE_SCHEDULER, {
  //   awaitRefetchQueries: true,
  //   refetchQueries: [{ query: SCHEDULER_QUERY }],
  //   onCompleted: ({ deleteScheduler }) => {
  //     if (_.isNull(deleteScheduler) || !deleteScheduler?.id) {
  //       toaster.warning("You are not authorized to do that")
  //     } else {
  //       toaster.success('Deleted scheduler')
  //     }
  //   }
  // })

  return (
    <Pane
      display="flex"
      flexDirection="column"
      width="100%"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      marginBottom={majorScale(4)}
      paddingX={majorScale(4)}
      paddingY={majorScale(4)}
    >
      <Pane>
        <Heading>{`Current scheduler: ${email}`}</Heading>
        <Paragraph size={500}>{`Created at ${formatDate(createdAt)}`}</Paragraph>
        <Paragraph size={500}>{`Last updated at ${formatDate(updatedAt)}`}</Paragraph>
      </Pane>
      {/*
          <Pane
            display="flex"
            justifyContent="flex-end">
            <Button
              marginTop={majorScale(2)}
              onClick={() => deleteScheduler({ variables: { id } })}
              appearance="primary"
              intent="danger">
              Delete Scheduler
            </Button>
          </Pane>
          */}
    </Pane>
  );
};

const CreateZoomRoom = ({ link }) => (
  <Pane
    display="flex"
    flexDirection="column"
    width="100%"
    backgroundColor="white"
    border={`1px solid ${theme.colors.gray500}`}
    borderRadius={majorScale(2)}
    marginBottom={majorScale(4)}
    paddingX={majorScale(4)}
    paddingY={majorScale(4)}
  >
    <Paragraph size={500}>Press below to create a new Zoom room.</Paragraph>
    <Pane display="flex" justifyContent="flex-end">
      <Button marginTop={majorScale(2)} is="a" href={link} appearance="primary">
        Create Zoom Room
      </Button>
    </Pane>
  </Pane>
);

const SchedulerPage = (props) => {
  const { data, loading, error } = useQuery(SCHEDULER_QUERY);
  let googleSchedulerLink, scheduler, zoomAuthLink, zoomRoom;

  if (data) {
    googleSchedulerLink = data?.googleSchedulerLink;
    scheduler = data?.scheduler;
    zoomAuthLink = data?.zoomAuthLink;
    zoomRoom = data?.zoomScheduler;
    zoomAuthLink = data?.schedulerZoomAuthLink;
  }

  return (
    <Col xs={12} sm={8} md={8} lg={8} offset={{ sm: 2, md: 2, lg: 2 }}>
      <Pane display="flex" justifyContent="center" flexDirection="column" paddingTop={majorScale(2)}>
        {loading && !(scheduler || googleSchedulerLink) && <Loader />}
        {error && <Alert intent="danger" title={PAGE_LOAD_ERROR} />}
        {scheduler && !_.isNil(scheduler) && !loading ? (
          <ExistingScheduler {...scheduler} />
        ) : (
          <CreateScheduler link={googleSchedulerLink} />
        )}
        {zoomRoom && !_.isNil(zoomRoom) && !loading ? <ZoomRoom {...zoomRoom} /> : <CreateZoomRoom link={zoomAuthLink} />}
      </Pane>
    </Col>
  );
};

export default SchedulerPage;
