import _ from "lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button, Checkbox, Heading, Pane, majorScale, minorScale, Paragraph } from "evergreen-ui";
import CandidateQueries from "../../../queries/candidate";
import TextArea from "../../form/TextArea";
import ProfileList from "../../../queries/profile/ProfileList";

const challengeOptions = [
  {
    key: "resume",
    value: "Resume and portfolio review"
  },
  {
    key: "technical_interview",
    value: "Practicing technical interviews"
  },
  {
    key: "finding_roles",
    value: "Finding relevant roles"
  },
  {
    key: "offers",
    value: "Evaluating and negotiating offers"
  }
  // {
  //   key: "all_of_the_above",
  //   value: "All of the above",
  // },
  // {
  //   key: "something_else",
  //   value: "Something else",
  // },
  // {
  //   key: "not_sure",
  //   value: "Not sure",
  // },
];

const JobSearchFom = ({ title, nextPage, currentStep, totalSteps }) => {
  const { data: myProfileResult } = useQuery(ProfileList.MyProfileQuery);
  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile);
  const [selectedOptions, setSelectedOptions] = useState(
    _.zipObject(_.keys(challengeOptions), _.fill(Array(challengeOptions.length), false))
  );
  const [setJobSearchChallenge] = useMutation(CandidateQueries.mutations.SetJobSearch, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CandidateQueries.queries.MyCandidateProfile
      }
    ],
    onCompleted: (results) => {
      if (results) {
        _.isFunction(nextPage) &&
          nextPage({
            topicIds: results?.setJobSearch?.map(({ id }) => id),
            jobRole: candidateResult?.myCandidateProfile?.jobRole || myProfileResult?.myProfile?.jobRole
          });
      }
    }
  });

  const updateChallenges = (selectedOptions, setFieldValue) => {
    const selected = _.pickBy(selectedOptions, (value) => value);
    const selectedKeys = _.keys(selected);

    setFieldValue("challenges", selectedKeys);
  };

  return (
    <Formik
      initialValues={{
        challenges: [],
        context: ""
      }}
      validationSchema={Yup.object({
        challenges: Yup.array().of(Yup.string()),
        context: Yup.string()
      })}
      onSubmit={(variables, { setSubmitting }) => {
        setJobSearchChallenge({ variables });
      }}
      render={({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Pane>
            <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={majorScale(4)}>
              <Heading size={600}>{title}</Heading>

              <Heading size={600}>{`${currentStep}/${totalSteps}`}</Heading>
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane paddingBottom={minorScale(2)}>
                <Heading>What’s been your biggest challenge while finding a role?</Heading>
                <Paragraph>
                  We’ll use this information to help recommend mentors and topics on the next page. Select as many as you’d like.
                </Paragraph>
              </Pane>
              <Pane>
                {challengeOptions.map((option, index) => (
                  <Checkbox
                    key={`JobSearchForm-${option.key}`}
                    label={option.value}
                    name={`challenges.${index}`}
                    value={option.key}
                    checked={selectedOptions[option.key]}
                    onChange={(e) => {
                      if (selectedOptions[option.key]) {
                        selectedOptions[option.key] = false;
                        setSelectedOptions({ ...selectedOptions, [option.key]: false });
                      } else {
                        selectedOptions[option.key] = true;
                        setSelectedOptions({ ...selectedOptions, [option.key]: true });
                      }

                      updateChallenges(selectedOptions, setFieldValue);
                    }}
                  />
                ))}
              </Pane>
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(3)}>
              <Pane paddingBottom={minorScale(2)}>
                <Heading>Anything else you can tell us?</Heading>
                <Paragraph>Our team will reach out if there are job recommendations</Paragraph>
              </Pane>
              <Pane>
                <Field
                  type="text"
                  name="context"
                  placeholder={
                    "I’ve been looking for a job for a couple of months now and I keep getting stuck on technical interviews. I would love to know how to manage this well."
                  }
                  component={TextArea}
                />
              </Pane>
            </Pane>

            <Pane display="flex" justifyContent="flex-end">
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                View mentors
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default JobSearchFom;
