import React, { useState } from "react";
import { Avatar, Pane, Tooltip, majorScale } from "evergreen-ui";

import Comment from "./Comment";
import ReplyIndex from "./ReplyIndex";
import CreateReply from "./CreateReply";
import UpdateCommentForm from "./UpdateCommentForm";
import ReactionIndex from "../reaction/ReactionIndex";

const CommentContainer = (containerProps) => {
  const { comment, myProfile, postId, onDelete } = containerProps;
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReactions, setShowReactions] = useState(true);

  if (!comment) return null;
  const indent = majorScale(5);

  return (
    <Pane display="flex" paddingTop={majorScale(2)} flexDirection="column">
      <Pane display="flex" width="100%" justifyContent="space-between">
        <Pane display="flex" alignItems="start" width={"100%"}>
          <Avatar
            border="default"
            marginTop={majorScale(1)}
            src={comment.owner.picture}
            name={comment.owner.fullName}
            size={majorScale(4)}
          />
          {showEdit ? (
            <UpdateCommentForm onClose={() => setShowEdit(false)} onUpdate={() => setShowEdit(false)} {...containerProps} />
          ) : (
            <Comment showDelete={showDelete} setShowDelete={setShowDelete} setShowEdit={setShowEdit} {...containerProps} />
          )}
        </Pane>
      </Pane>
      <Pane display="flex" justifyContent="space-between" width="100%" paddingLeft={indent} paddingTop={majorScale(1)} alignItems="center">
        {showReactions && <ReactionIndex post={{ id: postId }} comment={comment} myProfile={myProfile} />}
        <CreateReply commentId={comment.id} postId={postId} myProfile={myProfile} onShown={() => setShowReactions(!showReactions)} />
      </Pane>
      <Pane paddingLeft={indent}>
        <ReplyIndex commentId={comment.id} postId={postId} myProfile={myProfile} />
      </Pane>
    </Pane>
  );
};

export default CommentContainer;
