import _ from "lodash";
import React from "react";
import { useQuery } from "@apollo/client";
import { Alert, Heading, Pane, Paragraph, majorScale, minorScale } from "evergreen-ui";

import VideoLink from "../../../../components/nylas/VideoLink";
import ProfileList from "../../../../queries/profile/ProfileList";

const FIND_VIDEO_LINK_INFO = [
  "This link will appear in meeting invitations so that members can join the session. Otherwise, we'll automatically generate a Zoom meeting link for each mentorship session that you can access in the invitation or in the Merit app."
];

const SetVideoLink = () => {
  const { data: videoLink } = useQuery(ProfileList.VideoLinkQuery);

  return (
    <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
      <Pane>
        <Heading marginBottom={majorScale(1)}>🎥 &nbsp; How should mentees video call you?</Heading>
        {FIND_VIDEO_LINK_INFO.map((t) => (
          <Paragraph size={500} key={`info-${t}`} marginBottom={minorScale(3)}>
            {t}
          </Paragraph>
        ))}
        <VideoLink />
      </Pane>
    </Pane>
  );
};

export default SetVideoLink;
