import _ from "lodash";
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Pane,
  Heading,
  Text,
  DocumentOpenIcon,
  FolderCloseIcon,
  TickIcon,
  PersonIcon,
  ShareIcon,
  CrossIcon,
  minorScale,
  majorScale,
  defaultTheme as theme,
  toaster,
  Paragraph,
  MapMarkerIcon,
  UnpinIcon,
  MobileVideoIcon,
  BriefcaseIcon,
  WrenchIcon,
  AlignLeftIcon,
  CaretDownIcon,
  CaretUpIcon
} from "evergreen-ui";
import { Link as BrowserLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { formatDistanceToNow, isValid } from "date-fns";

import { AVATAR_LOGO } from "../profile/AutoFill";
import { SkillSection } from "../../routes/profile/components/ExpertiseSection";
import CandidateRequest from "./CandidateRequest";
import CandidateQueries from "../../queries/candidate";
import HoverLink from "../HoverLink";

const DESCRIPTION_LIMIT = 210;

const formatDate = (createdAt) => {
  // https://date-fns.org/v2.27.0/docs/isValid
  createdAt = isValid(new Date(createdAt)) ? createdAt : Number(createdAt);

  // https://date-fns.org/v2.27.0/docs/formatDistanceToNow
  return `${formatDistanceToNow(new Date(createdAt))} ago`;
};

const RecommendationsButton = ({ candidate }) => {
  const writers = _.map(candidate?.recommendations, "owner")
    .filter((o) => o?.picture)
    .slice(0, 2);

  return (
    <Button is={BrowserLink} to={`/p/${candidate?.user?.slug}`} target="_blank" appearance="default" paddingLeft={majorScale(1)}>
      {writers.map((writer, index) => (
        <Avatar src={writer?.picture} name={writer?.fullName} marginRight={minorScale(1)} />
      ))}
      {`${candidate?.recommendations?.length} recommendation${candidate?.recommendations?.length === 1 ? "" : "s"}`}
    </Button>
  );
};

export const ProspectOptions = ({ candidate, myRecruiterProfile, user, existingRequest, onUpdate }) => {
  const [oldStatus, setOldStatus] = useState(existingRequest?.status);
  const [showCandidateRequest, setShowCandidateRequest] = useState(false);
  const [respondToRequest, { loading }] = useMutation(CandidateQueries.mutations.ArchiveRequest, {
    onCompleted: () => {
      const statusMap = {
        Accepted: "accepted",
        Rejected: "rejected",
        Archived: "archived",
        Active: "active"
      };

      toaster.success(`Updated request.`, { duration: 2 });
      if (_.isFunction(onUpdate)) {
        onUpdate({ status: statusMap[oldStatus] });
        setOldStatus(existingRequest?.status);
      }
    },
    refetchQueries: [{ query: CandidateQueries.queries.MyCandidateRequests }],
    awaitRefetchQueries: true
  });

  const hasRecommendations = candidate?.recommendations?.length > 0;
  return (
    <Pane display="flex" flexDirection="row" justifyContent={hasRecommendations ? "space-between" : "flex-end"}>
      {!existingRequest?.id && (
        <>
          {hasRecommendations && <RecommendationsButton candidate={candidate} />}
          <Pane>
            {/* <Button
              iconBefore={ShareIcon}
              marginRight={majorScale(2)}>
              Not interested
            </Button> */}
            <Button appearance="primary" iconBefore={TickIcon} onClick={() => setShowCandidateRequest(true)}>
              Request to chat
            </Button>
            <CandidateRequest
              myRecruiterProfile={myRecruiterProfile}
              showCandidateRequest={showCandidateRequest}
              setShowCandidateRequest={setShowCandidateRequest}
              candidate={candidate}
              user={user}
              onUpdate={onUpdate}
            />
          </Pane>
        </>
      )}
      {existingRequest?.id && !_.isEqual(existingRequest?.status, "Rejected") && (
        <>
          {hasRecommendations && <RecommendationsButton candidate={candidate} />}
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Paragraph marginRight={majorScale(1)}>{`Sent ${formatDate(existingRequest?.createdAt)}`}</Paragraph>
            {existingRequest?.status === "Active" && (
              <Button appearance="primary" iconBefore={TickIcon} disabled={true}>
                Waiting on response
              </Button>
            )}
            {existingRequest?.status === "Accepted" && (
              <Button
                appearance="primary"
                iconBefore={FolderCloseIcon}
                isDisabled={true}
                isLoading={loading}
                onClick={() =>
                  respondToRequest({
                    variables: {
                      id: existingRequest?.id,
                      status: "Archived"
                    }
                  })
                }
              >
                Archive candidate
              </Button>
            )}
            {existingRequest?.status === "Archived" && (
              <Button
                appearance="primary"
                iconBefore={CrossIcon}
                isDisabled={true}
                isLoading={loading}
                onClick={() =>
                  respondToRequest({
                    variables: {
                      id: existingRequest?.id,
                      status: "Accepted"
                    }
                  })
                }
              >
                Reactivate
              </Button>
            )}
          </Pane>
        </>
      )}
    </Pane>
  );
};

const CandidateCard = ({ candidate, profile, myRecruiterProfile, borderless, existingRequest, onUpdate, isMobile }) => {
  const [showMore, setShowMore] = useState(false);
  const user = candidate?.user?.id ? candidate?.user : profile;
  let truncated;

  if (candidate?.user?.role?.description) {
    truncated = _.truncate(candidate?.user?.role?.description, {
      length: DESCRIPTION_LIMIT
    });
  }

  return (
    <Pane
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      border={borderless ? null : `1px solid ${theme.colors.gray500}`}
      borderRadius={borderless ? null : majorScale(2)}
      padding={borderless ? null : majorScale(2)}
      marginBottom={borderless ? null : majorScale(2)}
    >
      <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(2)}>
        <Pane display="flex" flexDirection="row">
          <Avatar name={user?.fullName} src={user?.picture} size={majorScale(8)} marginRight={majorScale(2)} />
          <Pane display="flex" flexDirection="column" justifyContent="center">
            <Pane display="flex" flexDirection="row">
              <HoverLink to={`/p/${user?.slug}`}>
                <Heading>{user?.fullName}</Heading>
              </HoverLink>
              {user?.pronouns?.length > 0 && <Heading marginLeft={minorScale(1)}>({user?.pronouns})</Heading>}
            </Pane>
            <Pane display="flex" flexDirection={isMobile ? "column" : "row"} marginTop={minorScale(1)}>
              {/* Location */}
              {user?.place?.formatted?.length > 0 && (
                <Pane display="flex" flexDirection="row" marginRight={majorScale(1)} marginBottom={isMobile ? majorScale(1) : undefined}>
                  <MapMarkerIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
                  <Heading size={400}>{user?.place?.formatted}</Heading>
                </Pane>
              )}
              {/* Open to relocation */}
              {candidate?.openToRelocation && (
                <Pane display="flex" flexDirection="row" marginRight={majorScale(1)} marginBottom={isMobile ? majorScale(1) : undefined}>
                  <UnpinIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
                  <Heading size={400}>Open to relocation</Heading>
                </Pane>
              )}
              {/* Remote or hybrid preferred */}
              <Pane display="flex" flexDirection="row" marginRight={majorScale(1)} marginBottom={isMobile ? majorScale(1) : undefined}>
                <MobileVideoIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
                <Heading size={400}>{candidate?.workStylePreference?.includes("hybrid") ? "Hybrid preferred" : "Remote preferred"}</Heading>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <Pane>
          {candidate?.hasResume && (
            <Button appearance="minimal" iconBefore={DocumentOpenIcon} is="a" href={candidate?.resume?.url} target="_blank">
              Resume
            </Button>
          )}
          <Button appearance="minimal" iconBefore={PersonIcon} is={BrowserLink} to={`/p/${user?.slug}`} target={"_blank"}>
            Profile
          </Button>
        </Pane>
      </Pane>
      {user?.role?.team?.name && (
        <Pane marginBottom={majorScale(1)}>
          <Pane display="flex" flexDirection="row">
            {isMobile ? (
              <Pane display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start">
                <Pane display="flex" flexDirection="row" justifyContent="flex-start">
                  <Avatar
                    shape="square"
                    size={majorScale(2)}
                    marginRight={minorScale(1)}
                    src={user?.role?.team?.logo?.length > 0 ? user?.role?.team?.logo : AVATAR_LOGO}
                  />
                  <Heading size={400}>{user?.role?.team?.name}</Heading>
                </Pane>
                <Paragraph>
                  {[user?.role?.companySize, user?.role?.companyIndustry].filter((i) => _.isString(i) && i.length > 0).join(" · ")}
                </Paragraph>
              </Pane>
            ) : (
              <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <Avatar
                  shape="square"
                  size={majorScale(2)}
                  marginRight={minorScale(1)}
                  src={user?.role?.team?.logo?.length > 0 ? user?.role?.team?.logo : AVATAR_LOGO}
                />
                <Heading size={400}>{user?.role?.team?.name}</Heading>
                <Paragraph marginLeft={majorScale(1)}>
                  {[user?.role?.companySize, user?.role?.companyIndustry].filter((i) => _.isString(i) && i.length > 0).join(" · ")}
                </Paragraph>
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
      {candidate?.user?.jobRole?.length > 0 && (
        <Pane marginBottom={majorScale(1)}>
          {isMobile ? (
            <Pane display="flex" flexDirection="column">
              <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="start">
                <BriefcaseIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
                <Heading size={400}>{candidate?.user?.jobRole}</Heading>
              </Pane>
              <Pane display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start">
                <Paragraph>{[user?.role?.title, user?.level].filter((i) => _.isString(i) && i.length > 0).join(" · ")}</Paragraph>
              </Pane>
            </Pane>
          ) : (
            <Pane display="flex" flexDirection="row">
              <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                <BriefcaseIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
                <Heading size={400}>{candidate?.user?.jobRole}</Heading>
                <Paragraph marginLeft={majorScale(1)}>
                  {[user?.role?.title, user?.level].filter((i) => _.isString(i) && i.length > 0).join(" · ")}
                </Paragraph>
              </Pane>
            </Pane>
          )}
        </Pane>
      )}
      {candidate?.specialities.length > 0 && <SkillSection small={true} myCandidateProfile={candidate} />}
      {candidate?.frameworks?.length > 0 && (
        <Pane marginBottom={majorScale(1)}>
          <Pane display="flex" flexDirection="row">
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <WrenchIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
              <Heading size={400}>Frameworks</Heading>
              <Paragraph marginLeft={majorScale(1)}>
                {candidate?.frameworks.filter((i) => _.isString(i) && i.length > 0).join(" · ")}
              </Paragraph>
            </Pane>
          </Pane>
        </Pane>
      )}
      {truncated?.length > 0 && (
        <Pane marginBottom={majorScale(1)}>
          <Pane display="flex" flexDirection="row">
            <Pane display="flex" flexDirection="column" justifyContent="flex-start">
              <Pane display="flex" flexDirection="row" marginBottom={minorScale(1)}>
                <AlignLeftIcon color={theme.colors.gray600} marginRight={minorScale(1)} />
                <Heading size={400}>Recent Experience</Heading>
              </Pane>
              <Paragraph>
                {showMore ? candidate?.user?.role?.description : truncated}
                <Button iconBefore={showMore ? CaretUpIcon : CaretDownIcon} appearance="minimal" onClick={() => setShowMore(!showMore)}>
                  {showMore ? "Show less" : "Show more"}
                </Button>
              </Paragraph>
            </Pane>
          </Pane>
        </Pane>
      )}
      {!borderless && (
        <Pane>
          <ProspectOptions
            existingRequest={existingRequest || candidate?.existingRequest}
            myRecruiterProfile={myRecruiterProfile}
            candidate={candidate}
            user={user}
            onUpdate={onUpdate}
          />
        </Pane>
      )}
    </Pane>
  );
};

export default CandidateCard;
