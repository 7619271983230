import React from "react";

import injectSheet from "react-jss";

import theme from "../theme";

import { Pane } from "evergreen-ui";

import Link from "../Link";

const styles = {
  p: {
    ...theme.p,
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing.grid * 0.75
  },
  h3: {
    ...theme.h4,
    margin: 0,
    padding: 0,
    fontWeight: 500
  },
  s: {
    ...theme.p,
    margin: 0,
    padding: 0,
    color: theme.color.altText,
    paddingTop: theme.spacing.grid * 0.25
  },
  social: {
    fontSize: "24px",
    color: theme.color.text,
    textDecoration: "none",
    marginRight: theme.spacing.grid,
    "&:hover": {
      color: theme.color.brand,
      transition: "color .15s cubic-bezier(0.215, 0.61, 0.355, 1)"
    }
  }
};

const Bio = ({ classes, person }) => {
  const { first, last } = person.name;
  const fullName = `${first} ${last}`;

  const social = [
    {
      className: "fa fa-linkedin",
      link: person.linkedin
    },
    {
      className: "fa fa-twitter",
      link: person.twitter
    }
  ];

  return (
    <Pane>
      <div className={classes.h3}>{fullName}</div>
      <div className={classes.s}>{person.title}</div>
      <div className={classes.p}>{person.bio}</div>
      <Pane display="flex" margin={0} paddingTop={theme.spacing.grid} paddingBottom={theme.spacing.grid}>
        {social.map((icon) => (
          <Link to={icon.link} target={icon.internal ? null : "_blank"} className={`${icon.className} ${classes.social}`}>
            {icon.text ? icon.text : null}
          </Link>
        ))}
      </Pane>
    </Pane>
  );
};

export default injectSheet(styles)(Bio);
