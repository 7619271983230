import React from "react";
import injectSheet from "react-jss";

import { Row, Col, Container } from "react-grid-system";

import Link from "../Link";
import theme from "../theme";

import { Pane } from "evergreen-ui";

const logo = "https://ucarecdn.com/80b87e91-3fdf-4b92-aae7-8f4fb5e5cabd/logoRedDot.svg";

const styles = {
  row: {
    color: theme.color.whiteText,
    paddingLeft: theme.spacing.grid,
    paddingRight: theme.spacing.grid,
    paddingBottom: theme.spacing.grid * 2
  },
  linkHeader: {
    ...theme.p,
    color: theme.color.whiteText,
    textDecoration: "none",
    margin: 0,
    padding: 0
  },
  h3: {
    ...theme.h3,
    color: theme.color.whiteText,
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing.grid,
    paddingTop: theme.spacing.grid * 3
  },
  social: {
    color: theme.color.whiteText,
    textDecoration: "none",
    marginRight: theme.spacing.grid * 1.5,
    marginTop: theme.spacing.grid,
    fontSize: "23px",
    "&:hover": {
      color: theme.color.brand,
      transition: "color .15s cubic-bezier(0.215, 0.61, 0.355, 1)"
    }
  },
  h5: {
    ...theme.h5,
    letterSpacing: "0.15em",
    textTransform: "uppercase",
    color: theme.color.whiteText,
    textAlign: "right",
    margin: 0,
    padding: 0
  },
  s: {
    ...theme.s,
    color: theme.color.whiteText,
    margin: 0,
    padding: 0
  },
  socials: {
    borderTop: `2px solid ${theme.color.altText}`,
    paddingTop: theme.spacing.grid,
    marginTop: theme.spacing.grid
  },
  fullWidth: {
    marginRight: -15,
    marginLeft: -15,
    backgroundColor: theme.color.text,
    width: "100%"
  }
};

const icons = [
  {
    className: "fa fa-linkedin",
    link: "https://www.linkedin.com/company/get-merit/"
  },
  {
    className: "fa fa-twitter",
    link: "https://twitter.com/meritmentor"
  }
];

const FOOTER_LINKS = [
  {
    title: "The Merit Blog",
    link: "https://blog.get-merit.com/"
  },
  {
    title: "Code Of Conduct",
    link: "/code-of-conduct"
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy"
  }
];

const Footer = ({ classes }) => {
  return (
    <div className={classes.fullWidth}>
      <Container>
        <Row align="start" justify="center" className={classes.row}>
          <Col md={3}>
            <img src={logo} alt="logo" style={{ paddingTop: theme.spacing.grid * 3 }} />
            <div>
              {icons.map((icon, index) => (
                <Link
                  to={icon.link}
                  target={icon.internal ? null : "_blank"}
                  className={`${icon.className} ${classes.social}`}
                  key={`Footer-icon-${index}`}
                >
                  {icon.text ? icon.text : null}
                </Link>
              ))}
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.h3}>Info</div>
            {FOOTER_LINKS.map((item) => (
              <p className={classes.linkHeader} key={`Footer-icon-${item.title}`}>
                <Link className={classes.linkHeader} to={item.link} target="_blank">
                  {item.title}
                </Link>
              </p>
            ))}
          </Col>
          <Col md={3}>
            <div className={classes.h3}>Questions?</div>
            <p className={classes.linkHeader}>team@get-merit.com</p>
          </Col>
        </Row>
        <Row align="start" justify="center" className={classes.row}>
          <Col md={12} className={classes.socials}>
            <Pane display="flex" alignItems="center" justifyContent="space-between">
              <div className={classes.s}>
                Made with <span style={{ color: theme.color.love }}> &#9829; </span> in NYC
              </div>
              <div className={classes.h5}>{`© Merit ${new Date().getFullYear()}`}</div>
            </Pane>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default injectSheet(styles)(Footer);
