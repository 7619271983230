import _ from "lodash";
import React from "react";
import { Avatar, Badge, Pane, Text, majorScale } from "evergreen-ui";

class ProfilePreview extends React.Component {
  renderTitle({ profile, createdAt }) {
    const { role } = profile;

    // TODO: clean this up!
    if (!createdAt && role) {
      if (role.team.name && !role.title) {
        return `${role.team.name}`;
      } else {
        return `${role.title}, ${role.team.name}`;
      }
    } else if (!role) {
      return `${createdAt}`;
    } else if (role.team.name && !role.title) {
      return `${role.team.name} • ${createdAt}`;
    } else {
      return `${role.title}, ${role.team.name} • ${createdAt}`;
    }
  }

  renderMentor({ profile }) {
    if (profile?.coach?.id && _.isEqual(profile?.coach?.status, "Active")) {
      return (
        <Badge marginLeft={majorScale(1)} color={"green"}>
          Mentor
        </Badge>
      );
    } else {
      return (
        <Badge marginLeft={majorScale(1)} color={"blue"}>
          Member
        </Badge>
      );
    }
  }

  render() {
    const { profile, createdAt } = this.props;

    return (
      <Pane display="flex">
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Avatar border="default" src={profile.picture} name={profile.fullName} size={majorScale(5)} />
        </Pane>
        <Pane display="flex" flexDirection="row" justifyContent="start" paddingLeft={majorScale(1)} alignItems="center">
          <Text>{profile.fullName}</Text>
          {this.renderMentor({ profile })}
        </Pane>
      </Pane>
    );
  }
}

export default ProfilePreview;
