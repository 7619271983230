import _ from "lodash";
import React, { useState } from "react";
import {
  Button,
  Pane,
  Heading,
  Text,
  Switch,
  majorScale,
  minorScale,
  defaultTheme as theme,
  SearchIcon,
  CalendarIcon,
  OfficeIcon,
  BookIcon,
  CubeIcon
} from "evergreen-ui";

import { SearchBar, FilterSelect, SortSelect, formatLabel, formatTopicLabel, FilterToggle } from "./FiltersRow";
import { FILTER_TYPE } from "../NewMentorsPage";
import LocationAutocomplete from "../../../components/LocationAutocomplete";
import { INITIAL_FILTERS } from "../NewMentorsPage";

const FiltersHeading = ({ title, icon }) => {
  const Icon = icon;

  return (
    <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
      <Icon size={majorScale(2)} color={theme.colors.gray600} marginRight={majorScale(1)} />
      <Heading size={400}>{title}</Heading>
    </Pane>
  );
};

const MentorFilters = ({ selectedFilters = {}, filterData = {}, onFilterUpdate, onLocationUpdate, onFiltersReset, locationInputRef }) => {
  const [locKey, setLocKey] = useState(0);
  const { availableJobRoles, availableLevels, availableTopics } = filterData;

  return (
    <Pane display="flex" flexDirection="column" padding={majorScale(2)} width="100%">
      <Pane
        display="flex"
        flexDirection="column"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray500}`}
        borderRadius={majorScale(2)}
        padding={majorScale(2)}
        marginBottom={majorScale(2)}
        width="100%"
      >
        <Pane display="flex" flexDirection="row" marginBottom={majorScale(1)}>
          <SearchIcon size={majorScale(2)} marginRight={majorScale(1)} color={theme.colors.gray900} />
          <Heading size={500} marginBottom={majorScale(2)}>
            Find a mentor
          </Heading>
        </Pane>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(1)}>
          <FiltersHeading title="Availability" icon={CalendarIcon} />
          <Pane marginBottom={majorScale(1)}>
            <FilterToggle
              type={FILTER_TYPE.TIME}
              title="Available this week"
              selected={selectedFilters?.availableSoon}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> available this week"
            />
          </Pane>
        </Pane>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(2)}>
          <FiltersHeading title="Company" icon={OfficeIcon} />
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Industry"
              type={FILTER_TYPE.INDUSTRY}
              selected={selectedFilters?.industry}
              options={filterData?.industry}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> Industry"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Team size"
              type={FILTER_TYPE.COMPANY_SIZE}
              selected={selectedFilters?.companySize}
              options={filterData?.companySize}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> Company size"
            />
          </Pane>
        </Pane>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(2)}>
          <FiltersHeading title="Expertise" icon={BookIcon} />
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Roles"
              type={FILTER_TYPE.ROLES}
              selected={selectedFilters?.roles}
              options={availableJobRoles?.map(formatLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> Job role"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Levels"
              type={FILTER_TYPE.LEVELS}
              selected={selectedFilters?.levels}
              options={availableLevels?.map(formatLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> Job level"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Topics"
              type={FILTER_TYPE.TOPICS}
              selected={selectedFilters?.topics}
              options={availableTopics?.map(formatTopicLabel)}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> Topics"
            />
          </Pane>
          <Pane display="flex" flexDirection="column" marginBottom={majorScale(1)} width="100%">
            <FilterSelect
              title="Skills"
              type={FILTER_TYPE.SKILLS}
              selected={selectedFilters?.skills}
              options={filterData?.skills}
              onFilterUpdate={onFilterUpdate}
              trackEvent="Filter mentors -> skills"
            />
          </Pane>
        </Pane>

        <Pane flexDirection="column" display="flex" marginBottom={majorScale(1)}>
          <FiltersHeading title="Even more" icon={CubeIcon} />
          <Pane marginBottom={majorScale(1)}>
            <LocationAutocomplete
              key={locKey}
              type={FILTER_TYPE.LOCATION}
              title="Search by location"
              disableUserUpdate={true}
              onSelected={onLocationUpdate}
              locationInputRef={locationInputRef}
              fullWidth={true}
              trackEvent="Filter mentors -> location"
            />
          </Pane>
          <SearchBar type={FILTER_TYPE.SEARCH} value={selectedFilters?.name} onFilterUpdate={onFilterUpdate} fullWidth={true} />
        </Pane>

        {!_.isEqual(selectedFilters, INITIAL_FILTERS) && (
          <Pane marginRight={majorScale(1)}>
            <Button
              width="100%"
              appearance="minimal"
              onClick={() => {
                setLocKey(locKey + 1);
                onFiltersReset();
              }}
              paddingX={majorScale(1)}
            >
              Clear filters
            </Button>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

export default MentorFilters;
