import _ from "lodash";
import React, { useState } from "react";
import queryString from "query-string";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Badge, Pane, Text, Button, Heading, RadioGroup, majorScale, minorScale, toaster } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";

import AuthQueries from "../../../queries/auth";
import ProfileList from "../../../queries/profile/ProfileList";
import TextInput from "../../form/TextInput";
import LocationAutocomplete from "../../LocationAutocomplete";
import { inNorthAmerica } from "../../../routes/auth/LocationCheck";

const CreateAccountSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Please include your full name")
    .max(100, "Please shorten the name you put in")
    .required("Please provide your name"),
  mode: Yup.string().required(),
  place: Yup.string().required("Please provide your location."),
  password: Yup.string()
    .min(4, "Please include your new password")
    .max(50, "Please shorten the password you use")
    .required("Please include a password")
});

const GoogleAuthSchema = Yup.object().shape({
  mode: Yup.string().required(),
  place: Yup.string().required("Please provide your location.")
});

const modes = [
  { value: "Learn", label: "Learn from others" },
  { value: "Coach", label: "Mentor others in tech" },
  { value: "Both", label: "Both learn from and mentor people in tech" }
];

const jobSearchModes = [
  { value: "active", label: "Ready to interview and actively searching" },
  { value: "passive", label: "Open to exploring new opportunities" },
  { value: "inactive", label: "Not open to considering new opportunities" },
  { value: "hiring", label: "I’m trying to hire" }
];

const CreateAccountForm = ({ redirect, isMobile, myProfile, groupId, mode, history, location: { search } }) => {
  const [conductCheck, setConductCheck] = useState(false);
  const { google, question } = queryString.parse(search, { arrayFormat: "comma" });
  const googleAuth = google && _.isEqual(google, "true");

  const [createPassword] = useMutation(googleAuth ? AuthQueries.mutations.CreateAccountFromGoogle : AuthQueries.mutations.CreatePassword, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }],
    onCompleted: (results) => {
      if (window && window.analytics) {
        window.analytics.track("Complete Create Account");
      }

      // Set the new jwt and have that last for much longer
      // than the temporary token we gave them earlier
      const { jwt, mode, applicationStatus, jobSearchMode } = _.head(_.values(results));
      Cookies.set("token", jwt, { expires: 360 });

      if (applicationStatus?.includes("Waitlist")) {
        history.push("/waitlist");
      } else {
        // If they're actively or passively looking for a job, send them to the job application
        // Otherwise, send them to the mentor application or discover page
        if (jobSearchMode?.includes("hiring")) {
          toaster.success("You’ll be contacted by our team to your email about hiring on Merit!", { duration: 2 });
        }
        if (mode && _.isEqual(mode, "Learn")) {
          if (!jobSearchMode?.includes("inactive") && !jobSearchMode?.includes("hiring")) {
            history.push(`/candidate/setup/0`);
          } else {
            let nextPage = question ? `/discover?hello=true&question=true` : `/discover?hello=true`;

            history.push(nextPage);
          }
        } else {
          window.analytics.track("Request Mentor Profile");
          history.push(`/apply/0`);
        }
      }
    }
  });

  return (
    <Formik
      validationSchema={googleAuth ? GoogleAuthSchema : CreateAccountSchema}
      initialValues={{
        name: "",
        mode: !_.isEmpty(mode) && mode?.length > 0 ? mode : "Learn",
        password: "",
        groupId: groupId ? groupId : "",
        place: myProfile?.place?.formatted ? myProfile.place.formatted : "",
        jobSearchMode: "active"
      }}
      onSubmit={(variables, { setSubmitting }) => {
        createPassword({ variables });
        setSubmitting(false);
      }}
      render={({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Pane padding={isMobile ? null : majorScale(2)}>
            <Heading size={700} marginBottom={majorScale(4)}>
              👥 Create your account
            </Heading>

            {googleAuth ? null : (
              <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
                <Heading paddingBottom={minorScale(2)}>Enter your full name</Heading>
                <Field type="text" name="name" placeholder="Your name" height={majorScale(4)} component={TextInput} />
              </Pane>
            )}

            {googleAuth ? null : (
              <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
                <Heading paddingBottom={minorScale(2)}>Create your password</Heading>
                <Field type="password" name="password" placeholder="Your password" component={TextInput} height={majorScale(4)} />
              </Pane>
            )}

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
              <Heading paddingBottom={minorScale(2)}>Where are you located?</Heading>
              <Text paddingBottom={minorScale(2)}>Merit works best for those working in the US or Canada.</Text>
              <LocationAutocomplete myProfile={myProfile} onSelected={(chosen) => setFieldValue("place", chosen, false)} />
              {touched["place"] && errors["place"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["place"]}
                </Badge>
              )}
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
              <Heading paddingBottom={minorScale(2)}>How do you want to use Merit?</Heading>
              <Pane paddingBottom={minorScale(2)}>
                <Text>If you're looking to mentor others, after you complete signup, we'll follow up with more information.</Text>
              </Pane>
              <RadioGroup defaultValue={values?.mode} options={modes} onChange={(e) => setFieldValue("mode", e.target.value, false)} />
              {touched["mode"] && errors["mode"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["mode"]}
                </Badge>
              )}
            </Pane>

            <Pane display="flex" flexDirection="column" marginBottom={majorScale(4)}>
              <Heading paddingBottom={minorScale(2)}>Are you currently looking for a job?</Heading>
              <Pane paddingBottom={minorScale(2)}>
                <Text>
                  {
                    "We can add you to our talent directory and connect you with jobs. If you’re actively looking, we’ll ask a few more questions."
                  }
                </Text>
              </Pane>
              <RadioGroup
                defaultValue={values?.jobSearchMode}
                options={jobSearchModes}
                onChange={(e) => setFieldValue("jobSearchMode", e.target.value, false)}
              />
              {touched["jobSearchMode"] && errors["jobSearchMode"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["jobSearchMode"]}
                </Badge>
              )}
            </Pane>

            <Pane display="flex" justifyContent="flex-end">
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                Create account
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default withRouter(CreateAccountForm);
