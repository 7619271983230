import React from "react";
import { useScreenClass } from "react-grid-system";
import { Link, Pane, ChevronRightIcon, defaultTheme as theme } from "evergreen-ui";

import { Link as BrowserLink } from "react-router-dom";

const ShowMore = ({ to }) => {
  const screenClass = useScreenClass();
  const isSmallScreen = ["xs", "sm", "md"].includes(screenClass);
  return (
    <Link is={BrowserLink} to={to}>
      <Pane color={theme.colors.blue500} fontWeight={500} minWidth={75}>
        {isSmallScreen ? <ChevronRightIcon size={24} /> : <span>Show more</span>}
      </Pane>
    </Link>
  );
};
export default ShowMore;
