import _ from "lodash";
import React, { useState } from "react";
import { Button, Dialog, HeartIcon, Pane, Text, majorScale } from "evergreen-ui";
import { useMutation } from "@apollo/client";

import FeedQueries from "../../queries/feed";
import EmailSignup from "../../routes/landing/EmailSignup";
import { learnersCTA } from "../../content/learners";

const SIGNUP_HEADER = "New to Merit?";
const SIGNUP_TEXT = "Sign up to talk to senior leaders in product, design & engineering.";

const findObj = ({ reply, comment, post }) => {
  if (reply?.id) return { commentId: reply.id };
  else if (comment?.id) return { commentId: comment.id };
  else return { postId: post.id };
};

// Takes a series of reaction responses and renders your view of it
const Upvote = ({ reply, comment, post, myProfile, responses }) => {
  const [showSignup, setShowSignup] = useState(false);

  const myReaction = myProfile ? _.find(responses?.edges, ["owner.id", myProfile.id]) : false;

  const [addReaction, { data: added }] = useMutation(FeedQueries.mutations.AddReaction, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.PostById, variables: { id: post?.id } },
      {
        query: FeedQueries.queries.FeedQuery,
        variables: { cursor: "1", sort: "POPULAR" }
      },
      {
        query: FeedQueries.queries.Comments,
        variables: { postId: post?.id }
      },
      {
        query: FeedQueries.queries.Replies,
        variables: { commentId: comment?.id }
      }
    ]
  });

  const [removeReaction, { data: removed }] = useMutation(FeedQueries.mutations.RemoveReaction, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: FeedQueries.queries.PostById, variables: { id: post?.id } },
      {
        query: FeedQueries.queries.FeedQuery,
        variables: { cursor: "1", sort: "POPULAR" }
      },
      {
        query: FeedQueries.queries.Comments,
        variables: { postId: post?.id }
      },
      {
        query: FeedQueries.queries.Replies,
        variables: { commentId: comment?.id }
      }
    ]
  });

  // Assume this is a comment if there's a comment and id, otherwise it's a post
  const obj = findObj({ reply, comment, post });
  const copy = responses?.edges?.length ? `${responses.edges.length}` : ``;
  let clickHandler;

  if (myProfile?.id) {
    clickHandler = () => {
      if (myReaction) {
        removeReaction({
          variables: {
            id: myReaction.id
          }
        });
      } else {
        addReaction({
          variables: {
            reaction: `👍`,
            ...obj
          }
        });
      }
    };
  } else {
    clickHandler = () => setShowSignup(true);
  }

  return (
    <>
      <Dialog
        title={SIGNUP_HEADER}
        isShown={showSignup}
        onCloseComplete={() => setShowSignup(false)}
        preventBodyScrolling
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
        hasFooter={false}
      >
        {showSignup && (
          <>
            <Pane display="flex" flexDirection="row" width="100%" marginBottom={majorScale(3)}>
              <Text>{SIGNUP_TEXT}</Text>
            </Pane>
            <EmailSignup mode="Learn" cta={learnersCTA} showGoogle={true} />
          </>
        )}
      </Dialog>
      <Button
        key={`Upvote-${obj?.id}`}
        size="small"
        background="#F4F5F9"
        fontWeight={600}
        appearance="minimal"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={myReaction ? "#1E1EC6" : null}
        marginRight={8}
        height={28}
        borderRadius={999}
        onClick={clickHandler}
        fontSize={14}
      >
        <HeartIcon marginRight={copy ? 5 : 0} />
        {copy}
      </Button>
    </>
  );
};

export default Upvote;
