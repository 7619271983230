import Layout from "../../components/layout/Layout";
// import CallView from './CallView'
// import ReviewCallForm from './ReviewCallForm'
// import BookSessionView from './BookSessionView'
// import FeedbackList from './FeedbackList'
// import NoShowForm from './NoShowForm'
import ReviewDetail from "../admin/ReviewDetail";
import FeedbackForm from "../session/FeedbackForm";
import FeedbackSubmitted from "../session/FeedbackSubmitted";

import withRoutes from "../withRoutes";

export default withRoutes(Layout, [
  {
    path: "/calls/:id/review",
    component: FeedbackForm,
    state: "Sessions",
    privateRoute: true,
    reviewRoute: true
  },
  {
    path: "/feedback/:id/submitted",
    component: FeedbackSubmitted,
    state: "Sessions",
    privateRoute: true,
    reviewRoute: true
  },
  // {
  //   path: "/calls/:id/no-show",
  //   component: NoShowForm,
  //   state: 'Sessions',
  //   privateRoute: true,
  //   reviewRoute: true,
  // },
  // {
  //   exact: true,
  //   path: "/calls/:id",
  //   component: CallView,
  //   state: 'Sessions',
  //   privateRoute: true,
  //   reviewRoute: true,
  // },
  {
    path: "/feedback/review/:reviewID",
    component: ReviewDetail,
    state: "Feedback",
    privateRoute: true,
    reviewRoute: true
  }
  // {
  //   path: "/booking/:coach/:playlist",
  //   component: BookSessionView,
  //   state: 'Browse',
  // },
  // {
  //   exact: true,
  //   path: "/calls/:id/:persona",
  //   component: CallView,
  //   state: 'Sessions',
  //   privateRoute: true,
  // },
]);
