import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Pane,
  Heading,
  Strong,
  Avatar,
  Alert,
  Text,
  Badge,
  Button,
  Link,
  CalendarIcon,
  IconButton,
  PersonIcon,
  ShareIcon,
  ChatIcon,
  StarIcon,
  EditIcon,
  Dialog,
  majorScale,
  minorScale,
  Card,
  Paragraph,
  Pill,
  TabNavigation,
  Tab,
  defaultTheme as theme,
  OfficeIcon
} from "evergreen-ui";
import { Row, Col, Container, ScreenClassRender } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Link as BrowserLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { head, isEqual } from "lodash";
import { fromUnixTime, parseISO, format } from "date-fns";
import { useScreenClass } from "react-grid-system";
import qs from "query-string";

import ProfileQuery from "../../components/profile/ProfileQuery";
import SessionQueries from "../../queries/session";
import MentorCard from "../../components/mentor/MentorCard";
import CoachList from "../../routes/coach/CoachList";
import PlaylistCard from "./PlaylistCard";
import CoachReminderButton from "../../components/profile/CoachReminderButton";
import ProfileStrength from "../../components/profile/ProfileStrength/index.js";
import AnswerCard from "../../components/discussion/AnswerCard";
import RecentQuestions from "./components/RecentQuestions";
import ProfilePaused from "../../components/profile/ProfilePaused";

import Job from "../../components/team/Job";
import Meta from "./ProfileMeta";
import SignupDialog from "../../components/SignupDialog";

import ProfileOverviewForm from "./edit/ProfileOverviewForm";
import EditProfilePhoto from "./edit/EditProfilePhoto";
import EditWorkExperienceContainer from "./edit/EditWorkExperienceContainer";
import EditEducationContainer from "./edit/EditEducationContainer";
import EditGroupsForm from "./edit/EditGroupsForm";
import EditAboutMe from "./edit/EditAboutMe";
import EditMotivations from "./edit/EditMotivations";
import EditCalendar from "./edit/EditCalendar";
import EditTopics from "./edit/EditTopics";
import MentorSetup from "./edit/MentorSetup";
import ZoomSetup from "./edit/ZoomSetup";
import HumanitySetup from "./edit/HumanitySetup";
import ProfileShare from "./edit/ProfileShare";
import BookSession from "./scheduler/BookSession";
import ExpertiseSection from "./components/ExpertiseSection";

import CandidateJobSearch from "./components/CandidateJobSearch";
import CandidateSkills from "./components/CandidateSkills";
import CandidateWorkExperience from "./components/CandidateWorkExperience";
import CandidateTeamPreferences from "./components/CandidateTeamPreferences";
import CandidateHiddenCompanies from "./components/CandidateHiddenCompanies";

import ProfileBackground from "./components/ProfileBackground";
import CalendarView from "./components/CalendarView";
import RequestView from "./components/RequestView";
import EmptyView from "./components/EmptyView";
import LoadingView from "./components/LoadingView";
import CandidateQueries from "../../queries/candidate";
import ResumeNotif from "../../components/candidate/ResumeNotif";
import { ProspectOptions } from "../../components/candidate/CandidateCard";

const AVATAR_LOGO = "https://ucarecdn.com/3892b394-7718-4ecf-88bb-d559bb5a2b99/office.svg";

const RoleToColors = {
  Management: "blue",
  "Software Engineering": "green",
  "Product Management": "orange",
  "Product Design": "purple",
  Other: "neutral"
};

const MotivationToColors = {
  "Meet People": "orange",
  "Practice Coaching": "yellow",
  "Skill Training": "green",
  "Give back to the community": "blue",
  "Learn from Others": "purple",
  Other: "neutral"
};

const ProfileInformation = (infoContext) => {
  const { profile, isSmallScreen, isEditing, showOverview, showShare, setShowShare, isCurrentUser, hasConversation } = infoContext;

  const overview = ({ place, jobRoles }) => {
    const items = [place?.formatted, jobRoles[0]];

    return items.filter((item) => _.isString(item) && item.length > 0).join(" · ");
  };

  return (
    <Pane paddingBottom={majorScale(3)}>
      {isEditing ? (
        <EditProfilePhoto myProfile={profile} isSmallScreen={isSmallScreen} />
      ) : (
        <Pane display="flex" justifyContent="space-between" alignItems="flex-end">
          <Avatar
            src={profile.picture}
            name={profile.fullName}
            size={isSmallScreen ? 140 : 175}
            marginTop="-80px"
            border="6px solid white"
          />
          {!isCurrentUser && <MessageButton connection={hasConversation} />}
        </Pane>
      )}
      <Pane display="flex" marginTop="16px" marginBottom="4px" alignItems="center">
        <Heading is="h1" size={700}>
          {profile.fullName}
        </Heading>
        {profile.pronouns && (
          <Heading is="h4" size={500} marginLeft="6px">
            ({profile.pronouns})
          </Heading>
        )}
        {isEditing && <ProfileOverviewForm myProfile={profile} showEditor={showOverview} />}
      </Pane>
      <Pane display="flex" flexDirection="column">
        {profile.role?.team && (
          <Text size={500} marginBottom={profile?.place?.formatted ? minorScale(1) : null}>
            {profile.role.title} at <Strong size={500}>{profile.role?.team?.name}</Strong>
          </Text>
        )}
        {overview(profile) && !_.isEqual(overview(profile), "undefined") && <Text size={400}>{overview(profile)}</Text>}
      </Pane>
      <Pane display="flex" alignItems="center" marginTop="16px">
        {profile.linkedin && (
          <Link target="_blank" href={profile.linkedin}>
            <Button width="32px" height="32px" color="#8F95B2" padding="10px" marginRight="12px">
              <FontAwesomeIcon size="xs" icon={faLinkedinIn} />
            </Button>
          </Link>
        )}
        {profile.twitter && (
          <Link target="_blank" href={profile.twitter}>
            <Button width="32px" height="32px" color="#8F95B2" padding="10px" marginRight="12px">
              <FontAwesomeIcon size="xs" icon={faTwitter} />
            </Button>
          </Link>
        )}
        {profile.website && (
          <Link target="_blank" href={profile.website}>
            <Button width="32px" height="32px" color="#8F95B2" padding="10px">
              <FontAwesomeIcon size="xs" icon={faExternalLinkAlt} />
            </Button>
          </Link>
        )}
      </Pane>
      {isEditing && showShare && <ProfileShare setShowShare={setShowShare} myProfile={profile} showShare={showShare} />}
    </Pane>
  );
};

const ProfileActions = ({
  profile,
  currentUser,
  coachByProfileId,
  showBookMe,
  isAvailable,
  hasConversation,
  isEditing,
  showRelated,
  showCalendar,
  showRequest,
  description,
  isAcceptedMentor
}) => {
  const iAmPaused = currentUser?.isPaused?.start?.length > 0;

  return (
    <Pane display="flex" alignItems="center" justifyContent="space-between" marginBottom={majorScale(2)}>
      <Pane display="flex" marginLeft={iAmPaused ? null : "auto"} flexDirection="row" alignItems="center" width="100%">
        {isEditing && isAcceptedMentor && (
          <Pane display="flex" width="100%" justifyContent="flex-end">
            <Button appearance="primary" iconBefore={EditIcon} is={BrowserLink} to={"/mentor/settings"}>
              Edit Mentor Settings
            </Button>
          </Pane>
        )}
        {!isEditing && showBookMe && (
          <BookSession profile={profile} coachByProfileId={coachByProfileId} myProfile={currentUser} hasConversation={hasConversation} />
        )}
        {/*!isEditing && showBookMe && coachByProfileId?.approveSession && (
          width={iAmPaused ? "100%" : undefined}
          <RequestView
            profile={profile}
            coachByProfileId={coachByProfileId}
            myProfile={currentUser}
            isCurrentUser={isCurrentUser}
            hasConversation={hasConversation}
            showRequest={showRequest}
            description={description}
            showCalendar={showCalendar}
          />
        )*/}
        {/*!isEditing && showBookMe &&
          !coachByProfileId?.approveSession && (
            <CalendarView
              profile={profile}
              coachByProfileId={coachByProfileId}
              myProfile={currentUser}
              isCurrentUser={isCurrentUser}
              hasConversation={hasConversation}
              showCalendar={showCalendar}
              description={description}
              hideButton={!coachByProfileId?.openEnded}
            />
          ) */}
        {!isEditing && showRelated && (
          <>
            {iAmPaused ? (
              <ProfilePaused isPaused={currentUser?.isPaused} myProfile={currentUser} />
            ) : (
              <CoachReminderButton coach={coachByProfileId} flat />
            )}
          </>
        )}
      </Pane>
    </Pane>
  );
};

const MessageButton = ({ connection }) => {
  return connection?.id ? (
    <Button iconBefore={ChatIcon} appearance="minimal" is={BrowserLink} to={`/conversation/${connection?.id}`} marginLeft={majorScale(1)}>
      Message
    </Button>
  ) : null;
};

const ProfileTopics = ({ topics, profile, coachByProfileId, reviews }) => {
  const [showMore, setShowMore] = useState(false);
  if (!coachByProfileId) return null;

  const cutoff = reviews?.length > 0 ? 3 : 6;
  const numItems = showMore ? topics.length : cutoff;
  const numCutoff = topics.length - cutoff;
  return (
    topics && (
      <Pane>
        <Row gutterWidth={8}>
          {topics.slice(0, numItems).map((topic) => (
            <Col md={4}>
              <Pane flex="1" width="100%">
                <PlaylistCard playlist={topic} profile={profile} coach={coachByProfileId} />
              </Pane>
            </Col>
          ))}
        </Row>
        {topics.length > cutoff && (
          <Pane display="flex" justifyContent="flex-end">
            <Button paddingX="10px" color="#3366FF" appearance="minimal" onClick={() => setShowMore(!showMore)}>
              {showMore ? "Show Less" : `See ${numCutoff} more topic${numCutoff !== 1 ? "s" : ""}`}
            </Button>
          </Pane>
        )}
      </Pane>
    )
  );
};

const ProfileIndustries = ({ industries }) => {
  const screenClass = useScreenClass();
  const isSmallScreen = ["xs", "sm", "md"].includes(screenClass);
  const [showMore, setShowMore] = useState(isSmallScreen ? false : true);

  return (
    industries?.length > 0 && (
      <Pane paddingY={majorScale(2)} borderTop="1px solid #E6E8F0">
        <Pane display="flex" alignItems="center" justifyContent="space-between" marginBottom={majorScale(isSmallScreen ? 0 : 1)}>
          <Heading fontWeight={500}>Industries</Heading>
          {isSmallScreen && (
            <Button appearance="minimal" paddingX="10px" onClick={() => setShowMore(!showMore)}>
              <FontAwesomeIcon size="sm" icon={showMore ? faChevronUp : faChevronDown} />
            </Button>
          )}
        </Pane>
        {showMore && (
          <Pane marginBottom={majorScale(1)}>
            {industries.slice(0, 2).map((item) => (
              <Pill key={item?.id} paddingX={majorScale(1)} marginRight={majorScale(1)} marginTop={majorScale(1)}>
                {item?.name}
              </Pill>
            ))}
          </Pane>
        )}
      </Pane>
    )
  );
};

const ProfileDemographics = (context) => {
  const { title, items, isEditing, editForm, myProfile, hasConversation, isCurrentUser } = context;

  const [showEditor, setShowEditor] = useState(context?.showEditor || false);
  const screenClass = useScreenClass();
  const isSmallScreen = ["xs", "sm", "md"].includes(screenClass);
  const isMobile = screenClass.includes("xs");
  const cutoff = isSmallScreen ? 0 : 3;
  const [showMore, setShowMore] = useState(false);
  const numItems = showMore ? items.length : cutoff;
  const EditForm = isEditing && editForm ? editForm : null;

  const _items = items?.filter((item) => item?.id);
  return (
    (isEditing || _items?.length > 0) && (
      <Pane paddingY={majorScale(2)} borderTop="1px solid #E6E8F0">
        <Pane display="flex" alignItems="center" justifyContent="space-between" marginBottom={majorScale(isSmallScreen ? 0 : 1)}>
          {isMobile ? (
            <Pane alignItems="center" display="flex" flexDirection="row">
              <Heading fontWeight={500}>{title}</Heading>
              {isEditing && <IconButton icon={EditIcon} appearance="minimal" onClick={() => setShowEditor(true)} />}
            </Pane>
          ) : (
            <>
              <Heading fontWeight={500}>{title}</Heading>
              {isEditing && <IconButton icon={EditIcon} appearance="minimal" onClick={() => setShowEditor(true)} />}
            </>
          )}
          {isSmallScreen && (
            <Button appearance="minimal" paddingX="10px" onClick={() => setShowMore(!showMore)}>
              <FontAwesomeIcon size="sm" icon={showMore ? faChevronUp : faChevronDown} />
            </Button>
          )}
        </Pane>
        {editForm && showEditor && (
          <Dialog
            isShown={showEditor}
            title={`Edit ${title}`}
            onCloseComplete={() => setShowEditor(false)}
            confirmLabel="Finish"
            hasCancel={false}
            preventBodyScrolling={true}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
          >
            <EditForm myProfile={myProfile} />
          </Dialog>
        )}
        {_items.slice(0, numItems).map((item) => {
          return (
            <Pane key={item?.id} display="flex" alignItems="center" paddingX={majorScale(1)} paddingY={minorScale(3)}>
              <Avatar
                src={item?.logo ? item.logo : "https://ucarecdn.com/3892b394-7718-4ecf-88bb-d559bb5a2b99/office.svg"}
                objectFit="contain"
                borderRadius={0}
                size={16}
              />
              <Text marginLeft={majorScale(2)}>{item?.name}</Text>
            </Pane>
          );
        })}
        {!isSmallScreen && _items.length > cutoff && (
          <Button paddingX="10px" color="#3366FF" appearance="minimal" marginTop={majorScale(1)} onClick={() => setShowMore(!showMore)}>
            {showMore ? "See less" : "See more"}
          </Button>
        )}
      </Pane>
    )
  );
};

const SimilarMentors = ({ coachByProfileId, profile }) => {
  const [showMore, setShowMore] = useState(false);
  const { data: relatedMentors } = useQuery(CoachList.LeaderQuery, {
    variables: {
      cursor: "1",
      name: "",
      levels: profile?.level ? [profile.level] : [],
      sort: "DEFAULT",
      roles: [...profile?.jobRoles],
      topics: coachByProfileId?.playlists?.length ? coachByProfileId?.playlists.map((p) => p?.id) : []
    }
  });

  const screenClass = useScreenClass();
  const isSmallScreen = ["xs", "sm", "md"].includes(screenClass);
  const cutoff = isSmallScreen ? 0 : 3;
  const numItems = showMore ? relatedMentors?.leaders?.edges?.length : cutoff;

  const _mentors = relatedMentors?.leaders?.edges?.length
    ? relatedMentors?.leaders?.edges.filter((m) => m.user && m.user.id !== profile.id)
    : null;

  return _mentors?.length ? (
    <>
      <Row>
        <Col>
          <Pane marginTop={majorScale(2)}>
            <Heading fontWeight={500}>{`Similar mentors to ${profile?.name?.first}`}</Heading>
          </Pane>
        </Col>
      </Row>
      <Row style={{ marginTop: majorScale(2) }}>
        {_mentors.slice(0, numItems).map((mentor) => (
          <Col md={4} key={mentor?.id}>
            <Pane marginBottom={showMore ? minorScale(10) : 0}>
              <MentorCard user={mentor?.user} nextAvailable={mentor?.nextAvailable} />
            </Pane>
          </Col>
        ))}
      </Row>
      {!isSmallScreen && _mentors?.length > cutoff && (
        <Row>
          <Col>
            <Button paddingX="10px" color="#3366FF" appearance="minimal" marginTop={majorScale(1)} onClick={() => setShowMore(!showMore)}>
              {showMore ? "See less" : "See more"}
            </Button>
          </Col>
        </Row>
      )}
    </>
  ) : null;
};

const ProfileRecommendations = ({ recommendations, profile, isMobile }) => {
  const cutoff = 3;
  const [showMore, setShowMore] = useState(false);
  const numItems = showMore ? recommendations.length : cutoff;

  return (
    recommendations.length > 0 && (
      <Pane marginTop={majorScale(2)} id="recommendations">
        <Heading size={500}>
          <StarIcon marginRight={majorScale(1)} />
          Recommendations
        </Heading>
        {profile?.name?.first && (
          <Paragraph size={500} marginBottom={majorScale(1)}>
            {`Here’s what Merit users have said about ${profile?.name?.first}`}
          </Paragraph>
        )}
        {recommendations.slice(0, numItems).map((recommendation) => {
          let timestamp;

          try {
            timestamp = format(parseISO(recommendation.createdAt), "MMMM do yyyy");
          } catch (err) {
            timestamp = format(fromUnixTime(recommendation?.createdAt / 1000), "MMM do yyyy");
          }

          return (
            <Card
              backgroundColor="white"
              border={`1px solid ${theme.colors.gray500}`}
              borderRadius={majorScale(2)}
              padding={majorScale(2)}
              marginBottom={majorScale(3)}
              key={recommendation.id}
            >
              {recommendation?.response?.length > 0 && (
                <Paragraph size={500} marginBottom={majorScale(2)}>
                  {recommendation?.response}
                </Paragraph>
              )}
              <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Pane display="flex" alignItems="center">
                  <Avatar src={recommendation?.owner?.picture} name={recommendation?.owner?.fullName} size={42} />
                  <Pane marginLeft={majorScale(2)} display="flex" flexDirection="column">
                    <Text size={500} fontWeight={500}>
                      {recommendation?.owner?.fullName}
                    </Text>
                    {recommendation?.owner?.role?.team?.name && (
                      <Pane display="flex" flexDirection="row">
                        {isMobile ? (
                          <Pane display="flex" flexDirection="column" justifyContent="flex-start" alignItems="start">
                            <Pane display="flex" flexDirection="row" justifyContent="flex-start">
                              <Avatar
                                shape="square"
                                size={majorScale(2)}
                                marginRight={minorScale(1)}
                                src={
                                  recommendation?.owner?.role?.team?.logo?.length > 0
                                    ? recommendation?.owner?.role?.team?.logo
                                    : AVATAR_LOGO
                                }
                              />
                              <Heading size={400}>{recommendation?.owner?.role?.team?.name}</Heading>
                            </Pane>
                            <Paragraph>
                              {[recommendation?.owner?.role?.title, recommendation?.owner?.role?.companySize]
                                .filter((i) => _.isString(i) && i.length > 0)
                                .join(" · ")}
                            </Paragraph>
                          </Pane>
                        ) : (
                          <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                            <Avatar
                              shape="square"
                              size={majorScale(2)}
                              marginRight={minorScale(1)}
                              src={
                                recommendation?.owner?.role?.team?.logo?.length > 0 ? recommendation?.owner?.role?.team?.logo : AVATAR_LOGO
                              }
                            />
                            <Heading size={400}>{recommendation?.owner?.role?.team?.name}</Heading>
                            <Paragraph marginLeft={majorScale(1)}>
                              {[recommendation?.owner?.role?.title, recommendation?.owner?.role?.companySize]
                                .filter((i) => _.isString(i) && i.length > 0)
                                .join(" · ")}
                            </Paragraph>
                          </Pane>
                        )}
                      </Pane>
                    )}
                  </Pane>
                </Pane>
                <Text display="block" textTransform="uppercase" fontWeight={500} size={300}>
                  {timestamp}
                </Text>
                {/* {myProfile?.id && _.isEqual(myProfile?.id, review?.user?.id) && (
                  <Button
                    appearance="minimal"
                    is={BrowserLink}
                    to={`/calls/${review?.session?.id}/review`}>
                    Edit
                  </Button>
                )} */}
              </Pane>
            </Card>
          );
        })}
        {recommendations.length > cutoff && (
          <Pane display="flex" justifyContent="flex-end">
            <Button paddingX="10px" color="#3366FF" appearance="minimal" onClick={() => setShowMore(!showMore)}>
              {showMore ? "See less recommendations" : "See all recommendations"}
            </Button>
          </Pane>
        )}
      </Pane>
    )
  );
};

const ProfileReviews = ({ reviews, myProfile }) => {
  const cutoff = 3;
  const [showMore, setShowMore] = useState(false);
  const numItems = showMore ? reviews.length : cutoff;

  return (
    reviews.length > 0 && (
      <Pane marginTop={majorScale(2)} id="reviews">
        {reviews.slice(0, numItems).map((review) => {
          const outcome =
            review.responses[1] && isEqual(review.responses[1].question, "What did you learn from this session?")
              ? review.responses[1].answer
              : "";

          const response = head(review.responses).answer;

          let timestamp;

          try {
            timestamp = format(parseISO(review.createdAt), "MMMM do yyyy");
          } catch (err) {
            timestamp = format(fromUnixTime(review?.createdAt / 1000), "MMM do yyyy");
          }

          return (
            <Card
              backgroundColor="white"
              border={`1px solid ${theme.colors.gray500}`}
              borderRadius={majorScale(2)}
              padding={majorScale(2)}
              marginBottom={majorScale(3)}
              key={review.id}
            >
              <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text display="block" textTransform="uppercase" fontWeight={500} paddingBottom={minorScale(3)} size={300}>
                  {timestamp}
                </Text>
                {myProfile?.id && _.isEqual(myProfile?.id, review?.user?.id) && (
                  <Button appearance="minimal" is={BrowserLink} to={`/calls/${review?.session?.id}/review`}>
                    Edit
                  </Button>
                )}
              </Pane>

              {response && (
                <Paragraph size={500} marginBottom={majorScale(2)}>
                  {response}
                </Paragraph>
              )}
              {outcome && (
                <Pane marginBottom={majorScale(2)}>
                  <Paragraph size={500}>
                    <Pill marginRight={majorScale(1)}>Outcome</Pill>
                    {outcome}
                  </Paragraph>
                </Pane>
              )}
              <Pane display="flex" alignItems="center">
                <Avatar src={review.user.picture} name={review.user.fullName} size={42} />
                <Pane marginLeft={majorScale(2)}>
                  <Text size={500} fontWeight={500}>
                    {review.user.fullName}
                  </Text>
                  <Text>{review.user.role}</Text>
                </Pane>
              </Pane>
            </Card>
          );
        })}
        {reviews.length > cutoff && (
          <Pane display="flex" justifyContent="flex-end">
            <Button paddingX="10px" color="#3366FF" appearance="minimal" onClick={() => setShowMore(!showMore)}>
              {showMore ? "See less reviews" : "See all reviews"}
            </Button>
          </Pane>
        )}
      </Pane>
    )
  );
};

const ProfileAboutMe = ({ profile, isEditing, showAbout, setShowAbout }) => {
  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass.includes("xs");
        return (
          <Card
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            backgroundColor="white"
            padding={majorScale(2)}
            marginBottom={majorScale(3)}
            marginTop={majorScale(3)}
            minHeight="100px"
            id="about-me"
          >
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
              <Heading fontWeight={500}>
                <span style={{ marginRight: "6px" }}>📓</span>
                About me
              </Heading>
              {isEditing && (
                <Button iconBefore={EditIcon} appearance="minimal" onClick={() => setShowAbout(true)}>
                  Edit About Me
                </Button>
              )}
            </Pane>
            <EditAboutMe myProfile={profile} showAbout={showAbout} setShowAbout={setShowAbout} />
            {profile?.bio && <Paragraph size={500}>{profile.bio}</Paragraph>}
            <ExpertiseSection profile={profile} isEditing={isEditing} />
          </Card>
        );
      }}
    />
  );
};

const ProfileMentorMotivations = ({ profile, mentor, isEditing }) => {
  const [showEditor, setShowEditor] = useState(false);

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass.includes("xs");

        return (
          <>
            {mentor?.motivations ? (
              <Card
                border={`1px solid ${theme.colors.gray500}`}
                borderRadius={majorScale(2)}
                backgroundColor="white"
                padding={majorScale(2)}
                marginBottom={majorScale(3)}
                marginTop={majorScale(3)}
                minHeight="100px"
                id="motivations"
              >
                <Pane display="flex" flexDirection="row" justifyContent="space-between" marginBottom={majorScale(1)} alignItems="center">
                  <Heading fontWeight={500}>
                    <span style={{ marginRight: "6px" }}>⭐️</span>
                    Why I mentor
                  </Heading>
                  {isEditing && (
                    <Button iconBefore={EditIcon} appearance="minimal" onClick={() => setShowEditor(true)}>
                      Edit Mentor Motivations
                    </Button>
                  )}
                </Pane>
                {mentor?.motivations.length > 0 && (
                  <Pane
                    display="grid"
                    gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
                    gridColumnGap={minorScale(1)}
                    gridRowGap={minorScale(1)}
                    marginTop={majorScale(1)}
                    marginBottom={majorScale(1)}
                  >
                    {mentor.motivations.map((motivation) => (
                      <Badge
                        key={`motivation-${mentor?.id}-index`}
                        color={MotivationToColors[motivation] ? MotivationToColors[motivation] : "blue"}
                      >
                        {motivation}
                      </Badge>
                    ))}
                  </Pane>
                )}
                {mentor?.reason && <Paragraph size={500}>{mentor.reason}</Paragraph>}
                <EditMotivations myProfile={profile} myCoachingProfile={mentor} showEditor={showEditor} setShowEditor={setShowEditor} />
                {mentor?.playlists?.length > 0 && (
                  <Pane display="flex" flexDirection="column" marginTop={majorScale(2)} width="100%">
                    <Heading size={400} marginBottom={majorScale(1)}>
                      Talk to me about
                    </Heading>
                    <Pane
                      display="grid"
                      gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
                      gridColumnGap={minorScale(1)}
                      gridRowGap={minorScale(1)}
                    >
                      {mentor.playlists.map(({ name }) => (
                        <Paragraph size={500}>{name}</Paragraph>
                      ))}
                    </Pane>
                  </Pane>
                )}
              </Card>
            ) : null}
          </>
        );
      }}
    />
  );
};

const ProfileActivity = ({ comments, profile, myProfile }) => {
  const [showMore, setShowMore] = useState(false);
  const cutoff = 1;
  const numItems = showMore ? comments?.edges?.length : cutoff;

  return (
    comments?.edges?.length > 0 && (
      <Pane marginTop={majorScale(2)} id="activity">
        <Heading paddingBottom={majorScale(3)} size={500}>
          ✍️ &nbsp; Recent Answers
        </Heading>
        <Pane>
          {comments.edges.slice(0, numItems).map((comment) => (
            <AnswerCard hideReplies myProfile={myProfile} comment={comment} />
          ))}
        </Pane>
        {comments?.edges.length > cutoff && (
          <Button paddingX="10px" color="#3366FF" appearance="minimal" marginTop={majorScale(1)} onClick={() => setShowMore(!showMore)}>
            {showMore ? "See less" : "See more"}
          </Button>
        )}
      </Pane>
    )
  );
};

const ProfileJobs = ({ jobs }) => {
  return (
    jobs?.length > 1 && (
      <Pane marginTop={majorScale(2)} id="jobs">
        {jobs.map((job) => (
          <Job team={job.team} job={job} key={job} flat />
        ))}
      </Pane>
    )
  );
};

const SectionTabs = withRouter(({ isActiveMentor, reviews, location, profile, myOpenJobs, showRelated, commentsByProfile }) => {
  let tabs = [];

  const tabOptions = {
    related: {
      label: "Similar Mentors",
      link: "#similar-mentors"
    },

    bio: {
      label: "About Me",
      link: "#about-me"
    },

    reviews: {
      label: "Recommendations" + (reviews?.length > 0 ? ` (${reviews?.length})` : ""),
      link: "#recommendations"
    },

    activity: {
      label: "Recent Answers",
      link: "#activity"
    },

    jobs: {
      label: "Open Roles" + (myOpenJobs?.length > 0 ? ` (${myOpenJobs?.length})` : ""),
      link: "#jobs"
    }
  };

  tabs = showRelated ? [tabOptions.related, ...tabs] : tabs;
  tabs = reviews?.length ? [...tabs, tabOptions.reviews] : tabs;
  tabs = profile?.bio ? [...tabs, tabOptions.bio] : tabs;
  tabs = commentsByProfile?.pageInfo?.edgeCount && isActiveMentor ? [...tabs, tabOptions.activity] : tabs;
  tabs = myOpenJobs?.length ? [...tabs, tabOptions.jobs] : tabs;

  return (
    tabs?.length > 1 && (
      <TabNavigation>
        {tabs.map((tab, index) => {
          const isSelected = location?.hash ? tab.link === location.hash : index === 0;
          return (
            <Tab
              appearance="minimal"
              key={tab.link}
              is="a"
              href={tab.link}
              id={tab.link}
              isSelected={isSelected}
              marginRight={majorScale(2)}
              paddingY={minorScale(5)}
              fontWeight={500}
              size={400}
              color={isSelected ? "#3366FF" : "#696F8C"}
              borderBottom={isSelected ? "2px solid #3366FF" : "2px solid white"}
            >
              {tab.label}
            </Tab>
          );
        })}
      </TabNavigation>
    )
  );
});

const PreviewButton = (profileContext) => {
  const { profile, setShowShare, isAcceptedMentor, isMobile, width } = profileContext;

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Pane
          position={isMobile ? "relative" : "absolute"}
          width={width}
          marginTop={isMobile ? undefined : majorScale(2)}
          marginBottom={isMobile ? majorScale(2) : undefined}
          textAlign="center"
          zIndex={1}
        >
          <Button
            iconBefore={PersonIcon}
            is="a"
            href={`/p/${profile?.slug}`}
            target="_blank"
            onClick={() => window.analytics.track("Preview Profile")}
          >
            View Public Profile
          </Button>
          {isAcceptedMentor && (
            <Button iconBefore={ShareIcon} marginLeft={majorScale(1)} onClick={() => setShowShare(true)}>
              Share Profile
            </Button>
          )}
        </Pane>
      )}
    />
  );
};

const CandidateProfileOptions = ({ profile, isRecruiter, myRecruiterProfile, width }) => {
  const { data: candidateResult, refetch } = useQuery(CandidateQueries.queries.MyCandidateProfile, {
    variables: {
      profileId: profile?.id
    }
  });

  if (!isRecruiter || !candidateResult) return null;

  return (
    <Pane marginBottom={majorScale(2)}>
      <ProspectOptions
        spacer
        myRecruiterProfile={myRecruiterProfile}
        candidate={_.extend(candidateResult?.myCandidateProfile, { user: profile })}
        user={profile}
        existingRequest={candidateResult?.myCandidateProfile?.existingRequest}
        onUpdate={() => refetch({ profileId: profile?.id })}
      />
    </Pane>
  );
};

const NewProfileView = withRouter((profileContext) => {
  const {
    data,
    isEditing,
    setShowEditor,
    location,
    setShowZoom,
    showZoom,
    setShowHumanity,
    showHumanity,
    showAbout,
    setShowAbout,
    showOverview,
    showShare,
    setShowShare,
    isActiveCandidate,
    hasResume,
    isRecruiter,
    recommendations,
    myRecruiterProfile
  } = profileContext;

  const { profile, coachByProfileId, myProfile, myOpenJobs, hasConversation, commentsByProfile, postsByProfile } = data;

  const { calendar, description, request, section } = qs.parse(location?.search, { arrayFormat: "comma" });
  const isCurrentUser = myProfile?.id === profile?.id;
  const screenClass = useScreenClass();
  const isSmallScreen = ["xs", "sm", "md"].includes(screenClass);
  const isMobile = ["xs"].includes(screenClass);
  const teams = profile?.roles?.map((role) => role.team);
  const schools = profile?.degrees?.map((degree) => degree.school);
  const isAvailable = coachByProfileId?.isAvailable && coachByProfileId?.status === "Active";
  const showBookMe =
    coachByProfileId && isAvailable && profile && !coachByProfileId?.isPaused?.start && !myProfile?.isPaused?.start && !isRecruiter;
  const showEmptyView =
    !profile?.coachingPlaylists?.length > 0 &&
    !coachByProfileId?.allReviews?.length > 0 &&
    !commentsByProfile?.edges?.length > 0 &&
    !profile?.bio &&
    !postsByProfile?.edges?.length > 0 &&
    !showBookMe &&
    !isRecruiter;

  const isActiveMentor = _.isEqual(coachByProfileId?.status, "Active") || _.isEqual(coachByProfileId?.status, "Inactive");
  const showRelated = !isEditing && coachByProfileId?.id && isActiveMentor && !showBookMe && !isRecruiter;
  const isAcceptedMentor =
    _.isEqual(coachByProfileId?.status, "Active") ||
    _.isEqual(coachByProfileId?.status, "Inactive") ||
    _.isEqual(coachByProfileId?.status, "Pending");

  // track event on page load
  useEffect(() => {
    window.analytics.track("View Profile");
  }, []);

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <>
          <ResumeNotif />
          <Row
            style={{
              minHeight: "calc(75vh - 120px)",
              paddingBottom: majorScale(4),
              paddingTop: majorScale(2),
              flexDirection: "column",
              display: "flex",
              width: width[screenClass]
            }}
          >
            {isEditing && (
              <PreviewButton
                isAcceptedMentor={isAcceptedMentor}
                profile={profile}
                coachByProfileId={coachByProfileId}
                isSmallScreen={isSmallScreen}
                setShowShare={setShowShare}
                isMobile={isMobile}
                width={width[screenClass]}
              />
            )}
            {!isEditing && isRecruiter && (
              <CandidateProfileOptions
                profile={profile}
                isRecruiter={isRecruiter}
                myRecruiterProfile={myRecruiterProfile}
                width={width[screenClass]}
              />
            )}
            <ProfileBackground
              profile={profile}
              isSmallScreen={isSmallScreen}
              isCurrentUser={isCurrentUser}
              isEditing={isEditing}
              isMobile={isMobile}
            />
            <Container style={{ width: "100%" }}>
              <Row>
                <Col
                  md={4}
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                >
                  <Pane
                    paddingLeft={majorScale(2)}
                    paddingRight={majorScale(2)}
                    backgroundColor="white"
                    border={`1px solid ${theme.colors.gray500}`}
                    borderTop="none"
                    borderBottomLeftRadius={majorScale(2)}
                    borderBottomRightRadius={majorScale(2)}
                    width="100%"
                    minHeight={360}
                  >
                    <ProfileInformation
                      profile={profile}
                      isSmallScreen={isSmallScreen}
                      isEditing={isEditing}
                      myProfile={myProfile}
                      showOverview={showOverview}
                      showShare={showShare}
                      setShowShare={setShowShare}
                      hasConversation={hasConversation}
                      isCurrentUser={profile?.id === myProfile?.id}
                    />
                    <ProfileIndustries industries={profile?.role?.team?.industries} />
                    <ProfileDemographics
                      title="Experience"
                      items={teams}
                      myProfile={profile}
                      isEditing={isEditing}
                      editForm={EditWorkExperienceContainer}
                      showEditor={_.isEqual(section, "work-experience")}
                    />
                    <ProfileDemographics
                      title="Education"
                      items={schools}
                      isEditing={isEditing}
                      myProfile={profile}
                      editForm={EditEducationContainer}
                    />
                    <ProfileDemographics
                      title="Groups"
                      items={profile?.groups}
                      isEditing={isEditing}
                      myProfile={profile}
                      editForm={EditGroupsForm}
                    />
                  </Pane>
                </Col>
                <Col md={8} style={{ paddingRight: 0, paddingLeft: screenClass?.includes("xs") ? 0 : majorScale(2) }}>
                  <Pane marginTop={majorScale(2)} marginBottom={majorScale(8)}>
                    {isEditing && (
                      <ProfileStrength
                        myCoachingProfile={coachByProfileId}
                        myProfile={profile}
                        setShowEditor={setShowEditor}
                        setShowZoom={setShowZoom}
                        setShowHumanity={setShowHumanity}
                        setShowAbout={setShowAbout}
                        setShowShare={setShowShare}
                        isMobile={isMobile}
                      />
                    )}
                    {!isRecruiter && (
                      <ProfileActions
                        isRecruiter={isRecruiter}
                        profile={data.profile}
                        currentUser={data.myProfile}
                        coachByProfileId={data.coachByProfileId}
                        showBookMe={showBookMe}
                        isAvailable={isAvailable}
                        hasConversation={hasConversation}
                        isEditing={isEditing}
                        showRelated={showRelated}
                        showCalendar={calendar}
                        showRequest={request}
                        description={description}
                        isAcceptedMentor={isAcceptedMentor}
                      />
                    )}
                    {/*showBookMe && (
                  <ProfileTopics
                    topics={profile?.coachingPlaylists}
                    profile={profile}
                    coachByProfileId={coachByProfileId}
                    reviews={coachByProfileId?.allReviews}
                  />
                )*/}
                    {!isEditing && (
                      <SectionTabs
                        showRelated={showRelated}
                        reviews={coachByProfileId?.allReviews}
                        profile={profile}
                        myOpenJobs={myOpenJobs}
                        commentsByProfile={commentsByProfile}
                        isActiveMentor={isActiveMentor}
                      />
                    )}
                    {!isEditing && recommendations?.length > 0 && isRecruiter && (
                      <ProfileRecommendations recommendations={recommendations} profile={profile} isMobile={isMobile} />
                    )}
                    {!isRecruiter && showRelated && <SimilarMentors profile={profile} coachByProfileId={coachByProfileId} />}
                    {(isEditing || isRecruiter) && <CandidateSkills profile={profile} isEditing={isEditing} isRecruiter={isRecruiter} />}
                    {(isEditing || isRecruiter) && (
                      <CandidateWorkExperience
                        profile={profile}
                        isEditing={isEditing}
                        isRecruiter={isRecruiter}
                        editForm={EditWorkExperienceContainer}
                        showEditor={_.isEqual(section, "experience")}
                      />
                    )}
                    {(isEditing || isRecruiter) && <CandidateJobSearch profile={profile} isEditing={isEditing} isRecruiter={isRecruiter} />}
                    {(isEditing || isRecruiter) && (
                      <CandidateTeamPreferences profile={profile} isEditing={isEditing} isRecruiter={isRecruiter} />
                    )}
                    {isEditing && <CandidateHiddenCompanies profile={profile} isEditing={isEditing} />}
                    {(isEditing || profile?.bio) && (
                      <ProfileAboutMe profile={profile} isEditing={isEditing} showAbout={showAbout} setShowAbout={setShowAbout} />
                    )}
                    {((isEditing && isAcceptedMentor) || (isActiveMentor && coachByProfileId?.motivations?.length > 0)) && (
                      <ProfileMentorMotivations profile={profile} mentor={coachByProfileId} isEditing={isEditing} />
                    )}
                    {myOpenJobs && <ProfileJobs jobs={myOpenJobs} />}
                    {/* {!isEditing && !isRecruiter && coachByProfileId?.allReviews?.length > 0 && (
                    <ProfileReviews
                      reviews={coachByProfileId?.allReviews}
                      myProfile={myProfile}
                    />
                  )} */}
                    {!isEditing && recommendations?.length > 0 && !isRecruiter && (
                      <ProfileRecommendations recommendations={recommendations} profile={profile} isMobile={isMobile} />
                    )}
                    {!isRecruiter && !isEditing && commentsByProfile && isActiveMentor && (
                      <ProfileActivity myProfile={myProfile} comments={commentsByProfile} />
                    )}
                    {!isRecruiter && !isEditing && postsByProfile && myProfile?.id && (
                      <RecentQuestions posts={postsByProfile} profile={profile} myProfile={myProfile} />
                    )}
                    {showEmptyView && !isEditing && <EmptyView profile={profile} />}
                  </Pane>
                </Col>
              </Row>
            </Container>
          </Row>
        </>
      )}
    />
  );
});

const NewProfileViewContainer = ({ match, history, location }) => {
  const { section } = qs.parse(location?.search, { arrayFormat: "comma" });
  const [showMentorSetup, setShowMentorSetup] = useState(_.isEqual(section, "accepted") && section);

  const [showZoom, setShowZoom] = useState(_.isEqual(section, "zoom"));
  const [showHumanity, setShowHumanity] = useState(_.isEqual(section, "humanity"));

  const [showAbout, setShowAbout] = useState(_.isEqual(section, "about"));

  const [showOverview] = useState(_.isEqual(section, "overview"));

  const [showShare, setShowShare] = useState(_.isEqual(section, "share"));

  const { data: candidateResult } = useQuery(CandidateQueries.queries.MyCandidateProfile);
  const { data: recruiterResult } = useQuery(CandidateQueries.queries.MyRecruiterProfile);
  const { data, loading } = useQuery(ProfileQuery, {
    variables: {
      slug: match?.params?.slug
    }
  });

  const isActiveCandidate = candidateResult?.myCandidateProfile?.id && !_.isEqual(candidateResult?.myCandidateProfile?.status, "inactive");
  const hasResume = candidateResult?.myCandidateProfile?.hasResume;

  const isRecruiter = data?.myProfile?.mode?.includes("Recruit");

  const isEditing =
    location?.pathname?.includes("/edit") && data?.profile && data?.myProfile && _.isEqual(data?.profile?.id, data?.myProfile?.id);

  return (
    <>
      {loading && <LoadingView />}

      {isEditing && showZoom && data?.profile && (
        <ZoomSetup
          myProfile={data?.profile}
          myCoachingProfile={data?.coachByProfileId}
          showEditor={isEditing}
          setShowEditor={setShowZoom}
          location={location}
        />
      )}

      {isEditing && showHumanity && data?.profile && (
        <HumanitySetup
          myProfile={data?.profile}
          myCoachingProfile={data?.coachByProfileId}
          showEditor={isEditing}
          setShowEditor={setShowHumanity}
          location={location}
        />
      )}

      {isEditing && showMentorSetup && data?.profile && (
        <MentorSetup
          myProfile={data?.profile}
          myCoachingProfile={data?.coachByProfileId}
          showEditor={isEditing}
          setShowEditor={setShowMentorSetup}
          location={location}
        />
      )}

      {data && <Meta profile={data?.profile} slug={match?.params?.slug} mentor={data?.coachByProfileId} />}

      {data?.profile && (
        <>
          <NewProfileView
            data={data}
            isEditing={isEditing}
            setShowZoom={setShowZoom}
            setShowHumanity={setShowHumanity}
            showZoom={showZoom}
            showHumanity={showHumanity}
            showCalendar={location?.search?.includes("?calendar=show")}
            showAbout={showAbout}
            setShowAbout={setShowAbout}
            showOverview={showOverview}
            showShare={showShare}
            setShowShare={setShowShare}
            isActiveCandidate={isActiveCandidate}
            hasResume={hasResume}
            isRecruiter={isRecruiter}
            myRecruiterProfile={recruiterResult?.myRecruiterProfile}
            recommendations={data?.recommendationsByProfileId}
          />
        </>
      )}
    </>
  );
};

export default NewProfileViewContainer;
