import React from "react";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

import DiscussionIndexPage from "./DiscussionIndexPage";
import DiscussionDetailPage from "./DiscussionDetailPage";
import DiscussionFormPage from "./DiscussionFormPage";
import GuidePage from "./GuidePage";

export default withRoutes(Layout, [
  {
    path: "/guidelines",
    component: GuidePage,
    state: "Discussions"
  },
  {
    path: "/discussions",
    component: DiscussionIndexPage,
    state: "Discussions"
  },
  {
    path: "/discussion/create",
    component: DiscussionFormPage,
    state: "Discussions"
  },
  {
    path: "/d/edit/:id",
    component: DiscussionFormPage,
    state: "Discussions"
  },
  {
    path: "/d/:id",
    component: DiscussionDetailPage,
    state: "Discussions"
  }
]);
