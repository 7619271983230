import gql from "graphql-tag";
import ProfileList from "../../queries/profile/ProfileList";
import TeamQueries from "../../queries/team";
import QueryUtils from "../../queries/utils";
import FeedQueries from "../../queries/feed";
import ConnectionQueries from "../../queries/connection";

export default gql`
  query ProfileQuery($profileID: String, $slug: String) {
    hasConversation(userID: $profileID, slug: $slug) {
      id
    },
    myProfile {
      ${ProfileList.fragments.profile},
      groups {
        id,
        name,
        logo,
        description
      },
      place {
        ${ProfileList.fragments.place}
      },
      isPaused {
        start,
        end
      },
    },
    coachByProfileId(profileID: $profileID, slug: $slug) {
      ${ProfileList.fragments.coach},
      meetingLength,
      isPaused {
        start,
        end
      },
      allReviews {
        id,
        createdAt,
        responses {
          question,
          answer
        },
        user {
          id,
          fullName,
          name {
            first,
            last
          }
          picture
        },
        session {
          id,
        }
      },
      user {
        ${ProfileList.fragments.profile}
      },
    },
    myOpenJobs(profileId: $profileID, slug: $slug) {
      ${TeamQueries.fragments.job},
      team {
        ${TeamQueries.fragments.team}
      }
    },
    postsByProfile(slug: $slug) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.post},
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction},
          }
        },
      }
    },
    commentsByProfile(slug: $slug) {
      __typename,
      ${QueryUtils.PageInfo},
      edges {
        ${FeedQueries.fragments.comment},
        reactions {
          __typename,
          ${QueryUtils.PageInfo},
          edges {
            ${FeedQueries.fragments.reaction},
          }
        },
        post {
          ${FeedQueries.fragments.post},
        }
      }
    },
    profile(profileID: $profileID, slug: $slug) {
      ${ProfileList.fragments.profile},
      place {
        ${ProfileList.fragments.place}
      },
      groups {
        id,
        name,
        logo,
        description
      }
    },
    recommendationsByProfileId(profileId: $profileID, slug: $slug) {
      ${ConnectionQueries.fragments.ConnectionRecommendation},
    }
  }
`;
