import React from "react";
import { useQuery } from "@apollo/client";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Alert, Button, Pane, Heading, CalendarIcon, Spinner, RadioGroup, majorScale, toaster, defaultTheme as theme } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import { useMutation } from "@apollo/client";

import SessionCard from "../../components/session/SessionCard";
import BackButton from "../../components/BackButton";
import TextArea from "../../components/form/TextArea";

import SessionQueries from "../../queries/session";

const options = [
  {
    label: "Something came up - I can no longer make this time",
    value: "NOT_AVAILABLE"
  },
  {
    label: "My situation changed - I no longer need to do this session",
    value: "NOT_RELEVANT"
  },
  {
    label: "Something else",
    value: "OTHER"
  }
];

const CancelForm = ({ session, history }) => {
  const [cancelSession, { loading }] = useMutation(SessionQueries.mutations.CancelSession, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.MySessions,
        variables: { direction: "upcoming" }
      },
      { query: SessionQueries.queries.MyFeedback },
      { query: SessionQueries.queries.MyReceivedRequests },
      { query: SessionQueries.queries.MySessionRequests },
      { query: SessionQueries.queries.MissingReviews },
      { query: SessionQueries.queries.MySessionOffers }
    ],
    onCompleted: ({ cancelSession }) => {
      toaster.success(`Canceled session.`);
      history.push(`/sessions/upcoming`);
    }
  });

  return (
    <Formik
      initialValues={{
        reason: "NOT_AVAILABLE",
        details: ""
      }}
      onSubmit={({ reason, details }, { setSubmitting }) => {
        cancelSession({
          variables: {
            id: session?.id,
            reason,
            details
          }
        });

        setSubmitting(false);
      }}
      render={({ errors, values, setFieldValue, isSubmitting }) => (
        <Form>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            backgroundColor="white"
            border={`1px solid ${theme.colors.gray500}`}
            borderRadius={majorScale(2)}
            marginBottom={majorScale(4)}
            paddingX={majorScale(4)}
            paddingY={majorScale(4)}
          >
            <Heading size={400}>Choose a reason</Heading>
            <RadioGroup
              defaultValue={values?.reason}
              options={options}
              size={majorScale(2)}
              onChange={(e) => setFieldValue("reason", e.target.value, false)}
              marginBottom={majorScale(1)}
            />
            <Heading size={400} marginBottom={majorScale(1)}>
              Add an optional message
            </Heading>
            <Field type="details" name="details" placeholder="" component={TextArea} />
            <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(1)}>
              <Button appearance="primary" type="submit" isLoading={loading || isSubmitting}>
                Cancel session
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const CancelSessionPage = ({
  history,
  match: {
    params: { id, state }
  }
}) => {
  const {
    data: sessionAndReview,
    loading,
    error
  } = useQuery(SessionQueries.queries.SessionAndReviewById, {
    variables: { id }
  });

  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Row justify="flex-start" style={{ width: "100%" }}>
          <Col xs={12} sm={2} md={2} lg={2}>
            <BackButton />
          </Col>
          <Col xs={12} sm={8} md={8} lg={8}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              borderBottom="1px solid $efefef"
              paddingTop={screenClass.includes("xs") ? null : majorScale(4)}
              width="100%"
            >
              <Heading display="flex" alignItems="center" size={700} marginBottom={majorScale(2)}>
                <CalendarIcon marginRight={majorScale(1)} size={majorScale(2)} />
                {"Cancel Session"}
              </Heading>
            </Pane>
            {loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Spinner />
              </Pane>
            )}
            {error && !loading && !sessionAndReview?.sessionById?.id && (
              <Pane display="flex" justifyContent="center" padding={majorScale(3)}>
                <Alert title="There was an erorr loading this page. Please try again in a few momments." />
              </Pane>
            )}
            {sessionAndReview?.sessionById?.id && (
              <>
                <Pane width="100%">
                  <SessionCard myProfile={sessionAndReview?.myProfile} session={sessionAndReview?.sessionById} />
                </Pane>
                <Pane>
                  <Heading size={600} marginBottom={majorScale(2)}>
                    {`Let them know why you're canceling`}
                  </Heading>
                  <CancelForm session={sessionAndReview?.sessionById} history={history} />
                </Pane>
              </>
            )}
          </Col>
        </Row>
      )}
    />
  );
};

export default CancelSessionPage;
