import React from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import {
  Button,
  Heading,
  Pane,
  Paragraph,
  PlusIcon,
  RemoveIcon,
  StatusIndicator,
  TextInput,
  majorScale,
  defaultTheme as theme
} from "evergreen-ui";

import { showSuccess, isMentor } from "./utils";

import SessionQueries from "../../queries/session";
import ProfileList from "../../queries/profile/ProfileList";

const ACTIONS_TITLE = "What action items should we do after this?";
const ACTIONS_INFO = "These are action items discussed during the meeting";

const RESOURCES_TITLE = "Are there any resources that I should look at?";
const RESOURCES_INFO = "Drop in any links, videos, books, etc that came to mind during the session";

const FollowUpForm = ({ myProfile, session }) => {
  const [updateFollowUp, { loading: saving }] = useMutation(SessionQueries.mutations.UpdateSessionFollowUp, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SessionQueries.queries.SessionAndReview,
        variables: {
          sessionID: session.id
        }
      },
      {
        query: SessionQueries.queries.SessionAndReviewById,
        variables: {
          id: session.id
        }
      },
      { query: ProfileList.LayoutQuery }
    ],
    onCompleted: () => {
      showSuccess("Updated.");
    }
  });

  return (
    <Formik
      initialValues={{
        actions: session?.actions ? session.actions : [],
        resources: session?.resources ? session.resources : []
      }}
      onSubmit={({ actions, resources }) => {
        updateFollowUp({
          variables: {
            actions,
            resources: resources,
            id: session?.id
          }
        });
      }}
      render={({ values: { actions, resources }, errors, status, touched, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Pane display="flex" justifyContent="center" flexDirection="column">
            <Pane marginBottom={majorScale(2)}>
              <Pane
                display="flex"
                flexDirection="column"
                width="100%"
                padding={majorScale(4)}
                borderRadius={majorScale(2)}
                border={`1px solid ${theme.colors.gray500}`}
                backgroundColor="white"
                minHeight="100px"
              >
                <Pane marginBottom={majorScale(2)}>
                  <Heading size={500} marginBottom={majorScale(1)}>
                    {ACTIONS_TITLE}
                  </Heading>
                  <Paragraph size={500}>{ACTIONS_INFO}</Paragraph>
                </Pane>
                <Pane>
                  {actions.map((action, index) => (
                    <Pane width="100%" display="flex" flexDirection="row" marginBottom={majorScale(2)} alignItems="center">
                      <TextInput
                        width="100%"
                        value={actions[index]}
                        onChange={(e) => {
                          actions[index] = e.target.value;
                          setFieldValue("actions", actions, false);
                        }}
                      />
                      <RemoveIcon
                        marginLeft={majorScale(2)}
                        color="danger"
                        onClick={() => {
                          actions.splice(index, 1);
                          setFieldValue("actions", actions);
                        }}
                      />
                    </Pane>
                  ))}
                  <Button appearance="minimal" iconBefore={PlusIcon} onClick={() => actions.push("")}>
                    {"Add Action Item"}
                  </Button>
                </Pane>
              </Pane>
            </Pane>

            <Pane
              display="flex"
              flexDirection="column"
              width="100%"
              padding={majorScale(4)}
              borderRadius={majorScale(2)}
              border={`1px solid ${theme.colors.gray500}`}
              backgroundColor="white"
              minHeight="100px"
            >
              <Pane marginBottom={majorScale(2)}>
                <Heading size={500} marginBottom={majorScale(1)}>
                  {RESOURCES_TITLE}
                </Heading>
                <Paragraph size={500}>{RESOURCES_INFO}</Paragraph>
              </Pane>
              <Pane>
                {resources.map((resource, index) => (
                  <Pane width="100%" display="flex" flexDirection="row" marginBottom={majorScale(2)} alignItems="center">
                    <TextInput
                      width="100%"
                      value={resources[index]}
                      onChange={(e) => {
                        resources[index] = e.target.value;
                        setFieldValue("resources", resources, false);
                      }}
                    />
                    <RemoveIcon
                      marginLeft={majorScale(2)}
                      color="danger"
                      onClick={() => {
                        resources.splice(index, 1);
                        setFieldValue("resources", resources);
                      }}
                    />
                  </Pane>
                ))}
                <Button appearance="minimal" iconBefore={PlusIcon} onClick={() => resources.push("")}>
                  {"Add Resource"}
                </Button>
              </Pane>
            </Pane>

            <Pane display="flex" flexDirection="row" justifyContent="flex-end" paddingTop={majorScale(2)} paddingBottom={majorScale(6)}>
              <Button type="submit" appearance="primary" intent="primary" isLoading={saving}>
                Save Notes
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

const FollowUpDetail = ({ myProfile, session }) => {
  const { resources, actions } = session;

  return (
    <>
      <Pane display="flex" justifyContent="center" flexDirection="column">
        <Pane marginBottom={majorScale(2)}>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            padding={majorScale(4)}
            borderRadius={majorScale(2)}
            border={`1px solid ${theme.colors.gray500}`}
            backgroundColor="white"
            minHeight="100px"
          >
            <Pane marginBottom={majorScale(2)}>
              <Heading size={500} marginBottom={majorScale(1)}>
                {ACTIONS_TITLE}
              </Heading>
              <Paragraph size={500}>{ACTIONS_INFO}</Paragraph>
            </Pane>
            <Pane>
              {actions.map((action, index) => (
                <Pane width="100%" display="flex" flexDirection="row" marginBottom={majorScale(2)} alignItems="center">
                  <StatusIndicator color="info">{action}</StatusIndicator>
                </Pane>
              ))}
            </Pane>
          </Pane>
        </Pane>

        <Pane marginBottom={majorScale(2)}>
          <Pane
            display="flex"
            flexDirection="column"
            width="100%"
            padding={majorScale(4)}
            borderRadius={majorScale(2)}
            border={`1px solid ${theme.colors.gray500}`}
            backgroundColor="white"
            minHeight="100px"
          >
            <Pane marginBottom={majorScale(2)}>
              <Heading size={500} marginBottom={majorScale(1)}>
                {RESOURCES_TITLE}
              </Heading>
              <Paragraph size={500}>{RESOURCES_INFO}</Paragraph>
            </Pane>
            <Pane>
              {resources.map((resource, index) => (
                <Pane width="100%" display="flex" flexDirection="row" marginBottom={majorScale(2)} alignItems="center">
                  <StatusIndicator color="info">{resource}</StatusIndicator>
                </Pane>
              ))}
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </>
  );
};

const FollowUpFormContainer = ({ myProfile, session }) => {
  const showMentorView = isMentor({ myProfile, session });

  return (
    <>
      <FollowUpForm myProfile={myProfile} session={session} />
    </>
  );
};

export default FollowUpFormContainer;
