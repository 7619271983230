import _ from "lodash";
import React from "react";
import { fromUnixTime, format } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, formatInTimeZone } from "date-fns-tz";

import { Heading, Pane, Paragraph, majorScale, defaultTheme as theme } from "evergreen-ui";

export const formatSessionDate = (date, timeZone) => {
  timeZone = timeZone?.length ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to UTC and then apply a proper timezone
  if (!_.isNaN(Number(date))) {
    date = fromUnixTime(Number(date) / 1000);
  }

  // Convert the date to UTC and then apply a proper timezone
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  const day = formatInTimeZone(zonedDate, timeZone, "eee LLLL do");
  let time = formatInTimeZone(zonedDate, timeZone, "hh:mm b (z)");

  return `${day} at ${time}`;
};

const SessionCard = ({ session, myProfile }) => {
  const timezone = myProfile?.timezone;
  const { date, cleanDescription, description } = session;
  const formattedDate = formatSessionDate(date, timezone);
  const details = _.isEmpty(cleanDescription) ? description : cleanDescription;

  return (
    <Pane
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray500}`}
      borderRadius={majorScale(2)}
      padding={majorScale(2)}
      width="100%"
    >
      <Heading size={400}>{formattedDate}</Heading>
      <Pane paddingTop={majorScale(1)} style={{ overflowWrap: "anywhere" }}>
        <Paragraph size={500}>{details}</Paragraph>
      </Pane>
    </Pane>
  );
};

export default SessionCard;
